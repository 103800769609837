

var changeClientResMsgSchema;
var joinGameReqMsgSchema;
var joinGameResMsgSchema;
var eventReqMsgSchema;
var eventResMsgSchema;
var moveResMsgSchema;
var drawCheckMsgSchema;
var drawRejectMsgSchema;
var startGameMsgSchema;
var gameEndResMsgSchema;

module.exports.changeClientResMsgSchema = {
        requestid: "string",
        type: "change_client",
        username:     "string",
        message : "string"
};

module.exports.joinGameReqMsgSchema = {
        requestId       : "string",
        type            : "string",
        variantId       : "uint8",
        username        : "string",
        message	        : "string",
        playerMoveTimer : "uint32",
        moveTimeIncrement : "uint32",
        gamePlayMode    : "string"
};

module.exports.startGameResMsgSchema = {
        requestId       : "string",
        type            : "string",
        message         : "string",
        version         : "uint32",
        variantId       : "uint32",
        variantType     : "uint32",
        variantName     : "string",
        winType         : "uint8",
        joiningFee      : "float32",
        pointDenom      : "float32",
        maxPlayers      : "uint8",
        state           : "uint8",
        stateDisplayName: "string",
        playerList      : ["string"],
        tableId         : "uint32",
        prevTableId     : "uint32",
        gameId          : "uint32",
        timerValue      : "uint32",
        timerType       : "string",
        currentPlayer   : "uint32",
        tossWinner      : "uint32",
        dealer          : "uint32",
        success         : "boolean",
        autoDropEnabled : "boolean",
        playerMoveTimer : "uint32",
        moveTimeIncrement: "uint32",
        startFen        : "string",
        opponentuserId  : "uint32",
        opponentusername: "string",
        rating          : "uint32",
        opponentRating  : "uint32",
        gamePlayMode    : "string",
        username        : "string",
        touchPiece      : "string",

};

module.exports.joinGameResMsgSchema = {
        requestId       : "string",
        type            : "string",
        message         : "string",
        version         : "uint32",
        variantId       : "uint32",
        variantType     : "uint32",
        variantName     : "string",
        winType         : "uint8",
        joiningFee      : "float32",
        pointDenom      : "float32",
        maxPlayers      : "uint8",
        state           : "uint8",
        stateDisplayName: "string",
        playerList      : ["string"],
        tableId         : "uint32",
        prevTableId     : "uint32",
        gameId          : "uint32",
        timerValue      : "uint32",
        timerType       : "string",
        currentPlayer   : "uint32",
        tossWinner      : "uint32",
        dealer          : "uint32",
        success         : "boolean",
        autoDropEnabled : "boolean",
        playerMoveTimer : "uint32",
        moveTimeIncrement: "uint32",
};

module.exports.eventReqMsgSchema = {
        requestId       : "string",
        type            : "string",
        userId          : "string",
        tableId         : "uint32",
        data            : "string"
};

// module.exports.eventResMsgSchema = {
//         requestId       : "string",
//         type            : "string",
//         userId          : "string",
//         tableId         : "uint32",
//         data            : "string",
//         white           : "string",
//         black           : "string"
// };
module.exports.eventResMsgSchema = {
        requestId           : "string",
        type                : "string",
        userId              : "string",
        tableId             : "uint32",
        data                : "string",
        whiteTime           : "uint32",
        blackTime           : "uint32",
        moves               : ["string"],
        whitePiecesCaptured : ["string"],
        blackPiecesCaptured : ["string"],
        previousFen         : "string"
};

// Game Ready message before the start time starts but, the 
// game is ready to be started. This communicates meta info
// about the game to the clients
module.exports.gameReadyMsgSchema = {
        requestId       : "string",
        type            : "string",
        userId          : "string",
        tableId         : "uint32",
        data            : "string",
        white           : "string",
        black           : "string"
};

module.exports.startGameMsgSchema = {
        requestId: "string",
        type : "string",
        tableId:   "uint32",
};

module.exports.moveResMsgSchema = {
        requestId: "string",
        type : "string",
        username: "string",
        tableId:   "uint32",
        move: "string",
};

module.exports.drawCheckMsgSchema = {
        requestId       : "string",
        type            : "string",
        userId          : "string",
        tableId         : "uint32",
        requester       : "string",
};

module.exports.drawRejectMsgSchema = {
        requestId       : "string",
        type            : "string",
        userId        : "string",
        tableId         : "uint32",
        responsee       : "string",
};

module.exports.gameEndResMsgSchema = {
        requestId           : "string",
        type                : "string",
        endType             : "string",
        userId              : "string",
        tableId             : "uint32",
        gameState           : "string",
        winner              : "string",
        pgn                 : "string",
        moves               : ["string"],
        whitePiecesCaptured : ["string"],
        blackPiecesCaptured : ["string"],
        opponent	    : "string",
        playerMoveTimer     : "uint32",
        moveTimeIncrement   : "uint32",
        white               : "string",
        winner_rating       : "uint32",
        opponent_rating     : "uint32",
        winner_rating_delta : "string",
        opponent_rating_delta: "string",
        winner_gameId       : "string",
        opponent_gameId     : "string",
        
};

module.exports.gameReadyResMsgSchema = {
        requestId       : "string",
        type            : "string",
        userId          : "string",
        tableId         : "uint32",
        data            : "string",
        opponentuserId  : "string",
        opponentusername: "string",
        moveTimer       : "uint32",
        username        : "string",
        customRulesMap  : "string",
        userRating      : "uint32",
        opponentRating  : "uint32",
        tossWinner      : "uint32",
};

module.exports.challengeGameMsgSchema = {
        requestId       : "string",
        type            : "string",
        variantId       : "uint8",
        userId          : "string",
        opponent        : "string",
        playerMoveTimer : "uint32",
        moveTimeIncrement : "uint32",
        gameState         : "string",
        white             : "string",
        black             : "string",
        gamePlayMode    : "string"

};

module.exports.preStartGameMsgSchema = {
        requestId       : "string",
        type            : "string",
        tableId         : "uint32",
        variantId       : "uint32",
        data            : "string",
        white           : "string",
        black           : "string",
        timerValue      : "uint32",
};

module.exports.leaveTableReqMsgSchema = {
        requestId: "string",
        type : "string",
        tableId:   "uint32",
};

module.exports.onlinePlayersSearchMsgSchema = {
        requestId      : "string",
        pattern        : "string",
        type           : "string",
        userId         : "string"
};

module.exports.onlinePlayersResultMsgSchema = {
        requestId      : "string",
        type           : "string",
        players        : "string"
};

module.exports.fetchGameStateMsgSchema = {
        requestId      : "string",
        type           : "string",
        userId         : "string"
};





module.exports.tableDataResMsgSchema  = module.exports.joinGameResMsgSchema;
// module.exports.startGameResMsgSchema  = module.exports.joinGameResMsgSchema;
