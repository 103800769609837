import React, {useState, useEffect, useRef} from 'react';
import {Image, PanResponder, Animated, Pressable} from 'react-native';
import BlindFoldChess from '../BlindFoldChess/BlindFoldChess';
import CCText from '@/Atoms/CCText';
import CCButton from '@/Atoms/CCButton';
import ChessboardComponent from 'chessboard-package';
import ConditionalRender from '@/Atoms/ConditionalRender';
import CCColors from '@/Utils/CCColors';
import Box from '@/Atoms/Box';
import Divider from '@/Atoms/Divider/Divider';
import Tooltip from 'react-native-walkthrough-tooltip';
import {useDrag} from 'react-dnd';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';
import {AnimatedCircularProgress} from 'react-native-circular-progress';
import Draggable from '@/Atoms/Draggable/Draggable';
import {SCREEN_HEIGHT, SCREEN_WIDTH, isPhone} from '@/Utils/Dimensions';
import styles from './styles';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import goBack from '@/Assets/Images/png/go_back.png';
import trash from '@/Assets/Images/png/trash.png';
import useChessboardColorTheme from '@/Hooks/ChessboardColorTheme/useChessboardColorTheme';

const pieces = ['Pawn', 'Knight', 'Bishop', 'Rook', 'Queen', 'King'];

const VisualisationMemory = ({showDropDown, setShowDropDown}) => {
  const {Chess} = require('chess.js');
  const chess = new Chess();
  const [position, setPosition] = useState<string>(
    'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  );
  const [isTrainingStarted, setIsTrainingStarted] = useState<boolean>(false);
  const [testFen, setTestFen] = useState<string>('');
  const [type, setType] = useState();
  const [color, setColor] = useState();
  const [square, setSquare] = useState<any>();
  const [removeSquare, setRemoveSquare] = useState<any>();
  const [correctAnswer, setCorrectAnswer] = useState<string>('');
  const [visitedCount, setVisitedCount] = useState<number>(0);
  const [selectedBlackPieceIndex, setSelectedBlackPieceIndex] = useState<
    number | null
  >(null);
  const [selectedWhitePieceIndex, setSelectedWhitePieceIndex] = useState<
    number | null
  >(null);
  const [draggedObject, setDraggedObject] = useState<{}>();
  const [clearBoard, setClearBoard] = useState<boolean>(false);
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [removeFill, setRemoveFill] = useState<number>(0);
  const [timer, setTimer] = useState<number>(30);
  const [isTimerOver, setIsTimerOver] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<number | null>(null);
  const [showChessBoard, setShowChessBoard] = useState<boolean>(false);
  const chessboardColorTheme = useChessboardColorTheme();

  const {isMobileOrTab} = useMediaQuery();

  const pieceNotation = {
    Pawn: 'p',
    Knight: 'n',
    Bishop: 'b',
    Rook: 'r',
    Queen: 'q',
    King: 'k',
  };

  const generateRandomPosition = () => {
    let maxNoOfMoves = Math.floor(Math.random() * 30) + 1;
    while (maxNoOfMoves !== 0) {
      const moves = chess.moves();
      const move = moves[Math.floor(Math.random() * moves.length)];
      chess.move(move);
      maxNoOfMoves -= 1;
    }
    return chess.fen();
  };

  useEffect(() => {
    setPosition(generateRandomPosition());
  }, []);

  const getFenHandler = FEN => {
    setTestFen(FEN);
  };

  let fillPerc = 100 - removeFill;
  let tintColor = CCColors.Green;
  if (fillPerc >= 75 && fillPerc <= 100) {
    tintColor = CCColors.Green;
  } else if (fillPerc >= 50 && fillPerc < 75) {
    tintColor = CCColors.Progress.Bar;
  } else if (fillPerc < 50) {
    tintColor = CCColors.WrongMoves.Background;
  }

  const startTimer = () => {
    const id = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer === 0) {
          clearInterval(id);
          setRemoveFill(0);
          setIsTrainingStarted(true);
          return 0;
        } else {
          setRemoveFill(prev => {
            return prev + 100 / timer;
          });
          return prevTimer - 1;
        }
      });
    }, 1000);

    setIntervalId(id);
  };

  useEffect(() => {
    clearInterval(intervalId);
    setRemoveFill(0);
    startTimer();
    setCorrectAnswer('');
  }, [isTrainingStarted]);

  return (
    <Box
      flexDirection={isMobileOrTab ? 'column' : 'row'}
      marginLeft={!isMobileOrTab && '5%'}
      alignItems="center"
      flexWrap="wrap"
      gap={20}>
      <Box flex={1} marginTop={10}>
        <ConditionalRender
          condition={!isTrainingStarted}
          childrenA={
            <>
              {isMobileOrTab && showChessBoard && (
                <Box style={{width: SCREEN_WIDTH, height: SCREEN_HEIGHT}}>
                  <ChessboardComponent
                    position={position}
                    disabled={true}
                    colorTheme={chessboardColorTheme}
                  />
                </Box>
              )}
              {!isMobileOrTab && (
                <ChessboardComponent
                  position={position}
                  disabled={true}
                  colorTheme={chessboardColorTheme}
                />
              )}
            </>
          }
          childrenB={
            <>
              <BlindFoldChess
                draggedObject={draggedObject}
                onDrop={(sourceSquare, targetSquare, piece) => {
                  setDraggedObject({
                    source: sourceSquare,
                    target: targetSquare,
                    piece: piece,
                  });
                }}
                removeSquare={removeSquare}
                getFen={getFenHandler}
                type={type}
                color={color}
                square={square}
                arePiecesDraggable={true}
                piecesVisible={true}
                position={testFen || '8/8/8/8/8/8/8/8 w - - 0 1'}
                handleSquareClick={square => {
                  setSquare(square);
                }}
                clearBoard={clearBoard}
              />
            </>
          }
        />
      </Box>
      <Box gap={10} style={[styles.container, {}]}>
        <CCText
          fontSize={20}
          textAlign={isMobileOrTab ? 'justify' : 'center'}
          fontWeight="medium">
          Visualisation - Memory
        </CCText>
        <CCText
          fontSize={14}
          fontWeight="regular"
          textAlign={isMobileOrTab ? 'justify' : 'center'}>
          {isTrainingStarted
            ? `Memorize the positions on the chess board and recreate it from scratch`
            : `Memorize the positions on the chess board and recreate it`}
        </CCText>
        {!isTrainingStarted && (
          <CCButton
            style={{marginTop: 20}}
            onPress={() => {
              setIsTrainingStarted(true);
              if (isMobileOrTab) {
                setShowDropDown(false);
                setShowChessBoard(true);
              }
            }}>
            <CCText
              style={{padding: 5}}
              color={CCColors.TextColor.Dark}
              fontSize={isMobileOrTab && 14}>
              {visitedCount ? 'Resume Training' : 'Start Training'}
            </CCText>
          </CCButton>
        )}

        {/* <ConditionalRender
          condition={isTrainingStarted && !isMobileOrTab}
          childrenA={
            <CCText textAlign="center" style={{marginTop: 20}}>
              The Board is going to be hidden in{' '}
            </CCText>
          }
        /> */}

        {/* {isTrainingStarted && !isMobileOrTab && (
          <Box justifyContent="center" alignItems="center" marginTop={20}>
            <AnimatedCircularProgress
              prefill={100}
              rotation={0}
              size={isPhone ? 100 : 240}
              width={isPhone ? 6 : 12}
              fill={fillPerc}
              tintColor={isTimerOver ? CCColors.Primary.lightBrown : tintColor}
              backgroundColor={
                isTimerOver ? CCColors.White : CCColors.Box.MiddleLine
              }>
              {fill => (
                <Box
                  justifyContent="center"
                  alignItems="center"
                  style={styles.circularProgress}>
                  {!isTimerOver && (
                    <CCText fontSize={isPhone ? 20 : 48} fontWeight="thunder">
                      {timer}s
                    </CCText>
                  )}
                </Box>
              )}
            </AnimatedCircularProgress>
          </Box>
        )} */}

        <ConditionalRender
          condition={isTrainingStarted}
          childrenA={
            <Box marginTop={40} style={styles.pieceSection}>
              <Box
                flexDirection="row"
                justifyContent="space-evenly"
                flexWrap="wrap">
                {pieces.map((piece, index) => {
                  return (
                    <Draggable key={index}>
                      <Pressable
                        onPress={() => {
                          setSquare(null);
                          setType(pieceNotation[piece]);
                          setColor('b');
                          setSelectedBlackPieceIndex(index);
                          setSelectedWhitePieceIndex(null);
                          setClearBoard(false);
                          setRemoveSquare(null);
                        }}
                        // onDragStart={() => {}}
                      >
                        <Image
                          resizeMethod="resize"
                          source={require(`@/Assets/ChessPieces/Black-${piece}.svg`)}
                          alt="piece"
                          style={{
                            width: 65,
                            height: 65,
                            borderRadius: 8,
                            backgroundColor:
                              selectedBlackPieceIndex === index
                                ? CCColors.Primary.Default
                                : 'transparent',
                          }}
                        />
                      </Pressable>
                    </Draggable>
                  );
                  // const [, drag] = useDrag({
                  //   item: {
                  //     type: 'image',
                  //     pieceType: pieceNotation[piece],
                  //     color: 'b',
                  //   },
                  // });

                  // return (
                  //   <div ref={drag}>
                  //     <Image
                  //       resizeMethod="resize"
                  //       source={require(`@/Assets/ChessPieces/Black-${piece}.svg`)}
                  //       alt="piece"
                  //       style={{width: 65, height: 65}}
                  //     />
                  //   </div>
                  // );
                })}
              </Box>
              <Divider
                style={{width: '90%', marginLeft: 'auto', marginRight: 'auto'}}
                marginTop={10}
                marginBottom={10}
              />
              <Box
                flexDirection="row"
                justifyContent="space-evenly"
                flexWrap="wrap">
                {pieces.map((piece, index) => {
                  return (
                    <div
                      onClick={() => {
                        setSquare(null);
                        setType(pieceNotation[piece]);
                        setColor('w');
                        setSelectedWhitePieceIndex(index);
                        setSelectedBlackPieceIndex(null);
                        setClearBoard(false);
                        setRemoveSquare(null);
                      }}
                      onDragStart={() => {}}>
                      <Image
                        resizeMethod="resize"
                        source={require(`@/Assets/ChessPieces/White-${piece}.svg`)}
                        alt="piece"
                        style={{
                          width: 65,
                          height: 65,
                          borderRadius: 8,
                          backgroundColor:
                            selectedWhitePieceIndex === index
                              ? CCColors.Primary.Default
                              : 'transparent',
                        }}
                      />
                    </div>
                  );
                })}
              </Box>
            </Box>
          }
        />

        <ConditionalRender
          condition={isTrainingStarted}
          childrenA={
            <>
              <Box
                marginTop={20}
                flexDirection="row"
                justifyContent="space-around"
                flexWrap="wrap">
                <Box
                  flexDirection="row"
                  justifyContent="space-around"
                  alignItems="center"
                  style={styles.goBack}>
                  <Pressable
                    onPress={() => {
                      setIsTrainingStarted(false);
                      setVisitedCount(prev => prev + 1);
                      setTimer(30);
                      setRemoveFill(0);
                    }}>
                    <Image
                      resizeMethod="resize"
                      source={{uri: goBack}}
                      alt="go back"
                      style={{width: 20, height: 20}}
                    />
                  </Pressable>
                  <Tooltip
                    disableShadow
                    contentStyle={styles.tooltip}
                    backgroundColor={`${CCColors.White}00`}
                    isVisible={tooltipVisible}
                    content={
                      <CCText
                        color={CCColors.White}
                        fontSize={12}
                        fontWeight="regular">
                        You have seen actual board {visitedCount} times
                      </CCText>
                    }
                    placement="bottom"
                    onClose={() => {
                      setTooltipVisible(false);
                    }}>
                    <TouchableWithoutFeedback
                      style={{cursor: 'pointer'}}
                      onPressIn={() => setTooltipVisible(true)}>
                      <CCText>({visitedCount})</CCText>
                    </TouchableWithoutFeedback>
                  </Tooltip>
                  <Divider isVertical={true} />
                  <Pressable
                    onPress={() => {
                      setRemoveSquare(square);
                      setType(null);
                      setColor(null);
                    }}>
                    <Image
                      resizeMethod="resize"
                      source={{uri: trash}}
                      alt="trash"
                      style={{width: 20, height: 20}}
                    />
                  </Pressable>
                </Box>

                <CCButton
                  style={{width: 200}}
                  onPress={() => {
                    setClearBoard(true);
                  }}>
                  <CCText style={{padding: 8}}>Clear Board</CCText>
                </CCButton>
                <CCButton
                  style={{width: 200}}
                  onPress={() => {
                    if (position.split(' ')[0] === testFen.split(' ')[0]) {
                      setCorrectAnswer('Correct');
                      setClearBoard(true);
                      setSelectedBlackPieceIndex(null);
                      setSelectedWhitePieceIndex(null);
                    } else {
                      setCorrectAnswer('Incorrect, please try again');
                    }

                    if (correctAnswer === 'Correct') {
                      setIsTrainingStarted(false);
                      setPosition(generateRandomPosition());
                      setTimer(30);
                      setVisitedCount(0);
                    }
                  }}>
                  <CCText style={{padding: 8}}>
                    {correctAnswer !== 'Correct' ? 'Verify' : 'Play Again'}
                  </CCText>
                </CCButton>
              </Box>
              <Box justifyContent="center" alignItems="center">
                <CCText
                  fontSize={20}
                  style={{padding: 5}}
                  textAlign="center"
                  color={
                    correctAnswer === 'Correct'
                      ? CCColors.Green
                      : CCColors.Error
                  }>
                  {correctAnswer}
                </CCText>
              </Box>
            </>
          }
        />
      </Box>
    </Box>
  );
};

export default VisualisationMemory;
