import Box from '../../Atoms/Box';
import CCText from '../../Atoms/CCText';
import CCButton from '../../Atoms/CCButton';
import React, { useState } from 'react';
import SafeAreaWrapper from '../../Atoms/SafeViewWrapper';
import Spacer from '@/Atoms/Spacer';
import { hs, vs, FontSizes, isPhone } from '../../Utils/Dimensions';
import { styles } from '../Login/styles';
import { styles2 } from '../WaitList/styles';
import CCTextInput from '@/Atoms/CCTextInput';
import ConditionalRender from '@/Atoms/ConditionalRender';

import logo from '../../Assets/Images/Svgs/cc_logo.png';
import { Text } from 'react-native';
import CCColors from '@/Utils/CCColors';
import { useRoute } from '@react-navigation/native';

import { Image } from 'react-native';
import ChessRestClient from '@/Api/ChessRestClient';

import frame_background from '../../Assets/Images/Svgs/background_frames.svg';
import bishopImg from '../../Assets/Images/bishop.png';
import pawnImg from '../../Assets/Images/pawn.png';

function WaitList() {

  const [formData, setFormData] = useState<{
    phone: string;
    valid: boolean;
    success: boolean;
    errorMsg: string;
  }>({
    phone: '',
    valid: false,
    success: false,
    errorMsg: '',
  });

  const handleLoginDataChange = (text: string, field: string) => {
    setFormData(prev => {

      var data = { ...prev, [field]: text };
      if (!data.phone.startsWith("0") && data.phone.length == 10) {
        data.valid = true;
      } else {
        data.valid = false;
      }

      data.errorMsg = '';

      return data;
    });
  }

  const route = useRoute();
  const source = route.params?.source;
  var heading = "Be among the first to experience CircleChess's revolutionary learning platform"
  if (source === "t") {
    heading = "Stay updated for future tournaments organized by CircleChess"
  }

  const doSubmit = () => {
    if (formData.phone.length == 10) {
      try {
        ChessRestClient.post('v1/waitlist_api/', {
          phone: formData.phone,
          source: source === "t" ? source : "l"
        }).then(resp => {
          if (resp.data.success) {
            setFormData({ ...formData, success: true });
          } else {
            setFormData({ ...formData, errorMsg: resp.data.msg });
          }

        });

      } catch (e) {
        console.log(e);
      }
    }
  };

    const formElement = (
      <>
      <Box
      flexDirection='column'
      justifyContent="center"
      alignItems="center"
      style={{ paddingTop: vs(12)}}>
      <Text style={styles2.header}>
        {heading}
      </Text>
      </Box>

      <CCText
      style={[styles2.welcomeMsg,
              {...(formData.success? {opacity: 1}: {})}
      ]}>
      Welcome onboard. You will hear from us soon!
      </CCText>

      <div style={formData.success? {opacity: 0} : {}}>
      <Box
      flexDirection={isPhone ? 'column' : 'row'}
      justifyContent="center"
      alignItems="center"
      style={{ paddingTop: vs(24) }}>
      <CCTextInput
      inputType="phone"
      label="Enter Phone number"
      style={[styles.textBoxes, styles2.input]}
      outlineColor={CCColors.TextBoxes.Borders}
      onChangeText={(text: string) => {
        handleLoginDataChange(text, 'phone');
      }}/>
      </Box>
      <Spacer spacing={24} />
      <Box flexDirection={isPhone ? 'column' : 'row'} justifyContent="center">
      <CCButton
      style={styles?.buttonWrapper}
      titleStyle={styles.buttonStyles}
      disabled={!formData.valid}
      onPress={doSubmit}
        type="outline">
        Join the Waitlist
      </CCButton>

</Box>

<Box flexDirection={isPhone ? 'column' : 'row'} justifyContent="center">
  <CCText
    style={[{
      flex: 0.5,
      textAlign: 'center',
      color: 'red',
      display: 'block',
      opacity: 0,
    },
            {...(formData.errorMsg.length > 0 ? {opacity: 1}: {})}]
    }>
    Already registered!
  </CCText>
</Box>


      </div>
      </>

    );

        return (
          <SafeAreaWrapper>
            <Box style={[styles.container, styles2.waitListPage]} flexDirection='column'>
              <Image source={frame_background} style={{ width: '75%', height: '75%', position: 'absolute', bottom: 0, right: 0, opacity: 0.5 }} />

              <Box

                style={[
                  styles.labelText,
                  styles2.waitlistContainer,
                ]}>
                <Image source={bishopImg} style={styles2.bishopImg} />
                <Image source={pawnImg} style={styles2.pawnImg} />

                <img
                  src={logo}
                  alt="Circlechess"
                  style={{ width: 200, marginLeft: 'auto', marginRight: 'auto' }}
                />
                <Spacer spacing={12} />
                <CCText fontWeight="bold" style={styles.loginHeader}>
          &nbsp;
            </CCText>
            <Spacer spacing={72} />
            {formElement}
          </Box>
        </Box>
      </SafeAreaWrapper>
    );
}

export default WaitList;
