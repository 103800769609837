import {streamGameAnalysis} from '@/Screens/ChessAnalysis/streamGameAnalysisApi';
import {useMutation, UseMutationResult} from '@tanstack/react-query';
// import {streamGameAnalysis} from './streamGameAnalysisApi';

export type StreamGameAnalysisPayload = {
  gameId: number;
};

export function useStreamGameAnalysis(): UseMutationResult<
  any[],
  Error,
  StreamGameAnalysisPayload
> {
  return useMutation<any[], Error, StreamGameAnalysisPayload>(
    async (payload: StreamGameAnalysisPayload) => {
      return streamGameAnalysis(payload.gameId);
    },
  );
}
