import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import ConditionalRender from '@/Atoms/ConditionalRender';
import CCColors from '@/Utils/CCColors';
import React from 'react';
import {Pressable, Image} from 'react-native';
import {RFPercentage} from 'react-native-responsive-fontsize';
import MenuItems from '../MenuItems';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import logo from '@/Assets/Images/Svgs/home_page/logo.svg';

function CCHomeHeader({signUpHandler}) {
  const {isMobileOrTab} = useMediaQuery();
  return (
    <Box
      style={{width: '100vw'}}
      overflow="visible"
      marginTop={20}
      flexDirection="row"
      justifyContent={!isMobileOrTab ? 'space-around' : 'space-between'}
      alignItems="center">
      <Image
        resizeMethod="resize"
        source={logo}
        alt="logo"
        style={{
          width: 162,
          height: 32,
          marginLeft: isMobileOrTab ? 20 : undefined,
        }}
      />

      <ConditionalRender
        condition={isMobileOrTab}
        childrenA={<MenuItems />}
        childrenB={
          <>
            <Box
              flexDirection="row"
              gap={28}
              style={{
                backgroundColor: CCColors.Primary.lightBrown,
                borderRadius: 24,
                paddingHorizontal: 40,
                paddingVertical: 12,
              }}>
              <Pressable
                onPress={() => {
                  window.open('https://circlechess.com/', '_blank');
                }}>
                {/* RFPercentage */}
                <CCText fontSize={RFPercentage(1.2)} fontWeight="medium">
                  Home
                </CCText>
              </Pressable>
              <Pressable
                onPress={() => {
                  window.open('https://learn.circlechess.com/', '_blank');
                }}>
                {/* RFPercentage */}
                <CCText fontSize={RFPercentage(1.2)} fontWeight="medium">
                  Play & Learn
                </CCText>
              </Pressable>
              <Pressable
                onPress={() => {
                  window.open(
                    'https://api.whatsapp.com/send/?phone=919620006600&text=Hi',
                    '_blank',
                  );
                }}>
                {/* RFPercentage */}
                <CCText fontSize={RFPercentage(1.2)} fontWeight="medium">
                  Chess Assistant
                </CCText>
              </Pressable>
              <Pressable
                onPress={() => {
                  window.open('https://event.circlechess.com/', '_blank');
                }}>
                <CCText fontSize={RFPercentage(1.2)} fontWeight="medium">
                  Manage Events
                </CCText>
              </Pressable>
              <Pressable
                onPress={() => {
                  window.open(
                    'https://circlechess.com/events/tournaments',
                    '_blank',
                  );
                }}>
                <CCText fontSize={RFPercentage(1.2)} fontWeight="medium">
                  Tournaments
                </CCText>
              </Pressable>
              {/* <Pressable
                onPress={() => {
                  window.open('https://circlechess.com/prebooking', '_blank');
                }}>
                <CCText fontSize={RFPercentage(1.2)} fontWeight="medium">
                  Prebooking
                </CCText>
              </Pressable> */}
              <Pressable
                onPress={() => {
                  window.open('https://circlechess.com/blogs', '_blank');
                }}>
                <CCText fontSize={RFPercentage(1.3)} fontWeight="medium">
                  Blog
                </CCText>
              </Pressable>
              <Pressable
                onPress={() => {
                  window.open('https://circlechess.com/about', '_blank');
                }}>
                <CCText fontSize={RFPercentage(1.2)} fontWeight="medium">
                  About
                </CCText>
              </Pressable>
            </Box>
            <Box>
              <CCButton onPress={signUpHandler}>
                <CCText fontSize={RFPercentage(1.2)}>Login</CCText>
              </CCButton>
            </Box>
          </>
        }
      />
    </Box>
  );
}

export default CCHomeHeader;
