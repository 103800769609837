import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import React, {useCallback, useEffect, useState, useRef} from 'react';
import { FontSizes, hs, vs } from '@/Utils/Dimensions';
import { View } from 'react-native';
import { Modal, Text, Button } from 'react-native';
import Animated, {
    useSharedValue,
    useAnimatedStyle,
    interpolateColor,
    interpolate,
    withTiming,
} from 'react-native-reanimated';
import { styles } from './styles';
import challenge from "@/Assets/Images/png/challenge@3x.png";
import useAnimation from '@/Hooks/useAnimation';
import CCColors from '@/Utils/CCColors';
import { useSocket } from '@/Hooks/UseSocket';
import {
    useNavigation,
    useRoute,
  } from '@react-navigation/native';
import { setLocalStorage } from '@/Utils/CommonUtils';
import useGamePlayState from '@/Store/GamePlayStore';
import ChallengeRejected from '@/Screens/PlayChess/components/ChallengeRejected';

// const schema = require('/Users/nitu/Circlechess/cc-client-sdk/lib/schema.js');
const schema = require('/var/www/game-service/cc-client-sdk/lib/schema.js');

function ChallengeComponent(props) {
    const {username} = props;

    const [showChallengeRequest, setShowChallengeRequest] = useState(false);
    const [ChallengeOpponent, setChallengeOpponent] = useState('');
    const [ChallengeOpponentUsername, setChallengeOpponentUsername] = useState('');
    const [ChallengeMoveTimer, setChallengeMoveTimer] = useState('');
    const [ChallengeIncrement, setChallengeIncrement] = useState('');
    const [ChallengeVariantId, setChallengeVariantId] = useState('');
    const [ChallengePosition, setChallengePosition] = useState(
        'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
    );
    const [ChallengeWhite, setChallengeWhite] = useState('');

    const [gameDetails, setGameDetails] = useState('');
    const [gamePlayMode, setGamePlayMode] = useState('Play');
    const [tableId, setTableId] = useState('');

    const gameplayStore = useGamePlayState();

    

    const originalInfo = console.info;

    

    const navigation = useNavigation();
    // const route = useRoute();

    const socket = useSocket();
    useEffect(() => {
        if (challengeRequestCallback && socket) {
            socket.registerMessageType('challenge_game_request', schema.challengeGameMsgSchema);
            socket.registerMessageType('challenge_request_check', schema.challengeGameMsgSchema);
            socket.registerMessageHandler('challenge_request_check', function (socket, data) {
              challengeRequestCallback(socket, data);
            });
        }
    
        if (challengeCancelCallback && socket) {
            socket.registerMessageType('challenge_cancel', schema.challengeGameMsgSchema);
            socket.registerMessageHandler('challenge_cancel', function (socket, data) {
              challengeCancelCallback(socket, data);
            });
        }
        if (challengeExpiredCallback && socket) {
            socket.registerMessageType('challenge_request_expire', schema.challengeGameMsgSchema);
            socket.registerMessageHandler('challenge_request_expire', function (socket, data) {
              challengeExpiredCallback(socket, data);
            });
        }
        if (socket){
            socket.on('change_client_connection',function(data) {
                console.info("Change in client_connection on socketid :", socket.id);
                setTimeout(() =>{
                    gameplayStore.setShowChangeInClient(true);
                },5000);
                
            })
        }
    }, [socket]);
    

    function challengeExpiredCallback(socket, data) {
        console.info('...challenge_reject message data...', JSON.stringify(data));
        gameplayStore?.setShowChallengeExpire(true);
        setShowChallengeRequest(false);
    }

    function challengeCancelCallback(socket, data) {
        console.info('...challenge_cancel message data...', JSON.stringify(data));
        gameplayStore?.setShowChallengeCancel(true);
        setShowChallengeRequest(false);
    }

    

    function challengeRequestCallback(socket, data) {
        console.info('...challenge_request_check message data...', JSON.stringify(data));
        if (data.userId == username) {
          setShowChallengeRequest(true);
          setChallengeOpponent(data.opponent.split(':')[0]);
          setChallengeOpponentUsername(data.opponent.split(':')[1]);
          setChallengeVariantId(data.variantId);
          setChallengeMoveTimer(data.playerMoveTimer);
          setChallengeIncrement(data.moveTimeIncrement);
        //   game?.load(data?.gameState || 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1');
        //   if (
        //     game?.fen() !=
        //     'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
        //   ) {
        //     setPosition(game?.fen());
        //   }
        
          setChallengePosition(data?.gameState || 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1');
          setTableId(data.tableId);
          setChallengeWhite(data.white);
          setGamePlayMode(data.gamePlayMode);
          gameplayStore?.setShowEndGameModal(false);
          gameplayStore?.setShowChallengeCancel(false);
          gameplayStore?.setShowChallengeReject(false);
          gameplayStore?.setShowChallengeExpire(false);
          // setBlackPlayer(data.black);
        } // Show the challenge request modal
    }

    const handleChallengeResponse = async accepted => {
        let packet = {};
        packet.requestId = new Date().getTime().toString();
        packet.variantId = ChallengeVariantId;
        packet.userId = username;
        packet.opponent = ChallengeOpponent;
        packet.playerMoveTimer = ChallengeMoveTimer;
        packet.moveTimeIncrement = ChallengeIncrement;
        packet.gameState = ChallengePosition;
        packet.white = ChallengeWhite;
        packet.black = '';
        packet.gamePlayMode = gamePlayMode;
    
        // packet.tableId = tableId;
        if (accepted) {
            packet.type = 'challenge_accept_response';
            console.info('challenge accept response Packet = ', JSON.stringify(packet));
            await setLocalStorage('challengePacket', packet);
            
            navigation.navigate('PlayChess') 
            setTimeout(() => {
                socket.send_msg('challenge_game_request', packet);
            },800);   
        } else {
          packet.type = 'challenge_reject';
          console.info('challenge reject response Packet = ', JSON.stringify(packet));
          socket.send_msg('challenge_game_request', packet);
          
        }
        
       
        setShowChallengeRequest(false); // Close the Challenge request modal
    };

    const handle_challenge_reject = () => {
        gameplayStore?.setShowChallengeCancel(false);
        gameplayStore?.setShowChallengeExpire(false);
    };

    const [gemsAnimValue, setGemsAnimValue] = useAnimation({
        prop: 'backgroundColor',
        start: 'rgba(255, 255, 255, 0)',
        end: CCColors.Green,
        isColor: true,
    });



    return (
        <View >    
            <Modal
                animationType="none"
                transparent={true}
                visible={showChallengeRequest && !gameplayStore?.showChallengeCancel &&!gameplayStore?.showChallengeExpire}
                onRequestClose={() => {
                }}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <img src={challenge} style={{ width: hs(208), height: vs(208) }} />

                        <Animated.View style={[styles.progressBarWrapper, gemsAnimValue]}>
                            <View style={styles.progressBar}>
                                <View
                                    style={[
                                        styles.progressBarFilling,
                                        { width: '43%' },
                                    ]}
                                />
                            </View>
                        </Animated.View>

                        <CCText fontSize={FontSizes[16]} style={{ color: 'black' }}>{ChallengeOpponentUsername} has challenged you!</CCText>
                        <CCText fontSize={FontSizes[12]} style={{ color: 'black', marginTop: 16 }}>
                            {(ChallengeMoveTimer / 1000).toString() + ' + ' + (ChallengeIncrement / 1000).toString()} Rapid    |    Rated    |    You are {ChallengeWhite == username ? 'White' : 'Black'}
                        </CCText>

                        <Box flexDirection="row" style={{ marginTop: 32 }}>
                            <CCButton onPress={(e) => handleChallengeResponse(false)} style={{ marginRight: 8 }}>Reject</CCButton>
                            <CCButton onPress={(e) => handleChallengeResponse(true)} type="outline">Accept</CCButton>
                        </Box>
                    </View>
                </View>
            </Modal>

            <ChallengeRejected
            Visible={gameplayStore?.showChallengeExpire}
            message="The challenge has expired!"
            handle_close={handle_challenge_reject}
            />
            <ChallengeRejected
            Visible={gameplayStore?.showChallengeCancel}
            message="Challenge Cancelled!"
            handle_close={handle_challenge_reject}
            />
        </View>

    );
}

export default ChallengeComponent;
