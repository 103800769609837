import React, {useState} from 'react';
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import {FontSizes, hs, vs, wrapFail} from '@/Utils/Dimensions';
import {View, GestureResponderEvent, Dimensions} from 'react-native';
import {useRive, Layout, Fit, Alignment} from '@rive-app/react-canvas';
import random_challenge from '@/Assets/Animation/Play_random_opponent.riv';
import challenge_friend from '@/Assets/Animation/Challenge_a_friend.riv';
import library_position from '@/Assets/Animation/Practice_from_a_position.riv';
import play_caissa from '@/Assets/Animation/Play_against_Caissa_Bot.riv';
import styles from './styles';

const {width: SCREEN_WIDTH} = Dimensions.get('screen');

interface LearnTileProps {
  GameOption?: '0' | '1' | '2' | '3';
  gameOptionText?: string;
  icon?: any; // Update to specific type if known
  selected?: boolean;
  flex?: number; // Adjust type based on usage
  animate?: boolean;
  onClick?: (event: GestureResponderEvent) => void;
  onHover?: () => void; // Adjust if needed
  handleCustomConfigChange?: (selected: boolean) => void;
}

const LearnTile: React.FC<LearnTileProps> = ({
  GameOption,
  gameOptionText,
  icon,
  selected = false,
  flex,
  animate = false,
  onClick = () => {},
  onHover = () => {},
  handleCustomConfigChange = () => {},
}) => {
  const color = selected
    ? CCColors.Buttons.TimeControlBackground
    : CCColors.White;

  const borderWidth = selected ? 1 : 0.5;

  const {RiveComponent} = useRive({
    src:
      GameOption === '0'
        ? random_challenge
        : GameOption === '1'
        ? challenge_friend
        : GameOption === '2'
        ? library_position
        : play_caissa,
    layout: new Layout({
      fit: Fit.Cover,
      alignment: Alignment.TopCenter,
    }),
    autoplay: true,
    animations: GameOption === '3' ? 'Timeline 1' : 'Loop',
  });

  return (
    <Box
      flexDirection="column"
      onPress={onClick}
      style={[
        styles.container,
        {
          backgroundColor: color,
          borderColor: selected ? '#4D3F37' : '#22222220',
          borderWidth: borderWidth,
          flex: 1,
          // width: SCREEN_WIDTH > 1150 ? (wrapFail ? hs(200) : hs(200)) : hs(200),
        },
      ]}>
      <View style={styles.content}>
        {GameOption && (
          <View style={styles.riveContainer}>
            <RiveComponent />
          </View>
        )}
        <CCText
          style={styles.text}
          textAlign="center"
          fontWeight="thunder"
          fontSize={FontSizes[16]}>
          {gameOptionText}
        </CCText>
      </View>
    </Box>
  );
};

export default LearnTile;
