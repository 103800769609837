/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/react-in-jsx-scope */
import {Modal, TouchableWithoutFeedback, View} from 'react-native';
import {useState, useEffect, useCallback} from 'react';
import Spacer from '@/Atoms/Spacer';
import AntDesign from 'react-native-vector-icons/AntDesign';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import CCTextInput from '@/Atoms/CCTextInput';
import ConditionalRender from '@/Atoms/ConditionalRender';
import CCSelect from '@/Atoms/CCSelect';
import useAddOTBGameApi from '@/Hooks/GetOTBGameDetails/useAddOTBGameApi';
import CCColors from '@/Utils/CCColors';
import SlideModal from '@/Atoms/SlideModal';
import {styles} from './styles';
import Box from '@/Atoms/Box';
import ChipList from '@/Atoms/ChipList/ChipList';
import {CHESS_RESULTS, TIME_CLASS} from '@/Utils/Constants';
import CCDatePicker from '@/Atoms/CCDatePicker';
import {formatDateToYYYYMMDD} from '@/Utils/CommonUtils';
import CCAutoComplete from '@/Atoms/CCAutoComplete';
import useGetInputAutoCompletion from '@/Hooks/GrandMastersGamesApi/useGetInputAutoCompletion';
import useLoggedInUserStore from '@/Store/loggedInUserstore';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import {debounce} from 'lodash';

type AddModalProps = {
  gameDetails?: {
    date?: string;
    tournament?: string;
    playerName?: string;
    opponent?: string;
    round?: string;
    opponentRating?: string;
    timeClass?: string;
    color?: string;
    result?: string;
  };
  setGameDetails?: (value: {}) => void;
  setDisplayData?: (value: {}) => void;
  setVisible?: (value: boolean) => void;
  visible?: boolean;
  uuid?: string;
  submitCallback?: () => void;
};

const AddGameModal: React.FC<AddModalProps> = ({
  setDisplayData,
  submitCallback,
  setVisible,
  visible,
}) => {
  const [dateError, setDateError] = useState<string | null>(null);
  const [tournamentError, setTournamentError] = useState<string | null>(null);
  const [playerNameError, setPlayerNameError] = useState<string | null>(null);
  const [opponentNameError, setOpponentNameError] = useState<string | null>(
    null,
  );
  const [opponentRatingError, setOpponentRatingError] = useState<string | null>(
    null,
  );
  // const [playerRatingError, setPlayerRatingError] = useState(false);
  const [timeClassError, setTimeClassError] = useState<string | null>(null);
  const [colorError, setColorError] = useState<string | null>(null);
  const [resultError, setResultError] = useState<string | null>(null);
  const [round, setRound] = useState<string>('1');
  const [timeClass, setTimeClass] = useState<string>(null);
  const [color, setColor] = useState<string>(null);
  const {isMobileOrTab} = useMediaQuery();

  const [result, setResult] = useState<string>(null);
  const [uuid, setUuid] = useState();
  const [gameDetails, setGameDetails] = useState<any>({});
  const [tournamentName, setTournamentName] = useState<string>('');
  const [tournamentId, setTournamentId] = useState<string>('');
  const [tournamentData, setTournamentData] = useState<string[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [dropDownClose, setDropDownClose] = useLoggedInUserStore(state => [
    state.dropDownClose,
    state.setDropDownClose,
  ]);
  const [itemSelected, setItemSelected] = useState<boolean>(true);

  const handleOnSuccessAuto = useCallback(
    data => {
      if (data && Array.isArray(data.data)) {
        const tournamentItems = data.data.map(item => ({
          id: item.id,
          name: item.name,
        }));

        if (tournamentItems.length > 0) {
          setTournamentData(tournamentItems);
        } else {
          setTournamentData([]);
        }
        setDropDownClose(tournamentItems.length === 0);
      } else {
        setTournamentData([]);
        setDropDownClose(true);
      }
    },
    [setDropDownClose, setTournamentData],
  );

  const handleOnFailedAuto = error => {
    console.log('error', error);
  };

  const {mutate: getAutoComplete} = useGetInputAutoCompletion(
    handleOnSuccessAuto,
    handleOnFailedAuto,
  );

  const debounceFetchTournament = useCallback(
    debounce(text => {
      if (text) {
        getAutoComplete({name: text});
      }
    }, 500),
    [getAutoComplete],
  );

  const handleTournamentChange = text => {
    setTournamentName(text);
    setItemSelected(false);
    setDropDownClose(false);
    debounceFetchTournament(text);
  };

  useEffect(() => {
    if (isFocused && tournamentName && !itemSelected) {
      debounceFetchTournament(tournamentName);
    }
    return () => debounceFetchTournament.cancel();
  }, [isFocused, tournamentName, itemSelected, debounceFetchTournament]);

  const Focus = () => {
    setItemSelected(false);
    handleTournamentChange('');
    setIsFocused(true);
    setDropDownClose(false);
  };

  const Blur = () => {
    setTimeout(() => {
      if (itemSelected) {
        setIsFocused(false);
        setTournamentData([]);
        setDropDownClose(true);
      }
    }, 150);
  };

  const validate = gameDetails => {
    let isValid = true;
    if (!gameDetails?.date) {
      setDateError('Date is required');
      isValid = false;
    } else {
      setDateError(null);
    }

    if (!tournamentName) {
      setTournamentError('Tournament is required');
      isValid = false;
    } else {
      setTournamentError(false);
    }

    // if (!gameDetails?.playerName) {
    //   setPlayerNameError('Player Name is required');
    //   isValid = false;
    // } else {
    //   setPlayerNameError(false);
    // }

    if (!gameDetails?.opponent) {
      setOpponentNameError('Opponent Name is required');
      isValid = false;
    } else {
      setOpponentNameError(false);
    }

    if (!gameDetails?.opponentRating) {
      setOpponentRatingError('Opponent Rating is required');
      isValid = false;
    } else {
      setOpponentRatingError(false);
    }

    if (!timeClass) {
      setTimeClassError('Time Class is required');
      isValid = false;
    } else {
      setTimeClassError(false);
    }

    if (!color) {
      setColorError('Color is required');
      isValid = false;
    } else {
      setColorError(false);
    }

    if (!result) {
      setResultError('Result is required');
      isValid = false;
    } else {
      setResultError(false);
    }

    return isValid;
  };

  const {mutate: addOTBGame} = useAddOTBGameApi(
    data => {
      const newGame = {
        sms_sent_timestamp: gameDetails?.date,
        tournament_id: tournamentId || null,
        tournament_name: tournamentName,
        // player_name: gameDetails?.playerName,
        round_id: gameDetails?.round,
        opponent_name: gameDetails?.opponent,
        opponent_fide_rating: gameDetails?.opponentRating,
        time_class: timeClass,
        round_color: gameDetails?.color,
        round_result:
          gameDetails?.result === 'Won'
            ? 1
            : gameDetails?.result === 'Loss'
            ? 0
            : 0.5,
        game_uuid: data.game_uuid, // Assuming you have a way to generate a new UUID
      };
      submitCallback?.();
      setVisible(false);

      // setDisplayData(prevData => ({
      //   ...prevData,
      //   data: [newGame, ...prevData.data],
      // }));

      // setUuid(data.game_uuid);
    },
    error => {
      console.log('error while adding game', error);
    },
  );

  const handleSubmit = () => {
    const newGame = {
      sms_sent_timestamp: gameDetails?.date,
      tournament_id: tournamentId || null,
      tournament_name: tournamentName,
      // player_name: gameDetails?.playerName,
      round_id: gameDetails?.round || 1,
      opponent_name: gameDetails?.opponent,
      opponent_fide_rating: gameDetails?.opponentRating,
      time_class: timeClass,
      round_color: gameDetails?.color,
      // player_rating: gameDetails?.playerRating,
      round_result:
        gameDetails?.result === 'Win'
          ? 1
          : gameDetails?.result === 'Loss'
          ? 0
          : -1,
      game_uuid: uuid, // Assuming you have a way to generate a new UUID
      timeClass: timeClass || 'classical',
    };

    if (!validate(gameDetails)) {
      return;
    }

    addOTBGame(newGame);
  };

  const handleSelectChange = e => {
    setRound(e);
    setGameDetails(prev => {
      return {
        ...prev,
        round: e,
      };
    });
  };

  return (
    <SlideModal
      visible={visible}
      type="side"
      modalHeaderTitle="Add Game Form"
      // animationType="slide"
      onClose={() => setVisible?.(false)}
      transparent={true}
      onRequestClose={() => setVisible?.(false)}>
      {/* <View
          style={{
            flex: 1,
            backgroundColor: CCColors.Modal.Background,
            justifyContent: 'center',
            alignItems: 'center',
          }}> */}
      <View
        style={{
          width: '100%',
          height: '90%',
          // justifyContent: 'center',
          // marginLeft: 'auto',
          // marginRight: 'auto',
          // marginTop: '2%',
          overflow: 'auto',
          padding: 20,
          // borderRadius: 10,
        }}>
        {/* <CCText fontSize={16} textAlign="center">
            Add Game Form
          </CCText> */}
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <CCText fontWeight="bold" style={styles.inputLabelStyle}>
            Date:
          </CCText>
        </View>
        <CCDatePicker
          onChange={text => {
            setGameDetails(prevState => ({
              ...prevState,
              date: formatDateToYYYYMMDD(text),
            }));
          }}
          value={gameDetails?.date}
        />
        {/* <CCTextInput
          autoFocus
          staticPostfix={
            <AntDesign
              name="calendar"
              size={22}
              // color={CCColors.White}
            />
          }
          placeholder="YYYY-MM-DD"
          onChangeText={text =>
            setGameDetails(prevState => ({
              ...prevState,
              date: text,
            }))
          }
          style={{
            width: '100%',
            // ,
          }}
          error={{flag: !!dateError, msg: dateError || ''}}
          // label="Date"
          fontSize={14}
          placeholderTextColor={CCColors.Grey.Grey}
        /> */}

        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <CCText fontWeight="bold" style={styles.inputLabelStyle}>
            Tournament:
          </CCText>
          {/* {tournamentError && (
              <CCText style={{color: CCColors.Error}}>{tournamentError}</CCText>
            )} */}
        </View>
        <View style={{zIndex: 100, position: 'relative'}}>
          <CCAutoComplete
            id={10}
            listHeight={200}
            placeholder="Tournament"
            value={tournamentName}
            onChangeText={handleTournamentChange}
            handleFocus={Focus}
            handleBlur={Blur}
            onKeyPress={() => setItemSelected(true)}
            dropDownClose={dropDownClose}
            data={tournamentData}
            onPressOnItem={item => {
              setItemSelected(true);
              setTournamentId(item.item.id);
              setTournamentName(item.item.name);
              setTournamentData([]);
              setDropDownClose(true);
            }}
          />
        </View>

        {/* <CCTextInput
          placeholder="Tournament"
          onChangeText={text =>
            setGameDetails(prevState => ({
              ...prevState,
              tournament: text,
            }))
          }
          error={{flag: !!tournamentError, msg: tournamentError || ''}}
          style={{width: '100%'}}
          fontSize={14}
          placeholderTextColor={CCColors.Grey.Grey}
        /> */}

        {/* <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <CCText fontWeight="bold" style={styles.inputLabelStyle}>
            Player Name:
          </CCText>
          {playerNameError && (
            <CCText style={{color: CCColors.Error}}>{playerNameError}</CCText>
          )}
        </View>

        <CCTextInput
          placeholder="Player name"
          onChangeText={text =>
            setGameDetails(prevState => ({
              ...prevState,
              playerName: text,
            }))
          }
          error={{flag: !!playerNameError, msg: playerNameError || ''}}
          style={{width: '100%'}}
          fontSize={14}
          placeholderTextColor={CCColors.Grey.Grey}
        />
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <CCText fontWeight="bold" style={styles.inputLabelStyle}>
            Player Rating:
          </CCText>
          {opponentRatingError && (
            <CCText style={{color: CCColors.Error}}>
              {opponentRatingError}
            </CCText>
          )}
        </View>
        <CCTextInput
          placeholder="Eg. 1000"
          onChangeText={text =>
            setGameDetails(prevState => ({
              ...prevState,
              playerRating: text,
            }))
          }
          keyboardType="numeric"
          error={{
            flag: !!playerRatingError,
            msg: playerRatingError || '',
          }}
          style={{width: '100%'}}
          fontSize={14}
          placeholderTextColor={CCColors.Grey.Grey}
        /> */}
        <CCText fontWeight="bold" style={styles.inputLabelStyle}>
          Round:
        </CCText>
        <CCSelect
          // ccSelectHeight={30}
          style={{
            backgroundColor: CCColors.Grey.BG100,

            borderColor: '#D3D3D3',
            borderWidth: 1,
          }}
          label=""
          selectedValue={round}
          onValueChange={e => {
            handleSelectChange(e);
          }}
          items={[
            {label: '1', value: '1'},
            {label: '2', value: '2'},
            {label: '3', value: '3'},
            {label: '4', value: '4'},
            {label: '5', value: '5'},
            {label: '6', value: '6'},
            {label: '7', value: '7'},
            {label: '8', value: '8'},
            {label: '9', value: '9'},
            {label: '10', value: '10'},
            {label: '11', value: '11'},
          ]}
        />

        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <CCText fontWeight="bold" style={styles.inputLabelStyle}>
            Opponent:
          </CCText>
          {/* {opponentNameError && (
              <CCText style={{color: CCColors.Error}}>
                {opponentNameError}
              </CCText>
            )} */}
        </View>
        <CCTextInput
          placeholder="Opponent name"
          onChangeText={text =>
            setGameDetails(prevState => ({
              ...prevState,
              opponent: text,
            }))
          }
          error={{flag: !!opponentNameError, msg: opponentNameError || ''}}
          style={{width: '100%'}}
          fontSize={14}
          placeholderTextColor={CCColors.Grey.Grey}
        />
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <CCText fontWeight="bold" style={styles.inputLabelStyle}>
            Opponent Rating:
          </CCText>
          {/* {opponentRatingError && (
              <CCText style={{color: CCColors.Error}}>
                {opponentRatingError}
              </CCText>
            )} */}
        </View>
        <CCTextInput
          placeholder="Eg. 1000"
          onChangeText={text =>
            setGameDetails(prevState => ({
              ...prevState,
              opponentRating: text,
            }))
          }
          error={{
            flag: !!opponentRatingError,
            msg: opponentRatingError || '',
          }}
          style={{width: '100%'}}
          fontSize={14}
          placeholderTextColor={CCColors.Grey.Grey}
        />

        <CCText fontWeight="bold" style={styles.inputLabelStyle}>
          Time Class:
        </CCText>
        <Box>
          <ChipList
            chipBackgroundColor={CCColors.Primary.lightBrown}
            onChange={element => {
              setTimeClass(element?.value);
            }}
            options={TIME_CLASS}
            value={timeClass}
            error={{flag: !!timeClassError, msg: timeClassError || ''}}
            // horizontal={true}
          />
        </Box>
        {/* <CCSelect
          ccSelectHeight={30}
          style={{
            backgroundColor: CCColors.Grey.BG100,
            borderColor: CCColors.CCSelect.BorderColor,
          }}
          label=""
          selectedValue={timeClass}
          onValueChange={e => {
            setTimeClass(e);
            setGameDetails(prev => {
              return {
                ...prev,
                timeClass: e,
              };
            });
          }}
          items={[
            {label: 'classical', value: 'classical'},
            {label: 'rapid', value: 'rapid'},
            {label: 'blentz', value: 'blentz'},
          ]}
        /> */}
        <CCText fontWeight="bold" style={styles.inputLabelStyle}>
          Color:
        </CCText>
        <Box>
          <ChipList
            chipBackgroundColor={CCColors.Primary.lightBrown}
            onChange={e => {
              setColor(e?.value);
              setGameDetails(prev => {
                return {
                  ...prev,
                  color: e?.value,
                };
              });
            }}
            options={[
              {key: 'Black', value: 'Black'},
              {key: 'White', value: 'White'},
            ]}
            value={color}
            error={{flag: !!colorError, msg: colorError || ''}}
            // horizontal={true}
          />
        </Box>
        {/* <CCSelect
          ccSelectHeight={30}
          style={{
            backgroundColor: CCColors.Grey.BG100,
            borderColor: CCColors.CCSelect.BorderColor,
          }}
          label=""
          selectedValue={color}
          onValueChange={e => {
            setColor(e);
            setGameDetails(prev => {
              return {
                ...prev,
                color: e,
              };
            });
          }}
          items={[
            {label: 'white', value: 'white'},
            {label: 'black', value: 'black'},
          ]}
        /> */}
        <CCText fontWeight="bold" style={styles.inputLabelStyle}>
          Result:
        </CCText>
        <Box>
          <ChipList
            chipBackgroundColor={CCColors.Primary.lightBrown}
            onChange={e => {
              setResult(e?.value);
              setGameDetails(prev => {
                return {
                  ...prev,
                  result: e?.value,
                };
              });
            }}
            options={CHESS_RESULTS}
            value={result}
            error={{flag: !!resultError, msg: resultError || ''}}
            // horizontal={true}
          />
        </Box>
        {/* <CCSelect
          ccSelectHeight={30}
          style={{
            backgroundColor: CCColors.Grey.BG100,
            borderColor: CCColors.CCSelect.BorderColor,
            marginTop: 1,
          }}
          label=""
          selectedValue={result}
          onValueChange={e => {
            setResult(e);
            setGameDetails(prev => {
              return {
                ...prev,
                result: e,
              };
            });
          }}
          items={[
            {label: 'Draw', value: 'Draw'},
            {label: 'Win', value: 'Win'},
            {label: 'Loss', value: 'Loss'},
          ]}
        /> */}
      </View>
      <Box style={styles.footerButton}>
        <CCButton onPress={handleSubmit}>Submit</CCButton>
      </Box>
    </SlideModal>
  );
};

export default AddGameModal;
