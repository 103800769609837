import {PuzzleVerifyResponse} from './types';
import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useVerifyPuzzlesApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<
    PuzzleVerifyResponse,
    Error,
    {puzzleId: string; move: string; type?: string}
  >(
    async ({puzzleId, move, type}) => {
      console.log('PuzzleID = ', puzzleId);
      console.log('Move = ', move);

      let q = {id: puzzleId, move: move};
      if (type) {
        q.type = type;
      }
      const objString = '?' + new URLSearchParams(q).toString();

      console.log('Query params = ', objString);

      const response = await ChessRestClient.get(
        `learning/verify_puzzle/${objString}`,
      );
      console.log(response.data);
      return response.data;
    },
    {
      onSuccess: data => {
        console.log('Puzzles Response = ', data);
        if (handleOnSuccess) handleOnSuccess(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
