import ChessRestClient from '@/Api/ChessRestClient';
import {SetAvatarResponse} from './types';
import {useMutation} from '@tanstack/react-query';

export default function useAvatar(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<
    SetAvatarResponse,
    Error,
    {
      name: string;
      dp: string;
    }
  >(
    async avatarInfo => {
      const {name, dp} = avatarInfo;
      console.log('Avatar Name = ', name, ' DP = ', dp);
      const response = await ChessRestClient.post(
        'v1/update_avatar_details/',
        {avatarInfo: {name, icon: dp}},
      );
      return response.data;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess && data.success) handleOnSuccess(data);
        else if (handleOnError) handleOnError(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
