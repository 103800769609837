import React, {useState, useRef, useEffect} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  GestureResponderEvent,
} from 'react-native';

type MenuOption = {
  label: string;
  onClick: () => void;
};

type ContextMenuProps = {
  menuOptions: MenuOption[];
  children: React.ReactNode;
};

const ContextMenu: React.FC<ContextMenuProps> = ({menuOptions, children}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState<{x: number; y: number}>({
    x: 0,
    y: 0,
  });

  const menuRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();

      // Adjust position relative to the wrapper element
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top + 50;

      setMenuPosition({x, y});
      setMenuVisible(true);
    }
  };

  const handleLongPress = (event: GestureResponderEvent) => {
    const {pageX, pageY} = event.nativeEvent;

    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();

      // Adjust position relative to the wrapper element
      const x = pageX - rect.left;
      const y = pageY - rect.top + 50;

      setMenuPosition({x, y});
      setMenuVisible(true);
    }
  };

  const handleOptionClick = (option: string) => {
    console.log('Selected option:', option);
    setMenuVisible(false);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setMenuVisible(false);
    }
  };

  useEffect(() => {
    if (menuVisible) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [menuVisible]);

  // Prevent the menu from going out of bounds
  const adjustedPosition = (menuWidth: number, menuHeight: number) => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();

      let x = menuPosition.x;
      let y = menuPosition.y;

      if (x + menuWidth > rect.width) {
        x = rect.width - menuWidth - 10; // Adjust to fit inside the wrapper
      }
      if (y + menuHeight > rect.height) {
        y = rect.height - menuHeight + 30; // Adjust to fit inside the wrapper
      }

      return {x, y};
    }

    return menuPosition;
  };

  return (
    <div
      ref={wrapperRef}
      onContextMenu={event => handleContextMenu(event)}
      style={{position: 'relative'}}>
      <View onLongPress={handleLongPress} style={{position: 'relative'}}>
        {children}
      </View>

      {menuVisible && (
        <div
          ref={menuRef}
          style={{
            ...styles.menu,
            top: adjustedPosition(150, 100).y,
            left: adjustedPosition(150, 100).x,
          }}>
          {menuOptions.map((option, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => {
                option.onClick();
                setMenuVisible(false);
              }}>
              <Text style={styles.menuOption}>{option.label}</Text>
            </TouchableOpacity>
          ))}
        </div>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  menu: {
    position: 'absolute',
    backgroundColor: '#fff',
    borderRadius: 4,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    borderWidth: 1,
    borderColor: '#ccc',
    zIndex: 1000,
    width: 150, // Define the menu width
    maxHeight: 100, // Define the menu height
    overflow: 'hidden',
  },
  menuOption: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    cursor: 'pointer',
    fontSize: 14,
    color: '#333',
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
});

export default ContextMenu;
