import React, {useEffect, useState} from 'react';
import Box from '@/Atoms/Box';
import CCPackageTable from '@/Atoms/CCPackageTable';
import {useNavigation} from '@react-navigation/native';
import CCText from '@/Atoms/CCText';
import CCButton from '@/Atoms/CCButton';
import {FontSizes, vs} from '@/Utils/Dimensions';
import useGetAssessmentApi from '@/Hooks/AssessmentApi/useGetAssessmentsApi';
import Spacer from '@/Atoms/Spacer';
import useAssessmentApi from '@/Hooks/AssessmentApi/useAssessmentApi';
import {Alert, Image, Modal, StyleSheet} from 'react-native';
import ChipList from '@/Atoms/ChipList/ChipList';
import {convertToReadableString} from '@/Utils/CommonUtils';
import zeroStateImage from '../../../../Assets/Images/Svgs/zero_assessments_state.svg';

const AssessmentList: React.FC = props => {
  const [isPending, setIsPending] = useState(true);
  const {handleGameIDSelection} = props;
  const [zeroState, setZeroState] = useState(false);

  const refreshData = (data: any) => {
    const formattedData = [];
    var isAssessmentPending = false;
    if (data.data?.data?.length > 0) {
      for (var i = 0; i < data.data?.data?.length; i++) {
        const itemValues = [];
        const item = data.data?.data?.[i];
        if (item.fields.status === 0 && !isAssessmentPending) {
          isAssessmentPending = true;
        }
        itemValues.push({
          name: (
            <Box style={{width: '100%'}}>
              <CCText textAlign="center">
                {item.fields.status === 0 ? 'Pending' : 'Complete'}
              </CCText>
            </Box>
          ),
          type: 'text',
        });
        itemValues.push({
          name: (
            <Box style={{width: '100%'}}>
              <CCText textAlign="center">0.1</CCText>
            </Box>
          ),
        });
        itemValues.push({
          name: (
            <Box style={{width: '100%'}}>
              <CCText textAlign="center">
                {new Date(item.fields.created_time).toDateString()}
              </CCText>
            </Box>
          ),
        });
        itemValues.push({
          name: (
            <Box style={{width: '100%'}}>
              <CCText textAlign="center">
                {convertToReadableString(item.fields.time_class)}
              </CCText>
            </Box>
          ),
        });
        itemValues.push({
          name: (
            <Box style={{width: '100%'}}>
              <CCText textAlign="center">25</CCText>
            </Box>
          ),
        });
        itemValues.push({
          name:
            item.fields.status === 1 ? (
              <Box style={{width: '100%'}}>
                <CCButton
                  onPress={() =>
                    handleGameIDSelection(item.fields.assessment_id)
                  }
                  style={{borderRadius: vs(20), height: vs(32)}}>
                  View Report
                </CCButton>
              </Box>
            ) : (
              <></>
            ),
        });
        formattedData.push(itemValues);
      }

      setIsPending(isAssessmentPending);
      setTableData(formattedData);
    } else {
      // Show zero state
      setZeroState(true);
    }
  };

  const onLoadAssessments = (data: any) => {
    refreshData(data);
  };

  const handleRunAssessmentResponse = (data: any) => {
    console.log('data: ', data);
    if (data.data.success) {
      refreshData(data);
    }
  };

  const {
    mutate: getAssessments,
    data: assessmentData,
    error: assessmentError,
  } = useGetAssessmentApi(onLoadAssessments, error => {
    console.log('Error Data = ', error);
  });

  const {
    mutate: runAssessment,
    data: successResponse,
    error: failureResponse,
  } = useAssessmentApi(handleRunAssessmentResponse, () => {});

  const [tableData, setTableData] = useState<
    Array<
      Array<{
        name: string;
        type: 'text' | 'link' | 'dropdown';
        url?: string;
        options?: string[];
      }>
    >
  >([]);
  const [tabHeaders, setTabHeaders] = useState<string[]>([
    'Status',
    'Version',
    'Assessment Date',
    'Time Control',
    'Number of games',
    '',
  ]);

  const navigation = useNavigation();
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    getAssessments();
  }, []);

  const [types, setTypes] = useState([
    {key: 'Classical', value: 'Classical'},
    {key: 'Rapid', value: 'Rapid'},
    {key: 'Blitz', value: 'Blitz'},
  ]);

  const [typeSelected, selectType] = useState({
    key: 'Classical',
    value: 'Classical',
  });

  return (
    <Box flexDirection="column" flex={1}>
      <Modal
        animationType="none"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert('Modal has been closed.');
          setModalVisible(!modalVisible);
        }}>
        <Box style={styles.centeredView}>
          <Box style={styles.modalView} flexDirection="column">
            <CCText fontSize={FontSizes[18]} style={styles.modalText}>
              Select the Time Control to select games for assessments
            </CCText>
            <CCText style={styles.modalText}>
              Triggering this will initiate assessment for the last 25 games of
              the selected time control.
            </CCText>
            <CCText style={styles.modalText}>
              This will take some time. Visit back this page after some time to
              check the report.
            </CCText>
            <Spacer spacing={vs(24)} />
            <ChipList
              onChange={element => {
                selectType(element);
              }}
              options={types}
              value={typeSelected}
              horizontal={true}
            />
            <Spacer spacing={vs(24)} />
            <Box flexDirection="row">
              <CCButton
                onPress={e => {
                  runAssessment(typeSelected.value);
                  setModalVisible(!modalVisible);
                }}
                type="outline"
                style={{
                  marginHorizontal: vs(4),
                  borderRadius: vs(24),
                }}>
                Run Assessment
              </CCButton>
              <CCButton
                onPress={e => {
                  setModalVisible(!modalVisible);
                }}
                style={{
                  marginHorizontal: vs(4),
                  borderRadius: vs(24),
                }}>
                Cancel
              </CCButton>
            </Box>
          </Box>
        </Box>
      </Modal>
      {!zeroState && (
        <>
          <Box flexDirection="row">
            <Box flex={0.5}>
              <CCText fontSize={FontSizes[24]} style={{marginLeft: vs(16)}}>
                Past Assessments
              </CCText>
              <CCText
                style={{
                  marginLeft: vs(16),
                  marginTop: vs(12),
                  lineHeight: vs(16),
                }}>
                Below are the assessments you have run previously. Click on one
                of the rows to view the detailed report.
              </CCText>
            </Box>
            <Box flex={0.5} style={{width: '100%'}}>
              <CCButton
                type="outline"
                onPress={e => setModalVisible(true)}
                disabled={isPending}
                style={{
                  alignSelf: 'flex-end',
                  marginRight: vs(16),
                  width: vs(240),
                  borderRadius: vs(20),
                }}>
                New Assessment
              </CCButton>
            </Box>
          </Box>
          <Spacer spacing={vs(24)} />
          <Box flexDirection="row" style={{height: '100%'}}>
            <CCPackageTable headers={tabHeaders} tableData={tableData} />
          </Box>
        </>
      )}
      {zeroState && (
        <>
          <Box flexDirection="column">
            <Box flexDirection="column">
              <Spacer spacing={vs(48)} />
              <Image
                source={zeroStateImage}
                style={{width: vs(130), height: vs(130), margin: 'auto'}}
              />
              <Spacer spacing={vs(48)} />
              <CCText fontSize={FontSizes[24]} style={{textAlign: 'center'}}>
                You are yet to run an assessment
              </CCText>
              <CCText style={{textAlign: 'center', lineHeight: vs(32)}}>
                Click on the link below to begin your first assessment!
              </CCText>
            </Box>
            <Spacer spacing={vs(48)} />
            <Box style={{width: '100%'}}>
              <CCButton
                type="outline"
                onPress={e => setModalVisible(true)}
                disabled={false}
                style={{
                  alignSelf: 'center',
                  marginRight: vs(16),
                  width: vs(240),
                  borderRadius: vs(20),
                }}>
                Run your first Assessment
              </CCButton>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
});

export default AssessmentList;
