import CCColors from '@/Utils/CCColors';
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginVertical: 4,
  },
  checkbox: {
    width: 14,
    height: 14,
    borderWidth: 1,
    borderColor: CCColors.Primary.Dark,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
  },
  checkboxMobile: {
    width: 14,
    height: 14,
  },
  checkboxActive: {
    backgroundColor: CCColors.Primary.Default, // Or any color for active state
    borderColor: CCColors.Primary.Default,
  },
  checkboxCheck: {
    color: '#fff', // Or any color that shows check mark clearly
  },
  checkboxLabel: {
    fontSize: 16,
  },
  checkboxLabelMobile: {
    fontSize: 12,
  },
  // ...other styles you may need
});

export default styles;
