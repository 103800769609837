import Box from '../../Atoms/Box';
import React from 'react';
import CCText from '../../Atoms/CCText';
import CCColors from '../../Utils/CCColors';
import CCButton from '../../Atoms/CCButton';
import {FontSizes, vs} from '../../Utils/Dimensions';
import Spacer from '../../Atoms/Spacer';
import img1 from '../../Assets/Images/Svgs/coming_soon.png';
import img2 from '../../Assets/Images/Svgs/coming_soon_2.png';
import {Text} from 'react-native';
import useGAPageView from '@/navigators/useGAPageView';
import {ScreenName} from '@/navigators/StackConstants';

function ComingSoon(props) {
  // useGAPageView(ScreenName.ComingSoon);
  useGAPageView(ScreenName.ComingSoon);

  const {feature, description, cover_img} = props;
  return (
    <Box>
      <Spacer spacing={vs(24)} />
      {cover_img == 1 && (
        <img
          src={img1}
          alt="Circlechess"
          style={{width: 400, alignSelf: 'center', borderRadius: vs(8)}}
        />
      )}
      {cover_img == 2 && (
        <img
          src={img2}
          alt="Circlechess"
          style={{width: 600, alignSelf: 'center', borderRadius: vs(8)}}
        />
      )}
      <Spacer spacing={vs(24)} />
      <Text
        type="primary"
        style={{
          fontWeight: 'bold',
          fontSize: FontSizes[40],
          margin: 'auto',
          color: '#3dab9e',
        }}>
        🪄 Coming Soon 🪀
      </Text>
      <Spacer spacing={vs(24)} />
      <Text
        type="primary"
        style={{
          width: 500,
          fontSize: FontSizes[20],
          margin: 'auto',
          color: '#4d3f37',
          textAlign: 'center',
        }}>
        {feature}
      </Text>
    </Box>
  );
}

export default ComingSoon;
