import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useAssistedGameMoveAnalysisApi( handleOnSuccess?: (data: any) => void,
handleOnError?: (data: any) => void,) {
    return useMutation(
      async ({
        move,
        fen, 
        last_fen, 
        color, 
        player, 
        userid, 
        uuid, 
        move_index
      }: {
        userid: string;
        move: string;
        fen: string;
        color: string;
        last_fen: string;
        player: string;
        uuid: string;
        move_index: number;
      }) => {
        const response = await ChessRestClient.post('game/start_analyzing_move', {
            userid: userid,
            move: move,
            fen: fen,
            color: color,
            last_fen: last_fen,
            player: player,
            uuid: uuid,
            move_index: move_index
        });
        console.log("assisted game move_analysis api respoinse is :", response);

        return response.data;
      },
      {
        onSuccess: data => {
          handleOnSuccess?.(data);
        },
        onError: data => {
          handleOnError?.(data);
        },
      },
    );
  }