import {Chessboard} from 'react-chessboard';
import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {Chess} from 'chess.js';
import useSound from 'use-sound';
import {View, Text} from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import {TouchableOpacity} from 'react-native-gesture-handler';
import Tooltip from '@/Atoms/Tooltip';
import WhitePawn from '@/Assets/ChessPieces/White-Pawn.svg';
import WhiteRook from '@/Assets/ChessPieces/White-Rook.svg';
import WhiteQueen from '@/Assets/ChessPieces/White-Queen.svg';
import WhiteKnight from '@/Assets/ChessPieces/White-Knight.svg';
import WhiteKing from '@/Assets/ChessPieces/White-King.svg';
import WhiteBishop from '@/Assets/ChessPieces/White-Bishop.svg';
import BlackPawn from '@/Assets/ChessPieces/Black-Pawn.svg';
import BlackRook from '@/Assets/ChessPieces/Black-Rook.svg';
import BlackQueen from '@/Assets/ChessPieces/Black-Queen.svg';
import BlackKnight from '@/Assets/ChessPieces/Black-Knight.svg';
import BlackKing from '@/Assets/ChessPieces/Black-King.svg';
import BlackBishop from '@/Assets/ChessPieces/Black-Bishop.svg';
import CCColors from '@/Utils/CCColors';
import {getLocalStorage} from '@/Utils/CommonUtils';

export const pieceImages = {
  wP: WhitePawn,
  wR: WhiteRook,
  wQ: WhiteQueen,
  wN: WhiteKnight,
  wK: WhiteKing,
  wB: WhiteBishop,
  bP: BlackPawn,
  bR: BlackRook,
  bQ: BlackQueen,
  bN: BlackKnight,
  bK: BlackKing,
  bB: BlackBishop,
};

// Create a function to generate the piece theme using the mapping
const createPieceThemeEmpty = pieceImages => {
  return Object.keys(pieceImages).reduce((theme, piece) => {
    theme[piece] = ({isDragging, squareWidth}) => <div></div>;
    return theme;
  }, {});
};

const createPieceTheme = pieceImages => {
  return Object.keys(pieceImages).reduce((theme, piece) => {
    theme[piece] = ({isDragging, squareWidth}) => (
      <img
        src={pieceImages[piece]}
        alt={piece}
        style={{
          height: squareWidth,
        }}
      />
    );
    return theme;
  }, {});
};

const ChessboardComponentInner = ({
  disabled,
  position,
  onMove,
  lastMove,
  chess,
  kingInCheckSquare,
  boardOrientation,
  selectedSquare,
  possibleMoves,
  onSquareSelected,
  setSelectedSquare,
  setPossibleMoves,
  handleSquareClick,
  squareColor,
  onPieceDrop,
  onDrop,
  piecesVisible,
  ...rest
}) => {
  const [arrows, setArrows] = useState([]);
  const [dragStartSquare, setDragStartSquare] = useState([]);
  const [color, setColor] = useState('');

  const handleMouseDown = useCallback(event => {
    if (event.button === 2) {
      // Right mouse button
      const square = getSquareFromMouseEvent(event);
      if (square) {
        setDragStartSquare(square);
      }
      event.preventDefault(); // Prevent the default context menu
    }
  }, []);

  const handleMouseMove = useCallback(
    event => {
      if (!dragStartSquare) return;

      const currentSquare = getSquareFromMouseEvent(event);
      // Update some temporary state or UI to show the in-progress arrow if needed
    },
    [dragStartSquare],
  );

  const handleMouseUp = useCallback(
    event => {
      if (dragStartSquare) {
        const endSquare = getSquareFromMouseEvent(event);
        if (endSquare) {
          setArrows(prevArrows => [
            ...prevArrows,
            {from: dragStartSquare, to: endSquare},
          ]);
        }
      }
      setDragStartSquare(null);
    },
    [dragStartSquare],
  );

  useEffect(() => {
    const chessboardEl = document.getElementById('BoardWithCustomArrows');
    if (chessboardEl) {
      chessboardEl.addEventListener('mousedown', handleMouseDown);
      chessboardEl.addEventListener('mousemove', handleMouseMove);
      chessboardEl.addEventListener('mouseup', handleMouseUp);

      // Prevent context menu on right click
      chessboardEl.oncontextmenu = e => e.preventDefault();
    }

    return () => {
      if (chessboardEl) {
        chessboardEl.removeEventListener('mousedown', handleMouseDown);
        chessboardEl.removeEventListener('mousemove', handleMouseMove);
        chessboardEl.removeEventListener('mouseup', handleMouseUp);

        chessboardEl.oncontextmenu = null;
      }
    };
  }, [handleMouseDown, handleMouseMove, handleMouseUp]);

  // const onSquareClick = useCallback(
  //   square => {
  //     if (disabled) return;
  //     handleSquareClick(square);
  //   },
  //   [selectedSquare, possibleMoves, onMove, onSquareSelected, disabled],
  // );

  const onSquareClick = useCallback(
    square => {
      if (disabled) return;

      setSelectedSquare(square);

      const moves = chess
        .moves({
          square: square,
          verbose: true,
        })
        .map(move => move.to);

      setPossibleMoves(moves);
      handleSquareClick(square);
    },
    [handleSquareClick],
  );

  useEffect(() => {
    if (selectedSquare) {
      squareStyles[selectedSquare] = {
        backgroundColor: `${squareColor}`,
      };
    }
    // if (possibleMoves) {
    //   possibleMoves?.forEach(move => {
    //     squareStyles[move] = {backgroundColor: 'rgba(20, 85, 30, 0.3)'};
    //   });
    // }
  }, [selectedSquare, possibleMoves]);

  useEffect(() => {
    const fetchColor = async () => {
      const savedColor = await getLocalStorage('color');
      setColor(savedColor);
    };

    fetchColor();
  }, []);
  let lightColor = CCColors.ChessBoard.Light;
  let darkColor = CCColors.ChessBoard.Dark;
  let startColor = CCColors.ChessBoard.StartColor;
  let endColor = CCColors.ChessBoard.EndColor;
  if (color) {
    if (color === 'green-white') {
      lightColor = CCColors.ChessBoard.GreenLightColor;
      darkColor = CCColors.ChessBoard.GreenDarkColor;
      startColor = CCColors.ChessBoard.GreenStartColor;
      endColor = CCColors.ChessBoard.GreenEndColor;
    } else if (color === 'blue-white') {
      lightColor = CCColors.ChessBoard.GreenLightColor;
      darkColor = CCColors.ChessBoard.BlueDarkColor;
      startColor = CCColors.ChessBoard.BlueStartColor;
      endColor = CCColors.ChessBoard.BlueEndColor;
    }
  }

  const squareStyles = {};

  ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'].forEach(r =>
    ['1', '2', '3', '4', '5', '6', '7', '8'].forEach(c => {
      const isDarkSquare = (r.charCodeAt(0) + parseInt(c)) % 2 === 0;
      squareStyles[r + c] = {
        border: '2px solid transparent',
        boxSizing: 'border-box',
        backgroundColor: isDarkSquare ? darkColor : lightColor,
      };
    }),
  );

  // if (lastMove?.from && isValidSquare(lastMove.from)) {
  //   squareStyles[lastMove.from].backgroundColor = 'rgb(120, 113, 64)';
  // }

  // if (lastMove?.to && isValidSquare(lastMove.to)) {
  //   squareStyles[lastMove.to].backgroundColor = 'rgb(196, 181, 57)';
  //   squareStyles[lastMove.to].border = '2px solid rgb(120, 113, 64)';
  // }

  // if (kingInCheckSquare && isValidSquare(kingInCheckSquare)) {
  //   squareStyles[kingInCheckSquare].background =
  //     'radial-gradient(transparent 60%, #EB5757)';
  // }

  if (selectedSquare && isValidSquare(selectedSquare)) {
    squareStyles[selectedSquare].backgroundColor = ``;
  }

  const pieceTheme = piecesVisible
    ? createPieceTheme(pieceImages)
    : createPieceThemeEmpty(pieceImages);
  return (
    <Chessboard
      position={position}
      arePiecesDraggable={disabled ? false : true}
      customBoardStyle={disabled ? {pointerEvents: 'none'} : {}}
      showBoardNotation={disabled ? false : true}
      promotionDialogVariant="modal"
      customPieces={pieceTheme}
      customDarkSquareStyle={{backgroundColor: '#D1AB41'}}
      customLightSquareStyle={{backgroundColor: '#EDD899'}}
      onPieceDrop={(source, target, piece) => {
        onDrop(source, target, piece);
      }} //I added
      onSquareClick={onSquareClick}
      customSquareStyles={squareStyles}
      boardOrientation={boardOrientation}
      id="BoardWithCustomArrows"
      customArrows={arrows}
      customArrowColor={'rgb(245, 192, 0)'}
      {...rest}
    />
  );
};

function isValidSquare(square) {
  return /^[a-h][1-8]$/.test(square);
}

function didCaptureOccur(lastFen, currentFen) {
  const getPieceCount = fen =>
    fen?.split(' ')?.[0]?.replace(/[\d/]/g, '')?.length;

  const lastCount = getPieceCount(lastFen);
  const currentCount = getPieceCount(currentFen);

  return currentCount < lastCount;
}

function findMoveFromFens(lastMoveFen, currentMoveFen) {
  // const chessLast = new Chess(lastMoveFen);
  // const chessCurrent = new Chess(currentMoveFen);
  // Generate all legal moves from the last position
  // const moves = chessLast.moves({verbose: true});
  // for (let i = 0; i < moves.length; i++) {
  //   chessLast.move(moves[i]);
  //   if (chessLast.fen() === currentMoveFen) {
  //     // If the FENs match, we found our move
  //     return {from: moves[i].from, to: moves[i].to};
  //   }
  //   chessLast.undo(); // Undo the move to try the next one
  // }
  // return null; // If no move leads to the current FEN, return null
}

const ChessGame = ({
  children,
  position = '8/8/8/8/8/8/8/8 w - - 0 1',
  lastMoveFen,
  handleNewFen,
  handleSquareClick,
  onPieceDrop,
  type,
  square,
  color,
  getFen,
  onDrop: customOnDrop,
  removeSquare,
  clearBoard,
  draggedObject = {source: 'a1', target: 'b1', piece: 'p'},
  ...rest
}) => {
  // const moveAudio = new Audio(moveSound);
  const [kingInCheckSquare, setKingInCheckSquare] = useState<string | null>(
    null,
  );

  const [moveSound] = useSound(
    'https://images.chesscomfiles.com/chess-themes/sounds/_MP3_/default/move-self.mp3',
  );

  const [captureSound] = useSound(
    'https://images.chesscomfiles.com/chess-themes/sounds/_MP3_/default/capture.mp3',
  );
  const [castlingSound] = useSound(
    'https://images.chesscomfiles.com/chess-themes/sounds/_MP3_/default/castle.mp3',
  );
  const [checkSound] = useSound(
    'https://images.chesscomfiles.com/chess-themes/sounds/_MP3_/default/move-check.mp3',
  );

  const chess = useMemo(() => {
    let innerChess = new Chess(position);
    innerChess.load(position, {
      skipValidation: true,
    });
    if (type && color && square) {
      // if (innerChess.get(square).type !== 'k') {
      innerChess.put({type: type, color: color}, square);
      // }
    } else if (removeSquare) {
      // if (innerChess.get(removeSquare).type !== 'k') {
      innerChess.remove(removeSquare);
      // }
    }

    if (draggedObject && innerChess.get(draggedObject?.source).type === 'k') {
      innerChess.remove(draggedObject?.source);
      innerChess.put(
        {
          type: draggedObject?.piece[1].toLowerCase(),
          color: draggedObject?.piece[0],
        },
        draggedObject?.target,
      );
    }

    if (clearBoard) {
      innerChess.clear();
    }
    return innerChess;
  }, [
    position,
    rest?.resetChessboard,
    onDrop,
    handleSquareClick,
    onPieceDrop,
    removeSquare,
    customOnDrop,
  ]);
  // chess.clear();
  const [fen, setFen] = useState(chess?.fen());
  const [lastMove, setLastMove] = useState(null);
  const [over, setOver] = useState('');
  const [isPromotionModalVisible, setIsPromotionModalVisible] = useState(false);
  const [promotionData, setPromotionData] = useState(null); // To store the source and target squares

  const [selectedSquare, setSelectedSquare] = useState(null);
  const [possibleMoves, setPossibleMoves] = useState([]);

  useEffect(() => {
    setFen(chess.fen());

    getFen(chess.fen());
  }, [chess, type, color, square]);

  useEffect(() => {
    chess.clear();
  }, []);

  const onSquareSelected = useCallback(
    square => {
      setSelectedSquare(square);
      const moves = chess
        .moves({
          square: square,
          verbose: true,
        })
        .map(move => move.to);
      setPossibleMoves(moves);
    },
    [chess],
  );

  useEffect(() => {
    if (lastMoveFen) {
      chess?.load?.(lastMoveFen, {skipValidation: true});
      const timeoutId = setTimeout(() => {
        chess?.load(position, {skipValidation: true}); // After delay, set the board to positionFen
        setFen(chess?.fen());
        const moveDetails = findMoveFromFens?.(lastMoveFen, position);
        setLastMove(moveDetails);

        // Check if a capture occurred and play the capture sound
        if (moveDetails && didCaptureOccur(lastMoveFen, position)) {
          captureSound();
        } else if (chess?.inCheck?.()) {
          checkSound(); // Play check sound
        } else {
          moveSound();
        }
      }, 300);

      return () => clearTimeout(timeoutId); // Clear the timeout if the component unmounts
    } else {
      setLastMove({from: '', to: ''});
      chess?.load(
        position === 'start'
          ? 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
          : position,
        {skipValidation: true},
      );
      setFen(chess?.fen());
    }
  }, [position, lastMoveFen, rest?.resetChessboard]);

  function onDrop(sourceSquare, targetSquare, promotedPiece) {
    chess.put({type: 'k', color: 'w'}, 'a1'); // shorthand
    // return true;
    // try {
    //   const moveData = {
    //     from: sourceSquare,
    //     to: targetSquare,
    //     promotion: promotedPiece?.[1]?.toLowerCase() || 'q',
    //   };

    //   const allMoves = chess.moves({verbose: true});

    //   const isValidMove = allMoves.some(
    //     move => move.from === sourceSquare && move.to === targetSquare,
    //   );

    //   if (!isValidMove) return false;

    //   const shallStop = rest?.onPieceDropExtension?.(
    //     moveData.from,
    //     moveData.to,
    //     moveData.promotion,
    //   );

    //   if (shallStop) return false;

    // const move = makeAMove(moveData);
    //   if (!move) return false;

    //   if (move !== null) setLastMove({from: sourceSquare, to: targetSquare});

    //   return move !== null;
    // } catch (e) {
    //   console.error(e); // Log the error
    //   return false;
    // }
  }

  const makeAMove = useCallback(
    move => {
      try {
        const lastFen = chess.fen();
        const result = chess?.move(move);
        const newFen = chess.fen();
        if (result) {
          // Constructing move data
          const moveData = {
            from: result.from,
            to: result.to,
            piece: result.piece,
            san: result.san, // Standard Algebraic Notation of the move
            newFen: newFen, // New FEN string after the move
            lastFen: lastFen,
          };

          // Calling handleNewFen with the new FEN string and move data
          handleNewFen?.(moveData);
        }

        setFen(newFen);

        if (result && result.captured) {
          captureSound();
        } else if (chess?.inCheck?.()) {
          checkSound(); // Play check sound
        } else {
          moveSound();
        }
        if (chess?.isGameOver()) {
          if (chess?.isCheckmate()) {
            setOver(
              `Checkmate! ${chess?.turn() === 'w' ? 'black' : 'white'} wins!`,
            );
          } else if (chess?.isDraw()) {
            setOver('Draw');
          } else {
            setOver('Game over');
          }
        }

        return result;
      } catch (e) {
        return null;
      }
    },
    [chess, moveSound, captureSound, castlingSound, checkSound, handleNewFen],
  );

  // useEffect(() => {
  //   if (chess?.inCheck?.()) {
  //     const kingPosition = findKingPosition(chess?.fen(), chess?.turn());
  //     setKingInCheckSquare(kingPosition);
  //   } else {
  //     setKingInCheckSquare(null);
  //   }
  // }, [fen]);

  function findKingPosition(fen, turn) {
    const rows = fen?.split(' ')?.[0]?.split('/');
    const king = turn === 'w' ? 'K' : 'k';

    for (let row = 0; row < rows?.length; row++) {
      let col = 0;
      for (const char of rows[row]) {
        if (char === king) {
          return String.fromCharCode(97 + col) + (8 - row);
        } else if (isNaN(char)) {
          col++;
        } else {
          col += parseInt(char, 10);
        }
      }
    }
    return null;
  }

  return (
    <>
      {children({
        position: fen,
        onMove: onDrop,
        lastMove: lastMove,
        selectedSquare: selectedSquare,
        possibleMoves: possibleMoves,
        onSquareSelected: onSquareSelected,
        chess: chess,
        kingInCheckSquare: kingInCheckSquare,
        setSelectedSquare: setSelectedSquare,
        setPossibleMoves: setPossibleMoves,
        ...rest,
      })}
    </>
  );
};

const BlindFoldChess = props => {
  const {
    handleSquareClick = () => {},
    squareColor,
    hideChessBoard,
    setHideChessBoard,
    selectedValue,
    onPieceDrag = () => {},
    onPieceDrop = () => {},
    onDrop,
    piecesVisible,
    type,
    color,
    square,
    getFen = () => {},
    removeSquare,
    draggedObject,
    clearBoard,
  } = props;

  const [position, setPosition] = useState();

  const hideChessBoardHandler = () => {
    setHideChessBoard(false);
  };

  const handlePieceDrop = square => {
    // Update the position state with the dragged piece
    const [piece, targetSquare] = square.split('-');
    const rows = position.split('/');
    const row = 8 - parseInt(targetSquare[1]);
    const col = targetSquare.charCodeAt(0) - 97;
    rows[row] = replaceAt(rows[row], col, piece.charAt(0));
    const newPosition = rows.join('/');
    setPosition(newPosition);

    // Notify the parent component about the updated position
    onPieceDrop(newPosition);
  };

  const replaceAt = (str, index, replacement) => {
    return (
      str.substr(0, index) +
      replacement +
      str.substr(index + replacement.length)
    );
  };

  return (
    <View>
      {!hideChessBoard && (
        <ChessGame
          position={position}
          onPieceDrop={handlePieceDrop}
          type={type}
          square={square}
          color={color}
          getFen={getFen}
          onDrop={onDrop}
          removeSquare={removeSquare}
          draggedObject={draggedObject}
          clearBoard={clearBoard}
          {...props}>
          {({position: p, onMove, lastMove, ...rest}) => (
            <ChessboardComponentInner
              {...rest}
              disabled={props.disabled}
              position={p}
              onMove={onMove}
              lastMove={lastMove}
              handleSquareClick={handleSquareClick}
              squareColor={squareColor}
              onPieceDrop={onPieceDrop}
              onDrop={onDrop}
              piecesVisible={piecesVisible}
            />
          )}
        </ChessGame>
      )}
      {selectedValue !== 'Square Trainer' && (
        <View style={{position: 'absolute', top: 5, right: 5}}>
          {hideChessBoard && (
            <TouchableOpacity onPress={hideChessBoardHandler}>
              <Feather name="eye" size={20} />
            </TouchableOpacity>
          )}
        </View>
      )}
    </View>
  );
};

export default BlindFoldChess;
