import React, {useEffect, useRef, useState} from 'react';
import {ScreenName} from '@/navigators/StackConstants';
import AssessmentList from './components/AssessmentList';
import AssessmentDetails from './components/AssessmentDetails';
import useGAPageView from '@/navigators/useGAPageView';

const AssessmentsComponent: React.FC = () => {
  useGAPageView(ScreenName.Assessments);

  const [assessmentId, setAssessmentId] = useState<string>('');

  const handleGameIDSelection = (assessment_id: string) => {
    console.log('Game ID = ', assessment_id);
    if (assessment_id) setAssessmentId(assessment_id);
  };

  const handleBack = () => {
    setAssessmentId('');
  };

  return (
    <>
      {!assessmentId && (
        <AssessmentList handleGameIDSelection={handleGameIDSelection} />
      )}
      {assessmentId && (
        <AssessmentDetails
          handleBack={handleBack}
          assessmentId={assessmentId}
        />
      )}
    </>
  );
};

export default AssessmentsComponent;
