import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useSyncGamesProgressApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<any, Error, void>(
    async () => {
      console.log('Checking onboarding game import status ..');
      const response = await ChessRestClient.get('game/v1/learning/get_onboarding_state/');

      return response;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) handleOnSuccess(data);
        else if (handleOnError) handleOnError(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
