import React from 'react';
import {View, StyleSheet} from 'react-native';
import CCAccordion from '@/Atoms/CCAccordion'; // Adjust the path as necessary
import CCText from '@/Atoms/CCText';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import {isPhone} from '@/Utils/Dimensions';

const FAQData = [
  {
    id: 1,
    question: 'How can I upgrade my current plan to a Aspiring Champions plan?',
    answer: 'You can change your plan by clicking on upgrade button any time.',
  },
  {
    id: 2,
    question: 'What are the benefits of the premium plan?',
    answer:
      'The premium plan offers advanced features, including unlimited access to exclusive content, priority support, and more customization options.',
  },
  // {
  //   id: 3,
  //   question: 'How do I cancel my subscription?',
  //   answer:
  //     "To cancel your subscription, go to the account settings page, select 'Subscription', and click on 'Cancel Subscription'. Follow the instructions to complete the process.",
  // },
  // {
  //   id: 4,
  //   question: 'Is there a free trial available?',
  //   answer:
  //     'Yes, we offer a 14-day free trial for new users. You can sign up for the trial on our website and explore all the premium features without any charges.',
  // },
  {
    id: 5,
    question: 'Can I switch between plans at any time?',
    answer:
      'Yes, you can switch between plans at any time by clicking on the subscription icon in the side menu. The changes will take effect immediately.',
  },
];

const FAQSections = FAQData.map(faq => {
  return {
    title: faq.question,
    renderContent: () => {
      return (
        <CCText fontSize={14} textAlign="justify">
          {faq.answer}
        </CCText>
      );
    },
  };
});

const FAQScreen = () => {
  const {isMobileOrTab} = useMediaQuery();
  return (
    <View style={styles.container}>
      <CCText
        fontSize={24}
        fontWeight="medium"
        textAlign="center"
        style={{margin: !isMobileOrTab ? 40 : 20}}>
        FAQ’s
      </CCText>
      <CCAccordion sections={FAQSections} fontWeight="500" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: !isPhone ? 16 : 4,
  },
});

export default FAQScreen;
