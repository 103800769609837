import {getLocalStorage} from '@/Utils/CommonUtils';
import Environment from '../../../environment';
// import EventSource from 'react-native-event-source';

type AnalysisResponse = {
  game_id: number;
  status?: string;
  puzzle_summary?: any;
  success?: boolean;
  game_moves?: any[];
  error?: string;
  players: any;
  game_reviewed_by_user: boolean;
  game_result: string;
  game_engine_analyzed: boolean;
  total_moves: number;
  move_index: number;
  analysis: any;
};

// import {Observable} from 'rxjs';

// export function streamGameAnalysis(
//   gameId: number,
// ): Observable<AnalysisResponse> {
//   return new Observable(observer => {
//     const url = `${Environment.CHESS_API_URL}/game/start_analyzing_game?game_id=${gameId}`;
//     const headers = {
//       'Content-Type': 'application/json',
//       'api-key': Environment.API_KEY,
//     };

//     const eventSource = new EventSource(url, {headers: headers});

//     eventSource.onmessage = event => {
//       const parsedData: AnalysisResponse = JSON.parse(event.data);
//       observer.next(parsedData); // Emit new data
//     };

//     eventSource.onerror = (error: Event) => {
//       console.log(error, 'error');
//       observer.error(error);
//       eventSource.close();
//     };

//     eventSource.onopen = () => {
//       // Handle any setup logic if needed
//     };

//     eventSource.onclose = () => {
//       observer.complete(); // Complete the observable when connection is closed
//     };

//     return () => {
//       eventSource.close(); // Cleanup: close the event source when observer is unsubscribed
//     };
//   });
// }
import {Observable} from 'rxjs';

let activeEventSource: EventSource | null = null;

export function streamGameAnalysis(
  gameId: number,
): Observable<AnalysisResponse> {
  return new Observable(observer => {
    // If there's already an active event stream, close it
    if (activeEventSource) {
      activeEventSource.close();
      activeEventSource = null;
    }

    (async () => {
      const token = await getLocalStorage('access_token');
      const url = `${Environment.ANALYZE_GAME_API_URL}?game_uuid=${gameId}&access_token=${token}`;

      activeEventSource = new EventSource(url);

      activeEventSource.onmessage = event => {
        const parsedData: AnalysisResponse = JSON.parse(event.data);
        observer.next(parsedData); // Emit new data

        if (
          parsedData.status === 'error' ||
          parsedData.status === 'Game already analyzed'
        ) {
          activeEventSource?.close();
          activeEventSource = null;
        }
      };

      activeEventSource.onerror = () => {
        observer.error(new Error('EventSource error'));
        activeEventSource?.close();
        activeEventSource = null;
      };

      return () => {
        activeEventSource?.close();
        activeEventSource = null;
      };
    })();
  });
}
