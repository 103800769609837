import Box from "@/Atoms/Box";
import CCText from "@/Atoms/CCText";
import ChipList from "@/Atoms/ChipList/ChipList";
import { ChipType } from "@/Atoms/ChipList/types";
import CCColors from "@/Utils/CCColors";
import { FontSizes, hs, vs } from "@/Utils/Dimensions";
import { useState } from "react";
import { TextInput, View } from "react-native";
import tick from "@/Assets/Images/icons/tick.png";
import white from "@/Assets/Images/png/white_color.png";
import black from "@/Assets/Images/png/black_color.png";
import random from "@/Assets/Images/png/random_color.png";
import { useEffect } from "react";
import { isPhone} from '@/Utils/Dimensions';



{/* TODO: Implement Custom configurations component in CustomConfig */ }
{/* This should implement Custom FEN Starting position, Rated game, Play as white / black */ }
function CustomConfig(props) {
    // Fen to start the game with
    const [fen, setFen] = useState('');

    // True / False whether the game is rated or not
    const [rated, setRated] = useState(true);

    const { handleFen, handleColor, selectedGameOption } = props;


    // 1: White
    // 0: Black
    // -1: Random
    const [color, setColor] = useState(1);

    const [colorOptions, setColorOptions] = useState<ChipType[]>([{ key: 'White', value: 1 }, { key: 'Black', value: 0 }, { key: 'Random', value: -1 }]);
    const [selectedColor, setSelectedColor] = useState<ChipType>({ value: -1, key: 'Random' });

    useEffect(() => {
        handleColor(selectedColor.value);
        console.log("SelectedColor is: ",selectedColor );
    }, [selectedColor]);

    return (<Box style={{ alignItems: 'center', padding: 8, zIndex:-10 }}>
       {(selectedGameOption == '1' || selectedGameOption == '3') && <Box>
            <CCText style={{ color: '#26232280', textAlign: 'left',zIndex:-10  }} fontSize={FontSizes[10]}>Want to start playing from a custom position? Enter FEN.</CCText>
            <TextInput
            onChangeText={(e) => handleFen(e)}
            style={{
                backgroundColor: 'white',
                borderColor: '#22222220',
                borderWidth: 1,
                width: isPhone ? '90vw' : '40vw',
                height: 48,
                borderRadius: 8,
                padding: 12,
                placeholderTextColor: '#26232240',
                zIndex:-10
            }} placeholder="Enter FEN"></TextInput>
        </Box>}
        {selectedGameOption !== '0' && <Box flex={6} flexDirection="row" style={{ width: isPhone ? '90vw' : '40vw', marginTop: 8, zIndex:-10 }}>
            { colorChip(1, 'White', white, (1 === selectedColor.value), () => setSelectedColor({value: 1, key: 'White'}) ) }
            { colorChip(0, 'Black', black, (0 === selectedColor.value), () => setSelectedColor({value: 0, key: 'Black'}) ) }
            { colorChip(-1, 'Random', random, (-1 === selectedColor.value), () => setSelectedColor({value: -1, key: 'Random'}) ) }
        </Box>}
        {selectedGameOption == '0' && <CCText style={{ color: '#26232280', textAlign: 'left',zIndex:-10  }} fontSize={FontSizes[10]}>Not available for random opponent search </CCText> }
    </Box>)
}

const colorChip = (value: Number, display: string, icon: any, selected: boolean, onClick: any) => {
    const borderWidth = 1;
    const color = selected ? CCColors.Buttons.TimeControlBackground : CCColors.White;
    const circleColor = selected ? CCColors.White : CCColors.Primary.lightBrown;

    return (<Box flex={2} flexDirection="column" style={{ 
        margin: 2, 
        justifyContent: 'center', 
        height: 48,
        backgroundColor: color, 
        borderColor: selected ? '#4D3F37' : '#22222220', 
        borderWidth: borderWidth, 
        borderRadius: 8,
        zIndex: -10 
    }}
    onPress={onClick}>
        <View style={{ flexDirection: 'row', alignItems: 'center', zIndex:-15 }}>
            <View style={{ marginHorizontal: 12, zIndex:-10 }}><img src={icon} style={{ width: isPhone ? 12: 18 }}/></View>
            <CCText textAlign="center" fontSize={FontSizes[12]}>{display}</CCText>
            <View style={{ width: 22, height: 22, justifyContent: 'center', alignItems: 'center', borderRadius: 11, backgroundColor: circleColor, position: 'absolute', right: 4}}>
            { selected && (<img src={tick} style={{ width: '70%', zIndex:-10 }}/>) }
        </View>

        </View>
    </Box>);
}

export default CustomConfig;