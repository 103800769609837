import create from 'zustand';
import { persist } from 'zustand/middleware';
import {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
} from '@/Utils/CommonUtils';

interface GamePlayState {
  showChallengeReject: boolean;
  showChallengeExpire: boolean;
  showChallengeCancel: boolean;
  showEndGameModal: boolean;
  showChangeInClient: boolean; // Add another state variable for flexibility
}

interface GamePlayActions {
  setShowChallengeReject: (val: boolean) => void;
  setShowChallengeExpire: (val: boolean) => void;
  setShowChallengeCancel: (val: boolean) => void;
  setShowEndGameModal: (val: boolean) => void;
  setShowChangeInClient: (val: boolean) => void;
}

const useGamePlayState = create(
  persist<GamePlayState & GamePlayActions>(
    (set) => ({
      showChallengeReject: false,
      showChallengeExpire: false,
      showChallengeCancel: false,
      showEndGameModal: false,
      showChangeInClient: false,


      setShowChallengeReject: (val) => set({ showChallengeReject: val }),
      setShowChallengeExpire: (val) => set({ showChallengeExpire: val }),
      setShowChallengeCancel: (val) => set({ showChallengeCancel: val }),
      setShowEndGameModal: (val) => set({ showEndGameModal: val }),
      setShowChangeInClient: (val) => set({ showChangeInClient: val }),
    }),
    {
      name: 'game-play-state', // Name for the persisted store
      getStorage: () => ({
        getItem: async (name) => {
          const result = await getLocalStorage(name);
          return result ? JSON.parse(result) : null;
        },
        setItem: async (name, value) => {
          await setLocalStorage(name, JSON.stringify(value));
        },
        removeItem: async (name) => {
          await removeLocalStorage(name);
        },
      }),
    },
  ),
);

export default useGamePlayState;
