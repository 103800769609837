import create from 'zustand';

type AlertModalState = {
  open: boolean;
  header: string;
  bodyTxt: string;
  buttonText: string;
  handleConfirmation: () => void;
  handleClose: () => void;
  openModal: (
    props: Partial<Omit<AlertModalState, 'open' | 'openModal' | 'closeModal'>>,
  ) => void;
  closeModal: () => void;
};

const useAlertModalStore = create<AlertModalState>(set => ({
  open: false,
  header: 'Confirm',
  bodyTxt: '',
  buttonText: 'OK',
  handleConfirmation: () => {},
  handleClose: () => set({open: false}),
  openModal: ({header, bodyTxt, buttonText, handleConfirmation, handleClose}) =>
    set({
      open: true,
      header: header || 'Confirm',
      bodyTxt: bodyTxt || '',
      buttonText: buttonText || 'OK',
      handleConfirmation: handleConfirmation || (() => {}),
      handleClose: handleClose || (() => set({open: false})),
    }),
  closeModal: () => set({open: false}),
}));

export default useAlertModalStore;
