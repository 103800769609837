// https://sp.circlechess.com/v1/generate_payment_links

import {useMutation} from '@tanstack/react-query';
import ChessPaymentRestClient from '@/Api/ChessPaymentRestClient';

interface PaymentApiParams {
  customer_id: any;
  mobile_number: any;
  email: string;
  subscription_plan_id: number;
}

export default function useGeneratePaymentApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (error: any) => void,
) {
  const mutation = useMutation<any, Error, PaymentApiParams>(
    async ({customer_id, mobile_number, email, subscription_plan_id}) => {
      const body = {
        customer_id: customer_id,
        mobile_number: mobile_number,
        email: email,
        subscription_plan_id: subscription_plan_id,
      };
      const response = await ChessPaymentRestClient.post(
        'v1/generate_payment_links',
        body,
      );

      return response;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) handleOnSuccess(data);
      },
      onError: error => {
        if (handleOnError) handleOnError(error);
      },
    },
  );

  return {
    ...mutation,
  };
}
