import create from 'zustand';
import {persist} from 'zustand/middleware';
import {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
} from '@/Utils/CommonUtils';

interface userPlanState {
  activePlan: string;
  choosenPlan: string;
  tenure: string;
  planDetails?: any;
}

interface userPlanActions {
  setActivePlan: (plan: string) => void;
  setChoosenPlan: (plan: string) => void;
  setTenure: (val: string) => void;
  setPlanDetails?: (val: any) => void;
}

interface nonPersistedState {
  subscriptionModalOpen: boolean;
}

interface nonPersistedActions {
  setSubscriptionModalOpen: (open: boolean) => void;
}

const useNonPersistedUserPlanStore = create<
  userPlanState & userPlanActions & nonPersistedState & nonPersistedActions
>(set => ({
  activePlan: '',
  choosenPlan: '',
  tenure: 'yearly',
  subscriptionModalOpen: false,

  setActivePlan: plan => set({activePlan: plan}),
  setChoosenPlan: plan => set({choosenPlan: plan}),
  setTenure: val => set({tenure: val}),
  setSubscriptionModalOpen: open => set({subscriptionModalOpen: open}),
}));

const useUserPlanStore = create(
  persist<userPlanState & userPlanActions>(
    set => ({
      activePlan: '',
      choosenPlan: '',
      tenure: 'yearly',
      planDetails: null,

      setActivePlan: plan => set({activePlan: plan}),
      setChoosenPlan: plan => set({choosenPlan: plan}),
      setTenure: val => set({tenure: val}),
      setPlanDetails: val => set({planDetails: val}),
    }),
    {
      name: 'user-plan-state', // Name for the persisted store
      getStorage: () => ({
        getItem: async name => {
          const result = await getLocalStorage(name);
          return result ? JSON.parse(result) : null;
        },
        setItem: async (name, value) => {
          await setLocalStorage(name, JSON.stringify(value));
        },
        removeItem: async name => {
          await removeLocalStorage(name);
        },
      }), // Use localStorage for persistence
    },
  ),
);

export {useUserPlanStore, useNonPersistedUserPlanStore};
