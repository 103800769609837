import {useMutation} from '@tanstack/react-query';
import {getLocalStorage} from '@/Utils/CommonUtils';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useMarkFavouriteGameApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<any, Error, void>(
    async (data: any) => {
      const response = await ChessRestClient.post('game/v1/learning/toggle_game_as_favourite/', data);

      return response.data;
    },
    {
      onSuccess: (data) => {
        console.log('On Success = ', data)
        if (handleOnSuccess) handleOnSuccess(data);
        else if (handleOnError) handleOnError(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
