import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {GestureHandlerRootView} from 'react-native-gesture-handler';

import ApplicationNavigator from './navigators/Application';
// import { PersistGate } from "redux-persist/lib/integration/react";
import {Platform} from 'react-native';
// import { Provider } from "react-redux";
import React from 'react';
import {Helmet} from 'react-helmet';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import ErrorBoundary from './ErrorBoundry';
// import ReactGA from 'react-ga';

// const TRACKING_ID = 'G-7YV4SDFYHV';

// ReactGA.initialize(TRACKING_ID);

// import { persistor, store } from "./store";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => (
  <GestureHandlerRootView style={{flex: 1}}>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <Helmet>
          <title>
            Join Caissa: Your Personalized AI Platform for Chess Playing,
            Learning, and Tracking
          </title>
          <meta
            name="description"
            content="Sign up for Caissa to experience a unified chess platform tailored to your needs. Improve your game with AI-driven insights and track your progress. Join our community of chess enthusiasts today and elevate your skills!"
          />
          <meta
            property="og:title"
            content="Join Caissa: Your Personalized AI Platform for Chess Playing, Learning, and Tracking"
          />
          <meta
            property="og:description"
            content="Sign up for Caissa to experience a unified chess platform tailored to your needs. Improve your game with AI-driven insights and track your progress. Join our community of chess enthusiasts today and elevate your skills!"
          />
          <meta
            name="keywords"
            content="Caissa, chess, online, play, learn, free, analyze, AI-coach, personalized, improve, discipline, puzzles, track"
            data-react-helmet="true"
          />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href={`/`} />
          <meta property="og:url" content={`https://learn.circlechess/home`} />
        </Helmet>
        <ApplicationNavigator />
      </ErrorBoundary>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  </GestureHandlerRootView>
);

export default App;
