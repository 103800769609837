import React, {useRef, useState} from 'react';
import ReactPlayer from 'react-player';
import Entypo from 'react-native-vector-icons/Entypo';
import CCColors from '@/Utils/CCColors';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Box from '../Box';
import {View} from 'react-native';
import CCText from '../CCText';
import CCButton from '../CCButton';

const CustomVideoPlayer = ({selectedVideo}) => {
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [showControls, setShowControls] = useState(false);

  // Toggle play/pause state
  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  // Handle volume changes
  const handleVolumeChange = e => {
    setVolume(parseFloat(e.target.value));
  };

  // Handle progress bar changes (scrubbing)
  const handleProgressChange = e => {
    const newProgress = parseFloat(e.target.value);
    setProgress(newProgress);
    playerRef.current.seekTo(newProgress);
  };

  // Format time in mm:ss format
  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <View
      style={styles.container}
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}>
      <View
        style={{...styles.controls, opacity: showControls || !playing ? 1 : 0}}>
        <CCButton onPress={handlePlayPause}>
          {playing ? (
            <Ionicons color={CCColors.Black} name={'pause'} size={20} />
          ) : (
            <Ionicons color={CCColors.Black} name={'play'} size={20} />
          )}
        </CCButton>

        {/* Video Progress (timeline) */}
        <input
          type="range"
          min={0}
          max={1}
          step="0.01"
          value={progress}
          onChange={handleProgressChange}
          style={styles.timeline}
        />

        {/* Volume Control */}
        <View style={styles.volumeContainer}>
          <CCText color={CCColors?.White}>Volume</CCText>
          <input
            type="range"
            min={0}
            max={1}
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
            style={styles.volumeSlider}
          />
        </View>

        {/* Timestamp */}
        <View style={styles.timestamp}>
          <CCText color={CCColors?.White}>{formatTime(playedSeconds)}</CCText> /{' '}
          <CCText color={CCColors?.White}>{formatTime(duration)}</CCText>
        </View>
      </View>
      <ReactPlayer
        ref={playerRef}
        url={selectedVideo.s3_path}
        playing={playing}
        volume={volume}
        width="100%"
        height="100%"
        controls={false}
        onProgress={state => {
          setProgress(state.played);
          setPlayedSeconds(state.playedSeconds);
        }}
        onDuration={duration => setDuration(duration)}
        onContextMenu={e => e.preventDefault()}
      />

      {/* Custom controls */}
    </View>
  );
};

// Custom styles
const styles = {
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: '#000',
  },
  controls: {
    position: 'absolute',
    flexDirection: 'row',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    transition: 'opacity 0.3s ease-in-out',
    opacity: 0,
    // pointerEvents: 'none', // Hide the controls when not hovered
    zIndex: 3,
  },
  playPauseButton: {
    backgroundColor: '#fff',
    border: 'none',
    padding: '10px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  timeline: {
    width: '60%',
    margin: '0 10px',
    cursor: 'pointer',
  },
  volumeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '10px',
  },
  volumeLabel: {
    color: '#fff',
    marginBottom: '5px',
    fontSize: '12px',
  },
  volumeSlider: {
    width: '100px',
    cursor: 'pointer',
  },
  timestamp: {
    color: '#fff',
    fontSize: '12px',
    flexDirection: 'row',
  },
};

export default CustomVideoPlayer;
