import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import * as React from 'react';
import {Image, ImageBackground, StyleSheet, Dimensions} from 'react-native';
import Carousel from 'react-native-reanimated-carousel';
import chessboardPartPlaceholder from '@/Assets/Images/png/chessboard_part_placeholder.png';
import squareBackgroundTopRight from '@/Assets/Images/png/square_background.png';

const {width: screenWidth} = Dimensions.get('window');

export const PlayAndLearnCarousel = () => {
  const data = [
    {
      image: chessboardPartPlaceholder,
      title: 'Game Play',
      description: 'Swift Gameplay with library of custom positions!',
    },
    {
      image: chessboardPartPlaceholder,
      title: 'Game Review',
      description: 'AI-Enabled Game review to improve every small inaccuracy',
    },
    {
      image: chessboardPartPlaceholder,
      title: 'Rules and Rewards',
      description:
        'Keep kids motivated with our rewards system, making learning chess fun and engaging',
    },
  ];

  const renderItem = ({item}) => (
    <ImageBackground
      source={squareBackgroundTopRight}
      style={styles.commonBackground}>
      <Image
        source={item.image}
        resizeMethod="resize"
        style={{width: 68, height: 56}}
      />
      <CCText
        fontWeight="semibold"
        fontSize={20}
        textAlign="center"
        color={CCColors.TextColor.Primary}>
        {item.title}
      </CCText>
      <CCText fontWeight="regular" fontSize={16} textAlign="center">
        {item.description}
      </CCText>
    </ImageBackground>
  );

  return (
    <Box flexDirection="row" marginTop={10} style={{marginLeft: '10%'}}>
      <Carousel
        loop
        width={screenWidth - 20}
        height={200}
        autoPlay={true}
        autoPlayInterval={3000}
        data={data}
        renderItem={renderItem}
        pagingEnabled={true}
        snapEnabled={true}
      />
    </Box>
  );
};

export const AssessAndCalibrateCarousel = () => {
  const data = [
    {
      image: chessboardPartPlaceholder,
      title: 'Parental Control',
      description:
        'Build custom rules to ensure beginners follow key chess principles ',
    },
    {
      image: chessboardPartPlaceholder,
      title: 'Assessment',
      description:
        'In-depth evaluations of your games, covering all aspects of chess including opening, middlegame, endgame, tactics, and positional play',
    },
    {
      image: chessboardPartPlaceholder,
      title: 'Game Analytics',
      description:
        'Identify your comparative strengths and weaknesses with each piece, allowing you to target and improve your biggest areas of need first',
    },
    {
      image: chessboardPartPlaceholder,
      title: 'Positions Trainer',
      description:
        'Enhance your skills by solving specific positions, with extensive filters',
    },
  ];

  const renderItem = ({item}) => (
    <ImageBackground
      source={squareBackgroundTopRight}
      style={styles.commonBackground}>
      <Image
        source={item.image}
        resizeMethod="resize"
        style={{width: 68, height: 56}}
      />
      <CCText
        fontWeight="semibold"
        fontSize={20}
        textAlign="center"
        color={CCColors.TextColor.Primary}>
        {item.title}
      </CCText>
      <CCText fontWeight="regular" fontSize={16} textAlign="center">
        {item.description}
      </CCText>
    </ImageBackground>
  );

  return (
    <Box flexDirection="row" marginTop={10} style={{marginLeft: '10%'}}>
      <Carousel
        loop
        width={screenWidth - 20}
        height={200}
        autoPlay={true}
        autoPlayInterval={3000}
        data={data}
        renderItem={renderItem}
        pagingEnabled={true}
        snapEnabled={true}
      />
    </Box>
  );
};

export const ElevateAndCultivateCarousel = () => {
  const data = [
    {
      image: chessboardPartPlaceholder,
      title: 'Sync OTB Games',
      description:
        'Upload and analyze all your over-the-board (OTB) games with a single click.',
    },
    {
      image: chessboardPartPlaceholder,
      title: 'Calculation Trainer',
      description:
        'Enhance your blindfold visualization skills with our five levels of training, designed to improve your ability to calculate and visualize moves step-by-step',
    },
    {
      image: chessboardPartPlaceholder,
      title: 'Books, Assignments and Video Resources',
      description:
        'Easy access to best books, assignments and video content, unified tracking',
    },
    {
      image: chessboardPartPlaceholder,
      title: 'Intuition Trainer',
      description:
        'Playthrough the classic games while trying to guess the moves and becoming a GM',
    },
  ];

  const renderItem = ({item}) => (
    <ImageBackground
      source={squareBackgroundTopRight}
      style={styles.commonBackground}>
      <Image
        source={item.image}
        resizeMethod="resize"
        style={{width: 68, height: 56}}
      />
      <CCText
        fontWeight="semibold"
        fontSize={20}
        textAlign="center"
        color={CCColors.TextColor.Primary}>
        {item.title}
      </CCText>
      <CCText fontWeight="regular" fontSize={16} textAlign="center">
        {item.description}
      </CCText>
    </ImageBackground>
  );

  return (
    <Box flexDirection="row" marginTop={10} style={{marginLeft: '10%'}}>
      <Carousel
        loop
        width={screenWidth - 20}
        height={200}
        autoPlay={true}
        autoPlayInterval={3000}
        data={data}
        renderItem={renderItem}
        pagingEnabled={true}
        snapEnabled={true}
      />
    </Box>
  );
};

// export default EnjoyGameplayCarousel;

const styles = StyleSheet.create({
  commonBackground: {
    width: screenWidth - 80,
    height: 200,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    marginHorizontal: 10,
    borderRadius: 12,
    // overflow: 'hidden',
    gap: 10,
  },
});
