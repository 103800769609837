import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import ConditionalRender from '@/Atoms/ConditionalRender';
import CCColors from '@/Utils/CCColors';
import React, {useRef, useState} from 'react';
import {Image, Animated} from 'react-native';
// import Animated from 'react-native-reanimated'
import styles from '../../styles';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import play from '@/Assets/Images/Svgs/home_page/play.svg';
import assess from '@/Assets/Images/Svgs/home_page/signup_assess.svg';
import elevate from '@/Assets/Images/Svgs/home_page/signup_elevate.svg';
import chessboardPartLeft from '@/Assets/Images/Svgs/home_page/chessboard_part_left.svg';
import chessboardPartRight from '@/Assets/Images/Svgs/home_page/chessboard_part_right.svg';
import CircleChessScreenshot from '@/Assets/Images/png/circlechess_screenshot.png';
import reviewGame from '@/Assets/Images/png/review_game.png';

const colors = [
  CCColors.Primary.lightBrown,
  CCColors.Level.Blue.iconColor,
  CCColors.Red,
];

function BannerComponent({signUpHandler}) {
  const {isMobileOrTab} = useMediaQuery();
  const animatedValue = useRef(new Animated.Value(0)).current;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeBox, setActiveBox] = useState(0);
  const backgroundColor = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [colors[currentIndex], colors[(currentIndex + 1) % 3]],
  });

  React.useEffect(() => {
    const cycleColors = () => {
      Animated.loop(
        Animated.timing(animatedValue, {
          toValue: 1,
          duration: 10000,
          useNativeDriver: false,
        }),
      ).start();

      const interval = setInterval(() => {
        setActiveBox(prevIndex => (prevIndex + 1) % 3);
        setCurrentIndex(prevIndex => (prevIndex + 1) % 3); // Cycle through the videos
      }, 10000);

      return () => clearInterval(interval);
    };

    cycleColors();
  }, []);

  return (
    <>
      <Box
        flex={isMobileOrTab ? 1 : undefined}
        overflow="visible"
        flexDirection={!isMobileOrTab ? 'row' : 'column-reverse'}
        justifyContent="center"
        style={{
          marginLeft: !isMobileOrTab ? '7%' : '3%',
          marginRight: !isMobileOrTab ? '7%' : '3%',
          marginTop: !isMobileOrTab ? 40 : 20,
          marginBottom: !isMobileOrTab ? 60 : 20,
          zIndex: -1,
        }}>
        <Box
          flex={!isMobileOrTab ? 0.8 : undefined}
          gap={20}
          style={{
            padding: !isMobileOrTab ? 20 : 0,
            marginTop: isMobileOrTab ? -50 : undefined,
            // zIndex: 1,
            // marginLeft: isMobileOrTab ? -5 : undefined,
          }}>
          <CCText
            fontSize={!isMobileOrTab ? 80 : 48}
            fontWeight="thunder"
            style={{
              backgroundColor: isMobileOrTab ? CCColors.White : undefined,
              width: isMobileOrTab ? '70%' : undefined,
              borderRadius: isMobileOrTab ? 10 : undefined,
              padding: isMobileOrTab ? 10 : undefined,
            }}>
            Together we
          </CCText>
          <Box
            // flex={isMobileOrTab ? 1 : undefined}
            flexDirection="row"
            marginLeft={isMobileOrTab ? 10 : undefined}
            gap={5}
            style={{paddingBottom: 5}}>
            <Animated.View
              style={[styles.box, activeBox === 0 && {backgroundColor}]}>
              <Image
                resizeMethod="resize"
                source={play}
                alt="play"
                style={{width: '40%', height: '40%'}}
              />
              <CCText
                fontSize={34}
                fontWeight="thunder"
                style={{paddingRight: 5, marginTop: 15}}>
                Play
              </CCText>
            </Animated.View>
            <Animated.View
              style={[styles.box, activeBox === 1 && {backgroundColor}]}>
              <Image
                resizeMethod="resize"
                source={assess}
                alt="assess"
                style={{width: '40%', height: '40%'}}
              />
              <CCText
                fontSize={34}
                fontWeight="thunder"
                style={{paddingRight: 5, marginTop: 15}}>
                Assess
              </CCText>
            </Animated.View>
            <Animated.View
              style={[styles.box, activeBox === 2 && {backgroundColor}]}>
              <Image
                resizeMethod="resize"
                source={elevate}
                alt="elevate"
                style={{width: '40%', height: '40%'}}
              />
              <CCText
                fontSize={34}
                fontWeight="thunder"
                style={{paddingRight: 5, marginTop: 15}}>
                Elevate
              </CCText>
            </Animated.View>
          </Box>
          <ConditionalRender
            condition={!isMobileOrTab}
            childrenA={
              <>
                <CCText
                  fontSize={!isMobileOrTab ? 64 : 48}
                  fontWeight="thunder"
                  style={{marginLeft: isMobileOrTab ? 10 : undefined}}>
                  your chess journey
                </CCText>
                <Box
                  flexDirection="row"
                  justifyContent="flex-start"
                  gap={10}
                  alignItems="center">
                  <Image
                    resizeMethod="resize"
                    source={chessboardPartLeft}
                    alt="chessboard part left"
                    style={{width: 24, height: 18}}
                  />
                  <CCText
                    fontSize={20}
                    fontWeight="thunder"
                    style={{width: '45%', fontWeight: '500', lineHeight: 28}}
                    textAlign="center">
                    Home to World's best chess coaches, players and largest
                    tournament listings
                  </CCText>
                  <Image
                    resizeMethod="resize"
                    source={chessboardPartRight}
                    alt="chessboardpart right"
                    style={{width: 24, height: 18}}
                  />
                </Box>
                <CCButton style={{width: '60%'}} onPress={signUpHandler}>
                  <CCText
                    fontSize={20}
                    style={{
                      paddingVertical: 5,
                      lineHeight: 24,
                      fontWeight: '500',
                    }}
                    color={CCColors.TextColor.Dark}>
                    Signup to CircleChess
                  </CCText>
                </CCButton>
              </>
            }
          />
        </Box>
        <Box
          overflow={isMobileOrTab ? 'hidden' : undefined}
          flex={1}
          justifyContent="center"
          alignItems="center"
          style={{borderRadius: 20}}>
          {/* <video
    src={videos[activeBox]}
    loop={true}
    autoPlay={true}
    muted={true}
    style={{
      width: '100%',
      height: '80%',
      objectFit: 'cover',
      borderRadius: 20,
      marginTop: 20,
    }}
  /> */}
          <Image
            resizeMethod="resize"
            source={!isMobileOrTab ? CircleChessScreenshot : reviewGame}
            style={{
              width: !isMobileOrTab ? '95%' : '100%',
              height: !isMobileOrTab ? '85%' : '100%',
              borderRadius: 20,
              shadowColor: '#000',
              shadowOffset: {width: 0, height: 5},
              shadowOpacity: 0.3,
              shadowRadius: 10,
              elevation: 10, // For Android shadow
              marginVertical: 20, // Replaces marginTop for top and bottom spacing
              borderColor: '#ddd',
              borderWidth: 1,
            }}
          />
        </Box>
      </Box>

      {/* For Mobile View */}
      <ConditionalRender
        condition={isMobileOrTab}
        childrenA={
          <Box
            gap={20}
            marginLeft="auto"
            marginRight="auto"
            justifyContent="center"
            style={{width: '90%', marginBottom: '10%'}}>
            <CCText
              fontSize={!isMobileOrTab ? 64 : 48}
              fontWeight="thunder"
              style={{margin: '5%'}}>
              your chess journey
            </CCText>
            <Box
              flexDirection="row"
              justifyContent="flex-start"
              gap={10}
              alignItems="center"
              style={{alignSelf: 'center'}}>
              <Image
                resizeMethod="resize"
                source={chessboardPartLeft}
                alt="chessboard part left"
                style={{width: 24, height: 18}}
              />
              <CCText
                fontSize={!isMobileOrTab ? 20 : 16}
                style={{width: '80%'}}
                textAlign="center">
                Home to World's best chess coaches, players and largest
                tournament listings
              </CCText>
              <Image
                resizeMethod="resize"
                source={chessboardPartRight}
                alt="chessboardpart right"
                style={{width: 24, height: 18}}
              />
            </Box>
            <CCButton
              onPress={signUpHandler}
              style={{alignSelf: 'center', width: '90%'}}>
              <CCText
                fontSize={16}
                style={{paddingVertical: 5}}
                color={CCColors.TextColor.Dark}>
                Signup to CircleChess
              </CCText>
            </CCButton>
          </Box>
        }
      />
    </>
  );
}

export default BannerComponent;
