import React, {useState, useEffect} from 'react';
import Box from '@/Atoms/Box';
import SlideModal from '@/Atoms/SlideModal';
import ChessboardComponent from 'chessboard-package';
import {Chess} from 'chess.js';
import CCText from '@/Atoms/CCText';
import CCButton from '@/Atoms/CCButton';
import Entypo from 'react-native-vector-icons/Entypo';
import Ionicons from 'react-native-vector-icons/Ionicons';
import AntDesign from 'react-native-vector-icons/AntDesign';
import CCColors from '@/Utils/CCColors';
import Spacer from '@/Atoms/Spacer';
function SuccessModal({
  fen,
  boardOrientation,
  autoPlayMoves,
  open,
  handleClose,
}) {
  if (!open) return;
  const [selectedMove, setSelectedMove] = useState({
    currentFen: fen,
    lastFen: fen,
  });

  function generateNewFen(currentFen, move) {
    try {
      console.log(currentFen, move);
      const chess = new Chess(currentFen);

      // `move` should be an object like { from: 'e2', to: 'e4' }
      // or a string like 'e2e4' or 'e7e8q' (for pawn promotion)
      const result = chess.move(move);

      if (result === null) {
        // throw new Error('Invalid move');
        return currentFen;
      }

      return chess.fen();
    } catch (error) {
      return currentFen;
    }
  }

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [allMovesFormattedFen, setAllMovesFormattedFen] = useState([]);

  useEffect(() => {
    if (autoPlayMoves?.length > 0) {
      const tempAllFen = autoPlayMoves?.reduce(
        (prev, curr) => {
          const tempFen = generateNewFen(prev[prev?.length - 1], curr);
          return [...prev, tempFen];
        },
        [fen],
      );
      console.log(tempAllFen, 'tempAllFen');
      setAllMovesFormattedFen(tempAllFen);
    }
  }, [autoPlayMoves]);

  const handleKeyDown = event => {
    switch (event.key) {
      case 'ArrowLeft':
        handlePrev();
        break;
      case 'ArrowRight':
        handleNext();
        break;
      case ' ':
        togglePlayPause();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (isPlaying) {
      const play = setInterval(() => {
        if (currentIndex < autoPlayMoves.length) {
          setCurrentIndex(currentIndex + 1);
        } else {
          clearInterval(play);
          setIsPlaying(false);
        }
      }, 1000); // Adjust time as needed

      return () => clearInterval(play);
    }
  }, [currentIndex, isPlaying, autoPlayMoves.length]);

  useEffect(() => {
    if (currentIndex < autoPlayMoves.length) {
      try {
        let newFen = '';
        let lastFen = '';
        // if (selectedMove?.lastFen[currentIndex]) {
        //   newFen = selectedMove?.lastFen[currentIndex];
        //   lastFen = selectedMove?.lastFen[currentIndex - 1] || fen;
        // } else {
        //   newFen = generateNewFen(
        //     selectedMove?.currentFen,
        //     autoPlayMoves[currentIndex],
        //   );
        //   lastFen = selectedMove?.currentFen;
        // }
        let tempAllFen = [];
        if (allMovesFormattedFen.length < 1 || !allMovesFormattedFen.length) {
          tempAllFen = autoPlayMoves?.reduce(
            (prev, curr) => {
              const tempFen = generateNewFen(prev[prev?.length - 1], curr);
              return [...prev, tempFen];
            },
            [fen],
          );
        }

        setSelectedMove({
          currentFen:
            tempAllFen[currentIndex] || allMovesFormattedFen[currentIndex],
          lastFen:
            tempAllFen[currentIndex - 1] ||
            allMovesFormattedFen[currentIndex - 1] ||
            fen,
        });
      } catch (error) {}

      //   chess.move(autoPlayMoves[currentIndex]);
    }
  }, [currentIndex, autoPlayMoves]);

  const handleNext = () => {
    if (currentIndex < autoPlayMoves.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <Box>
      <SlideModal
        type="center"
        modalHeaderTitle="The line could have continued as..."
        visible={open}
        onClose={handleClose}
        customStyle={{
          height: '90vh',
          width: '78vw',
          top: '-5vh',
          left: '-42%',
        }}>
        <Box
          style={{
            width: '55%',
            margin: 'auto',
          }}>
          <ChessboardComponent
            position={selectedMove?.currentFen}
            lastMoveFen={selectedMove?.lastFen}
            // onPieceDropExtension={() => {
            //   return true;
            // }}
            boardOrientation={boardOrientation}
          />
        </Box>
        <Box
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingVertical: 20,
            width: '55%',
            margin: 'auto',
          }}>
          <CCButton onPress={handlePrev}>
            <AntDesign
              name="arrowleft"
              size={20}
              color={CCColors?.Primary?.Brown}
            />
          </CCButton>
          <Spacer spacing={10} />
          <CCButton onPress={togglePlayPause}>
            {isPlaying ? (
              <Ionicons
                name="pause"
                size={20}
                color={CCColors?.Primary?.Brown}
              />
            ) : (
              <Entypo
                name="controller-play"
                size={20}
                color={CCColors?.Primary?.Brown}
              />
            )}
          </CCButton>
          <Spacer spacing={10} />
          <CCButton onPress={handleNext}>
            <AntDesign
              name="arrowright"
              size={20}
              color={CCColors?.Primary?.Brown}
            />
          </CCButton>
        </Box>
      </SlideModal>
    </Box>
  );
}

export default SuccessModal;
