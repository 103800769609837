import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import customStyles from './styles'; // Import custom styles
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';

const Pagination = ({currentPage, totalPages, paginate}) => {
  const pageNumbers = [];
  const ELLIPSES = '...';
  const {isMobileOrTab} = useMediaQuery();
  const MAX_PAGE_BUTTONS = !isMobileOrTab ? 15 : 5; // Maximum number of page buttons to show at a time

  let delta = Math.floor(MAX_PAGE_BUTTONS / 2);
  let rangeStart = Math.max(currentPage - delta, 1);
  let rangeEnd = Math.min(currentPage + delta, totalPages);

  if (rangeEnd - rangeStart < MAX_PAGE_BUTTONS - 1) {
    if (currentPage - 1 < delta) {
      rangeEnd = Math.min(rangeStart + MAX_PAGE_BUTTONS - 1, totalPages);
    } else if (totalPages - currentPage < delta) {
      rangeStart = Math.max(totalPages - MAX_PAGE_BUTTONS + 1, 1);
    }
  }

  // Push the first page button
  if (rangeStart > 1) {
    pageNumbers.push(1);
    if (rangeStart > 2) pageNumbers.push(ELLIPSES);
  }

  // Push buttons for the current range
  for (let i = rangeStart; i <= rangeEnd; i++) {
    pageNumbers.push(i);
  }

  // Push the last page button
  if (rangeEnd < totalPages) {
    if (rangeEnd < totalPages - 1) pageNumbers.push(ELLIPSES); // Add ellipsis if there's a gap
    pageNumbers.push(totalPages);
  }

  return (
    <View style={customStyles.paginationContainer}>
      <TouchableOpacity
        style={[
          customStyles.paginationButton,
          currentPage === 1 && customStyles.paginationDisabledButton,
        ]}
        disabled={currentPage === 1}
        onPress={() => paginate(Math.max(1, currentPage - 1))}>
        <Text
          style={[
            customStyles.paginationText,
            currentPage === 1 && customStyles.paginationDisabledText,
          ]}>
          Previous
        </Text>
      </TouchableOpacity>
      {pageNumbers.map((number, index) =>
        number === ELLIPSES ? (
          <Text key={index} style={customStyles.paginationText}>
            {ELLIPSES}
          </Text>
        ) : (
          <TouchableOpacity
            key={number}
            style={[
              customStyles.paginationButton,
              currentPage === number && customStyles.paginationActiveButton,
            ]}
            onPress={() => paginate(number)}>
            <Text
              style={[
                customStyles.paginationText,
                currentPage === number && customStyles.paginationActiveText,
              ]}>
              {number}
            </Text>
          </TouchableOpacity>
        ),
      )}
      <TouchableOpacity
        style={[
          customStyles.paginationButton,
          currentPage === totalPages && customStyles.paginationDisabledButton,
        ]}
        disabled={currentPage === totalPages}
        onPress={() => paginate(Math.min(totalPages, currentPage + 1))}>
        <Text
          style={[
            customStyles.paginationText,
            currentPage === totalPages && customStyles.paginationDisabledText,
          ]}>
          Next
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default Pagination;
