import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useGetTagStartingWith() {
    return useMutation(
      async ({
        pattern,
        game_stage,
      }: {
        pattern: string;
        game_stage: string;
        
      }) => {
        const response = await ChessRestClient.post('learning/get_tag_starting_with/', {
          pattern: pattern,
          game_stage: game_stage,
          
        });
        console.log("get_tag_starting_with api respoinse is :", response);

        return response.data;
      }
    );
  }
  