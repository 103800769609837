import React, {useEffect, useRef, useState} from 'react';
import {
  Image,
  Keyboard,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import Spacer from '@/Atoms/Spacer';
import SlideModal from '@/Atoms/SlideModal';
import CCTextInput from '@/Atoms/CCTextInput';
import CCColors from '@/Utils/CCColors';
import styles from './styles';
import {FontSizes} from '@/Utils/Dimensions';
import useGetCaissaChatbotResponse from '@/Hooks/CaissaChatbot';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import useLoggedInUserStore from '@/Store/loggedInUserstore';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import InfoIcon from '@/Atoms/InfoIcon';

type textMsgProp = {
  id: number;
  timeStamp: string;
  msg: string;
  type: 'text' | 'image' | 'pdf' | 'video';
  from: 'user' | 'caissa';
};

const preMessages = [
  'How do I improve my middle game?',
  'What is the Sicilian Defense?',
];

const PreQuestions = ({question, onQuestionClick}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <TouchableOpacity onPress={() => onQuestionClick(question)}>
      <Box
        style={[
          styles.PreQuestionsWrp,
          isHovered ? styles.normalShadow : styles.hoverShadow,
        ]}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <Box style={{padding: 20}} type="row-flex-start">
          <Image
            source={require('@/Assets/Images/Caissa/chatbot/Parental_control_rules.svg')}
            style={styles.buttonGirlIcon}
            resizeMode="contain"
          />
          <Spacer spacing={10} horizontal />
          <CCText fontSize={FontSizes[14]}>{question}</CCText>
        </Box>
      </Box>
    </TouchableOpacity>
  );
};

const EachMessage = ({msgDeatils}: {msgDeatils: textMsgProp}) => {
  const avatar = useLoggedInUserStore(state => state.avatar);
  return (
    <Box
      type={'row-flex-start'}
      style={{
        flexDirection: msgDeatils?.from === 'user' ? 'row-reverse' : 'row',
        width: '95%',
        alignItems: 'flex-start',
      }}
      margin={10}>
      <Box
        style={[
          styles.userIcon,
          {
            backgroundColor:
              msgDeatils?.from === 'user' ? CCColors.Grey.Grey : CCColors.Red,
          },
        ]}>
        {msgDeatils?.from === 'user' ? (
          <MaterialCommunityIcons
            style={styles.buttonUserIcon}
            name={'account'}
            size={30}
            color={CCColors.White}
          />
        ) : (
          <Image
            source={require('@/Assets/Images/Caissa/Caissa_bot_girl.svg')}
            style={[styles.buttonGirlIcon]}
            resizeMode="contain"
          />
        )}
      </Box>
      <Spacer spacing={10} horizontal />
      <Box
        style={[
          styles.msgBoxWrapper,
          {
            backgroundColor:
              msgDeatils?.from === 'user'
                ? CCColors.Primary.lightBrown
                : CCColors.White,
          },
        ]}>
        <CCText>{msgDeatils?.msg}</CCText>
      </Box>
    </Box>
  );
};

function ChatModal({openModal, handleClose}) {
  const [caissaInput, setCaissaInput] = useState('');
  const [messages, setMessages] = useState<textMsgProp[]>([]);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const scrollViewRef = useRef<ScrollView>(null);
  const {isMobileOrTab} = useMediaQuery();

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollToEnd({animated: true});
    }
  }, [messages]);

  // API hook for Caissa chatbot response
  const {mutate: getCaissaResponse} = useGetCaissaChatbotResponse(response => {
    // Add the chatbot response to the message list
    setMessages(prevMessages => [
      ...prevMessages,
      {
        id: prevMessages.length + 1,
        timeStamp: new Date().toISOString(),
        msg: response?.response,
        type: 'text',
        from: 'caissa',
      },
    ]);

    // Stop loading after the response is received
    setIsLoading(false);
  });

  const sendMessage = message => {
    if (message.trim()) {
      // Add user's message to the chat UI
      setMessages(prevMessages => [
        ...prevMessages,
        {
          id: prevMessages.length + 1,
          timeStamp: new Date().toISOString(),
          msg: message,
          type: 'text',
          from: 'user',
        },
      ]);

      // Set loading state to true
      setIsLoading(true);

      // Call API with user's input
      getCaissaResponse({user_message: message});

      // Clear input field
      setCaissaInput('');
      Keyboard.dismiss(); // Dismiss keyboard after sending
    }
  };

  const handleKeyPress = e => {
    if (e.nativeEvent.key === 'Enter') {
      sendMessage(caissaInput);
    }
  };

  const handleQuestionClick = question => {
    sendMessage(question); // Send the pre-selected question
  };

  return (
    <SlideModal
      type="side"
      visible={openModal}
      onClose={handleClose}
      customHeaderStyle={{display: 'none'}}>
      <Box
        style={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <TouchableOpacity onPress={handleClose} style={styles.closeiIconWrp}>
          <Box type="center" style={styles.closeiIcon}>
            <Ionicons name="close-outline" size={24} color="black" />
          </Box>
        </TouchableOpacity>
        <ScrollView ref={scrollViewRef} style={{height: '93vh', width: '100%'}}>
          <Image
            source={require('@/Assets/Images/Caissa/chatbot/chessboard_stairs_chatbot_bg.svg')}
            style={{width: '100%', height: '15vh', marginTop: -3}}
            resizeMode="contain"
          />
          <Spacer spacing={15} />
          <Box style={styles.chatImage}>
            <Ionicons
              name="chatbubbles-outline"
              size={50}
              color={CCColors.White}
              style={styles.chatIconBanner}
            />
          </Box>
          <Spacer spacing={20} />
          <Box marginHorizontal={10}>
            <CCText textAlign="center" fontSize={FontSizes[20]}>
              Hello, I am Caissa!
            </CCText>
            <Spacer spacing={5} />
            {messages?.length === 0 ? (
              <>
                <Box type="row-top-start">
                  <InfoIcon
                    text={'This is a wrapper on top of chatgpt'}
                    style={{
                      color: CCColors.Red,
                      width: 'fit-content',
                    }}
                  />
                  <CCText textAlign="center" fontSize={FontSizes[14]}>
                    I am an AI-enabled chatbot that can answer all your queries
                    related to Chess.
                  </CCText>
                </Box>

                {/* <CCText textAlign="center" fontSize={FontSizes[14]}>
                  This is a wrapper on top of chatgpt
                </CCText> */}
                {preMessages?.map((question, index) => (
                  <PreQuestions
                    key={index}
                    question={question}
                    onQuestionClick={handleQuestionClick}
                  />
                ))}
              </>
            ) : (
              <></>
            )}
          </Box>
          {messages.map(i => (
            <EachMessage key={i.id} msgDeatils={i} />
          ))}
        </ScrollView>
      </Box>
      <Box style={isMobileOrTab ? styles.sendBtnMobile : styles.sendBtn}>
        <CCTextInput
          placeholder="Ask Caissa anything..."
          value={caissaInput}
          onChangeText={setCaissaInput}
          onKeyPress={handleKeyPress} // Trigger on Enter
          style={{borderRadius: 25, flex: 1}}
        />
        <Box style={styles.sendBtnBg} onPress={() => sendMessage(caissaInput)}>
          {isLoading ? (
            // Loader with three dots
            <Ionicons
              name="ellipsis-horizontal"
              size={25}
              color={CCColors.White}
            />
          ) : (
            // Default send icon
            <MaterialIcons name="send" size={25} color={CCColors.White} />
          )}
        </Box>
      </Box>
    </SlideModal>
  );
}

export default ChatModal;
