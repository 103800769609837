import CCColors from '@/Utils/CCColors';
import {hs} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: CCColors.Primary.lightBrown,
    borderRadius: 8,
    padding: 15,
    margin: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 10,
  },
  cell1: {
    // textAlign: 'right',
    flex: 0.35,
  },
  cell2: {
    // textAlign: 'left',
    wordBreak: 'break-all',
    flex: 0.63,
    paddingRight: hs(12),
  },
});

export default styles;
