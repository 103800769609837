import React from 'react';

import CCColors from '../../Utils/CCColors';
import {StyleSheet, View, ViewStyle} from 'react-native';
import CCText from '../CCText';
import Spacer from '../Spacer';
import {vs} from '@/Utils/Dimensions';

export interface EmptyProps {
  imgSrc: string;
  title: string;
  description: string;
  children?: React.ReactNode;
  noBorder?: boolean;
  height?: string;
  imgWidth?: number;
}

function Empty(props: EmptyProps) {
  const {
    imgSrc,
    title,
    description,
    children,
    noBorder,
    height = '60vh',
    imgWidth = 200,
  } = props;

  return (
    <View
      style={{
        ...styles.container,
        border: noBorder ? 'none' : '1px solid rgba(34, 34, 34, 0.20)',
        minHeight: height,
      }}>
      <img src={imgSrc} style={{width: imgWidth}} alt="File Icon" />
      <Spacer spacing={vs(20)} />
      <CCText textAlign="center" style={styles.content}>
        {title}
      </CCText>
      <Spacer spacing={vs(10)} />
      <CCText textAlign="center" style={styles.content}>
        {description}
      </CCText>
      <Spacer spacing={vs(20)} />
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    backgroundColor: CCColors.White,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    width: 400,
  },
});

export default React.memo(Empty);
