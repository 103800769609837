import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import CCTextInput from '@/Atoms/CCTextInput';
import SlideModal from '@/Atoms/SlideModal';
import * as React from 'react';
import {StyleSheet} from 'react-native';

const AddNewRewardModel = ({
  showCreateModal,
  newRewardName,
  setNewRewardName,
  setShowCreateModal,
  newRewardDescription,
  setNewRewardDescription,
  newRewardCost,
  setNewRewardCost,
  handleCreateReward,
}) => {
  const [rewardNameError, setRewardNameError] = React.useState(false);
  const [rewardDescriptionError, setRewardDescriptionError] =
    React.useState(false);
  const [rewardCostError, setRewardCostError] = React.useState(false);

  const validateInputs = () => {
    let valid = true;
    if (!newRewardName) {
      setRewardNameError(true);
      valid = false;
    } else {
      setRewardNameError(false);
    }
    if (!newRewardDescription) {
      setRewardDescriptionError(true);
      valid = false;
    } else {
      setRewardDescriptionError(false);
    }
    if (!newRewardCost) {
      setRewardCostError(true);
      valid = false;
    } else {
      setRewardCostError(false);
    }
    return valid;
  };

  const handleCreateRewardWithValidation = () => {
    if (validateInputs()) {
      handleCreateReward();
    }
  };

  const clearErrors = () => {
    setRewardNameError(false);
    setRewardDescriptionError(false);
    setRewardCostError(false);
  };

  React.useEffect(() => {
    clearErrors();
  }, [showCreateModal]);

  return (
    <SlideModal
      changeModalWidth={true}
      type="center"
      modalHeaderTitle="Create New Reward"
      visible={showCreateModal}
      onClose={() => setShowCreateModal(false)}
      style={styles.modal}>
      <Box margin={12}>
        <CCText style={styles.label}>Reward Name:</CCText>
        <CCTextInput
          fontSize={14}
          placeholder="Enter reward name"
          value={newRewardName}
          onChangeText={setNewRewardName}
          style={[styles.input, rewardNameError && styles.errorInput]}
        />
        {rewardNameError && (
          <CCText style={styles.errorText}>This field is required</CCText>
        )}
        <CCText style={styles.label}>Description:</CCText>
        <CCTextInput
          fontSize={14}
          placeholder="Enter description"
          value={newRewardDescription}
          onChangeText={setNewRewardDescription}
          style={[styles.input, rewardDescriptionError && styles.errorInput]}
        />
        {rewardDescriptionError && (
          <CCText style={styles.errorText}>This field is required</CCText>
        )}
        <CCText style={styles.label}>Cost:</CCText>
        <CCTextInput
          fontSize={14}
          placeholder="Enter cost in CC Points"
          keyboardType="numeric"
          value={newRewardCost}
          onChangeText={text => {
            const numericText = text.replace(/[^0-9]/g, '');
            setNewRewardCost(numericText);
          }}
          style={[styles.input, rewardCostError && styles.errorInput]}
        />
        {rewardCostError && (
          <CCText style={styles.errorText}>This field is required</CCText>
        )}
        <Box flexDirection="row" justifyContent="flex-end" marginTop={24}>
          <CCButton
            onPress={handleCreateRewardWithValidation}
            style={styles.button}>
            <CCText style={styles.buttonText}>Create</CCText>
          </CCButton>
          <CCButton
            onPress={() => {
              clearErrors();
              setShowCreateModal(false);
            }}
            style={[styles.button, styles.cancelButton]}>
            <CCText style={styles.buttonText}>Cancel</CCText>
          </CCButton>
        </Box>
      </Box>
    </SlideModal>
  );
};

export default AddNewRewardModel;

const styles = StyleSheet.create({
  modal: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 10,
  },
  label: {
    marginBottom: 5,
    fontWeight: 'bold',
    color: '#333',
  },
  requiredMarker: {
    color: 'red',
  },
  input: {
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    padding: 5,
    marginBottom: 5,
    backgroundColor: '#f9f9f9',
  },
  errorInput: {
    borderColor: 'red',
  },
  errorText: {
    color: 'red',
    marginBottom: 2,
  },
  button: {
    backgroundColor: '#007BFF',
    padding: 5,
    borderRadius: 5,
  },
  cancelButton: {
    backgroundColor: '#6c757d',
    marginLeft: 12,
  },
  buttonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
});
