import React from 'react';
import {View, FlatList, TouchableOpacity, Image} from 'react-native';
import AntDesign from 'react-native-vector-icons/AntDesign';
import CCText from '@/Atoms/CCText';
import Spacer from '@/Atoms/Spacer';
import Box from '@/Atoms/Box';
import CCColors from '@/Utils/CCColors';
import {vs} from '@/Utils/Dimensions';
import discover from '@/Assets/Images/png/book-closed.png';

const ChapterList = ({
  chapters,
  onChapterChange,
  setShowChapters,
  selectedItem,
  isChapterHover,
  setChapterHover,
}) => {
  const renderItem = ({item: c, index}) => (
    <TouchableOpacity
      onPress={() => {
        console.log('called 1');
        onChapterChange(c.chapter_index);
        setShowChapters(false);
      }}
      onMouseEnter={() => setChapterHover(c.chapter_index)}
      onMouseLeave={() => setChapterHover(0)}
      style={{
        padding: 15,
        backgroundColor:
          isChapterHover === c.chapter_index
            ? CCColors.Primary.lightBrown
            : CCColors.White,
      }}>
      <Box type="row-flex-start">
        <AntDesign
          name="checkcircle"
          size={20}
          color={c?.solved ? CCColors.Green : CCColors.Primary.Grey}
          style={{marginRight: 20}}
        />
        <View>
          <CCText
            fontSize={16}
            style={{
              textDecorationLine: c?.solved ? 'line-through' : 'none',
            }}>
            {c?.chapter_index}. {c?.sub_chapter}
          </CCText>
          <Spacer spacing={vs(5)} />
          <Box type="row-flex-start">
            <Image source={discover} style={{width: 15}} alt="icon" />
            <Spacer spacing={vs(5)} horizontal />
            <CCText fontSize={14}>{c?.chapter}</CCText>
            <Spacer spacing={vs(5)} horizontal />
            {selectedItem.chapter_index === c.chapter_index && (
              <CCText
                fontSize={12}
                color={CCColors.Green}
                style={{
                  backgroundColor: '#E0F2EF',
                  padding: 5,
                  borderRadius: 4,
                }}>
                ONGOING
              </CCText>
            )}
          </Box>
        </View>
      </Box>
    </TouchableOpacity>
  );

  return (
    <View style={{paddingTop: 30}}>
      <CCText
        fontSize={14}
        color={CCColors.Grey.Shade100}
        style={{marginLeft: 30}}>
        {chapters.length} Chapters
      </CCText>
      <FlatList
        data={chapters}
        renderItem={renderItem}
        keyExtractor={item => item.chapter_index.toString()}
      />
    </View>
  );
};

export default ChapterList;
