import CCColors from '@/Utils/CCColors';
import {isPhone} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {},
  dummySpacer: {
    width: 0,
    height: 32,
  },
  checkStyle: {
    width: 32,
    height: 32,
    backgroundColor: `${CCColors.Green}16`,
    borderRadius: 25,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  crossStyle: {
    width: 32,
    height: 32,
    backgroundColor: `${CCColors.Red}16`,
    borderRadius: 25,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cellStyle: {
    width: '100%',
    height: '100%',
    borderWidth: 1,
    borderColor: '#26232220',
    margin: 0,
    padding: 10,
  },
  buttonCell: {
    width: '100%',
    borderWidth: 1,
    borderColor: '#26232220',
    margin: 0,
    padding: 10,
  },
  table: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: 8,
    backgroundColor: CCColors.White,
    borderWidth: 0,
  },
  faq: {
    width: isPhone ? '95%' : '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export default styles;
