import {useMutation} from '@tanstack/react-query';
import {getLocalStorage} from '@/Utils/CommonUtils';
import ChessRestClient from '@/Api/ChessRestClient';

interface CaissaChatbotResponse {
  message: string;
}

export default function useGetCaissaChatbotResponse(
  handleOnSuccess?: (data: CaissaChatbotResponse) => void,
) {
  const mutation = useMutation<
    CaissaChatbotResponse,
    Error,
    {user_message: string}
  >(
    async ({user_message}) => {
      const response = await ChessRestClient.post(
        '/gpt/v1/get_caissa_chatbot_response',
        {
          user_message,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) {
          handleOnSuccess(data);
        }
      },
    },
  );

  return {
    ...mutation,
  };
}
