export const CATEGORY_TYPE = [
  {value: 1, key: 'player'},
  {value: 2, key: 'parent'},
  {value: 3, key: 'organizer'},
  {value: 4, key: 'coach'},
];

export const GENDER_TYPE = [
  {value: 1, key: 'male'},
  {value: 2, key: 'female'},
  {value: 3, key: 'others'},
];

export const GA4_TRACKING_ID = 'G-YP388RELCY';

export const OPACITY_0_2 = {opacity: 0.2};
export const OPACITY_1_0 = {opacity: 1};
export const MOBILE_HEADER_HEIGHT = 80;

export const TIME_CLASS = [
  {key: 'Classical', value: 'Classical'},
  {key: 'Rapid', value: 'Rapid'},
  {key: 'Blitz', value: 'Blitz'},
];

export const CHESS_COLORS = [
  {key: 'Black', value: 'Black'},
  {key: 'White', value: 'White'},
];

export const CHESS_RESULTS = [
  {key: 'Win', value: 'Win'},
  {key: 'Loss', value: 'Loss'},
  {key: 'Draw', value: 'Draw'},
];

export const START_INTUITION_FROM_MOVE = 20;

export const PRO_PLAN_IDS = [4, 5];
