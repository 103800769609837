import {
  Animated,
  BackHandler,
  Modal,
  NativeEventSubscription,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import React, {useEffect, useRef} from 'react';

import CCColors from '../../Utils/CCColors';
import LottieView from 'react-native-web-lottie';
import {WINDOW_HEIGHT, WINDOW_WIDTH} from '../../Utils/Dimensions';

interface LoaderProps {
  loading: boolean;
  loadingIcon?: () => JSX.Element;
  bgStyle?: ViewStyle;
  bgWhite?: boolean;
  isComponentLoader?: boolean;
}

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const {loading, bgStyle, bgWhite = false, isComponentLoader = false} = props;

  const spinAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const backAction = () => {
      return true; // This blocks the back button
    };

    let backHandler: NativeEventSubscription | null = null;
    if (loading) {
      backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    } else {
      if (backHandler) {
        // @ts-ignore
        backHandler?.remove();
      }
    }
    return () => backHandler?.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const spin = spinAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  if (isComponentLoader) {
    if (!loading) {
      return;
    }
    return (
      // <View
      //   style={[
      //     styles.modalBackground,
      //     bgStyle,
      //     bgWhite && {backgroundColor: CCColors?.White},
      //   ]}>
      //   <View style={styles.activityIndicatorWrapper}>
      <View
        style={{
          transform: [{perspective: 1000}],
          margin: 'auto',
          opacity: 0.8,
        }}>
        <LottieView
          source={require('../../Assets/Animation/chessboardLoader.json')}
          style={{width: WINDOW_WIDTH / 4, height: WINDOW_HEIGHT / 4}}
          autoPlay
          loop
        />
      </View>
      // </View>
      // </View>;
    );
  }
  return (
    <Modal transparent={true} animationType={'none'} visible={loading}>
      <View
        style={[
          styles.modalBackground,
          bgStyle,
          bgWhite && {backgroundColor: CCColors?.White},
        ]}>
        <View style={styles.activityIndicatorWrapper}>
          <View style={{transform: [{perspective: 1000}]}}>
            <LottieView
              source={require('../../Assets/Animation/chessboardLoader.json')}
              style={{width: WINDOW_WIDTH / 4, height: WINDOW_HEIGHT / 4}}
              autoPlay
              loop
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalBackground: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    backgroundColor: '#00000063',
  },
  activityIndicatorWrapper: {
    backgroundColor: 'transparent',
    height: 100,
    width: 100,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    zIndex: 9999999999999,
  },
});

export default Loader;
