import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import SlideModal from '@/Atoms/SlideModal';
import CCColors from '@/Utils/CCColors';
import {vs} from '@/Utils/Dimensions';
import * as React from 'react';
import {Text, View, StyleSheet} from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';

const ConfirmationModal = ({
  showConfirm,
  onConfirmModalClose,
  redeemSuccess,
  selectedReward,
  errorMessage,
  redeemInProgress,
  callRedeemReward,
}) => {
  const {isMobileOrTab} = useMediaQuery();
  return (
    <SlideModal
      changeModalWidth={true}
      type="center"
      modalHeaderTitle=""
      visible={showConfirm}
      onClose={onConfirmModalClose}>
      <Box margin={6}>
        {!redeemSuccess && (
          <>
            <Box
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              style={{
                width: 60,
                height: 60,
                backgroundColor: CCColors.Animated.Background,
                borderRadius: '50%',
                alignSelf: 'center',
              }}>
              <FontAwesome name="trophy" size={40} color={CCColors.Green} />
            </Box>
            <CCText
              textAlign="center"
              fontSize={20}
              color={CCColors.TextColor.Dark}>
              Want to redeem {selectedReward.name}
            </CCText>
          </>
        )}
        {!redeemSuccess && (
          <CCText
            style={{
              margin: 6,
              width: '50%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            textAlign="center">
            You are <b>spending {selectedReward.cost} Points</b> to redeem '
            {selectedReward.name}'. Are you sure?
          </CCText>
        )}
        {redeemSuccess && (
          <>
            <Box
              gap={10}
              style={{
                width: 70,
                height: 70,
                alignSelf: 'center',
                backgroundColor: CCColors.Green,
                borderRadius: '50%',
              }}
              justifyContent="center"
              alignItems="center">
              <AntDesign name="check" size={40} color={CCColors.White} />
            </Box>
            <CCText textAlign="center" fontSize={20} fontWeight="semibold">
              You have redeemed successfully
            </CCText>
            <CCText
              textAlign="center"
              style={{
                margin: 12,
                width: '60%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}>
              Your dedication and effort have paid off! Enjoy your well-deserved
              reward and celebrate this achievement with pride. You've earned
              it! 🌟🏆
            </CCText>
          </>
        )}
        {!redeemSuccess && errorMessage && (
          <CCText style={{margin: 12, color: CCColors.Red}}>
            {errorMessage}
          </CCText>
        )}
        {!redeemSuccess && (
          <Box
            flexDirection="row"
            justifyContent="center"
            gap={20}
            marginTop={20}>
            <CCButton
              disabled={redeemInProgress}
              onPress={_e => {
                onConfirmModalClose();
              }}>
              <CCText>Cancel</CCText>
            </CCButton>
            <CCButton
              disabled={redeemInProgress}
              onPress={_e => {
                callRedeemReward(selectedReward.id);
              }}>
              <CCText>Redeem</CCText>
            </CCButton>
          </Box>
        )}
      </Box>
    </SlideModal>
  );
};

export default ConfirmationModal;

const styles = StyleSheet.create({
  container: {},
});
