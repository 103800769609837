import * as React from 'react';
import {FlatList} from 'react-native';
import Box from '../Box';
import CCTextInput from '../CCTextInput';
import ConditionalRender from '../ConditionalRender';
import CCButton from '../CCButton';
import CCText from '../CCText';
import Divider from '../Divider/Divider';
import styles from './styles';
import {GestureResponderEvent} from 'react-native';

interface AutoCompleteProps {
  iconPath?: string;
  placeholder?: string;
  value: string;
  onChangeText: (text: string) => void;
  autoFocus?: boolean;
  onKeyPress?: (event: GestureResponderEvent) => void;
  dropDownClose?: boolean;
  data: Array<string>;
  onPressOnItem: (item: any) => void;
  zIndex?: number;
  parentZindex?: number;
  noOfItemsToShow?: number;
  id?: number;
  listHeight?: number;
  handleFocus?: () => void;
  handleBlur?: () => void;
  loading?: boolean;
}

const CCAutoComplete: React.FC<AutoCompleteProps> = ({
  iconPath,
  id = 1,
  placeholder,
  value,
  onChangeText,
  autoFocus = false,
  onKeyPress,
  dropDownClose,
  data,
  onPressOnItem,
  noOfItemsToShow = 8,
  listHeight = 250,
  handleFocus,
  handleBlur,
  loading = false,
}) => {
  const [focused, setFocused] = React.useState(false);

  // Handle focus and blur events to update the focused state
  const handleInputFocus = () => {
    setFocused(true);
    if (handleFocus) {
      handleFocus();
    }
  };

  const handleInputBlur = () => {
    setFocused(false);
    if (handleBlur) {
      handleBlur();
    }
  };

  // Determine the data to display based on the loading and focused states
  const displayedData =
    loading && focused && value
      ? ['loading...']
      : data.slice(0, noOfItemsToShow);

  return (
    <Box overflow="visible" style={{zIndex: -1 * id}}>
      <ConditionalRender
        condition={!dropDownClose}
        childrenA={
          <FlatList
            style={[
              styles.autoCompleteFlatList,
              {
                borderWidth: displayedData.length > 0 ? 1 : 0,
                zIndex: id,
                maxHeight: listHeight,
              },
            ]}
            data={displayedData}
            renderItem={item => (
              <>
                <CCButton
                  style={{backgroundColor: 'inherit'}}
                  onPress={() => !loading && onPressOnItem(item)}
                  disabled={loading && item?.item === 'loading...'}>
                  <CCText showEllipses>
                    {' '}
                    {item?.item?.name || item?.item}
                  </CCText>
                </CCButton>
                <Divider style={styles.divider} />
              </>
            )}
            keyExtractor={(item, index) => index.toString()}
          />
        }
      />
      <CCTextInput
        icon={iconPath ? require('@/Assets/' + iconPath) : undefined}
        label=""
        placeholder={placeholder}
        value={value}
        onChangeText={onChangeText}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        // autoFocus={autoFocus}
        style={styles.input}
        fontSize={14}
        onKeyPress={onKeyPress}
        placeholderTextColor="#999"
      />
    </Box>
  );
};

export default React.memo(CCAutoComplete);
