import {PuzzlesResponse} from './types';
import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function usePuzzlesApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<PuzzlesResponse, Error, void>(
    async filters => {
      console.log('Fetching puzzle data .. ', JSON.stringify(filters));
      if (!filters) filters = {};
      let q = {};

      for (var key in filters) {
        const element = filters[key];
        console.log(element);
        q[key] = element.value || element;
      }
      console.log(q);
      const objString = '?' + new URLSearchParams(q).toString();
      console.log('Query params = ', objString);

      const response = await ChessRestClient.get(
        `learning/puzzles/${objString}`,
      );
      console.log(response.data);
      return response.data;
    },
    {
      onSuccess: data => {
        console.log('Puzzles Data = ', data);
        if (handleOnSuccess && data.success) handleOnSuccess(data);
        else if (handleOnError) handleOnError(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
