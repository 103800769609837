import React, {useEffect, useRef, useState} from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Animated,
  Dimensions,
} from 'react-native';
import Modal from 'react-native-modal';
import Ionicons from 'react-native-vector-icons/Ionicons';
import CCColors from '@/Utils/CCColors';
import {WINDOW_HEIGHT} from '@/Utils/Dimensions';

type BottomSheetProps = {
  children: JSX.Element;
  minHeight?: number;
};

const BottomSheetPlay: React.FC = ({
  children,
  minHeight = WINDOW_HEIGHT / 2.25,
}: BottomSheetProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const INITIAL_HEIGHT = minHeight;
  const FULL_HEIGHT = WINDOW_HEIGHT / 1.3;
  const sheetHeight = useRef(new Animated.Value(minHeight)).current;

  useEffect(() => {
    Animated.timing(sheetHeight, {
      toValue: minHeight,
      duration: 300,
      useNativeDriver: false,
    }).start();
  }, [minHeight]);

  const handleArrowClick = () => {
    const targetHeight = isExpanded ? minHeight : FULL_HEIGHT;
    Animated.timing(sheetHeight, {
      toValue: targetHeight,
      duration: 300,
      useNativeDriver: false,
    }).start(() => {
      setIsExpanded(!isExpanded);
    });
  };

  return (
    <View style={styles.container}>
      <Modal
        isVisible={isVisible}
        style={styles.modal}
        hasBackdrop={false}
        coverScreen={false}
        backdropOpacity={0}
        useNativeDriver={true}
        propagateSwipe={true}
        onBackdropPress={() => null}>
        <View style={styles.backgroundTouchable} pointerEvents="box-none">
          <Animated.View
            style={[styles.modalContent, {height: sheetHeight}, styles.shadow]}>
            <TouchableOpacity
              onPress={handleArrowClick}
              hitSlop={{
                top: 40,
                bottom: 40,
                left: 40,
                right: 40,
              }}
              style={styles.dragHandle}>
              <Ionicons
                name={isExpanded ? 'chevron-down' : 'chevron-up'}
                size={30}
                color={CCColors.Grey.Grey}
              />
            </TouchableOpacity>
            {children}
          </Animated.View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
    zIndex: 1,
  },
  modalContent: {
    backgroundColor: 'white',
    borderTopLeftRadius: 17,
    borderTopRightRadius: 17,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  dragHandle: {
    alignSelf: 'center',
    paddingVertical: 10,
  },
  backgroundTouchable: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  shadow: {
    shadowColor: 'rgba(0,0,0, .4)',
    shadowOffset: {height: 1, width: 1},
    shadowOpacity: 1,
    shadowRadius: 1,
    backgroundColor: '#fff',
    elevation: 2,
  },
});

export default BottomSheetPlay;
