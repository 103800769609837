import Box from "../Box";
import CCText from "../CCText";
import Divider from "../Divider/Divider";
import React from "react";
import styles from "./styles";

function OrSeparator() {
  return (
    <Box flexDirection="row" style={styles.orSeparator}>
      <Divider style={{ flex: 0.47 }} />
      <CCText>or</CCText>
      <Divider style={{ flex: 0.47 }} />
    </Box>
  );
}

export default OrSeparator;
