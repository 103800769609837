import Box from "@/Atoms/Box";
import { FontSizes, hs, isPhone, vs } from '@/Utils/Dimensions';


import React, { useState, useEffect, } from 'react';
import { TextInput, View, Text, TouchableOpacity, StyleSheet,  TouchableWithoutFeedback, FlatList } from 'react-native';
import { debounce } from 'lodash';


import CCTextInput from '@/Atoms/CCTextInput';
import CCColors from '@/Utils/CCColors';
import ConditionalRender from '@/Atoms/ConditionalRender';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import Divider from '@/Atoms/Divider/Divider';

import {GestureResponderEvent} from 'react-native';
import useLoggedInUserStore from '@/Store/loggedInUserstore';


function ChallengeUserComponent(props) {
    const { handleOpponentName, searchPlayers, searchPlayersResult, user } = props;

    const [searchResults, setSearchResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    

    // Debounce the searchPlayers function to delay its execution
    const debouncedSearchPlayers = debounce((text) => handleUsernameChange(text), 300);

    const handleUsernameChange = (username) => {
        // Update the search term state
        setSearchTerm(username);
        // Call the debounced searchPlayers function
        searchPlayers(username);
        
    };
    const handleFocus = () => {
        handleUsernameChange('');
        setIsFocused(true);
    };

    const handleBlur = () => {
        // Delay setting isFocused to false by 150 milliseconds
        setTimeout(() => {
          setIsFocused(false);
        }, 300); // 150 milliseconds
      };

    const [isFocused, setIsFocused] = useState(false);



    useEffect(() => {
        console.log('player list = ', searchPlayersResult);
        if (searchPlayersResult) {
            try {
                let parsedResult = JSON.parse(searchPlayersResult);
                parsedResult = Object.fromEntries(
                    Object.entries(parsedResult).filter(([key, value]) => value !== user)
                );
                let resultArray = Object.keys(parsedResult).map(username => ({
                    username: username,
                    userId: parsedResult[username]
                  }))
                console.log('parsed list = ', parsedResult)
                console.log('resultArray = ', resultArray)
                setSearchResults(resultArray);
            } catch (error) {
                console.error('Error parsing searchPlayersResult:', error);
            }
            handleOpponentName('');
        }
    }, [searchPlayersResult])
    

    const handlePlayerSelect = (selectedPlayer) => {
        // Call the handleOpponentName function with the selected player's name
        setSearchTerm(selectedPlayer.username);
        handleOpponentName(selectedPlayer.userId + ":" + selectedPlayer.username);
        // Clear the search results
    };
    

    return (
        <View style={styles.container}>
            {/* <TouchableWithoutFeedback>
            <View>
                <TextInput
                    onChangeText={handleUsernameChange}
                    onFocus={handleFocus}
                    value={searchTerm}
                    style={styles.input}
                    placeholder="Username of the opponent"
                />
            
            
                <View>
                    {searchResults && isDropdownOpen && (
                        <View style={styles.dropdown}>
                            {Object.entries(searchResults).map(([username, userId]) => (
                                <TouchableOpacity key={userId} onPress={() => handlePlayerSelect({ username, userId })}>
                                    <Box type="row-center">
                                        <Text style={styles.dropdownItem}>{username}</Text>
                                    </Box>
                                </TouchableOpacity>
                            ))}
                        </View>
                    )}
                </View>
            </View>
            </TouchableWithoutFeedback> */}
            <Box overflow="visible" style={{zIndex: -10 }}>
                <ConditionalRender
                    condition={isFocused}
                    childrenA={
                    <FlatList
                        style={[
                        styles.autoCompleteFlatList,
                        {
                            borderWidth: searchResults.length > 0 ? 1 : 0,
                            zIndex: 0,
                            maxHeight: 250,
                        },
                        ]}
                        data={searchResults}
                        renderItem={item => {
                            const { username, userId } = item.item;

                        return (
                            <>
                            <CCButton
                                style={{backgroundColor: 'inherit'}}
                                // onHoverIn={() => handlePlayerSelect({ username, userId })}
                                onPress={() => handlePlayerSelect({ username, userId })}>
                                <CCText >
                                {username}  
                                </CCText>
                            </CCButton>
                            <Divider style={styles.divider} />
                            </>
                        );
                        }}
                        keyExtractor={(item, index) => index.toString()}
                        // keyExtractor={item => item.userId.toString()}
                    />
                    }
                />
                <CCTextInput
                    // icon={require('@/Assets/Images/png/black_rook.png')}
                    
                    label=""
                    placeholder="Username of the opponent"
                    value={searchTerm}
                    onChangeText={handleUsernameChange}
                    // autoFocus={true}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    style={styles.input}
                    fontSize={14}
                    // onKeyPress={() => setDropDownClose(true)}
                    placeholderTextColor={CCColors.TextColor.Grey}
                />
            </Box>
            
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        position: 'relative',
        alignItems: 'flex-start',
        padding: 8,
        zIndex: -10
        // width: '90%'
    },
    input: {
        backgroundColor: 'white',
        borderColor: '#22222220',
        borderWidth: 1,
        width: isPhone ? '90vw' : '24vw',
        height: 48,
        borderRadius: 8,
        padding: 12,
        placeholderTextColor: '#26232240',
        zIndex: -10
    },
    dropdown: {
        position: 'relative',
        backgroundColor: 'white',
        borderRadius: 8,
        borderColor: '#22222220',
        borderWidth: 1,
        width: 480,
        zIndex: 0,
        bottom: 0,
    },
    autoCompleteFlatList: {
        position: 'absolute',
        width: '100%',
        top: '100%',
        left: 0,
        backgroundColor: CCColors.White,
        borderColor: CCColors.BorderBottom,
        borderRadius: 10,
    },
    divider: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    dropdownItem: {
        padding: 12,
    },
});

export default ChallengeUserComponent;