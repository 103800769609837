import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useGetAllPlansApi(handleOnSuccess, handleOnError) {
  const mutation = useMutation(
    async () => {
      const response = await ChessRestClient.get(
        'subscription/v1/get_all_plans',
      );
      return response;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) handleOnSuccess(data);
      },
      onError: error => {
        if (handleOnError) handleOnError(error);
      },
    },
  );

  return {
    ...mutation,
  };
}
