// // import ChatRestClient from '../../Api/ChatRestClient';
// import ChessRestClient from '@/Api/ChessRestClient';
// import {useMutation} from '@tanstack/react-query';
// import {calculationResponse} from './types';
// import {getLocalStorage} from '@/Utils/CommonUtils';

// export default function useGetCalculation(
//   handleOnSuccess?: (data) => void,
//   handleOnError?: (data) => void,
// ) {
//   const mutation = useMutation<calculationResponse>(
//     Error,
//     {
//       move_count?:Number
//     }
//     async (move_count = 1) => {
//       const response = await ChessRestClient.post(
//         '/learning/blindfold_puzzles/',
//         {
//           move_count: move_count,
//         },
//       );

//       return response.data;
//     },
//     {
//       onSuccess: data => {
//         if (handleOnSuccess) handleOnSuccess(data);
//         else if (handleOnError) handleOnError(data);
//       },
//     },
//   );

//   return {
//     ...mutation,
//   };
// }

import ChessRestClient from '@/Api/ChessRestClient';
import {useMutation} from '@tanstack/react-query';
import {calculationResponse} from './types';

export default function useGetCalculation(
  handleOnSuccess?: (data) => void,
  handleOnError?: (data) => void,
) {
  const mutation = useMutation<calculationResponse>({
    mutationFn: async (move_count = 1) => {
      const response = await ChessRestClient.get(
        `/learning/blindfold_puzzles/?moves_count=${move_count}&puzzle_status=0`,
      );

      return response.data;
    },
    onSuccess: data => {
      if (handleOnSuccess) handleOnSuccess(data);
      else if (handleOnError) handleOnError(data);
    },
  });

  return mutation;
}
