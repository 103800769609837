import {Image} from 'react-native';
import blunderImg from '../../Assets/Images/Svgs/learning/blunder-icon.svg';
import inaccuracy from '../../Assets/Images/Svgs/learning/inaccuracy-icon.svg';
import mistake from '../../Assets/Images/Svgs/learning/mistakes-icon.svg';
// import inaccuracy from '../../Assets/Images/Svgs/learning/inaccuracy-icon.svg';

export const moveIconsAndColors = {
  // 'Normal Move': {
  //   icon: '',
  //   color: '#303030',
  // },
  // Brilliant: {
  //   icon: '⚠️',
  //   color: '#FFD700',
  // },
  // 'Great Move': {
  //   icon: '❗',
  //   color: '#FF4500',
  // },
  // 'Best Move': {
  //   icon: '⭐',
  //   color: '#FFD700',
  // },
  // Excellent: {
  //   icon: '🔆',
  //   color: '#17df17',
  // },
  // Good: {
  //   icon: '✅',
  //   color: '#008000',
  // },
  // Book: {
  //   icon: '📖',
  //   color: '#b58863',
  // },
  Inaccuracy: {
    icon: <Image source={inaccuracy} style={{width: 20, height: 15}} />,
    color: '#FFA500',
  },
  Mistake: {
    icon: <Image source={mistake} style={{width: 20, height: 15}} />,
    color: '#FF0000',
  },
  Miss: {
    icon: 'X',
    color: '#FF0000',
  },
  Blunder: {
    icon: <Image source={blunderImg} style={{width: 20, height: 15}} />,
    color: '#FF0000',
  },
};

export default moveIconsAndColors;

export const gameMoves = [
  {
    platform: 'lichess',
    move: 'e4',
    fen: 'rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR b KQkq - 0 1',
    best_possible_response:
      '1...c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 e5 7. Nb3 Be7 8. Qd2 Ng4 9. Nd5 Nxe3 10. Nxe3 Be6 11. O-O-O',
    best_current_move: 'e4',
    best_move: 'e4',
    best_response: 'c5',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 1,
    player: 'opponent',
    color: 'white',
    piece: 'Pawn',
    capture: 0,
    time_taken_in_secs: 0,
    trade_initiated: 0,
    trade_back: 0,
    score: -38,
    score_change: -38,
    inaccuracy: 0,
    mistake: 1,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 600.0,
    average_time: 0.0,
    created_time: '2023-10-15T19:36:00.372Z',
    updated_time: '2023-10-15T19:36:00.372Z',
  },
  {
    platform: 'lichess',
    move: 'e5',
    fen: 'rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2',
    best_possible_response:
      '2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bb4+ 5. c3 Bc5 6. Be3 Bb6 7. Na3 Nf6 8. Nxc6 bxc6 9. e5 Nd5',
    best_current_move:
      '1...c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 e5 7. Nb3 Be7 8. Qd2 Ng4 9. Nd5 Nxe3 10. Nxe3 Be6 11. O-O-O',
    best_move: 'c5',
    best_response: 'Nf3',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 1,
    player: 'me',
    color: 'black',
    piece: 'Pawn',
    capture: 0,
    time_taken_in_secs: 0,
    trade_initiated: 0,
    trade_back: 0,
    score: -34,
    score_change: 4,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 600.0,
    average_time: 0.0,
    created_time: '2023-10-15T19:36:01.412Z',
    updated_time: '2023-10-15T19:36:01.412Z',
  },
  {
    platform: 'lichess',
    move: 'Nf3',
    fen: 'rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq - 1 2',
    best_possible_response:
      '2...Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 O-O 8. c3 d5 9. exd5 Nxd5 10. a4 Be6 11. d3 Bf6 12. axb5 axb5 13. Rxa8 Qxa8',
    best_current_move:
      '2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bb4+ 5. c3 Bc5 6. Be3 Bb6 7. Na3 Nf6 8. Nxc6 bxc6 9. e5 Nd5',
    best_move: 'Nf3',
    best_response: 'Nc6',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 2,
    player: 'opponent',
    color: 'white',
    piece: 'Knight',
    capture: 0,
    time_taken_in_secs: 3,
    trade_initiated: 0,
    trade_back: 0,
    score: -33,
    score_change: 1,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 597.0,
    average_time: 1.5,
    created_time: '2023-10-15T19:36:02.453Z',
    updated_time: '2023-10-15T19:36:02.453Z',
  },
  {
    platform: 'lichess',
    move: 'Nc6',
    fen: 'r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq - 2 3',
    best_possible_response:
      '3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 Bg4 9. h3 Bh5 10. g4 Bg6 11. a4 O-O 12. d3 Na5 13. Ba2 c5 14. Nbd2 c4 15. Nh4 Qc7',
    best_current_move:
      '2...Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 O-O 8. c3 d5 9. exd5 Nxd5 10. a4 Be6 11. d3 Bf6 12. axb5 axb5 13. Rxa8 Qxa8',
    best_move: 'Nc6',
    best_response: 'Bb5',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 2,
    player: 'me',
    color: 'black',
    piece: 'Knight',
    capture: 0,
    time_taken_in_secs: 4,
    trade_initiated: 0,
    trade_back: 0,
    score: -39,
    score_change: -6,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 596.0,
    average_time: 2.0,
    created_time: '2023-10-15T19:36:02.753Z',
    updated_time: '2023-10-15T19:36:02.753Z',
  },
  {
    platform: 'lichess',
    move: 'Bc4',
    fen: 'r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R b KQkq - 3 3',
    best_possible_response: '3...Nf6 4. d3',
    best_current_move:
      '3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 Bg4 9. h3 Bh5 10. g4 Bg6 11. a4 O-O 12. d3 Na5 13. Ba2 c5 14. Nbd2 c4 15. Nh4 Qc7',
    best_move: 'Bb5',
    best_response: 'Nf6',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 3,
    player: 'opponent',
    color: 'white',
    piece: 'Bishop',
    capture: 0,
    time_taken_in_secs: 4,
    trade_initiated: 0,
    trade_back: 0,
    score: -24,
    score_change: 15,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 593.0,
    average_time: 2.3,
    created_time: '2023-10-15T19:36:03.870Z',
    updated_time: '2023-10-15T19:36:03.870Z',
  },
  {
    platform: 'lichess',
    move: 'Nf6',
    fen: 'r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq - 4 4',
    best_possible_response:
      '4. d3 Bc5 5. c3 d6 6. O-O a6 7. Nbd2 Ba7 8. Re1 Na5 9. Bb3 Nxb3 10. axb3 O-O 11. h3 Ne8 12. d4 exd4 13. Nxd4',
    best_current_move: '3...Nf6 4. d3',
    best_move: 'Nf6',
    best_response: 'd3',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 3,
    player: 'me',
    color: 'black',
    piece: 'Knight',
    capture: 0,
    time_taken_in_secs: 2,
    trade_initiated: 0,
    trade_back: 0,
    score: -27,
    score_change: -3,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 594.0,
    average_time: 2.0,
    created_time: '2023-10-15T19:36:04.510Z',
    updated_time: '2023-10-15T19:36:04.510Z',
  },
  {
    platform: 'lichess',
    move: 'Ng5',
    fen: 'r1bqkb1r/pppp1ppp/2n2n2/4p1N1/2B1P3/8/PPPP1PPP/RNBQK2R b KQkq - 5 4',
    best_possible_response:
      '4...d5 5. exd5 Na5 6. Bb5+ c6 7. dxc6 bxc6 8. Bd3 Nd5 9. Nf3 Bd6 10. O-O Nf4 11. Be2 e4 12. Ne1 Nxe2+ 13. Qxe2 O-O 14. d3 exd3',
    best_current_move:
      '4. d3 Bc5 5. c3 d6 6. O-O a6 7. Nbd2 Ba7 8. Re1 Na5 9. Bb3 Nxb3 10. axb3 O-O 11. h3 Ne8 12. d4 exd4 13. Nxd4',
    best_move: 'd3',
    best_response: 'd5',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 4,
    player: 'opponent',
    color: 'white',
    piece: 'Knight',
    capture: 0,
    time_taken_in_secs: 4,
    trade_initiated: 0,
    trade_back: 0,
    score: -15,
    score_change: 12,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 589.0,
    average_time: 2.7,
    created_time: '2023-10-15T19:36:05.557Z',
    updated_time: '2023-10-15T19:36:05.557Z',
  },
  {
    platform: 'lichess',
    move: 'd5',
    fen: 'r1bqkb1r/ppp2ppp/2n2n2/3pp1N1/2B1P3/8/PPPP1PPP/RNBQK2R w KQkq - 0 5',
    best_possible_response: '5. exd5 Na5',
    best_current_move:
      '4...d5 5. exd5 Na5 6. Bb5+ c6 7. dxc6 bxc6 8. Bd3 Nd5 9. Nf3 Bd6 10. O-O Nf4 11. Be2 e4 12. Ne1 Nxe2+ 13. Qxe2 O-O 14. d3 exd3',
    best_move: 'd5',
    best_response: 'exd5',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 4,
    player: 'me',
    color: 'black',
    piece: 'Pawn',
    capture: 0,
    time_taken_in_secs: 1,
    trade_initiated: 0,
    trade_back: 0,
    score: 0,
    score_change: 15,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 593.0,
    average_time: 1.7,
    created_time: '2023-10-15T19:36:06.601Z',
    updated_time: '2023-10-15T19:36:06.601Z',
  },
  {
    platform: 'lichess',
    move: 'exd5',
    fen: 'r1bqkb1r/ppp2ppp/2n2n2/3Pp1N1/2B5/8/PPPP1PPP/RNBQK2R b KQkq - 0 5',
    best_possible_response:
      '5...Na5 6. Bb5+ c6 7. dxc6 bxc6 8. Bd3 Nd5 9. Nf3 Bd6 10. Nc3 O-O 11. Be2 f5 12. d3 e4 13. Nd4 Nxc3 14. bxc3 Qh4 15. Nb3 Nb7 16. Qd2 a5 17. Qg5',
    best_current_move: '5. exd5 Na5',
    best_move: 'exd5',
    best_response: 'Na5',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 5,
    player: 'opponent',
    color: 'white',
    piece: 'Pawn',
    capture: 1,
    time_taken_in_secs: 66,
    trade_initiated: 1,
    trade_back: 0,
    score: -1,
    score_change: -1,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 523.0,
    average_time: 15.4,
    created_time: '2023-10-15T19:36:06.859Z',
    updated_time: '2023-10-15T19:36:06.859Z',
  },
  {
    platform: 'lichess',
    move: 'Na5',
    fen: 'r1bqkb1r/ppp2ppp/5n2/n2Pp1N1/2B5/8/PPPP1PPP/RNBQK2R w KQkq - 1 6',
    best_possible_response:
      '6. Bb5+ c6 7. dxc6 bxc6 8. Bd3 Nd5 9. Nf3 Bd6 10. O-O Nf4 11. Nc3 Nxd3 12. cxd3 O-O 13. b3 Ba6 14. Re1 Re8 15. d4 exd4 16. Rxe8+ Qxe8 17. Nxd4 Bxh2+ 18. Kxh2 Qe5+ 19. Kg1 Qxd4',
    best_current_move:
      '5...Na5 6. Bb5+ c6 7. dxc6 bxc6 8. Bd3 Nd5 9. Nf3 Bd6 10. Nc3 O-O 11. Be2 f5 12. d3 e4 13. Nd4 Nxc3 14. bxc3 Qh4 15. Nb3 Nb7 16. Qd2 a5 17. Qg5',
    best_move: 'Na5',
    best_response: 'Bb5+',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 5,
    player: 'me',
    color: 'black',
    piece: 'Knight',
    capture: 0,
    time_taken_in_secs: 15,
    trade_initiated: 0,
    trade_back: 0,
    score: -25,
    score_change: -24,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 578.0,
    average_time: 4.4,
    created_time: '2023-10-15T19:36:07.265Z',
    updated_time: '2023-10-15T19:36:07.265Z',
  },
  {
    platform: 'lichess',
    move: 'Bb3',
    fen: 'r1bqkb1r/ppp2ppp/5n2/n2Pp1N1/8/1B6/PPPP1PPP/RNBQK2R b KQkq - 2 6',
    best_possible_response:
      '6...h6 7. Nf3 e4 8. Qe2 Nxb3 9. axb3 Be7 10. Ne5 Qxd5 11. Nc4 Bg4 12. Ne3 Bxe2 13. Nxd5 Nxd5 14. Kxe2 Nf4+ 15. Kf1 Ne6 16. Nc3 f5 17. g4 fxg4 18. Ra4 Nc5',
    best_current_move:
      '6. Bb5+ c6 7. dxc6 bxc6 8. Bd3 Nd5 9. Nf3 Bd6 10. O-O Nf4 11. Nc3 Nxd3 12. cxd3 O-O 13. b3 Ba6 14. Re1 Re8 15. d4 exd4 16. Rxe8+ Qxe8 17. Nxd4 Bxh2+ 18. Kxh2 Qe5+ 19. Kg1 Qxd4',
    best_move: 'Bb5+',
    best_response: 'h6',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 6,
    player: 'opponent',
    color: 'white',
    piece: 'Bishop',
    capture: 0,
    time_taken_in_secs: 39,
    trade_initiated: 0,
    trade_back: 0,
    score: 108,
    score_change: 133,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 484.0,
    average_time: 19.3,
    created_time: '2023-10-15T19:36:07.828Z',
    updated_time: '2023-10-15T19:36:07.828Z',
  },
  {
    platform: 'lichess',
    move: 'h6',
    fen: 'r1bqkb1r/ppp2pp1/5n1p/n2Pp1N1/8/1B6/PPPP1PPP/RNBQK2R w KQkq - 0 7',
    best_possible_response:
      '7. Nf3 e4 8. Qe2 Be7 9. Ne5 Nxb3 10. axb3 Qxd5 11. Nc4 Bg4 12. Ne3 Bxe2 13. Nxd5 Nxd5 14. Kxe2 f5 15. Ra5 c5 16. d3 exd3+ 17. cxd3',
    best_current_move:
      '6...h6 7. Nf3 e4 8. Qe2 Nxb3 9. axb3 Be7 10. Ne5 Qxd5 11. Nc4 Bg4 12. Ne3 Bxe2 13. Nxd5 Nxd5 14. Kxe2 Nf4+ 15. Kf1 Ne6 16. Nc3 f5 17. g4 fxg4 18. Ra4 Nc5',
    best_move: 'h6',
    best_response: 'Nf3',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 6,
    player: 'me',
    color: 'black',
    piece: 'Pawn',
    capture: 0,
    time_taken_in_secs: 27,
    trade_initiated: 0,
    trade_back: 0,
    score: 115,
    score_change: 7,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 551.0,
    average_time: 8.1,
    created_time: '2023-10-15T19:36:08.090Z',
    updated_time: '2023-10-15T19:36:08.090Z',
  },
  {
    platform: 'lichess',
    move: 'Nh3',
    fen: 'r1bqkb1r/ppp2pp1/5n1p/n2Pp3/8/1B5N/PPPP1PPP/RNBQK2R b KQkq - 1 7',
    best_possible_response:
      '7...Bg4 8. f3 Bxh3 9. gxh3 Nxb3 10. axb3 Bc5 11. Qe2 O-O 12. Nc3 Nxd5 13. Nxd5 Qxd5 14. d3 Bd4 15. Kd2 Rad8 16. c3 Bb6 17. Kc2 Rd6 18. Ra4 f5 19. Ra1 Rfd8 20. Rd1 Rg6',
    best_current_move:
      '7. Nf3 e4 8. Qe2 Be7 9. Ne5 Nxb3 10. axb3 Qxd5 11. Nc4 Bg4 12. Ne3 Bxe2 13. Nxd5 Nxd5 14. Kxe2 f5 15. Ra5 c5 16. d3 exd3+ 17. cxd3',
    best_move: 'Nf3',
    best_response: 'Bg4',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 7,
    player: 'opponent',
    color: 'white',
    piece: 'Knight',
    capture: 0,
    time_taken_in_secs: 67,
    trade_initiated: 0,
    trade_back: 0,
    score: 184,
    score_change: 69,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 417.0,
    average_time: 26.1,
    created_time: '2023-10-15T19:36:08.927Z',
    updated_time: '2023-10-15T19:36:08.927Z',
  },
  {
    platform: 'lichess',
    move: 'Nxb3',
    fen: 'r1bqkb1r/ppp2pp1/5n1p/3Pp3/8/1n5N/PPPP1PPP/RNBQK2R w KQkq - 0 8',
    best_possible_response:
      '8. axb3 Bg4 9. f3 Bxh3 10. gxh3 Bc5 11. Qe2 O-O 12. Nc3 Nxd5 13. Nxd5 Qxd5 14. d3 a5 15. Qe4 Qe6 16. Qc4 Qc6 17. Qe4 Qb6 18. Bd2 Bd4 19. c3 Bc5 20. O-O-O',
    best_current_move:
      '7...Bg4 8. f3 Bxh3 9. gxh3 Nxb3 10. axb3 Bc5 11. Qe2 O-O 12. Nc3 Nxd5 13. Nxd5 Qxd5 14. d3 Bd4 15. Kd2 Rad8 16. c3 Bb6 17. Kc2 Rd6 18. Ra4 f5 19. Ra1 Rfd8 20. Rd1 Rg6',
    best_move: 'Bg4',
    best_response: 'axb3',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 7,
    player: 'me',
    color: 'black',
    piece: 'Knight',
    capture: 1,
    time_taken_in_secs: 5,
    trade_initiated: 1,
    trade_back: 0,
    score: 185,
    score_change: 1,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 546.0,
    average_time: 7.7,
    created_time: '2023-10-15T19:36:09.648Z',
    updated_time: '2023-10-15T19:36:09.648Z',
  },
  {
    platform: 'lichess',
    move: 'axb3',
    fen: 'r1bqkb1r/ppp2pp1/5n1p/3Pp3/8/1P5N/1PPP1PPP/RNBQK2R b KQkq - 0 8',
    best_possible_response:
      '8...Bg4 9. f3 Bxh3 10. gxh3 Bc5 11. Qe2 O-O 12. Nc3 Nxd5 13. Nxd5 Qxd5 14. d3 a5 15. Qe4 Qd7 16. Be3 Bxe3 17. Qxe3 a4 18. bxa4 Rxa4 19. Kd2 Rf4 20. Qxe5 Rxf3',
    best_current_move:
      '8. axb3 Bg4 9. f3 Bxh3 10. gxh3 Bc5 11. Qe2 O-O 12. Nc3 Nxd5 13. Nxd5 Qxd5 14. d3 a5 15. Qe4 Qe6 16. Qc4 Qc6 17. Qe4 Qb6 18. Bd2 Bd4 19. c3 Bc5 20. O-O-O',
    best_move: 'axb3',
    best_response: 'Bg4',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 8,
    player: 'opponent',
    color: 'white',
    piece: 'Pawn',
    capture: 1,
    time_taken_in_secs: 3,
    trade_initiated: 0,
    trade_back: 1,
    score: 194,
    score_change: 9,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 414.0,
    average_time: 23.2,
    created_time: '2023-10-15T19:36:09.884Z',
    updated_time: '2023-10-15T19:36:09.884Z',
  },
  {
    platform: 'lichess',
    move: 'Bxh3',
    fen: 'r2qkb1r/ppp2pp1/5n1p/3Pp3/8/1P5b/1PPP1PPP/RNBQK2R w KQkq - 0 9',
    best_possible_response:
      '9. gxh3 Qxd5 10. O-O Bc5 11. d3 O-O 12. Nd2 Rae8 13. Qf3 e4 14. dxe4 Nxe4 15. Qg2 Kh7 16. Ra5 b6 17. Nxe4 Rxe4 18. Rxa7 Rfe8 19. Ra4 Qf5 20. Be3',
    best_current_move:
      '8...Bg4 9. f3 Bxh3 10. gxh3 Bc5 11. Qe2 O-O 12. Nc3 Nxd5 13. Nxd5 Qxd5 14. d3 a5 15. Qe4 Qd7 16. Be3 Bxe3 17. Qxe3 a4 18. bxa4 Rxa4 19. Kd2 Rf4 20. Qxe5 Rxf3',
    best_move: 'Bg4',
    best_response: 'gxh3',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 8,
    player: 'me',
    color: 'black',
    piece: 'Bishop',
    capture: 1,
    time_taken_in_secs: 1,
    trade_initiated: 0,
    trade_back: 1,
    score: 174,
    score_change: -20,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 545.0,
    average_time: 6.8,
    created_time: '2023-10-15T19:36:10.820Z',
    updated_time: '2023-10-15T19:36:10.820Z',
  },
  {
    platform: 'lichess',
    move: 'gxh3',
    fen: 'r2qkb1r/ppp2pp1/5n1p/3Pp3/8/1P5P/1PPP1P1P/RNBQK2R b KQkq - 0 9',
    best_possible_response:
      '9...Qxd5 10. O-O Bc5 11. d3 O-O 12. Re1 Rae8 13. Nc3 Qc6 14. Be3 Bd4 15. Bxd4 exd4 16. Ne2 Re5 17. f4 Rh5 18. Rxa7 Rxh3',
    best_current_move:
      '9. gxh3 Qxd5 10. O-O Bc5 11. d3 O-O 12. Nd2 Rae8 13. Qf3 e4 14. dxe4 Nxe4 15. Qg2 Kh7 16. Ra5 b6 17. Nxe4 Rxe4 18. Rxa7 Rfe8 19. Ra4 Qf5 20. Be3',
    best_move: 'gxh3',
    best_response: 'Qxd5',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 9,
    player: 'opponent',
    color: 'white',
    piece: 'Pawn',
    capture: 1,
    time_taken_in_secs: 11,
    trade_initiated: 0,
    trade_back: 1,
    score: 180,
    score_change: 6,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 403.0,
    average_time: 21.8,
    created_time: '2023-10-15T19:36:11.454Z',
    updated_time: '2023-10-15T19:36:11.454Z',
  },
  {
    platform: 'lichess',
    move: 'Qxd5',
    fen: 'r3kb1r/ppp2pp1/5n1p/3qp3/8/1P5P/1PPP1P1P/RNBQK2R w KQkq - 0 10',
    best_possible_response:
      '10. O-O Bc5 11. d3 O-O 12. Re1 Rae8 13. Be3 Bd4 14. Nc3 Qc6 15. Bxd4 exd4 16. Ne2 Qd7 17. Ng3 Qxh3 18. Qf3 c6 19. Qf5 Ng4 20. Qh5 Qxh5 21. Nxh5 Ne5 22. Kg2 Ng6',
    best_current_move:
      '9...Qxd5 10. O-O Bc5 11. d3 O-O 12. Re1 Rae8 13. Nc3 Qc6 14. Be3 Bd4 15. Bxd4 exd4 16. Ne2 Re5 17. f4 Rh5 18. Rxa7 Rxh3',
    best_move: 'Qxd5',
    best_response: 'O-O',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 9,
    player: 'me',
    color: 'black',
    piece: 'Queen',
    capture: 1,
    time_taken_in_secs: 1,
    trade_initiated: 0,
    trade_back: 1,
    score: 176,
    score_change: -4,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 544.0,
    average_time: 6.2,
    created_time: '2023-10-15T19:36:12.103Z',
    updated_time: '2023-10-15T19:36:12.103Z',
  },
  {
    platform: 'lichess',
    move: 'Rg1',
    fen: 'r3kb1r/ppp2pp1/5n1p/3qp3/8/1P5P/1PPP1P1P/RNBQK1R1 b Qkq - 1 10',
    best_possible_response:
      '10...Qe4+ 11. Kf1 Bc5 12. Rg3 Qh1+ 13. Rg1 Qc6 14. d3 e4 15. d4 Qd7 16. Rxg7 Qxh3+ 17. Ke1 O-O-O 18. Be3 Qxh2 19. Nc3 Rhe8 20. Qe2 Bxd4 21. Bxd4 Rxd4 22. Rxa7',
    best_current_move:
      '10. O-O Bc5 11. d3 O-O 12. Re1 Rae8 13. Be3 Bd4 14. Nc3 Qc6 15. Bxd4 exd4 16. Ne2 Qd7 17. Ng3 Qxh3 18. Qf3 c6 19. Qf5 Ng4 20. Qh5 Qxh5 21. Nxh5 Ne5 22. Kg2 Ng6',
    best_move: 'O-O',
    best_response: 'Qe4+',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 10,
    player: 'opponent',
    color: 'white',
    piece: 'Rook',
    capture: 0,
    time_taken_in_secs: 19,
    trade_initiated: 0,
    trade_back: 0,
    score: 190,
    score_change: 14,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 384.0,
    average_time: 21.6,
    created_time: '2023-10-15T19:36:13.158Z',
    updated_time: '2023-10-15T19:36:13.158Z',
  },
  {
    platform: 'lichess',
    move: 'Bc5',
    fen: 'r3k2r/ppp2pp1/5n1p/2bqp3/8/1P5P/1PPP1P1P/RNBQK1R1 w Qkq - 2 11',
    best_possible_response: '11. Nc3 Qc6',
    best_current_move:
      '10...Qe4+ 11. Kf1 Bc5 12. Rg3 Qh1+ 13. Rg1 Qc6 14. d3 e4 15. d4 Qd7 16. Rxg7 Qxh3+ 17. Ke1 O-O-O 18. Be3 Qxh2 19. Nc3 Rhe8 20. Qe2 Bxd4 21. Bxd4 Rxd4 22. Rxa7',
    best_move: 'Qe4+',
    best_response: 'Nc3',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 10,
    player: 'me',
    color: 'black',
    piece: 'Bishop',
    capture: 0,
    time_taken_in_secs: 28,
    trade_initiated: 0,
    trade_back: 0,
    score: 125,
    score_change: -65,
    inaccuracy: 1,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 516.0,
    average_time: 8.4,
    created_time: '2023-10-15T19:36:14.213Z',
    updated_time: '2023-10-15T19:36:14.213Z',
  },
  {
    platform: 'lichess',
    move: 'Nc3',
    fen: 'r3k2r/ppp2pp1/5n1p/2bqp3/8/1PN4P/1PPP1P1P/R1BQK1R1 b Qkq - 3 11',
    best_possible_response:
      '11...Qc6 12. d3 g6 13. Be3 Bb4 14. Bd2 a5 15. Qe2 O-O-O 16. O-O-O Rhe8 17. Rge1 b6 18. Kb1 Kb7 19. f3 Re6 20. h4 Nh5 21. Qf2 f5 22. Bxh6 Bxc3 23. bxc3 Qxc3 24. d4',
    best_current_move: '11. Nc3 Qc6',
    best_move: 'Nc3',
    best_response: 'Qc6',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 11,
    player: 'opponent',
    color: 'white',
    piece: 'Knight',
    capture: 0,
    time_taken_in_secs: 31,
    trade_initiated: 0,
    trade_back: 0,
    score: 121,
    score_change: -4,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 353.0,
    average_time: 22.4,
    created_time: '2023-10-15T19:36:15.295Z',
    updated_time: '2023-10-15T19:36:15.295Z',
  },
  {
    platform: 'lichess',
    move: 'Qc6',
    fen: 'r3k2r/ppp2pp1/2q2n1p/2b1p3/8/1PN4P/1PPP1P1P/R1BQK1R1 w Qkq - 4 12',
    best_possible_response:
      '12. d3 g6 13. Be3 Bb4 14. Bd2 a5 15. Qe2 O-O-O 16. O-O-O Rhe8 17. Rge1 b6 18. f3 Re6 19. Kb1 Nd5 20. Nxd5 Rxd5 21. Qe4 Bxd2 22. Rxd2 Rd4 23. Qxc6 Rxc6 24. Rxe5 Rf4 25. Re8+ Kd7 26. Rde2 Rxf3 27. R2e7+ Kd6',
    best_current_move:
      '11...Qc6 12. d3 g6 13. Be3 Bb4 14. Bd2 a5 15. Qe2 O-O-O 16. O-O-O Rhe8 17. Rge1 b6 18. Kb1 Kb7 19. f3 Re6 20. h4 Nh5 21. Qf2 f5 22. Bxh6 Bxc3 23. bxc3 Qxc3 24. d4',
    best_move: 'Qc6',
    best_response: 'd3',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 11,
    player: 'me',
    color: 'black',
    piece: 'Queen',
    capture: 0,
    time_taken_in_secs: 22,
    trade_initiated: 0,
    trade_back: 0,
    score: 118,
    score_change: -3,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 494.0,
    average_time: 9.6,
    created_time: '2023-10-15T19:36:16.214Z',
    updated_time: '2023-10-15T19:36:16.214Z',
  },
  {
    platform: 'lichess',
    move: 'd3',
    fen: 'r3k2r/ppp2pp1/2q2n1p/2b1p3/8/1PNP3P/1PP2P1P/R1BQK1R1 b Qkq - 0 12',
    best_possible_response:
      '12...g6 13. Be3 Bb4 14. Bd2 a5 15. Qe2 O-O-O 16. O-O-O Rhe8 17. Rge1 Re6 18. Kb1 b6 19. f3 h5 20. Qf1 Rd7 21. h4 Nd5 22. Nxd5 Rxd5 23. Re4 Bxd2 24. Rxd2',
    best_current_move:
      '12. d3 g6 13. Be3 Bb4 14. Bd2 a5 15. Qe2 O-O-O 16. O-O-O Rhe8 17. Rge1 b6 18. f3 Re6 19. Kb1 Nd5 20. Nxd5 Rxd5 21. Qe4 Bxd2 22. Rxd2 Rd4 23. Qxc6 Rxc6 24. Rxe5 Rf4 25. Re8+ Kd7 26. Rde2 Rxf3 27. R2e7+ Kd6',
    best_move: 'd3',
    best_response: 'g6',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 12,
    player: 'opponent',
    color: 'white',
    piece: 'Pawn',
    capture: 0,
    time_taken_in_secs: 21,
    trade_initiated: 0,
    trade_back: 0,
    score: 121,
    score_change: 3,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 332.0,
    average_time: 22.3,
    created_time: '2023-10-15T19:36:16.905Z',
    updated_time: '2023-10-15T19:36:16.905Z',
  },
  {
    platform: 'lichess',
    move: 'O-O-O',
    fen: '2kr3r/ppp2pp1/2q2n1p/2b1p3/8/1PNP3P/1PP2P1P/R1BQK1R1 w Q - 1 13',
    best_possible_response: '13. Be3',
    best_current_move:
      '12...g6 13. Be3 Bb4 14. Bd2 a5 15. Qe2 O-O-O 16. O-O-O Rhe8 17. Rge1 Re6 18. Kb1 b6 19. f3 h5 20. Qf1 Rd7 21. h4 Nd5 22. Nxd5 Rxd5 23. Re4 Bxd2 24. Rxd2',
    best_move: 'g6',
    best_response: 'Be3',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 12,
    player: 'me',
    color: 'black',
    piece: 'King',
    capture: 0,
    time_taken_in_secs: 8,
    trade_initiated: 0,
    trade_back: 0,
    score: 96,
    score_change: -25,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 486.0,
    average_time: 9.5,
    created_time: '2023-10-15T19:36:17.945Z',
    updated_time: '2023-10-15T19:36:17.945Z',
  },
  {
    platform: 'lichess',
    move: 'Be3',
    fen: '2kr3r/ppp2pp1/2q2n1p/2b1p3/8/1PNPB2P/1PP2P1P/R2QK1R1 b Q - 2 13',
    best_possible_response:
      '13...Rhe8 14. Bxc5 Qxc5 15. Qe2 e4 16. dxe4 Nxe4 17. Nxe4 Qb4+ 18. Kf1 Rxe4 19. Qf3 Qb5+ 20. c4 Qc6 21. Rg3 a6 22. Qxf7 g5 23. Qf3 Rd2 24. Re1 Rf4 25. Qxc6 Rfxf2+ 26. Kg1 bxc6 27. Re6 Rxb2 28. Rxh6',
    best_current_move: '13. Be3',
    best_move: 'Be3',
    best_response: 'Rhe8',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 13,
    player: 'opponent',
    color: 'white',
    piece: 'Bishop',
    capture: 0,
    time_taken_in_secs: 28,
    trade_initiated: 0,
    trade_back: 0,
    score: 92,
    score_change: -4,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 304.0,
    average_time: 22.7,
    created_time: '2023-10-15T19:36:18.757Z',
    updated_time: '2023-10-15T19:36:18.757Z',
  },
  {
    platform: 'lichess',
    move: 'e4',
    fen: '2kr3r/ppp2pp1/2q2n1p/2b5/4p3/1PNPB2P/1PP2P1P/R2QK1R1 w Q - 0 14',
    best_possible_response:
      '14. d4 Kb8 15. Na4 Bb4+ 16. c3 Bd6 17. c4 Qd7 18. Kd2 Qxh3 19. Kc2 h5 20. Rxg7 Rhg8 21. Rxf7 Ng4 22. Qe2',
    best_current_move:
      '13...Rhe8 14. Bxc5 Qxc5 15. Qe2 e4 16. dxe4 Nxe4 17. Nxe4 Qb4+ 18. Kf1 Rxe4 19. Qf3 Qb5+ 20. c4 Qc6 21. Rg3 a6 22. Qxf7 g5 23. Qf3 Rd2 24. Re1 Rf4 25. Qxc6 Rfxf2+ 26. Kg1 bxc6 27. Re6 Rxb2 28. Rxh6',
    best_move: 'Rhe8',
    best_response: 'd4',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 13,
    player: 'me',
    color: 'black',
    piece: 'Pawn',
    capture: 0,
    time_taken_in_secs: 5,
    trade_initiated: 0,
    trade_back: 0,
    score: 75,
    score_change: -17,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 481.0,
    average_time: 9.1,
    created_time: '2023-10-15T19:36:19.803Z',
    updated_time: '2023-10-15T19:36:19.803Z',
  },
  {
    platform: 'lichess',
    move: 'd4',
    fen: '2kr3r/ppp2pp1/2q2n1p/2b5/3Pp3/1PN1B2P/1PP2P1P/R2QK1R1 b Q - 0 14',
    best_possible_response: '14...a6',
    best_current_move:
      '14. d4 Kb8 15. Na4 Bb4+ 16. c3 Bd6 17. c4 Qd7 18. Kd2 Qxh3 19. Kc2 h5 20. Rxg7 Rhg8 21. Rxf7 Ng4 22. Qe2',
    best_move: 'd4',
    best_response: 'a6',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 14,
    player: 'opponent',
    color: 'white',
    piece: 'Pawn',
    capture: 0,
    time_taken_in_secs: 60,
    trade_initiated: 0,
    trade_back: 0,
    score: 84,
    score_change: 9,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 244.0,
    average_time: 25.4,
    created_time: '2023-10-15T19:36:20.842Z',
    updated_time: '2023-10-15T19:36:20.842Z',
  },
  {
    platform: 'lichess',
    move: 'Bb6',
    fen: '2kr3r/ppp2pp1/1bq2n1p/8/3Pp3/1PN1B2P/1PP2P1P/R2QK1R1 w Q - 1 15',
    best_possible_response:
      '15. Rxg7 Rhg8 16. Rg3 Qd7 17. Qd2 Rxg3 18. hxg3 Qxh3 19. O-O-O Qf5 20. Na4 Ng4 21. c4 c6 22. Nxb6+ axb6 23. d5 Nxe3 24. Qxe3',
    best_current_move: '14...a6',
    best_move: 'a6',
    best_response: 'Rxg7',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 14,
    player: 'me',
    color: 'black',
    piece: 'Bishop',
    capture: 0,
    time_taken_in_secs: 1,
    trade_initiated: 0,
    trade_back: 0,
    score: -27,
    score_change: -111,
    inaccuracy: 0,
    mistake: 1,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 480.0,
    average_time: 8.5,
    created_time: '2023-10-15T19:36:21.886Z',
    updated_time: '2023-10-15T19:36:21.886Z',
  },
  {
    platform: 'lichess',
    move: 'Rxg7',
    fen: '2kr3r/ppp2pR1/1bq2n1p/8/3Pp3/1PN1B2P/1PP2P1P/R2QK3 b Q - 0 15',
    best_possible_response:
      '15...Rhg8 16. Rg3 Qd7 17. Qe2 Rxg3 18. hxg3 Qxh3 19. O-O-O Qf5 20. Bxh6 Rxd4 21. Rh1 Rd8 22. Bf4 a6 23. Kb1 Nd5 24. Nxd5',
    best_current_move:
      '15. Rxg7 Rhg8 16. Rg3 Qd7 17. Qd2 Rxg3 18. hxg3 Qxh3 19. O-O-O Qf5 20. Na4 Ng4 21. c4 c6 22. Nxb6+ axb6 23. d5 Nxe3 24. Qxe3',
    best_move: 'Rxg7',
    best_response: 'Rhg8',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 15,
    player: 'opponent',
    color: 'white',
    piece: 'Rook',
    capture: 1,
    time_taken_in_secs: 29,
    trade_initiated: 1,
    trade_back: 0,
    score: -34,
    score_change: -7,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 215.0,
    average_time: 25.6,
    created_time: '2023-10-15T19:36:22.921Z',
    updated_time: '2023-10-15T19:36:22.921Z',
  },
  {
    platform: 'lichess',
    move: 'Nd5',
    fen: '2kr3r/ppp2pR1/1bq4p/3n4/3Pp3/1PN1B2P/1PP2P1P/R2QK3 w Q - 1 16',
    best_possible_response:
      '16. Qg4+ f5 17. Qxf5+ Kb8 18. Nxd5 Rxd5 19. Qf7 Rdd8 20. O-O-O a6 21. c4 Rhf8 22. Qh5 Rf6 23. Re7 a5 24. c5 Rfd6 25. Re5 a4 26. d5 Qb5 27. cxb6',
    best_current_move:
      '15...Rhg8 16. Rg3 Qd7 17. Qe2 Rxg3 18. hxg3 Qxh3 19. O-O-O Qf5 20. Bxh6 Rxd4 21. Rh1 Rd8 22. Bf4 a6 23. Kb1 Nd5 24. Nxd5',
    best_move: 'Rhg8',
    best_response: 'Qg4+',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 15,
    player: 'me',
    color: 'black',
    piece: 'Knight',
    capture: 0,
    time_taken_in_secs: 2,
    trade_initiated: 0,
    trade_back: 0,
    score: -293,
    score_change: -259,
    inaccuracy: 0,
    mistake: 0,
    blunder: 1,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 478.0,
    average_time: 8.1,
    created_time: '2023-10-15T19:36:23.882Z',
    updated_time: '2023-10-15T19:36:23.882Z',
  },
  {
    platform: 'lichess',
    move: 'Qg4+',
    fen: '2kr3r/ppp2pR1/1bq4p/3n4/3Pp1Q1/1PN1B2P/1PP2P1P/R3K3 b Q - 2 16',
    best_possible_response:
      '16...f5 17. Qxf5+ Kb8 18. Nxd5 Rxd5 19. Qf7 Rdd8 20. O-O-O a5 21. Rg4 a4 22. Qc4 axb3 23. Qxb3 Rhf8 24. c4 Rf5 25. c5 Ba5 26. Rdg1 Rfd5 27. Rg6 R5d6',
    best_current_move:
      '16. Qg4+ f5 17. Qxf5+ Kb8 18. Nxd5 Rxd5 19. Qf7 Rdd8 20. O-O-O a6 21. c4 Rhf8 22. Qh5 Rf6 23. Re7 a5 24. c5 Rfd6 25. Re5 a4 26. d5 Qb5 27. cxb6',
    best_move: 'Qg4+',
    best_response: 'f5',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 16,
    player: 'opponent',
    color: 'white',
    piece: 'Queen',
    capture: 0,
    time_taken_in_secs: 13,
    trade_initiated: 0,
    trade_back: 0,
    score: -302,
    score_change: -9,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 202.0,
    average_time: 24.8,
    created_time: '2023-10-15T19:36:24.582Z',
    updated_time: '2023-10-15T19:36:24.582Z',
  },
  {
    platform: 'lichess',
    move: 'Kb8',
    fen: '1k1r3r/ppp2pR1/1bq4p/3n4/3Pp1Q1/1PN1B2P/1PP2P1P/R3K3 w Q - 3 17',
    best_possible_response:
      '17. Nxd5 Qxd5 18. c4 Qc6 19. c5 Bxc5 20. dxc5 Qf6 21. Rc1 h5 22. Qg5 Qxb2 23. Kf1 Qxb3 24. Qf5 Rhg8 25. Rg3 h4 26. Rg4 Rxg4 27. hxg4 h3 28. Qxe4 a5 29. Qc4',
    best_current_move:
      '16...f5 17. Qxf5+ Kb8 18. Nxd5 Rxd5 19. Qf7 Rdd8 20. O-O-O a5 21. Rg4 a4 22. Qc4 axb3 23. Qxb3 Rhf8 24. c4 Rf5 25. c5 Ba5 26. Rdg1 Rfd5 27. Rg6 R5d6',
    best_move: 'f5',
    best_response: 'Nxd5',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 16,
    player: 'me',
    color: 'black',
    piece: 'King',
    capture: 0,
    time_taken_in_secs: 1,
    trade_initiated: 0,
    trade_back: 0,
    score: -310,
    score_change: -8,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 477.0,
    average_time: 7.6,
    created_time: '2023-10-15T19:36:25.172Z',
    updated_time: '2023-10-15T19:36:25.172Z',
  },
  {
    platform: 'lichess',
    move: 'Nxd5',
    fen: '1k1r3r/ppp2pR1/1bq4p/3N4/3Pp1Q1/1P2B2P/1PP2P1P/R3K3 b Q - 0 17',
    best_possible_response:
      '17...f5 18. Qg3 Rxd5 19. c4 f4 20. Rg8+ Rd8 21. Rxh8 Rxh8 22. Qxf4 Qe8 23. O-O-O h5 24. Rg1 Ba5 25. Qe5 Qxe5 26. dxe5 Bb4',
    best_current_move:
      '17. Nxd5 Qxd5 18. c4 Qc6 19. c5 Bxc5 20. dxc5 Qf6 21. Rc1 h5 22. Qg5 Qxb2 23. Kf1 Qxb3 24. Qf5 Rhg8 25. Rg3 h4 26. Rg4 Rxg4 27. hxg4 h3 28. Qxe4 a5 29. Qc4',
    best_move: 'Nxd5',
    best_response: 'f5',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 17,
    player: 'opponent',
    color: 'white',
    piece: 'Knight',
    capture: 1,
    time_taken_in_secs: 39,
    trade_initiated: 1,
    trade_back: 0,
    score: -308,
    score_change: 2,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 163.0,
    average_time: 25.7,
    created_time: '2023-10-15T19:36:26.021Z',
    updated_time: '2023-10-15T19:36:26.021Z',
  },
  {
    platform: 'lichess',
    move: 'Qxd5',
    fen: '1k1r3r/ppp2pR1/1b5p/3q4/3Pp1Q1/1P2B2P/1PP2P1P/R3K3 w Q - 0 18',
    best_possible_response:
      '18. c4 Qc6 19. c5 Bxc5 20. dxc5 Qf6 21. Qg3 Qxb2 22. Rc1 Rhe8 23. c6 b6 24. Kf1 Rc8 25. Kg1 f5 26. Rf1 h5 27. Bf4 h4 28. Bxc7+ Ka8 29. Qg6 Qxb3 30. Qxf5',
    best_current_move:
      '17...f5 18. Qg3 Rxd5 19. c4 f4 20. Rg8+ Rd8 21. Rxh8 Rxh8 22. Qxf4 Qe8 23. O-O-O h5 24. Rg1 Ba5 25. Qe5 Qxe5 26. dxe5 Bb4',
    best_move: 'f5',
    best_response: 'c4',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 17,
    player: 'me',
    color: 'black',
    piece: 'Queen',
    capture: 1,
    time_taken_in_secs: 7,
    trade_initiated: 0,
    trade_back: 1,
    score: -332,
    score_change: -24,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 470.0,
    average_time: 7.6,
    created_time: '2023-10-15T19:36:26.424Z',
    updated_time: '2023-10-15T19:36:26.424Z',
  },
  {
    platform: 'lichess',
    move: 'Bxh6',
    fen: '1k1r3r/ppp2pR1/1b5B/3q4/3Pp1Q1/1P5P/1PP2P1P/R3K3 b Q - 0 18',
    best_possible_response:
      '18...Qxd4 19. Rxf7 Qb4+ 20. c3 Qxb3 21. Rxc7 Bxc7 22. Be3 b6 23. Bf4 Qf7 24. Bxc7+ Qxc7 25. Qg3 Qxg3 26. fxg3',
    best_current_move:
      '18. c4 Qc6 19. c5 Bxc5 20. dxc5 Qf6 21. Qg3 Qxb2 22. Rc1 Rhe8 23. c6 b6 24. Kf1 Rc8 25. Kg1 f5 26. Rf1 h5 27. Bf4 h4 28. Bxc7+ Ka8 29. Qg6 Qxb3 30. Qxf5',
    best_move: 'c4',
    best_response: 'Qxd4',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 18,
    player: 'opponent',
    color: 'white',
    piece: 'Bishop',
    capture: 1,
    time_taken_in_secs: 51,
    trade_initiated: 0,
    trade_back: 1,
    score: 508,
    score_change: 840,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 112.0,
    average_time: 27.1,
    created_time: '2023-10-15T19:36:27.020Z',
    updated_time: '2023-10-15T19:36:27.020Z',
  },
  {
    platform: 'lichess',
    move: 'Rxh6',
    fen: '1k1r4/ppp2pR1/1b5r/3q4/3Pp1Q1/1P5P/1PP2P1P/R3K3 w Q - 0 19',
    best_possible_response:
      '19. O-O-O f5 20. Qg3 Rhh8 21. Kb1 a6 22. c4 f4 23. Qxf4 Qh5 24. Rdg1 Rhf8',
    best_current_move:
      '18...Qxd4 19. Rxf7 Qb4+ 20. c3 Qxb3 21. Rxc7 Bxc7 22. Be3 b6 23. Bf4 Qf7 24. Bxc7+ Qxc7 25. Qg3 Qxg3 26. fxg3',
    best_move: 'Qxd4',
    best_response: 'O-O-O',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 18,
    player: 'me',
    color: 'black',
    piece: 'Rook',
    capture: 1,
    time_taken_in_secs: 5,
    trade_initiated: 0,
    trade_back: 1,
    score: 406,
    score_change: -102,
    inaccuracy: 0,
    mistake: 1,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 465.0,
    average_time: 7.5,
    created_time: '2023-10-15T19:36:27.515Z',
    updated_time: '2023-10-15T19:36:27.515Z',
  },
  {
    platform: 'lichess',
    move: 'Rg8',
    fen: '1k1r2R1/ppp2p2/1b5r/3q4/3Pp1Q1/1P5P/1PP2P1P/R3K3 b Q - 1 19',
    best_possible_response:
      '19...Qxd4 20. Rxd8+ Qxd8 21. Rd1 Qe8 22. Qd7 Rh8 23. Qxe8+ Rxe8 24. h4 Kc8 25. Rd5 e3 26. fxe3 Bxe3 27. Rf5 Bc1+ 28. Kd1 Bxb2 29. Rxf7 Rd8+ 30. Ke1',
    best_current_move:
      '19. O-O-O f5 20. Qg3 Rhh8 21. Kb1 a6 22. c4 f4 23. Qxf4 Qh5 24. Rdg1 Rhf8',
    best_move: 'O-O-O',
    best_response: 'Qxd4',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 19,
    player: 'opponent',
    color: 'white',
    piece: 'Rook',
    capture: 0,
    time_taken_in_secs: 4,
    trade_initiated: 0,
    trade_back: 0,
    score: 490,
    score_change: 84,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 108.0,
    average_time: 25.8,
    created_time: '2023-10-15T19:36:28.495Z',
    updated_time: '2023-10-15T19:36:28.495Z',
  },
  {
    platform: 'lichess',
    move: 'Rh8',
    fen: '1k1r2Rr/ppp2p2/1b6/3q4/3Pp1Q1/1P5P/1PP2P1P/R3K3 w Q - 2 20',
    best_possible_response:
      '20. Rxh8 Rxh8 21. O-O-O Qe6 22. Qxe6 fxe6 23. b4 c6 24. Re1 Bxd4 25. Rxe4 Bxf2 26. Rxe6 Kc8 27. Re7',
    best_current_move:
      '19...Qxd4 20. Rxd8+ Qxd8 21. Rd1 Qe8 22. Qd7 Rh8 23. Qxe8+ Rxe8 24. h4 Kc8 25. Rd5 e3 26. fxe3 Bxe3 27. Rf5 Bc1+ 28. Kd1 Bxb2 29. Rxf7 Rd8+ 30. Ke1',
    best_move: 'Qxd4',
    best_response: 'Rxh8',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 19,
    player: 'me',
    color: 'black',
    piece: 'Rook',
    capture: 0,
    time_taken_in_secs: 2,
    trade_initiated: 0,
    trade_back: 0,
    score: 417,
    score_change: -73,
    inaccuracy: 1,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 463.0,
    average_time: 7.2,
    created_time: '2023-10-15T19:36:29.227Z',
    updated_time: '2023-10-15T19:36:29.227Z',
  },
  {
    platform: 'lichess',
    move: 'Rxh8',
    fen: '1k1r3R/ppp2p2/1b6/3q4/3Pp1Q1/1P5P/1PP2P1P/R3K3 b Q - 0 20',
    best_possible_response:
      '20...Rxh8 21. O-O-O f5 22. Qg7 Rd8 23. Kb1 c5 24. c4 Qd7 25. Qe5+ Bc7 26. Qxc5 Rh8 27. d5 Bb6 28. Qa3',
    best_current_move:
      '20. Rxh8 Rxh8 21. O-O-O Qe6 22. Qxe6 fxe6 23. b4 c6 24. Re1 Bxd4 25. Rxe4 Bxf2 26. Rxe6 Kc8 27. Re7',
    best_move: 'Rxh8',
    best_response: 'Rxh8',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 20,
    player: 'opponent',
    color: 'white',
    piece: 'Rook',
    capture: 1,
    time_taken_in_secs: 33,
    trade_initiated: 1,
    trade_back: 0,
    score: 407,
    score_change: -10,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 75.0,
    average_time: 26.2,
    created_time: '2023-10-15T19:36:29.769Z',
    updated_time: '2023-10-15T19:36:29.769Z',
  },
  {
    platform: 'lichess',
    move: 'Rxh8',
    fen: '1k5r/ppp2p2/1b6/3q4/3Pp1Q1/1P5P/1PP2P1P/R3K3 w Q - 0 21',
    best_possible_response:
      '21. O-O-O f5 22. Qg7 Rg8 23. Qh7 c6 24. Kb1 Qe6 25. h4 Qg6 26. Qxg6 Rxg6 27. c3 Bd8 28. h5 Rg5 29. h6 Rh5',
    best_current_move:
      '20...Rxh8 21. O-O-O f5 22. Qg7 Rd8 23. Kb1 c5 24. c4 Qd7 25. Qe5+ Bc7 26. Qxc5 Rh8 27. d5 Bb6 28. Qa3',
    best_move: 'Rxh8',
    best_response: 'O-O-O',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 20,
    player: 'me',
    color: 'black',
    piece: 'Rook',
    capture: 1,
    time_taken_in_secs: 1,
    trade_initiated: 0,
    trade_back: 1,
    score: 416,
    score_change: 9,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 462.0,
    average_time: 6.9,
    created_time: '2023-10-15T19:36:30.191Z',
    updated_time: '2023-10-15T19:36:30.191Z',
  },
  {
    platform: 'lichess',
    move: 'Rd1',
    fen: '1k5r/ppp2p2/1b6/3q4/3Pp1Q1/1P5P/1PP2P1P/3RK3 b - - 1 21',
    best_possible_response:
      '21...f5 22. Qg7 Rg8 23. Qe5 Qf7 24. c4 Re8 25. b4 Rxe5 26. dxe5 c6 27. b3 e3 28. Rd8+ Bxd8 29. fxe3 Qh7',
    best_current_move:
      '21. O-O-O f5 22. Qg7 Rg8 23. Qh7 c6 24. Kb1 Qe6 25. h4 Qg6 26. Qxg6 Rxg6 27. c3 Bd8 28. h5 Rg5 29. h6 Rh5',
    best_move: 'O-O-O',
    best_response: 'f5',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 21,
    player: 'opponent',
    color: 'white',
    piece: 'Rook',
    capture: 0,
    time_taken_in_secs: 13,
    trade_initiated: 0,
    trade_back: 0,
    score: 517,
    score_change: 101,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 62.0,
    average_time: 25.6,
    created_time: '2023-10-15T19:36:31.117Z',
    updated_time: '2023-10-15T19:36:31.117Z',
  },
  {
    platform: 'lichess',
    move: 'f5',
    fen: '1k5r/ppp5/1b6/3q1p2/3Pp1Q1/1P5P/1PP2P1P/3RK3 w - - 0 22',
    best_possible_response:
      '22. Qg7 Rg8 23. Qe5 Qf7 24. c4 a6 25. Kd2 Re8 26. Qf4 Bxd4 27. Kc2 Bb6 28. Rd5 e3 29. fxe3 Bxe3 30. Qxf5 Qg7 31. Qg4',
    best_current_move:
      '21...f5 22. Qg7 Rg8 23. Qe5 Qf7 24. c4 Re8 25. b4 Rxe5 26. dxe5 c6 27. b3 e3 28. Rd8+ Bxd8 29. fxe3 Qh7',
    best_move: 'f5',
    best_response: 'Qg7',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 21,
    player: 'me',
    color: 'black',
    piece: 'Pawn',
    capture: 0,
    time_taken_in_secs: 32,
    trade_initiated: 0,
    trade_back: 0,
    score: 531,
    score_change: 14,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 430.0,
    average_time: 8.0,
    created_time: '2023-10-15T19:36:32.091Z',
    updated_time: '2023-10-15T19:36:32.091Z',
  },
  {
    platform: 'lichess',
    move: 'Qg7',
    fen: '1k5r/ppp3Q1/1b6/3q1p2/3Pp3/1P5P/1PP2P1P/3RK3 b - - 1 22',
    best_possible_response:
      '22...Rg8 23. Qe5 Qf7 24. h4 a6 25. Kd2 Re8 26. Qf4 Bxd4 27. Kc1 Bb6 28. Qg5 Rg8 29. Qf4 Ka7 30. Kb1 Re8 31. Kc1 c6',
    best_current_move:
      '22. Qg7 Rg8 23. Qe5 Qf7 24. c4 a6 25. Kd2 Re8 26. Qf4 Bxd4 27. Kc2 Bb6 28. Rd5 e3 29. fxe3 Bxe3 30. Qxf5 Qg7 31. Qg4',
    best_move: 'Qg7',
    best_response: 'Rg8',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 22,
    player: 'opponent',
    color: 'white',
    piece: 'Queen',
    capture: 0,
    time_taken_in_secs: 13,
    trade_initiated: 0,
    trade_back: 0,
    score: 535,
    score_change: 4,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 49.0,
    average_time: 25.0,
    created_time: '2023-10-15T19:36:32.947Z',
    updated_time: '2023-10-15T19:36:32.947Z',
  },
  {
    platform: 'lichess',
    move: 'Rg8',
    fen: '1k4r1/ppp3Q1/1b6/3q1p2/3Pp3/1P5P/1PP2P1P/3RK3 w - - 2 23',
    best_possible_response:
      '23. Qe5 Qf7 24. Kd2 Re8 25. Qf4 Bxd4 26. Kc1 Qf6 27. Kb1 Bxb2 28. Rd7 Be5 29. Qd2 Bd6 30. Qd5 Ba3 31. c3 Bd6 32. Rxd6 Qxd6 33. Qxd6 cxd6 34. f4',
    best_current_move:
      '22...Rg8 23. Qe5 Qf7 24. h4 a6 25. Kd2 Re8 26. Qf4 Bxd4 27. Kc1 Bb6 28. Qg5 Rg8 29. Qf4 Ka7 30. Kb1 Re8 31. Kc1 c6',
    best_move: 'Rg8',
    best_response: 'Qe5',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 22,
    player: 'me',
    color: 'black',
    piece: 'Rook',
    capture: 0,
    time_taken_in_secs: 2,
    trade_initiated: 0,
    trade_back: 0,
    score: 535,
    score_change: 0,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 428.0,
    average_time: 7.8,
    created_time: '2023-10-15T19:36:33.684Z',
    updated_time: '2023-10-15T19:36:33.684Z',
  },
  {
    platform: 'lichess',
    move: 'Qe7',
    fen: '1k4r1/ppp1Q3/1b6/3q1p2/3Pp3/1P5P/1PP2P1P/3RK3 b - - 3 23',
    best_possible_response:
      '23...f4 24. Kd2 Bxd4 25. Kc1 Bxb2+ 26. Kxb2 Qxd1 27. Qxe4 Qd6 28. Qd3 Qf6+ 29. Ka2 Ka8 30. c3',
    best_current_move:
      '23. Qe5 Qf7 24. Kd2 Re8 25. Qf4 Bxd4 26. Kc1 Qf6 27. Kb1 Bxb2 28. Rd7 Be5 29. Qd2 Bd6 30. Qd5 Ba3 31. c3 Bd6 32. Rxd6 Qxd6 33. Qxd6 cxd6 34. f4',
    best_move: 'Qe5',
    best_response: 'f4',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 23,
    player: 'opponent',
    color: 'white',
    piece: 'Queen',
    capture: 0,
    time_taken_in_secs: 29,
    trade_initiated: 0,
    trade_back: 0,
    score: 590,
    score_change: 55,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 20.0,
    average_time: 25.2,
    created_time: '2023-10-15T19:36:34.394Z',
    updated_time: '2023-10-15T19:36:34.394Z',
  },
  {
    platform: 'lichess',
    move: 'f4',
    fen: '1k4r1/ppp1Q3/1b6/3q4/3Ppp2/1P5P/1PP2P1P/3RK3 w - - 0 24',
    best_possible_response:
      '24. Qe5 Qf7 25. Kd2 Re8 26. Qb5 e3+ 27. fxe3 fxe3+ 28. Kc1 e2 29. Re1 c6 30. Qd3 Bc7 31. c3 Qf2 32. Rxe2 Rxe2',
    best_current_move:
      '23...f4 24. Kd2 Bxd4 25. Kc1 Bxb2+ 26. Kxb2 Qxd1 27. Qxe4 Qd6 28. Qd3 Qf6+ 29. Ka2 Ka8 30. c3',
    best_move: 'f4',
    best_response: 'Qe5',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 23,
    player: 'me',
    color: 'black',
    piece: 'Pawn',
    capture: 0,
    time_taken_in_secs: 4,
    trade_initiated: 0,
    trade_back: 0,
    score: 556,
    score_change: -34,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 424.0,
    average_time: 7.6,
    created_time: '2023-10-15T19:36:35.431Z',
    updated_time: '2023-10-15T19:36:35.431Z',
  },
  {
    platform: 'lichess',
    move: 'f3',
    fen: '1k4r1/ppp1Q3/1b6/3q4/3Ppp2/1P3P1P/1PP4P/3RK3 b - - 0 24',
    best_possible_response:
      '24...Rg1+ 25. Ke2 exf3+ 26. Kf2 Bxd4+ 27. Rxd4 Qxd4+ 28. Kxf3 Qd5+ 29. Kxf4 Qd4+ 30. Kf5 Qf2+ 31. Ke6 Re1+ 32. Kd7 Qf5+ 33. Ke8 Qg6+ 34. Kd8 Rd1+ 35. Qd7 Qf6+ 36. Ke8 Re1+ 37. Qe6 Rxe6+ 38. Kd7 Qe7#',
    best_current_move:
      '24. Qe5 Qf7 25. Kd2 Re8 26. Qb5 e3+ 27. fxe3 fxe3+ 28. Kc1 e2 29. Re1 c6 30. Qd3 Bc7 31. c3 Qf2 32. Rxe2 Rxe2',
    best_move: 'Qe5',
    best_response: 'Rg1+',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 24,
    player: 'opponent',
    color: 'white',
    piece: 'Pawn',
    capture: 0,
    time_taken_in_secs: 14,
    trade_initiated: 0,
    trade_back: 0,
    score: 150000,
    score_change: 149444,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 6.0,
    average_time: 24.7,
    created_time: '2023-10-15T19:36:35.906Z',
    updated_time: '2023-10-15T19:36:35.906Z',
  },
  {
    platform: 'lichess',
    move: 'e3',
    fen: '1k4r1/ppp1Q3/1b6/3q4/3P1p2/1P2pP1P/1PP4P/3RK3 w - - 0 25',
    best_possible_response:
      '25. Ke2 a6 26. Qe5 Rg2+ 27. Kd3 Qxf3 28. Re1 Qf2 29. c3 Qxe1 30. Qxf4 Ka7 31. Qxe3 Qd1+ 32. Kc4 Qf1+ 33. Kd5 Qf7+ 34. Ke4',
    best_current_move:
      '24...Rg1+ 25. Ke2 exf3+ 26. Kf2 Bxd4+ 27. Rxd4 Qxd4+ 28. Kxf3 Qd5+ 29. Kxf4 Qd4+ 30. Kf5 Qf2+ 31. Ke6 Re1+ 32. Kd7 Qf5+ 33. Ke8 Qg6+ 34. Kd8 Rd1+ 35. Qd7 Qf6+ 36. Ke8 Re1+ 37. Qe6 Rxe6+ 38. Kd7 Qe7#',
    best_move: 'Rg1+',
    best_response: 'Ke2',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 24,
    player: 'me',
    color: 'black',
    piece: 'Pawn',
    capture: 0,
    time_taken_in_secs: 2,
    trade_initiated: 0,
    trade_back: 0,
    score: 843,
    score_change: -149157,
    inaccuracy: 0,
    mistake: 0,
    blunder: 1,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 422.0,
    average_time: 7.4,
    created_time: '2023-10-15T19:36:36.947Z',
    updated_time: '2023-10-15T19:36:36.947Z',
  },
  {
    platform: 'lichess',
    move: 'h4',
    fen: '1k4r1/ppp1Q3/1b6/3q4/3P1p1P/1P2pP2/1PP4P/3RK3 b - - 0 25',
    best_possible_response:
      '25...Qxf3 26. Qf8+ Rxf8 27. Rd2 Rg8 28. Rg2 Rxg2 29. c3 Rg1#',
    best_current_move:
      '25. Ke2 a6 26. Qe5 Rg2+ 27. Kd3 Qxf3 28. Re1 Qf2 29. c3 Qxe1 30. Qxf4 Ka7 31. Qxe3 Qd1+ 32. Kc4 Qf1+ 33. Kd5 Qf7+ 34. Ke4',
    best_move: 'Ke2',
    best_response: 'Qxf3',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 25,
    player: 'opponent',
    color: 'white',
    piece: 'Pawn',
    capture: 0,
    time_taken_in_secs: 2,
    trade_initiated: 0,
    trade_back: 0,
    score: 50000,
    score_change: 49157,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 4.0,
    average_time: 23.8,
    created_time: '2023-10-15T19:36:37.002Z',
    updated_time: '2023-10-15T19:36:37.002Z',
  },
  {
    platform: 'lichess',
    move: 'Qxf3',
    fen: '1k4r1/ppp1Q3/1b6/8/3P1p1P/1P2pq2/1PP4P/3RK3 w - - 0 26',
    best_possible_response:
      '26. Qf8+ Rxf8 27. Rd2 Rg8 28. Rg2 Rxg2 29. c3 Rg1#',
    best_current_move:
      '25...Qxf3 26. Qf8+ Rxf8 27. Rd2 Rg8 28. Rg2 Rxg2 29. c3 Rg1#',
    best_move: 'Qxf3',
    best_response: 'Qf8+',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 25,
    player: 'me',
    color: 'black',
    piece: 'Queen',
    capture: 1,
    time_taken_in_secs: 1,
    trade_initiated: 1,
    trade_back: 0,
    score: 40000,
    score_change: -10000,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 421.0,
    average_time: 7.1,
    created_time: '2023-10-15T19:36:37.043Z',
    updated_time: '2023-10-15T19:36:37.043Z',
  },
  {
    platform: 'lichess',
    move: 'Rd3',
    fen: '1k4r1/ppp1Q3/1b6/8/3P1p1P/1P1Rpq2/1PP4P/4K3 b - - 1 26',
    best_possible_response: '26...Rg1#',
    best_current_move: '26. Qf8+ Rxf8 27. Rd2 Rg8 28. Rg2 Rxg2 29. c3 Rg1#',
    best_move: 'Qf8+',
    best_response: 'Rg1#',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 26,
    player: 'opponent',
    color: 'white',
    piece: 'Rook',
    capture: 0,
    time_taken_in_secs: 2,
    trade_initiated: 0,
    trade_back: 0,
    score: 10000,
    score_change: -30000,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 2.0,
    average_time: 23.0,
    created_time: '2023-10-15T19:36:37.081Z',
    updated_time: '2023-10-15T19:36:37.081Z',
  },
  {
    platform: 'lichess',
    move: 'Rg1#',
    fen: '1k6/ppp1Q3/1b6/8/3P1p1P/1P1Rpq2/1PP4P/4K1r1 w - - 2 27',
    best_possible_response: '',
    best_current_move: '26...Rg1#',
    best_move: 'Rg1#',
    best_response: '',
    end_time: '2022-08-27T14:32:05Z',
    move_index: 26,
    player: 'me',
    color: 'black',
    piece: 'Rook',
    capture: 0,
    time_taken_in_secs: 2,
    trade_initiated: 0,
    trade_back: 0,
    score: 0,
    score_change: -10000,
    inaccuracy: 0,
    mistake: 0,
    blunder: 0,
    reason: null,
    alternate_move: null,
    time_left_on_clock: 419.0,
    average_time: 6.9,
    created_time: '2023-10-15T19:36:37.117Z',
    updated_time: '2023-10-15T19:36:37.117Z',
  },
];
