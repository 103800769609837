import ReactGA from 'react-ga';
import {GA4_TRACKING_ID} from './Constants';

// utils/ga4.js

export const pageview = url => {
  window?.gtag('config', GA4_TRACKING_ID, {
    page_path: url,
  });
};

export const event = ({action, params}) => {
  window?.gtag('event', action, params);
};

/**
 * Logs an event in Google Analytics.
 * @param {string} category - Typically the object that was interacted with (e.g., 'User', 'Navigation', 'Button').
 * @param {string} action - The type of interaction (e.g., 'click', 'submit').
 * @param {string} [label] - Optional: Additional information about the event.
 * @param {number} [value] - Optional: A numeric value associated with the event.
 */
export const logEvent = (type = 'event', action, label = '') => {
  try {
    if (type === 'event') {
      // ReactGA.event({
      //   category,
      //   action,
      //   label,
      //   value,
      // });
      event(action, label);
    } else if (type === 'pageview') {
      pageview(window.location.pathname);
      // ReactGA.pageview(window.location.pathname);
    }
  } catch (e) {
    //pass
  }
};

export const getPageLoadTime = () => {
  if (window.performance) {
    const timings = window.performance.timing;
    const loadTime = timings.loadEventEnd - timings.navigationStart;

    return loadTime; // Load time in milliseconds
  }
  return null;
};

// const usePageLoadTime = () => {
//     const startTime = useRef(new Date().getTime());
//     const isFocused = useIsFocused();

//     useEffect(() => {
//       if (isFocused) {
//         const endTime = new Date().getTime();
//         const loadTime = endTime - startTime.current;
//         return loadTime; // Load time in milliseconds
//       }
//     }, [isFocused]);

//     return null;
//   };
