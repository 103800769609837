import CCColors from '@/Utils/CCColors';
import {isPhone} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignSelf: 'flex-start',
    marginLeft: '5%',
    marginRight: '5%',
    marginTop: 30,
    width: !isPhone && '50%',
  },
  circularProgress: {
    width: 180,
    height: 180,
    backgroundColor: CCColors.White,
    borderRadius: '50%',
  },
  pieceSection: {
    backgroundColor: CCColors.White,
    borderRadius: 8,
    padding: 20,
    borderWidth: 1,
    borderColor: CCColors.Box.MiddleLine,
  },
  tooltip: {
    backgroundColor: CCColors.Black,
    width: 185,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  goBack: {
    backgroundColor: CCColors.White,
    width: '20%',
    padding: 15,
    borderRadius: 8,
  },
});

export default styles;
