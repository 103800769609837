import ChessRestClient from '@/Api/ChessRestClient';
// import {SetAvatarResponse} from './types';
import {useMutation} from '@tanstack/react-query';

export default function useSaveGameMoveAnnotation(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<
    any,
    Error,
    {
      gameMoveId: number;
      annotation: string;
    }
  >(
    async details => {
      const {gameMoveId, annotation} = details;
      const response = await ChessRestClient.post('save-move-annotation/', {
        game_move_id: gameMoveId,
        annotation: annotation,
      });
      return response.data;
    },
    {
      onSuccess: data => {
        handleOnSuccess?.(data);
      },
      onError: data => {
        handleOnError?.(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
