import {
  FlatListProps,
  ListRenderItem,
  ListRenderItemInfo,
  RefreshControl,
} from 'react-native';
import React, {useCallback} from 'react';

import Animated from 'react-native-reanimated';
import Spacer from '../Spacer/Spacer';
import {vs} from '../../Utils/Dimensions';

export interface ICCFlatlistProps extends FlatListProps<any> {
  loading?: boolean;
  refreshing?: boolean;
  renderPlaceholder?: ListRenderItem<any> | null | undefined;
}

export default function CCFlatlist<T>(props: ICCFlatlistProps) {
  const {
    renderPlaceholder,
    refreshing = false,
    data,
    onRefresh = () => {},
    loading = false,
    ItemSeparatorComponent,
    ...rest
  } = props;

  const finalData =
    loading && !data?.length ? [{id: 1}, {id: 2}, {id: 3}] : data;

  const renderItem = (d: ListRenderItemInfo<T>) => {
    if (loading && !data?.length) {
      return renderPlaceholder?.(d);
    }

    return props?.renderItem?.(d) ?? null;
  };

  // Horizontal Item Separator is buggy in the ReAnimated.
  // So append Space at the end of Item by yourself
  const ItemSeparator = useCallback(() => {
    if (ItemSeparatorComponent) {
      return <ItemSeparatorComponent />;
    }

    return <Spacer horizontal={props.horizontal ?? false} spacing={vs(16)} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Animated.FlatList
      data={finalData}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
      refreshing={refreshing}
      ItemSeparatorComponent={ItemSeparator}
      {...rest}
      renderItem={renderItem}
    />
  );
}
