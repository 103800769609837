import CCColors, {updateColorWithOpacity} from "@/Utils/CCColors";
import { StyleSheet } from "react-native";
import { FontSizes, hs, isPhone, vs } from '@/Utils/Dimensions';

export const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        // marginTop: 22,
        backgroundColor: 'rgba(0, 0, 0, 0.5)'

    },
    modalView: {
        width: isPhone ? '90%' : 540,
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 8,
        padding: 35,
        alignItems: 'center',
        borderColor: updateColorWithOpacity(CCColors.TextColor.Dark, 0.2),
        borderWidth: 1
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    buttonOpen: {
        backgroundColor: '#F194FF',
    },
    buttonClose: {
        backgroundColor: '#2196F3',
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
    },
    reviewGameActionText: {
        fontSize: 16,
        lineHeight: 24,
        fontWeight: '500',
        paddingRight: 8,
        textAlign: 'center'
        // color: ''
    },
    reviewGameActionCoinsContainer: {
        backgroundColor: CCColors.Primary.Light,
        borderRadius: 100,
        padding: 4,
        paddingHorizontal: 16,
        // flex: 1,
        flexDirection: 'row',
        // height: 32
    },

});