import CCColors from '@/Utils/CCColors';
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: CCColors.Primary.lightBrown,
    borderRadius: 8,
    padding: 15,
    marginRight: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 3,
  },
  cell1: {
    flex: 0.85,
  },
  cell2: {
    flex: 0.1,
  },
});

export default styles;
