import ChessRestClient from '@/Api/ChessRestClient';
import {useMutation} from '@tanstack/react-query';
import {autoCompletionResponse} from './types';

export default function useGetInputAutoCompletion(
  handleOnSuccess?: (data) => void,
  handleOnError?: (data) => void,
) {
  const mutation = useMutation<autoCompletionResponse, any, {name: string}>({
    mutationFn: async ({name}) => {
      const params = new URLSearchParams({ name });
      if (name) {
        params.append('name', name);
      }

      try {
        const response = await ChessRestClient.get(
          `/v1/tournament-name/?${params.toString()}`,
        );
        // console.log('Response data:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error during auto-completion fetch:', error);
        throw error;
      }
    },
    onSuccess: data => {
      if (handleOnSuccess) handleOnSuccess(data);
    },
    onError: error => {
      if (handleOnError) handleOnError(error);
    },
  });

  return mutation;
}
