import React, {useState} from 'react';
import {View, Platform} from 'react-native';
import CCText from '@/Atoms/CCText';
import {Popover, ArrowContainer} from 'react-tiny-popover';

const Tooltip = ({
  children,
  text,
  position = 'top',
  backgroundColor,
  textcolor,
  borderColor,
  arrowColor,
  fontSize,
  width,
  height,
  paddingVertical,
  borderWidth,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggle() {
    setIsOpen(prev => !prev);
  }

  const popoverContainerStyle = {
    backgroundColor: backgroundColor || '#000',
    borderRadius: 8,
    paddingVertical: paddingVertical || 12,
    paddingHorizontal: 16,
    maxWidth: width || 300,
    height: height || undefined,
    borderWidth: borderWidth || 1,
    borderColor: borderColor || '#000',
    borderStyle: 'solid',
  };

  const content = ({position: arrowPosition, childRect, popoverRect}) => (
    <ArrowContainer
      position={arrowPosition}
      childRect={childRect}
      popoverRect={popoverRect}
      arrowColor={arrowColor || '#000'}
      arrowSize={8}>
      <View style={popoverContainerStyle}>
        <CCText color={textcolor || '#fff'} fontSize={fontSize || 13}>
          {text}
        </CCText>
      </View>
    </ArrowContainer>
  );

  return (
    <Popover
      isOpen={isOpen}
      positions={[position]}
      align="center"
      padding={8}
      reposition={true}
      onClickOutside={() => setIsOpen(false)}
      containerStyle={{zIndex: 10000}}
      containerClassName="tooltip-container"
      container={Platform.OS === 'web' ? document.body : undefined}
      content={content}>
      {children(toggle)}
    </Popover>
  );
};

export default Tooltip;
