import CCColors from '@/Utils/CCColors';
import {Dimensions, StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  inputLabelStyle: {
    fontSize: 16,
    marginBottom: 6,
    marginTop: 20,
  },
  footerButton: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: CCColors.White,
    padding: '2%',
  },
});
