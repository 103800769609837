import React, {useEffect, useState} from 'react';
import {StyleSheet, View, ActivityIndicator, Text} from 'react-native';
import useGetUserOTBGames from '@/Hooks/GetUserOTBGames/useGetUserOTBGames'; // Adjust this import path as needed
import useGAPageView from '@/navigators/useGAPageView';
import {ScreenName} from '@/navigators/StackConstants';
import CCPackageTableNew from '@/Atoms/CCPackageTableNew';
import CCButton from '@/Atoms/CCButton';
import {useNavigation} from '@react-navigation/native';
import CCText from '@/Atoms/CCText';
import AddGameModal from './components/AddGame/index';
import Box from '@/Atoms/Box';
import CCPackageTable from '@/Atoms/CCPackageTable';

function OTBGames(props) {
  useGAPageView(ScreenName.OTBGames);
  const [visible, setVisible] = useState<boolean>(false);
  const navigation = useNavigation();
  const [displayData, setDisplayData] = useState<any[]>([]);
  const {data, isLoading, error, refetch} = useGetUserOTBGames({});
  const [uuid, setUuid] = useState();
  const [message, setMessage] = useState<{
    type: 'success' | 'error';
    text: string;
  } | null>(null);

  useEffect(() => {
    setDisplayData(data);
  }, [data]);

  const handleFormSubmit = async () => {
    try {
      await refetch();
      setMessage({type: 'success', text: 'Game added successfully!'});
    } catch (error) {
      setMessage({
        type: 'error',
        text: 'Failed to add game. Please select the tournamnet from the dropdown.',
      });
    } finally {
      setTimeout(() => setMessage(null), 3000);
    }
  };

  // Define table headers
  const headers = [
    'Date',
    'Tournament',
    // 'Player Name',
    // 'Player rating',
    'Round',
    'Opponent',
    'Opp. rating',
    'Color',
    'Result',
    'Feed Game',
  ];

  const tableData =
    displayData?.data?.map(game => [
      {name: game?.sms_sent_timestamp?.slice(0, 10), type: 'text'},
      {name: game.tournament_name, type: 'text'},
      // {name: game.player_name, type: 'text'},
      // {name: game.player_fide_rating, type: 'text'},
      {name: game.round_id, type: 'text'},
      {name: game.opponent_name, type: 'text'},
      {name: game.opponent_fide_rating, type: 'text'},
      {name: game.round_color, type: 'text'},
      {
        name:
          game?.round_result == 1
            ? 'Won'
            : game?.round_result == 0
            ? 'Loss'
            : game?.round_result == 0.5
            ? 'Draw'
            : 'Unknown',
        type: 'text',
      },
      {
        name: (
          <CCButton
            style={{width: 90}}
            onPress={() => {
              navigation.navigate(ScreenName.SaveOTBGames, {
                uuid: game?.game_uuid,
              });
            }}>
            <CCText
              fontSize={12}
              textAlign="center"
              style={{paddingLeft: 0, paddingRight: 0, whiteSpace: 'nowrap'}}>
              Feed Game
            </CCText>
          </CCButton>
        ),
        type: 'button',
      },
    ]) || [];

  const buttonElement = data => (
    <CCButton type="outline" color="blue">
      Feed Game
    </CCButton>
  );

  return (
    <View style={styles.container}>
      {isLoading ? (
        <ActivityIndicator size="large" />
      ) : error ? (
        <Text>Error: {error.message}</Text>
      ) : (
        <View>
          {message && (
            <View
              style={[
                styles.messageContainer,
                message.type === 'success' ? styles.success : styles.error,
              ]}>
              <Text style={styles.messageText}>{message.text}</Text>
            </View>
          )}
          <CCButton
            style={{
              marginBottom: 10,
              justifyContent: 'center',
              width: 150,
              alignItems: 'center',
              alignSelf: 'center',
              marginTop: 10,
            }}
            onPress={() => {
              setVisible(true);
            }}>
            <CCText style={{whiteSpace: 'nowrap'}}>Add Game</CCText>
          </CCButton>
          <CCPackageTableNew
            headers={headers}
            tableData={tableData}
            flexArr={[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
            // rowProps={{
            //   flexArr: [2, 2, 2, 2, 2, 2, 2, 2, 2],
            // }}
            cellProps={{
              numberOfLines: 2,
              multiline: true,
            }}
          />
        </View>
      )}
      <AddGameModal
        setDisplayData={setDisplayData}
        setVisible={setVisible}
        visible={visible}
        uuid={uuid}
        submitCallback={handleFormSubmit}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    // height: '10%',
    // alignItems: 'center',
  },
  messageContainer: {
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  success: {
    backgroundColor: 'green',
  },
  error: {
    backgroundColor: 'red',
  },
  messageText: {
    color: 'white',
  },
});

export default OTBGames;
