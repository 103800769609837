import React from 'react';
import {Text, TouchableOpacity} from 'react-native';
import {View, StyleSheet} from 'react-native';
import CCText from '../CCText';
import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';

//progress bar
// type = primary which has default yellow color
// type = secondary which has aqua color
const ProgressBar = ({
  progress,
  style,
  fillStyle,
  text,
  icon,
  type = 'primary',
  onPointerMove = () => {},
  onPointerLeave = () => {},
}) => {
  const isSecondaryType = type === 'secondary';
  return (
    <View
      onPointerMove={onPointerMove}
      onPointerLeave={onPointerLeave}
      style={[
        isSecondaryType ? styles.backgroundSecondary : styles.background,
        style,
      ]}>
      <View
        style={[
          isSecondaryType ? styles.fillSecondary : styles.fill,
          {width: progress ? `${progress * 100}%` : '0%'},
          fillStyle,
        ]}></View>
      {text && (
        <View
          style={{
            margin: 'auto',
            position: 'absolute',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          {icon && icon}
          <CCText style={{textAlign: 'center'}}>{text}</CCText>
        </View>
      )}
    </View>
  );
};

//how to use
const App = () => {
  const progress = 0.5; // 50%

  return (
    <View style={styles.container}>
      <ProgressBar progress={progress} />
    </View>
  );
};

//progress bar style
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  backgroundSecondary: {
    height: '100%',
    backgroundColor: CCColors.Grey.Border,
    borderRadius: 40,
  },
  background: {
    height: '100%',
    backgroundColor: updateColorWithOpacity(CCColors.Primary.Default, 0.3),
    borderRadius: 40,
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  fill: {
    height: '100%',
    backgroundColor: CCColors.Primary.Default,
    borderRadius: 40,
  },
  fillSecondary: {
    height: '100%',
    backgroundColor: CCColors.Primary.Aqua,
    borderRadius: 40,
  },
});

export default ProgressBar;
