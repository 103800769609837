import create from 'zustand';

interface Notification {
  id: string;
  message: string;
  timestamp: string; // ISO string for consistent formatting
  isNew: boolean; // Indicates whether the notification is unread
}

interface NotificationStore {
  notifications: Notification[];
  refetchStudy: boolean;
  setRefetchStudy: (refetchStudy: boolean) => void;
  addNotification: (notification: Notification) => void;
  markAllAsRead: () => void;
  removeNotification: (id: string) => void;
  hasNewNotifications: () => boolean;
}

export const useNotificationStore = create<NotificationStore>((set, get) => ({
  notifications: [],
  refetchStudy: false,
  setRefetchStudy: refetchStudy => set({refetchStudy: refetchStudy}),
  addNotification: notification =>
    set(state => {
      const exists = state.notifications.some(
        notif => notif.id === notification.id,
      );
      if (exists) return state;
      return {
        notifications: [{...notification, isNew: true}, ...state.notifications],
      };
    }),
  markAllAsRead: () =>
    set(state => ({
      notifications: state.notifications.map(notif => ({
        ...notif,
        isNew: false,
      })),
    })),
  removeNotification: id =>
    set(state => ({
      notifications: state.notifications.filter(notif => notif.id !== id),
    })),
  hasNewNotifications: () => get().notifications.some(notif => notif.isNew),
}));
