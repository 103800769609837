import {StyleSheet} from 'react-native';
import CCColors from '@/Utils/CCColors';

const styles = StyleSheet.create({
  tournamentDetails: {
    backgroundColor: CCColors.White,
    borderRadius: 20,
    padding: 20,
    marginLeft: '2%',
    marginRight: '2%',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  puzzleSolutionText: {
    padding: 2,
    textAlign: 'center',
    fontSize: 20,
    fontWeight: '500',
  },
  playerScore: {
    flex: 1,
  },
  selectedMove: {
    backgroundColor: CCColors.Primary.lightYellow,
    borderColor: CCColors.Primary.Default,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  eachMoveWrapper1: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  eachMoveWrapper: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  eachMoveInsideWrapper: {
    marginTop: 15,
    flexDirection: 'row',
  },
  eachMoveWrapper2: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 0,
    backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  chessboard: {
    flex: 0.5,
    height: '100%',
  },
  left: {
    flex: 0.2,
  },
  middle: {
    flex: 0.5,
  },
  winningBar: {
    // width: isPhone ? '99vw' : 25,
    justifyContent: 'center',
    margin: 0,
    marginHorizontal: 0,
  },
});

export default styles;
