import {StyleSheet} from 'react-native';
import CCColors from '@/Utils/CCColors';

const styles = StyleSheet.create({
  input: {width: '100%', backgroundColor: CCColors.White},
  autoCompleteFlatList: {
    position: 'absolute',
    width: '100%',
    top: '120%',
    left: 0,
    backgroundColor: CCColors.White,
    borderColor: CCColors.BorderBottom,
    borderRadius: 10,
  },
  divider: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export default styles;
