import React, {useEffect} from 'react';
import * as SentryReact from '@sentry/react';
import ENV from '../environment';
const SentryWrapper = () => {
  const dsnNumber = ENV.SENTRY_DSN;
  useEffect(() => {
    if (!ENV.DEBUG_ENABLED) {
      SentryReact.init({
        dsn: dsnNumber,
        tracesSampleRate: 1.0,
      });
    }

    return () => {
      SentryReact.close();
    };
  }, [dsnNumber]);

  return null;
};

export default SentryWrapper;
