import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import CCColors, { updateColorWithOpacity } from "../../Utils/CCColors";
import React, { useEffect } from "react";

import CCText from "../CCText";
import { ISPRadioProps } from "./types";
import { Pressable } from "react-native";
import Styles from "./styles";

export default function CCRadio(props: ISPRadioProps) {
  const {
    disabled,
    label,
    onToggle,
    selected,
    unselectedColor,
    style = {},
  } = props;

  const animated = useSharedValue(selected ? 1 : 0);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: animated.value,
      transform: [{ scale: animated.value ? 1 : 0 }],
    };
  });

  const onPress = () => {
    if (disabled) {
      return;
    }

    onToggle?.(!selected);
  };

  const color = updateColorWithOpacity(
    unselectedColor && !selected ? unselectedColor : CCColors?.Primary.Default,
    disabled ? 0.5 : 1
  );

  useEffect(() => {
    animated.value = withTiming(selected ? 1 : 0, { duration: 100 });
  }, [selected, animated]);

  return (
    <Pressable onPress={onPress} style={[Styles.parent, style]}>
      <Animated.View style={[Styles.containerStyle, { borderColor: color }]}>
        <Animated.View
          style={[Styles.dotStyle, { backgroundColor: color }, animatedStyle]}
        />
      </Animated.View>

      {!!label && (
        <CCText
          style={Styles.labelStyle}
          color={
            unselectedColor && !selected
              ? CCColors.TextColor.Primary
              : CCColors.Primary.Default
          }
        >
          {label}
        </CCText>
      )}
    </Pressable>
  );
}
