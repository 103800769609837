import React, {useEffect, useState} from 'react';
import {
  FlatList,
  Linking,
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native';

import CCText from '../../Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import CCSelect from '../CCSelect';
import Feather from 'react-native-vector-icons/Feather';
import CCTextInput from '../CCTextInput';
import Box from '../Box';
import CCButton from '../CCButton';
import Spacer from '../Spacer';
import {vs} from '@/Utils/Dimensions';
import fontWeightToFamily from '@/Assets/Fonts/fontWeightToFamily';
import {useNavigation} from '@react-navigation/native';
import {ScreenName} from '@/navigators/StackConstants';
import Tooltip from '../Tooltip';
import ConditionalRender from '../ConditionalRender';

export interface CommonTableHeader {
  component?: React.ReactNode;
  key: string;
  label: string;
  dropdownItems?: {label: string; value: string}[];
  flex?: number;
  render?: (value, rowDetails) => React.ReactNode;
}

export interface CellData {
  value?: string;
  dropdownItems?: {label: string; value: string}[];
  currentDropdownValue?: string;
}

export interface Props {
  headers: CommonTableHeader[];
  data: Record<string, any>[] | [];
  displayLimit?: number;
  isEditable?: boolean;
  headerStyle?: TextStyle;
  assessmentId?: string;
  enableBorderStyle?: any;
  color?: string;
}

const isURL = (str: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i',
  );
  return !!pattern.test(str);
};
const CCTableCell: React.FC<{
  header: CommonTableHeader;
  item: Record<string, any>;
  rowIndex: number;
  handleDropdownChange: (value: string, key: string, rowIndex: number) => void;
  handleTextInputChange: (value: string, key: string, rowIndex: number) => void; // Handle text input change
  isEditMode: boolean; // Check if current row is in edit mode
  assessmentId: any;
  enableBorderStyle;
  color: any;
}> = ({
  header,
  item,
  rowIndex,
  handleDropdownChange,
  handleTextInputChange,
  isEditMode,
  assessmentId,
  color,
}) => {
  const navigation = useNavigation();
  const cellData = item[header.key];

  if (header.render) {
    return header.render(cellData, item);
  } else if (header?.dropdownItems || cellData?.dropdownItems) {
    const items = cellData?.dropdownItems || header.dropdownItems;
    const selectedValue = cellData?.currentDropdownValue || cellData;

    return (
      <CCSelect
        label=""
        items={items}
        selectedValue={selectedValue}
        onValueChange={value =>
          handleDropdownChange(value, header.key, rowIndex)
        }
        editable={isEditMode} // Assuming your CCSelect component has an editable prop
      />
    );
  } else if (isURL(cellData?.value || cellData)) {
    const urlValue = cellData?.value || cellData;
    const displayName = cellData?.name || 'Check';

    return (
      <CCText
        onPress={() => Linking.openURL(urlValue)}
        showEllipses
        style={styles.cellText}
        color={CCColors?.Blue}>
        {displayName}
      </CCText>
    );
  } else if (header.key === 'total' && !isEditMode) {
    console.log(item);
    // Check if the column is "Name" and not in edit mode
    return (
      <ConditionalRender
        condition={item?.win || item?.loss || item?.draw}
        childrenA={
          <Tooltip
            text={
              <Box>
                <CCText color={CCColors.White}>Won : {item?.win || 0}</CCText>
                <CCText color={CCColors.White}>Lost : {item?.loss || 0}</CCText>
                <CCText color={CCColors.White}>Draw : {item?.draw || 0}</CCText>
              </Box>
            }
            children={toggleEvent => {
              return (
                <View onPointerEnter={toggleEvent} onPointerLeave={toggleEvent}>
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate(ScreenName.Games, {
                        assessmentId: assessmentId,
                        endgame_type: item.type,
                      });
                    }}>
                    <CCText showEllipses style={styles.cellText}>
                      {cellData?.value || cellData || '--'}
                    </CCText>
                  </TouchableOpacity>
                </View>
              );
            }}
          />
        }
        childrenB={
          <TouchableOpacity
            onPress={() => {
              navigation.navigate(ScreenName.Games, {
                opening_mainline: item.Name.replace(/\s*\(.*?\)\s*/g, ''),
                assessmentId: assessmentId,
                query: `color=${color[0].toUpperCase() + color.slice(1)}`,
                opening: item.opening_eco.split(';')[0],
              });
            }}>
            <CCText showEllipses style={styles.cellText}>
              {cellData?.value || cellData || '--'}
            </CCText>
          </TouchableOpacity>
        }
      />
    );
  } else if (isEditMode) {
    return (
      <Box style={styles.cellText}>
        <CCTextInput
          label={header.key}
          value={cellData?.value || cellData || ''}
          onChangeText={value =>
            handleTextInputChange(value, header.key, rowIndex)
          }
          // Apply the same style, adjust as needed
        />
      </Box>
    );
  } else {
    return (
      <CCText showEllipses style={styles.cellText}>
        {cellData?.value || cellData || '--'}
      </CCText>
    );
  }
};

const CCTable: React.FC<Props> = ({
  headers,
  data,
  displayLimit = 10,
  isEditable = false,
  headerStyle = {},
  assessmentId,
  enableBorderStyle = false,
  color,
}) => {
  const [displayCount, setDisplayCount] = useState(displayLimit);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editedData, setEditedData] = useState<Record<string, string> | null>(
    null,
  );
  const [tableWidth, setTableWidth] = useState(0);

  useEffect(() => {
    setDisplayCount(displayLimit);
  }, [displayLimit]);

  const handleTextInputChange = (
    value: string,
    key: string,
    rowIndex: number,
  ) => {
    if (editingIndex !== null && editedData) {
      setEditedData(prevData => ({...prevData, [key]: value}));
    }
  };

  const handleEditClick = (index: number) => {
    setEditingIndex(index);
    setEditedData(data[index]);
  };

  const handleSeeMore = () => {
    setDisplayCount(prevCount => prevCount + displayLimit);
  };

  const handleSaveClick = () => {
    if (editingIndex !== null && editedData) {
      setEditingIndex(null);
      setEditedData(null);
    }
  };

  const handleDropdownChange = (
    value: string,
    key: string,
    rowIndex: number,
  ) => {
    if (editingIndex !== null && editedData) {
      setEditedData(prevData => ({...prevData, [key]: value}));
    }
  };

  const renderItem = ({
    item,
    index,
  }: {
    item: Record<string, string>;
    index: number;
  }) => (
    <View
      style={{
        ...(enableBorderStyle ? styles.borderRow : styles.row),
        backgroundColor:
          !enableBorderStyle && index % 2 !== 0
            ? CCColors.Primary.Light
            : 'transparent',
        //  borderWidth: 0.1,
        //  borderColor: 'black',
      }}>
      {isEditable && (
        <Feather name="edit" size={20} onPress={() => handleEditClick(index)} />
      )}
      {headers.map((header, colIndex) => (
        <View
          key={colIndex}
          style={[
            enableBorderStyle ? styles.borderCell : styles.cell,
            {flex: header.flex},
          ]}>
          <CCTableCell
            enableBorderStyle={enableBorderStyle}
            header={header}
            item={editingIndex === index ? editedData : item}
            rowIndex={index}
            handleDropdownChange={handleDropdownChange}
            handleTextInputChange={handleTextInputChange} // Passing down the new handler
            isEditMode={editingIndex === index} // Check if the current row is in edit mode
            assessmentId={assessmentId}
            color={color}
          />
        </View>
      ))}
      {editingIndex === index && (
        <>
          <CCButton type="underline" onPress={handleSaveClick}>
            Save
          </CCButton>
          <Spacer spacing={vs(10)} horizontal />
          <CCButton
            titleStyle={{color: CCColors?.Error}}
            type="underline"
            onPress={handleSaveClick}>
            cancel
          </CCButton>
        </>
      )}
    </View>
  );

  const tableHeader = () => (
    // <ScrollView
    //   horizontal
    //   showsHorizontalScrollIndicator={false}
    //   style={styles.header}>
    <Box
      style={[
        !enableBorderStyle ? styles.header : styles.borderHeader,
        headerStyle,
      ]}>
      {headers?.map((header, index) => (
        <Box
          key={index}
          style={[
            styles.headerCell,
            {flex: header.flex},
            enableBorderStyle && styles.borderHeaderStyle,
          ]}>
          {header.component ? (
            header.component
          ) : (
            <Text style={[styles.headerText, headerStyle]}>
              {header?.label}
            </Text>
          )}
        </Box>
      ))}
    </Box>
    // </ScrollView>
  );

  return (
    <Box>
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={{flex: 1}}>
        <FlatList
          data={data?.slice(0, displayCount)}
          renderItem={renderItem}
          keyExtractor={(_, index) => index?.toString()}
          ListHeaderComponent={tableHeader}
          ListEmptyComponent={
            <CCText textAlign="center">No data available</CCText>
          }
          style={{
            borderColor: CCColors?.Grey.Border,
            // borderWidth: 0.0001
          }}
        />
      </ScrollView>

      {displayCount < data?.length && (
        <CCText onPress={handleSeeMore}>...See More</CCText>
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    backgroundColor: '#f2f2f2',
    padding: 10,
  },
  borderHeader: {
    flexDirection: 'row',
    backgroundColor: '#f2f2f2',
  },
  headerCell: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  borderHeaderStyle: {
    borderWidth: 1,
    borderColor: CCColors.BorderBottom,
    margin: 0,
    padding: 0,
  },
  headerText: {
    fontFamily: fontWeightToFamily(),
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    // borderLeftWidth: 0.01,
    borderColor: '#26232211',
    padding: 10,
    alignItems: 'center',
  },
  borderRow: {
    flexDirection: 'row',
    // borderBottomWidth: 1,
    // borderColor: `#262322`,
    // borderWidth: 1,
    alignItems: 'center',
  },
  cell: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  borderCell: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // paddingHorizontal: 20,
  },
  cellText: {
    textAlign: 'center',
    padding: 15,
  },
});

export default CCTable;
