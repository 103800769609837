import App from './src/App';
import {AppRegistry} from 'react-native';
import Feather from '@expo/vector-icons/Feather';
import Ionicons from '@expo/vector-icons/Ionicons';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import ProductSansBold from './src/Assets/Fonts/ProductSans-Bold.ttf';
import ProductSansLight from './src/Assets/Fonts/ProductSans-Light.ttf';
import ProductSansMedium from './src/Assets/Fonts/ProductSans-Medium.ttf';
import ProductSansRegular from './src/Assets/Fonts/ProductSans-Regular.ttf';
import ProductSansThin from './src/Assets/Fonts/ProductSans-Thin.ttf';
import DMSansRegular from './src/Assets/Fonts/DMSans-Regular.ttf';
import DMSansMedium from './src/Assets/Fonts/DMSans-Medium.ttf';
import DMSansSemiBold from './src/Assets/Fonts/DMSans-SemiBold.ttf';
import Thunder from './src/Assets/Fonts/THUNDER/Fonts/Web-TT/Thunder-LC.ttf';

import {name as appName} from './app.json';

const fontFaces = {
  // 'ProductSans-Regular': ProductSansRegular,
  // 'ProductSans-Bold': ProductSansBold,
  // 'ProductSans-Light': ProductSansLight,
  // 'ProductSans-Medium': ProductSansMedium,
  // 'ProductSans-Thin': ProductSansThin,
  'DMSans-Regular': DMSansRegular,
  'DMSans-Medium': DMSansMedium,
  'DMSans-SemiBold': DMSansSemiBold,
  Thunder: Thunder,
  // add other fonts...
};
let fontsStyles = '';

for (const [fontName, fontPath] of Object.entries(fontFaces)) {
  fontsStyles += `
    @font-face {
      font-family: ${fontName};
      src: url(${fontPath});
    }
  `;
}

const style = document.createElement('style');
style.type = 'text/css';

if (style.styleSheet) {
  style.styleSheet.cssText = fontsStyles;
} else {
  style.appendChild(document.createTextNode(fontsStyles));
}

document.head.appendChild(style);

const IoniconsStyles = `@font-face {
  src: url(${Ionicons});
  font-family: Ionicons;
}
@font-face {
  src: url(${Feather});
  font-family: Feather;
}
@font-face {
  src: url(${MaterialIcons});
  font-family: MaterialIcons;
}
@font-face {
  src: url(${MaterialCommunityIcons});
  font-family: MaterialCommunityIcons;
}
`;

style.type = 'text/css';

if (style.styleSheet) {
  style.styleSheet.cssText = IoniconsStyles;
} else {
  style.appendChild(document.createTextNode(IoniconsStyles));
}

document.head.appendChild(style);

if (module.hot) {
  module.hot.accept();
}
AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: document.getElementById('root'),
});
