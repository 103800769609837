import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginTop: 8,
    justifyContent: 'center',
    zIndex: 2,
    // overflow: 'visible',
    height: '6%',
    flex: 0.05,
  },
  leftPosition: {
    position: 'absolute',
    right: 0,
    // width: 'max-content',
  },
  infoBox: {
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#22222220',
    backgroundColor: 'white',
    borderRadius: 4,
    height: 56,
    paddingHorizontal: 12,
  },
  centeredBox: {
    // margin: 'auto',
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
  },
  centeredText: {
    height: '100%',

    textAlignVertical: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    verticalAlign: 'middle',
    margin: 'auto',
  },
  actionBox: {
    backgroundColor: 'white',
    width: 64,
    height: 56,
    justifyContent: 'center',
    borderColor: '#22222220',
    borderWidth: 1,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  resignBox: {
    backgroundColor: 'white',
    width: 64,
    height: 56,
    justifyContent: 'center',
    borderColor: '#22222220',
    borderWidth: 1,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  abortBox: {
    backgroundColor: 'white',
    height: 56,
    width: 56,
    justifyContent: 'center',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderColor: '#22222220',
    borderWidth: 1,
  },
  icon: {
    width: 24,
    height: 24,
    margin: 'auto',
  },
  modal: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 8,
    borderColor: '#22222220',
    borderWidth: 1,
    position: 'absolute',
    top: '-20%',
    left: '30%',
    transform: [{translateX: -50}, {translateY: -50}],
    zIndex: 1000,
    width: 200,
    alignItems: 'center',
  },
  modalMobile: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 8,
    borderColor: '#22222220',
    borderWidth: 1,
    position: 'absolute',
    top: '-20%',
    left: '-5%',
    transform: [{translateX: -50}, {translateY: -50}],
    zIndex: 1000,
    width: 200,
    alignItems: 'center',
  },
  modalText: {
    marginBottom: 20,
    textAlign: 'center',
  },
  modalButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  modalButton: {
    padding: 10,
    borderRadius: 4,
    backgroundColor: '#f0f0f0',
    width: 50,
  },
  modalButtonText: {
    textAlign: 'center',
  },
  confirmButton: {
    backgroundColor: '#FFD700',
  },
});

export default styles;
