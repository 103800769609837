import ChessRestClient from '@/Api/ChessRestClient';
import {useMutation} from '@tanstack/react-query';

type FeedbackProp = {
  feedback: number;
  puzzle_id: number;
  userid: number;
  attempt_id: number;
};

export default function usePuzzleFeedback() {
  return useMutation(async (payload: FeedbackProp) => {
    const response = await ChessRestClient.post(
      'learning/puzzle_feedback/',
      payload,
    );
    if (!response.data) {
      throw new Error('Response data is null');
    }
    return response.data;
  });
}
