/* eslint-disable react-native/no-inline-styles */
import React, {useState, useEffect} from 'react';
import {View, Text, TouchableOpacity, StyleSheet} from 'react-native';
import SlideModal from '../../../../Atoms/SlideModal';
import CCButton from '../../../../Atoms/CCButton';
import {FontSizes, vs} from '../../../../Utils/Dimensions';
import {AccordionSection} from '@/Atoms/CCAccordion';
import CCCheckbox from '@/Atoms/CCCheckbox';
import Spacer from '@/Atoms/Spacer';
import Box from '@/Atoms/Box';
import Feather from '@expo/vector-icons/Feather';
import CCColors from '@/Utils/CCColors';
import CCText from '@/Atoms/CCText';
import gameFiltersApi from '@/Hooks/GameFiltersApi/gameFiltersApi';
import {useRoute} from '@react-navigation/native';

function Filters({
  open,
  handleOpenFilter,
  handleFilter,
  singleSelection = false,
}) {
  const {
    mutate: gameFilters,
    data: allFilters,
    error,
    isLoading,
  } = gameFiltersApi();
  const [filtersSelected, setFiltersSelected] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [appearOpening, setAppearOpening] = useState(false);

  //   Populate initial state

  const route = useRoute();

  // Fetch filters on component mount
  useEffect(() => {
    gameFilters();
  }, []);

  


  function normalizeString(str) {
    // return decodeURIComponent(str?.replace(/\+/g, ' ')).trim();
    return str[0] ? str?.replace('+', ' ') : false;
  }

  function compareStrings(str1, str2) {
    const normalizedStr1 = normalizeString(str1);
    const normalizedStr2 = normalizeString(str2);

    return normalizedStr1 === normalizedStr2;
  }

  function parseQueryString(queryString) {
    if (!queryString) {
      return;
    }
    const pairs = queryString.split('&');
    const result = {};

    pairs.forEach(pair => {
      const [key, value] = pair.split('=');
      const decodedKey = decodeURIComponent(key);
      let decodedValue = decodeURIComponent(value);
      decodedValue = decodedValue.replace(/\+/g, ' ');

      result[decodedKey] = decodedValue.split(',');
    });

    return result;
  }

  useEffect(() => {
    if (allFilters && !route.params?.query) {
      const initialFilterState = {};
      Object.keys(allFilters)?.forEach(filter => {
        initialFilterState[filter] = [];
      });

      setFiltersSelected(initialFilterState);
    } else if (allFilters && route.params?.query) {
      const initialFilterState = {};
      let parsedQuery = parseQueryString(route.params?.query);

      Object.keys(allFilters)?.forEach(filter => {
        initialFilterState[filter] = parsedQuery[filter]
          ? parsedQuery[filter]
          : [];
      });

      setFiltersSelected(initialFilterState);
    }
  }, [allFilters]);

  const handleCheckboxChange = (filterName, option, isChecked) => {
    setFiltersSelected(currentFilters => {
      let updatedFilters = {...currentFilters};

      if (singleSelection) {
        // For single selection, only one checkbox can be selected at a time
        if (isChecked) {
          updatedFilters[filterName] = [option];  
        } else {
          updatedFilters[filterName] = [];
        }
      } else {
        // For multiple selection, toggle the checked state of the checkbox
        if (isChecked) {
          updatedFilters[filterName] = [...updatedFilters[filterName], option];
        } else {
          updatedFilters[filterName] = updatedFilters[filterName].filter(
            item => item !== option.toString(),
          );
        }
      }

      return updatedFilters;
    });
  };

  const renderFilterOptionsOne = filterName => {
    return allFilters?.[filterName]?.map((option, index) => {
      let filters = filtersSelected[filterName]?.map(item => {
        console.log('Item:', item, 'Type:', typeof item);
        return typeof item === 'string' ? item.replace(/\+/g, ' ') : item;
    });

      if (option !== 0) {
        return (
          <>
            <CCCheckbox
              key={option}
              label={option}
              value={filters?.includes(option.toString())}
              onValueChange={(option, isChecked) => {
                handleCheckboxChange(filterName, option, isChecked);
              }}
            />
          </>
        );
      }
    });
  };

  const renderFilterOptionsTwo = filterName => {
    return allFilters?.[filterName]?.map((option, index) => {
      if (option !== 0) {
        return (
          <>
            <CCCheckbox
              key={option}
              label={option}
              value={filtersSelected[filterName]?.includes(option)}
              onValueChange={(option, isChecked) => {
                handleCheckboxChange(filterName, option, isChecked);
              }}
            />
          </>
        );
      }
    });
  };

  const handleApplyFilter = () => {
    const appliedFilters = Object.entries(filtersSelected).reduce(
      (acc, [key, value]) => {
        if (value.length) acc[key] = value;
        return acc;
      },
      {},
    );
    console.log("inside handle filter appliedFilters are:",appliedFilters);

    handleFilter(appliedFilters);
  };

  const checkFilterIsValid = () => {
    return Object.values(filtersSelected).some(
      selection => selection.length > 0,
    );
  };

  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  let noOfFilters = 0;
  if (filtersSelected) {
    let arr = Object.keys(filtersSelected).filter(filter => {
      if (filtersSelected[filter].length > 0) {
        return true;
      }
    });
    noOfFilters = arr.length;
  }

  return (
    <>
      <CCButton onPress={handleFilterOpen}>
        <Feather name="filter" size={14} color={CCColors.TextColor.Primary} />{' '}
        <Spacer spacing={5} horizontal />{' '}
        <CCText fontSize={FontSizes[12]}>Filters ({noOfFilters})</CCText>
      </CCButton>

      <SlideModal
        type = 'side'
        // changeModalWidth={true}
        modalHeaderTitle={'Filters'}
        visible={openFilter}
        onClose={() => {
          setOpenFilter(false);
        }}>
        <View style={{paddingHorizontal: vs(20), maxHeight: 400}}>
          {allFilters &&
            Object.keys?.(allFilters).filter(filterName => {
              const filterValues = allFilters[filterName];
          
              // Exclude 'is_favourite' when it contains only 0
              if ((filterName === 'is_favourite' && filterValues.length === 1 && filterValues[0] === 0) || filterValues.length === 0) {
                  return false;
              }
          
              return true;
          })?.map(filterName => (
             <AccordionSection
                key={filterName}
                title={filterName.replace(/_/g, ' ')}
                selectedCount={filtersSelected?.[filterName]?.length}
                renderContent={() => (
                  <View>
                    {route?.params?.query
                      ? renderFilterOptionsOne(filterName)
                      : renderFilterOptionsTwo(filterName)}
                  </View>
                )}
              />
            ))}
          <Spacer spacing={40} />
          <CCButton
            onPress={handleApplyFilter}
            type="outline"
            style={{
              width: '20%',
              margin: 'auto',
              marginBottom: 20,
            }}
            // disabled={!checkFilterIsValid()}
          >
            <CCText fontSize={FontSizes[12]}>Apply</CCText>
          </CCButton>
        </View>
      </SlideModal>
    </>
  );
}

export default Filters;
