import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import React, {useEffect, useState} from 'react';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {styles} from './styles';
import useManageParticipants from '@/Hooks/ManageStudyParticipantsApi/useManageParticipants';
import Addedparticipants from '../AddParticipants';
import CCColors from '@/Utils/CCColors';
import {LeftComponentType} from './types';
import Spacer from '@/Atoms/Spacer';

function Participants({
  participantsList,
  userDetailsData,
  gameId,
  setLoading,
  refetchStudy,
  isOwner,
  selectedUser,
  setSelectedUser,
  setOpenRemoveConfimation,
}: LeftComponentType) {
  //   const [selectedUser, setSelectedUser] = useState(null);

  const handleParticipantsSuccess = data => {
    setLoading?.(false);
    refetchStudy?.();
  };

  const handleParticipantsError = data => {
    setLoading?.(false);
  };
  const {
    mutate: manageParticipants,
    isLoading: isUpdatingPlayers,
    data: manageParticipantsData,
    error: manageParticipantsError,
  } = useManageParticipants(handleParticipantsSuccess, handleParticipantsError);

  const handleParticipants = (
    phone: string,
    action: 'add' | 'remove' = 'add',
  ) => {
    manageParticipants({
      phone: phone,
      action: action,
      gameId: gameId,
    });
  };

  return (
    <>
      {/* <CCText fontWeight="semibold">Participants</CCText> */}
      <Spacer spacing={10} />
      <Box>
        {participantsList?.map(player => (
          <Box
            style={[
              styles.participantsName,
              selectedUser?.username === player?.username
                ? styles.selectedName
                : {},
            ]}
            onPress={() => {
              setSelectedUser(player);
              // resetBoard();
            }}>
            <CCText>
              {player?.username}{' '}
              {player?.mobile_number === userDetailsData?.mobile_number
                ? '(you)'
                : ''}
            </CCText>
            {player?.isDeletable &&
              selectedUser?.username === player?.username &&
              isOwner && (
                <AntDesign
                  name="delete"
                  size={20}
                  color={CCColors.Error}
                  onPress={() =>
                    setOpenRemoveConfimation({
                      flag: true,
                      bodyTxt: `Are you sure you want to remove ${
                        player?.username || player?.mobile_number
                      }`,
                      callbackFunc: async () =>
                        await handleParticipants(
                          player?.mobile_number,
                          'remove',
                        ),
                    })
                  }
                  // style={{marginRight: 20}}
                />
              )}
          </Box>
        ))}
        {/* <CCText style={styles.participantsName}>You</CCText> */}
        {/* <CCText style={styles.participantsName}>Player 1</CCText> */}
        {/* <CCText style={styles.participantsName}>Player 2</CCText> */}
        <Addedparticipants
          startLoader={() => setLoading(true)}
          handleParticipants={handleParticipants}
          manageParticipantsData={manageParticipantsData}
          manageParticipantsError={manageParticipantsError}
          refetchStudy={refetchStudy}
          isOwner={isOwner}
        />
      </Box>
    </>
  );
}

export default Participants;
