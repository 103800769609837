import ChessRestClient from '@/Api/ChessRestClient';
import { useMutation } from "@tanstack/react-query";
import { GamesFiltersResponse } from './types';

export default function gameFiltersApi() {
  const mutation = useMutation<GamesFiltersResponse, Error>(
    async () => {
      const response = await ChessRestClient.get(
        "/game/get_filter_values"
      );
      return response.data;
    }
  );
  return {
    ...mutation,
  };
}