import {getLocalStorage} from '@/Utils/CommonUtils';
import {useState, useEffect} from 'react';

const useChessboardColorTheme = () => {
  const [chessboardColorTheme, setChessboardColorTheme] = useState(null);

  useEffect(() => {
    const getColorTheme = async () => {
      const chessboardColorThemeTemp = await getLocalStorage('color');
      setChessboardColorTheme(chessboardColorThemeTemp);
    };

    getColorTheme();
  }, []);

  return chessboardColorTheme;
};

export default useChessboardColorTheme;
