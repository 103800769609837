import Box from '@/Atoms/Box';
import React from 'react';
import CCText from '@/Atoms/CCText';
import CCButton from '@/Atoms/CCButton';
import CCColors from '@/Utils/CCColors';
import Spacer from '@/Atoms/Spacer';
import {Image, ImageBackground, ScrollView} from 'react-native';
import styles from '../../styles';
import ConditionalRender from '@/Atoms/ConditionalRender';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import proCurve from '@/Assets/Images/Svgs/home_page/PRO.svg';
import bottomRightSquare from '@/Assets/Images/Svgs/home_page/bottomRightSquare.svg';
import doubleArrowUp from '@/Assets/Images/Svgs/home_page/double_arrow_up.svg';
import bottomLeftSquare from '@/Assets/Images/Svgs/home_page/bottomLeftSquare.svg';
import squareBackgroundTopRight from '@/Assets/Images/Svgs/home_page/square_background.svg';
import SyncOBT from '@/Assets/Images/Svgs/home_page/sync_obt.svg';
import CalculationTrainer from '@/Assets/Images/Svgs/home_page/calculation_trainer.svg';
import BooksAndVideos from '@/Assets/Images/Svgs/home_page/books_videos.svg';
import IntuitionTrainer from '@/Assets/Images/Svgs/home_page/intuition_trainer.svg';
import FourSquares from '@/Assets/Images/Svgs/home_page/four_squares.svg';
import TwoSquares from '@/Assets/Images/Svgs/home_page/two_squares.svg';
import EnjoyGameplayCarousel, {
  ElevateAndCultivateCarousel,
} from '../../components/EnjoyGamePlay';

const ProLevel = ({signUpHandler}) => {
  const {isMobileOrTab} = useMediaQuery();

  const PRO = (
    <>
      <Box
        flexDirection="row"
        marginLeft="10%"
        marginRight={!isMobileOrTab ? '10%' : undefined}>
        <Box flex={1} justifyContent="center" style={{width: 159}}>
          <CCText
            fontWeight="thunder"
            style={{
              fontSize: !isMobileOrTab ? 56 : 48,
              lineHeight: !isMobileOrTab ? 64 : 56,
              fontFamily: 'thunder',
            }}>
            Elevate & Cultivate
          </CCText>
        </Box>
        <Box
          flex={1}
          style={{
            alignItems: !isMobileOrTab ? 'flex-end' : 'flex-start',
            marginRight: !isMobileOrTab ? 130 : 0,
            overflow: !isMobileOrTab ? 'visible' : 'hidden',
          }}>
          <Image
            source={proCurve}
            resizeMethod="resize"
            style={{width: 386, height: 182, borderRadius: 2}}
          />
        </Box>
      </Box>

      <Box
        flexDirection={!isMobileOrTab ? 'row' : 'column'}
        alignItems="center"
        style={styles.descContainer}>
        <ImageBackground
          source={bottomRightSquare}
          resizeMethod="resize"
          style={{
            width: 143,
            height: 143,
            flex: 1,
            position: 'absolute',
            flexDirection: 'row',
            alignItems: 'flex-start',
            bottom: 0,
            left: 0,
          }}
        />
        <Box
          // flex={1}
          style={{
            width: !isMobileOrTab ? '50vh' : '100%',
            marginLeft: isMobileOrTab ? '3vw' : '5vw',
            marginRight: '5vw'
          }}>
          <CCText
            fontWeight="regular"
            fontSize={!isMobileOrTab ? 22 : 16}
            textAlign={!isMobileOrTab ? 'left' : 'justify'}
            style={styles.learnPlayDesc}>
            Access the best resources and tools, train like a GM
          </CCText>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'flex-end',
            alignItems: 'flex-end',
            width: !isMobileOrTab ? '50%' : '89vw',
          }}>
          <Box
            style={{
              height: isMobileOrTab ? '30vw' : '10vw',
              width: isMobileOrTab ? '60vw' : '',
              flex: 1,
              flexDirection: 'column',
              marginBottom: 10,
              marginTop: 10,
              marginRight: '3vw'
            }}>
            <ImageBackground
              source={FourSquares}
              resizeMethod="resize"
              style={{
                flex: 1,
                flexDirection: 'row',
                marginLeft: isMobileOrTab ? '15vw' : '20vw',
                marginRight: '5vw'
              }}
            />
            <Box
              gap={!isMobileOrTab ? 10 : 0}
              flexDirection="row"
              alignItems="center"
              style={styles.rightContainer}>
              <Image
                source={doubleArrowUp}
                resizeMethod="resize"
                alt="up arrow"
                style={{width: 28, height: 28}}
              />
              <CCText fontSize={!isMobileOrTab ? 14 : 12}>
                Improve FIDE score by{' '}
              </CCText>
              <CCText
                fontSize={!isMobileOrTab ? 14 : 12}
                color={CCColors.Green}>
                {' '}
                700-900 points
              </CCText>
            </Box>
            <ImageBackground
              source={TwoSquares}
              resizeMethod="resize"
              style={{
                flex: 1,
                flexDirection: 'row',
                marginRight: '20vw',
                marginLeft: '5vw'
              }}
            />
          </Box>
        </Box>
        <ImageBackground
            source={bottomLeftSquare}
            resizeMethod="resize"
            style={{
              width: 143,
              height: 143,
              flex: 1,
              position: 'absolute',
              flexDirection: 'row',
              alignItems: 'flex-end',
              bottom: 0,
              right: 0,
            }}
          />
      </Box>

      <ConditionalRender
        condition={true}
        childrenA={
          <ScrollView
            scrollEnabled
            horizontal
            style={{
              gap: 20,
              flex: 1,
              marginLeft: !isMobileOrTab ? '10%' : '5%',
              marginBottom: isMobileOrTab ? 10 : 100,
            }}>
            <ImageBackground
              source={squareBackgroundTopRight}
              style={styles.commonBackground}
              imageStyle={{
                borderBottomWidth: 4,
                borderColor: '#67B3E1',
                borderRadius: 8,
              }}>
              <Image
                source={SyncOBT}
                resizeMethod="resize"
                style={{width: 68, height: 56}}
              />
              <CCText
                fontWeight="semibold"
                fontSize={20}
                textAlign="center"
                color={CCColors.TextColor.Primary}
                style={{fontWeight: '700', lineHeight: 26.04}}>
                Sync OTB Games
              </CCText>
              <CCText
                fontWeight="regular"
                fontSize={16}
                style={{lineHeight: 24, fontWeight: '400'}}>
                Upload and analyze all your over-the-board (OTB) games with a
                single click.
              </CCText>
            </ImageBackground>
            <ImageBackground
              source={squareBackgroundTopRight}
              style={styles.commonBackground}
              imageStyle={{
                borderBottomWidth: 4,
                borderColor: '#E17846',
                borderRadius: 8,
              }}>
              <Image
                source={CalculationTrainer}
                resizeMethod="resize"
                style={{width: 68, height: 56}}
              />
              <CCText
                fontWeight="semibold"
                fontSize={20}
                textAlign="center"
                color={CCColors.TextColor.Primary}
                style={{fontWeight: '700', lineHeight: 26.04}}>
                Calculation Trainer
              </CCText>
              <CCText
                fontWeight="regular"
                fontSize={16}
                style={{lineHeight: 24, fontWeight: '400'}}>
                Enhance your blindfold visualization skills with our five levels
                of training, designed to improve your ability to calculate and
                visualize moves step-by-step
              </CCText>
            </ImageBackground>
            <ImageBackground
              source={squareBackgroundTopRight}
              style={styles.commonBackground}
              imageStyle={{
                borderBottomWidth: 4,
                borderColor: '#DB7ACC',
                borderRadius: 8,
              }}>
              <Image
                source={BooksAndVideos}
                resizeMethod="resize"
                style={{width: 68, height: 56}}
              />
              <CCText
                fontWeight="semibold"
                fontSize={20}
                textAlign="left"
                color={CCColors.TextColor.Primary}
                style={{fontWeight: '700', lineHeight: 26.04}}>
                Books, Assignments and Videos
              </CCText>
              <CCText
                fontWeight="regular"
                fontSize={16}
                style={{lineHeight: 24, fontWeight: '400'}}>
                Easy access to best books and video content, unified tracking
              </CCText>
            </ImageBackground>
            <ImageBackground
              source={squareBackgroundTopRight}
              style={styles.commonBackground}
              imageStyle={{
                borderBottomWidth: 4,
                borderColor: '#3DAB9E',
                borderRadius: 8,
              }}>
              <Image
                source={IntuitionTrainer}
                resizeMethod="resize"
                style={{width: 68, height: 56}}
              />
              <CCText
                fontWeight="semibold"
                fontSize={20}
                textAlign="center"
                color={CCColors.TextColor.Primary}
                style={{fontWeight: '700', lineHeight: 26.04}}>
                Intuition Trainer
              </CCText>
              <CCText
                fontWeight="regular"
                fontSize={16}
                style={{lineHeight: 24, fontWeight: '400'}}>
                Playthrough the classic games while trying to guess the moves
                and becoming a GM
              </CCText>
            </ImageBackground>
          </ScrollView>
        }
        childrenB={<ElevateAndCultivateCarousel />}
      />

      <ConditionalRender
        condition={isMobileOrTab}
        childrenA={
          <CCButton
            style={{
              width: '90%',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: 20,
              alignSelf: 'center',
            }}
            onPress={signUpHandler}>
            <CCText
              fontSize={16}
              style={{paddingVertical: 5}}
              color={CCColors.TextColor.Dark}>
              Signup to CircleChess
            </CCText>
          </CCButton>
        }
      />
    </>
  );
  return (
    <>
      <Spacer spacing={40} />
      {PRO}
    </>
  );
};

export default ProLevel;
