// import {useMutation} from '@tanstack/react-query';
// import ChessRestClient from '@/Api/ChessRestClient';

// export default function useUpdatePgn() {
//   return useMutation(
//     async ({
//       phone,
//       gameId,
//       pgn,
//     }: {
//       phone: string;
//       gameId: string;
//       pgn: string;
//     }) => {
//       const response = await ChessRestClient.post(
//         `learning/study_games/${gameId}/participants/${phone}/update_pgn/`,
//         {
//           pgn: pgn,
//         },
//       );
//       return response.data;
//     },
//   );
// }

// import ChessRestClient from '@/Api/ChessRestClient';
// // import {SetAvatarResponse} from './types';
// import {useMutation} from '@tanstack/react-query';

// export default function useUpdatePgn(
//   handleOnSuccess?: (data: any) => void,
//   handleOnError?: (data: any) => void,
// ) {
//   const mutation = useMutation<any, Error>(
//     async ({
//       phone,
//       gameId,
//       pgn,
//     }: {
//       phone: string;
//       gameId: string;
//       pgn: string;
//     }) => {
//       // const {gameMoveId, alternateMoves} = details;
//       const response = await ChessRestClient.post(
//         `learning/study_games/${gameId}/participants/${phone}/update_pgn/`,
//         {
//           pgn: pgn,
//         },
//       );
//       return response.data;
//     },
//     {
//       onSuccess: data => {
//         if (handleOnSuccess && data?.success) handleOnSuccess(data);
//         else if (handleOnError) handleOnError(data);
//       },
//       onError: data => {
//         if (handleOnError) handleOnError(data);
//       },
//     },
//   );

//   return {
//     ...mutation,
//   };
// }

import ChessRestClient from '@/Api/ChessRestClient';
import {useMutation} from '@tanstack/react-query';

export default function useUpdatePgn(handleOnSuccess, handleOnError) {
  return useMutation(
    async ({phone, gameId, pgn}) => {
      try {
        const response = await ChessRestClient.post(
          `learning/study_games/${gameId}/participants/${phone}/update_pgn/`,
          {pgn},
        );
        return response.data;
      } catch (error) {
        // Assuming error is an AxiosError
        throw new Error(error?.response?.data?.error || 'Failed to update PGN');
      }
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) handleOnSuccess(data);
      },
      onError: error => {
        if (handleOnError) handleOnError(error);
      },
    },
  );
}
