/* eslint-disable react/jsx-no-undef */
import Box from '@/Atoms/Box';
import React, {useCallback, useMemo, useState} from 'react';
import {styles} from './styles';
import {Image, ImageBackground, ScrollView} from 'react-native';
import CCText from '@/Atoms/CCText';
import {FontSizes} from '@/Utils/Dimensions';
import Spacer from '@/Atoms/Spacer';
import CCColors from '@/Utils/CCColors';
import Feather from 'react-native-vector-icons/Feather';
import CCButton from '@/Atoms/CCButton';
import Divider from '@/Atoms/Divider/Divider';
import {useAddWebsiteToWalkthrough} from '@/Hooks/useAddWebsiteToWalkthrough';
import {useNavigation, useRoute} from '@react-navigation/native';
import {ScreenName} from '@/navigators/StackConstants';
import Loader from '@/Atoms/CCLoader/CCLoader';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';

const stage2Data = [
  {
    icon: require('./Stage2Icons/icon1.svg'),
    text: 'Play and Review 2 games daily for 100+ points!',
  },
  {
    icon: require('./Stage2Icons/icon2.svg'),
    text: 'Solve Positions from your games on the first attempt',
  },
  {
    icon: require('./Stage2Icons/icon3.svg'),
    text: 'DO NOT make blunders! You earn 50 points for every blunder free game you play.',
  },
  {
    icon: require('./Stage2Icons/icon4.svg'),
    text: 'Nothing is as bad as making a blunder without thinking! Avoid 50 point penalties for each time you do this.',
  },
  {
    icon: require('./Stage2Icons/icon5.svg'),
    text: 'Achieve Accuracy Targets',
  },
];

const stage3Data = [
  {
    icon: require('./Stage3Icons/stage3Icon1.svg'),
    text: 'If you are a Chess parent or a Chess coach, set rewards for the Child’s achievements.',
  },
  {
    icon: require('./Stage3Icons/stage3Icon2.svg'),
    text: 'At the same time, get daily reports on the child’s efforts and progress and see how they are doing!',
  },
];

function OnboardingWalkthrough() {
  const {isMobileOrTab} = useMediaQuery();
  const [stage, setStage] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();
  const {params} = useRoute();
  const {mutate: addWebsiteToWalkthrough} = useAddWebsiteToWalkthrough(
    data => {
      window?.location?.reload();
      setLoading(false);
    },
    error => {
      setLoading(false);
      console.log('Error:', error);
    },
  );

  const handleDoneClick = () => {
    setLoading(true);
    addWebsiteToWalkthrough('learning');
  };
  const scoreBox = ({
    topTxt,
    count,
    increase,
    index,
  }: {
    topTxt: JSX.Element;
    count: number;
    increase: number;
    index?: number;
  }) => (
    <Box
      style={[
        isMobileOrTab ? styles.eachScoreBoxMobile : styles.eachScoreBox,
        index !== 4 && styles.borderRight,
      ]}>
      <CCText fontSize={FontSizes[14]}>{topTxt}</CCText>
      <CCText style={styles.bottomTxt} fontSize={FontSizes[28]}>
        {count}
        <CCText fontSize={FontSizes[14]} color={CCColors.Green}>
          <Feather name="chevrons-up" size={20} color={CCColors.Green} />{' '}
          {increase}
        </CCText>
      </CCText>
    </Box>
  );

  const handleContinue = tempStage => {
    setStage(tempStage);
  };
  const renderComponent = useCallback(stage => {
    switch (stage) {
      case 1:
        return stage1;
      case 2:
        return stage2;

      case 3:
        return stage3;

      default:
        break;
    }
  }, []);

  const stage1 = (
    <ImageBackground
      source={require('../../Assets/onboardingScreen1.svg')}
      resizeMode="cover"
      style={styles.backgroundImg}>
      <Spacer spacing={'3%'} />
      <CCText textAlign="center" fontSize={FontSizes[24]}>
        Your Chess Goals!
      </CCText>
      <Spacer spacing={'1%'} />
      <CCText textAlign="center" fontSize={FontSizes[16]}>
        Your goals are at the top of your dashboard here!
      </CCText>
      <CCText textAlign="center" fontSize={FontSizes[16]}>
        Let us begin with your Starting Goals.
      </CCText>
      <Spacer spacing={'3%'} />
      <Box
        type={isMobileOrTab ? 'row-center-between' : 'row-center'}
        style={
          isMobileOrTab ? {flexWrap: 'wrap', marginHorizontal: '10%'} : {}
        }>
        {scoreBox({
          topTxt: (
            <>
              <CCText color={'red'}>⁉️</CCText> Blunder free games
            </>
          ),
          count: 67,
          increase: 2,
        })}
        {scoreBox({
          topTxt: <>❌ Blunders under 10s</>,
          count: 12,
          increase: 4,
        })}
        {scoreBox({
          topTxt: <>🧩 Positions on 1st try</>,
          count: 23,
          increase: 1,
        })}
        {scoreBox({
          topTxt: <>🎯 Accuracy in games</>,
          count: 43,
          increase: 6,
          index: 4,
        })}
      </Box>
      <Spacer spacing={'3%'} />
      <CCText textAlign="center" fontSize={FontSizes[16]}>
        Track your progress daily and keep getting better!
      </CCText>
      <Spacer spacing={'3%'} />
      <Box type="row-center" marginBottom={23}>
        <CCButton
          type="fill"
          style={{width: 'min-content'}}
          onPress={() => handleContinue(2)}
          titleStyle={{color: CCColors.TextColor.Primary}}>
          Continue
        </CCButton>
      </Box>
    </ImageBackground>
  );

  const stage2 = (
    <Box
      style={[
        styles.backgroundImg2,
        {
          backgroundColor: CCColors.White,
        },
      ]}>
      <ScrollView>
        <Spacer spacing={'3%'} />
        <CCText textAlign="center" fontSize={FontSizes[24]}>
          Make Progress Daily, Earn Rewards!
        </CCText>
        <Spacer spacing={'4%'} />
        <Box style={styles.container}>
          {stage2Data?.map((i, index) => {
            return (
              <Box style={styles.item}>
                <Image
                  source={i?.icon}
                  style={styles.stage2IconStyle}
                  resizeMode="contain"
                />
                <Spacer spacing={10} horizontal />
                <CCText fontSize={FontSizes[14]} style={{flexWrap: 'wrap'}}>
                  {i?.text}
                </CCText>
              </Box>
            );
          })}
        </Box>
        <Divider style={{marginHorizontal: 15}} />
        <Spacer spacing={20} />
        <Box type="row-center" marginBottom={23}>
          <CCButton
            type="fill"
            style={{width: 'min-content'}}
            onPress={() => handleContinue(3)}
            titleStyle={{color: CCColors.TextColor.Primary}}>
            Continue
          </CCButton>
        </Box>
      </ScrollView>
    </Box>
  );

  const stage3 = (
    <Box
      style={[
        styles.backgroundImg2,
        {
          backgroundColor: CCColors.White,
        },
      ]}>
      <Spacer spacing={'3%'} />
      <CCText textAlign="center" fontSize={FontSizes[24]}>
        Make Progress Daily, Earn Rewards!
      </CCText>
      <Spacer spacing={'4%'} />
      <Box style={styles.container2}>
        {stage3Data?.map((i, index) => {
          return (
            <Box style={styles.item2}>
              <Image
                source={i?.icon}
                style={styles.stage3IconStyle}
                resizeMode="contain"
              />
              <Spacer spacing={10} horizontal />
              <CCText
                fontSize={FontSizes[14]}
                style={{flexWrap: 'wrap', textAlign: 'center'}}>
                {i?.text}
              </CCText>
            </Box>
          );
        })}
      </Box>
      <Spacer spacing={'4%'} />
      <Box type="row-center">
        <CCButton
          type="fill"
          style={{width: 'min-content'}}
          onPress={handleDoneClick}
          titleStyle={{color: CCColors.TextColor.Primary}}>
          Done
        </CCButton>
      </Box>
      <Spacer spacing={'4%'} />
    </Box>
  );

  return (
    <Box style={styles.background} type="row-center">
      <Loader loading={loading} />
      <ScrollView style={{flex: 1, height: '95vh'}}>
        {renderComponent(stage)}
      </ScrollView>
    </Box>
  );
}

export default OnboardingWalkthrough;

// onboardingScreen1
