import {StyleSheet} from 'react-native';
import {vs, hs} from '@/Utils/Dimensions';
import CCColors from '@/Utils/CCColors';

const styles = StyleSheet.create({
  container: {
    // margin: 2,
    justifyContent: 'center',
    // height: vs(40),
    borderRadius: 8,
    marginVertical: 13,
  },
  normalShadow: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 8,

    shadowColor: '#4D3F37',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.05,
    shadowRadius: 8,

    elevation: 8,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 31,
    paddingVertical: 13,
  },
  animationContainer: {
    height: 24,
    width: 24,
    marginTop: 4,
  },
  settingsText: {
    color: CCColors.Green,
    paddingHorizontal: vs(32),
  },
  settingsIconContainer: {
    height: 32,
    width: 32,
    marginTop: 4,
  },
});

export default styles;
