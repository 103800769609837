/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-undef */
import {
  NavigationContainer,
  useIsFocused,
  useNavigationContainerRef,
} from '@react-navigation/native';
import {
  Image,
  Platform,
  SafeAreaView,
  ScrollView,
  View,
  Text,
  TouchableWithoutFeedback,
} from 'react-native';
import LottieView from 'react-native-web-lottie';
// import { SafeAreaView, StatusBar } from "react-native";
import {
  OnboardingScreenStack,
  OnboardingWalkthroughStack,
  Screen,
  ScreenName,
  ScreenStack,
  UnauthenticatedScreens,
} from './StackConstants';
// import {ApplicationStackParamList} from '../../@types/navigation';
import CCColors from '@/Utils/CCColors';
import CCText from '../Atoms/CCText';
import {AppStyles} from '../styles';
// import Header from "../components/Header";
// import { Platform } from "react-native";
import React, {useEffect} from 'react';
// import SignIn from "../components/SignIn";
import {capitalizeFirstLetter, setLocalStorage} from '../Utils/CommonUtils';

import {createStackNavigator} from '@react-navigation/stack';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {useFlipper} from '@react-navigation/devtools';
import Spacer from '@/Atoms/Spacer';
import Box from '@/Atoms/Box';
import {
  vs,
  isPhone,
  WINDOW_WIDTH,
  WINDOW_HEIGHT,
  FontSizes,
} from '@/Utils/Dimensions';

import {useState} from 'react';
import {useLoginState} from '@/Hooks/LoginApi/loginApi';
import Login from '../Screens/Login';
import WaitList from '../Screens/WaitList';

import Ionicons from 'react-native-vector-icons/Ionicons';
import MainMenu from '@/Atoms/MainMenu';
import Header from '@/Atoms/Header';
import useIsMobile from '@/Hooks/useIsMobile';
import useLoggedInUserStore from '@/Store/loggedInUserstore';
import CCLoader from '@/Atoms/CCLoader';
import {GA4_TRACKING_ID} from '@/Utils/Constants';
import {loadExternalScripts} from './LoadExternalScripts';
import OnboardingWalkthrough from '@/Screens/OnboardingWalkthrough';
import ENV from '../../environment';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import useGetUserActivePlanApi from '@/Hooks/UserActivePlanApi/useGetUserActivePlan';
import {useUserPlanStore} from '@/Store/userPlanStore';
import CCHome from '@/Screens/CCHome';
import ConditionalRender from '@/Atoms/ConditionalRender';
import SubscriptionModal from '@/Screens/Subscription/components/Modals';
import AlertModal from '@/Components/AlertModal';
import SentryWrapper from '@/SentryWrapper';
import CaissaChatbot from '@/Components/CaissaChatbot';
import PostMessageHandler from './Handlers/PostMessageHandler';
import {SocketProvider} from '@/Providers/SocketProvider';
import {getLocalStorage} from '@/Utils/CommonUtils';
import ChallengeComponent from '@/Components/ChallengeComponent';
import UserAssistance from '@/Components/UserAssistance';

// import ENV from 'environment';
// import useSignInModalStore from "../components/SignIn/store";
// import { useTheme } from "../hooks";
// import withAuth from "./withAuth";

// const Stack = createStackNavigator<any>();

const ApplicationNavigator = () => {
  // const {Layout, darkMode, NavigationTheme} = useTheme();
  // const {colors} = NavigationTheme;
  // const openSideModal = useSignInModalStore(state => state.isOpen);

  const [showHome, setShowHome] = useState<boolean>(true);
  const [verificationError, setVerificationError] = useState<any>(null);

  useEffect(() => {
    if (!__DEV__) {
      loadExternalScripts?.();
    }
    // setLocalStorage(
    //   'access_token',
    //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzIwMzEwOTA3LCJpYXQiOjE3MTI1MzQ5MDcsImp0aSI6IjdkYTljZmNhYzcxMDQ0ZjdiOGJkN2Y1MGNiNDUwNWE0IiwidXNlcl9pZCI6NCwidXNlcl9rZXkiOiIyZjU4YWYxOC02ZjUxLTQyYTEtYTY0Mi1hMmE5N2RhNmExNjQifQ.bHQkLhbJu6S-n6NLH3hIlSL_1UVS-44_W0sXSud-L0U',
    // );
    if (!ENV.DEBUG_ENABLED) {
      console.log = () => {};
    }
    // getActivePlan();
  }, []);

  if (!__DEV__) {
    console.log = () => {};
    console.error = () => {};
  }
  const navigationRef = useNavigationContainerRef();
  let prefix;
  useFlipper(navigationRef);

  if (Platform.OS === 'web') {
    // @ts-ignore

    prefix = `${window?.location?.protocol}//${window?.location?.hostname}${
      // @ts-ignore
      window?.location?.port ? ':' + window?.location?.port : ''
    }/`;
  } else {
    // TODO: change url here
    prefix = 'myapp://';
  }

  function transformScreens(screens: Screen[]): {[key: string]: string} {
    const result: {[key: string]: string} = {};

    screens.forEach(screen => {
      result[capitalizeFirstLetter(screen.Name)] = screen.Name;
    });

    return result;
  }

  // Define linking configuration
  const linking = {
    prefixes: [prefix],
    config: {
      screens: {
        ...transformScreens(ScreenStack),
        ...transformScreens(OnboardingScreenStack),
        ...transformScreens(UnauthenticatedScreens),
      },
    },
  };

  const {isMobileOrTab} = useMediaQuery();
  // const isMobile = useIsMobile();
  // const isMobile = false;

  const Drawer = createDrawerNavigator();
  const Stack = createStackNavigator();

  const [reloadLoginState, setReloadLoginState] = useState<boolean>(false);

  const {data, loading, isUserLoggedIn, error} =
    useLoginState(reloadLoginState);
  const [userData, setUserData] = useState({});
  const [accessToken, setAccessToken] = useState('');
  const handleUserVerified = async (data: any) => {
    await setLocalStorage('refresh_token', data?.refresh_token, true);
    await setLocalStorage('access_token', data?.access_token, true);
    await setLocalStorage('userkey', data?.user_key, true);
    setUserData(data);
    window.location.reload();
    console.log('User successfully verified:', data);
  };

  const handleVerificationError = (error: any) => {
    setVerificationError(error);
    console.error('Verification error:', error);
  };

  useEffect(() => {
    setUserData(data?.data?.user);
  }, [data]);

  useEffect(() => {
    if (isUserLoggedIn) {
      setAuthorizationToken();
    }
  }, [isUserLoggedIn]);

  window.addEventListener('storage', event => {
    if (event.key === 'logoutEvent') {
      // Perform logout actions in this tab
      window.location.reload(true);
    }
  });

  // don't put any hooks after this
  if (loading) {
    // return <Text>Loading...</Text>;
    return <CCLoader loading bgWhite />;
  }
  if (error) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Image
          resizeMode="contain"
          style={{width: vs(200), height: vs(100)}}
          source={require('@/Assets/Images/Svgs/cc-logo-light.svg')}
        />
        <Box justifyContent="center" alignItems="center">
          <LottieView
            source={require('../Assets/Animation/error_boundary_animation.json')}
            style={{width: WINDOW_WIDTH / 2, height: WINDOW_HEIGHT / 2}}
            autoPlay
            loop
          />
        </Box>
        <CCText fontSize={FontSizes[20]}>{error}</CCText>
        <Spacer spacing={10} />
        <UserAssistance />
      </View>
    );
  }

  useLoggedInUserStore.setState({
    gems: data.data?.avatar?.gems,
    pointsToNextLevel:
      data.data?.avatar?.points_to_next_level + data.data?.avatar?.gems,
    level: data.data?.avatar?.level + 1,
    netScore: data.data?.user?.net_score,
    streakConfig: data?.data?.streak_details,
    streakDetailsSummary: data?.data?.streak_details_summary,
  });

  const setAuthorizationToken = async () => {
    const token = await getLocalStorage('access_token');
    if (token) {
      setAccessToken(`Bearer ${token}`);
    }
  };

  function onLoginStateChange() {
    setReloadLoginState(prev => !prev);
    // setIsUserLoggedIn(true)
  }

  function isUserOnboarded(data) {
    return data.data?.user?.learning_onboarding_status;
  }

  function isNewUser(data) {
    return !data.data?.user?.seen_walkthroughs?.includes('learning');
  }

  // if (isUserLoggedIn && isMobile) {
  //   return (
  //     <>
  //       <Image
  //         style={{
  //           width: 162,
  //           height: 32,
  //           margin: 24,
  //         }}
  //         source={require('@/Assets/Images/Svgs/cc-logo-light.svg')}
  //       />
  //       <Box
  //         style={{
  //           flex: 1,
  //           // flexDirection: 'row',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //           padding: 20,
  //           // height: '98vh',
  //         }}>
  //         <View style={{transform: [{perspective: 1000}]}}>
  //           <LottieView
  //             source={require('../Assets/Animation/under_construction.json')}
  //             // style={{width: WINDOW_WIDTH / 2, height: WINDOW_HEIGHT / 2}}
  //             autoPlay
  //             loop
  //           />
  //         </View>
  //         <CCText textAlign="center" style={{fontSize: 20}}>
  //           Please switch to a laptop or desktop for the best experience.
  //         </CCText>
  //       </Box>
  //     </>
  //   );
  // }
  // TODO: Both inline styles are not having any effect. The inner Box element is also not needed.
  // Verify.

  const username = userData?.user_id?.toString() || '';
  const originalInfo = console.info;
  if (username && !console.info.isOverridden) {
    console.info = function (...args) {
      // Persist log to your Django server
      fetch('https://api.circlechess.com/client_logs/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Username': username, // Custom header for username
        },
        body: JSON.stringify({log: args}),
      }).catch(err => originalInfo('Failed to send log to server', err));

      // Also log to the console
      if (ENV.DEBUG_ENABLED) {
        originalInfo.apply(console, args);
      }
    };
    console.info.isOverridden = true;
  }

  return (
    <SafeAreaView style={{flex: 1}}>
      {!ENV.DEBUG_ENABLED && <SentryWrapper />}
      <NavigationContainer
        theme={{colors: {background: '#000'}}}
        ref={navigationRef}
        linking={linking}
        documentTitle={{enabled: false}}>
        <PostMessageHandler
          onUserVerified={handleUserVerified}
          onVerificationError={handleVerificationError}
        />
        <ConditionalRender
          condition={!isUserLoggedIn && !isUserOnboarded(data) && showHome}
          childrenA={
            <Stack.Navigator screenOptions={{headerShown: false}}>
              <Stack.Screen name="home">
                {props => (
                  <CCHome
                    {...props}
                    onLoginStateChange={onLoginStateChange}
                    setShowHome={setShowHome}
                  />
                )}
              </Stack.Screen>
              <Stack.Screen name="Login">
                {props => (
                  <Login {...props} onLoginStateChange={onLoginStateChange} />
                )}
              </Stack.Screen>
            </Stack.Navigator>
          }
          childrenB={
            <>
              {!isUserLoggedIn && (
                <Stack.Navigator screenOptions={{headerShown: false}}>
                  <Stack.Screen name="Login">
                    {props => (
                      <Login
                        {...props}
                        onLoginStateChange={onLoginStateChange}
                      />
                    )}
                  </Stack.Screen>

                  <Stack.Screen name="Waitlist">
                    {props => <WaitList {...props} />}
                  </Stack.Screen>
                </Stack.Navigator>
              )}
              {isUserLoggedIn && isUserOnboarded(data) && isNewUser(data) ? (
                <Stack.Navigator screenOptions={{headerShown: false}}>
                  <Stack.Screen name="OnboardingWalkthrough">
                    {props => (
                      <OnboardingWalkthrough
                        {...props}
                        onLoginStateChange={onLoginStateChange}
                      />
                    )}
                  </Stack.Screen>
                </Stack.Navigator>
              ) : (
                <></>
              )}

              {isUserLoggedIn && !isUserOnboarded(data) && (
                <Stack.Navigator screenOptions={{headerShown: false}}>
                  {OnboardingScreenStack?.map(screen => (
                    <Stack.Screen
                      key={screen.id}
                      name={capitalizeFirstLetter(screen.Name)}>
                      {(props: any) => (
                        <Box style={{flex: 1}}>
                          <Box>
                            <screen.component
                              {...props}
                              onLoginStateChange={onLoginStateChange}
                              userData={data.data.user}
                            />
                          </Box>
                        </Box>
                      )}
                    </Stack.Screen>
                  ))}
                </Stack.Navigator>
              )}

              {isUserLoggedIn && isUserOnboarded(data) && !isNewUser(data) ? (
                <SocketProvider username={username} accessToken={accessToken}>
                  {!isMobileOrTab ? (
                    <Header
                      isMobile={isMobileOrTab}
                      userData={userData}
                      avatar={data.data.avatar}
                      navigationRef={navigationRef}
                      onLoginStateChange={onLoginStateChange}
                    />
                  ) : (
                    <></>
                  )}
                  <Drawer.Navigator
                    backBehavior="history"
                    screenOptions={{
                      drawerType: isMobileOrTab ? 'front' : 'permanent',
                      drawerStyle: isMobileOrTab
                        ? AppStyles.mobileMenu
                        : AppStyles.menu,

                      headerShown: isMobileOrTab,
                      sceneContainerStyle: {
                        backgroundColor: CCColors.Background,
                      },
                      header: props => {
                        if (isMobileOrTab) {
                          return (
                            <Header
                              isMobile={isMobileOrTab}
                              userData={userData}
                              avatar={data.data.avatar}
                              navigationRef={navigationRef}
                              onLoginStateChange={onLoginStateChange}
                            />
                          );
                        }
                      },
                    }}
                    drawerContent={props => (
                      <MainMenu
                        isMobile={isMobileOrTab}
                        {...props}
                        userIsStaff={data.data.user.is_staff}
                      />
                    )}>
                    {ScreenStack?.map(screen => {
                      const isChessAnalysisScreen = [
                        'chessAnalysis',
                        'positions',
                        'playChess',
                        'SaveOTBGames',
                        'resources',
                        'VisualisationTrainer',
                        'studyDetails',
                        'playChess',
                        'assessmentReport',
                      ]?.includes(screen.Name);

                      const Container = isChessAnalysisScreen
                        ? View
                        : ScrollView;
                      const formatedOptions =
                        isUserOnboarded(data) === 2
                          ? {
                              ...screen.menuOptions,
                              disabled: screen.menuOptions.preAnalysisDisabled,
                              unmountOnBlur: false,
                            }
                          : {...screen.menuOptions, disabled: false};

                      return (
                        <Drawer.Screen
                          key={screen.id}
                          name={capitalizeFirstLetter(screen.Name)}
                          options={formatedOptions}>
                          {(props: any) => (
                            <RenderOnFocus>
                              <Container
                                showsVerticalScrollIndicator={false}
                                showsHorizontalScrollIndicator={false}
                                style={{
                                  height: '100%',
                                  overflowY: 'scroll',
                                  overflowX: 'hidden',
                                }}>
                                <screen.component
                                  {...props}
                                  {...screen.props}
                                  dashboardData={data.data}
                                  data={data}
                                  isUserLoggedIn={isUserLoggedIn}
                                  isLoading={loading}
                                  setUserData={setUserData}
                                />
                              </Container>
                            </RenderOnFocus>
                          )}
                        </Drawer.Screen>
                      );
                    })}
                  </Drawer.Navigator>
                  <CaissaChatbot />
                  <ChallengeComponent username={username} />
                </SocketProvider>
              ) : (
                <></>
              )}
            </>
          }
        />
        {/* <Stack.Navigator screenOptions={{headerShown: false}}>
          <Stack.Screen name="SignUp">
            {props => (
              <SignUp {...props} onLoginStateChange={onLoginStateChange} />
            )}
          </Stack.Screen>
        </Stack.Navigator> */}

        {/* {!isUserLoggedIn && (
          <Stack.Navigator screenOptions={{headerShown: false}}>
            <Stack.Screen name="Login">
              {props => (
                <Login {...props} onLoginStateChange={onLoginStateChange} />
              )}
            </Stack.Screen>

            <Stack.Screen name="Waitlist">
              {props => <WaitList {...props} />}
            </Stack.Screen>
          </Stack.Navigator>
        )}
        {isUserLoggedIn && isUserOnboarded(data) && isNewUser(data) && (
          <Stack.Navigator screenOptions={{headerShown: false}}>
            <Stack.Screen name="OnboardingWalkthrough">
              {props => (
                <OnboardingWalkthrough
                  {...props}
                  onLoginStateChange={onLoginStateChange}
                />
              )}
            </Stack.Screen>
          </Stack.Navigator>
        )}

        {isUserLoggedIn && !isUserOnboarded(data) && (
          <Stack.Navigator screenOptions={{headerShown: false}}>
            {OnboardingScreenStack?.map(screen => (
              <Stack.Screen
                key={screen.id}
                name={capitalizeFirstLetter(screen.Name)}>
                {(props: any) => (
                  <Box style={{flex: 1}}>
                    <Box>
                      <screen.component
                        {...props}
                        onLoginStateChange={onLoginStateChange}
                        userData={data.data.user}
                      />
                    </Box>
                  </Box>
                )}
              </Stack.Screen>
            ))}
          </Stack.Navigator>
        )}

        {isUserLoggedIn && isUserOnboarded(data) && !isNewUser(data) && (
          <>
            {!isMobileOrTab && (
              <Header
                isMobile={isMobileOrTab}
                userData={data.data.user}
                avatar={data.data.avatar}
                navigationRef={navigationRef}
                onLoginStateChange={onLoginStateChange}
              />
            )}
            <Drawer.Navigator
              screenOptions={{
                drawerType: isMobileOrTab ? 'front' : 'permanent',
                drawerStyle: isMobileOrTab
                  ? AppStyles.mobileMenu
                  : AppStyles.menu,

                headerShown: isMobileOrTab,
                sceneContainerStyle: {
                  backgroundColor: CCColors.Background,
                },
                header: props => {
                  if (isMobileOrTab) {
                    return (
                      <Header
                        isMobile={isMobileOrTab}
                        userData={data.data.user}
                        avatar={data.data.avatar}
                        navigationRef={navigationRef}
                        onLoginStateChange={onLoginStateChange}
                      />
                    );
                  }
                },
              }}
              drawerContent={props => (
                <MainMenu isMobile={isMobileOrTab} {...props} />
              )}>
              {ScreenStack?.map(screen => {
                const isChessAnalysisScreen = [
                  'chessAnalysis',
                  'positions',
                  'playChess',
                  'SaveOTBGames',
                  'resources',
                  'VisualisationTrainer',
                  'studyDetails',
                  'playChess',
                  'assessmentReport',
                ]?.includes(screen.Name);

                const Container = isChessAnalysisScreen ? View : ScrollView;

                const formatedOptions =
                  isUserOnboarded(data) === 2
                    ? {
                        ...screen.menuOptions,
                        disabled: screen.menuOptions.preAnalysisDisabled,
                      }
                    : {...screen.menuOptions, disabled: false};
                return (
                  <Drawer.Screen
                    key={screen.id}
                    name={capitalizeFirstLetter(screen.Name)}
                    options={formatedOptions}>
                    {(props: any) => (
                      <RenderOnFocus>
                        <Container
                          showsVerticalScrollIndicator={false}
                          style={{height: '100%'}}>
                          <screen.component
                            {...props}
                            {...screen.props}
                            dashboardData={data.data}
                          />
                        </Container>
                      </RenderOnFocus>
                    )}
                  </Drawer.Screen>
                );
              })}
            </Drawer.Navigator>
          </>
        )} */}
        <SubscriptionModal user={data?.data?.user} />
        <AlertModal />
      </NavigationContainer>
    </SafeAreaView>
  );
};

function RenderOnFocus({children}) {
  const isFocused = useIsFocused();

  if (isFocused) {
    return (
      <TouchableWithoutFeedback
        onPress={() => {
          useLoggedInUserStore.setState({
            dropDownClose: true,
          });
        }}>
        {children}
      </TouchableWithoutFeedback>
    );
  } else {
    return <></>;
  }
}

export default ApplicationNavigator;
