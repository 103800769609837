import React, {useMemo} from 'react';
import Box from '../Box/Box';
import CCText from '../CCText';
import Feather from 'react-native-vector-icons/Feather';
import {ChipProps} from './types';
import Styles from './styles';
import SPColors, {updateColorWithOpacity} from '../../Utils/CCColors';
import CCColors from '../../Utils/CCColors';
import {FontSizes, vs} from '@/Utils/Dimensions';
import Spacer from '../Spacer';
import ConditionalRender from '../ConditionalRender';

export default function Chip(props: ChipProps) {
  const {
    item,
    onPress,
    isSelected,
    disabled = false,
    type = 1,
    chipBackgroundColor,
    removeFeather,
  } = props;

  const _onPress = () => {
    if (!disabled) {
      onPress?.(item);
    }
  };

  const styleType = useMemo(() => {
    const effectiveType = item?.isOver ? 3 : type;
    switch (effectiveType) {
      case 1:
        return isSelected ? [Styles.chipStyleType1] : [Styles.chipStyleType1];
      case 2:
        return isSelected
          ? [Styles.chipStyleType2, Styles.chipStyleTypeSelected2]
          : [Styles.chipStyleType2];
      case 3:
        return [
          Styles?.chipStyleType2,
          isSelected
            ? [
                Styles?.chipStyleType2,
                Styles?.overSelected,
                item?.isOver && {
                  backgroundColor: updateColorWithOpacity(
                    CCColors.LightGreen,
                    0.2,
                  ),
                },
              ]
            : null,
        ];
      default:
        return [Styles.chipStyleType1];
    }
  }, [item?.isOver, type, isSelected]);

  const backgroundColor = useMemo(() => {
    if (isSelected) {
      return SPColors.Primary.Brown;
    } else if (disabled || item?.isOver) {
      return SPColors.Grey.BG400;
    } else if (chipBackgroundColor) {
      return chipBackgroundColor;
    }
    return SPColors.Primary.lightBrown;
  }, [isSelected, disabled, item?.isOver]);

  const textStyle = useMemo(() => {
    if (isSelected && type === 1) {
      return {color: CCColors.White};
    } else if (disabled || item?.isOver) {
      return {
        color: updateColorWithOpacity(SPColors.Black, 0.5),
        style: item?.isOver ? Styles.overStyle : null,
      };
    } else if (isSelected && type === 2) {
      return {
        fontWeight: 'bold',
        color: CCColors.TextColor.Primary,
      };
    }
    return {color: CCColors.Primary.Brown};
  }, [isSelected, disabled, item?.isOver, type]);

  return (
    <Box
      disabled={disabled}
      style={[{backgroundColor}, ...styleType]}
      onPress={_onPress}>
      {item?.prefix && <Box flex={0.15}>{item?.prefix}</Box>}
      {/* @ts-ignore */}
      <Box flex={item?.prefix ? 0.65 : 75}>
        <CCText disabled={disabled} {...textStyle} textAlign="left">
          {item.key}
        </CCText>
        {type === 2 && isSelected && !item?.isOver ? (
          <>
            <Spacer spacing={vs(12)} />

            <CCText color={CCColors.TextColor.Grey} fontSize={FontSizes[10]}>
              {item?.moveDetails.fields?.area.toUpperCase() === 'MISTAKE'
                ? 'Find the best way opponent can take advantage of your move'
                : 'Try and figure out the best alternative move'}
            </CCText>
          </>
        ) : type === 2 && isSelected && item?.isOver ? (
          <>
            <Spacer spacing={vs(12)} />

            <CCText color={CCColors.TextColor.Grey} fontSize={FontSizes[10]}>
              Try solving again
            </CCText>
          </>
        ) : null}
      </Box>
      {item?.type && <Box flex={0.1}>{item?.type}</Box>}
      <Spacer spacing={10} horizontal />
      <Box>
        {item?.postfix ? (
          item?.postfix
        ) : (
          <Box
            flex={0.15}
            style={[
              Styles.checkWrapper,
              {
                backgroundColor: item?.isOver
                  ? CCColors.Primary.Aqua
                  : type === 2
                  ? 'transparent'
                  : isSelected
                  ? CCColors.Primary.Default
                  : CCColors.White,
              },
            ]}>
            <ConditionalRender
              condition={!removeFeather}
              childrenA={
                <Feather
                  name="check"
                  size={20}
                  color={!isSelected ? CCColors.White : CCColors.Primary.Brown}
                />
              }
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
