import Box from '@/Atoms/Box';
import React, {useEffect, useState} from 'react';
import {styles} from './styles';
import CCText from '@/Atoms/CCText';
import {
  FontSizes,
  WINDOW_HEIGHT,
  WINDOW_WIDTH,
  hs,
  isPhone,
  vs,
} from '@/Utils/Dimensions';
import custom_rules_modal from '@/Assets/Images/png/custom_rules_modal.png';
import CCColors from '@/Utils/CCColors';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Spacer from '@/Atoms/Spacer';
import Divider from '@/Atoms/Divider/Divider';
import {Image, FlatList} from 'react-native';
import useLoggedInUserStore from '@/Store/loggedInUserstore';
import {useGetRules} from '@/Hooks/GetGoals/useGetGoals';
import Feather from 'react-native-vector-icons/Feather';
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import dayjs from 'dayjs';
import CCTabs from '@/Atoms/CCTabs';
import {AccordionSection} from '@/Atoms/CCAccordion';
import CCCurrencyIcon from '@/Assets/Images/Svgs/CCCurrency';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import ConditionalRender from '@/Atoms/ConditionalRender';
import SlideModal from '@/Atoms/SlideModal';
import CustomRulesModal from '../PlayChess/components/CustomRulesModal';

export const rulesDetails = [
  {
    id: 1,
    key: 'analyzed',
    boxTitle: 'Play and analysed',
    icon: (
      <Box style={styles.iconWrapper}>
        <AntDesign
          name="barschart"
          size={isPhone ? 16 : 20}
          color={CCColors.White}
        />
      </Box>
    ),
    rows: [
      {
        title: 'Play and analyzed',
        subTitle: 'Analyze 2 games & earned points',
        value: 100,
      },
      {
        title: 'Play and analyzed',
        subTitle: 'Extra points for each additional game analyzed',
        value: 25,
      },
      {
        title: 'Play and analyzed',
        subTitle: 'If a game is not analyzed after playing',
        value: -50,
      },
    ],
  },
  {
    id: 2,
    key: 'blunder',
    boxTitle: 'Blunder',
    icon: (
      <Box
        style={[styles.iconWrapper, {backgroundColor: CCColors.Red}]}
        type="row-center">
        <CCText fontSize={isPhone ? 16 : 20} color={CCColors.White}>
          ??
        </CCText>
      </Box>
    ),
    rows: [
      {
        title: 'Zero blunder',
        subTitle: 'Win 100 points for every blunder free game played',
        value: 100,
      },
      {
        title: 'Blunder',
        subTitle: 'For every blunder made in your games, you loose 25 points',
        value: -25,
      },
      {
        title: 'Blunder less then 10s',
        subTitle: 'Loose 50 points for each blunders made within 10 seconds.',
        value: -50,
      },
    ],
  },
  {
    id: 3,
    key: 'accuracy',
    boxTitle: 'Accuracy',
    icon: (
      <Box style={[styles.iconWrapper, {backgroundColor: CCColors.Blue}]}>
        <Feather
          name="target"
          size={isPhone ? 16 : 20}
          color={CCColors.White}
        />
      </Box>
    ),
    rows: [
      {
        title: 'Additional accuracy',
        subTitle: 'For accuracy over target accuracy in one game',
        value: 100,
      },
      {
        title: 'Additional accuracy',
        subTitle: 'For each additional percentage',
        value: 10,
      },
    ],
  },
  {
    id: 4,
    key: 'first_attempt',
    boxTitle: 'First attempt',
    icon: (
      <Box style={[styles.iconWrapper, {backgroundColor: CCColors.Pink}]}>
        <SimpleLineIcons
          name="puzzle"
          size={isPhone ? 16 : 20}
          color={CCColors.White}
        />
      </Box>
    ),
    rows: [
      {
        title: 'Positions solved on 1st try',
        subTitle: 'For every position solved on the first attempt',
        value: 20,
      },
    ],
  },
];

export const coinCard = netScore => {
  return (
    <Box style={styles.pointsWrapper}>
      <Image
        source={require('@/Assets/Images/Svgs/coinBackgoundForGoals.svg')}
        style={styles.backgroundImage}
      />
      <Box style={styles.points}>
        <Box>
          <CCText fontSize={FontSizes[14]} color={CCColors.White}>
            Your points
          </CCText>
          <Box type="row-flex-start" gap={5}>
            <CCCurrencyIcon width={25} height={25} />
            <CCText fontSize={FontSizes[28]} color={CCColors.White}>
              {netScore}
            </CCText>
          </Box>
        </Box>
        <Image
          style={{
            width: 80,
            height: 90,
          }}
          resizeMethod="resize"
          source={require('@/Assets/Images/Svgs/pointsCoinIcon.svg')}
        />
      </Box>
    </Box>
  );
};

export const getRelevantIcon = title => {
  switch (title) {
    case 'parental_controls':
      return (
        <Box style={styles.iconWrapper}>
          <MaterialIcons
            name="family-restroom"
            size={isPhone ? 16 : 20}
            color={CCColors.White}
          />
        </Box>
      );

    case 'analyzed':
      return (
        <Box style={styles.iconWrapper}>
          <AntDesign
            name="barschart"
            size={isPhone ? 16 : 20}
            color={CCColors.White}
          />
        </Box>
      );
    case 'accuracy':
      return (
        <Box style={[styles.iconWrapper, {backgroundColor: CCColors.Blue}]}>
          <Feather
            name="target"
            size={isPhone ? 16 : 20}
            color={CCColors.White}
          />
        </Box>
      );
    case 'blunder':
      return (
        <Box
          style={[styles.iconWrapper, {backgroundColor: CCColors.Red}]}
          type="row-center">
          <CCText fontSize={isPhone ? 16 : 20} color={CCColors.White}>
            ??
          </CCText>
        </Box>
      );

    case 'first_attempt':
      return (
        <Box style={[styles.iconWrapper, {backgroundColor: CCColors.Pink}]}>
          <SimpleLineIcons
            name="puzzle"
            size={isPhone ? 16 : 20}
            color={CCColors.White}
          />
        </Box>
      );

    default:
      return null; // or any default icon you want to return
  }
};

const mapDataToStructure = data => {
  return Object.keys(data).map((key, index) => {
    return {
      id: index + 1,
      key: key,
      boxTitle: key
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' '),
      icon: <Box style={styles.iconWrapper}>{getRelevantIcon(key)}</Box>,
      rows: data[key].map(item => ({
        title: item.title,
        subTitle: item.subTitle,
        value: item.value,
      })),
    };
  });
};

function Goals({dashboardData, rules}) {
  // const [rules, setRules] = useState(rulesDetails);
  // const [endDate, setEndDate] = useState(dayjs());
  // const [isSubscriptionModalVisible, setIsSubscriptionModalVisible] =
  //   useState(false);

  // const getRulesSuccess = ({data, validTill}: any) => {
  // const getRulesSuccess = (apiData: any) => {
  //   const {data, validTill, is_permitted} = apiData;
  //   // if (is_permitted === false) {
  //   //   setIsSubscriptionModalVisible(true);
  //   //   return;
  //   // }
  //   // const currentRules = [...rules];
  //   // Object.keys(data).forEach(key => {
  //   //   const index = currentRules.findIndex(r => r.key === key);
  //   //   currentRules[index]['rows'] = data[key];
  //   // });
  //   const currentRules = mapDataToStructure(apiData?.data);
  //   setRules(currentRules);
  //   setEndDate(validTill);
  // };

  // const {mutate: getRules} = useGetRules(getRulesSuccess);
  const {isMobileOrTab} = useMediaQuery();
  const [netScore] = useLoggedInUserStore(state => [state.netScore]);

  // useEffect(() => {
  //   getRules();
  // }, []);

  return (
    <>
      <Box style={styles.GoalsWrapper}>
        {/* <Box style={styles?.leftComponent}>
          <Box style={styles?.header}>
            <CCText fontSize={isMobileOrTab ? FontSizes[18] : FontSizes[24]}>
              Learn and earn CircleChess points
            </CCText>
            <Spacer spacing={vs(5)} />
            <Box flexDirection="row">
              <Feather
                name="calendar"
                size={16}
                color={CCColors.TextColor.Grey}
              />
              <Spacer spacing={hs(5)} horizontal />
              <CCText color={CCColors.TextColor.Grey}>
                Rules applicable till:
                <Spacer spacing={hs(5)} horizontal />
                <CCText color={CCColors.TextColor.Primary}>
                  {dayjs().format('YYYY-MM-DD')} to{' '}
                  {dayjs(endDate).format('YYYY-MM-DD')}
                </CCText>
              </CCText>
            </Box>
          </Box>
        </Box> */}
        {/* {!isMobileOrTab && <Box style={styles?.rightComponent}></Box>} */}
      </Box>
      <Box style={styles.GoalsWrapper}>
        {/* <CCTabs
          tabIndex={0}
          tabItems={tabs}
          handleTabChange={({route}) => {}}
        /> */}
        <Box
          flex={isMobileOrTab ? 1 : undefined}
          style={{width: !isMobileOrTab ? '70%' : undefined}}>
          <FlatList
            nestedScrollEnabled={true}
            data={rules}
            renderItem={({item}) => (
              <Box style={{marginVertical: 10}}>
                <AccordionSection
                  keepOpen
                  showIcon={isMobileOrTab}
                  header={
                    isMobileOrTab && (
                      <Box
                        style={{
                          paddingHorizontal: isMobileOrTab ? 0 : 10,
                          paddingVertical: 0,
                        }}>
                        <Box type="row-flex-start" gap={10}>
                          {item.icon}
                          <Box style={{flex: 1}}>
                            <CCText
                              fontSize={FontSizes[14]}
                              color={CCColors.TextColor.Dark}>
                              {item.boxTitle}
                            </CCText>
                          </Box>
                        </Box>
                      </Box>
                    )
                  }
                  title={''}
                  isScrollable={false}
                  renderContent={() => (
                    <Box flexDirection="row" style={{padding: 10}}>
                      {!isMobileOrTab && (
                        <Box flex={0.25}>
                          <Box type="row-flex-start" gap={10}>
                            {item.icon}
                            <Box style={{flex: 1}}>
                              <CCText
                                fontSize={FontSizes[14]}
                                color={CCColors.TextColor.Dark}>
                                {item.boxTitle}
                              </CCText>
                            </Box>
                          </Box>
                        </Box>
                      )}
                      <Box flex={isMobileOrTab ? 1 : 0.7}>
                        {item?.rows?.map((eachRow, index) => {
                          return (
                            <>
                              <Box style={styles.boxEachRow}>
                                <Box style={styles.boxLeftRow}>
                                  <CCText fontSize={FontSizes[14]}>
                                    {eachRow?.title}
                                  </CCText>
                                  <Spacer spacing={4} />
                                  <CCText
                                    color={CCColors.Grey.Grey}
                                    fontSize={FontSizes[12]}>
                                    {eachRow?.subTitle}
                                  </CCText>
                                </Box>
                                <ConditionalRender
                                  condition={typeof eachRow.value === 'string'}
                                  childrenA={
                                    <CustomRulesModal
                                      childElement={
                                        <Box
                                          flexDirection="row"
                                          alignItems="center"
                                          marginRight={30}
                                          style={{
                                            backgroundColor:
                                              eachRow.value === 'enabled'
                                                ? CCColors.GreenLight
                                                : CCColors.RedLight,
                                            width: 'fit-content',
                                            paddingVertical: 8,
                                            paddingHorizontal: 16,
                                            borderRadius: 25,
                                          }}
                                          gap={5}>
                                          <CCText>{eachRow.value}</CCText>
                                        </Box>
                                      }
                                    />
                                  }
                                  childrenB={
                                    <Box style={styles.boxRightRow}>
                                      {eachRow?.value >= 0 ? (
                                        <Box
                                          flexDirection="row"
                                          alignItems="center"
                                          style={{
                                            backgroundColor:
                                              CCColors.GreenLight,
                                            width: 'fit-content',
                                            paddingVertical: 8,
                                            paddingHorizontal: 16,
                                            borderRadius: 25,
                                          }}
                                          gap={5}>
                                          <CCCurrencyIcon
                                            width={15}
                                            height={15}
                                            color={CCColors.Green}
                                          />
                                          <CCText
                                            fontSize={FontSizes[16]}
                                            style={{fontWeight: 700}}
                                            color={CCColors.Green}>
                                            +{eachRow?.value}
                                            <CCText
                                              fontSize={14}
                                              color={CCColors.Green}>
                                              pts.
                                            </CCText>
                                          </CCText>
                                        </Box>
                                      ) : (
                                        <Box
                                          flexDirection="row"
                                          alignItems="center"
                                          style={{
                                            backgroundColor: CCColors.RedLight,
                                            width: 'fit-content',
                                            paddingVertical: 8,
                                            paddingHorizontal: 16,
                                            borderRadius: 25,
                                          }}
                                          gap={5}>
                                          <CCCurrencyIcon
                                            width={15}
                                            height={15}
                                            color={CCColors.Red}
                                          />
                                          <CCText
                                            fontSize={FontSizes[16]}
                                            style={{fontWeight: 700}}
                                            color={CCColors.Red}>
                                            {eachRow?.value}
                                            <CCText
                                              fontSize={14}
                                              color={CCColors.Red}>
                                              pts.
                                            </CCText>
                                          </CCText>
                                        </Box>
                                      )}
                                    </Box>
                                  }
                                />
                              </Box>
                              {item?.rows?.length !== index + 1 && <Divider />}
                            </>
                          );
                        })}
                      </Box>
                    </Box>
                  )}
                />
              </Box>
            )}
            keyExtractor={item => item.id}
          />
        </Box>
        <Box style={{width: !isMobileOrTab ? '25%' : '100%'}}>
          <Spacer spacing={16} />
          {coinCard(netScore)}
        </Box>
      </Box>

      {/* <SubscriptionModal
        visible={isSubscriptionModalVisible}
        onClose={() => setIsSubscriptionModalVisible(false)}
        user={dashboardData?.user}
      /> */}
    </>
  );
}

export default Goals;
