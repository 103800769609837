/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-native/no-inline-styles */
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import Feather from 'react-native-vector-icons/Feather';
import React, {useEffect, useRef, useState} from 'react';
import {styles} from './styles';
import useGetRewardsApi from '@/Hooks/RewardsApi/useRewardsApi';
import {FlatList, Image, Pressable, TouchableOpacity} from 'react-native';
import Spacer from '@/Atoms/Spacer';
import {FontSizes, hs, vs} from '@/Utils/Dimensions';
import CCButton from '@/Atoms/CCButton';
import Entypo from 'react-native-vector-icons/Entypo';
import NewReward from '../../Assets/Images/rewards/new_reward.svg';
import SlideModal from '@/Atoms/SlideModal';
import useRedeemRewardsApi from '@/Hooks/RewardsApi/useRedeemRewardsApi';
import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import CCTextInput from '@/Atoms/CCTextInput';
import useCreateRewardApi from '@/Hooks/RewardsApi/useCreateRewardsApi';
import {ScreenName} from '@/navigators/StackConstants';
import useGAPageView from '@/navigators/useGAPageView';
import useLoggedInUserStore from '@/Store/loggedInUserstore';
import Divider from '@/Atoms/Divider/Divider';
import Goals, {coinCard, getRelevantIcon, rulesDetails} from '../Goals';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import CCPackageTable from '@/Atoms/CCPackageTable';
import ConditionalRender from '@/Atoms/ConditionalRender';
import AddNewRewardModel from './components/AddNewReward';
import ConfirmationModal from './components/ConfirmationModal';
import CCTable from '@/Atoms/CCTable';
import {renderTabBar} from '@/Components/TabBar';
import {TabView, TabBar, SceneMap} from 'react-native-tab-view';
import {renderBoxTabBar} from '@/Atoms/CCTabs';
import dayjs from 'dayjs';
import {useGetRules} from '@/Hooks/GetGoals/useGetGoals';
import {useNonPersistedUserPlanStore} from '@/Store/userPlanStore';
import {useNavigation} from '@react-navigation/native';
import SubscriptionModal from '../Subscription/components/Modals';

const mapDataToStructure = data => {
  return Object.keys(data).map((key, index) => {
    return {
      id: index + 1,
      key: key,
      boxTitle: key
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' '),
      icon: <Box style={styles.iconWrapper}>{getRelevantIcon(key)}</Box>,
      rows: data[key].map(item => ({
        title: item.title,
        subTitle: item.subTitle,
        value: item.value,
      })),
    };
  });
};

const RewardsComponent: React.FC = () => {
  const {isMobileOrTab} = useMediaQuery();
  const navigation = useNavigation();
  useGAPageView(ScreenName.Rewards);
  const [netScore] = useLoggedInUserStore(state => [state.netScore]);

  const [rewards, setRewards] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedReward, setSelectedReward] = useState({});

  const [redeemInProgress, setRedeemInProgress] = useState(false);
  const [redeemSuccess, setRedeemSuccess] = useState();

  const [errorMessage, setErrorMessage] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newRewardName, setNewRewardName] = useState('');
  const [newRewardDescription, setNewRewardDescription] = useState('');
  const [newRewardCost, setNewRewardCost] = useState('');
  const [statementData, setStatementData] = useState('');
  const [tab, setTab] = useState('rewards');
  const [index, setIndex] = useState(0);
  const [endDate, setEndDate] = useState(dayjs());
  const [rules, setRules] = useState(rulesDetails);
  const getRulesSuccess = (apiData: any) => {
    const {data, validTill, is_permitted} = apiData;
    // if (is_permitted === false) {
    //   setIsSubscriptionModalVisible(true);
    //   return;
    // }
    // const currentRules = [...rules];
    // Object.keys(data).forEach(key => {
    //   const index = currentRules.findIndex(r => r.key === key);
    //   currentRules[index]['rows'] = data[key];
    // });
    const currentRules = mapDataToStructure(apiData?.data);
    setRules(currentRules);
    setEndDate(validTill);
  };

  const {mutate: getRules} = useGetRules(getRulesSuccess);
  const [routes] = React.useState(
    [
      {key: 'Point calculation rules', title: 'Point calculation rules'},
      {key: 'Rewards', title: 'Rewards'},
      {key: 'Summary Statement', title: 'Summary Statement'},
      // { key: "previousTournaments", title: "Previous Tournaments" },
    ]?.filter(i => i),
  );
  useEffect(() => {
    getRules();
  }, []);

  const [timer, setTimer] = useState();
  const [limitType, setLimitType] = useState('');
  const [usageLimit, setUsageLimit] = useState(0);
  const [planName, setPlanName] = useState('');
  const {subscriptionModalOpen, setSubscriptionModalOpen} =
    useNonPersistedUserPlanStore();

  const {mutate: createReward} = useCreateRewardApi(
    data => {
      setLimitType(data?.limit_type);
      setUsageLimit(data?.usage_limit);
      setPlanName(data?.plan_name);
      console.log('Reward created successfully:', data);
      if (data?.data?.is_permitted === false) {
        setShowCreateModal(false);
        setSubscriptionModalOpen(true);
        return;
      }
      setShowCreateModal(false);
      getRewards(); // Refresh the rewards list
    },
    error => {
      console.error('Error creating reward:', error);
    },
  );

  const handleCreateReward = () => {
    const rewardData = {
      name: newRewardName,
      description: newRewardDescription,
      cost: parseInt(newRewardCost, 10), // Ensure cost is an integer
    };

    createReward(rewardData);
  };

  const redeemRewardCallback = data => {
    setRedeemSuccess(data.status === 'success' ? true : false);
    console.log('Redeem resonse = ', data);
    if (data.data.status === 'failure') {
      setRedeemSuccess(false);
      setErrorMessage(data.data.message);
      const timer = setTimeout(() => {
        cleanupState();
      }, 5000);
      setTimer(timer);
    } else {
      setRedeemSuccess(true);
      setErrorMessage('');

      useLoggedInUserStore.setState({
        gems: data.data.points?.points,
        pointsToNextLevel: data.data.points?.points_to_next_level,
        level: data.data.points?.level,
        netScore: data.data.net_score,
      });

      const timer = setTimeout(() => {
        cleanupState();
      }, 5000);
      setTimer(timer);
    }
    setSelectedReward({});
  };

  const cleanupState = () => {
    setRedeemSuccess(false);
    setShowConfirm(false);
    setRedeemInProgress(false);
    setErrorMessage('');
  };

  const onConfirmModalClose = () => {
    if (timer) clearTimeout(timer);
    cleanupState();
    setShowConfirm(false);
  };

  const {
    mutate: getRewards,
    data: validatedData,
    error: validatedError,
    isLoading,
  } = useGetRewardsApi(
    data => {
      // console.log('myStatement=', data.data.statement);
      setStatementData(data?.data?.statement);
      let fetchedRewards = Array.from(Object.values(data?.data?.data));
      const addReward = {
        id: 10000,
        user_key: '',
        name: 'Add your Reward',
        desc: 'Add a Custom Reward that can be claimed',
        image: '',
        cost: 0,
      };
      fetchedRewards.push(addReward);
      setRewards(fetchedRewards);
    },
    () => {},
  );
  const planMessage = `${limitType} limit of ${usageLimit} ${
    usageLimit === 1 ? 'reward' : 'rewards'
  } is exhausted. Upgrade now to continue with custom rewards.`;
  const {
    mutate: redeemReward,
    data: redeemRewardResponse,
    error: redeemRewardError,
    isRedeemLoading,
  } = useRedeemRewardsApi(redeemRewardCallback, () => {});

  const callRedeemReward = (id: Number) => {
    setRedeemInProgress(true);
    redeemReward(id);
    // Make API call to redeem this reward
    // This should also trigger a communication over WhatsApp
  };

  useEffect(() => {
    getRewards();
  }, [tab]);

  let allRewards: any = [];

  rewards?.forEach(reward =>
    allRewards.push(
      <Box style={styles.card}>
        <Box style={styles.cardHeader} overflow="hidden">
          <Image
            style={{
              width: 80,
              height: 80,
              backgroundColor: CCColors.Primary.lightYellow,
            }}
            resizeMethod="resize"
            source={require('@/Assets/Images/Svgs/giftIcon.svg')}
          />
          <Spacer spacing={vs(16)} horizontal />
          <Box>
            <CCText fontSize={FontSizes[18]} fontWeight="bold">
              {reward.name}
            </CCText>
            <Spacer spacing={vs(5)} />
            <CCText
              color={CCColors.Grey.Grey}
              fontSize={FontSizes[12]}
              style={{width: '70%'}}>
              {reward.desc}
            </CCText>
          </Box>
        </Box>
        <Spacer spacing={vs(24)} />
        <Divider />
        <Spacer spacing={vs(24)} />
        <Box flex={1} type="row-center-between">
          <Box type="row-center">
            <Image
              style={{
                width: 20,
                height: 20,
              }}
              resizeMethod="resize"
              source={require('@/Assets/Images/Svgs/coinInRewards.svg')}
            />
            <CCText> {reward.cost}Pts</CCText>
          </Box>
          {reward.cost === 0 && (
            <TouchableOpacity
              style={{
                flexDirection: 'row',
              }}
              onPress={() => setShowCreateModal(true)}>
              <CCText fontSize={FontSizes[16]} color={CCColors.Primary.Aqua}>
                Add new reward{' '}
              </CCText>
              <Box
                style={{
                  backgroundColor: updateColorWithOpacity(
                    CCColors.Primary.Aqua,
                    0.2,
                  ),
                  padding: 2,
                  borderRadius: '50%',
                }}>
                <Entypo
                  name="chevron-small-right"
                  color={CCColors.Primary.Aqua}
                  size={20}
                />
              </Box>
            </TouchableOpacity>
          )}
          {reward.cost !== 0 && (
            <TouchableOpacity
              style={{
                flexDirection: 'row',
              }}
              onPress={() => {
                setSelectedReward({
                  id: reward.id,
                  name: reward.name,
                  cost: reward.cost,
                });
                setShowConfirm(true);
              }}>
              <CCText fontSize={FontSizes[16]} color={CCColors.Primary.Aqua}>
                Redeem now{' '}
              </CCText>
              <Box
                style={{
                  backgroundColor: updateColorWithOpacity(
                    CCColors.Primary.Aqua,
                    0.2,
                  ),
                  padding: 2,
                  borderRadius: '50%',
                }}>
                <Entypo
                  name="chevron-small-right"
                  color={CCColors.Primary.Aqua}
                  size={20}
                />
              </Box>
            </TouchableOpacity>
            // <CCButton
            //   onPress={_e => {

            //   }}>
            //   <CCText>Redeem</CCText>
            // </CCButton>
          )}
          {/* <Spacer spacing={vs(16)} /> */}
        </Box>
      </Box>,
    ),
  );

  let formattedData;
  if (statementData) {
    formattedData = statementData;
  }

  const renderScene = (rendererSceneProps: any) => {
    const {route: tempRoute} = rendererSceneProps;
    let returnJSX = <></>;
    // {key: 'Rewards', title: 'Rewards'},
    // {key: 'Point calculation rules', title: 'Point calculation rules'},
    // {key: 'Summary Statement', title: 'Summary Statement'},
    switch (tempRoute.key) {
      case 'Rewards':
        return (
          <Box
            flexDirection={isMobileOrTab ? 'column-reverse' : 'row'}
            justifyContent="space-between">
            <Box style={[styles.leftSide, styles.container]}>
              <Box style={styles.bannerContainer}>
                <Box style={styles.banner}>
                  <Box>
                    <Box
                      style={{
                        flexDirection: isMobileOrTab ? 'column' : 'row',
                        justifyContent: 'space-between',
                      }}>
                      {isMobileOrTab ? (
                        <>
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginBottom: 20,
                              gap: 10,
                              justifyContent: 'center',
                            }}>
                            <Image
                              source={require('@/Assets/Images/Svgs/bannerItems/left.png')}
                              style={styles.headerIcon}
                            />
                            <CCText style={styles.topHeading}>
                              How Rewards Work?
                            </CCText>
                            <Image
                              source={require('@/Assets/Images/Svgs/bannerItems/right.png')}
                              style={styles.headerIcon}
                            />
                          </Box>
                          <CCText style={styles.topMobileDescription}>
                            Instill consistency and discipline in player by
                            rewarding them for positive behaviour while making
                            the journey enriching and fun!
                          </CCText>
                        </>
                      ) : (
                        <Box style={styles.rewardItem}>
                          <CCText style={styles.topHeading}>
                            How Rewards Work?
                          </CCText>
                          <CCText style={styles.description}>
                            Instill consistency and discipline in player by
                            rewarding them for positive behaviour while making
                            the journey enriching and fun!
                          </CCText>
                        </Box>
                      )}
                      {!isMobileOrTab ? (
                        <>
                          <Box style={styles.rewardItem}>
                            <Image
                              source={require('@/Assets/Images/Svgs/bannerItems/header1.png')}
                              style={styles.icon}
                            />
                            <CCText style={styles.heading}>
                              1. Create Reward
                            </CCText>
                            <CCText style={styles.description}>
                              Parents and coaches can create custom rewards for
                              players
                            </CCText>
                          </Box>
                          <Box style={styles.rewardItem}>
                            <Image
                              source={require('@/Assets/Images/Svgs/bannerItems/header2.png')}
                              style={styles.icon}
                            />
                            <CCText style={styles.heading}>
                              2. Earn CC Points
                            </CCText>
                            <CCText style={styles.description}>
                              Players earn CC points by solving positions and
                              reaching targets
                            </CCText>
                          </Box>
                          <Box style={styles.rewardItem}>
                            <Image
                              source={require('@/Assets/Images/Svgs/bannerItems/header3.png')}
                              style={styles.icon}
                            />
                            <CCText style={styles.heading}>
                              3. Redeem Rewards
                            </CCText>
                            <CCText style={styles.description}>
                              Players can redeem rewards using CC points
                            </CCText>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box style={styles.rewardItem}>
                            <Image
                              source={require('@/Assets/Images/Svgs/bannerItems/header1.png')}
                              style={styles.icon}
                            />
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}>
                              <CCText style={styles.heading}>
                                1. Create Reward
                              </CCText>
                              <CCText style={styles.description}>
                                Parents and coaches can create custom rewards
                                for players
                              </CCText>
                            </Box>
                          </Box>
                          <Box style={styles.rewardItem}>
                            <Image
                              source={require('@/Assets/Images/Svgs/bannerItems/header2.png')}
                              style={styles.icon}
                            />
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}>
                              <CCText style={styles.heading}>
                                2. Earn CC Points
                              </CCText>
                              <CCText style={styles.description}>
                                Players earn CC points by solving positions and
                                reaching targets
                              </CCText>
                            </Box>
                          </Box>
                          <Box style={styles.rewardItem}>
                            <Image
                              source={require('@/Assets/Images/Svgs/bannerItems/header3.png')}
                              style={styles.icon}
                            />
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}>
                              <CCText style={styles.heading}>
                                3. Redeem Rewards
                              </CCText>
                              <CCText style={styles.description}>
                                Players can redeem rewards using CC points
                              </CCText>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                  <Box style={styles.disclaimerContainer}>
                    <CCText style={styles.disclaimerText}>
                      * CircleChess doesn't offer any rewards, it's on
                      parent/coach to grant the reward what you want to set for
                      player.
                    </CCText>
                  </Box>
                </Box>
              </Box>
              {allRewards?.map(i => i)}
            </Box>
            <Box style={[styles.rightSide]}>{coinCard(netScore)}</Box>
          </Box>
        );
      case 'Point calculation rules':
        return <Goals rules={rules} />;
      case 'Summary Statement':
        const sortedData = Array.isArray(formattedData)
          ? formattedData.sort((a, b) => {
              const dateA = a?.score_date
                ? new Date(a.score_date).getTime()
                : 0;
              const dateB = b?.score_date
                ? new Date(b.score_date).getTime()
                : 0;
              return dateB - dateA;
            })
          : [];
        return (
          <Box>
            <Spacer spacing={10} />
            <Box
              style={{
                width: !isMobileOrTab ? '95%' : undefined,
                margin: 'auto',
                borderRadius: 12,
                backgroundColor: CCColors.White,
              }}>
              <CCTable headers={headers} data={sortedData} />
            </Box>
            <Spacer spacing={40} />
          </Box>
        );
    }
  };

  // const headers = ['Redemption Date', 'Action', 'Points', 'Reason'];
  const headers = [
    {
      key: 'Redemption Date',
      label: 'Redemption Date',
      render: (value, rowDetails) => {
        return <CCText>{rowDetails?.score_date}</CCText>;
      },
    },
    // {
    //   key: 'Action',
    //   label: 'Action',
    //   render: (val, rowDetails) => {
    //     return <CCText>{rowDetails?.action}</CCText>;
    //   },
    // },
    {
      key: 'Game Details',
      label: 'Game Details',
      render: (val, rowDetails) => {
        const handleGameDetails = () => {
          navigation.navigate(ScreenName?.ChessAnalysis, {
            game_id: rowDetails?.game_uuid,
          });
        };

        return (
          <TouchableOpacity onPress={handleGameDetails}>
            <Box style={styles.cellWrapperBox}>
              <CCText style={styles.cellText}>
                {rowDetails?.opponent_username}
              </CCText>
              <Box type="row-flex-start">
                <CCText
                  color={CCColors.Grey.Grey}
                  fontSize={FontSizes[11]}
                  style={styles.cellText}>
                  {rowDetails?.platform} |{' '}
                </CCText>
                <CCText
                  color={CCColors.Grey.Grey}
                  fontSize={FontSizes[11]}
                  style={styles.cellText}>
                  {rowDetails?.time_class}
                </CCText>
              </Box>
            </Box>
          </TouchableOpacity>
        );
      },
    },
    {
      key: 'Points',
      label: 'Points',
      render: (val, rowDetails) => {
        return (
          <Box
            flex={1}
            justifyContent="center"
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              // width: 96,
              // height: 32,
              padding: 5,
              whiteSpace: 'nowrap',
              borderRadius: 20,
              backgroundColor:
                rowDetails.action === 'credit'
                  ? CCColors.Rewards.Green
                  : CCColors.Rewards.Red,
            }}>
            <Image
              style={{
                width: 20,
                height: 20,
                marginRight: 5,
              }}
              source={
                rowDetails.action === 'credit'
                  ? require('@/Assets/Images/Points/positivePoint.png')
                  : require('@/Assets/Images/Points/negativePoint.png')
              } // Provide the path to your image
            />
            {rowDetails.action === 'credit' && (
              <CCText color={CCColors.Rewards.TextGreen}>
                +{rowDetails.points.toString()} pts.
              </CCText>
            )}
            {rowDetails.action === 'debit' && (
              <CCText color={CCColors.Rewards.TextRed}>
                {rowDetails.points.toString()} pts.
              </CCText>
            )}
          </Box>
        );
      },
    },
    {
      key: 'Reason',
      label: 'Reason',
      render: (val, rowDetails) => {
        return <CCText>{rowDetails?.reason}</CCText>;
      },
    },
  ];
  // const tableData = formattedData
  //   ? formattedData.map(item => {
  //       return [
  //         {name: item.fields.score_date, type: 'text'},
  //         {name: item.fields.action, type: 'text'},
  //         {
  //           type: 'custom', // Use a custom component for redeem points
  //           name: (
  //             <Box
  //               flex={1}
  //               justifyContent="center"
  //               style={{
  //                 flexDirection: 'row',
  //                 alignItems: 'center',
  //                 // width: 96,
  //                 // height: 32,
  //                 padding: 5,
  //                 whiteSpace: 'nowrap',
  //                 borderRadius: 20,
  //                 backgroundColor:
  //                   item.fields.action === 'credit'
  //                     ? CCColors.Rewards.Green
  //                     : CCColors.Rewards.Red,
  //               }}>
  //               <Image
  //                 style={{
  //                   width: 20,
  //                   height: 20,
  //                   marginRight: 5,
  //                 }}
  //                 source={
  //                   item.fields.action === 'credit'
  //                     ? require('@/Assets/Images/Points/positivePoint.png')
  //                     : require('@/Assets/Images/Points/negativePoint.png')
  //                 } // Provide the path to your image
  //               />
  //               {item.fields.action === 'credit' && (
  //                 <CCText color={CCColors.Rewards.TextGreen}>
  //                   +{item.fields.points.toString()} pts.
  //                 </CCText>
  //               )}
  //               {item.fields.action === 'debit' && (
  //                 <CCText color={CCColors.Rewards.TextRed}>
  //                   -{item.fields.points.toString()} pts.
  //                 </CCText>
  //               )}
  //             </Box>
  //           ),
  //         },
  //         {name: item.fields.reason, type: 'text'},
  //       ];
  //     })
  //   : [];

  // const tableData = formattedData
  //   ? formattedData.map(item => {
  //       return [
  //         {name: item.fields.score_date, type: 'text'},
  //         {name: item.fields.action, type: 'text'},
  //         {
  //           type: 'custom', // Use a custom component for redeem points
  //           name: (
  //             <Box
  //               flex={1}
  //               justifyContent="center"
  //               style={{
  //                 flexDirection: 'row',
  //                 alignItems: 'center',
  //                 // width: 96,
  //                 // height: 32,
  //                 padding: 5,
  //                 whiteSpace: 'nowrap',
  //                 borderRadius: 20,
  //                 backgroundColor:
  //                   item.fields.action === 'credit'
  //                     ? CCColors.Rewards.Green
  //                     : CCColors.Rewards.Red,
  //               }}>
  //               <Image
  //                 style={{
  //                   width: 20,
  //                   height: 20,
  //                   marginRight: 5,
  //                 }}
  //                 source={
  //                   item.fields.action === 'credit'
  //                     ? require('@/Assets/Images/Points/positivePoint.png')
  //                     : require('@/Assets/Images/Points/negativePoint.png')
  //                 } // Provide the path to your image
  //               />
  //               {item.fields.action === 'credit' && (
  //                 <CCText color={CCColors.Rewards.TextGreen}>
  //                   +{item.fields.points.toString()} pts.
  //                 </CCText>
  //               )}
  //               {item.fields.action === 'debit' && (
  //                 <CCText color={CCColors.Rewards.TextRed}>
  //                   -{item.fields.points.toString()} pts.
  //                 </CCText>
  //               )}
  //             </Box>
  //           ),
  //         },
  //         {name: item.fields.reason, type: 'text'},
  //       ];
  //     })
  //   : [];

  console.log('tabledata', formattedData);

  return (
    <Box style={styles.rewardsWrapper}>
      <ConfirmationModal
        callRedeemReward={callRedeemReward}
        errorMessage={errorMessage}
        onConfirmModalClose={onConfirmModalClose}
        redeemInProgress={redeemInProgress}
        redeemSuccess={redeemSuccess}
        selectedReward={selectedReward}
        showConfirm={showConfirm}
      />
      <AddNewRewardModel
        handleCreateReward={handleCreateReward}
        newRewardCost={newRewardCost}
        newRewardDescription={newRewardDescription}
        newRewardName={newRewardName}
        setNewRewardCost={setNewRewardCost}
        setNewRewardDescription={setNewRewardDescription}
        setNewRewardName={setNewRewardName}
        setShowCreateModal={setShowCreateModal}
        showCreateModal={showCreateModal}
      />
      <Box margin={24}>
        <CCText fontSize={FontSizes[24]}>Rules & Rewards</CCText>
        {/* <ConditionalRender
          condition={tab === 'rewards'}
          childrenA={
            <CCText fontSize={FontSizes[14]}>
              Use your points to redeem exciting rewards
            </CCText>
          }
          childrenB={
            <CCText fontSize={FontSizes[14]}>
              Review your transaction history for a detailed account of your
              activities
            </CCText>
          }
        /> */}
        {/* <Box flexDirection="row">
          <Feather name="calendar" size={16} color={CCColors.TextColor.Grey} />
          <Spacer spacing={hs(5)} horizontal />
          <CCText color={CCColors.TextColor.Grey}>
            Rules applicable till:
            <Spacer spacing={hs(5)} horizontal />
            <CCText color={CCColors.TextColor.Primary}>
              {dayjs().format('YYYY-MM-DD')} to{' '}
              {dayjs(endDate).format('YYYY-MM-DD')}
            </CCText>
          </CCText>
        </Box> */}
      </Box>

      {/* <Box
        flexDirection="row"
        gap={20}
        marginLeft={25}
        style={{marginBottom: 0}}>
        <Pressable
          onPress={() => {
            setTab('rewards');
          }}>
          <CCText style={tab === 'rewards' ? styles.active : {}}>
            Rewards
          </CCText>
        </Pressable>
        <Pressable
          onPress={() => {
            setTab('summary');
          }}>
          <CCText style={tab === 'summary' ? styles.active : {}}>
            Summary Statement
          </CCText>
        </Pressable>
      </Box> */}

      {/* <Divider /> */}
      <TabView
        // lazy
        //   style={styles.contentContainerStyle}
        // @ts-ignore
        style={{marginLeft: '2%'}}
        navigationState={{index: index, routes}}
        renderScene={renderScene}
        renderTabBar={renderBoxTabBar}
        onIndexChange={setIndex}
        // initialLayout={{width: WINDOW_WIDTH / 3}}
        // style={{height: '75vh'}}
      />
      {/* <ConditionalRender
        condition={tab === 'rewards'}
        childrenA={
          <Box
            flexDirection={isMobileOrTab ? 'column-reverse' : 'row'}
            justifyContent="space-between">
            <Box style={[styles.leftSide, styles.container]}>
              {allRewards?.map(i => i)}
            </Box>
            <Box style={[styles.rightSide]}>{coinCard(netScore)}</Box>
          </Box>
        }
        childrenB={
          <Box>
            <Spacer spacing={20}></Spacer>

            <Box
              style={{
                width: !isMobileOrTab ? '95%' : undefined,
                marginLeft: 'auto',
                marginRight: 'auto',
                borderRadius: 12,
                backgroundColor: CCColors.White,
              }}>
              <CCTable headers={headers} data={formattedData} />
            </Box>
            <Spacer spacing={40} />
          </Box>
        }
      /> */}
      <SubscriptionModal
        visible={subscriptionModalOpen}
        onClose={() => setSubscriptionModalOpen(false)}
        planMessage={planMessage}
        currentPlan={planName}
      />
    </Box>
  );
};

export default RewardsComponent;
