import ChessRestClient from '@/Api/ChessRestClient';
import {useMutation} from '@tanstack/react-query';

type AssistedFeedbackProp = {
  feedback: number;
  uuid: number;
  userid: string;
  move_index: number;
};

export default function useAssistedPuzzleFeedback() {
  return useMutation(async (payload: AssistedFeedbackProp) => {
    const response = await ChessRestClient.post(
      'game/assisted_hint_feedback',
      payload,
    );
    if (!response.data) {
      throw new Error('Response data is null');
    }
    return response.data;
  });
}
