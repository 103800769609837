import { FontSizes, WINDOW_HEIGHT, WINDOW_WIDTH, hs, isPhone, vs } from "../../Utils/Dimensions";

import CCColors from "../../Utils/CCColors";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    height: WINDOW_HEIGHT,
    width: WINDOW_WIDTH,
  },
  signInTypo: {
    textAlign: "right",
    left: "50%",
    top: 48,
    color: CCColors?.Primary?.Default,
    position: "absolute",
  },
  labelText: {
    width: '100%',
    height: '100%'
  },
  rightlabelText: {
    paddingLeft: vs(120),
    flex: 0.5,
    marginTop: '6%'
  },
  buttonStyles: {
    fontSize: FontSizes[9],
    paddingVertical: vs(3),
  },
  buttonWrapper: {
    ...(isPhone && { width: "70%", margin: "auto" }),
  },
});
