import ChessRestClient from '@/Api/ChessRestClient';
import {useMutation} from '@tanstack/react-query';

export default function getIntuitionTrainerLevel(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<Error>(
    async () => {
      const response = await ChessRestClient.get(
        'learning/get_intuition_trainer_level/',
      );
      return response.data;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) handleOnSuccess(data);
        else if (handleOnError) handleOnError(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
