import SlideModal from '@/Atoms/SlideModal';
import React from 'react';
import {View, Text, FlatList, StyleSheet, TouchableOpacity} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';

const NotificationModal = ({
  open,
  handleClose,
  notifications = [],
  onDeleteNotification, // Callback to delete a notification
}: {
  open: boolean;
  handleClose: Function;
  notifications: {id: string; message: string; timestamp: string}[];
  onDeleteNotification: (id: string) => void; // Function to handle delete
}) => {
  const renderNotification = ({item}: {item: any}) => (
    <View style={styles.notificationCard}>
      <View style={styles.notificationContent}>
        <Text style={styles.notificationMessage}>{item.message}</Text>
        <Text style={styles.notificationTimestamp}>
          {new Date(item.timestamp).toLocaleString()}
        </Text>
      </View>
      <TouchableOpacity
        onPress={() => onDeleteNotification(item.id)}
        style={styles.deleteIcon}>
        <Icon name="close" size={20} color="#ff0000" />
      </TouchableOpacity>
    </View>
  );

  return (
    <SlideModal
      visible={open}
      type="center"
      customStyle={styles.levelModalCustom}
      onClose={handleClose}
      modalHeaderTitle={`Notifications (${notifications?.length})`}>
      <FlatList
        data={notifications}
        keyExtractor={item => item.id}
        renderItem={renderNotification}
        contentContainerStyle={styles.notificationList}
      />
    </SlideModal>
  );
};

const styles = StyleSheet.create({
  levelModalCustom: {
    width: '90%',
    maxHeight: '80%',
    borderRadius: 15,
    padding: 15,
    backgroundColor: '#f9f9f9',
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 5},
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
  },
  notificationList: {
    paddingBottom: 20,
  },
  notificationCard: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#ffffff',
    padding: 15,
    marginVertical: 8,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
    borderWidth: 1,
    borderColor: '#f0f0f0',
  },
  notificationContent: {
    flex: 1,
    marginRight: 10,
  },
  notificationMessage: {
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 5,
    color: '#333',
  },
  notificationTimestamp: {
    fontSize: 12,
    color: '#888',
  },
  deleteIcon: {
    padding: 5,
  },
});

export default NotificationModal;
