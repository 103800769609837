import CCColors from '@/Utils/CCColors';
import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  skillBox: {
    // flexBasis: '49%',
    flexBasis: '49%',
    backgroundColor: CCColors?.Primary?.lightBrown,
    padding: 24,
    borderRadius: 4,
  },
  skillBoxMobile: {
    // flexBasis: '49%',
    margin: 10,
    backgroundColor: CCColors?.Primary?.lightBrown,
    padding: 10,
    borderRadius: 4,
  },
  ratingBox: {
    // width: '100%',
    marginVertical: 4,
    paddingHorizontal: 12,
    borderRadius: 4,
    paddingVertical: 6,
  },
});
