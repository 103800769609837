import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import React, {useCallback, useMemo} from 'react';
import {SkillName} from './types';
import {styles} from './styles';
import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import {FontSizes} from '@/Utils/Dimensions';
import {RenderProgress} from '../../../AssessmentDetails';
import ProgressBar from '@/Atoms/ProgressBar';
import Spacer from '@/Atoms/Spacer';
import {View} from 'react-native';
import Tooltip from '@/Atoms/Tooltip';
import Feather from 'react-native-vector-icons/Feather';
import {capitalizeFirstLetter} from '@/Utils/CommonUtils';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';

const RatingLevelBox = ({
  rating,
  defaultLevel,
}: {
  rating: number;
  defaultLevel: number;
}) => {
  //   return <CCText>{rating}</CCText>;
  const ratingConfig = [
    {maxRating: 20, level: 1, color: CCColors.Red},
    {maxRating: 40, level: 2, color: CCColors.Progress.Bar},
    {maxRating: 60, level: 3, color: CCColors.Blue},
    {maxRating: 99, level: 4, color: CCColors.Green},
    {maxRating: Infinity, level: 5, color: CCColors.Green},
  ];

  const getRatingLevel = useMemo(() => {
    const config = ratingConfig.find(({level}) => defaultLevel <= level);
    return config ? {level: config.level, color: config.color} : null;
  }, [defaultLevel]);

  return (
    <Tooltip
      text={
        <Box>
          <Box type="row-flex-start">
            <CCText fontSize={14} color={CCColors.White}>
              Rating{'  '} :{'  '}
            </CCText>
            <CCText fontSize={14} color={getRatingLevel?.color}>
              {rating}%
            </CCText>
          </Box>
          <Box type="row-flex-start">
            <CCText fontSize={14} color={CCColors.White}>
              Level{'  '} :{'  '}
            </CCText>
            <CCText fontSize={14} color={getRatingLevel?.color}>
              {getRatingLevel?.level}
            </CCText>
          </Box>
        </Box>
      }
      children={toggleEvent => (
        <View
          style={{
            width: '100%',
          }}
          onPointerEnter={toggleEvent}
          onPointerLeave={toggleEvent}>
          <Box
            type="row-center-between"
            style={[
              styles?.ratingBox,
              {
                borderColor: updateColorWithOpacity(getRatingLevel?.color, 0.7),
                borderWidth: 1,
                backgroundColor: updateColorWithOpacity(
                  getRatingLevel?.color,
                  0.1,
                ),
              },
            ]}>
            <Box>
              <CCText color={getRatingLevel?.color}>{rating}%</CCText>
            </Box>
            <Spacer spacing={27} horizontal />
            <Box>
              {[5, 4, 3, 2, 1]?.map(i => (
                <ProgressBar
                  //   onPointerMove={() => setTooltipVisible(true)}
                  //   onPress={() => setTooltipVisible(true)}
                  //   onPointerLeave={() => setTooltipVisible(false)}
                  style={{
                    width: 24,
                    height: 6,
                    marginVertical: 2,
                    //   backgroundColor: getRatingLevel?.color,
                  }}
                  progress={1}
                  fillStyle={{
                    backgroundColor:
                      i > getRatingLevel?.level
                        ? CCColors?.White
                        : getRatingLevel?.color,

                    // backgroundColor:
                    //   index == 0
                    //     ? CCColors.Green
                    //     : index == 1
                    //     ? CCColors.ChessBoard.Dark
                    //     : CCColors.Red,
                  }}
                  text={undefined}
                  icon={undefined}
                />
              ))}
            </Box>
          </Box>
        </View>
      )}
    />
  );
};
function SkillsBox({skillGrpDetails}: {skillGrpDetails: SkillName}) {
  const {isMobileOrTab} = useMediaQuery();
  return (
    <Box style={[styles.skillBox, isMobileOrTab && styles.skillBoxMobile]}>
      <CCText fontSize={FontSizes[14]} fontWeight="bold">
        {skillGrpDetails?.title}
      </CCText>
      <Box type="row-center-between">
        <Box flex={isMobileOrTab ? 0.6 : 0.7}></Box>
        <Box flex={isMobileOrTab ? 0.4 : 0.3}>
          <CCText
            textAlign="center"
            color={CCColors?.TextColor?.Grey}
            fontSize={FontSizes[12]}>
            Rating & level
          </CCText>
        </Box>
      </Box>
      {skillGrpDetails?.skills?.map(eachSkills => (
        <Box type="row-center-between">
          <Box flex={isMobileOrTab ? 0.6 : 0.7}>
            <CCText>
              <Tooltip
                text={
                  <CCText fontSize={14} color={CCColors.White}>
                    {eachSkills?.skillDetails?.infoString
                      ? eachSkills?.skillDetails?.infoString
                      : 'N/A'}
                  </CCText>
                }
                children={toggleEvent => (
                  <View
                    onPointerEnter={toggleEvent}
                    onPointerLeave={toggleEvent}>
                    <Feather
                      name="info"
                      size={16}
                      color={CCColors.TextColor.Grey}
                      style={{marginRight: 5}}
                    />
                  </View>
                )}
              />
              {capitalizeFirstLetter(eachSkills?.name)}
            </CCText>
          </Box>
          <Box type="row-center-between" flex={isMobileOrTab ? 0.4 : 0.3}>
            <RatingLevelBox
              rating={eachSkills?.skillDetails?.rating}
              defaultLevel={eachSkills?.skillDetails?.defaultLevel}
            />{' '}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default SkillsBox;
