import create from 'zustand';
import {persist} from 'zustand/middleware';
import {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
} from '@/Utils/CommonUtils';

interface IntuitionTrainerState {
  selectedTrainer: string;
  playerColor: string;
  games: any[];
  level: number;
  pgn: string[];
  isGamesFetched: boolean;
  currentPage: number;
  totalPages: number;
  isGameStarted: boolean;
  item: any;
  text1: string;
  noOfMovesAttempted: number;
  boardPosition: string;
  isToggleEnabled: boolean;
  isTimerOn: string;
  currentMoveIndex: number;
  movesList: any[];
  count: number;
  engineMatchCount: number;
  lateGuesses: number;
  blackPieces: any;
  whitePieces: any;
  counter: number;
  currentColor: string;
  gameAccuracy: number;
  totalItems: number;
}

interface IntuitionTrainerActions {
  setSelectedTrainer: (trainer: string) => void;
  setPlayerColor: (playerColor: string) => void;
  setTotalItems: (totalItems: number) => void;
  setGames: (games: any[]) => void;
  setLevel: (level: number) => void;
  setPgn: (pgn: string[]) => void;
  setIsGamesFetched: (val: boolean) => void;
  setCurrentPage: (currentPage: number) => void;
  setTotalPages: (totalPages: number) => void;
  setIsGameStarted: (val: boolean) => void;
  setItem: (item: any) => void;
  setText1: (text1: string) => void;
  setNoOfMovesAttempted: (val: number) => void;
  setBoardPosition: (position: string) => void;
  setIsToggleEnabled: (val: boolean) => void;
  setIsTimerOn: (val: string) => void;
  setCurrentMoveIndex: (val: number) => void;
  setMovesList: (prevMoves: any[]) => void;
  setCount: (val: number) => void;
  setEngineMatchCount: (val: number) => void;
  setLateGuesses: (val: number) => void;
  setBlackPieces: (pieces: any) => void;
  setWhitePieces: (pieces: any) => void;
  setCounter: (val: number) => void;
  setCurrentColor: (col: string) => void;
  setGameAccuracy: (val: number) => void;
}

const useIntuitionTrainerState = create(
  persist<IntuitionTrainerState & IntuitionTrainerActions>(
    set => ({
      selectedTrainer: '',
      playerColor: '',
      games: [],
      totalItems: 0,
      level: 0,
      pgn: [],
      isGamesFetched: false,
      currentPage: 0,
      totalPages: 0,
      isGameStarted: false,
      item: null,
      text1: '',
      noOfMovesAttempted: 0,
      boardPosition: '',
      isToggleEnabled: false,
      isTimerOn: 'No',
      currentMoveIndex: 0,
      movesList: [],
      count: 0,
      engineMatchCount: 0,
      lateGuesses: 0,
      blackPieces: [],
      whitePieces: [],
      counter: 0,
      currentColor: '',
      gameAccuracy: 0,

      setSelectedTrainer: trainer => set({selectedTrainer: trainer}),
      setPlayerColor: playerColor => set({playerColor}),
      setGames: games => set({games}),
      setTotalItems: totalItems => set({totalItems}),
      setLevel: level => set({level}),
      setPgn: pgn => set({pgn}),
      setIsGamesFetched: val => set({isGamesFetched: val}),
      setCurrentPage: currentPage => set({currentPage}),
      setTotalPages: totalPages => set({totalPages}),
      setIsGameStarted: val => set({isGameStarted: val}),
      setItem: item => set({item}),
      setText1: text1 => set({text1}),
      setNoOfMovesAttempted: val => set({noOfMovesAttempted: val}),
      setBoardPosition: position => set({boardPosition: position}),
      setIsToggleEnabled: val => set({isToggleEnabled: val}),
      setIsTimerOn: val => set({isTimerOn: val}),
      setCurrentMoveIndex: val => set({currentMoveIndex: val}),
      setMovesList: prevMoves => set({movesList: prevMoves}),
      setCount: val => set({count: val}),
      setEngineMatchCount: val => set({engineMatchCount: val}),
      setLateGuesses: val => set({lateGuesses: val}),
      setBlackPieces: pieces => set({blackPieces: pieces}),
      setWhitePieces: pieces => set({whitePieces: pieces}),
      setCounter: val => set({counter: val}),
      setCurrentColor: col => set({currentColor: col}),
      setGameAccuracy: val => set({gameAccuracy: val}),
    }),
    {
      name: 'intuition-trainer-state', // Name for the persisted store
      getStorage: () => ({
        getItem: async name => {
          const result = await getLocalStorage(name);
          return result ? JSON.parse(result) : null;
        },
        setItem: async (name, value) => {
          await setLocalStorage(name, JSON.stringify(value));
        },
        removeItem: async name => {
          await removeLocalStorage(name);
        },
      }), // Use localStorage for persistence
    },
  ),
);

export default useIntuitionTrainerState;
