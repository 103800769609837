import ChessRestClient from '@/Api/ChessRestClient';
import RandomApiClient from '../../Api/RandomApiClient';
import {LoginResponse, SendOtpResponse} from './types';
import {useMutation, useQuery} from '@tanstack/react-query';
import {useState, useEffect} from 'react';
import {getLocalStorage, setLocalStorage} from '../../Utils/CommonUtils';
import useChatRestClient from '@/Api/ChatRestClient';
import {useRoute} from '@react-navigation/native';
import {useUserPlanStore} from '@/Store/userPlanStore';

export default function useLogin(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<
    LoginResponse,
    Error,
    {
      username: string;
      password: string;
      email: string;
      isLogin: boolean;
    }
  >(
    async loginData => {
      console.log('Calling Login / Registration API..');
      const {username, password, email, isLogin} = loginData;
      if (isLogin) {
        console.log('About to call login api ..');
        const response = await ChessRestClient.post('v1/login_api/', {
          username,
          password,
        });
        return response.data;
      } else {
        console.log('About to call register api ..');
        const response = await ChessRestClient.post('v1/register_api/', {
          username,
          password,
          email,
        });
        return response.data;
      }
    },
    {
      onSuccess: data => {
        if (handleOnSuccess && data.success) handleOnSuccess(data);
        else if (handleOnError) handleOnError(data);
      },
    },
  );

  return {
    ...mutation,
  };
}

// TODO: temp code, cleanup

export const useLoginState = reload => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const {setPlanDetails} = useUserPlanStore();
  const [error, setError] = useState('');
  useEffect(() => {
    const fetchData = async function () {
      try {
        setLoading(true);
        const token = await getLocalStorage('access_token');
        console.log('useLoginState token: ', token);

        if (token) {
          const response = await ChessRestClient.get(
            'dashboard/v1/summary/?website=learning',
          );
          if (response.status === 200) {
            setData(response.data);
            const updateLocalStorage = async () => {
              try {
                await setLocalStorage(
                  'user_details',
                  JSON.stringify(response?.data?.data?.user),
                  true,
                );
                setPlanDetails?.(
                  response?.data?.data?.user_subscription_details,
                );
              } catch (error) {
                console.error('Failed to set local storage:', error);
                setError(`API request failed with status ${response.status}`);
              }
            };
            await updateLocalStorage();
            setIsUserLoggedIn(true);
          }
        } else {
          setData({});
          setIsUserLoggedIn(false);
        }
      } catch (error) {
        setError('An unexpected error occurred. We will resolve it shortly.');
        throw error;
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [reload]);

  return {loading, data, isUserLoggedIn, error};
};

export const sendOtp = (
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) => {
  const ChatRestClient = useChatRestClient();
  const mutation = useMutation<
    SendOtpResponse,
    Error,
    {
      mobile: string;
      countryCode: string;
      source: string;
    }
  >(
    async loginData => {
      const {mobile, countryCode} = loginData;
      const response = await ChatRestClient.post('auth/v1/send_otp', {
        mobile_number: mobile,
        country_code: countryCode,
        source: 'learn',
      });
      return response.data;
    },
    {
      onSettled: (data, error) => {
        if (handleOnSuccess && data) handleOnSuccess(data);
        else if (handleOnError && error) handleOnError(error);
      },
    },
  );

  return {
    ...mutation,
  };
};

export const verifyOtp = (
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) => {
  const ChatRestClient = useChatRestClient();
  const mutation = useMutation<
    SendOtpResponse,
    Error,
    {
      mobile: string;
      countryCode: string;
      otp: string;
    }
  >(
    async loginData => {
      const {mobile, countryCode, otp} = loginData;
      const response = await ChatRestClient.post('auth/v1/verify_otp', {
        mobile_number: mobile,
        country_code: countryCode,
        otp,
        sender: 'ccl',
      });
      return response.data;
    },
    {
      onSettled: (data, error) => {
        if (handleOnSuccess && data) handleOnSuccess(data);
        else if (handleOnError && error) handleOnError(error);
      },
    },
  );

  return {
    ...mutation,
  };
};

export const getTableauWorkbooks = (
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) => {
  const mutation = useMutation<any, Error>(
    async () => {
      const response = await ChessRestClient.get('learning/tableau_views/');
      return response.data;
    },
    {
      onSettled: (data, error) => {
        if (handleOnSuccess && data) handleOnSuccess(data);
        else if (handleOnError && error) handleOnError(error);
      },
    },
  );

  return {
    ...mutation,
  };
};
