import {RunScoreAnalysisResponse} from './types';
import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useGameAnalysisScoreApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<RunScoreAnalysisResponse, Error, void>(
    async game_uuid => {
      const response = await ChessRestClient.get(
        'learning/run_score_analysis/',
        {
          headers: {'Content-Type': 'application/json'},
          params: {game_uuid},
        },
      );
      console.log('Game Analysis Reponse = ', response);
      return response.data;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess && data.success) handleOnSuccess(data);
        else if (handleOnError) handleOnError(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
