import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import LottieView from 'react-native-web-lottie';
import { vs, isPhone, FontSizes, hs, isPortrait } from '@/Utils/Dimensions';
import Rive from '@rive-app/react-canvas';
import { useRive, useStateMachineInput, Layout, Fit, Alignment } from '@rive-app/react-canvas';
import React, { useState, useEffect, } from 'react';

function SearchProgressComponent(props) {
    const dumb = () => {};

    const { visible = false, cancelSearch={dumb}, started, opponent } = props;

    

    const {RiveComponent, rive} = useRive({
        src: require("@/Assets/Animation/circlechess_opponent_matching_animation.riv"),
        // artboard: "Find Opponent 3",
        stateMachines: "Opponent Matching ",
        layout: new Layout({
            fit: Fit.Cover,
            // alignment: Alignment.TopCenter,
          }),
        autoplay: true,
      });

    useEffect(() => {
    if (rive) {
        console.log(rive.stateMachineNames);
        console.log("Rive text was initially: ", rive.getTextRunValue("Opponent Name"));
        rive.setTextRunValue("Opponent Name", "Searching...");
        console.log("Rive text is now: ", rive.getTextRunValue("Opponent Name"));
    }
    }, [rive]);

    const matchInput = useStateMachineInput(rive, "Opponent Matching ", "Character", 0);

    

    useEffect(() => {
        if(started ){
            console.log("opponent is: ",opponent);
            if (matchInput) {
                matchInput.value = Math.floor(Math.random() * 5) + 1;
            }
            if(rive){
                rive.setTextRunValue("Opponent Name", opponent);
                console.log("Rive text is now: ", rive.getTextRunValue("Opponent Name"));
            } 
        }else if(matchInput) {
            matchInput.value = 0
        }
    },[started,opponent])

    return (
        visible &&
        <Box flex={(isPhone || isPortrait) ? 1 : 0.45} flexDirection="column" style={{
            height: '89%', alignItems: 'center' , marginTop: '2%' 
        }}>
            <Box style={{width: '70vh', aspectRatio: 1, borderRadius: 8,shadowColor: "#4D3F37",
            // paddingBottom: 12,
            shadowOffset: {
              width: 0,
              height: 0,
            },
            flex: 1,
            shadowOpacity: 0.1,
            shadowRadius: 3.84,
            marginBottom: 14}} overflow='hidden'>
                <RiveComponent />
            </Box> 
            {/* <LottieView source={require('../../../../Assets/Animation/Search.json')} autoPlay loop={true} /> */}
            <CCButton style={{alignSelf: 'center', width: 200}} onPress={cancelSearch}><CCText style={{ textAlign: 'center' }}>Cancel Search</CCText></CCButton>
        </Box>
    );

}

export default SearchProgressComponent;