import React, {useState, useEffect, useRef} from 'react';
import {View, StyleSheet} from 'react-native';
import ChessboardComponent from 'chessboard-package';
import CCColors from '@/Utils/CCColors';

const ChessboardWithArrow = ({boardOrientation = 'white', ...rest}) => {
  const [arrows, setArrows] = useState([]);
  const [markedSquares, setMarkedSquares] = useState([]);
  const [currentArrow, setCurrentArrow] = useState(null);
  const [dragStartSquare, setDragStartSquare] = useState(null);
  const [boardWidth, setBoardWidth] = useState(0);
  const boardRef = useRef(null);

  const colorOptions = {
    default: CCColors.Green,
    shiftFn: CCColors.Red,
    optionCmd: CCColors.Blue,
  };

  const getArrowColor = event => {
    if (event.ctrlKey || event.metaKey) return colorOptions.optionCmd;
    else if (event.shiftKey || event.key === 'Fn') return colorOptions.shiftFn;
    else if (event.altKey) return colorOptions.shiftFn;
    return colorOptions.default;
  };

  const flipSquare = square => {
    if (boardOrientation === 'white') return square;

    const file = square.charCodeAt(0);
    const rank = parseInt(square[1], 10);
    const flippedFile = String.fromCharCode(104 - (file - 97));
    const flippedRank = 9 - rank;
    return `${flippedFile}${flippedRank}`;
  };

  const getSquarePosition = square => {
    const flippedSquare = flipSquare(square);
    const file = flippedSquare.charCodeAt(0) - 97;
    const rank = 8 - parseInt(flippedSquare[1], 10);
    return {x: file * (boardWidth / 8), y: rank * (boardWidth / 8)};
  };

  const getSquareFromEvent = (event, boardRect) => {
    const {clientX, clientY} = event;
    const squareSize = boardRect.width / 8;
    const x = Math.floor((clientX - boardRect.left) / squareSize);
    const y = Math.floor((clientY - boardRect.top) / squareSize);

    const file = String.fromCharCode(97 + x);
    const rank = 8 - y;
    const square = `${file}${rank}`;
    return flipSquare(square);
  };

  const handleMouseDown = event => {
    if (event.button === 0) {
      setArrows([]);
      setMarkedSquares([]);
    }
    if (event.button === 2) {
      event.preventDefault();
      const boardElement = boardRef.current;
      if (boardElement) {
        const boardRect = boardElement.getBoundingClientRect();
        const startSquare = getSquareFromEvent(event, boardRect);
        const color = getArrowColor(event);
        setDragStartSquare(startSquare);
        setCurrentArrow([startSquare, startSquare, color]);
      }
    }
  };

  const handleMouseMove = event => {
    if (dragStartSquare) {
      const boardElement = boardRef.current;
      if (boardElement) {
        const boardRect = boardElement.getBoundingClientRect();
        const currentSquare = getSquareFromEvent(event, boardRect);
        const color = currentArrow ? currentArrow[2] : CCColors.Green;
        setCurrentArrow([dragStartSquare, currentSquare, color]);
      }
    }
  };

  const handleMouseUp = event => {
    if (event.button === 2 && dragStartSquare) {
      event.preventDefault();
      const boardElement = boardRef.current;
      if (boardElement) {
        const boardRect = boardElement.getBoundingClientRect();
        const endSquare = getSquareFromEvent(event, boardRect);
        const color = currentArrow ? currentArrow[2] : CCColors.Green;

        if (dragStartSquare === endSquare) {
          setMarkedSquares(prev =>
            prev.some(mark => mark.square === endSquare)
              ? prev.filter(mark => mark.square !== endSquare)
              : [...prev, {square: endSquare, color}],
          );
        } else {
          const newArrow = [dragStartSquare, endSquare, color];
          setArrows(prevArrows =>
            prevArrows.some(
              arrow =>
                arrow[0] === newArrow[0] &&
                arrow[1] === newArrow[1] &&
                arrow[2] === newArrow[2],
            )
              ? prevArrows.filter(
                  arrow =>
                    !(
                      arrow[0] === newArrow[0] &&
                      arrow[1] === newArrow[1] &&
                      arrow[2] === newArrow[2]
                    ),
                )
              : [...prevArrows, newArrow],
          );
        }
      }
      setDragStartSquare(null);
      setCurrentArrow(null);
    }
  };

  const handleContextMenu = event => {
    event.preventDefault();
  };

  useEffect(() => {
    const updateBoardWidth = () => {
      if (boardRef.current) {
        const boardRect = boardRef.current.getBoundingClientRect();
        setBoardWidth(boardRect.width);
      }
    };

    updateBoardWidth();
    const resizeObserver = new ResizeObserver(updateBoardWidth);
    if (boardRef.current) {
      resizeObserver.observe(boardRef.current);
    }

    return () => {
      if (boardRef.current) {
        resizeObserver.unobserve(boardRef.current);
      }
    };
  }, []);

  return (
    <View
      style={styles.container}
      onContextMenu={handleContextMenu}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}>
      <View ref={boardRef} style={{width: '100%'}}>
        {boardWidth > 0 && (
          <>
            <ChessboardComponent
              boardWidth={boardWidth}
              customArrows={currentArrow ? [...arrows, currentArrow] : arrows}
              boardOrientation={boardOrientation}
              customBoardID="BoardWithDynamicWidth"
              {...rest}
            />
            {markedSquares.map(({square, color}) => {
              const {x, y} = getSquarePosition(square);
              return (
                <View
                  key={square}
                  style={{
                    position: 'absolute',
                    top: y,
                    left: x,
                    width: boardWidth / 8,
                    height: boardWidth / 8,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <View
                    style={{
                      width: '95%',
                      height: '95%',
                      borderRadius: '85%',
                      borderWidth: 4,
                      borderColor: color,
                      backgroundColor: 'transparent',
                    }}
                  />
                </View>
              );
            })}
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ChessboardWithArrow;
