import CCColors from '../../../../Utils/CCColors';
import {StyleSheet} from 'react-native';
import isMobileOrTab from '@/Hooks/useIsMobile';

const styles = StyleSheet.create({
  modalBackground: {
    flex: 1,
    backgroundColor: CCColors?.White,
    padding: 20,
    justifyContent: 'center',
    borderRadius: 10,
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
    color: CCColors?.TextColor?.Primary,
    textAlign: 'center',
    flex: 1,
  },
  input: {
    width: '100%',
    height: 130,
    borderColor: CCColors?.Border,
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    marginBottom: 15,
    fontSize: 16,
    textAlignVertical: 'top',
  },
  uploadFiles: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: CCColors?.Primary?.lightBrown,
    paddingVertical: 10,
    paddingHorizontal: 15,
    marginBottom: 15,
    width: '100%',
    borderRadius: 8,
    border: '1px dashed rgba(34, 34, 34, 0.20)',
    height: 150,
    display: 'flex',
    cursor: 'pointer',
  },
  uploadText: {
    textAlign: 'center',
    color: CCColors?.TextColor?.Primary,
    fontSize: 16,
    marginRight: 10,
  },
  deleteIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
    color: CCColors?.Red,
  },
  button: {
    width: '100%',
    alignSelf: 'center',
  },
  successContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  successIcon: {
    marginBottom: 10,
  },
  successText: {
    textAlign: 'center',
    color: CCColors?.TextColor?.Primary,
    fontSize: 18,
    marginRight: 10,
    maxWidth: '90%',
    width: isMobileOrTab ? '100%' : '50%',
    flexWrap: 'wrap',
  },
});

export default styles;
