import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

type createStudyType = {
  name: string;
  chapter: {
    name: string;
    fen?: string;
    pgn?: string;
    orientation: 'W' | 'B';
  };
};
export default function useCreateStudyGame() {
  return useMutation(async (payload: createStudyType) => {
    const response = await ChessRestClient.post(
      'learning/study_games/create/',
      payload,
    );
    return response.data;
  });
}

// {
//   name: newStudyDetails?.name,
//   chapter: {
//     name: newChapterDetails?.name,
//     ...startingPos,
//     orientation: orientation,
//   },
// }
