// import useChatRestClient from '@/Api/ChatRestClient';
// import {getLocalStorage, getUserDetails} from '@/Utils/CommonUtils';
// import {useMutation} from '@tanstack/react-query';

// export default function useVerifyFideID() {
//   const ChatRestClient = useChatRestClient();
//   const mutation = useMutation(async ({fide_id}: {fide_id: string}) => {
//     let userDetails = await getUserDetails();
//     userDetails = JSON.parse(userDetails);
//     const mobile = userDetails?.mobile_number;
//     // const mobile = await getLocalStorage('mobile');
//     if (!mobile || !fide_id) {
//       throw new Error('Missing mobile number or FIDE ID');
//     }

//     const response = await ChatRestClient.post('/v1/verify_fide_id', {
//       fide_id: fide_id,
//       mobile_number: mobile,
//     });

//     if (!response.data?.name) {
//       throw new Error('Response data is null');
//     }

//     return response.data;
//   });

//   return {
//     ...mutation,
//     isLoading: mutation.isLoading,
//   };
// }

// import {useMutation} from '@tanstack/react-query';
// import ChessRestClient from '@/Api/ChessRestClient';

// export default function useSaveOTBGamePGN() {
//   return useMutation(
// async ({
//   gameUuid,
//   pgnContent,
//   isPublished,
// }: {
//   gameUuid: string;
//   pgnContent: TemplateStringsArray;
//   isPublished: boolean;
// }) => {
// const response = await ChessRestClient.post('learning/save-otb-pgn/', {
//   game_uuid: gameUuid,
//   pgn_content: pgnContent,
//   is_published: isPublished,
// });
//       return response.data;
//     },
//   );
// }

import useChatRestClient from '@/Api/ChatRestClient';
import ChessRestClient from '@/Api/ChessRestClient';
import {getUserDetails} from '@/Utils/CommonUtils';
// import {SetAvatarResponse} from './types';
import {useMutation} from '@tanstack/react-query';

export default function useVerifyFideID(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const ChatRestClient = useChatRestClient();
  const mutation = useMutation(
    async ({fide_id}: {fide_id: string}) => {
      // const {gameMoveId, annotation} = details;
      let userDetails = await getUserDetails();
      userDetails = JSON.parse(userDetails);
      const mobile = userDetails?.mobile_number;
      // const mobile = await getLocalStorage('mobile');
      if (!mobile || !fide_id) {
        throw new Error('Missing mobile number or FIDE ID');
      }

      const response = await ChatRestClient.post('/v1/verify_fide_id', {
        fide_id: fide_id,
        mobile_number: mobile,
      });

      return response.data;
    },
    {
      onSuccess: data => {
        handleOnSuccess?.(data);
      },
      onError: data => {
        handleOnError?.(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
