import {FontSizes, hs, isPhone, vs} from '../../Utils/Dimensions';

import CCColors from '../../Utils/CCColors';
import {Dimensions, StyleSheet} from 'react-native';

// export const styles = StyleSheet.create({
//   signInTypo: {
//     textAlign: "right",
//     left: "50%",
//     top: 48,
//     color: CCColors?.Primary?.Default,
//     position: "absolute",
//   },
//   labelText: {
//     width: isPhone ? "100%" : "90%",
//     height: 840
//   },
//   buttonStyles: {
//     fontSize: FontSizes[9],
//     paddingVertical: vs(3),
//   },
//   buttonWrapper: {
//     backgroundColor: '#FACF47',
//     borderColor: '#262322',
//     ...(isPhone && { width: "70%", margin: "auto" }),
//   },
//   container: {
//   },
//   item: {
//     padding: vs(6),
//     backgroundColor: '#FAF6EB',
//     alignItems: 'center',
//     justifyContent: 'center',
//     flex: 1,
//     margin: 1,
//     height: Dimensions.get('window').height / 6 // approximate a square
//   },
//   itemInvisible: {
//     backgroundColor: 'transparent',
//   },
//   itemText: {
//     color: '#262322',
//     textAlign: 'center',
//     fontSize: FontSizes[6]
//   },
// });

export const styles = StyleSheet.create({
  GoalsWrapper: {
    flexDirection: isPhone ? 'column-reverse' : 'row',
    justifyContent: 'space-between',
    paddingHorizontal: isPhone ? 10 : 0,
    paddingTop: isPhone ? 10 : 0,
    backgroundColor: CCColors.Primary.lightBrown,
  },
  leftComponent: {
    flex: isPhone ? 1 : 0.79,
  },
  rightComponent: {
    flex: isPhone ? 'none' : 0.3,
  },
  header: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 16,
  },
  eachBoxWrapper: {
    // width: 'fit-content',
    // flex: isPhone
    //   ? '1 1 100%'
    //   : '1 1 50%' /* Grow and shrink equally, base width of 50% */,
    flexDirection: 'row',
    justifyContent: 'space-between',
    // alignItems: 'center' /* Center content vertically */,
    // minHeight: '50%',
    width: isPhone ? '98%' : '48%',
    backgroundColor: CCColors.White,
    borderRadius: 8,
    // flex: 0.5,
    padding: 24,
    margin: isPhone ? 0 : 16,
    ...(isPhone ? {marginVertical: 10} : {}),
  },
  iconWrapper: {
    borderRadius: '50%',
    backgroundColor: CCColors.Purple,
    width: isPhone ? 32 : 40,
    height: isPhone ? 32 : 40,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  eachBoxHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  boxEachRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 20,
  },
  boxLeftRow: {
    flex: isPhone ? 0.6 : 0.75,
  },
  boxRightRow: {
    flex: isPhone ? 0.4 : 0.25,
  },
  pointsWrapper: {
    width: '100%',
    height: 150,
    borderRadius: 8,
    backgroundColor: CCColors.Primary.Aqua,
    position: 'relative',
    // flexDirection: 'row',
    // justifyContent: 'space-around',
    // alignItems: 'center',
  },
  points: {
    // width: '100%',
    // height: 150,
    // borderRadius: 8,
    // backgroundColor: CCColors.Primary.Aqua,
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  backgroundImage: {
    position: 'absolute', // Position the image absolutely within the blue box
    right: 0, // Align to the right-most side
    width: '25%', // Cover 50% of the blue box width from the right. Adjust as needed.
    height: '100%',
    // flex: 0.3,
    // flexDirection: 'row',
    // justifyContent: 'flex-end', // This aligns child content vertically. Adjust as needed.
  },
  // rightAlignContainer: {
  //   flex: 1,
  //   justifyContent: 'center', // This will keep it centered vertically, adjust as needed.
  //   alignItems: 'flex-end', // This aligns the children to the right.
  //   paddingRight: 20, // Optional: Adds some padding on the right.
  // },
});
