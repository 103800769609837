import {GA4_TRACKING_ID} from '@/Utils/Constants';
import {clarity} from 'react-microsoft-clarity';

export const loadExternalScripts = () => {
  // Load GA4 Script
  const loadGA4 = () => {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA4_TRACKING_ID}`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window?.dataLayer || [];
      function gtag() {
        dataLayer?.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', GA4_TRACKING_ID);
    };
  };

  // Load Clarity Script
  const loadClarity = () => {
    const script = document.createElement('script');
    script.src = 'https://www.clarity.ms/tag/klpl77glsk'; // Replace with your actual Clarity tracking ID
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.clarity =
        window.clarity ||
        function () {
          (window.clarity.q = window.clarity.q || []).push(arguments);
        };
      clarity('js', new Date());
    };
  };
  clarity.init('klpl77glsk');

  loadGA4();
  // loadClarity();
};
