import React, {useEffect, useRef} from 'react';
import {Animated, Easing} from 'react-native';
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'; // Import MaterialIcons
import Spacer from '@/Atoms/Spacer';

const NotificationBox = ({styles}) => {
  // Animation refs for the text
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const translateAnim = useRef(new Animated.Value(-10)).current;

  // Animation ref for the flickering network icon
  const flickerAnim = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    // Text animation (fade and translate)
    Animated.parallel([
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
        easing: Easing.out(Easing.ease),
      }),
      Animated.timing(translateAnim, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
        easing: Easing.out(Easing.ease),
      }),
    ]).start();

    // Flicker animation (network disconnected icon flickering)
    Animated.loop(
      Animated.sequence([
        Animated.timing(flickerAnim, {
          toValue: 0,
          duration: 500,
          useNativeDriver: true,
        }),
        Animated.timing(flickerAnim, {
          toValue: 1,
          duration: 500,
          useNativeDriver: true,
        }),
      ]),
    ).start();
  }, [fadeAnim, translateAnim, flickerAnim]);

  return (
    <Box style={styles?.notificationContainer}>
      {/* Flickering network disconnected icon */}
      <Animated.View
        style={{opacity: flickerAnim}} // Flicker effect for the icon
      >
        <MaterialIcons
          name="wifi-off" // The network disconnected icon
          size={24} // Icon size
          color="red" // Icon color
          style={styles?.indicatorIcon} // Optional custom style
        />
      </Animated.View>
      <Spacer spacing={10} horizontal />
      <Animated.View
        style={{
          opacity: fadeAnim,
          transform: [{translateY: translateAnim}],
        }}>
        <CCText style={styles?.notificationText}>Lost connection!!</CCText>
      </Animated.View>
    </Box>
  );
};

export default NotificationBox;
