import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

// Define the type for the payload
export type PromoteBranchPayload = {
  move_id: string; // ID of the move to promote
  action: 'delete' | 'promote';
};

export default function usePromoteBranch() {
  return useMutation(async (payload: PromoteBranchPayload) => {
    try {
      // API endpoint and payload structure
      const response = await ChessRestClient.post(
        `learning/move/manage-branch/`,
        {
          action: payload.action, // Define the action as 'promote'
          move_id: payload.move_id, // Pass the move ID
        },
      );
      return response.data; // Return the response data
    } catch (error) {
      // Handle errors when the API call fails
      console.error('Failed to promote branch:', error);
      throw new Error('Failed to promote branch');
    }
  });
}
