import Box from '@/Atoms/Box';
import * as React from 'react';
import {
  Image,
  ImageBackground,
  Animated,
  Pressable,
  ScrollView,
} from 'react-native';
import CCText from '@/Atoms/CCText';
import {FontSizes, isPhone} from '@/Utils/Dimensions';
import CCButton from '@/Atoms/CCButton';
import CCColors from '@/Utils/CCColors';
import {RFPercentage} from 'react-native-responsive-fontsize';

// import videoOne from '@/Assets/Videos/videoOne.mp4';
// import videoTwo from '@/Assets/Videos/videoTwo.mp4';
// import videoThree from '@/Assets/Videos/videoThree.mp4';
import {useRef, useState} from 'react';
import Spacer from '@/Atoms/Spacer';

import RatingsComponent from './components/Ratings';
import Footer from './components/Footer';
import {useNavigation} from '@react-navigation/native';
import {ScreenName} from '@/navigators/StackConstants';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import ConditionalRender from '@/Atoms/ConditionalRender';
import RatingsCarousel from './components/RatingsCarousel';
import CCHomeHeader from './components/CCHomeHeader';
import BannerComponent from './components/BannerComponent';
import Caissa from './components/AI-enabled-caissa';
import BeginnersLevel from './components/GameLevel/BeginnersLevel';
import IntermediateLevel from './components/GameLevel/IntermediateLevel';
import ProLevel from './components/GameLevel/ProLevel';
import MobileView from './components/MobileView';
import Countdown from './components/Countdown';
import Chatbot from './components/chatbot';
import SafeAreaWrapper from '@/Atoms/SafeViewWrapper';

const CCHome = ({onLoginStateChange, setShowHome}) => {
  const animatedValue = useRef(new Animated.Value(0)).current;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeBox, setActiveBox] = useState(0);

  const {isMobileOrTab} = useMediaQuery();
  const navigation = useNavigation();

  const containerRef = useRef(null);
  let scrollAnimation;

  const startScrollAnimation = () => {
    const container = containerRef.current;
    if (container) {
      const distance = container.scrollWidth - container.clientWidth;
      scrollAnimation = container.animate(
        [
          {transform: `translateX(0px)`},
          {transform: `translateX(-${distance}px)`},
        ],
        {
          duration: 5000, // Adjust as needed
          iterations: Infinity,
          easing: 'linear',
        },
      );
    }
  };

  const stopScrollAnimation = () => {
    if (scrollAnimation) {
      scrollAnimation.pause();
    }
  };

  const handleMouseEnter = () => {
    stopScrollAnimation();
  };

  const handleMouseLeave = () => {
    startScrollAnimation();
  };

  // const videos = [videoOne, videoTwo, videoThree];

  const signUpHandler = () => {
    // setShowHome(false);
    navigation.navigate(ScreenName.Login);
  };

  return (
    <SafeAreaWrapper>
      <Box style={{backgroundColor: CCColors.White}} flex={1}>
        <CCHomeHeader signUpHandler={signUpHandler} />

        <BannerComponent signUpHandler={signUpHandler} />

        <Caissa signUpHandler={signUpHandler} />

        <BeginnersLevel signUpHandler={signUpHandler} />

        <IntermediateLevel signUpHandler={signUpHandler} />

        <ProLevel signUpHandler={signUpHandler} />

        <MobileView signUpHandler={signUpHandler} />

        <Countdown />

        <Chatbot />

        <ConditionalRender
          condition={isMobileOrTab}
          childrenA={<Spacer spacing={40} />}
          childrenB={<Spacer spacing={40} />}
        />

        <ConditionalRender
          condition={!isMobileOrTab}
          childrenA={<RatingsComponent />}
          childrenB={<RatingsCarousel />}
        />

        <Spacer spacing={100} />
        <Footer />
      </Box>
    </SafeAreaWrapper>
  );
};

export default CCHome;
