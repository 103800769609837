import React, {useState} from 'react';
import {
  View,
  Text,
  Image,
  Modal,
  StyleSheet,
  TouchableOpacity,
  Pressable,
} from 'react-native';
import close from '@/Assets/Images/icons/close.png';
import custom_rules_modal from '@/Assets/Images/Rules-image.png';
import custom_rules_icon from '@/Assets/Images/png/custom_rules_icon.png';
import CCText from '@/Atoms/CCText';
import Box from '@/Atoms/Box';
import {FontSizes, hs, isPhone, vs} from '@/Utils/Dimensions';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import CCColors from '@/Utils/CCColors';

export const CustomRulesModal = ({childElement}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };
  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  return (
    <View style={styles.container}>
      {childElement ? (
        <Pressable onPress={toggleModal}> {childElement}</Pressable>
      ) : (
        <Box style={styles.container} onPress={toggleModal}>
          <CCText
            fontSize={FontSizes[14]}
            style={{
              alignItems: 'center',
              // fontSize: FontSizes[16],
              // fontWeight: 'bold',
              marginRight: 10,
              color: CCColors.Green,
            }}>
            Parental Controls
          </CCText>
          <MaterialCommunityIcons
            name="shield-account-outline"
            size={20}
            color={CCColors.Green}
          />
          {/* <TouchableOpacity style={styles.iconButton} onPress={toggleModal}>
            <Image source={custom_rules_icon} style={styles.icon} />
          </TouchableOpacity> */}
        </Box>
      )}
      <Modal
        visible={modalVisible}
        animationType="fade"
        transparent={true}
        onRequestClose={closeModal}>
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <View style={{position: 'absolute', top: 16, right: 16}}>
              <TouchableOpacity onPress={closeModal}>
                <Image
                  source={close} // Replace with your image URL
                  style={{
                    backgroundColor: 'white',
                    // borderColor: '#22222240',
                    // borderWidth: 1,
                    height: 40,
                    width: 40,
                  }}
                />
              </TouchableOpacity>
            </View>

            <Box
              flexDirection="row"
              justifyContent="flex-start"
              style={{width: '100%', marginTop: 40, overflow: 'visible'}}>
              {!isPhone && (
                <View style={styles.imageContainer}>
                  <Image
                    source={custom_rules_modal} // Replace with your image URL
                    style={styles.image}
                  />
                </View>
              )}

              <View style={styles.stepsContainer}>
                <CCText style={styles.title}>Parental Controls</CCText>
                <CCText style={styles.description}>
                  Enable Parental control rules for your game learning
                </CCText>
                <CCText style={styles.description}>
                  experience with these simple steps
                </CCText>
                <View style={styles.step}>
                  <CCText style={styles.stepNumber}>1</CCText>
                  <CCText style={styles.stepText}>
                    Send whatsApp msg "Custom rules" to 9620006600
                  </CCText>
                </View>
                <View style={styles.step}>
                  <CCText style={styles.stepNumber}>2</CCText>
                  <CCText style={styles.stepText}>
                    Select the rules you want to apply to your game
                  </CCText>
                </View>
                <View style={styles.step}>
                  <CCText style={styles.stepNumber}>3</CCText>
                  <CCText style={styles.stepText}>Click enable CTA</CCText>
                </View>
              </View>
            </Box>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // position: 'relative',
    // right: -160,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 100,
    // width: '20%',
    // marginTop: 8,
    // zIndex: -5,
    // marginLeft: 'auto',
    // marginRight: 'auto'
  },
  iconButton: {
    padding: 10,
  },
  icon: {
    width: 48,
    height: 48,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    // width: '50%'
  },
  modalContent: {
    width: isPhone ? '100%' : 600,
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    alignItems: 'center',
    position: 'relative',
    height: 400,
  },
  header: {
    alignItems: 'center',
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  description: {
    textAlign: 'left',
    color: '#666',
  },
  stepsContainer: {
    width: isPhone ? '100%' : '50%',
    // marginRight: '50%'
  },
  step: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    marginTop: 10,
  },
  stepNumber: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#00b894',
    marginRight: 10,
  },
  stepText: {
    fontSize: 16,
    color: '#666',
  },
  imageContainer: {
    marginTop: 20,
    width: '50%',
    height: '115%',
    justifyContent: 'flex-end',
    overflow: 'visible',
    // alignItems: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
    overflow: 'visible',
  },
});

export default CustomRulesModal;
