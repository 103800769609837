import {useMutation} from '@tanstack/react-query';
import useChatRestClient from '@/Api/ChatRestClient';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useGetUserActivePlanApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (error: any) => void,
) {
  const mutation = useMutation<any, Error>(
    async () => {
      const response = await ChessRestClient.get(
        `subscription/v1/get_user_active_plan`,
      );

      return response;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) handleOnSuccess(data);
      },
      onError: error => {
        if (handleOnError) handleOnError(error);
      },
    },
  );

  return {
    ...mutation,
  };
}
