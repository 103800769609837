import ChessRestClient from '@/Api/ChessRestClient';
import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {GamesResponse} from './types';
import {ExecOptionsWithStringEncoding} from 'child_process';

type GamesPayload = {
  page?: number;
  game_id?: string;
  filter_date?: string;
  result?: string[];
  time_class?: string[];
  platform?: string[];
  color?: string[];
  is_favourite?: string[];
  opening_mainline?: string[];
  assessmentId?: any;
  opening?: string[];
  endgame_type?: string;
  player_name?: string;
};

export default function useGetUserGamesApi(
  handleOnSuccess?: (data) => void,
  handleOnError?: (error) => void,
): UseMutationResult<GamesResponse, Error, GamesPayload> {
  return useMutation<GamesResponse, Error, GamesPayload>(
    async (payload: GamesPayload) => {
      const postPayload: any = {};
      console.log('payload', payload);
      if (payload?.page) {
        // TODO: Commenting pagination for now, need to implement pagination on client side
        postPayload.page = payload?.page;
        console.log(payload);
      }
      if (payload?.game_id) {
        // TODO: Commenting pagination for now, need to implement pagination on client side
        postPayload.game_id = payload?.game_id;
        console.log(payload);
      }
      if (payload?.filter_date) {
        postPayload.filter_date = payload?.filter_date;
      }

      if (payload?.assessmentId) {
        postPayload.assessmentId = payload?.assessmentId;
      }

      // if (payload?.opening_mainline) {
      //   const openingArray = payload?.opening_name?.map(item =>
      //     decodeURIComponent(item?.replace(/\+/g, ' ')),
      //   );

      //   postPayload.opening_mainline = openingArray;
      // }
      if (typeof payload?.opening_mainline === 'string') {
        const openingArray = payload.opening_mainline
          .split('%2C') // Split the encoded string by the encoded comma
          .map(item => decodeURIComponent(item.replace(/\+/g, ' '))); // Decode each part and replace '+' with space

        postPayload.opening_mainline = openingArray;
      } else if (payload?.opening_mainline) {
        // const openingArray = payload.opening_mainline[0]
        //     .split('%2C') // Split the encoded string by the encoded comma
        //     .map(item => decodeURIComponent(item.replace(/\+/g, ' '))); // Decode each part and replace '+' with space

        postPayload.opening_mainline = payload?.opening_mainline;
      }

      if (payload?.result) {
        postPayload.results = payload?.result;
      }
      if (payload?.platform) {
        postPayload.platform = payload?.platform;
      }
      if (payload?.time_class) {
        postPayload.timeclass = payload?.time_class;
      }
      if (payload?.is_favourite) {
        postPayload.is_favourite = payload?.is_favourite;
      }
      if (payload?.color) {
        postPayload.color = payload?.color;
      }
      // if (payload?.opening_name) {
      //   postPayload.opening = payload?.opening_name;
      // }
      if (payload?.player_name) {
        postPayload.free_text_search = payload?.player_name;
      }

      if (payload?.opening) {
        postPayload.opening = payload?.opening;
      }
      if (payload?.endgame_type) {
        postPayload.endgame_type = payload?.endgame_type;
      }

      console.log('postPayload: ', postPayload);

      // const {page = null} = payload;
      // return ChessRestClient.post('game/get_games', postPayload);
      return ChessRestClient.post('game/get_games', postPayload);
    },
    {
      // You can add onSuccess, onError, etc. here if needed
      onSuccess: data => {
        if (handleOnSuccess) {
          handleOnSuccess(data);
        }
      },
      onError: error => {
        if (handleOnError) {
          handleOnError(error);
        }
      },
    },
  );
}
