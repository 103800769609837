const plans = {
  1: {name: 'Novice', level: 1},
  2: {name: 'Aspiring Learners - YEARLY', level: 2},
  3: {name: 'Aspiring Learners - MONTHLY', level: 2},
  4: {name: 'Aspiring Champions - YEARLY', level: 3},
  5: {name: 'Aspiring Champions - MONTHLY', level: 3},
};

export function canSubscribe(currentPlanId, targetPlanId) {
  const currentPlan = plans[currentPlanId];
  const targetPlan = plans[targetPlanId];

  if (!currentPlan || !targetPlan) {
    return false;
    // throw new Error('Invalid plan ID provided.');
  }

  return targetPlan.level >= currentPlan.level;
}

// export const getPlanExpiry(startDate:string,tenure:string){
// if(tenure?.toUpperCase()==='MONTHLY'){

// }
// else if (tenure?.toUpperCase()==='YEARLY'){

// }
// else{
//     return null
// }
// }
