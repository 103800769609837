type ConditionalRenderProps = {
  condition: boolean;
  childrenA: JSX.Element | null;
  childrenB?: JSX.Element | null;
};
function ConditionalRender(props: ConditionalRenderProps) {
  const { condition, childrenA = null, childrenB = null } = props;
  if (condition) {
    return childrenA;
  } else {
    return childrenB;
  }
}

export default ConditionalRender;
