import ChessRestClient from '@/Api/ChessRestClient';
import {useMutation} from '@tanstack/react-query';
import {GamesResponse} from './types';

interface GetGrandMastersGamesParams {
  white_player_name?: string | null;
  black_player_name?: string | null;
  color?: string | null;
  opening_name?: string | null;
  page?: number | null;
}

export default function useGetGrandMastersGames(
  handleOnSuccess?: (data: GamesResponse) => void,
  handleOnError?: (error: any) => void,
) {
  const mutation = useMutation<GamesResponse>({
    mutationFn: async ({
      white_player_name,
      black_player_name,
      opening_name,
      page = 1,
    }: GetGrandMastersGamesParams) => {
      const queryParams = new URLSearchParams();
      if (white_player_name)
        queryParams.append('white_player_name', white_player_name);
      if (black_player_name)
        queryParams.append('black_player_name', black_player_name);
      if (opening_name) queryParams.append('opening_name', opening_name);
      if (page) queryParams.append('page', page);

      console.log('inside games api', queryParams);

      const response = await ChessRestClient.get(
        `/game/get_master_games?${queryParams}`,
      );

      return response.data;
    },
    onSuccess: data => {
      if (handleOnSuccess) handleOnSuccess(data);
    },
    onError: error => {
      if (handleOnError) handleOnError(error);
    },
  });

  return mutation;
}
