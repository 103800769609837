import Environment from '../../environment';
import axios from 'axios';
import {onFulfilled} from './interceptors/request';
// import onRejected from './interceptors/response';

const ChessPaymentRestClient = axios.create({
  baseURL: Environment.PAYMENT_API_URL,
});
ChessPaymentRestClient.interceptors.request.use(onFulfilled);

export default ChessPaymentRestClient;
