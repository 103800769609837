import CCColors from '@/Utils/CCColors';
import {StyleSheet} from 'react-native';
import {FontSizes, WINDOW_HEIGHT, isPhone, vs} from '@/Utils/Dimensions';

const styles = StyleSheet.create({
  boxLeftBorder: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  boxRightBorder: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  boxTabBar: {
    backgroundColor: CCColors?.White,
    width: 'max-content',
    // margin: 10,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: CCColors.Grey.Border,
    padding: 0,
    borderRadius: 8,
  },
  boxIndicator: {
    // backgroundColor: CCColors?.Primary.Brown,
    height: '0%',
    marginBottom: 20,
    // borderRadius: 8,
  },
  boxTabBarLabel: {
    color: CCColors?.White,
    paddingBottom: 20,
    marginBottom: 0,
    paddingTop: 0,
    marginTop: 0,
    // borderWidth: StyleSheet.hairlineWidth,
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  page: {
    width: 1172,
    height: 42,
  },
  rewardsWrapper: {
    backgroundColor: CCColors.Primary.Light,
    minHeight: '100vh',
  },
  timer: {
    fontSize: 20,
    marginBottom: 20,
    marginTop: 20,
  },
  buttonContainer: {
    width: '80%',
    marginLeft: undefined,
    marginRight: undefined,
    marginTop: 20,
  },
  buttonContainerMobile: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
  },
  trainerContainer: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: '5%',
    marginRight: '5%',
    justifyContent: 'space-around',
    padding: 10,
    width: '40%',
    height: 'auto',
    backgroundColor: 'yellow',
  },
  blindFoldParent: {
    position: 'relative',
    width: '35%',
    marginLeft: '5%',
    marginTop: '2%',
    marginBottom: 'auto',
  },
  textOverlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    borderRadius: '50%',
    pointerEvents: 'none',
  },
  label: {
    marginLeft: '4%',
  },
  startButton: {
    width: 150,
    backgroundColor: '#E9C142',
    paddingVertical: 10,
    borderRadius: 20,
    alignItems: 'center',
  },
  startButtonDisabled: {
    width: 150,
    backgroundColor: '#ccc',
    paddingVertical: 10,
    borderRadius: 20,
    alignItems: 'center',
  },
  buttonText: {
    color: '#333',
    textAlign: 'center',
    fontSize: 16,
  },
  moveContainer: {
    padding: 10,
    marginLeft: '5%',
  },
  itemContainer: {
    marginBottom: 20,
    padding: 20,
    borderRadius: 5,
    backgroundColor: '#F3E7A7',
    marginLeft: '5%',
    marginRight: '5%',
  },
  moveText: {
    fontSize: 16,
    marginBottom: 3,
  },
  input: {
    borderWidth: 0.1,
    borderColor: '#F3E7A7',
    borderRadius: 5,
    marginBottom: 10,
    fontSize: 14,
    height: 48,
  },
  recordingText: {
    marginTop: 10,
    marginBottom: 5,
  },
  submitButton: {
    backgroundColor: '#E9C142',
    borderRadius: 15,
    padding: 8,
    alignItems: 'center',
  },
  submitButtonText: {
    color: '#333',
    fontSize: 16,
  },
  puzzleSolutionText: {
    padding: 16,
    textAlign: 'center',
    fontSize: 23,
    fontWeight: '500',
  },
  eachMoveWrapper: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
    margin: 0,
  },
  eachMoveWrapperSolve: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    backgroundColor: CCColors.White,
    paddingHorizontal: 5,
    paddingVertical: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  eachMoveWrapper1: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  eachMoveWrapper2: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 0,
    backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  eachMoveInsideWrapper: {
    marginTop: 15,
    flexDirection: 'row',
  },
  AnalysisRightComponentWrapper: {
    flex: 1,
    height: '92vh',
    width: '100%',
    backgroundColor: CCColors.Dashboard.DashboardBackground,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  label: {
    fontSize: 18,
  },
  count: {
    fontSize: 18,
  },
  playerScore: {
    flex: 1,
  },
  reviewSummary: {
    marginTop: vs(18),
    marginHorizontal: vs(18),
    width: '90%',
    backgroundColor: 'white',
    padding: vs(8),
    borderRadius: vs(8),
    shadowRadius: vs(2),
    shadowOpacity: 0.5,
    shadowColor: '#4D3F37',
  },
  reviewSummaryHeader: {
    letterSpacing: 2,
  },
  reviewSummaryDescription: {
    marginTop: vs(8),
    letterSpacing: 1,
    lineHeight: vs(18),
  },
  rightReviewTopHeader: {
    backgroundColor: CCColors?.White,
    padding: 10,
    paddingTop: 30,
    paddingBottom: 15,
    fontSize: FontSizes[12],
    color: CCColors?.Tabs.InactiveTabFont,
    minWidth: 100,
    textAlign: 'center',
    width: '34%',
    borderBottomColor: CCColors?.Primary.Default,
    borderBottomWidth: 3,
  },
  rightReviewLeftHeader: {
    justifyContent: 'flex-start',
    padding: 10,
    width: '30%',
    color: CCColors?.White,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  selectedMove: {
    backgroundColor: CCColors.Primary.lightYellow,
    borderColor: CCColors.Primary.Default,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  rightReviewTopHeaderSub: {
    backgroundColor: CCColors?.White,
    padding: 10,
    paddingTop: 30,
    paddingBottom: 15,
    fontSize: FontSizes[12],
    color: CCColors?.Tabs.InactiveTabFont,
    minWidth: 100,
    textAlign: 'center',
    width: '33%',
  },
  rightReviewTopHeaderSubSub: {
    fontSize: FontSizes[3],
    height: 'fit-content',
  },
  dropdown: {
    ...{width: '50%'},

    marginRight: '4%',
    borderRadius: 8,
    padding: 10,
    borderColor: CCColors.BorderBottom,
    backgroundColor: CCColors.White,
  },
  dropdownMobile: {
    ...{marginLeft: '5%'},
    marginRight: '4%',
    borderRadius: 8,
    padding: 10,
    borderColor: CCColors.BorderBottom,
    backgroundColor: CCColors.White,
  },
  tileContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 25,
    backgroundColor: '#4CAF50', // Example color, you can use CCColors.Buttons.Background
    borderRadius: 10,
    paddingHorizontal: 20,
    paddingVertical: 5,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 5,
  },
  tileText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#FFFFFF', // Example color, you can use CCColors.TextColor.Dark
  },
  boxContainer: {
    flex: 1,
    padding: 4,
    backgroundColor: '#F5F5F5', // Example color, you can adjust as needed
  },
  boxRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 5,
  },
  mainTile: {
    backgroundColor: '#4CAF50', // Main tile color
  },
  subTile: {
    backgroundColor: '#2196F3', // Sub-tile color
  },
  // tileText: {
  //   fontSize: 16,
  //   fontWeight: 'bold',
  //   color: '#FFFFFF',
  // },
  icon: {
    marginRight: 10,
  },
});

export default styles;
