import Box from '@/Atoms/Box';
import React from 'react';
import {Image} from 'react-native';
import CCText from '@/Atoms/CCText';
import CCButton from '@/Atoms/CCButton';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import caissa from '@/Assets/Images/Svgs/home_page/caissa.svg';
import square from '@/Assets/Images/Svgs/home_page/square.svg';

function Caissa({signUpHandler}) {
  const {isMobileOrTab} = useMediaQuery();
  return (
    <Box
      style={{
        flexDirection: isMobileOrTab ? 'column-reverse' : 'column',
        position: 'relative',
        backgroundColor: '#4D3F37',
        justifyContent: 'space-between',
        overflow: 'hidden',
      }}>
      <Image
        source={caissa}
        alt="Caissa illustration"
        resizeMode="contain"
        style={{
          width: isMobileOrTab ? '130vw' : '42vw',
          height: isMobileOrTab ? '62vw' : '37vw',
          ...(!isMobileOrTab && {position: 'absolute'}),
          // position: 'absolute',
          bottom: 0,
          right: 0,
        }}
      />
      <Box
        style={{
          justifyContent: 'center',
          alignItems: isMobileOrTab ? 'center' : 'flex-start',
          // paddingBottom: isMobileOrTab ? 10 : 0,
          marginVertical: isMobileOrTab ? '5%' : '12%',
          marginHorizontal: isMobileOrTab ? 20 : '8%',
          padding: 10,
        }}>
        <Box style={{flexDirection: 'row', alignItems: 'center'}}>
          <CCText
            fontWeight="thunder"
            fontSize={!isMobileOrTab ? 80 : 48}
            textAlign="center"
            color="#E17846"
            style={{marginRight: 8, fontFamily: 'thunder'}}>
            AI-enabled
          </CCText>
          <CCText
            fontWeight="thunder"
            fontSize={!isMobileOrTab ? 80 : 48}
            textAlign="center"
            color="#FAF6EB"
            style={{fontFamily: 'thunder'}}>
            Caissa
          </CCText>
        </Box>
        <Box style={{marginTop: 24, flexDirection: 'column'}}>
          <Box
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 15,
            }}>
            <Image
              resizeMethod="resize"
              source={square}
              alt="square"
              style={{
                width: !isMobileOrTab ? 16 : 20,
                height: !isMobileOrTab ? 16 : 20,
                marginRight: 8,
              }}
            />
            <CCText
              fontSize={18}
              fontWeight="regular"
              style={{lineHeight: 24, paddingLeft: 10}}
              color="#FFFFFF">
              Play chess & improve wholesomely
            </CCText>
          </Box>
          <Box
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 15,
            }}>
            <Image
              resizeMethod="resize"
              source={square}
              alt="square"
              style={{width: 16, height: 16, marginRight: 8}}
            />
            <CCText
              fontSize={18}
              fontWeight="regular"
              style={{lineHeight: 24, paddingLeft: 10}}
              color="#FFFFFF">
              Personalized game strategies.
            </CCText>
          </Box>
          <Box
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 50,
            }}>
            <Image
              resizeMethod="resize"
              source={square}
              alt="square"
              style={{width: 16, height: 16, marginRight: 8}}
            />
            <CCText
              fontSize={18}
              fontWeight="regular"
              style={{lineHeight: 24, paddingLeft: 10}}
              color="#FFFFFF">
              Start with a free Plan
            </CCText>
          </Box>
          <Box style={{paddingBottom: 20}}>
            <CCButton
              onPress={signUpHandler}
              style={{
                height: '100%',
                width: '100%',
                alignSelf: 'center',
                borderRadius: 32,
                backgroundColor: '#FACF47',
              }}>
              <CCText
                fontSize={20}
                style={{
                  width: 208,
                  height: 24,
                  fontWeight: '500',
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
                color="#262322">
                Signup to CircleChess
              </CCText>
            </CCButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Caissa;
