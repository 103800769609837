import CCColors from '@/Utils/CCColors';
import {WINDOW_WIDTH, isPhone} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignSelf: 'center',

    marginTop: 30,
    width: '50%',
  },
  mobiletimecontrol: {
    marginLeft: '-5%',
  },

  timecontrol: {
    fontSize: 32,
    fontWeight: 'medium',
    fontFamily: 'thunder',
    flexWrap: 'wrap',
  },
  right: {
    backgroundColor: `${CCColors.Green}29`,
    width: 32,
    height: 32,
    borderRadius: 4,
  },
  wrong: {
    backgroundColor: `${CCColors.WrongMoves.Background}29`,
    width: 32,
    height: 32,
    borderRadius: 4,
  },
  modalsquare: {
    position: 'absolute',
    top: '1%',
    left: '12%',
    right: '50%',
    width: '70%',
    height: '85%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  levelMobileModalCustom: {
    borderRadius: 12,
    backgroundColor: 'transparent',
    zIndex: 5,
    overflowY: 'hidden',
  },
  levelModalCustom: {
    borderRadius: 12,
    backgroundColor: 'transparent',
    zIndex: 5,
    overflowY: 'hidden',
    minWidth: WINDOW_WIDTH / 3,
    margin: 'auto',
    left: 40,
  },
});

export default styles;
