import { FontSizes, hs, isPhone, vs } from "../../Utils/Dimensions";

import CCColors from "../../Utils/CCColors";
import { Dimensions, StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  header: {
    fontSize: FontSizes[24], 
    textAlign: 'center', 
    fontWeight: 'bold', 
    color: '#806234'
  },
  sectionHeader: 
  { 
    textAlign: 'left', 
    fontWeight: 'bold', 
    paddingLeft: vs(12) 
  },
  signInTypo: {
    textAlign: "right",
    left: "50%",
    top: vs(48),
    color: CCColors?.Primary?.Default,
    position: "absolute",
  },
  labelText: {
    width: isPhone ? "100%" : "90%",
    height: vs(840)
  },
  buttonStyles: {
    fontSize: FontSizes[12],
    paddingVertical: vs(3),
    width: vs(240),
    height: vs(48),
    borderRadius: vs(32)
  },
  buttonWrapper: {
    backgroundColor: '#FACF47',
    borderColor: '#262322',
    ...(isPhone && { width: "70%", margin: "auto" }),
  },
  container: {
  },
  item: {
    padding: vs(6),
    backgroundColor: '#FAF6EB',
    alignItems: 'center',
    justifyContent: 'center',
    width: vs(120),
    margin: 1,
    height: Dimensions.get('window').height / 6 // approximate a square
  },
  itemInvisible: {
    backgroundColor: 'transparent',
  },
  itemText: {
    color: '#262322',
    textAlign: 'center',
    fontSize: FontSizes[6]
  },
  formElements: {
    width: vs(320),
    maxWidth: vs(320),
    minWidth: vs(240),
    padding: vs(8)
  }
});
