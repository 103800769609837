import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import { View } from 'react-native';
import LottieView from 'react-native-web-lottie';
import { vs, isPhone, FontSizes, hs, isPortrait } from '@/Utils/Dimensions';
import Rive from '@rive-app/react-canvas';
import { useRive,Layout, Fit, Alignment } from '@rive-app/react-canvas';
import React, { useState, useEffect, } from 'react';


function ChallengeProgressComponent(props) {
    const dumb = () => {};
    
    const { visible = false, cancelSearch={dumb}, opponent, gameDetails, featureText} = props;
    console.log("opponent in challengeprogress is:",opponent);
    const {RiveComponent, rive} = useRive({
        src: require("@/Assets/Animation/challenge_animation.riv"),
        artboard: "challenge",
        layout: new Layout({
            fit: Fit.Cover,
            // alignment: Alignment.TopCenter,
          }),
        autoplay: true,
      });

    useEffect(() => {
    if (rive) {
        console.log("Rive text was initially: ", rive.getTextRunValue("Title"));
        rive.setTextRunValue("Title", `Waiting for ${opponent}'s response!`);
        rive.setTextRunValue("Game Details", gameDetails);
        // rive.setTextRunValue("Feature Text", featureText);
        console.log("Rive text is now: ", rive.getTextRunValue("Title"));
    }
    }, [rive]);

    return (
        visible &&
        <Box flex={(isPhone || isPortrait) ? 1 : 0.45} flexDirection="column" style={{
            height: '100%', alignItems: 'center', marginTop: '2%'
        }}>
            <Box overflow='hidden' style={{width: '70vh', aspectRatio: 1, borderRadius: 8,shadowColor: "#4D3F37",
            // paddingBottom: 12,
            shadowOffset: {
              width: 0,
              height: 0,
            },
            shadowOpacity: 0.1,
            shadowRadius: 3.84,
            marginBottom: 14}} >
                <RiveComponent />
            </Box> 
            {/* <LottieView source={require('../../../../Assets/Animation/challenge_wait.json')} autoPlay loop={false} /> */}
            <CCButton style={{justifyContent: 'center', alignSelf: 'center', width: 320}} onPress={cancelSearch}><CCText style={{ textAlign: 'center' }}>Cancel Challenge</CCText></CCButton>
        </Box>
    );

}

export default ChallengeProgressComponent;