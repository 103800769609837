import {StyleSheet} from 'react-native';
import CCColors from '@/Utils/CCColors';
import {isPhone} from '@/Utils/Dimensions';

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    alignItems: 'center',
    padding: 8,
    justifyContent: 'space-between',
  },
  input: {
    backgroundColor: 'white',
    borderColor: '#22222220',
    borderWidth: 1,
    width: isPhone ? '90vw' : '24vw',
    height: 42,
    borderRadius: 8,
    placeholderTextColor: '#26232240',
    alignSelf: 'flex-start',
  },
  autoCompleteFlatList: {
    position: 'absolute',
    width: '100%',
    top: '100%',
    left: 0,
    backgroundColor: CCColors.White,
    borderColor: CCColors.BorderBottom,
    borderRadius: 10,
  },
  divider: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  dropdownItem: {
    padding: 12,
  },
});

export default styles;
