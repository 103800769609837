import ChessRestClient from '@/Api/ChessRestClient';
import {useMutation} from '@tanstack/react-query';

export default function saveSquareTrainerResult(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<
    Error,
    {
      correct_count: number;
      timer: number;
    }
  >(
    async ({correct_count, timer}) => {
      const response = await ChessRestClient.get(
        `learning/save_square_trainer_result/?correct_count=${correct_count}&&timer=${timer}`,
      );
      console.log(`-- Response -- ${response}`);
      return response.data;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) handleOnSuccess(data);
        else if (handleOnError) handleOnError(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
