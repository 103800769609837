import CCColors from '../../Utils/CCColors';
import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  eachVariation: {
    paddingHorizontal: 10,
    paddingVertical: 2,
    borderBottomWidth: 1,
    borderBottomColor: CCColors.Primary.Brown,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  // stockfishWrapper: {
  //   position: 'fixed',
  //   top: 0,
  // },
  switchBox: {
    padding: 10,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: 'transparent',
    borderCurve: 'circular',
    marginTop: 5,
    // marginBottom: 10,
  },
});
