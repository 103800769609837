import {FontSizes, isPhone, vs} from '../../Utils/Dimensions';

import CCColors from '../../Utils/CCColors';
import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  boxTabBar: {
    backgroundColor: CCColors?.White,
    width: 'max-content',
    borderRadius: 8,
  },
  iconWrapper: {
    borderRadius: '50%',
    backgroundColor: CCColors.Purple,
    width: isPhone ? 32 : 40,
    height: isPhone ? 32 : 40,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxIndicator: {
    backgroundColor: CCColors?.Primary.Brown,
    height: '100%',
  },
  boxTabBarLabel: {
    color: CCColors?.White,
  },
  rewardsWrapper: {
    backgroundColor: CCColors.Primary.Light,
    minHeight: '100vh',
  },
  leftSide: {
    ...(isPhone ? {} : {flex: 0.74}),
  },
  rightSide: {
    ...(isPhone ? {} : {flex: 0.25}),
    margin: 10,
  },

  container: {
    backgroundColor: CCColors.Primary.Light,
    paddingLeft: isPhone ? 10 : 0,
    flexDirection: isPhone ? 'column' : 'row',
    flexWrap: 'wrap',
    // flex: 1,
    // height: '100%',
  },
  cardHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  card: {
    margin: isPhone ? 0 : 8,
    ...(isPhone ? {marginVertical: 5} : {}),
    padding: 24,
    // flex: '1 1 45%',
    boxSizing: 'border-box',
    flex: '1 1 48%',
    // flexShrink: '30%',
    width: isPhone ? '98%' : '48%',
    backgroundColor: CCColors.White,
    borderRadius: 8,
    shadowColor: CCColors.Primary.Dark,
    shadowRadius: 8,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.05,
  },
  masterGameCard: {
    flex: 0.5,
  },
  masterGameAction: {
    borderRadius: 100,
  },
  masterGameContent: {
    borderColor: CCColors.BorderLight,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  masterGamePlayer: {
    flex: 0.33,
    justifyContent: 'center',
    alignItems: 'center',
  },
  masterGamePlayerPhotoContainer: {
    borderWidth: 2,
    borderColor: CCColors.Primary.Brown,
    width: 84,
    height: 84,
    borderRadius: 100,
    overflow: 'hidden',
    marginBottom: 8,
  },
  masterGamePlayerPhoto: {
    width: 80,
    height: 80,
    borderRadius: 100,
    borderWidth: 2,
    borderColor: '#fff',
  },
  masterGamePlayerTitle: {
    position: 'absolute',
    backgroundColor: CCColors.Primary.Action,
    fontWeight: '600',
    fontSize: 10,
    bottom: 0,
    alignSelf: 'center',
    paddingHorizontal: 32,
    paddingVertical: 2,
  },
  masterGamePlayerName: {
    fontSize: 14,
    lineHeight: 24,
  },
  masterGamePlayerRating: {
    color: CCColors.TextColor.Grey,
    fontSize: 12,
    lineHeight: 16,
    fontWeight: '500',
  },

  masterGameTournament: {
    color: CCColors.TextColor.Grey,
    alignSelf: 'center',
    marginTop: 20,
    fontSize: 14,
    lineHeight: 18,
  },

  masterGameBoard: {
    flex: 0.34,
  },

  reviewGameCard: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.5,
  },
  reviewGameDetails: {
    flex: 0.5,
    paddingLeft: 32,
    paddingRight: 8,
  },

  reviewGameAction: {
    // width: '100%',
    borderRadius: 100,
    // alignSelf: 'flex-end',
  },

  reviewGameActionText: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '500',
    // color: ''
  },
  reviewGameActionCoinsContainer: {
    backgroundColor: CCColors.Primary.Light,
    borderRadius: 100,
    padding: 8,
    paddingHorizontal: 16,
    // flex: 1,
    flexDirection: 'row',
    // height: 32
  },

  cardHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  cardHeadingIcon: {
    borderRadius: 100,
    // paddingVertical: 8,
    // paddingHorizontal: 10,
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    // display: 'flex',
  },
  cardHeadingText: {
    fontWeight: '500',
    fontSize: 18,
    paddingVertical: 12,
    marginLeft: 12,
    // display: 'flex',
  },

  reviewGameText: {
    lineHeight: 20,
    // width: '70%',
    marginBottom: 16,
  },
  reviewGameStat: {
    marginVertical: 16,
  },
  reviewGameStatHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  reviewGameStatIcon: {
    marginRight: 8,
  },

  reviewGameStatHeadingText: {
    fontSize: 14,
    color: CCColors.TextColor.Grey,
    lineHeight: 20,
  },

  reviewGameStatText: {
    fontSize: 16,
    lineHeight: 24,
    marginTop: 4,
  },

  signInTypo: {
    textAlign: 'right',
    left: '50%',
    top: 48,
    color: CCColors?.Primary?.Default,
    position: 'absolute',
  },
  labelText: {
    width: isPhone ? '100%' : '90%',
  },
  buttonStyles: {
    fontSize: FontSizes[9],
    paddingVertical: vs(3),
  },
  buttonWrapper: {
    ...(isPhone && {width: '70%', margin: 'auto'}),
  },
  active: {
    borderBottomWidth: 2,
    paddingBottom: 16,
    borderBottomColor: CCColors.Buttons.Background,
  },

  bannerContainer: {
    backgroundColor: CCColors.Red,
    borderRadius: 12,
    padding: 10,
    marginTop: 20,
    marginBottom: 20,
    marginLeft: !isPhone ? 10 : 0,
    width: '97%',
  },
  banner: {
    borderRadius: 12,
    borderWidth: 2,
    borderColor: CCColors.Primary.Default,
    paddingTop: 20,
    paddingRight: 20,
    paddingLeft: 20,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  rewardItem: {
    alignItems: 'flex-start',
    maxWidth: isPhone ? '100%' : '20%',
    display: 'flex',
    flexDirection: isPhone ? 'row' : 'column',
  },
  icon: {
    width: !isPhone ? 56 : 48,
    height: !isPhone ? 56 : 48,
    marginRight: isPhone ? 10 : 0,
    marginBottom: 10,
  },
  headerIcon: {
    width: 12,
    height: 18,
  },
  topIcon: {
    width: !isPhone ? 142 : 177,
    height: !isPhone ? 100 : 123,
  },
  topHeading: {
    fontSize: isPhone ? 20 : 24,
    lineHeight: isPhone ? 28 : 32,
    fontWeight: 'bold',
    color: '#FFFFFF',
    textAlign: isPhone ? 'center' : 'left',
    marginBottom: isPhone ? 0 : 10,
  },
  heading: {
    fontSize: 18,
    lineHeight: 20,
    fontWeight: 'bold',
    color: '#FFFFFF',
    textAlign: 'left',
    marginBottom: 10,
  },
  description: {
    fontSize: 14,
    lineHeight: 16,
    color: '#FFFFFF',
    textAlign: 'left',
    width: isPhone ? '65%' : '100%',
    marginBottom: 15,
  },
  topMobileDescription: {
    fontSize: 14,
    lineHeight: 16,
    color: '#FFFFFF',
    textAlign: 'center',
    width: '100%',
    marginBottom: 20,
  },
  disclaimerContainer: {
    textAlign: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'center',
  },
  disclaimerText: {
    fontSize: 12,
    color: '#FFFFFF',
  },

  cellWrapperBox: {
    padding: vs(8),
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  cellText: {
    fontSize: FontSizes[13],
  },
});
