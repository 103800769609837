import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import {FontSizes, WINDOW_HEIGHT, WINDOW_WIDTH, vs} from '@/Utils/Dimensions';
import React, {Component} from 'react';
import {View, Text, StyleSheet, Image} from 'react-native';
import LottieView from 'react-native-web-lottie';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // logging error to sentry
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      //  fallback UI
      return (
        <View style={styles.container}>
          <Image
            resizeMode="contain"
            style={{width: vs(200), height: vs(100)}}
            source={require('@/Assets/Images/Svgs/cc-logo-light.svg')}
          />
          <Box justifyContent="center" alignItems="center">
            <LottieView
              source={require('../Assets/Animation/error_boundary_animation.json')}
              style={{width: WINDOW_WIDTH / 2, height: WINDOW_HEIGHT / 2}}
              autoPlay
              loop
            />
          </Box>
          <CCText fontSize={FontSizes[20]}>
            An error occured. Please try again
          </CCText>
        </View>
      );
    }

    return this.props.children;
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 18,
    color: 'red',
  },
});

export default ErrorBoundary;
