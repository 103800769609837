import React, {useState} from 'react';
import {ScrollView, TextInput, View} from 'react-native';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import Spacer from '@/Atoms/Spacer';
import CCRadio from '@/Atoms/CCRadio/CCRadio';
import CCColors from '@/Utils/CCColors';
import {vs} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';
import {useVideoCourseImportApi} from '@/Hooks/BooksApi/useBooksApi';
import upload from '../../../Assets/Images/png/upload.png';
import CCDatePicker from '@/Atoms/CCDatePicker';
import {formatDateToYYYYMMDD} from '@/Utils/CommonUtils';

interface UploadVideoProps {
  buttonName: string;
  importSuccess: boolean;
  setImportSuccess: (status: boolean) => void;
  uploadType: string;
  onClose: () => void;
}

const UploadVideo: React.FC<UploadVideoProps> = ({
  buttonName,
  importSuccess,
  setImportSuccess,
  uploadType,
  onClose,
}) => {
  const {mutate: importVideoCourse} = useVideoCourseImportApi(
    () => {
      setImportSuccess(true);
      onClose(); // Close the modal after success
    },
    () => {
      setImportSuccess(false);
      onClose(); // Close the modal after error
    },
  );

  const [date, setDate] = useState('');
  const [title, setTitle] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [s3Path, setS3Path] = useState('');
  const [price, setPrice] = useState('');
  const [mrp, setMrp] = useState('');
  const [coach, setCoach] = useState('');
  const [tags, setTags] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [isPrivate, setIsPrivate] = useState('free');
  const [tournamentId, setTournamentId] = useState('');
  const [tournamentCategory, setTournamentCategory] = useState('');

  const handleUpload = async () => {
    const formattedDate = formatDateToYYYYMMDD(date);
    const videoData = {
      tournament_date: formattedDate,
      title,
      category_id: Number(categoryId),
      s3_path: s3Path,
      price: parseFloat(price),
      mrp: parseFloat(mrp),
      coach,
      tags: tags
        .split(',')
        .map(tag => tag.trim())
        .join(','),
      thumbnail,
      difficulty,
      is_private: isPrivate === 'private' ? 1 : 0,
      is_free: isPrivate === 'free' ? 1 : 0,
      tournament_id: tournamentId ? Number(tournamentId) : null,
      tournament_category: tournamentCategory || null,
    };
    try {
      await importVideoCourse(videoData);
    } catch (error) {
      setImportSuccess(false);
      onClose(); // Close the modal after error
    }
  };

  return (
    <ScrollView style={{padding: 20}}>
      <CCText>Webinar Date</CCText>
      <Spacer spacing={vs(5)} />
      <CCDatePicker
        onChange={text => setDate(formatDateToYYYYMMDD(text))}
        value={date}
      />
      <Spacer spacing={vs(20)} />

      <CCText>Title</CCText>
      <Spacer spacing={vs(5)} />
      <TextInput
        style={styles.input}
        value={title}
        onChangeText={text => setTitle(text)}
        placeholder={`Enter video title`}
        placeholderTextColor={CCColors.TextColor.Grey}
      />
      <Spacer spacing={vs(20)} />

      <CCText>Category ID</CCText>
      <Spacer spacing={vs(5)} />
      <TextInput
        style={styles.input}
        value={categoryId}
        onChangeText={text => setCategoryId(text)}
        placeholder={`Enter category ID`}
        placeholderTextColor={CCColors.TextColor.Grey}
      />
      <Spacer spacing={vs(20)} />

      <CCText>S3 Path</CCText>
      <Spacer spacing={vs(5)} />
      <TextInput
        style={styles.input}
        value={s3Path}
        onChangeText={text => setS3Path(text)}
        placeholder={`Enter S3 path`}
        placeholderTextColor={CCColors.TextColor.Grey}
      />
      <Spacer spacing={vs(20)} />

      <CCText>Price</CCText>
      <Spacer spacing={vs(5)} />
      <TextInput
        style={styles.input}
        value={price}
        onChangeText={text => setPrice(text)}
        placeholder={`Enter price`}
        keyboardType="numeric"
        placeholderTextColor={CCColors.TextColor.Grey}
      />
      <Spacer spacing={vs(20)} />

      <CCText>MRP</CCText>
      <Spacer spacing={vs(5)} />
      <TextInput
        style={styles.input}
        value={mrp}
        onChangeText={text => setMrp(text)}
        placeholder={`Enter MRP`}
        keyboardType="numeric"
        placeholderTextColor={CCColors.TextColor.Grey}
      />
      <Spacer spacing={vs(20)} />

      <CCText>Coach</CCText>
      <Spacer spacing={vs(5)} />
      <TextInput
        style={styles.input}
        value={coach}
        onChangeText={text => setCoach(text)}
        placeholder={`Enter coach name`}
        placeholderTextColor={CCColors.TextColor.Grey}
      />
      <Spacer spacing={vs(20)} />

      <CCText>Tags</CCText>
      <Spacer spacing={vs(5)} />
      <TextInput
        style={styles.input}
        value={tags}
        onChangeText={text => setTags(text)}
        placeholder={`Enter tags, separated by commas`}
        placeholderTextColor={CCColors.TextColor.Grey}
      />
      <Spacer spacing={vs(20)} />

      <CCText>Purchase Type</CCText>
      <Spacer spacing={vs(5)} />
      <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
        <CCRadio
          label="Free"
          value="free"
          selected={isPrivate === 'free'}
          onToggle={() => setIsPrivate('free')}
        />
        <CCRadio
          label="Paid"
          value="paid"
          selected={isPrivate === 'paid'}
          onToggle={() => setIsPrivate('paid')}
        />
        <CCRadio
          label="Private"
          value="private"
          selected={isPrivate === 'private'}
          onToggle={() => setIsPrivate('private')}
        />
      </View>
      <Spacer spacing={vs(20)} />

      <CCText>Thumbnail (optional)</CCText>
      <Spacer spacing={vs(5)} />
      <TextInput
        style={styles.input}
        value={thumbnail}
        onChangeText={text => setThumbnail(text)}
        placeholder={`Enter thumbnail URL`}
        placeholderTextColor={CCColors.TextColor.Grey}
      />
      <Spacer spacing={vs(20)} />

      <CCText>Difficulty (optional)</CCText>
      <Spacer spacing={vs(5)} />
      <TextInput
        style={styles.input}
        value={difficulty}
        onChangeText={text => setDifficulty(text)}
        placeholder={`Enter difficulty (e.g., beginner)`}
        placeholderTextColor={CCColors.TextColor.Grey}
      />
      <Spacer spacing={vs(20)} />

      <CCText>Tournament ID (optional)</CCText>
      <Spacer spacing={vs(5)} />
      <TextInput
        style={styles.input}
        value={tournamentId}
        onChangeText={text => setTournamentId(text)}
        placeholder={`Enter tournament ID`}
        keyboardType="numeric"
        placeholderTextColor={CCColors.TextColor.Grey}
      />
      <Spacer spacing={vs(20)} />

      <CCText>Tournament Category (optional)</CCText>
      <Spacer spacing={vs(5)} />
      <TextInput
        style={styles.input}
        value={tournamentCategory}
        onChangeText={text => setTournamentCategory(text)}
        placeholder={`Enter tournament category`}
        placeholderTextColor={CCColors.TextColor.Grey}
      />
      <Spacer spacing={vs(20)} />

      <View style={styles.button}>
        <CCButton
          icon={upload}
          stretched
          disabled={
            !date.length ||
            !title.length ||
            !categoryId.length ||
            !s3Path.length ||
            !price.length ||
            !mrp.length ||
            !coach.length ||
            !tags.length ||
            !isPrivate.length
          }
          titleStyle={{color: CCColors.TextColor.Dark, padding: 15}}
          onPress={handleUpload}>
          {buttonName ? buttonName : 'Upload Video'}
        </CCButton>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  input: {
    height: 48,
    borderRadius: 8,
    padding: 12,
    border: `1px solid ${CCColors.Border}`,
  },
  button: {
    width: 'calc(100% - 120px)',
    alignSelf: 'center',
  },
});

export default UploadVideo;
