import CCColors from '@/Utils/CCColors';
import {isPhone} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {},
  box: {
    width: !isPhone ? 122 : 110,
    height: !isPhone ? 122 : 100,
    borderWidth: 2,
    borderRadius: 12,
    borderColor: CCColors.Primary.Dark,
    padding: !isPhone ? 15 : 5,
    backgroundColor: CCColors.SignUp.BoxBackground,
    shadowColor: '#000',
    shadowOffset: {
      width: 5, // horizontal shadow
      height: 5, // vertical shadow
    },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 10, // Android shadow
    overflow: 'hidden',
    marginRight: 10,
  },
  video: {
    width: '100%',
    height: '100%',
  },
  commonBackground: {
    backgroundColor: CCColors.Primary.lightBrown,
    padding: 20,
    width: 270,
    height: 270,
    borderRadius: 8,
    marginTop: 10,
    marginRight: '2%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
  },
  squareBackgroundBox: {
    backgroundColor: CCColors.Primary.lightBrown,
    padding: !isPhone ? 20 : 5,
    width: !isPhone ? 200 : 110,
    height: 150,
    borderRadius: 8,
    marginTop: 10,
  },
  bottomBox: {
    backgroundColor: CCColors.Primary.lightBrown,
    padding: 20,
    borderRadius: 8,
    marginTop: 10,
  },
  squareBackgroundBottomRight: {
    backgroundColor: CCColors.Primary.lightBrown,
    padding: 20,
    height: 150,
    borderRadius: 8,
    marginTop: 10,
  },
  learnPlayDesc: {
    fontWeight: '400',
    lineHeight: 28,
    justifyContent: 'center',
    marginTop: isPhone ? 10 : undefined,
    marginLeft: '4vw',
    alignItems: 'center',
    textAlign: 'left',
  },
  descContainer: {
    height: isPhone ? '50vw' : 221,
    maxWidth: isPhone ? '90vw' : '80vw',
    backgroundColor: CCColors.Primary.lightBrown,
    marginLeft: !isPhone ? '10%' : '5%',
    marginRight: !isPhone ? '10%' : '5%',
    borderRadius: 8,
    width: !isPhone ? '95%' : '90%',
    // padding: !isPhone ? 20 : 0,
  },
  rightContainer: {
    width: isPhone ? '68vw' : '41vh',
    height: 72,
    backgroundColor: CCColors.White,
    // paddingHorizontal: !isPhone ? 20 : 10,
    // paddingVertical: 10,
    borderRadius: 8,
    marginTop: isPhone ? 0 : 0,
    // marginBottom: isPhone ? 20 : undefined,
    alignSelf: 'center',
    justifyContent: 'center',
  },
});

export default styles;
