import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import {FontSizes, WINDOW_HEIGHT, isPhone, vs} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';

const customStyles = StyleSheet.create({
  eachMoveWrapper: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
    // flexDirection: 'row',
    // flex: 1,
  },
  eachMoveWrapperSolve: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    backgroundColor: CCColors.White,
    paddingHorizontal: 5,
    paddingVertical: 15,
    paddingBottom: 0,
    paddingTop: 0,

    justifyContent: 'center',
    // flexDirection: 'row',
    // flex: 1,
  },
  eachMoveWrapper1: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,

    justifyContent: 'center',
    // flexDirection: 'row',
    // flex: 1,
  },
  eachMoveWrapper2: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 0,
    backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,

    justifyContent: 'center',
    // flexDirection: 'row',
    // flex: 1,
  },
  eachMoveInsideWrapper: {
    // margin: 15,
    marginTop: 15,
    flexDirection: 'row',
    // marginTop:15,
    // marginTop:15,
    // marginTop:15,
  },
  AnalysisRightComponentWrapper: {
    // borderColor: CCColors?.Primary?.Default,
    // borderWidth: 1,
    // borderRadius: 4,
    flex: 1,

    // maxHeight: '50%',
    // maxHeight: WINDOW_HEIGHT / 1.6,
    height: '92vh',
    width: '100%',
    // justifyContent: 'center',
    // alignItems: 'center',
    backgroundColor: CCColors.Dashboard.DashboardBackground,
  },
  AnalysisRightComponentWrapperMobile: {
    height: '100%',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  label: {
    fontSize: 18,
  },
  count: {
    fontSize: 18,
  },
  playerScore: {
    // flexDirection: 'column',
    // width: '100%',
    // height: '100%',
    flex: 1,
    // overflow: 'auto',
  },
  reviewSummary: {
    marginTop: vs(18),
    marginHorizontal: vs(18),
    width: '90%',
    backgroundColor: 'white',
    padding: vs(8),
    borderRadius: vs(8),
    shadowRadius: vs(2),
    shadowOpacity: 0.5,
    shadowColor: '#4D3F37',
  },
  reviewSummaryHeader: {
    letterSpacing: 2,
  },
  reviewSummaryDescription: {
    marginTop: vs(8),
    letterSpacing: 1,
    lineHeight: vs(18),
  },
  playerScoreEach: {
    // flex: 0.2,
    // borderWidth: StyleSheet.hairlineWidth,
  },
  rightReviewTopHeader: {
    backgroundColor: CCColors?.White,
    padding: 10,
    paddingTop: 30,
    paddingBottom: 15,
    fontSize: FontSizes[12],
    color: CCColors?.Tabs.InactiveTabFont,
    minWidth: 100,
    textAlign: 'center',

    borderBottomColor: CCColors?.Primary.Default,
    borderBottomWidth: 3,
  },
  rightReviewTopHeaderMobile: {
    paddingVertical: 5,
  },
  rightReviewLeftHeader: {
    justifyContent: 'flex-start',
    // backgroundColor: CCColors?.Primary?.Default,
    padding: 10,
    width: '30%',
    color: CCColors?.White,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  selectedMove: {
    backgroundColor: CCColors.Primary.lightYellow,
    borderColor: CCColors.Primary.Default,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    // backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  rightReviewTopHeaderSub: {
    backgroundColor: CCColors?.White,
    padding: 10,
    paddingTop: 30,
    paddingBottom: 15,
    fontSize: FontSizes[12],
    color: CCColors?.Tabs.InactiveTabFont,
    minWidth: 100,
    textAlign: 'center',
    // width: '33%',
  },
  rightReviewTopHeaderSubMobile: {
    paddingTop: 5,
  },
  rightReviewTopHeaderSubSub: {
    fontSize: FontSizes[3],
    height: 'fit-content',
  },
});
export default customStyles;
