// import ChessRestClient from "../Api/ChessRestClient";
// import { getLocalStorage } from "../Utils/CommonUtils";
import useChatRestClient from '@/Api/ChatRestClient';
import {getLocalStorage, getUserDetails} from '@/Utils/CommonUtils';
import {useMutation} from '@tanstack/react-query';

type registeredUserProps = {
  mobile_number: string;
};
export default function useFetchRegisteredPlayers() {
  const ChatRestClient = useChatRestClient();
  const mutation = useMutation(async () => {
    let userDetails = await getUserDetails();
    userDetails = JSON.parse(userDetails);
    const mobile = userDetails?.mobile_number;
    if (!mobile) {
      return false;
    }
    console.log(userDetails, 'userDetails');
    // TODO: remove the comment
    const response = await ChatRestClient.post('/v1/fetch_registrations', {
      // mobile_number: mobile,
      mobile_number: mobile,
      // mobile_number: "9900614285",
    });
    return response.data;
  });

  return {
    ...mutation,
    isLoading: mutation.isLoading,
  };
}
