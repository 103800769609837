import React, {useState} from 'react';
import SkillsBox from './Components/SkillsBox';
import Box from '@/Atoms/Box';
import {View} from 'react-native';
import {styles} from './styles';
import Spacer from '@/Atoms/Spacer';
import {Assessment, Course, Skill} from './types';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import CCText from '@/Atoms/CCText';

const chessPsychologyKeys = [
  'SACRIFICING_TENDENCY',
  'DECISIVENESS',
  'PERFORMANCE_UNDER_TIME_PRESSURE',
  'STRENGTH_TO_RECOVER',
  'TIME_MANAGEMENT',
  'STRENGTH_TO_RECOVER(Tilt)',
];

const chessSkillsKeys = [
  'SIMPLE_CALCULATION',
  'ENDGAME_PERFORMANCE',
  'CONVERSION',
  'WHITE_OPENING_PERFORMANCE',
  'BLACK_OPENING_PERFORMANCE',
  'CENTER_CONTROL',
  'SPACE_ADVANTAGE',
];

function classifyData(data: Record<string, Assessment>): Course[] {
  const chessPsychology: Skill[] = [];
  const chessSkills: Skill[] = [];

  let skillId = 1;

  Object.entries(data).forEach(([key, value]) => {
    const skill: Skill = {
      id: skillId++,
      name: key.replace(/_/g, ' ').toLowerCase(),
      skillDetails: {
        rating: Number(value.assessment_rating)?.toFixed(1) * 100, // Converting to percentage for consistency
        infoString: value?.assessment_info,
        defaultLevel: value?.assessment_level,
      },
    };

    if (chessPsychologyKeys.includes(key)) {
      chessPsychology.push(skill);
    } else if (chessSkillsKeys.includes(key)) {
      chessSkills.push(skill);
    }
  });

  return [
    {
      id: 1,
      title: 'Chess Psychology',
      skills: chessPsychology,
    },
    {
      id: 2,
      title: 'Chess Skills',
      skills: chessSkills,
    },
  ];
}

function OverallAssessmentBox({
  overallAssessmentData,
}: {
  overallAssessmentData: any;
}) {
  const {isMobileOrTab} = useMediaQuery();

  console.log(overallAssessmentData, 'overallAssessmentData');
  const [activeTab, setActiveTab] = useState<'Psychology' | 'Skills'>(
    'Psychology',
  );
  const classifiedData = classifyData(overallAssessmentData);

  const renderSkills = () => {
    const activeSkills =
      activeTab === 'Psychology'
        ? classifiedData.find(group => group.title === 'Chess Psychology')
            ?.skills
        : classifiedData.find(group => group.title === 'Chess Skills')?.skills;

    return activeSkills ? (
      <SkillsBox skillGrpDetails={{title: activeTab, skills: activeSkills}} />
    ) : null;
  };

  return (
    <Box>
      <Spacer spacing={24} />
      {isMobileOrTab ? (
        <>
          <View style={styles.toggleWrapper}>
            <View
              style={[
                styles.toggleButtonWrapper,
                activeTab === 'Psychology' && styles.activeToggle,
              ]}>
              <CCText
                onPress={() => setActiveTab('Psychology')}
                style={[
                  styles.toggleButton,
                  activeTab === 'Psychology' && styles.activeToggleText,
                ]}>
                Chess Psychology
              </CCText>
            </View>
            <View
              style={[
                styles.toggleButtonWrapper,
                activeTab === 'Skills' && styles.activeToggle,
              ]}>
              <CCText
                onPress={() => setActiveTab('Skills')}
                style={[
                  styles.toggleButton,
                  activeTab === 'Skills' && styles.activeToggleText,
                ]}>
                Chess Skills
              </CCText>
            </View>
          </View>
          <Box style={styles.skillBoxWrapper}>{renderSkills()}</Box>
        </>
      ) : (
        <Box type="row-top-between" style={styles.skillBoxWrapper}>
          {classifiedData.map(eachSkillGrp => (
            <SkillsBox key={eachSkillGrp.id} skillGrpDetails={eachSkillGrp} />
          ))}
        </Box>
      )}
    </Box>
  );
}

export default OverallAssessmentBox;
