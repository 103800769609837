import CCColors from '@/Utils/CCColors';
import React, {useEffect, useRef, useState} from 'react';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import LottieView from 'react-native-web-lottie';

import {Pressable, View} from 'react-native';
import CCText from '@/Atoms/CCText';

// const lottieRef = useRef<AnimatedLottieView|null>(null);

// useEffect(() => {
//   if (lottieRef.current) {
//     setTimeout(() => {
//       lottieRef.current?.reset();
//       lottieRef.current?.play();
//     }, 100);
//   }
// }, [lottieRef.current]);

// ...

// <LottieView
//     ref={lottieRef}
//     loop={true}
//     speed={1}
//     style={{height: hp('35%'), alignSelf: 'center'}}
//     source={require('./../../../assets/lottie/noPlacesInProfile.json')}
//     renderMode={"SOFTWARE"}
// />
function LottieWrapper({fileurl, startAnimation = false}) {
  // const [moveOver, setMoveOver] = useState(startAnimation);
  const lottieRef = useRef<null>(null);

  useEffect(() => {
    // setMoveOver(startAnimation);
    if (startAnimation) {
      lottieRef.current?.play();
    } else {
      lottieRef.current?.reset();
    }
  }, [startAnimation]);

  // const handleHoverIn = () => {
  //   console.log('Hover in');
  //   setMoveOver(true);
  // };

  // const handleHoverOut = () => {
  //   console.log('Hover out');
  //   setMoveOver(false);
  // };

  return (
    <View
      style={{
        width: 45,
        height: 45,
        overflow: 'hidden',
      }}>
      {/* {moveOver ? <CCText>a</CCText> : <CCText>b</CCText>} */}
      {/* {moveOver ? ( */}
      <LottieView
        ref={lottieRef}
        source={fileurl}
        height={45}
        width={45}
        // autoPlay
        loop={true}
      />
      {/* ) : (
        <LottieView source={fileurl} height={30} width={30} />
      )} */}
    </View>
  );
}

export const GAMEPLAY_STRIP_CONTENT = [
  {
    id: 1,
    header: 'Square Trainer',
    screenName: 'VisualisationTrainer',
    screenProps: {
      selected: 'square',
    },
    desc: 'Guess the square',
    icon: startAnimation => (
      <LottieWrapper
        fileurl={require('../../Assets/Animation/Square_Trainer/Square_training.json')}
        startAnimation={startAnimation}
      />
    ),
  },
  {
    id: 2,
    header: 'Calculation Trainer',
    screenName: 'VisualisationTrainer',
    screenProps: {
      selected: 'calculation',
    },
    desc: 'Guess next move',
    icon: startAnimation => (
      <LottieWrapper
        fileurl={require('../../Assets/Animation/Calculation_Trainer/Calculation_Trainer.json')}
        startAnimation={startAnimation}
      />
    ),
  },
  {
    id: 3,
    header: 'Intuition Trainer',
    screenName: 'VisualisationTrainer',
    screenProps: {
      selected: 'intuition',
    },
    desc: 'Perform against GM',
    icon: startAnimation => (
      <LottieWrapper
        fileurl={require('../../Assets/Animation/Intuition_Trainer/Intuition_Trainer.json')}
        startAnimation={startAnimation}
      />
    ),
  },
  // {
  //   id: 3,
  //   header: 'Solve Puzzles',
  //   screenName: 'Positions',
  //   screenProps: {},
  //   desc: 'From position master',
  //   icon: startAnimation => (
  //     <LottieWrapper
  //       fileurl={require('../../Assets/Animation/Bullet.json')}
  //       startAnimation={startAnimation}
  //     />
  //   ),
  //   // icon: () => (
  //   //   <MaterialCommunityIcon
  //   //     name={'puzzle'}
  //   //     size={25}
  //   //     color={CCColors.TextColor.Primary}
  //   //   />
  //   // ),
  // },
  // {
  //   id: 4,
  //   header: 'Visual Trainer',
  //   screenName: 'VisualisationTrainer',
  //   screenProps: {},
  //   desc: 'Repeat your last game',
  //   disabled: true,
  // },
  // {
  //   id: 5,
  //   header: 'Calculation Trainer',
  //   screenName: 'VisualisationTrainer',
  //   screenProps: {
  //     selected: 'calculation',
  //   },
  //   // screenProps: {selected:},
  //   desc: 'Guess next best move',
  //   icon: startAnimation => (
  //     <LottieWrapper
  //       fileurl={require('../../Assets/Animation/Calculation_Trainer/Calculation_Trainer.json')}
  //       startAnimation={startAnimation}
  //     />
  //   ),
  // },
];
