// import ChatRestClient from '../../Api/ChatRestClient';
import ChessRestClient from '@/Api/ChessRestClient';
import {useMutation} from '@tanstack/react-query';
import {leaderboardResponse} from './types';
import {getLocalStorage} from '@/Utils/CommonUtils';

export default function useGetLeaderBoards(
  handleOnSuccess?: (data) => void,
  handleOnError?: (data) => void,
) {
  const mutation = useMutation<
    leaderboardResponse,
    Error,
    {
      ranking_criteria?: string;
      duration?: string;
    }
  >(
    // Error
    // {
    //   search?: string;
    //   tournamentKey?: number | string;
    //   filters?: {[key: string]: string[]};
    // }
    async ({ranking_criteria, duration}) => {
      const response = await ChessRestClient.post(
        '/dashboard/v1/get_leaderboard',
        {
          ranking_criteria: ranking_criteria,
          duration: duration,
          leaderboardt_ype: 'global',
        },
      );

      return response.data;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) handleOnSuccess(data);
        else if (handleOnError) handleOnError(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
