import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useGetGameWithTag() {
    return useMutation(
      async ({
        tag_name,
        game_stage,
      }: {
        tag_name: string;
        game_stage: string;
        
      }) => {
        const response = await ChessRestClient.post('learning/get_game_with_tag/', {
          tag_name: tag_name,
          game_stage: game_stage,
          
        });
        console.log("get_game_with_tag api respoinse is :", response);

        return response.data;
      }
    );
  }