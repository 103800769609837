import ChessRestClient from '@/Api/ChessRestClient';
import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {BookPuzzlePayload, BooksResponse} from './types';
import {getLocalStorage} from '@/Utils/CommonUtils';

export default function useBooksApi(): UseMutationResult<
  any,
  Error,
  BookPuzzlePayload
> {
  return useMutation<any, Error, BookPuzzlePayload>(
    async (payload: BookPuzzlePayload) => {
      const token = await getLocalStorage('access_token');
      const options = {
        params: payload,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      };
      return ChessRestClient.get('v1/play_against_computer', options);
    },
    {
      // You can add onSuccess, onError, etc. here if needed
    },
  );
}
