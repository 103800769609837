import Box from '@/Atoms/Box';
import React from 'react';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import { Image, ImageBackground, ScrollView } from 'react-native';
import styles from '../../styles';
import ConditionalRender from '@/Atoms/ConditionalRender';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import chessLargeLeft from '@/Assets/Images/Svgs/home_page/chessboard_left_part_large.svg';
import chessLargeRight from '@/Assets/Images/Svgs/home_page/chessboard_right_part_large.svg';
import chat from '@/Assets/Images/Svgs/home_page/chat.svg';
import chessEvents from '@/Assets/Images/Svgs/home_page/chess_events.svg';
import learning from '@/Assets/Images/Svgs/home_page/learning.svg';
import trophy from '@/Assets/Images/Svgs/home_page/organisation_trophy.svg';

const Chatbot = () => {
    const {isMobileOrTab} = useMediaQuery();

    const featuresComponent = (
        <Box
          style={{
            flexDirection: !isMobileOrTab ? 'row' : 'column',
            marginLeft: isMobileOrTab ? '20%' : '10%',
            marginRight: isMobileOrTab ? '20%' : '10%',
            marginBottom: 80,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
          }}>
          <Box style={{flexDirection: 'row'}}>
            <Box gap={10} alignItems="center" style={{width: 200, height: 250}}>
              <Image
                resizeMethod="resize"
                source={chat}
                style={{width: 140, height: 150}}
              />
              <CCText
                textAlign="center"
                fontSize={22}
                fontWeight="regular"
                style={{width: '60%'}}>
                Whatsapp Chatbot
              </CCText>
            </Box>
            <Box gap={10} alignItems="center" style={{width: 220, height: 250}}>
              <Image
                resizeMethod="resize"
                source={chessEvents}
                style={{width: 140, height: 150}}
              />
              <CCText
                textAlign="center"
                fontSize={22}
                fontWeight="regular"
                style={{width: '60%'}}>
                Chess Events
              </CCText>
            </Box>
          </Box>
          <Box style={{flexDirection: 'row'}}>
            <Box gap={10} alignItems="center" style={{width: 220, height: 250}}>
              <Image
                resizeMethod="resize"
                source={trophy}
                style={{width: 140, height: 150}}
              />
              <CCText
                textAlign="center"
                fontSize={22}
                fontWeight="regular"
                style={{width: '60%'}}>
                Tournament Organisation
              </CCText>
            </Box>
            <Box gap={10} alignItems="center" style={{width: 220, height: 250}}>
              <Image
                resizeMethod="resize"
                source={learning}
                style={{width: 140, height: 150}}
              />
              <CCText
                textAlign="center"
                fontSize={22}
                fontWeight="regular"
                style={{width: '60%'}}>
                Personalised Learning
              </CCText>
            </Box>
          </Box>
        </Box>
      );

  return (
    <>
        <ConditionalRender
          condition={!isMobileOrTab}
          childrenA={
            <Box
              gap={5}
              flexDirection="row"
              marginLeft="10%"
              marginRight="10%"
              style={{
                width: 1280,
                height: 280,
                alignSelf: 'center',
                marginTop: 100,
                justifyContent: 'center',
              }}>
              <Box flexDirection="row">
                <Image
                  resizeMethod="resize"
                  source={chessLargeLeft}
                  style={{ width: 300, height: 90 }}
                />
              </Box>
              <CCText
                fontSize={120}
                textAlign="center"
                style={{
                  width: 587,
                  height: 220,
                  fontFamily: 'thunder',
                  fontWeight: '500',
                  paddingRight: 20,
                  paddingLeft: 20,
                  paddingTop: 10,
                  lineHeight: 100
                }}>
                We do all this and more.
              </CCText>
              <Box flexDirection="row">
                <Image
                  resizeMethod="resize"
                  source={chessLargeRight}
                  style={{ width: 300, height: 90 }}
                />
              </Box>
            </Box>
          }
          childrenB={
            <Box
              gap={5}
              flexDirection="row"
              marginLeft="10%"
              marginRight="10%"
              style={{
                width: '100%',
                height: 280,
                alignSelf: 'center',
                marginTop: 100,
                justifyContent: 'center',
              }}>
              <Box flexDirection="row">
                <Image
                  resizeMethod="resize"
                  source={chessLargeLeft}
                  style={{width: 40, height: 30}}
                />
              </Box>
              <CCText
                fontSize={80}
                textAlign="center"
                style={{
                  fontFamily: 'thunder',
                  fontWeight: '500',
                  paddingRight: 10,
                  paddingLeft: 10,
                  lineHeight: 100
                }}>
                We do all this and more.
              </CCText>
              <Box flexDirection="row">
                <Image
                  resizeMethod="resize"
                  source={chessLargeRight}
                  style={{width: 40, height: 30}}
                />
              </Box>
            </Box>
          }
        />

        <ConditionalRender
          condition={!isMobileOrTab}
          childrenA={<>{featuresComponent}</>}
          childrenB={<>{featuresComponent}</>}
        />
    </>
  )
}

export default Chatbot