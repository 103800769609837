import React from 'react';
import CCText from '@/Atoms/CCText';
import {FontSizes} from '@/Utils/Dimensions';
import {Image, ImageBackground, View} from 'react-native';
import {styles} from './styles';
import Box from '@/Atoms/Box';
import Parental_control_rules from '../../../../Assets/Images/Svgs/Parental_control_rules.svg';
import fully_private_games from '../../../../Assets/Images/Svgs/fully_private_games.svg';
import gameplay_assistant from '../../../../Assets/Images/Svgs/gameplay_assistant.svg';
import Play_from_any_position from '../../../../Assets/Images/Svgs/Play_from_any_position.svg';
import reflects_your_rating from '../../../../Assets/Images/Svgs/gameplay_assistant.svg';
import {RFPercentage} from 'react-native-responsive-fontsize';

import {LinearGradient} from 'expo-linear-gradient';

function IdleStateGameBanner({visible, StartColor, StopColor}) {
  const rules = [
    {
      logo: Parental_control_rules,
      title: 'Parental control rules',
      description: 'Control your child’s gameplay on the platform',
    },
    {
      logo: gameplay_assistant,
      title: 'Gameplay Assistant',
      description: 'Get real-time feedback after the wrong moves',
    },
    {
      logo: fully_private_games,
      title: 'Fully private games',
      description: 'Your gameplay data is yours and yours only',
    },
    {
      logo: Play_from_any_position,
      title: 'Play from any position',
      description: 'Practice specific positions to improve gameplay',
    },
    {
      logo: reflects_your_rating,
      title: 'Reflects your FIDE rating',
      description: 'Our CC rating is based on FIDE rating calculations',
    },
  ];
  return (
    visible && (
      <LinearGradient
        // eslint-disable-next-line react-native/no-inline-styles
        style={{
          // width:'75vh',
          height: '75vh',
          marginTop: '4%',
          borderRadius: 16,
          position: 'relative',
          overflow: 'hidden',
          margin: '3%'
        }}
        colors={[StartColor, StopColor]}
        LinearGradient={{
          start: {y: 0},
          end: {y: 1},
        }}>
        <ImageBackground
          resizeMode="cover"
          source={require('../../../../Assets/Images/Svgs/circle_chess_back_half.png')}
          style={styles.backgroundImg}
        />
        <View style={styles.mainView}>
          <View style={styles.contentContainer}>
            <Box
              flex={100}
              style={styles.mainHeading}
              flexDirection="column"
              gap={'5%'}>
              <CCText
                color={'#FFFFFF'}
                fontSize={RFPercentage(2)}
                fontWeight="500">
                Why Play
              </CCText>
              <CCText
                fontSize={RFPercentage(2)}
                color={'#FFFFFF'}
                fontWeight="500">
                On CircleChess?
              </CCText>
            </Box>

            <View style={styles.tilesContainer}>
              <Image
                resizeMode="contain"
                source={require('../../../../Assets/Images/Svgs/knights_elephant.png')}
                style={styles.knight_elephants_logo}
              />
              {rules.map(({logo, title, description}, index) => (
                <Box
                  key={index}
                  flex={100}
                  flexDirection="row"
                  alignItems="center"
                  gap={20}>
                  <Image
                    source={logo}
                    resizeMethod="resize"
                    style={[styles.tileLogo, {backgroundColor: StartColor}]}
                  />
                  <Box
                    flex={100}
                    style={{overflow: 'visible'}}
                    flexDirection="column"
                    gap={2}>
                    <CCText
                      fontSize={RFPercentage(1)}
                      style={styles.title}
                      fontWeight="semibold">
                      {title}
                    </CCText>
                    <CCText
                      fontSize={RFPercentage(1)}
                      style={styles.tileDescription}>
                      {description}
                    </CCText>
                  </Box>
                </Box>
              ))}
            </View>
          </View>
        </View>
      </LinearGradient>
    )
  );
}
export default IdleStateGameBanner;
