import React, {useRef, useEffect, useState} from 'react';
import {Animated, View, StyleSheet, LayoutChangeEvent} from 'react-native';

interface BlinkWrapperProps {
  children: React.ReactNode;
  isLoading: boolean;
}

const BlinkWrapper: React.FC<BlinkWrapperProps> = ({children, isLoading}) => {
  const fadeAnim = useRef(new Animated.Value(1)).current; // Initial opacity set to 1 (fully visible)
  const [size, setSize] = useState<{width: number; height: number}>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    let blinkAnimation: Animated.CompositeAnimation | null = null;

    if (isLoading) {
      // Start blinking animation (looped)
      blinkAnimation = Animated.loop(
        Animated.sequence([
          Animated.timing(fadeAnim, {
            toValue: 0, // Fade out to opacity 0
            duration: 500, // Duration of the fade out
            useNativeDriver: true,
          }),
          Animated.timing(fadeAnim, {
            toValue: 1, // Fade in to opacity 1
            duration: 500, // Duration of the fade in
            useNativeDriver: true,
          }),
        ]),
      );
      blinkAnimation.start();
    } else {
      // Reset to fully visible if blinking is disabled
      fadeAnim.setValue(1);
    }

    // Clean up animation when component unmounts or shouldBlink changes
    return () => {
      if (blinkAnimation) {
        blinkAnimation.stop();
      }
    };
  }, [isLoading, fadeAnim]);

  const onLayout = (event: LayoutChangeEvent) => {
    const {width, height} = event.nativeEvent.layout;
    setSize({width, height});
  };

  return (
    <Animated.View
      style={[
        styles.container,
        {opacity: fadeAnim},
        // ,{opacity: fadeAnim, width: size.width, height: size.height},
      ]}>
      <View onLayout={onLayout}>{children}</View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    // overflow: 'hidden', // To prevent any visual glitch if needed
  },
});

export default BlinkWrapper;
