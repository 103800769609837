export const joinGameHandler = (data, me) => {
    console.info('Game started = ', JSON.stringify(data));
    console.info('Table ID = ', data.tableId.toString());
    console.info('Player List = ', JSON.stringify(data.playerList));
};

export const moveHandler = (data, me) => {
    console.log('Invoked move handler = ', data);
}

export const makeMoveHandler = (data, me) => {
    console.log('Make move ... ', data);
}