import { FontSizes, hs } from "../../Utils/Dimensions";

import SPColors from "../../Utils/CCColors";
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  parent: {
    flexDirection: "row",
  },

  containerStyle: {
    width: hs(18),
    height: hs(18),
    borderRadius: 18,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 2,
    borderColor: SPColors.Primary.Default,
  },

  dotStyle: {
    width: hs(9),
    height: hs(9),
    borderRadius: 10,
    backgroundColor: SPColors.Primary.Default,
  },

  labelStyle: {
    fontSize: FontSizes[13],
    marginLeft: hs(10),
  },
});

export default styles;
