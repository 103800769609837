import React, {useState} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Modal,
  Image,
  ScrollView,
  TouchableWithoutFeedback,
} from 'react-native';
import custom_rules_icon from '@/Assets/Images/png/custom_rules_icon.png';
import CCText from '@/Atoms/CCText';
import styles from './styles';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import CustomRulesModal from '../CustomRulesModal';
import {FontSizes, hs, isPhone, vs} from '@/Utils/Dimensions';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import CCColors from '@/Utils/CCColors';

interface GameRulesProps {
  rules?: string[];
}

const GameRules: React.FC<GameRulesProps> = ({rules = []}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const {isMobileOrTab} = useMediaQuery();
  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const rulesDescriptions: Record<string, string> = {
    castling_rule:
      'Castle within the first 15 moves else you lose the game by auto resignation.',
    thinking_time_rule:
      'Forced thinking time to avoid quick blunders, enabled from the 5th move onwards.',
    rooks_connected_rule:
      'Keep the rooks connected after the 20th move else auto-resign.',
    games_reviewed_rule:
      'Review all the games played on the last day to enable gameplay.',
    touch_piece_rule:
      'Enforces touch and move on clicking or dragging a piece.',
  };

  const updatedRules = rules.map(rule => rulesDescriptions[rule] || rule);
  const top = rules.length > 1 ? 100 + (rules.length - 1) * 50 : 100;
  const top_string = `-${top}%`;

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.iconButton} onPress={toggleModal}>
        <CCText
          fontSize={FontSizes[14]}
          style={{
            alignItems: 'center',
            // fontSize: FontSizes[16],
            // fontWeight: 'bold',
            marginRight: 10,
            color: CCColors.Green,
          }}>
          {isMobileOrTab ? ` Parental\nControls` : ' Parental Controls'}
        </CCText>
        <MaterialCommunityIcons
          name="shield-account-outline"
          size={20}
          color={CCColors.Green}
        />
      </TouchableOpacity>
      {/* <CustomRulesModal /> */}
      {modalVisible && !isMobileOrTab && (
        <TouchableWithoutFeedback onPress={toggleModal}>
          <View style={[styles.modalOverlay, {top: top_string}]}>
            <View style={styles.modalContent}>
              <CCText style={styles.title}>Parental Controls</CCText>
              <CCText style={styles.subtitle}>
                If you break these rules you will lose the game instantly
              </CCText>
              <ScrollView>
                <View style={styles.rulesContainer}>
                  {rules.length > 0 ? (
                    updatedRules.map((rule, index) => (
                      <View key={index} style={styles.ruleItem}>
                        <Image
                          source={custom_rules_icon}
                          style={styles.ruleIcon}
                        />
                        <CCText style={styles.ruleText}>{rule}</CCText>
                      </View>
                    ))
                  ) : (
                    <Text style={styles.noRulesText}>
                      Parental controls not enabled
                    </Text>
                  )}
                </View>
              </ScrollView>
            </View>
          </View>
        </TouchableWithoutFeedback>
      )}
      {modalVisible && isMobileOrTab && (
        <TouchableWithoutFeedback onPress={toggleModal}>
          <Modal
            transparent
            visible={modalVisible}
            animationType="fade"
            onRequestClose={toggleModal}>
            <TouchableOpacity style={styles.overlay} onPress={toggleModal}>
              <View style={styles.modalOverlay}>
                <View style={styles.modalContent}>
                  <CCText style={styles.title}>Parental Controls</CCText>
                  <CCText style={styles.subtitle}>
                    If you break these rules you will lose the game instantly
                  </CCText>
                  <ScrollView>
                    <View style={styles.rulesContainer}>
                      {rules.length > 0 ? (
                        updatedRules.map((rule, index) => (
                          <View key={index} style={styles.ruleItem}>
                            <Image
                              source={custom_rules_icon}
                              style={styles.ruleIcon}
                            />
                            <CCText style={styles.ruleText}>{rule}</CCText>
                          </View>
                        ))
                      ) : (
                        <Text style={styles.noRulesText}>
                          Parental controls not enabled
                        </Text>
                      )}
                    </View>
                  </ScrollView>
                </View>
              </View>
            </TouchableOpacity>
          </Modal>
        </TouchableWithoutFeedback>
      )}
    </View>
  );
};

export default GameRules;
