import {Platform, StyleSheet} from 'react-native';

import CCColors from '../../Utils/CCColors';

const styles = StyleSheet.create({
  container: {
    borderColor: CCColors.TextBoxes.Borders,
    borderRadius: 10,
    borderWidth: 1,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  superscript: {
    fontSize: 10,
    lineHeight: 10,
    textBaselineOffset: 10,
  },
  input: {
    includeFontPadding: false,
    padding: 0,
    flex: 1,
    width: '100%',
    alignSelf: 'stretch',
  },

  labelContainer: {
    // alignSelf: "center",
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#FFF',
    paddingHorizontal: 2,
    position: 'absolute',
    marginTop: Platform?.OS === 'web' ? 0 : 12,
  },
  errorTxt: {
    marginLeft: 2,
    marginTop: 2,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

export default styles;
