import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

interface DeleteChapterParams {
  studySlug: string;
  chapterId: string | number;
}

export default function useDeleteChapter(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  return useMutation(
    async ({studySlug, chapterId}: DeleteChapterParams) => {
      const response = await ChessRestClient.post(
        'learning/study_games_moves/delete_chapter/',
        {
          study_game_slug: studySlug,
          chapter_id: chapterId,
        },
      );
      return response.data;
    },
    {
      onSuccess: data => {
        handleOnSuccess?.(data);
        console.log('Mutation successful:', data);
      },
      onError: error => {
        handleOnError?.(error);
        console.error('Mutation error:', error);
      },
      // onSettled: () => {
      //   console.log('Mutation settled (either success or error)');
      // },
    },
  );
}
