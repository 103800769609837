import CCColors from '@/Utils/CCColors';
import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  skillBoxWrapper: {
    flexWrap: 'wrap',
  },
  toggleWrapper: {
    flexDirection: 'row',
    width: '80%',
    borderRadius: 10,
    borderColor: CCColors?.Primary?.Grey,
    borderWidth: 1,
    overflow: 'hidden',
    marginBottom: 16,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  toggleButtonWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 6,
    paddingHorizontal: 10,
  },
  toggleButton: {
    fontSize: 14,
    color: CCColors?.Black,
  },
  activeToggle: {
    backgroundColor: CCColors?.Primary?.Brown,
  },
  activeToggleText: {
    color: CCColors?.White,
  },
});
