import {useQuery} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';
import {ApiResponse} from './types';

export default function useGetUserOTBGames({
  game_uuid = '',
  disableAutorun = false,
}: {
  game_uuid?: string;
  disableAutorun?: boolean;
}) {
  return useQuery<ApiResponse, Error>(
    [`getUserOTBGames-${game_uuid}`], // Unique key for the query
    // [],
    async () => {
      const response = await ChessRestClient.get(
        game_uuid
          ? `learning/otb-games/?game_uuid=${game_uuid}`
          : `learning/otb-games/`,
      );
      return response.data;
    },
    {
      enabled: !disableAutorun,
    },
  );
}

// import {useQuery, QueryFunction, UseQueryOptions} from '@tanstack/react-query';
// import ChessRestClient from '@/Api/ChessRestClient';
// import {ApiResponse} from './types';
// import {useState} from 'react';

// export function useGetUserOTBGames() {
//   // State to manage the activation of the query
//   const [gameUuid, setGameUuid] = useState<string | undefined>(undefined);

//   const fetchGames = async ({queryKey}: {queryKey: any[]}) => {
//     // Extract game_uuid from the queryKey
//     const [_key, game_uuid] = queryKey;
//     const url = game_uuid
//       ? `learning/otb-games/?game_uuid=${game_uuid}`
//       : 'learning/otb-games/';
//     const response = await ChessRestClient.get(url);
//     return response.data;
//   };

//   const query = useQuery<ApiResponse, Error>(
//     ['getUserOTBGames', gameUuid],
//     fetchGames,
//     {
//       // The query will not automatically run until `gameUuid` is set
//       enabled: !!gameUuid,
//     },
//   );

//   // Function to trigger the query manually
//   const executeQuery = (uuid?: string) => {
//     setGameUuid(uuid);
//   };

//   return {...query, executeQuery};
// }
