import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export function useAddWebsiteToWalkthrough(handleOnSuccess, handleOnError) {
  return useMutation(
    async website => {
      const response = await ChessRestClient.post(
        'v1/add_website_to_walkthrough/',
        {website},
      );
      return response.data;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) handleOnSuccess(data);
      },
      onError: error => {
        if (handleOnError) handleOnError(error);
      },
    },
  );
}
