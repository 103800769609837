import Box from '@/Atoms/Box';
import {useState, useEffect} from 'react';
import * as React from 'react';
import {
  Text,
  View,
  StyleSheet,
  Image,
  StyleProp,
  ViewStyle,
} from 'react-native';
import levelUnitFilled from '@/Assets/Images/Svgs/levelUnitFilled.svg';
import levelUnitEmpty from '@/Assets/Images/Svgs/levelUnitEmpty.svg';
import levelUnitFilledBrown from '@/Assets/Images/Svgs/levelUnitFilledBrown.svg';
import levelUnitEmptyBrown from '@/Assets/Images/Svgs/levelUnitEmptyBrown.svg';
import CCColors from '@/Utils/CCColors';
import CCText from '@/Atoms/CCText';
import upgradearrow from '@/Assets/Images/Svgs/learning/arrow.svg';
import slash from '@/Assets/Images/Svgs/learning/slash.svg';
import left from '@/Assets/Images/Svgs/left.svg';
import right from '@/Assets/Images/Svgs/right.svg';
import middle from '@/Assets/Images/Svgs/middle.svg';
import CCButton from '@/Atoms/CCButton';
import correct from '@/Assets/Images/Svgs/correct.svg';
import greycircle from '@/Assets/Images/Svgs/greycircle.svg';
import wrong from '@/Assets/Images/Svgs/wrong.svg';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import {vs, isPhone} from '@/Utils/Dimensions';
import AntDesign from 'react-native-vector-icons/AntDesign';

interface LevelModalProps {
  level: number;
  config: (nextLevel: number) => string;
  accuracy: number;
  objectData: any[];
  onClose: () => void;
  isSquareTrainer: boolean;
  isCalculationTrainer: boolean;
  isIntuitionTrainer: boolean;
  customStyl?: StyleProp<ViewStyle>;
  custombrownbg?: StyleProp<ViewStyle>;
  threshold: number;
}
interface PopUpProps {
  confirm: () => void;
  cancel?: () => void;
  text: string;
  buttonConfirm: string;
  fontSize?: number;
  gap?: number;
}
export const PopUp = ({
  confirm,
  cancel,
  text,
  buttonConfirm,
  fontSize = 20,
  gap = 30,
}: PopUpProps) => {
  return (
    <Box
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={gap}>
      <CCText
        style={{
          fontSize: fontSize,
        }}>
        {/* Are you sure you want to logout? */}
        {text}
      </CCText>
      <Box flexDirection="row" justifyContent="center" gap={20}>
        <CCButton onPress={confirm}>
          <Text style={styles.btnStyle}> {buttonConfirm}</Text>
        </CCButton>
        {cancel && (
          <CCButton onPress={cancel}>
            <Text style={styles.btnStyle}>Cancel</Text>
          </CCButton>
        )}
      </Box>
    </Box>
  );
};
export const getLevel = (level: number) => {
  const array = [1, 1, 1, 1, 1];
  return (
    <Box flexDirection="row" gap={4} justifyContent="center">
      {array.map((val, index) => (
        <Image
          key={index}
          source={index < level ? levelUnitFilled : levelUnitEmpty}
          style={{width: 32, height: 32}}
        />
      ))}
    </Box>
  );
};

export const getLevel2 = (level: number) => {
  const array = [1, 1, 1, 1, 1];
  const {isMobileOrTab} = useMediaQuery();
  return (
    <Box flexDirection="row" gap={4} justifyContent="center">
      {array.map((val, index) => (
        <Image
          key={index}
          source={index < level ? levelUnitFilledBrown : levelUnitEmptyBrown}
          style={{
            width: isMobileOrTab ? 30 : 40,
            height: isMobileOrTab ? 30 : 40,
          }}
        />
      ))}
    </Box>
  );
};

export const UpgradeLevel = ({level}: {level: number}) => {
  const nextLevel = level;

  return (
    <Box>
      {nextLevel > 1 && (
        <Box style={styles.upgradeBackground}>
          <Image source={slash} alt="" style={styles.upgradeSlash} />
          <Box style={styles.upgradeContent}>
            <Image
              source={upgradearrow}
              alt=""
              style={{width: 28, height: 36, position: 'absolute', left: 24}}
            />
            <Box>
              <CCText textAlign="center" fontSize={14} color={CCColors.White}>
                Congratulations!
              </CCText>
              <CCText fontSize={14} color={CCColors.White}>
                You have reached level {level}
              </CCText>
            </Box>

            <Image
              source={upgradearrow}
              alt=""
              style={{width: 28, height: 36, position: 'absolute', right: 24}}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const LevelModal: React.FC<LevelModalProps> = ({
  level,
  config,
  accuracy,
  onClose,
  isSquareTrainer,
  isCalculationTrainer,
  isIntuitionTrainer,
  customStyl,
  custombrownbg,
  objectData = [],
  threshold,
}) => {
  const nextLevel = level + 1;
  const filledObjectData = [
    ...objectData,
    ...new Array(10 - objectData.length).fill(null),
  ];

  return (
    <Box style={[styles.modalbg, customStyl]}>
      <Box style={[styles.brownbg, custombrownbg]}>
        <Box style={styles.groupimg}>
          <Box>{getLevel2(level)}</Box>
          <Box
            style={{
              paddingTop: 13,
            }}>
            <CCText
              color={CCColors.White}
              style={{
                fontSize: 14,
                fontWeight: 500,
              }}>
              Level {level}
            </CCText>
          </Box>
        </Box>
        <Box style={styles.bottomlayer}>
          <Box style={styles.ctext}>
            {isSquareTrainer && (
              <CCText
                style={{
                  color: 'white',
                  fontSize: 28,
                }}>
                {accuracy}
              </CCText>
            )}
            {!isSquareTrainer && (
              <CCText
                style={{
                  color: 'white',
                  fontSize: 28,
                }}>
                {accuracy}%
              </CCText>
            )}

            {!isSquareTrainer && (
              <CCText
                style={{
                  color: 'white',
                  fontSize: 16,
                }}>
                Successful Attempts
              </CCText>
            )}
            {isSquareTrainer && (
              <CCText
                style={{
                  color: 'white',
                  fontSize: 16,
                }}>
                Average correct attempts per 10 seconds
              </CCText>
            )}
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}>
            <Image source={middle} style={{width: 50, height: 50}} />
            <Image source={left} style={{width: 60, height: 60}} />

            <Image source={right} style={{width: 60, height: 60}} />
          </Box>
        </Box>
      </Box>
      <Box style={styles.whitebg}>
        <CCText
          color="#A8A7A6"
          style={{
            marginBottom: isPhone ? 4 : 20,

            fontSize: isPhone && 14,
          }}>
          Last 10 attempts
        </CCText>
        <Box style={styles.tenimg}>
          {objectData.length > 0 ? (
            filledObjectData.map((count, index) => (
              <Box
                style={
                  isSquareTrainer
                    ? {
                        position: 'relative',
                        marginRight: 6,
                        justifyContent: 'center',
                        paddingTop: isPhone ? 5 : 0,
                      }
                    : styles.imageContainer
                }
                key={index}>
                {isSquareTrainer && (
                  <>
                    {' '}
                    <Image
                      source={
                        count === null
                          ? greycircle
                          : count < threshold
                          ? wrong
                          : correct
                      }
                      style={{
                        width: isPhone ? 33 : 35,
                        height: isPhone ? 33 : 35,
                      }}
                    />
                    <Text
                      style={
                        count < threshold
                          ? styles.redcountSqTrainer
                          : styles.countSqTrainer
                      }>
                      {count}
                    </Text>
                  </>
                )}
                {isCalculationTrainer && (
                  <>
                    {count === null ? (
                      <Image
                        source={greycircle}
                        style={{
                          width: isPhone ? 33 : 35,
                          height: isPhone ? 33 : 35,
                        }}
                      />
                    ) : count < 1 ? (
                      <Box style={styles.crossStyle}>
                        <AntDesign
                          name="close"
                          color={CCColors.Red}
                          size={20}
                        />
                      </Box>
                    ) : (
                      <Box style={styles?.checkStyle}>
                        <AntDesign
                          name="check"
                          color={CCColors.Green}
                          size={20}
                        />
                      </Box>
                    )}
                  </>
                )}
                {isIntuitionTrainer && (
                  <>
                    <Image
                      source={
                        count === null
                          ? greycircle
                          : count < 20
                          ? wrong
                          : correct
                      }
                      style={{
                        width: isPhone ? 29 : 32,
                        height: isPhone ? 29 : 32,
                      }}
                    />
                    {count !== null && (
                      <Text
                        style={
                          count < 20
                            ? styles.redpercentcount
                            : styles.percentcount
                        }>
                        {count}%
                      </Text>
                    )}
                  </>
                )}
              </Box>
            ))
          ) : (
            <Box
              style={[
                styles.confg,
                {justifyContent: 'center', alignItems: 'center'},
              ]}>
              {' '}
              <CCText fontWeight="bold" fontSize={16}>
                Please solve positions to see
              </CCText>
              <CCText fontWeight="bold" fontSize={16}>
                your performance.
              </CCText>
            </Box>
          )}
        </Box>
        {nextLevel <= 5 ? (
          <CCText
            fontWeight="regular"
            fontSize={14}
            textAlign="center"
            style={styles.confg}>
            {config(nextLevel, accuracy)}
          </CCText>
        ) : (
          <Box style={styles.noconfig}>
            <CCText
              style={{
                fontWeight: 'bold',
                color: CCColors.BlueAqua,
              }}>
              {' '}
              Congratulations!
            </CCText>
            <CCText
              style={{
                fontWeight: 'bold',
                color: CCColors.BlueAqua,
              }}>
              You have completed all 5 levels.
            </CCText>
          </Box>
        )}
        <CCButton onPress={onClose} style={styles.gotit}>
          <CCText fontWeight="semi-bold" fontSize={16} color={CCColors.Black}>
            Got it
          </CCText>
        </CCButton>
      </Box>
    </Box>
  );
};

export default LevelModal;
export const LevelInfoBox = ({
  isMobileOrTab,
  isUpgradeVisible,
  level,
  handlePressOnGetLevel,
  getLevel,
  marginVertical,
}) => {
  return (
    <Box
      flexDirection="column"
      alignItems="center"
      style={{
        marginVertical: isMobileOrTab && marginVertical,
      }}>
      {!isUpgradeVisible && (
        <Box
          onPress={handlePressOnGetLevel}
          style={{
            backgroundColor: CCColors.White,
            borderRadius: 10,
            width: isMobileOrTab ? 340 : 362,
            height: 64,
            marginTop: 0,
          }}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 24,
              paddingVertical: 13,
              paddingHorizontal: isMobileOrTab ? 2 : 15,
            }}>
            <Box flexDirection="column">
              <CCText fontSize={14}>
                You are at&nbsp;
                <CCText fontSize={14} style={{fontWeight: 600}}>
                  level {level}
                </CCText>
              </CCText>
              <CCText
                fontSize={12}
                style={{
                  color: CCColors.BlueAqua,
                }}>
                How is this calculated?
              </CCText>
            </Box>
            {getLevel(level)}
          </Box>
        </Box>
      )}
      {isUpgradeVisible && <UpgradeLevel level={level} />}
    </Box>
  );
};

const styles = StyleSheet.create({
  upgradeBackground: {
    backgroundColor: CCColors.BlueAqua,
    borderRadius: 10,
    width: 350,
    height: 64,
    marginRight: 0,
    paddingTop: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  upgradeSlash: {
    width: 100,
    height: 64,
    zIndex: -1,
    position: 'absolute',
    left: '35%',
  },
  upgradeContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    paddingVertical: 12,
    paddingHorizontal: 24,
    justifyContent: 'center',
  },
  modalbg: {
    borderRadius: isPhone ? 20 : 8,
    backgroundColor: CCColors.White,
    width: isPhone ? 320 : 340,
    height: isPhone ? 360 : 380,
    overflow: 'visible',
  },
  brownbg: {
    backgroundColor: '#4D3F37',
    height: isPhone ? '33%' : '35%',
    display: 'flex',
    flexDirection: 'column',
    borderTopLeftRadius: isPhone ? 20 : 8,
    borderTopRightRadius: isPhone ? 20 : 8,
  },
  groupimg: {
    padding: 16,
    paddingTop: isPhone ? 10 : undefined,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  bottomlayer: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  ctext: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: isPhone ? 5 : 16,
    paddingBottom: 5,
  },
  whitebg: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '62%',
    overflow: 'visible',
  },
  tenimg: {
    width: '86%',
    height: isPhone ? '40%' : '37%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8,
    justifyContent: 'center',
    marginBottom: isPhone ? 0 : 5,
  },
  confg: {
    width: isPhone ? '92%' : '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: isPhone ? 10 : 5,
    justifyContent: 'center',
  },
  noconfig: {
    height: 45,
    alignItems: 'center',
    marginTop: 10,
  },
  gotit: {
    marginTop: isPhone ? 10 : 12,
    width: isPhone ? 282 : 300,
    color: 'black',
  },
  condimg: {width: isPhone ? 35 : 30, height: isPhone ? 35 : 30},
  count: {
    position: 'absolute',
    top: 3,
    right: isPhone ? 3 : 5,
    color: CCColors.BlueAqua,
    padding: 5,
    fontSize: 18,
    fontWeight: 'bold',
  },
  redcount: {
    position: 'absolute',
    top: 3,
    right: isPhone ? 3 : 5,
    color: '#E17846',
    padding: 5,
    fontSize: 18,
    fontWeight: 'bold',
  },
  percentcount: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: [{translateX: '50%'}, {translateY: '-50%'}],
    color: CCColors.BlueAqua,

    fontSize: 12,
    fontWeight: 'bold',
    zIndex: 0,
    textAlign: 'center',
  },
  redpercentcount: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: [{translateX: '50%'}, {translateY: '-50%'}],
    color: '#E17846',

    fontSize: 12,
    fontWeight: 'bold',
    zIndex: 1,
    textAlign: 'center',
  },
  imageContainer: {
    position: 'relative',
    marginRight: 10,
    justifyContent: 'center',
  },
  checkStyle: {
    width: isPhone ? 26 : 32,
    height: isPhone ? 26 : 32,
    backgroundColor: `${CCColors.Green}16`,
    borderRadius: 25,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  crossStyle: {
    width: isPhone ? 26 : 32,
    height: isPhone ? 26 : 32,
    backgroundColor: `${CCColors.Red}16`,
    borderRadius: 25,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  redcountSqTrainer: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: [{translateX: '50%'}, {translateY: '-50%'}],
    color: '#E17846',
    padding: 1,
    fontSize: 12,
    fontWeight: 'bold',
    zIndex: 1,
    textAlign: 'center',
  },
  countSqTrainer: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: [{translateX: '50%'}, {translateY: '-50%'}],
    color: CCColors.BlueAqua,
    padding: 1,
    fontSize: 12,
    fontWeight: 'bold',
    zIndex: 1,
    textAlign: 'center',
  },
});
