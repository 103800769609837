import React from 'react';
import {NavigationState, SceneRendererProps} from 'react-native-tab-view';
import {Animated, InteractionManager, TouchableOpacity} from 'react-native';

import Box from '../../../Atoms/Box/Box';
import Styles, {TabWidth} from './styles';
import CCColors from '../../../Utils/CCColors';

type TabBarProps = SceneRendererProps & {
  navigationState: NavigationState<{
    key: string;
    title: string;
  }>;
};

export default function TabBar(props: TabBarProps) {
  const translation = props.position.interpolate({
    inputRange: [0, 1],
    outputRange: [0, TabWidth / props.navigationState.routes.length],
  });

  const {index: selectedIndex} = props.navigationState;

  return (
    <Box alignItems="center">
      <Box style={Styles.containerStyle}>
        <Box style={Styles.tabBarPill}>
          {props.navigationState.routes.map((route, i) => {
            return (
              <TouchableOpacity
                key={route.title}
                style={[Styles.tabItem]}
                onPress={() => {
                  InteractionManager.runAfterInteractions(() => {
                    props.jumpTo(route.key);
                  });
                }}>
                <Animated.Text
                  style={[
                    Styles.labelStyle,
                    {
                      color:
                        selectedIndex === i
                          ? CCColors.Primary.Default
                          : CCColors.Grey.Grey,
                    },
                  ]}>
                  {route.title}
                </Animated.Text>
              </TouchableOpacity>
            );
          })}
        </Box>

        <Box style={Styles.sliderParent}>
          <Animated.View
            style={[
              Styles.sliderContainerStyle,
              {
                transform: [{translateX: translation}],
                width: TabWidth / props.navigationState.routes.length,
              },
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
}

export function renderTabBarPill(props: TabBarProps): React.ReactNode {
  return <TabBar {...props} />;
}
