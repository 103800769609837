import { DashboardResponse } from './types';
import { useMutation } from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useUpdateProfileApi(
    handleOnSuccess?: (data: any) => void,
    handleOnError?: (data: any) => void
) {
    const mutation = useMutation<
        DashboardResponse,
        Error,
        any>(
            async (data: any) => {
                try {
                    const response = await ChessRestClient.post('learning/save_profile/', data);
                    console.log('Retrieved profile = ', response.data);
                    return response.data;
                } catch (e) {
                    console.log('Exception occurred = ', e);
                }
            },
            {
                onSuccess: (data) => {
                    console.log('On Success = ', data)
                    if (handleOnSuccess && data.success)
                        handleOnSuccess(data);
                    else if (handleOnError)
                        handleOnError(data);
                },
            });

    return {
        ...mutation,
    };
}
