import Box from '@/Atoms/Box';
import ClockComponent from '../ClockComponent';
import CCColors from '@/Utils/CCColors';
import CCText from '@/Atoms/CCText';
import {FontSizes, isPortrait, isPhone} from '@/Utils/Dimensions';
import CapturedPieces from '../CapturedPieces';
import {useState} from 'react';
import {RFPercentage} from 'react-native-responsive-fontsize';

function PlayerComponent(props) {
  const {
    color,
    username,
    timer,
    whitePieces,
    blackPieces,
    showFirstMoveTimer,
    firstMoveTimer,
    rating,
    myTurn,
    playing,
    marginLeftUsername,
    squareWidth,
    marginLeft,
    usernameFont,
  } = props;
  const borderColor = color === 'white' ? 'black' : 'white';
  // console.log('timer is: ', timer);

  const [whitePiecesCaptured, setWhitePiecesCaptured] = useState([
    'P',
    'P',
    'P',
    'N',
    'B',
  ]);
  const [blackPiecesCaptured, setBlackPiecesCaptured] = useState([
    'P',
    'P',
    'P',
    'P',
    'P',
    'R',
    'N',
  ]);
  const firstMoveColor = CCColors.LightYellow;
  const [FirstMoveTimer, setFirstMoveTimer] = useState(20);
  // Check how to implement shadow here
  return (
    <Box
      // flex={(isPhone||isPortrait)? 4 : 10}
      flexDirection="row"
      style={{
        width: isPhone || isPortrait ? '100%' : '90%',
        padding: 8,
        backgroundColor: CCColors.White,
        borderColor: '#2623221A',
        borderWidth: 1,
        elevation: 100,
        shadowColor: 'black',
        shadowOffset: 10,
        shadowOpacity: 0.5,
        borderRadius: 8,
        height: isPhone || isPortrait ? 70 : '12.5%',
      }}>
      {(timer || timer == 0) && (
        <ClockComponent
          timer={timer}
          myTurn={myTurn}
          playing={playing}
          setFirstMovetimer={setFirstMoveTimer}
          firstMovetimer={firstMoveTimer}
        />
      )}
      <Box
        flex={5}
        flexDirection="row"
        style={{
          marginLeft: marginLeft ? marginLeft : '5%',
          alignItems: 'center',
        }}>
        <Box
          flexDirection="column"
          style={{
            alignContent: 'center',
            width: squareWidth ? squareWidth : 24,
            height: squareWidth ? squareWidth : 24,
            backgroundColor: color,
            borderTopColor: borderColor,
            borderBottomColor: borderColor,
            borderBlockColor: borderColor,
            borderWidth: 1,
          }}></Box>
        <Box flexDirection="column">
          <CCText
            showEllipses
            style={{
              justifyContent: 'center',
              marginLeft: marginLeftUsername ? marginLeftUsername : 16,
            }}
            fontSize={usernameFont ? usernameFont : FontSizes[12]}>
            {username}
          </CCText>
          <CCText
            showEllipses
            style={{justifyContent: 'center', marginLeft: 16}}
            fontSize={FontSizes[10]}>
            {rating}
          </CCText>
        </Box>
      </Box>
      {showFirstMoveTimer && (
        <Box
          flex={4}
          flexDirection="row"
          style={{
            alignContent: 'center',
            alignSelf: 'center',
            backgroundColor: firstMoveColor,
            width: 'auto',
            height: 'auto',
          }}>
          <CCText
            style={{justifyContent: 'center', marginLeft: 16}}
            fontSize={RFPercentage(1)}>
            {FirstMoveTimer}s to make the first move
          </CCText>
        </Box>
      )}
      {!showFirstMoveTimer && (
        <CapturedPieces
          color={color}
          blackPieces={blackPieces}
          whitePieces={whitePieces}
        />
      )}
    </Box>
  );
}

export default PlayerComponent;
