import CCColors, {updateColorWithOpacity} from '../../Utils/CCColors';
import {hs, vs} from '../../Utils/Dimensions';

import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  containerStyle: {
    outlineStyle: 'none',
    borderRadius: 16,
    height: 'auto',
    backgroundColor: CCColors.Primary.Default,
    overflow: 'hidden',
  },
  boxStyle: {
    flexDirection: 'row',
    borderRadius: 1,
    height: 'auto',
    backgroundColor: CCColors.Primary.Brown,
    borderColor: updateColorWithOpacity(CCColors.Grey.Grey, 0.4),
    borderWidth: 1,
    // borderStyle: 'solid',
    // border: 1px solid rgba(34, 34, 34, 0.12),
    // minWidth: vs(250),
    // paddingHorizontal: hs(12),
    // paddingVertical: vs(8),
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerOutlineStyle: {
    outlineStyle: 'none',
    borderRadius: 8,
    height: 'auto',
    backgroundColor: CCColors.White,
    borderColor: CCColors.Buttons.Background,
    borderWidth: 1,
    overflow: 'hidden',
  },
  containerOutlineMobileStyle: {
    // outlineStyle: 'none',
    // borderRadius: 8,
    // height: 'auto',
    // backgroundColor: CCColors.White,
    // borderColor: CCColors.Buttons.Background,
    borderWidth: 2,
    // overflow: 'hidden',
  },

  contentContainerStyle: {
    paddingHorizontal: hs(30),
    paddingVertical: vs(8),
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },

  pageNoStyle: {
    paddingHorizontal: hs(5),
  },
  stretched: {
    alignSelf: 'stretch',
  },

  disabled: {
    backgroundColor: updateColorWithOpacity(CCColors.Buttons.Background, 0.2),
    // opacity: 0.2,
    borderColor: CCColors?.Grey.Grey,
    // borderBottomWidth: 1,
  },
  pressed: {
    // backgroundColor: updateColorWithOpacity(CCColors.Buttons.Background, 0.4),
  },
  underlinePressed: {
    borderBottomColor: CCColors?.White,
    borderBottomWidth: 2,
    paddingBottom: 6,
  },
  title: {
    marginTop: -vs(2), // For center aligning text
    color: CCColors.White,
  },
  titleOutlineStyle: {
    marginTop: -vs(2), // For center aligning text
    color: CCColors.TextColor.Primary,
  },
  containerUnderlineStyle: {
    borderBottomColor: CCColors.Buttons.Background,
    borderBottomWidth: 2,
    paddingBottom: 6,
  },
  loadingStyle: {
    // opacity: 0.8,
  },

  loading: {
    position: 'absolute',
    top: '45%',
    left: '45%',
    // height: 30,
    // width: 30,
  },
});

export default styles;
