import React from 'react';
import styles from './styles';
import CCText from '@/Atoms/CCText';
import Box from '@/Atoms/Box';
import {convertTimeControlToMins} from '../../utils';
import {ActivityIndicator} from 'react-native';
import ConditionalRender from '@/Atoms/ConditionalRender';
import CCColors from '@/Utils/CCColors';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';

export interface ChessGameType {
  id: number;
  userid: number;
  platform: string;
  url: string;
  color: string;
  pgn: string;
  time_control: string;
  end_time: string;
  rated: number;
  uuid: string;
  time_class: string;
  result: string;
  reason: string;
  rating: number;
  opponent_rating: number;
  opponent_profile: string;
  opponent_username: string;
  opening: string;
  total_number_of_moves: number;
  castling_index: number;
  opponent_castling_index: number;
  average_time: number;
  opponent_average_time: number;
  time_left_on_clock: number;
  opponent_time_left_on_clock: number;
  time_utilized: number;
  opponent_time_utilized: number;
  total_game_duration_in_minutes: number;
  accuracy: number;
  opponent_accuracy: number;
  analyzed: number;
  unsolved: number;
  annotation_reason: number;
  tournament_id: number;
  round_id: number;
  created_time: string;
  updated_time: string;
}

function getGameOutcomeReason(reasonString: string) {
  if (!reasonString) {
    return '';
  }

  switch (reasonString) {
    case 'timeout':
      return 'Time out';
      break;
    case 'timevsinsufficient':
      return 'Insufficient material';
      break;
    case 'resigned':
      return 'Resignation';
      break;
    case 'repetition':
      return '3-fold repetition';
      break;
    case 'abandoned':
      return 'Game abandoned';
      break;
    case 'checkmated':
      return 'Checkmated';
      break;
    case 'stalemate':
      return 'Stalemate';
      break;
    case '50move':
      return '50 move rule';
      break;
    case 'insufficient':
      return 'Insufficient material';
      break;
    case 'agreed':
      return 'Draw Agreed';
      break;
  }
  return reasonString;
}

function formatTime(time: number): string {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  
  if (minutes === 0) {
    return `${seconds} second${seconds !== 1 ? 's' : ''}`;
  }
  
  return `${minutes} minute${minutes !== 1 ? 's' : ''} ${seconds} second${seconds !== 1 ? 's' : ''}`;
}

function generateGameOverview(game) {
  const outcomeReason = getGameOutcomeReason(game?.reason || game?.end_type);
  const myColor = game?.color;
  const opponentColor = game?.color == 'White' ? 'Black' : 'White';
  const overview = {
    'Game ID': game?.id,
    Platform: game?.platform,
    'Game URL': game?.url,
    Date: game?.end_time ? new Date(game?.end_time).toLocaleDateString() : null,
    Players:
      game?.username && game?.opponent_username
        ? `${game?.username} (${myColor}, ${game?.rating}) vs ${game?.opponent_username} (${opponentColor}, ${game?.opponent_rating})`
        : null,
    Result:
      `${game?.username} ` && game?.result
        ? ` ${
            game?.result === 'Loss'
              ? game?.username + ' Lost'
              : game?.result === 'Draw'
              ? ' Draw'
              : game?.username + ' Won'
          }${outcomeReason ? '  |   Reason: ' + outcomeReason : ''}`
        : null,
    'Total Number of Moves': game?.total_number_of_moves,
    'Time Control': game?.time_control
      ? convertTimeControlToMins(game?.time_control)
      : null,
    'Average Time per Move': game?.average_time
      ? `${game?.average_time} seconds`
      : null,
    'Time Left on Clock':
      game?.time_left_on_clock != null &&
      game?.opponent_time_left_on_clock != null
        ? `${formatTime(game?.time_left_on_clock)} for ${game?.username}; ${formatTime(game?.opponent_time_left_on_clock)} for ${game?.opponent_username}`
        : null,
    'Total Game Duration': game?.total_game_duration_in_minutes
      ? `${game?.total_game_duration_in_minutes} minutes`
      : null,
    Castling:
      game?.username && game?.opponent_username
        ? `${game?.username} ${
            game?.castling_index > 0
              ? 'castled on move ' + game?.castling_index
              : 'did not castle'
          }; ${game?.opponent_username} ${
            game?.opponent_castling_index > 0
              ? 'castled on move ' + game?.opponent_castling_index
              : 'did not castle'
          }`
        : null,
    'Time Utilization':
      game?.username && game?.opponent_username
        ? `${game?.username} utilized ${
            game?.time_utilized ? game?.time_utilized.toFixed(1) : 0
          }% of their time, while ${game?.opponent_username} utilized ${
            game?.opponent_time_utilized
              ? game?.opponent_time_utilized.toFixed(1)
              : 0
          }%`
        : null,
  };

  // Filter out entries with null or undefined values
  return Object.fromEntries(
    Object.entries(overview).filter(
      ([_, value]) => value != null && value !== '',
    ),
  );
}

function GameOverview({gameData}: {gameData: ChessGameType}) {
  const {isMobileOrTab} = useMediaQuery();
  const formattedData = generateGameOverview(gameData);
  return (
    <Box>
      <ConditionalRender
        condition={Object.keys(formattedData).length === 0}
        childrenA={
          <Box style={{height: '100%'}} overflow="hidden">
            <ActivityIndicator
              size="small"
              color={CCColors.Green}
              style={{marginTop: !isMobileOrTab ? 200 : 10}}
            />
          </Box>
        }
        childrenB={
          <>
            {Object.keys(formattedData)?.map(i => (
              <Box style={styles.row}>
                <CCText style={styles.cell1}>{i}</CCText>
                <CCText style={styles.cell2} fontWeight="600">
                  {formattedData?.[i]}
                </CCText>
              </Box>
            ))}
          </>
        }
      />
    </Box>
  );
}

export default GameOverview;
