import React, {useState} from 'react';
import {View, Text, StyleSheet, TextInput, Button} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {ScreenName} from '@/navigators/StackConstants';
import CCText from '@/Atoms/CCText';
import CCButton from '@/Atoms/CCButton';
import CCColors from '@/Utils/CCColors';
import reportStyles from './styles';

export interface Screen {
  id: number;
  Name: string;
  component: React.ComponentType<any>;
  props?: object;
  withAuth?: boolean;
  menuOptions?: Object;
  hideMenu?: boolean;
  Dashboard?: () => void; // Define the Dashboard property
}

const ReportAnIssue = () => {
  const [inputText, setInputText] = useState('');
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState('');
  const navigation = useNavigation();

  const handleInputChange = text => {
    setInputText(text);
  };

  const handleButtonPress = () => {
    if (inputText.trim() === '') {
      setError('please type something');
    } else {
      console.log('successfully submitted', inputText, selectedFile);
      setInputText('');
      setShow(true);
    }
  };

  const handleImageInputChange = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const gotoDashboard = () => {
    if (ScreenName && ScreenName.Dashboard) {
      navigation.navigate(ScreenName.Dashboard);
    }
  };

  return (
    <View style={reportStyles.reportForm}>
      {!show && (
        <View style={reportStyles.innerview}>
          <CCText fontSize={18} fontWeight="500" style={{marginBottom: 10}}>
            Report Form
          </CCText>
          <TextInput
            style={reportStyles.input}
            onChangeText={handleInputChange}
            value={inputText}
            placeholder="Type something..."
            autoFocus
          />
          <View style={reportStyles.buttonContainer}>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageInputChange}
            />
          </View>

          {error && (
            <CCText style={{color: CCColors.Error}}>
              Report should not be empty
            </CCText>
          )}
          <View style={reportStyles.buttonContainer}>
            <CCButton
              onPress={handleButtonPress}
              style={{
                backgroundColor: CCColors.Tabs.ActiveBackground,
                marginTop: 10,
              }}>
              Submit
            </CCButton>
          </View>
        </View>
      )}

      {show && (
        <View style={reportStyles.displaySuccess}>
          <CCText>Successfully Reported</CCText>
          <CCButton
            onPress={gotoDashboard}
            style={{backgroundColor: CCColors.Tabs.ActiveBackground}}>
            Go to Dashboard
          </CCButton>
        </View>
      )}
    </View>
  );
};

export default ReportAnIssue;
