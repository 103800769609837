import create from 'zustand';

interface GraphDimensionsState {
  dimensions: {width: number; height: number};
  setDimensions: (width: number, height: number) => void;
}
const useGraphDimensionsStore = create<GraphDimensionsState>(set => ({
  dimensions: {width: 0, height: 0},
  setDimensions: (width, height) => set({dimensions: {width, height}}),
}));

export default useGraphDimensionsStore;
