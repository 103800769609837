import React, {useEffect} from 'react';
import {logEvent} from '@/Utils/GAEvents';

const useGAPageView = screenName => {
  useEffect(() => {
    logEvent('pageview', screenName);
  }, [screenName]);
};

export default useGAPageView;
