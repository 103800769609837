import Box from '../../Atoms/Box';
import CCText from '../../Atoms/CCText';
import CCButton from '../../Atoms/CCButton';
import React, { useState } from 'react';
import SafeAreaWrapper from '../../Atoms/SafeViewWrapper';
import Spacer from '@/Atoms/Spacer';
import { hs, vs, FontSizes, isPhone } from '../../Utils/Dimensions';
import { styles } from './styles';
import { useNavigation } from '@react-navigation/native';
import { ScreenName } from '@/navigators/StackConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChessPawn, faChessKnight, faChessQueen, faChessBishop, faChessRook, faChessKing, faChess } from '@fortawesome/free-solid-svg-icons'
import { FlatList, View } from 'react-native';
import useAvatar from '@/Hooks/setAvatar/setAvatarApi';
import CCColors from '@/Utils/CCColors';
import logo from '../../Assets/Images/Svgs/cc_logo.png';
import brief_logo from '../../Assets/Images/Svgs/brief_logo.png';
import illustration from '../../Assets/Images/Svgs/select_avatar.svg';
import { Image } from 'react-native';

function SelectAvatar({onLoginStateChange}) {
  const navigation = useNavigation<any>();
  const [avatarInfo, setAvatarInfo] = useState<{ avatarIcon: any, avatarName: string, avatarTagline: string }>({
    avatarIcon: faChessBishop,
    avatarName: 'Blazing Bishop',
    avatarTagline: 'You fancy blazing through the 64 squares, leaving a trail of destruction!'
  });

  const handleOnSuccess = (apiData: any) => {
    onLoginStateChange()
  };

  const handleOnError = (apiData: any) => {
  }

  const data = [
    { key: 'A', icon: faChessPawn, name: 'Persistent Pawn', tagline: 'There are so few like you, determined, unrelenting, positional and winner!' },
    { key: 'B', icon: faChessKnight, name: 'Killer Knight', tagline: 'Tricky, deceptive, positional and attacking, you are an artist!' },
    { key: 'C', icon: faChessBishop, name: 'Blazing Bishop', tagline: 'You fancy blazing through the 64 squares, leaving a trail of destruction!' },
    { key: 'D', icon: faChessRook, name: 'Rumbling Rook', tagline: 'You are a heavy weight champion, crushing your enemies beneath your feet.' },
    { key: 'E', icon: faChessQueen, name: 'Qrushing Queen', tagline: 'Your aggression has no limits, you crush all that come in your path.' },
    { key: 'F', icon: faChessKing, name: 'Kingly King', tagline: 'You are majestic, silent, strategic leader, marshalling your forces to victory!' },
  ];

  const {
    mutate: setAvatar,
    data: validatedData,
    error: validatedError,
    isLoading,
  } = useAvatar(handleOnSuccess, handleOnError);

  const selectAvatar = async () => {
    setAvatar({ name: avatarInfo.avatarName, dp: avatarInfo.avatarIcon.iconName });
  }

  const formatData = (data, numColumns) => {
    const numberOfFullRows = Math.floor(data.length / numColumns);

    let numberOfElementsLastRow = data.length - (numberOfFullRows * numColumns);
    while (numberOfElementsLastRow !== numColumns && numberOfElementsLastRow !== 0) {
      data.push({ key: `blank-${numberOfElementsLastRow}`, empty: true });
      numberOfElementsLastRow++;
    }

    return data;
  };

  const numColumns = 3;

  const renderItem = ({ item, index }) => {
    if (item.empty === true) {
      return <View style={[styles.item, styles.itemInvisible]} />;
    }
    return (
      <Box style={styles.item} onPress={(e) => {
        setAvatarInfo({ avatarIcon: item.icon, avatarName: item.name, avatarTagline: item.tagline })
      }
      }>
        <FontAwesomeIcon icon={item.icon} size='3x' color='#FAF6EB' />
        <Spacer spacing={12} />
        <CCText fontSize={FontSizes[12]} style={styles.itemText}>{item.name}</CCText>
      </Box>
    );
  };

  return (
    <SafeAreaWrapper>
      <Box justifyContent="center" alignItems="center" flexDirection='column' style={styles.container}>
        <Box style={[styles.labelText]} flexDirection='row'>
          <Box style={{ flex: 0.5, backgroundColor: '#FAF6EB' }} flexDirection='column'>
            <CCText
              fontSize={FontSizes[24]}
              textAlign="center"
              fontWeight="bold"
              style={{ marginTop: '15%' }}
              color={CCColors.TextColor.Primary}>
              Select your Avatar in the world of CircleChess
            </CCText>
            <CCText
              fontSize={FontSizes[18]}
              textAlign="center"
              style={{ marginTop: vs(32) }}
              color={CCColors.TextColor.Primary}>
              Earn CircleChess points while you learn. Redeem them to earn rewards!
            </CCText>
            <Image source={illustration} style={{ height: vs(455), width: vs(455), alignSelf: 'center', marginTop: vs(48) }} />
          </Box>
          <Box style={styles.rightlabelText}>
            <Box flexDirection='row'>
              <Box>
                <img src={brief_logo} style={{ width: 28, height: 28 }} />
              </Box>
              <Box
                style={{
                  justifyContent: 'flex-end', marginHorizontal: vs(10), borderTopWidth: 4, borderTopColor: CCColors.ColorCodes.Done,
                  width: 112
                }}>
                <CCText style={{ color: CCColors.TextColor.Primary }} fontSize={FontSizes[12]}>Link accounts</CCText>
              </Box>
              <Box
                style={{
                  justifyContent: 'flex-end', marginHorizontal: vs(10), borderTopWidth: 4, borderTopColor: CCColors.ColorCodes.Done,
                  width: 112
                }}>
                <CCText style={{ color: CCColors.TextColor.Primary }} fontSize={FontSizes[12]}>Fetch Games</CCText>
              </Box>
              <Box
                style={{
                  justifyContent: 'flex-end', marginHorizontal: vs(10), borderTopWidth: 4, borderTopColor: CCColors.ColorCodes.Current,
                  width: 112
                }}>
                <CCText style={{ color: CCColors.TextColor.Primary }} fontSize={FontSizes[12]}>Select Avatar</CCText>
              </Box>
            </Box>
            <CCText
              fontSize={FontSizes[24]}
              fontWeight="bold"
              style={{ marginTop: '10%' }}
              color={CCColors.menuBackground.main}>
              Preparing your battleground
            </CCText>
            <Spacer spacing={24} />
            <CCText
              fontSize={FontSizes[14]}>
              Select your Avatar.
            </CCText>
            <Spacer spacing={48} />
            <Box flexDirection={isPhone ? "column" : "row"}
              alignItems='center'
            >
              <Box style={{ flex: 0.4 }}>
                <FlatList
                  data={formatData(data, 3)}
                  style={styles.container_avatar}
                  renderItem={renderItem}
                  numColumns={3} />
              </Box>
              <Spacer spacing={36} horizontal={true} />
              <Box
                flexDirection='column'
                justifyContent="center"
                alignItems='center'
                style={{ paddingTop: vs(12), padding: 12, width: 100, flex: 0.2 }}
              >
                <FontAwesomeIcon
                  icon={avatarInfo.avatarIcon}
                  size='4x'
                  color='#4D3F37'
                  border={true}
                  style={{ borderWidth: 1, borderColor: '#4D3F37' }} />
                <Spacer spacing={12} />
                <CCText fontSize={FontSizes[12]} style={{ textAlign: 'center' }}>{avatarInfo.avatarName}</CCText>
              </Box>
            </Box>
            <Spacer spacing={18} />
            <CCText style={{ color: 'green' }} fontSize={FontSizes[14]}>
              <i>{avatarInfo.avatarTagline}</i>
            </CCText>
            <Spacer spacing={32} />
            <Box
              flexDirection={isPhone ? "column" : "row"}
            >
              <CCButton
                style={styles?.buttonWrapper}
                titleStyle={styles.buttonStyles}
                onPress={selectAvatar}
                type='outline'
              >
                <CCText fontSize={FontSizes[14]}>Step into the Chess Ring!</CCText>
              </CCButton>
            </Box>
            <img
              src={logo}
              alt="Circlechess"
              style={{ position: 'absolute', bottom: 50, right: 50, width: 200, marginTop: vs(36) }}
            />
          </Box>
        </Box>
      </Box>
    </SafeAreaWrapper>
  );
}

export default SelectAvatar;
