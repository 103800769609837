import { FontSizes, hs, isPhone, vs, WINDOW_HEIGHT, WINDOW_WIDTH } from "../../Utils/Dimensions";
import { StyleSheet } from "react-native";

export const styles2 = StyleSheet.create({
    bishopImg: {
        position: 'absolute',
        bottom: '5%',
        right: '50%',
        width: '200px',
        height: '300px',
        transform: 'translateX(200%)',
        ...(isPhone && { display: "none" }),
    },

    header: {
        fontWeight: 600,
        color: '#262322',
        fontSize: 30,
        marginBottom: '120px',
        textAlign: 'center',
        ...(isPhone && { fontSize: "24px", marginBottom: '24px' }),
    },

    welcomeMsg: {
        flex: 0.1,
        textAlign: 'center',
        marginBottom: 6,
        opacity: 0,
        position: 'absolute',
        bottom: '20%',
        left: '50%',
        transform: 'translateX(-50%)',
      },

    input: {
        ...(isPhone && { width: 300 }),

    },

    pawnImg: {
        position: 'absolute',
        bottom: '10%',
        left: '50%',
        transform: 'translateX(-250%)',
        width: '150px',
        height: '200px',
        ...(isPhone && { display: "none" }),

    },

    waitlistContainer: {
        padding: '96px',
        ...(isPhone && { padding: '24px', paddingTop: '96px' }),

    },

    waitListPage: {
        'background-image': 'linear-gradient(to top, #FAF6EB 20% , transparent 50%);',
     }
})
