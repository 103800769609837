import CCColors from '@/Utils/CCColors';
import {vs, isPhone, WINDOW_WIDTH} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  intuitionContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 25,
    width: '50%',
  },
  intuitionContain: {
    width: '100%',
  },
  mobileContainer: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  tabletContainer: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: CCColors.Primary.lightBrown,
    paddingHorizontal: 10,
    flexWrap: 'nowrap',
    justifyContent: 'center',
  },
  title: {
    marginBottom: 20,
    marginTop: '2%',
  },
  row: {
    flex: 1,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    gap: 40,
    padding: 10,
    width: '100%',
  },
  select: {
    width: '100%',
    marginRight: 10,
    backgroundColor: CCColors.White,
    borderColor: CCColors.BorderBottom,
    height: 38,
  },
  playButton: {
    marginLeft: 10,
  },

  autoCompleteFlatList: {
    position: 'absolute',
    width: '100%',
    top: '120%',
    left: 0,
    backgroundColor: CCColors.White,
    borderColor: CCColors.BorderBottom,
    borderRadius: 10,
  },
  divider: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  gamesList: {
    borderRadius: 8,
    backgroundColor: CCColors.White,
    height: vs(400),
  },
  input: {
    width: '90vw',
    display: 'flex',
    gap: 12,
    marginHorizontal: 'auto',
    justifyContent: 'center',
  },
  tablet: {
    width: '65vw',
    display: 'flex',
    gap: 12,
    marginHorizontal: 'auto',
    justifyContent: 'center',
  },
  inputfrdesktop: {
    width: '80%',
    display: 'flex',
    gap: 12,

    justifyContent: 'flex-start',
  },
  inputfrdesktoponback: {
    width: '100%',
    display: 'flex',
    gap: 12,

    justifyContent: 'flex-start',
  },
  levelMobileModalCustom: {
    borderRadius: 12,
    backgroundColor: 'transparent',
    zIndex: 5,

    overflowY: 'hidden',
  },
  levelModalCustom: {
    borderRadius: 12,
    backgroundColor: 'transparent',
    zIndex: 5,
    overflowY: 'hidden',
    minWidth: WINDOW_WIDTH / 3,
    margin: 'auto',
    left: 40,
  },
  dimension: {
    height: 390,
  },
  dimensions: {
    height: '33%',
    overflow: 'hidden',
  },
});

export default styles;
