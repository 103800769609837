import {FontSizes, hs, isPhone, vs} from '../../Utils/Dimensions';

import CCColors, {updateColorWithOpacity} from '../../Utils/CCColors';
import {Dimensions, StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  closeIcon: {
    width: 22,
    height: 22,
    borderRadius: 25,
    backgroundColor: updateColorWithOpacity(CCColors.Primary.lightBrown, 0.8),
    position: 'absolute',
    zIndex: 1,
    right: 2,
    top: '5%',
  },
  playerScore: {
    // flexDirection: 'column',
    // width: '100%',
    // height: '100%',
    flex: 1,
    // overflow: 'auto',
  },
  eachPlayerName: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  eachPlayerDetails: {
    marginHorizontal: 10,
  },
  BrownBox: {
    width: 15,
    height: 15,
    backgroundColor: CCColors.Primary.Brown,
    borderColor: CCColors.Primary.Brown,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 2,
  },
  whiteBox: {
    width: 15,
    height: 15,
    backgroundColor: CCColors.White,
    borderColor: CCColors.Black,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 2,
  },
  selectedMove: {
    backgroundColor: CCColors.Primary.lightYellow,
    borderColor: CCColors.Primary.Default,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    // backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  eachMoveWrapper1: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,

    justifyContent: 'center',
    // flexDirection: 'row',
    // flex: 1,
  },
  eachMoveWrapper: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
    // flexDirection: 'row',
    // flex: 1,
  },
  eachMoveInsideWrapper: {
    // margin: 15,
    marginTop: 15,
    flexDirection: 'row',
    // marginTop:15,
    // marginTop:15,
    // marginTop:15,
  },
  eachMoveWrapper2: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 0,
    backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,

    justifyContent: 'center',
    // flexDirection: 'row',
    // flex: 1,
  },
  chessboard: {
    ...(isPhone ? {} : {flex: 0.43}),
    height: '100%',
  },
  container: {
    flexDirection: isPhone ? 'column' : 'row',
    // height: '90vh',
  },
  // left: {
  //   ...(isPhone ? {} : {flex: 0.2}),
  // },
  // middle: {
  //   ...(isPhone ? {} : {flex: 0.4}),
  // },
  // right: {
  //   ...(isPhone ? {} : {flex: 0.4}),
  //   width: isPhone ? '90%' : '50%',
  //   height: !isPhone ? '100%' : undefined,
  //   marginLeft: 'auto',
  //   marginRight: 'auto',
  // },
  left: {
    flex: 0.2,
    paddingHorizontal: 20,
    paddingTop: 10,
  },
  center: {
    flex: 0.5,
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
  },
  right: {
    flex: isPhone ? 1 : 0.3,
    padding: 10,
  },
  deleteIcon: {
    position: 'absolute',
    right: 50,
    top: 20,
    width: 30,
    cursor: 'pointer',
  },
  toggleButton: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#007BFF',
    textDecorationLine: 'underline',
  },
});
