import ChessRestClient from '@/Api/ChessRestClient';
import {getLocalStorage} from '@/Utils/CommonUtils';
import {useMutation} from '@tanstack/react-query';

export default function useSubmitSupportTicket(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<
    any,
    Error,
    {
      description: string;
      file?: File;
    }
  >(
    async ({description, file}) => {
      const token = await getLocalStorage('access_token');

      const formData = new FormData();
      formData.append('description', description);
      if (file) {
        formData.append('file', file);
      }

      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      };

      const response = await ChessRestClient.post(
        'save_raised_issue/',
        formData,
        options,
      );
      return response.data;
    },
    {
      onSuccess: data => {
        handleOnSuccess?.(data);
      },
      onError: data => {
        handleOnError?.(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
