// import ArrowAnimation from '@/Animations/UpAndDownArrow';
// import CCColors from '@/Utils/CCColors';
// import {WINDOW_HEIGHT} from '@/Utils/Dimensions';
// import React, {useRef, useState} from 'react';
// import {
//   View,
//   StyleSheet,
//   TouchableOpacity,
//   Text,
//   Animated,
//   Dimensions,
//   PanResponder,
// } from 'react-native';
// import Modal from 'react-native-modal';
// import Ionicons from 'react-native-vector-icons/Ionicons';
// import Box from '../Box';

// const {height: screenHeight} = Dimensions.get('window');
// type BottomSheetType = {
//   children: JSX.Element;
//   minHeight?: number;
// };
// // const INITIAL_HEIGHT = WINDOW_HEIGHT / 2.25;
// const BottomSheet: React.FC = ({
//   children = <></>,
//   minHeight = WINDOW_HEIGHT / 2.25,
// }: BottomSheetType) => {
//   const [isVisible, setIsVisible] = useState(true);
//   const INITIAL_HEIGHT = minHeight;
//   const sheetHeight = useRef(new Animated.Value(INITIAL_HEIGHT)).current;
//   const pan = useRef(new Animated.Value(0)).current;

//   const panResponder = useRef(
//     PanResponder.create({
//       onMoveShouldSetPanResponder: () => true,
//       onPanResponderGrant: () => {
//         pan.setOffset(pan.__getValue());
//         pan.setValue(0);
//       },
//       onPanResponderMove: (evt, gestureState) => {
//         if (gestureState.dy < 0) {
//           Animated.timing(sheetHeight, {
//             toValue: INITIAL_HEIGHT - gestureState.dy,
//             duration: 0,
//             useNativeDriver: false,
//           }).start();
//         }
//       },
//       onPanResponderRelease: (evt, gestureState) => {
//         pan.flattenOffset();
//         if (gestureState.dy > 0) {
//           // If dragging downward, snap back to initial height
//           Animated.timing(sheetHeight, {
//             toValue: INITIAL_HEIGHT,
//             duration: 300,
//             useNativeDriver: false,
//           }).start();
//         }
//       },
//     }),
//   ).current;

//   const toggleModal = () => {
//     setIsVisible(!isVisible);
//   };

//   return (
//     <View style={styles.container}>
//       {/* <TouchableOpacity onPress={toggleModal} style={styles.button}>
//         <Text style={styles.buttonText}>Open Bottom Sheet</Text>
//       </TouchableOpacity> */}
//       <Modal
//         isVisible={isVisible}
//         // onBackdropPress={() => {}}
//         style={styles.modal}
//         hasBackdrop={false}
//         coverScreen={false}
//         backdropOpacity={0}
//         useNativeDriver={false}
//       >
//         <View style={styles.backgroundTouchable}>
//           <Animated.View
//             style={[styles.modalContent, {height: sheetHeight}, styles.shadow]}>
//             <View
//               {...panResponder.panHandlers}
//               hitSlop={{
//                 top: 40,
//                 bottom: 40,
//                 left: 40,
//                 right: 40,
//               }}>
//               <View style={styles.dragHandle}>
//                 <Ionicons
//                   name="chevron-up"
//                   size={30}
//                   color={CCColors.Grey.Grey}
//                 />
//                 {/* <ArrowAnimation /> */}
//               </View>
//             </View>
//             {children}
//             {/* <Text style={styles.modalText}>This is a bottom sheet</Text>
//             <TouchableOpacity onPress={toggleModal} style={styles.closeButton}>
//               <Text style={styles.buttonText}>Close</Text>
//             </TouchableOpacity> */}
//           </Animated.View>
//         </View>
//       </Modal>
//     </View>
//   );
// };

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   button: {
//     backgroundColor: '#2196F3',
//     padding: 10,
//     borderRadius: 5,
//   },
//   buttonText: {
//     color: '#fff',
//     fontSize: 16,
//   },
//   modal: {
//     justifyContent: 'flex-end',
//     margin: 0,
//   },
//   modalContent: {
//     backgroundColor: 'white',
//     // padding: 10,
//     borderTopLeftRadius: 17,
//     borderTopRightRadius: 17,
//     borderBottomLeftRadius: 0,
//     borderBottomRightRadius: 0,
//     borderColor: 'rgba(0, 0, 0, 0.1)',
//   },
//   // dragHandle: {
//   //   width: 40,
//   //   height: 6,
//   //   backgroundColor: '#ccc',
//   //   borderRadius: 3,
//   //   alignSelf: 'center',
//   //   marginVertical: 10,
//   // },
//   dragHandle: {
//     // width: 40,
//     // height: 6,
//     // backgroundColor: '#ccc',
//     // borderRadius: 3,
//     alignSelf: 'center',
//     // marginVertical: 10,
//   },
//   modalText: {
//     fontSize: 20,
//     marginBottom: 15,
//     textAlign: 'center',
//   },
//   closeButton: {
//     backgroundColor: '#FF6347',
//     padding: 10,
//     borderRadius: 5,
//     alignItems: 'center',
//   },
//   backgroundTouchable: {
//     flex: 1,
//     justifyContent: 'flex-end',
//     zIndex: 2,
//   },
//   shadow: {
//     boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.9)',
//     shadowColor: 'rgba(0,0,0, .4)', // IOS
//     shadowOffset: {height: 1, width: 1}, // IOS
//     shadowOpacity: 1, // IOS
//     shadowRadius: 1, //IOS
//     backgroundColor: '#fff',
//     elevation: 2, // Android
//   },
// });

// export default BottomSheet;
import ArrowAnimation from '@/Animations/UpAndDownArrow';
import CCColors from '@/Utils/CCColors';
import {WINDOW_HEIGHT} from '@/Utils/Dimensions';
import React, {useEffect, useRef, useState} from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
  Animated,
  Dimensions,
  PanResponder,
} from 'react-native';
import Modal from 'react-native-modal';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Box from '../Box';

// const {height: screenHeight} = Dimensions.get('window');
type BottomSheetType = {
  children: JSX.Element;
  minHeight?: number;
};

const BottomSheet: React.FC = ({
  children = <></>,
  minHeight = WINDOW_HEIGHT / 2.25,
}: BottomSheetType) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const INITIAL_HEIGHT = minHeight;
  const FULL_HEIGHT = WINDOW_HEIGHT / 1.3;
  const sheetHeight = useRef(new Animated.Value(minHeight)).current;
  const pan = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(sheetHeight, {
      toValue: minHeight,
      duration: 300,
      useNativeDriver: false,
    }).start();
  }, [minHeight]);

  const panResponder = useRef(
    PanResponder.create({
      onMoveShouldSetPanResponder: () => true,
      onPanResponderGrant: () => {
        pan.setOffset(pan.__getValue());
        pan.setValue(0);
      },
      onPanResponderMove: (evt, gestureState) => {
        if (gestureState.dy < 0) {
          Animated.timing(sheetHeight, {
            toValue: minHeight - gestureState.dy,
            duration: 0,
            useNativeDriver: false,
          }).start();
        }
      },
      onPanResponderRelease: (evt, gestureState) => {
        pan.flattenOffset();
        if (gestureState.dy > 0) {
          // If dragging downward, snap back to initial height
          Animated.timing(sheetHeight, {
            toValue: minHeight,
            duration: 300,
            useNativeDriver: false,
          }).start();
        }
      },
    }),
  ).current;

  const toggleModal = () => {
    setIsVisible(!isVisible);
  };

  const handleArrowClick = () => {
    const targetHeight = isExpanded ? minHeight : FULL_HEIGHT;
    Animated.timing(sheetHeight, {
      toValue: targetHeight,
      duration: 300,
      useNativeDriver: false,
    }).start(() => {
      setIsExpanded(!isExpanded);
    });
  };

  return (
    <View style={styles.container}>
      <Modal
        isVisible={isVisible}
        style={styles.modal}
        hasBackdrop={false}
        coverScreen={false}
        backdropOpacity={0}>
        <View style={styles.backgroundTouchable}>
          <Animated.View
            style={[styles.modalContent, {height: sheetHeight}, styles.shadow]}>
            <View
            // {...panResponder.panHandlers}
            >
              <TouchableOpacity
                onPress={handleArrowClick}
                hitSlop={{
                  top: 40,
                  bottom: 40,
                  left: 40,
                  right: 40,
                }}
                style={styles.dragHandle}>
                <Ionicons
                  name={isExpanded ? 'chevron-down' : 'chevron-up'}
                  size={30}
                  color={CCColors.Grey.Grey}
                />
              </TouchableOpacity>
            </View>
            {children}
          </Animated.View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    backgroundColor: '#2196F3',
    padding: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
  },
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  modalContent: {
    backgroundColor: 'white',
    borderTopLeftRadius: 17,
    borderTopRightRadius: 17,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  dragHandle: {
    alignSelf: 'center',
  },
  modalText: {
    fontSize: 20,
    marginBottom: 15,
    textAlign: 'center',
  },
  closeButton: {
    backgroundColor: '#FF6347',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
  },
  backgroundTouchable: {
    flex: 1,
    justifyContent: 'flex-end',
    zIndex: 2,
  },
  shadow: {
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.9)',
    shadowColor: 'rgba(0,0,0, .4)', // IOS
    shadowOffset: {height: 1, width: 1}, // IOS
    shadowOpacity: 1, // IOS
    shadowRadius: 1, // IOS
    backgroundColor: '#fff',
    elevation: 2, // Android
  },
});

export default BottomSheet;
