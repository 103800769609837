import {useQuery} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

interface LeaderboardItem {
  rank: number;
  name: string;
  points?: string;
  profilePic?: string;
  rankIcon?: string | null;
  isYou?: boolean;
}

interface LeaderboardResponse {
  length: any;
  data: LeaderboardItem[];
}

export default function useGetLeaderboard(bookId) {
  return useQuery<LeaderboardResponse, Error>(
    ['leaderboard', bookId],
    async () => {
      try {
        console.log('Making request to leaderboard API...');
        const response = await ChessRestClient.get(
          `/v1/get_books_leaderboard`,
          {
            params: {book_id: bookId},
          },
        );
        console.log('Response data:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error making API request:', error);
        throw error;
      }
    },
    {
      enabled: !!bookId,
      onSuccess: data => {
        console.log('Data fetched successfully:', data);
      },
      onError: error => {
        console.error('Error fetching leaderboard:', error);
      },
    },
  );
}
