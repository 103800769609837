import Box from '@/Atoms/Box';
import React, {useEffect, useMemo, useState} from 'react';
import {styles} from './styles';
import ChessboardComponent from 'chessboard-package';
import CCPackageTableNew from '@/Atoms/CCPackageTableNew';
import CCTabs from '@/Atoms/CCTabs';
import {Pressable, View} from 'react-native';
import CCButton from '@/Atoms/CCButton';
import CCTextInput from '@/Atoms/CCTextInput';
import CCColors from '@/Utils/CCColors';
import CCText from '@/Atoms/CCText';
import SlideModal from '@/Atoms/SlideModal';
import {Chess} from 'chess.js';
import Spacer from '@/Atoms/Spacer';
import {useGetAllStudyGames} from '@/Hooks/StudyGamesListApi/useGetAllStudyGames';
import CCLoader from '@/Atoms/CCLoader';
import {formatDate, openInNewTab} from '@/Utils/CommonUtils';
import {useNavigation} from '@react-navigation/native';
import {ScreenName} from '@/navigators/StackConstants';
import useCreateStudyGame from '@/Hooks/CreateNewStudyApi/useCreateStudyGame';
import CreateNewStudy from './Component/CreateNewStudy';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';

const headers = ['Date', 'Workbook Name', 'Owner'];

const tableDataDetails = [
  [
    {name: 'date', type: 'text'},
    {name: 'game', type: 'text'},
    {name: 'members', type: 'text'},
  ],
  [
    {name: 'date', type: 'text'},
    {name: 'game', type: 'text'},
    {name: 'members', type: 'text'},
  ],
  [
    {name: 'date', type: 'text'},
    {name: 'game', type: 'text'},
    {name: 'members', type: 'text'},
  ],
];
const tableDataDetails2 = [
  [
    {name: 'date2', type: 'text'},
    {name: 'game', type: 'text'},
    {name: 'members', type: 'text'},
  ],
  [
    {name: 'date', type: 'text'},
    {name: 'game', type: 'text'},
    {name: 'members', type: 'text'},
  ],
  [
    {name: 'date', type: 'text'},
    {name: 'game', type: 'text'},
    {name: 'members', type: 'text'},
  ],
];

const TableDetails = ({
  headerTitles,
  tableData,
}: {
  headerTitles: string[];
  tableData: {name: string; type: string}[][];
}) => {
  return (
    <CCPackageTableNew
      headers={headerTitles}
      tableData={tableData}
      rowProps={{
        widthArr: [
          '24vw',
          '24vw',
          '24vw',
          '24vw',
          // '5vw',
          // '7vw',
          // '4vw',
          // '5vw',
          // '7vw',
          // '7vw',
          // '12vw',
        ],
      }}
    />
  );
};
function StudyGameList() {
  const {isMobileOrTab} = useMediaQuery();
  const [tabSelected, setTabSelected] = useState(0);
  const [openNewStudyModal, setOpenNewStudyModal] = useState(false);
  const [filter, setFilter] = useState('owner');
  // const {
  //   mutate: createNewStudy,
  //   data: newStudySuccess,
  //   error: newStudyError,
  // } = useCreateStudyGame();
  const navigation = useNavigation();
  const chess = new Chess();
  const {data, isLoading, error} = useGetAllStudyGames(filter, () => {});

  const tabDetails = useMemo(() => {
    const tableDataDetailsTemp = data?.data?.map(eachGame => {
      return [
        {name: formatDate(eachGame?.created_at), type: 'text'},
        {name: eachGame?.name, type: 'text'},
        {name: eachGame?.owner_details?.username, type: 'text'},
        {
          name: (
            <Box>
              <CCButton
                onPress={() => {
                  // openInNewTab(
                  //   `/${ScreenName.StudyDetails}?study_id=${eachGame?.slug}`,
                  // );
                  navigation?.navigate(ScreenName.StudyDetails, {
                    study_id: eachGame?.slug,
                  });
                }}>
                Open
              </CCButton>
            </Box>
          ),
        },
      ];
    });
    return [
      {
        key: 0,
        label: 'Your workbooks',
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => (
          <TableDetails
            headerTitles={headers}
            tableData={tableDataDetailsTemp || []}
          />
        ),
      },
      {
        key: 1,
        label: 'Other workbooks',
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => (
          // <Box marginHorizontal={10}>
          <TableDetails
            headerTitles={headers}
            tableData={tableDataDetailsTemp || []}
          />
          // </Box>
        ),
      },
    ];
  }, [data]);
  return (
    <Box style={styles.container}>
      <CreateNewStudy />

      <CCTabs
        tabIndex={tabSelected}
        tabItems={tabDetails}
        handleTabChange={({route}) => {
          setTabSelected(parseInt(route.key, 10));
          if (route.key === 1) {
            setFilter('participant');
          } else {
            setFilter('owner');
          }
        }}
      />
      <CCLoader loading={isLoading} />
    </Box>
  );
}

export default StudyGameList;
