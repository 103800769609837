import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import { useRef } from 'react';
import { ScrollView, View } from 'react-native';
import Entypo from 'react-native-vector-icons/Entypo';

const RatingsComponent = () => {
  const containerRef = useRef(null);
  let scrollAnimation;

  const startScrollAnimation = () => {
    const container = containerRef.current;
    if (container) {
      const distance = container.scrollWidth - container.clientWidth;
      scrollAnimation = container.animate(
        [
          { transform: `translateX(0px)` },
          { transform: `translateX(-${distance}px)` },
        ],
        {
          duration: 5000, // Adjust as needed
          iterations: Infinity,
          easing: 'linear',
        },
      );
    }
  };

  const stopScrollAnimation = () => {
    if (scrollAnimation) {
      scrollAnimation.pause();
    }
  };

  const handleMouseEnter = () => {
    stopScrollAnimation();
  };

  const handleMouseLeave = () => {
    startScrollAnimation();
  };

  return (
    <Box flexDirection="row" gap={20} marginLeft="auto" marginRight="auto">
      <Box
        gap={20}
        style={{
          width: 300,
          minHeight: 300,
          padding: 30,
          backgroundColor: CCColors.Primary.lightBrown,
        }}>
        <Box flexDirection="row" gap={10}>
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
        </Box>
        <CCText fontSize={28} style={{  lineHeight: 36 }} textAlign="auto">
          Thank you Gaurav and the CircleChess team for organizing a seamless tournament that provided a good platform to our kids and all Chess players in Bangalore.
          Each round was spot on, the activities to keep people engaged was well thought through and helpful, Sitting area and parking was comfortable and effortless.
          Thank you once again for being the leading light.
        </CCText>
        <Box flexDirection="row" marginTop={20} alignItems="center" gap={20}>
          <Box
            style={{
              width: 56,
              height: 56,
              backgroundColor: CCColors.Green,
              borderRadius: '50%',
            }}></Box>
          <Box>
            <CCText fontWeight="thunder" fontSize={16}>
              Vinod V
            </CCText>
            <CCText fontSize={14}>
              Chess Parent, Bangalore
            </CCText>
          </Box>
        </Box>
      </Box>
      <Box
        gap={20}
        style={{
          width: 300,
          minHeight: 300,
          padding: 30,
          backgroundColor: CCColors.Primary.lightBrown,
        }}>
        <Box flexDirection="row" gap={10}>
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
        </Box>
        <CCText fontSize={28} style={{  lineHeight: 36 }} textAlign="auto">
          The passion of the organizers to make it a memorable experience came through in spades. So much attention to detail in small things which makes a significant difference.
          I wish CircleChess all success and hope to see more and more offline tournaments.
        </CCText>
        <Box flexDirection="row" marginTop={20} alignItems="center" gap={20}>
          <Box
            style={{
              width: 56,
              height: 56,
              backgroundColor: CCColors.Red,
              borderRadius: '50%',
            }}></Box>
          <Box>
            <CCText fontWeight="thunder" fontSize={16}>
              Vijay Venkat
            </CCText>
            <CCText fontSize={14}>
              Chess Parent, Bangalore
            </CCText>
          </Box>
        </Box>
      </Box>
      <Box
        gap={20}
        style={{
          width: 300,
          minHeight: 300,
          padding: 30,
          backgroundColor: CCColors.Primary.lightBrown,
        }}>
        <Box flexDirection="row" gap={10}>
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
        </Box>
        <CCText fontSize={28} style={{  lineHeight: 36 }} textAlign="auto">
          Amazing platform, I am ready to take the paid version, I have never seen a middle game training module like this, you are creating puzzles on our mistakes in games and then making us think where we went wrong. Amazing, really amazing. Count me in for a platform subscription.
        </CCText>
        <Box flexDirection="row" marginTop={20} alignItems="center" gap={20}>
          <Box
            style={{
              width: 56,
              height: 56,
              backgroundColor: CCColors.Green,
              borderRadius: '50%',
            }}></Box>
          <Box>
            <CCText fontWeight="thunder" fontSize={16}>
              Dr Phani Pullela
            </CCText>
            <CCText fontSize={14}>
              Assoociate Dean
            </CCText>
          </Box>
        </Box>
      </Box>
      <Box
        gap={20}
        style={{
          width: 300,
          minHeight: 300,
          padding: 30,
          backgroundColor: CCColors.Primary.lightBrown,
        }}>
        <Box flexDirection="row" gap={10}>
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
          <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
        </Box>
        <CCText fontSize={28} style={{  lineHeight: 36 }} textAlign="auto">
          Registering through circlechess is just awesome and very simple.
          Registered for a few tournaments recently, just wanted to share the appreciation :)
        </CCText>
        <Box flexDirection="row" marginTop={20} alignItems="center" gap={20}>
          <Box
            style={{
              width: 56,
              height: 56,
              backgroundColor: CCColors.Green,
              borderRadius: '50%',
            }}></Box>
          <Box>
            <CCText fontWeight="thunder" fontSize={16}>
              Dinesh Kumar
            </CCText>
            <CCText fontSize={14}>
              Rating: 1800, Hyderabad
            </CCText>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RatingsComponent;
