import {useEffect, useState} from 'react';
import {useMutation} from '@tanstack/react-query';
import useChatRestClient from '@/Api/ChatRestClient';
import {getLocalStorage, setLocalStorage} from '@/Utils/CommonUtils';

interface PostMessageHandlerProps {
  onUserVerified: (data: any) => void;
  onVerificationError?: (error: any) => void;
}

const PostMessageHandler: React.FC<PostMessageHandlerProps> = ({
  onUserVerified,
  onVerificationError,
  children = <></>,
}) => {
  const ChatRestClient = useChatRestClient();

  const mutation = useMutation(
    async (data: {
      mobile_number: string;
      country_code: string;
      apiKey: string;
      customer_id: number;
      origin: string;
      email: string;
      username: string;
      customer_name: string;
      sex: string;
      dob: string;
      fide_id: string;
      lic_name: string;
      cdc_name: string;
    }) => {
      console.log(
        'Calling third-party verification API using ChatRestClient...',
      );
      const {
        mobile_number,
        country_code,
        apiKey,
        customer_id,
        origin,
        email,
        username,
        customer_name,
        sex,
        dob,
        fide_id,
        lic_name,
        cdc_name,
      } = data;
      if (origin?.includes('circlechess')) {
        return;
      }
      const response = await ChatRestClient.post(
        'auth/v1/extlogin',
        {
          mobile_number,
          country_code,
          origin,
          email,
          username,
          customer_name,
          sex,
          dob,
          fide_id,
          lic_name,
          cdc_name,
        },
        {
          headers: {
            'CC-API-KEY': apiKey,
            'CC-CUSTOMER-ID': customer_id,
          },
        },
      );

      return response.data;
    },
    {
      onSuccess: data => {
        if (onUserVerified && data.success) {
          onUserVerified(data);
        }
      },
      onError: error => {
        console.error('Verification failed:', error);
        if (onVerificationError) onVerificationError(error);
      },
    },
  );

  const blockViewport = () => {
    const overlay = document.createElement('div');
    overlay.id = 'block-overlay';
    overlay.style.position = 'fixed';
    overlay.style.top = 0;
    overlay.style.left = 0;
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
    overlay.style.zIndex = 9999;
    overlay.style.display = 'flex';
    overlay.style.alignItems = 'center';
    overlay.style.justifyContent = 'center';
    overlay.style.color = 'white';
    overlay.innerText =
      'Unauthorized access detected. Please contact circlechess team';
    document.body.appendChild(overlay);
  };

  const unblockViewport = () => {
    const overlay = document.getElementById('block-overlay');
    if (overlay) {
      overlay.remove();
    }
  };

  useEffect(() => {
    const handlePostMessage = async (event: MessageEvent) => {
      const parentOrigin = document?.referrer;
      console.log(
        'Message received: iframe',
        parentOrigin,
        event?.data?.type,
        event,
      );

      if (event.data && event.data.type !== 'third_party_message') {
        return;
      }
      let allowedOrigins = 'chessklub.com';
      if (!event.origin.includes(allowedOrigins)) {
        // console.error('Message from unauthorized origin:', event.origin);
        // blockViewport();
        return;
      } else {
        // unblockViewport();
      }

      const {payload} = event.data;
      const tempMobile = await getLocalStorage('third_party_mobile');
      if (
        payload &&
        payload?.mobile_number &&
        payload?.country_code &&
        tempMobile !== payload.mobile_number &&
        payload.apiKey &&
        payload.customer_id
      ) {
        console.log(
          'Extracted message payload:',
          payload.mobile_number,
          payload.apiKey,
        );

        allowedOrigins = 'chessklub.com';

        mutation.mutate({
          mobile_number: payload.mobile_number,
          country_code: payload.country_code,
          apiKey: payload.apiKey,
          customer_id: payload.customer_id,
          origin: parentOrigin,
          email: payload.email,
          username: payload.username,
          customer_name: payload.customer_name,
          sex: payload.sex,
          dob: payload.dob,
          fide_id: payload.fide_id,
          lic_name: payload.lic_name,
          cdc_name: payload.cdc_name,
        });

        setLocalStorage('third_party_mobile', payload?.mobile_number);
      } else {
        console.error('Invalid message payload:', event.data);
      }
    };

    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, [mutation]);

  return children;
};

export default PostMessageHandler;
