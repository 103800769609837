import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

// Define the type for the payload
type CreateNewMoveType = {
  parent_id: string;
  san: string;
  fen: string;
};

export default function useStudySaveAlternateMoves() {
  return useMutation(async (moveData: CreateNewMoveType) => {
    // Properly formatted payload
    const formattedPayload = {
      parent_id: moveData.parent_id,
      san: moveData.san,
      fen: moveData.fen,
      chapter_id: moveData.chapter_id,
    };

    try {
      const response = await ChessRestClient.post(
        `learning/study_games_moves/create_alternate/`,
        formattedPayload,
      );
      return response.data; // Return the data from the response
    } catch (error) {
      // Handle errors when the API call fails
      console.error('Failed to save alternate move:', error);
      throw new Error('Failed to save alternate move');
    }
  });
}

// {
//   "parent_id": 197,  // ID of the move from which the new move will branch
//   "san": "h5",       // Standard Algebraic Notation of the new move
//   "fen": "nbqkbnr/ppp2pp1/4p3/3p3p/3PP2P/8/PPP2PP1/RNBQKBNR w KQkq - 0 4", // FEN after the move
//   "move_seq": 5,     // Sequence number of the move
//   "annotation": "Develops a piece and controls the center"  // Commentary or notes on the move
// }
