/* eslint-disable react-native/no-inline-styles */
import Box from '@/Atoms/Box';
import React, {useEffect, useState, isValidElement, useMemo} from 'react';
import {MaterialIcons} from '@expo/vector-icons';
import {Image, Pressable, Text, TouchableOpacity, View} from 'react-native';
import useDashboardApi, {
  useDashboardApiQuery,
} from '@/Hooks/DashboardApi/dashboardApi';

import LottieView from 'react-native-web-lottie';
import Feather from 'react-native-vector-icons/Feather';
import GemsSummaryComponent from './component/GemsSummary';
// import {vs} from '@/Utils/Dimensions';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {styles} from './styles';
import TasksBox from './component/Tasks';
import ScoreCard from './component/Scorecard';
import useLoggedInUserStore from '@/Store/loggedInUserstore';
import GameReviewBox from './component/GameReview';
import MasterPuzzleBox from './component/MasterPuzzleBox';
import {useNavigation} from '@react-navigation/native';
import CCLoader from '@/Atoms/CCLoader';
import CCText from '@/Atoms/CCText';
import ChessboardComponent from 'chessboard-package';

import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import Ionicons from 'react-native-vector-icons/Ionicons';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import CCButton from '@/Atoms/CCButton';
import {ScreenName} from '@/navigators/StackConstants';
import {logEvent} from '@/Utils/GAEvents';
import useGAPageView from '@/navigators/useGAPageView';
import InfoIcon, {CustomInfoBox} from '@/Atoms/InfoIcon';
import Spacer from '@/Atoms/Spacer';
import {FontSizes, vs} from '@/Utils/Dimensions';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import Divider from '@/Atoms/Divider/Divider';
import CCPackageTableNew from '@/Atoms/CCPackageTableNew';
import {setLocalStorage} from '@/Utils/CommonUtils';
import {OPACITY_0_2, OPACITY_1_0, PRO_PLAN_IDS} from '@/Utils/Constants';
import {GAMEPLAY_STRIP_CONTENT} from './helper';
import {
  useNonPersistedUserPlanStore,
  useUserPlanStore,
} from '@/Store/userPlanStore';
import useGetUserActivePlanApi from '@/Hooks/UserActivePlanApi/useGetUserActivePlan';
import summaryStore from '@/Store/summaryStore';
import useChessboardColorTheme from '@/Hooks/ChessboardColorTheme/useChessboardColorTheme';
import useRunAssesment from '@/Hooks/AssessmentApi/useRunAssesment';
import useAlertModalStore from '@/Store/AlertModalStore';

// import * as dayjs from 'dayjs';

function GamePlayStripComponent({
  hoveredItemForGameplayCard,
  itemDetails,
  navigation,
}) {
  const [moveHover, setMoveHover] = useState(false);
  const {isMobileOrTab} = useMediaQuery();
  const handleHoverIn = () => {
    setMoveHover(true);
  };
  const handleHoverOut = () => {
    setMoveHover(false);
  };
  return (
    <Pressable
      onHoverIn={handleHoverIn}
      onHoverOut={handleHoverOut}
      // onHoverIn={() => setHoveredItemForGameplayCard(i?.id)}
      // onHoverOut={() => setHoveredItemForGameplayCard(0)}
      disabled={itemDetails?.disabled}
      onPress={() => {
        if (itemDetails?.screenName) {
          navigation.navigate(
            itemDetails?.screenName,
            itemDetails?.screenProps,
          );
        }
      }}
      style={[
        styles.trainerBox,
        // moveHover && styles.hoverCard,
        {
          paddingVertical: 8,
          // flex: 0.2,
          // flexDirection: 'row',
          // justifyContent: 'center',
          opacity: itemDetails?.disabled
            ? 0.5
            : hoveredItemForGameplayCard === itemDetails?.id
            ? 0.6
            : 1,
        },
      ]}>
      <Box
        type="center"
        style={{
          backgroundColor: CCColors.Primary.lightYellow,
          margin: 'auto',
          height: 'fit-content',
          borderRadius: 4,
        }}>
        <View
          style={{
            // height: 40,
            // width: 40,
            margin: 8,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {itemDetails?.icon ? (
            itemDetails?.icon?.(moveHover)
          ) : (
            <LottieView
              source={require('../../Assets/Animation/Bullet.json')}
              isPlaying={hoveredItemForGameplayCard === itemDetails?.id}
              // autoPlay
              height={55}
              width={55}
              autoPlay={false}
              loop={true}
              style={{
                height: 55,
                width: 55,
              }}
              // loop={hoveredItemForGameplayCard === itemDetails?.id}
              // hover
            />
          )}
        </View>
      </Box>
      <Spacer spacing={15} />
      <View
        style={{
          margin: isMobileOrTab ? 5 : 'auto',
          height: 'fit-content',
        }}>
        <CCText
          textAlign="center"
          fontSize={isMobileOrTab ? FontSizes[12] : FontSizes[16]}
          fontWeight="bold">
          {itemDetails?.header}
        </CCText>
        <Spacer spacing={5} />
        <CCText
          textAlign="center"
          fontSize={isMobileOrTab ? FontSizes[10] : FontSizes[14]}
          color={updateColorWithOpacity(CCColors.TextColor.Primary, 0.8)}>
          {itemDetails?.desc}
        </CCText>
      </View>
    </Pressable>
  );
}

function Dashboard({data: summaryData, isLoading}) {
  // const [avatar, setAvatar] = useState(null);
  // const [gameForReview, setGameForReview] = useState(null);
  // const [puzzle, setPuzzle] = useState(null);
  // const [scorecards, setScorecards] = useState(null);
  // const [netCCPoints, setNetCCPoints] = useState(0);
  // const [masterPuzzle, setMasterPuzzle] = useState(null);
  const openAlertModal = useAlertModalStore(state => state.openModal);

  const handleOnSuccess = (responseData: any) => {
    if (responseData?.data?.is_permitted === false) {
      setSubscriptionModalOpen(true);
    } else if (responseData?.data?.success) {
      // setSubscriptionModalOpen(true);
      openAlertModal({
        header: 'Info',
        bodyTxt: 'Your assessment is started and will be available in a while',
      });
    }
    // setAvatar(responseData.data?.avatar);
    // if (responseData.data?.game) {
    //   setGameForReview(responseData.data?.game);
    // }
    // setPuzzle(responseData.data?.puzzle);
    // setScorecards(responseData.data?.scorecards);
    // setUserEmail(responseData.data?.user.email);
    // setUserAvatar(responseData.data?.avatar.avatar);
    // setUserIcon(responseData.data?.avatar.avatarDP);
    // setUserGems(responseData.data?.user.gems);
    // setUserId(responseData.data?.user.username);
    // setUserLevel(responseData.data?.avatar.level);
    // setUserProgress(responseData.data?.avatar.progress);
    // setNetCCPoints(responseData.data?.user.net_score);
    // setMasterPuzzle(responseData.data?.master_puzzle);
  };

  const navigation = useNavigation();
  useGAPageView(ScreenName.Dashboard);
  const {isMobile, isTablet, isMobileOrTab} = useMediaQuery();

  const setUserEmail = useLoggedInUserStore(state => state.setEmail);
  const setUserGems = useLoggedInUserStore(state => state.setGems);
  const setUserId = useLoggedInUserStore(state => state.setUserId);
  const setUserLevel = useLoggedInUserStore(state => state.setLevel);
  const setUserAvatar = useLoggedInUserStore(state => state.setAvatar);
  const setUserIcon = useLoggedInUserStore(state => state.setIcon);
  const setStreakDetailsSummary = useLoggedInUserStore(
    state => state.setStreakDetailsSummary,
  );
  const setStreakConfig = useLoggedInUserStore(state => state.setStreakConfig);
  const chessboardColorTheme = useChessboardColorTheme();

  const [hoveredItemForGameplayCard, setHoveredItemForGameplayCard] =
    useState(0);
  const handleOnError = (error: any) => {
    console.log('Error occurred in Dashboard API: ', error);
  };
  const [rating, setRating] = useState(false);
  const [calculationTooltip, setCalcultaionTooltip] = useState('');
  const [endgameTooltip, setEndgameTooltip] = useState('');
  const [middleGameTooltip, setMiddleGameTooltip] = useState('');
  const [openingTooltip, setOpeningTooltip] = useState('');
  const [assessmentDate, setAssessmentDate] = useState('');
  useEffect(() => {
    setCalcultaionTooltip(
      summaryData?.data?.assessment?.score_tooltips?.calculation_depth_tooltip,
    );
    setEndgameTooltip(
      summaryData?.data?.assessment?.score_tooltips?.endgame_tooltip,
    );
    setMiddleGameTooltip(
      summaryData?.data?.assessment?.score_tooltips?.middlegame_tooltip,
    );
    setOpeningTooltip(
      summaryData?.data?.assessment?.score_tooltips?.opening_tooltip,
    );
    setAssessmentDate(
      summaryData?.data?.assessment?.created_time?.slice(0, 10),
    );
  }, [summaryData]);

  // const {mutate: getDashboardApi} = useDashboardApi(
  //   handleOnSuccessDashboardData,
  //   handleOnFailedDashboardData,
  // );
  const {setSubscriptionModalOpen} = useNonPersistedUserPlanStore();

  const {
    mutate: runAssessment,
    data: runAssessmentData,
    error: runAssessmentError,
    isLoading: runAssessmentLoading,
  } = useRunAssesment(handleOnSuccess, handleOnError);

  // const {
  //   mutate: loadDashboard,
  //   data: validatedData,
  //   error: validatedError,
  //   isLoading,
  // } = useDashboardApi(handleOnSuccess, handleOnError);

  // const {isLoading, data} = useDashboardApiQuery();
  const data = summaryData?.data;
  // const {data, isUserLoggedIn, loading: isLoading} = summaryStore();
  useEffect(() => {
    const updateLocalStorage = async () => {
      try {
        await setLocalStorage(
          'user_details',
          JSON.stringify(data?.user),
          true,
          () => {},
        );
      } catch (error) {
        console.error('Failed to set local storage:', error);
      }
    };
    if (data?.streak_details) {
      setStreakConfig(data?.streak_details);
    }
    if (data?.streak_details_summary) {
      setStreakDetailsSummary(data?.streak_details_summary);
    }
    if (data?.avatar) {
      setUserAvatar(data?.avatar);
    }
    if (data?.user) {
      updateLocalStorage();
    }
    if (data?.user?.gameplay_rating) {
      setRating(data?.user?.gameplay_rating);
    }
  }, [data]);

  const userPlanStore = useUserPlanStore();
  const isPremiumUser = useMemo(
    () =>
      PRO_PLAN_IDS?.includes(
        userPlanStore?.planDetails?.user_subscription_details?.fields?.plan_id,
      ),
    [userPlanStore?.planDetails],
  );
  const handleOnSuccessGetPlan = data => {
    let planName = data?.data?.user_subscription_plan_name;
    // planName = planName.split(' ')[0];
    let plan = 'Free';
    if (planName.toLowerCase().includes('aspiring learners')) {
      plan = 'aspiringLearners';
    } else if (planName.toLowerCase().includes('aspiring champions')) {
      plan = 'aspiringChampions';
    }
    userPlanStore.setActivePlan(plan);
  };
  const handleOnErrorGetPlan = error => {
    console.log('error while fetching user active plan', error);
  };
  const {mutate: getActivePlan, data: planData} = useGetUserActivePlanApi(
    handleOnSuccessGetPlan,
    handleOnErrorGetPlan,
  );

  useEffect(() => {
    getActivePlan();
  }, []);
  if (isLoading) {
    return <CCLoader loading bgWhite />;
  }
  const avatar = data?.avatar;
  const netCCPoints = data?.user?.net_score;
  const gameForReview = data?.game;
  const masterPuzzle = data?.master_puzzle;
  const puzzle = data?.puzzle;
  const scorecards = data?.scorecards;
  const parts = data?.master_puzzle.username
    .split('|')
    .map((part: string) => part.trim());

  const perfStatsAgg = data?.perf_stats?.last_n_games_stats.reduce(
    (acc, current) => {
      return {
        blunders: acc?.blunders + current?.blunders,
        blunder_free_games:
          current.blunders == 0
            ? acc?.blunder_free_games + 1
            : acc?.blunder_free_games,
        blunders_in_less_than_ten_secs:
          acc?.blunders_in_less_than_ten_secs +
          current?.blunders_in_less_than_ten_secs,
        accuracy: acc?.accuracy + current?.accuracy,
      };
    },
    {
      blunders: 0,
      blunder_free_games: 0,
      blunders_in_less_than_ten_secs: 0,
      accuracy: 0,
    },
  );

  const puzzleOnFirstTryScore = Math.floor(
    (data?.perf_stats.puzzles_on_first_try_last_n.reduce((count, current) => {
      return current != -1 ? count + 1 : count;
    }, 0) *
      100) /
      data?.perf_stats.puzzles_on_first_try_last_n.length,
  );

  const perfStatsLen = data?.perf_stats.last_n_games_stats?.length;
  const perfStatsAvg = {
    blunder_free_games: Math.floor(
      (perfStatsAgg?.blunder_free_games * 100) / perfStatsLen,
    ),
    blunders_in_less_than_ten_secs: Math.floor(
      (perfStatsAgg?.blunders_in_less_than_ten_secs * 100) /
        perfStatsAgg?.blunders,
    ),
    accuracy: Math.floor(perfStatsAgg?.accuracy / perfStatsLen),
  };

  function goal(
    titleText,
    subTitleText,
    infoText,
    value,
    opts = {},
    target: Number | null = null,
    infoIcon = <></>,
  ) {
    const {isFirst = null, isLast = null, action = null} = opts;
    console.log(target, 'target');
    return (
      <View
        style={[
          isMobileOrTab ? styles.goalMobile : styles.goal,
          !isMobileOrTab && isFirst && {paddingLeft: 0},
          !isMobileOrTab && isLast && {borderRightWidth: 0},
        ]}>
        <View style={styles.goalHeader}>
          {infoIcon && <Box style={styles.goalIcon}>{infoIcon}</Box>}
          {/* <CCText style={[styles.goalTitle, {...OPACITY}]}>{titleText}</CCText> */}
          <InfoIcon style={styles.goalInfoIcon} text={infoText}></InfoIcon>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 30,
          }}>
          <CCText style={[styles.goalNumber, {...OPACITY}]}>
            {value}
            {value != '-' && (
              <CCText
                fontWeight="500"
                size={12}
                style={{paddingLeft: 2, ...OPACITY}}
                color={CCColors.TextColor.Grey}>
                %
              </CCText>
            )}
          </CCText>
          {target && (
            <CCText style={OPACITY} fontSize={12}>
              🎯 Target: {target}
              {value != '-' && (
                <CCText
                  fontWeight="500"
                  fontSize={12}
                  style={{paddingLeft: 2}}
                  color={CCColors.TextColor.Grey}>
                  %
                </CCText>
              )}
            </CCText>
          )}
          {action && (
            <CCButton
              style={styles.goalAction}
              onPress={action.handler}
              contentContainerStyle={{
                paddingVertical: 6,
                paddingHorizontal: 12,
              }}>
              <CCText fontWeight="500" fontSize={14}>
                {action.text}
              </CCText>
            </CCButton>
          )}
        </View>
        <View>
          <CCText style={[styles.goalTitle, {...OPACITY}]}>{titleText}</CCText>
          {/* <InfoIcon style={styles.goalInfoIcon} text={infoText}></InfoIcon> */}
          <CCText
            style={[
              styles.goalSubTitle,
              isMobileOrTab && styles.goalSubTitleMobile,
            ]}>
            {subTitleText}
          </CCText>
        </View>
      </View>
    );
  }

  function headerColTextWithUnderline(
    el,
    i = 0,
    flex = 0.3,
    restart = false,
    end = false,
    tempTextStyle = {},
  ) {
    const marginLeft = restart ? 16 : 0;
    const marginRight = end ? 16 : 0;
    return (
      <View
        style={[
          {
            flex: flex,
            marginLeft: marginLeft,
            marginRight: marginRight,
          },
        ]}>
        {isValidElement(el) ? (
          <Box>{el}</Box>
        ) : (
          <CCText
            fontWeight="500"
            style={[styles.headerColText, {color: CCColors.Primary.Dark}]}
            fontSize={12}>
            {el}
          </CCText>
        )}
      </View>
    );
  }

  function headerColText(el, i = 0, flex = 0.3) {
    return (
      <View style={{flex: flex}}>
        {isValidElement(el) ? (
          el
        ) : (
          <CCText fontWeight="500" style={styles.headerColText}>
            {el}
          </CCText>
        )}
      </View>
    );
  }

  function colText(text, flex = 0.3) {
    return (
      <View
        style={[
          isMobileOrTab ? {} : styles.mL16,
          {
            flex: flex,
            paddingVertical: 16,
          },
        ]}>
        <CCText fontWeight="500" style={isMobileOrTab ? {} : styles.mL2}>
          {text}
        </CCText>
      </View>
    );
  }

  const handleGameplayRedirection = () => {
    navigation.navigate(ScreenName.PlayChess);
  };

  const OPACITY =
    data?.user.learning_onboarding_status === 2 ? OPACITY_0_2 : OPACITY_1_0;

  return (
    <View style={[styles.container, isMobileOrTab && styles.containerMobile]}>
      <CCLoader loading={runAssessmentLoading} />
      {/* Top card with message of displaying information to be shown only if the onboarding status = 2, ie. games have not all been imported yet */}
      {data.user.learning_onboarding_status === 2 && (
        <Box
          style={{flexDirection: 'row', flexBasis: '100%'}}
          onPress={handleGameplayRedirection}>
          <View
            style={[
              styles.card,
              styles.goalsCard,
              (isMobile || isTablet) && styles.mobileCard,
              {
                backgroundColor: updateColorWithOpacity(
                  CCColors.Primary.Default,
                  0.2,
                ),
                alignItems: 'center',
              },
            ]}>
            <AntDesign
              name="infocirlceo"
              size={20}
              color={CCColors?.Red}
              // style={{marginRight: 20}}
            />
            <Spacer spacing={10} horizontal />
            <CCText
              style={{color: CCColors.Primary.Brown}}
              fontSize={FontSizes[14]}>
              Welcome to CircleChess! We are analysing your games and getting
              your learning dashboard ready! This should take about couple of
              hours and we will send you notification via whatsapp when it
              completes. In the meantime, you can play games, analyse offline
              games, use our trainers to get better!
            </CCText>
          </View>
        </Box>
      )}

      <View style={{flexDirection: 'row', flexBasis: '100%'}}>
        {perfStatsLen > 0 && (
          <View
            style={[
              styles.card,
              styles.goalsCard,
              (isMobile || isTablet) && styles.mobileCard,
            ]}>
            <View
              style={
                isMobileOrTab ? styles.goalSectionMobile : styles.goalSection
              }>
              <View style={[styles.cardHeading]}>
                <MaterialCommunityIcon
                  name="bullseye-arrow"
                  size={20}
                  color="#fff"
                />
                <View style={styles.cardHeadingTextContainer}>
                  <CCText
                    style={[
                      styles.cardHeadingText,
                      {paddingVertical: 0, ...OPACITY},
                    ]}>
                    Your Goals Progress
                  </CCText>
                  <CCText
                    style={[
                      styles.cardHeadingSubText,
                      isMobileOrTab && styles.cardHeadingSubTextMobile,
                      {...OPACITY},
                    ]}>
                    See how you’re performing against your goals
                  </CCText>
                </View>
              </View>
              <View
                style={
                  isMobileOrTab
                    ? styles.goalsMobileContainer
                    : styles.goalsContainer
                }>
                {goal(
                  'Blunder free games',
                  'last 10 games',
                  'Percentage of your last 10 games where you committed zero blunders',
                  `${perfStatsAvg?.blunder_free_games}`,
                  {
                    isFirst: true,
                  },
                  null,
                  <Image
                    source={require('@/Assets/blunderDashboardQuestion.svg')}
                    style={{height: 25, width: 25}}
                  />,
                )}
                {goal(
                  'Blunders under 10s',
                  'last 10 games',
                  'Percentage of your last 10 blunders where you spent less than 10 seconds before making the move',
                  isNaN(perfStatsAvg.blunders_in_less_than_ten_secs)
                    ? '-'
                    : `${perfStatsAvg.blunders_in_less_than_ten_secs}`,
                  undefined,
                  undefined,
                  <Image
                    source={require('@/Assets/reset10Icon.svg')}
                    style={{height: 25, width: 25}}
                  />,
                )}
                {goal(
                  'Positions on 1st try',
                  data?.perf_stats.puzzles_on_first_try_last_n.length > 0
                    ? `last ${data?.perf_stats.puzzles_on_first_try_last_n.length} positions`
                    : '',
                  'Percentage of the last 10 positions you solved in the first attempt!',
                  isNaN(puzzleOnFirstTryScore)
                    ? '-'
                    : `${puzzleOnFirstTryScore}`,
                  isNaN(puzzleOnFirstTryScore) && {
                    action: {
                      text: 'Start solving',
                      handler: () => {
                        navigation.navigate(ScreenName.Puzzles);
                      },
                    },
                  },
                  undefined,
                  <MaterialCommunityIcon
                    name={'puzzle-outline'}
                    size={25}
                    color={CCColors.TextColor.Primary}
                  />,
                )}
                {goal(
                  'Accuracy in games',
                  'last 10 games',
                  'Your average accuracy in last 10 games',
                  `${perfStatsAvg.accuracy}`,
                  {
                    isLast: true,
                  },
                  data?.target_accuracy,
                  <Feather
                    name={'target'}
                    size={25}
                    color={CCColors.TextColor.Primary}
                  />,
                )}
              </View>
              {false && (
                <Box style={styles.reportsStrip}>
                  <Box type="row-flex-start">
                    <Box style={styles.circleContainer}>
                      <MaterialIcons name="bar-chart" size={25} color="#fff" />
                    </Box>
                    <Spacer spacing={10} horizontal />
                    <CCText>
                      Dive deep into a comprehensive analysis of your gameplay
                    </CCText>
                  </Box>
                  <CCButton
                    onPress={() => {
                      navigation.navigate(ScreenName.Reports);
                    }}>
                    <CCText>View reports</CCText>
                  </CCButton>
                </Box>
              )}
            </View>

            <View
              style={
                isMobileOrTab
                  ? styles.assessmentSectionMobile
                  : styles.assessmentSection
              }>
              <View style={[styles.cardHeading]}>
                <MaterialCommunityIcon
                  style={[styles.cardHeadingIcon, {backgroundColor: '#67B3E1'}]}
                  name="chart-line"
                  size={20}
                  color="#fff"
                />
                <CCText style={[styles.cardHeadingText, {...OPACITY}]}>
                  Last {data?.assessment?.games_count || 25}-Games Assessment
                </CCText>
              </View>
              <View style={{flexGrow: 1, justifyContent: 'space-between'}}>
                <View style={styles.assessmentScoreContainer}>
                  <View style={styles.assessmentMainScore}>
                    <CustomInfoBox
                      text={calculationTooltip}
                      customView={
                        <>
                          <CCText
                            style={[styles.assessmentScore, {...OPACITY}]}>
                            {Number(data?.assessment?.score?.calculation_depth)
                              ? data?.assessment?.score?.calculation_depth.toFixed(
                                  2,
                                )
                              : '-'}
                          </CCText>
                          <CCText
                            style={[
                              styles.assessmentBottomLabel,
                              {...OPACITY},
                            ]}>
                            Calculation
                          </CCText>
                        </>
                      }
                    />
                  </View>
                  <View style={styles.assessmentMainScore}>
                    <CustomInfoBox
                      text={openingTooltip}
                      customView={
                        <>
                          <CCText
                            style={[styles.assessmentScore, {...OPACITY}]}>
                            {Math.floor(data?.assessment?.score?.opening) || 0}
                            <CCText
                              fontWeight="500"
                              size={12}
                              color={CCColors.TextColor.Grey}>
                              %
                            </CCText>
                          </CCText>
                          <CCText
                            style={[
                              styles.assessmentBottomLabel,
                              {...OPACITY},
                            ]}>
                            Opening
                          </CCText>
                        </>
                      }
                    />
                  </View>
                  <View style={styles.assessmentMainScore}>
                    <CustomInfoBox
                      text={middleGameTooltip}
                      customView={
                        <>
                          <CCText
                            style={[styles.assessmentScore, {...OPACITY}]}>
                            {Math.floor(data?.assessment?.score?.middlegame) ||
                              0}
                            <CCText
                              fontWeight="500"
                              size={12}
                              color={CCColors.TextColor.Grey}>
                              %
                            </CCText>
                          </CCText>
                          <CCText
                            style={[
                              styles.assessmentBottomLabel,
                              {...OPACITY},
                            ]}>
                            Middlegame
                          </CCText>
                        </>
                      }
                    />
                  </View>
                  <View
                    style={[styles.assessmentMainScore, {borderRightWidth: 0}]}>
                    <CustomInfoBox
                      text={endgameTooltip}
                      customView={
                        <>
                          <CCText
                            style={[styles.assessmentScore, {...OPACITY}]}>
                            {isNaN(data?.assessment?.score?.endgame)
                              ? data?.assessment?.score?.endgame || 0
                              : Math.floor(data?.assessment?.score?.endgame) ||
                                0}
                            <CCText
                              fontWeight="500"
                              size={12}
                              color={CCColors.TextColor.Grey}>
                              %
                            </CCText>
                          </CCText>
                          <CCText
                            style={[
                              styles.assessmentBottomLabel,
                              {...OPACITY},
                            ]}>
                            Endgame
                          </CCText>
                        </>
                      }
                    />
                  </View>
                </View>
                {!isPremiumUser && (
                  <View>
                    <CCButton
                      type="none"
                      style={{}}
                      onPress={() => {
                        openAlertModal({
                          header: 'Confirm',
                          bodyTxt:
                            'Are you sure you want to run the assessment?',
                          buttonText: 'Yes', // Custom button text
                          handleConfirmation: () => {
                            runAssessment();
                          },
                        });
                      }}
                      contentContainerStyle={{
                        paddingHorizontal: 0,
                        paddingVertical: 8,
                        justifyContent: 'space-between',
                        flexDirection: isMobileOrTab ? 'column' : 'row',
                      }}>
                      <CCText
                        fontWeight="500"
                        style={[{marginRight: 16, ...OPACITY}]}>
                        <CCText
                          fontWeight="500"
                          style={[
                            {
                              marginRight: 8,
                              alignSelf: 'flex-start',
                              ...OPACITY,
                            },
                          ]}
                          color="#67B3E1">
                          ▪
                        </CCText>
                        Run assessment
                      </CCText>
                      <MaterialCommunityIcon name="arrow-top-right" size={20} />
                    </CCButton>
                    {/* <CCText
                    fontWeight="500"
                    size={12}
                    textAlign="right"
                    color={CCColors.TextColor.Grey}>
                    Updated: 1 day ago
                  </CCText> */}
                  </View>
                )}
                <View>
                  <CCButton
                    type="none"
                    style={{}}
                    onPress={() => {
                      if (data?.user.learning_onboarding_status === 1)
                        navigation.navigate(ScreenName.AssessmentReport, {
                          id: data?.assessment.id,
                          openingGamePercentage:
                            data?.assessment?.score?.opening,
                          middleGamePercentage:
                            data?.assessment?.score?.middlegame,
                          endGamePercentage: data?.assessment?.score?.endgame,
                          calculationDepthPercentage:
                            data?.assessment?.score?.calculation_depth,
                        });
                    }}
                    contentContainerStyle={{
                      paddingHorizontal: 0,
                      paddingVertical: 8,
                      justifyContent: 'space-between',
                      flexDirection: isMobileOrTab ? 'column' : 'row',
                    }}>
                    <CCText
                      fontWeight="500"
                      style={[{marginRight: 16, ...OPACITY}]}>
                      <CCText
                        fontWeight="500"
                        style={[
                          {marginRight: 8, alignSelf: 'flex-start', ...OPACITY},
                        ]}
                        color="#67B3E1">
                        ▪
                      </CCText>
                      Full report
                    </CCText>
                    <MaterialCommunityIcon name="arrow-top-right" size={20} />
                  </CCButton>
                  <CCText
                    fontWeight="500"
                    size={12}
                    textAlign="right"
                    color={CCColors.TextColor.Grey}>
                    Last updated on {assessmentDate}
                  </CCText>
                </View>
              </View>
            </View>
          </View>
        )}
      </View>

      {/* <View style={styles.gameplayCards}>
        {GAMEPLAY_STRIP_CONTENT?.map(i => (
          <GamePlayStripComponent
            hoveredItemForGameplayCard={hoveredItemForGameplayCard}
            itemDetails={i}
            navigation={navigation}
          />
        ))}
      </View> */}
      {/* <View style={[styles.card, styles.reviewGameCard]}>
        <View
          style={[
            styles.cardHeading,
            {justifyContent: 'space-between', flexWrap: 'wrap'},
          ]}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <MaterialCommunityIcon
              style={[
                styles.cardHeadingIcon,
                {backgroundColor: CCColors.Primary.Aqua},
              ]}
              name="cursor-default-click-outline"
              size={20}
              color="#fff"
            />
            <CCText style={[styles.cardHeadingText, OPACITY]}>
              Intuition Trainer
            </CCText>
          </View>
          {!isMobileOrTab && (
            <CCButton
              onPress={() => {
                navigation.navigate('VisualisationTrainer', {
                  selected: 'intuition',
                });
              }}
              style={styles.reviewGameAction}
              contentContainerStyle={{
                paddingRight: 8,
                paddingVertical: 8,
                paddingLeft: 32,
              }}>
              <View
                style={{
                  flex: 1,
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <CCText style={[styles.reviewGameActionText, OPACITY]}>
                  Start Training
                </CCText>
              </View>
            </CCButton>
          )}
        </View>
        <View
          style={isMobileOrTab ? {} : {display: 'flex', flexDirection: 'row'}}>
          <View style={isMobileOrTab ? {width: '100%'} : {flex: 0.5}}>
            <ChessboardComponent
              disabled
              // boardOrientation={data?.game.color?.toLowerCase()}
              position={'5q2/4k3/1n6/8/2N5/4Q3/8/3K4 b - - 0 1'}
            />
          </View>
          <View style={styles.reviewGameDetails}>
            <View
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                flex: 1,
                justifyContent: 'space-evenly',
              }}>
              <CCText>
                Analyze your moves in detail, rectify your mistakes and earn
                100pts.
              </CCText>
              <View style={styles.reviewGameStatHeading}>
                <FontAwesome5
                  style={styles.reviewGameStatIcon}
                  name="user"
                  size={16}
                  color={CCColors.Red}
                />
                <CCText style={styles.intuitionTrainerHeadingText}>
                  Think like a grand master
                </CCText>
              </View>
              <View style={styles.reviewGameStatHeading}>
                <FontAwesome5
                  style={styles.reviewGameStatIcon}
                  name="user"
                  size={16}
                  color={CCColors.Red}
                />
                <CCText style={styles.intuitionTrainerHeadingText}>
                  Guest grand master’s move
                </CCText>
              </View>
              <View style={styles.reviewGameStatHeading}>
                <FontAwesome5
                  style={styles.reviewGameStatIcon}
                  name="user"
                  size={16}
                  color={CCColors.Red}
                />
                <CCText style={styles.intuitionTrainerHeadingText}>
                  Learn from master’s game
                </CCText>
              </View>

              {isMobileOrTab && (
                <Box style={{width: '100%'}}>
                  <Spacer spacing={10} />
                  <CCButton
                    onPress={() => {
                      navigation.navigate(ScreenName.ChessAnalysis, {
                        game_id: data?.game.uuid,
                      });
                    }}
                    style={styles.reviewGameAction}
                    contentContainerStyle={{
                      paddingRight: 8,
                      paddingVertical: 8,
                      paddingLeft: 32,
                    }}>
                    <View
                      style={{
                        flex: 1,
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <CCText style={styles.reviewGameActionText}>
                        Review
                      </CCText>
                      <View style={styles.reviewGameActionCoinsContainer}>
                        <FontAwesome5 name="coins" size={16} color="#D1AB41" />
                        <CCText
                          fontSize="12"
                          fontWeight="500"
                          style={{marginLeft: 8, lineHeight: 16}}>
                          Upto +
                          {(data?.game?.score?.total_puzzles -
                            data?.game?.score?.puzzles_solved) *
                            5 +
                            50}
                          pts
                        </CCText>
                      </View>
                    </View>
                  </CCButton>
                </Box>
              )}
            </View>
          </View>
        </View>
      </View> */}
      <View
        style={[
          styles.card,
          styles.reviewGameCard,
          isMobileOrTab && styles.reviewGameCardMobile,
        ]}>
        <View
          style={[
            styles.cardHeading,
            {justifyContent: 'space-between', flexWrap: 'wrap'},
          ]}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <MaterialCommunityIcon
              style={[styles.cardHeadingIcon, {backgroundColor: CCColors.Red}]}
              name="magnify"
              size={20}
              color="#fff"
            />
            <CCText style={[styles.cardHeadingText, OPACITY]}>
              Review game
            </CCText>
          </View>
          {!isMobileOrTab && (
            <CCButton
              onPress={() => {
                navigation.navigate(ScreenName.ChessAnalysis, {
                  game_id: data?.game.uuid,
                });
              }}
              style={[
                styles.reviewGameAction,
                isMobileOrTab && styles.reviewGameActionMobile,
              ]}
              contentContainerStyle={{
                paddingRight: 8,
                paddingVertical: 8,
                paddingLeft: 32,
              }}>
              <View
                style={{
                  flex: 1,
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <CCText style={[styles.reviewGameActionText, OPACITY]}>
                  Review
                </CCText>
                <View style={styles.reviewGameActionCoinsContainer}>
                  <FontAwesome5 name="coins" size={16} color="#D1AB41" />
                  <CCText
                    fontSize="12"
                    fontWeight="500"
                    style={{marginLeft: 8, lineHeight: 16}}>
                    Upto +
                    {(data?.game?.score?.total_puzzles -
                      data?.game?.score?.puzzles_solved) *
                      5 +
                      50}
                    pts
                  </CCText>
                </View>
              </View>
            </CCButton>
          )}
        </View>
        <View
          style={isMobileOrTab ? {} : {display: 'flex', flexDirection: 'row'}}>
          <View style={isMobileOrTab ? {width: '100%'} : {flex: 0.5}}>
            <ChessboardComponent
              disabled
              boardOrientation={data.game.color?.toLowerCase()}
              position={data.game.fen}
              colorTheme={chessboardColorTheme}
            />
          </View>
          <View
            style={[
              styles.reviewGameDetails,
              isMobileOrTab && styles.reviewGameDetailsMobile,
            ]}>
            <View
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                flex: 1,
                justifyContent: 'space-between',
              }}>
              <View
                style={{
                  display: 'flex',
                }}>
                <View style={styles.reviewGameStat}>
                  <View style={styles.reviewGameStatHeading}>
                    <FontAwesome5
                      style={styles.reviewGameStatIcon}
                      name="user"
                      size={16}
                      color={CCColors.Red}
                    />
                    <CCText style={styles.reviewGameStatHeadingText}>
                      Opponent
                    </CCText>
                  </View>
                  <CCText style={styles.reviewGameStatText}>
                    {data?.game.opponent} ({data?.game.opponent_rating})
                  </CCText>
                </View>

                <View style={styles.reviewGameScoreStat}>
                  <CCText style={styles.reviewGameScoreStatHeadingText}>
                    Accuracy
                  </CCText>
                  <CCText style={styles.reviewGameScoreStatText}>
                    {data?.game?.accuracy?.toFixed(1)}
                  </CCText>
                </View>
                <View style={styles.reviewGameScoreStat}>
                  <CCText style={styles.reviewGameScoreStatHeadingText}>
                    First attempt positions solved
                  </CCText>
                  <CCText style={styles.reviewGameScoreStatText}>
                    {data?.game?.score?.first_attempt_bonus_puzzles}
                  </CCText>
                </View>
                <View style={styles.reviewGameScoreStat}>
                  <CCText style={styles.reviewGameScoreStatHeadingText}>
                    Blunders committed
                  </CCText>
                  <CCText style={styles.reviewGameScoreStatText}>
                    {data?.game?.score?.no_of_blunders}
                  </CCText>
                </View>
                <View style={styles.reviewGameScoreStat}>
                  <CCText style={styles.reviewGameScoreStatHeadingText}>
                    Quick Blunders (&lt;10 secs thought)
                  </CCText>
                  <CCText style={styles.reviewGameScoreStatText}>
                    {data?.game?.score?.blunder_in_less_than_ten_secs}
                  </CCText>
                </View>
                {data?.game?.score?.net_score > 0 && (
                  <View style={[styles.reviewGameScoreStat, styles.top12]}>
                    <CCText
                      style={[
                        styles.reviewGameScoreStatHeadingText,
                        {color: CCColors.Green},
                      ]}>
                      You have scored:
                    </CCText>
                    <CCText
                      style={[
                        styles.reviewGameScoreStatText,
                        {color: CCColors.Green},
                      ]}>
                      +{data?.game?.score?.net_score}pts
                    </CCText>
                  </View>
                )}
                {data?.game?.score?.net_score < 0 && (
                  <View style={[styles.reviewGameScoreStat, styles.top12]}>
                    <CCText
                      style={[
                        styles.reviewGameScoreStatHeadingText,
                        {color: CCColors.Red},
                      ]}>
                      You have lost:
                    </CCText>
                    <CCText
                      style={[
                        styles.reviewGameScoreStatText,
                        {color: CCColors.Red},
                      ]}>
                      {data?.game?.score?.net_score}pts
                    </CCText>
                  </View>
                )}
                <View style={styles.reviewGameScoreStat}>
                  <CCText style={styles.reviewGameScoreStatHeadingText}>
                    Earn upto &nbsp;
                    {(data?.game?.score?.total_puzzles -
                      data?.game?.score?.puzzles_solved) *
                      5 +
                      50}{' '}
                    pts.
                  </CCText>
                </View>
              </View>
              {isMobileOrTab && (
                <Box style={{width: '100%'}}>
                  <Spacer spacing={10} />
                  <CCButton
                    onPress={() => {
                      navigation.navigate(ScreenName.ChessAnalysis, {
                        game_id: data?.game.uuid,
                      });
                    }}
                    style={styles.reviewGameAction}
                    contentContainerStyle={{
                      paddingRight: 8,
                      paddingVertical: 8,
                      paddingLeft: 32,
                    }}>
                    <View
                      style={{
                        flex: 1,
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <CCText style={styles.reviewGameActionText}>
                        Review
                      </CCText>
                      <View style={styles.reviewGameActionCoinsContainer}>
                        <FontAwesome5 name="coins" size={16} color="#D1AB41" />
                        <CCText
                          fontSize="12"
                          fontWeight="500"
                          style={{marginLeft: 8, lineHeight: 16}}>
                          Upto +
                          {(data?.game?.score?.total_puzzles -
                            data?.game?.score?.puzzles_solved) *
                            5 +
                            50}
                          pts
                        </CCText>
                      </View>
                    </View>
                  </CCButton>
                </Box>
              )}
            </View>
          </View>
        </View>
      </View>
      <Box
        flex={isMobileOrTab ? 1 : 0.5}
        style={{
          justifyContent: 'space-between',
          paddingHorizontal: 5,
          paddingVertical: 8,
        }}>
        <Box
          type="row-center-between"
          style={{
            height: '34%',
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
          }}>
          <Box
            onPress={() => navigation.navigate(ScreenName.PlayChess)}
            style={[
              styles.card,
              {
                height: '100%',
                width: '50%',
                justifyContent: 'space-between',
                cursor: 'pointer',
              },
              isMobileOrTab ? {marginHorizontal: 5, paddingHorizontal: 5} : {},
            ]}>
            {/* <Box type="row-center-between"> */}
            <Box
              type="row-center-between"
              style={[styles.upperBox, isMobileOrTab && styles.upperBoxMobile]}>
              <CCText
                style={[
                  styles.assessmentMainScore2,
                  {paddingRight: isMobileOrTab ? 5 : 8},
                ]}
                fontWeight="bold"
                fontSize={isMobileOrTab ? FontSizes[12] : FontSizes[16]}>
                Play Online
              </CCText>
              <Spacer spacing={10} />

              <CCText
                style={{
                  paddingLeft: isMobileOrTab ? 5 : 8,
                  width: '80%',
                }}
                fontSize={isMobileOrTab ? FontSizes[10] : FontSizes[12]}
                color={updateColorWithOpacity(CCColors.TextColor.Primary, 0.8)}>
                Randomize opponent or challenge a friend
              </CCText>
            </Box>
            {rating && (
              <Box type="row-center-between" style={styles.rating}>
                <CCText
                  style={{
                    fontWeight: 500,
                    fontSize: 12,
                    color: '#A8A7A6',
                  }}>
                  Your Rating
                </CCText>
                <Box style={styles.ratingnum}>
                  <CCText
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}>
                    {rating}
                  </CCText>
                </Box>
              </Box>
            )}
            {/* <View> */}
            <Image
              source={require('@/Assets/Dashboard/PlayOnline/chessboardBg__PlayChess.svg')}
              style={styles.PlayChessBG1}
              resizeMode="cover"
            />

            <Image
              source={require('@/Assets/Dashboard/PlayOnline/playChessIcon.svg')}
              style={styles.PlayChessBG2}
              resizeMode="cover"
            />
            {/* </View> */}
            {/* </Box> */}
          </Box>
          <Box
            onPress={() => navigation.navigate(ScreenName.Puzzles)}
            style={[
              styles.card,
              {
                height: '100%',
                width: '50%',
                justifyContent: 'center',
                cursor: 'pointer',
                overflow: 'hidden',
              },
              isMobileOrTab ? {marginHorizontal: 5, paddingHorizontal: 5} : {},
            ]}>
            <Box
              type="column-flex-between"
              style={{width: '57%', overflow: 'hidden'}}>
              <CCText
                fontWeight="bold"
                fontSize={isMobileOrTab ? FontSizes[14] : FontSizes[16]}>
                Solve Positions
              </CCText>
              <Spacer spacing={10} />
              <CCText
                fontSize={FontSizes[12]}
                color={updateColorWithOpacity(CCColors.TextColor.Primary, 0.8)}>
                Play the right move from your games
              </CCText>
            </Box>
            <Image
              source={require('@/Assets/Dashboard/SolvePuzzle/puzzleBg.svg')}
              style={styles.PlayChessBG}
              resizeMode="cover"
            />
          </Box>
        </Box>
        <Box
          type="row-center-between"
          style={{height: '62%', overflow: 'hidden'}}>
          <Box
            type="row-center-space-around"
            style={[
              styles.card,
              {height: '100%', width: '100%'},
              isMobileOrTab ? {padding: 5} : {},
            ]}>
            {GAMEPLAY_STRIP_CONTENT?.map((i, index) => (
              <>
                <GamePlayStripComponent
                  hoveredItemForGameplayCard={hoveredItemForGameplayCard}
                  itemDetails={i}
                  navigation={navigation}
                />
                {GAMEPLAY_STRIP_CONTENT?.length - 1 !== index && (
                  <Divider isVertical style={{height: '80%'}} />
                )}
              </>
            ))}
          </Box>
          {/* <View style={[styles.card, {height: '100%', width: '33%'}]}>
            <CCText>hi 1</CCText>
          </View>
          <View style={[styles.card, {height: '100%', width: '33%'}]}>
            <CCText>hi 1</CCText> */}
          {/* </View> */}
        </Box>
      </Box>
      {/* <View style={[styles.card, styles.reviewGameCard]}>
        <View
          style={[
            styles.cardHeading,
            {justifyContent: 'space-between', flexWrap: 'wrap'},
          ]}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <MaterialCommunityIcon
              style={[styles.cardHeadingIcon, {backgroundColor: CCColors.Red}]}
              name="magnify"
              size={20}
              color="#fff"
            />
            <CCText style={[styles.cardHeadingText, OPACITY]}>
              Review game
            </CCText>
          </View>
          {!isMobileOrTab && (
            <CCButton
              onPress={() => {
                navigation.navigate(ScreenName.ChessAnalysis, {
                  game_id: data?.game.uuid,
                });
              }}
              style={styles.reviewGameAction}
              contentContainerStyle={{
                paddingRight: 8,
                paddingVertical: 8,
                paddingLeft: 32,
              }}>
              <View
                style={{
                  flex: 1,
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <CCText style={[styles.reviewGameActionText, OPACITY]}>
                  Review
                </CCText>
                <View style={styles.reviewGameActionCoinsContainer}>
                  <FontAwesome5 name="coins" size={16} color="#D1AB41" />
                  <CCText
                    fontSize="12"
                    fontWeight="500"
                    style={{marginLeft: 8, lineHeight: 16}}>
                    Upto +
                    {(data?.game?.score?.total_puzzles -
                      data?.game?.score?.puzzles_solved) *
                      5 +
                      50}
                    pts
                  </CCText>
                </View>
              </View>
            </CCButton>
          )}
        </View>
        <View
          style={isMobileOrTab ? {} : {display: 'flex', flexDirection: 'row'}}>
          <View style={isMobileOrTab ? {width: '100%'} : {flex: 0.5}}>
            <ChessboardComponent
              disabled
              boardOrientation={data?.game.color?.toLowerCase()}
              position={data?.game.fen}
            />
          </View>
          <View style={styles.reviewGameDetails}>
            <View
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                flex: 1,
                justifyContent: 'space-between',
              }}>
              <View
                style={{
                  display: 'flex',
                }}>
                <View style={styles.reviewGameStat}>
                  <View style={styles.reviewGameStatHeading}>
                    <FontAwesome5
                      style={styles.reviewGameStatIcon}
                      name="user"
                      size={16}
                      color={CCColors.Red}
                    />
                    <CCText style={styles.reviewGameStatHeadingText}>
                      Opponent
                    </CCText>
                  </View>
                  <CCText style={styles.reviewGameStatText}>
                    {data?.game.opponent} ({data?.game.opponent_rating})
                  </CCText>
                </View>

                <View style={styles.reviewGameScoreStat}>
                  <CCText style={styles.reviewGameScoreStatHeadingText}>
                    Accuracy
                  </CCText>
                  <CCText style={styles.reviewGameScoreStatText}>
                    {data?.game?.accuracy?.toFixed(1)}
                  </CCText>
                </View>
                <View style={styles.reviewGameScoreStat}>
                  <CCText style={styles.reviewGameScoreStatHeadingText}>
                    First attempt positions solved
                  </CCText>
                  <CCText style={styles.reviewGameScoreStatText}>
                    {data?.game?.score?.first_attempt_bonus_puzzles}
                  </CCText>
                </View>
                <View style={styles.reviewGameScoreStat}>
                  <CCText style={styles.reviewGameScoreStatHeadingText}>
                    Blunders committed
                  </CCText>
                  <CCText style={styles.reviewGameScoreStatText}>
                    {data?.game?.score?.no_of_blunders}
                  </CCText>
                </View>
                <View style={styles.reviewGameScoreStat}>
                  <CCText style={styles.reviewGameScoreStatHeadingText}>
                    Quick Blunders (&lt;10 secs thought)
                  </CCText>
                  <CCText style={styles.reviewGameScoreStatText}>
                    {data?.game?.score?.blunder_in_less_than_ten_secs}
                  </CCText>
                </View>
                {data?.game?.score?.net_score > 0 && (
                  <View style={[styles.reviewGameScoreStat, styles.top12]}>
                    <CCText
                      style={[
                        styles.reviewGameScoreStatHeadingText,
                        {color: CCColors.Green},
                      ]}>
                      You have scored:
                    </CCText>
                    <CCText
                      style={[
                        styles.reviewGameScoreStatText,
                        {color: CCColors.Green},
                      ]}>
                      +{data?.game?.score?.net_score}pts
                    </CCText>
                  </View>
                )}
                {data?.game?.score?.net_score < 0 && (
                  <View style={[styles.reviewGameScoreStat, styles.top12]}>
                    <CCText
                      style={[
                        styles.reviewGameScoreStatHeadingText,
                        {color: CCColors.Red},
                      ]}>
                      You have lost:
                    </CCText>
                    <CCText
                      style={[
                        styles.reviewGameScoreStatText,
                        {color: CCColors.Red},
                      ]}>
                      {data?.game?.score?.net_score}pts
                    </CCText>
                  </View>
                )}
                <View style={styles.reviewGameScoreStat}>
                  <CCText style={styles.reviewGameScoreStatHeadingText}>
                    Earn upto &nbsp;
                    {(data?.game?.score?.total_puzzles -
                      data?.game?.score?.puzzles_solved) *
                      5 +
                      50}{' '}
                    pts.
                  </CCText>
                </View>
              </View>
              {isMobileOrTab && (
                <Box style={{width: '100%'}}>
                  <Spacer spacing={10} />
                  <CCButton
                    onPress={() => {
                      navigation.navigate(ScreenName.ChessAnalysis, {
                        game_id: data?.game.uuid,
                      });
                    }}
                    style={styles.reviewGameAction}
                    contentContainerStyle={{
                      paddingRight: 8,
                      paddingVertical: 8,
                      paddingLeft: 32,
                    }}>
                    <View
                      style={{
                        flex: 1,
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <CCText style={styles.reviewGameActionText}>
                        Review
                      </CCText>
                      <View style={styles.reviewGameActionCoinsContainer}>
                        <FontAwesome5 name="coins" size={16} color="#D1AB41" />
                        <CCText
                          fontSize="12"
                          fontWeight="500"
                          style={{marginLeft: 8, lineHeight: 16}}>
                          Upto +
                          {(data?.game?.score?.total_puzzles -
                            data?.game?.score?.puzzles_solved) *
                            5 +
                            50}
                          pts
                        </CCText>
                      </View>
                    </View>
                  </CCButton>
                </Box>
              )}
            </View>
          </View>
        </View>
      </View> */}

      {/* DONT REMOVE THIS COMMENT, MIGHT BE NEEDED LATER */}
      {/* <View style={[styles.card, styles.masterGameCard]}>
        <View
          style={[
            styles.cardHeading,
            {justifyContent: 'space-between', flexWrap: 'wrap'},
          ]}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <MaterialCommunityIcon
              style={[
                styles.cardHeadingIcon,
                {backgroundColor: CCColors.Green},
                isMobileOrTab ? {} : {paddingBottom: 2},
              ]}
              name="puzzle-outline"
              size={20}
              color="#fff"
            />
            <CCText style={styles.cardHeadingText}>
              Positions from Masters’ games
            </CCText>
          </View>
          {!isMobileOrTab && (
            <CCButton
              style={styles.masterGameAction}
              onPress={() => {
                navigation.navigate(ScreenName.Puzzles, {is_masters: true});
              }}
              contentContainerStyle={{
                paddingVertical: 10,
                paddingHorizontal: 24,
              }}>
              <CCText fontWeight="500" fontSize="16">
                Solve
              </CCText>
            </CCButton>
          )}
        </View>

        <View style={styles.masterGameContent}>
          <View style={styles.masterGamePlayer}>
            <View style={styles.masterGamePlayerPhotoContainer}>
              <Image
                style={styles.masterGamePlayerPhoto}
                source={
                  data?.master_puzzle.user_photo_url
                    ? {uri: data?.master_puzzle.user_photo_url}
                    : require('@/Assets/Images/Svgs/silhouette-male-icon.svg')
                }
              />
              <CCText style={styles.masterGamePlayerTitle}>GM</CCText>
            </View>
            <CCText style={styles.masterGamePlayerName}>{parts[0]}</CCText>
            <CCText style={styles.masterGamePlayerRating}>
              {data?.master_puzzle.user_rating
                ? data?.master_puzzle.user_rating
                : 2830}
            </CCText>
          </View>
          {isMobileOrTab && <Divider isVertical style={{height: '100%'}} />}
          {!isMobileOrTab && (
            <View style={styles.masterGameBoard}>
              <ChessboardComponent
                disabled
                boardOrientation="white"
                lastMoveFen={data?.master_puzzle.previous_fen}
                position={data?.master_puzzle.fen}
              />
            </View>
          )}

          <View style={styles.masterGamePlayer}>
            <View style={styles.masterGamePlayerPhotoContainer}>
              <Image
                style={styles.masterGamePlayerPhoto}
                source={
                  data?.master_puzzle.opponent_photo_url
                    ? {uri: data?.master_puzzle.opponent_photo_url}
                    : require('@/Assets/Images/Svgs/silhouette-male-icon.svg')
                }
              />
              <CCText style={styles.masterGamePlayerTitle}>GM</CCText>
            </View>
            <CCText style={styles.masterGamePlayerName}>
              {data?.master_puzzle.opponent}
            </CCText>
            <CCText style={styles.masterGamePlayerRating}>
              {data?.master_puzzle.opponent_rating
                ? data?.master_puzzle.opponent_rating
                : 2830}
            </CCText>
          </View>
        </View>
        {isMobileOrTab && (
          <>
            <View style={styles.masterGameBoard}>
              <ChessboardComponent
                disabled
                boardOrientation="white"
                lastMoveFen={data?.master_puzzle.previous_fen}
                position={data?.master_puzzle.fen}
              />
            </View>
          </>
        )}
        <CCText style={styles.masterGameTournament}>
          {parts[1] ? parts[1] : 'Rapid Blitz Tournament, London, 2022'}
        </CCText>
        {isMobileOrTab && (
          <>
            <Spacer spacing={10} />
            <CCButton
              style={styles.masterGameAction}
              onPress={() => {
                navigation.navigate(ScreenName.Puzzles, {is_masters: true});
              }}
              contentContainerStyle={{
                paddingVertical: 10,
                paddingHorizontal: 24,
              }}>
              <CCText fontWeight="500" fontSize="16">
                Solve
              </CCText>
            </CCButton>
          </>
        )}
      </View> */}

      <View style={{flexDirection: 'row', flexBasis: '100%'}}>
        <View style={[styles.card, styles.scoreboardCard]}>
          <View style={styles.cardHeading}>
            <MaterialCommunityIcon
              style={[styles.cardHeadingIcon, {backgroundColor: '#937ADB'}]}
              name="clipboard-text-outline"
              size={20}
              color="#fff"
            />
            <CCText style={[styles.cardHeadingText, OPACITY]}>
              My Scoreboard
            </CCText>
          </View>

          {!isMobileOrTab && (
            <View style={{height: 36, flexDirection: 'row'}}>
              <CCText textAlign="center" style={{flex: 1}}>
                Game Play Performance
              </CCText>
              <CCText textAlign="center" style={{flex: 1}}>
                Game Review Performance
              </CCText>
            </View>
          )}
          <Divider />
          <View style={[styles.scoreboardTable, {margin: 0}]}>
            {isMobileOrTab && (
              <CCPackageTableNew
                headers={[
                  'Date',
                  'Played',
                  'Blunder free games',
                  'No. of Blunders',
                  'Average Accuracy',
                  'Reviewed',
                  'First Attempt Solves',
                  'You scored',
                  '',
                ]}
                tableData={scorecards?.slice(0, 5)?.map(({fields}, i) => [
                  {name: colText(fields['score_date']), type: 'text'},
                  {
                    name: colText(
                      <View style={{flexDirection: 'row'}}>
                        <CCText fontWeight="500">
                          {fields['games_played']}
                        </CCText>
                      </View>,
                    ),
                    type: 'text',
                  },
                  {name: colText(fields['zero_blunder_games']), type: 'text'},
                  {name: colText(fields['no_of_blunders']), type: 'text'},
                  {
                    name: colText(fields['average_accuracy']?.toFixed(1)),
                    type: 'text',
                  },
                  {
                    name: colText(
                      <View style={{flexDirection: 'row'}}>
                        <CCText fontWeight="500">
                          {fields['games_analyzed']}
                        </CCText>
                        {/* {fields['games_played'] != fields['games_analyzed'] && (
            <CCText
              fontWeight="500"
              fontSize={12}
              color={CCColors.TextColor.Grey}
              style={{marginLeft: 8, alignSelf: 'center'}}>
              {fields['games_played'] - fields['games_analyzed']}{' '}
              remaining
            </CCText>
          )} */}
                      </View>,
                    ),
                    type: 'text',
                  },
                  {
                    name: colText(fields['first_attempt_puzzles_solved']),
                    type: 'text',
                  },
                  {name: colText(fields['net_score']), type: 'text'},
                  {
                    name: colText(
                      <CCButton
                        type="none"
                        style={styles.scoreboardReviewButton}
                        onPress={() => {
                          navigation.navigate(ScreenName.Games, {
                            filter_date: fields['score_date'],
                          });
                        }}
                        contentContainerStyle={{
                          paddingHorizontal: 16,
                          paddingVertical: 0,
                        }}>
                        <CCText fontWeight="500" color="#3DAB9E">
                          Review
                        </CCText>
                      </CCButton>,
                    ),
                    type: 'text',
                  },
                  // {name: game?.sms_sent_timestamp?.slice(0, 10), type: 'text'},
                ])}
                rowProps={{
                  widthArr: [
                    '7vw',
                    '25vw',
                    '7vw',
                    '5vw',
                    '7vw',
                    '4vw',
                    '5vw',
                    '7vw',
                    '7vw',
                    '12vw',
                  ],
                }}
                cellProps={{
                  numberOfLines: 2, // Adjust the number of lines as needed
                  multiline: true, // Enable multiline text within cells
                }}
              />
            )}
            {!isMobileOrTab && (
              <View style={{flexDirection: 'row', margin: 0}}>
                <Box margin={0} flex={1} overflow="hidden">
                  <View style={[styles.tableRow, styles.tableHeader]}>
                    <Box flex={1} flexDirection="row">
                      {headerColText('Date')}
                      {headerColText('Played')}
                      {headerColText('Blunder free games')}
                      {headerColText('No. of Blunders')}
                      {headerColText('Average Accuracy')}
                    </Box>
                    <Divider isVertical />
                    <Box flex={1} flexDirection="row">
                      {headerColText('Reviewed')}
                      {headerColText('First Attempt Solves')}
                      {headerColText('You scored')}
                      {headerColText('')}
                    </Box>
                  </View>
                  {scorecards?.slice(0, 5).map(({fields}, i) => {
                    return (
                      <View
                        style={[
                          styles.tableRow,
                          i % 2 == 1 && {
                            backgroundColor: updateColorWithOpacity(
                              CCColors.Primary.Light,
                              0.5,
                            ),
                          },
                        ]}>
                        <Box flex={1} flexDirection="row">
                          {colText(fields['score_date'])}
                          {colText(
                            <View style={{flexDirection: 'row'}}>
                              <CCText fontWeight="500">
                                {fields['games_played']}
                              </CCText>
                            </View>,
                          )}
                          {colText(fields['zero_blunder_games'])}
                          {colText(fields['no_of_blunders'])}
                          {colText(fields['average_accuracy'].toFixed(1))}
                        </Box>
                        <Divider isVertical />
                        <Box flex={1} flexDirection="row">
                          {colText(
                            <View style={{flexDirection: 'row'}}>
                              <CCText fontWeight="500">
                                {fields['games_analyzed']}
                              </CCText>
                            </View>,
                          )}
                          {colText(fields['first_attempt_puzzles_solved'])}
                          {colText(fields['net_score'])}
                          {colText(
                            <CCButton
                              type="none"
                              style={styles.scoreboardReviewButton}
                              onPress={() => {
                                navigation.navigate(ScreenName.Games, {
                                  filter_date: fields['score_date'],
                                });
                              }}
                              contentContainerStyle={{
                                paddingHorizontal: 16,
                                paddingVertical: 0,
                              }}>
                              <CCText fontWeight="500" color="#3DAB9E">
                                Review
                              </CCText>
                            </CCButton>,
                          )}
                        </Box>
                      </View>
                    );
                  })}
                </Box>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}

export default React.memo(Dashboard);
