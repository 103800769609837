import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import { FontSizes, hs, vs } from '@/Utils/Dimensions';
import { View } from 'react-native';
import { Modal, Text, Button } from 'react-native';
import Animated, {
    useSharedValue,
    useAnimatedStyle,
    interpolateColor,
    interpolate,
    withTiming,
} from 'react-native-reanimated';
import { styles } from './styles';
import challenge from "@/Assets/Images/png/challenge@3x.png";
import useAnimation from '@/Hooks/useAnimation';
import CCColors from '@/Utils/CCColors';
import close from "@/Assets/Images/icons/close.png";
import { useRive, useStateMachineInput, Layout, Fit, Alignment } from '@rive-app/react-canvas';


function ChallengeRejected(props) {
    
    const {Visible, message, handle_close} = props

    const getFilePath = (message) => {
        switch (message) {
          case 'Challenge Rejected!':
            return require('@/Assets/Animation/rejected_your_challenge.riv');
          case 'The challenge has expired!':
            return require('@/Assets/Animation/didnt_respond.riv');
          case 'Challenge Cancelled!':
            return require('@/Assets/Animation/rejected_your_challenge.riv');
          case 'Players not available, Try again later!':
            return require('@/Assets/Animation/Didnt_find_any_opponent.riv');
          default:
            return require('@/Assets/Animation/Didnt_find_any_opponent.riv'); // Default file path
        }
    };
    
    const filePath = getFilePath(message);

    const {RiveComponent, rive} = useRive({
        src: filePath,
        layout: new Layout({
            fit: Fit.Cover,
          }),
        autoplay: true,
      });

    return (
        <Modal
            animationType='fade'
            transparent={true}
            visible={Visible}
            onRequestClose={() => {
            }}>
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <View style={{ position: 'absolute', top: 16, right: 16 }}>
                            <img src={close} 
                                onClick={handle_close} 
                                style={{ backgroundColor: 'white', borderColor: '#22222240', borderWidth: 1, height: 40, width: 40 }}/>
                    </View>
                    {/* <img src={challenge} style={{ width: hs(225), height: vs(225), marginBottom: 40 }}/> */}
                    <Box style={{width: '80%', aspectRatio: 1,marginBottom: 14}} overflow='hidden'>
                        <RiveComponent />
                    </Box> 
                    <CCText fontSize={FontSizes[20]} style={{ color: 'black' }}>{message}</CCText>
                </View>
            </View>
        </Modal>
    );
}

export default ChallengeRejected;
