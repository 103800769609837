import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import React, {useEffect, useState} from 'react';
import AntDesign from 'react-native-vector-icons/AntDesign';
import useManageParticipants from '@/Hooks/ManageStudyParticipantsApi/useManageParticipants';
import Addedparticipants from '../AddParticipants';
import CCColors from '@/Utils/CCColors';
import {LeftComponentType} from './types';
import Spacer from '@/Atoms/Spacer';
import CreateNewStudy from '@/Screens/StudyGameList/Component/CreateNewStudy';
import {GestureResponderEvent, TouchableOpacity} from 'react-native';
import {styles} from './styles';
import Ionicons from 'react-native-vector-icons/Ionicons';
import useDeleteChapter from '@/Hooks/ManageStudyParticipantsApi/useDeleteChapter';

function Chapters({
  chaptersList,
  //   userDetailsData,
  gameId,
  setLoading,
  refetchStudy,
  isOwner,
  selectedChapter,
  setSelectedChapter,
  setOpenRemoveConfimation,
}: //   setOpenRemoveConfimation,
LeftComponentType) {
  //   const [selectedChapter, setSelectedChapter] = useState(null);

  const handleParticipantsSuccess = data => {
    setLoading?.(false);
    refetchStudy?.();
  };

  const handleParticipantsError = data => {
    setLoading?.(false);
  };

  const {
    mutate: deleteChapter,
    isLoading: isDeletingChapter,
    data: deleteChapterData,
    error: deleteChapterError,
  } = useDeleteChapter(handleParticipantsSuccess, handleParticipantsError);

  // const handleParticipants = (
  //   phone: string,
  //   action: 'add' | 'remove' = 'add',
  // ) => {
  //   manageParticipants({
  //     phone: phone,
  //     action: action,
  //     gameId: gameId,
  //   });
  // };

  return (
    <>
      {/* <CCText fontWeight="semibold">Participants</CCText> */}
      <Spacer spacing={10} />
      <Box>
        {chaptersList?.map(chapter => {
          const isSelected = selectedChapter?.id === chapter?.id;
          return (
            <Box
              style={[
                styles.participantsName,
                isSelected ? styles.selectedName : {},
              ]}
              onPress={() => {
                setSelectedChapter(chapter);
                // resetBoard();
              }}>
              <CCText width={'80%'}>{chapter?.name}</CCText>
              {/* {isSelected && (
                <Ionicons
                  color={CCColors.TextColor.Primary}
                  name={'pencil'}
                  size={20}
                />
              )} */}
              {selectedChapter?.id === chapter?.id && isOwner && (
                <AntDesign
                  name="delete"
                  size={20}
                  color={CCColors.Error}
                  onPress={() =>
                    setOpenRemoveConfimation({
                      flag: true,
                      bodyTxt: `Are you sure you want to remove ${chapter?.name}`,
                      callbackFunc: async () =>
                        await deleteChapter({
                          studySlug: gameId,
                          chapterId: chapter?.id,
                        }),
                    })
                  }
                  // style={{marginRight: 20}}
                />
              )}
            </Box>
          );
        })}
        {/* <CCText style={styles.participantsName}>You</CCText> */}
        {/* <CCText style={styles.participantsName}>Player 1</CCText> */}
        {/* <CCText style={styles.participantsName}>Player 2</CCText> */}

        {isOwner && (
          <CreateNewStudy
            // eslint-disable-next-line react/no-unstable-nested-components
            overrideButton={(
              onClickFunc: ((event: GestureResponderEvent) => void) | undefined,
            ) => (
              <TouchableOpacity style={styles.addNewBox} onPress={onClickFunc}>
                <CCText color={CCColors.White}>+ add new</CCText>
              </TouchableOpacity>
            )}
            createChapterOnly
            successCallback={refetchStudy}
            studySlug={gameId}
          />
        )}
        {/* // <Addedparticipants
        //   startLoader={() => setLoading(true)}
        //   handleParticipants={handleParticipants}
        //   manageParticipantsData={manageParticipantsData}
        //   manageParticipantsError={manageParticipantsError}
        //   refetchStudy={refetchStudy}
        //   isOwner={isOwner}
        // /> */}
      </Box>
    </>
  );
}

export default Chapters;
