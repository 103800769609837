import React, {useState, useEffect} from 'react';
import {View, Image, StyleSheet, Dimensions, PanResponder} from 'react-native';

const WINDOW_HEIGHT = Dimensions.get('window').height;
const MAX_ZOOM_FACTOR = 5; // This can be adjusted based on your requirements

const ZoomableImage = ({src, ...rest}) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [position, setPosition] = useState({x: 0, y: 0});
  const [imageSize, setImageSize] = useState({width: 0, height: 0});
  const [scale, setScale] = useState(1);
  const [lastTap, setLastTap] = useState(null);

  const panResponder = PanResponder.create({
    onStartShouldSetPanResponder: () => isZoomed,
    onPanResponderMove: (event, gestureState) => {
      if (isZoomed) {
        setPosition(prev => ({
          x: prev.x + gestureState.dx,
          y: prev.y + gestureState.dy,
        }));
      }
    },
    onPanResponderRelease: () => {},
  });

  // const handleMouseMove = event => {
  //   if (isZoomed) {
  //     const rect = event.currentTarget.getBoundingClientRect();
  //     const x = event.clientX - rect.left - rect.width / 2; // Adjusted to center zoom on cursor
  //     const y = event.clientY - rect.top - rect.height / 2;
  //     setPosition({x: -x, y: -y});
  //   }
  // };

  // const handlePress = event => {
  //   if (!isZoomed) {
  //     const rect = event.currentTarget.getBoundingClientRect();
  //     // Calculate the position to zoom into based on the click coordinates
  //     const x = event.clientX - rect.left - rect.width / 2;
  //     const y = event.clientY - rect.top - rect.height / 2;
  //     setPosition({x: -x, y: -y});
  //     // Set zoom scale based on image resolution
  //     const scaleFactor = Math.min(
  //       imageSize.width / rect.width,
  //       MAX_ZOOM_FACTOR,
  //     );
  //     setScale(scaleFactor);
  //   } else {
  //     // Reset position and scale when unzooming
  //     setPosition({x: 0, y: 0});
  //     setScale(1);
  //   }
  //   setIsZoomed(!isZoomed);
  // };

  const handleDoubleClick = () => {
    if (isZoomed) {
      setPosition({x: 0, y: 0});
      setScale(1);
    } else {
      setScale(1);
    }
    setIsZoomed(!isZoomed);
  };

  const handleClick = () => {
    const now = Date.now();
    if (lastTap && now - lastTap < 300) {
      // Double click detected
      handleDoubleClick();
    } else {
      setLastTap(now);
    }
  };

  const handleImageLoaded = event => {
    const {width, height} = event.nativeEvent.source;
    setImageSize({width, height});
  };

  return (
    <View
      style={styles.container}
      onClick={handleClick}
      // onMouseMove={handleMouseMove}
      {...panResponder.panHandlers}>
      <Image
        source={{uri: src}}
        onLoad={handleImageLoaded}
        style={{
          width: '100%',
          height: WINDOW_HEIGHT / 1.5,
          transform: [
            {scale: isZoomed ? 5 : 1},
            {translateX: position.x},
            {translateY: position.y},
          ],
        }}
        resizeMode="contain"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    cursor: 'zoom-in',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
});

export default ZoomableImage;
