import {BlindFoldVerifyResponse} from './types';
import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useVerifyCalculationTrainerResultApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<
    BlindFoldVerifyResponse,
    Error,
    {puzzleId: string; move: string; type?: string; moves_count: number}
  >(
    async ({puzzleId, move, type, moves_count}) => {
      let q = {id: puzzleId, move: move, moves_count: moves_count};
      if (type) {
        q.type = type;
      }
      const objString = '?' + new URLSearchParams(q).toString();

      const response = await ChessRestClient.get(
        `learning/verify_blind_fold_puzzle_attempt/${objString}`,
      );
      return response.data;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) handleOnSuccess(data);
      },
    },
    {
      onError: error => {
        if (handleOnError) handleOnError(error);
      },
    },
  );

  return {
    ...mutation,
  };
}
