import React, {useEffect, useState} from 'react';
import CCText from '@/Atoms/CCText';
import {TextStyle} from 'react-native';
const AnimatedLoaderText = ({
  children,
  style,
}: {
  children: string;
  style?: TextStyle;
}) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prev => (prev.length < 3 ? prev + '.' : '')); // Cycle between '', '.', '..', and '...'
    }, 500);

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <CCText style={style}>
      {children}
      {dots}
    </CCText>
  );
};

export default AnimatedLoaderText;
