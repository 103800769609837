import Box from "@/Atoms/Box";
import CCText from "@/Atoms/CCText";
import CCColors from "@/Utils/CCColors";
import { FontSizes, hs, vs } from "@/Utils/Dimensions";
import { useState, useEffect } from "react";
import { RFPercentage } from 'react-native-responsive-fontsize';

function formatTime(seconds) {
    if(seconds||seconds==0){
    //   console.log(seconds);
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(remainingSeconds).padStart(2, '0');
      return `${formattedMinutes}:${formattedSeconds}`;
    }
    
  }

function ClockComponent({timer, myTurn, playing, setFirstMovetimer, firstMovetimer}) {

    // Setting defaults to 10 + 5
    
    // console.log('clock is:',timer);
    const normalColor = CCColors.Green;
    const lowTimeColor = CCColors.Red;

    const [time, setTime] = useState(timer); // State to hold the current time
    const [timerId, setTimerId] = useState(null); // State to hold the timer ID

   

    // Function to update the time
    const updateTime = () => {
        setTime((prevTime) => Math.max(prevTime - 1, 0));
        
    };

    // Function to start the timer
    const startTimer = () => {
        if (timerId) {
            clearInterval(timerId);
            setTimerId(setInterval(updateTime, 1000));
        } else {
            setTimerId(setInterval(updateTime, 1000));
        }
        
    };

    // Function to stop the timer
    const stopTimer = () => {
        if (timerId) {
            clearInterval(timerId);
            setTimerId(null);
        }
    };
    useEffect(() => {
        setFirstMovetimer(time - firstMovetimer);
    },[time]);

    useEffect(() => {
        setTime(timer)
        if (myTurn && playing) {
            startTimer();
        } else {
            stopTimer();
        }

        return () => {
            stopTimer();
        };
    }, [myTurn, timer, playing]);

    const backgroundColor = time < 10 ? lowTimeColor : normalColor;

    const color = normalColor;

    return (
        <Box style={{ height: '95%', width: '20%', marginVertical: 'auto', backgroundColor, justifyContent: 'center', alignContent: 'center', borderRadius: 4 }}>
            <CCText fontWeight='thunder' style={{ color: 'white', textAlign: 'center' }} fontSize={RFPercentage(2.5)}>{formatTime(time)}</CCText>
        </Box>
    );
}

export default ClockComponent;