import {
  ActivityIndicator,
  GestureResponderEvent,
  Pressable,
  View,
} from 'react-native';
import {useState} from 'react';
import {CCButtonProps} from './types';
import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import CCText from '../CCText';
import React from 'react';
import Styles from './styles';
import useAnimation from '@/Hooks/useAnimation';
import Animated from 'react-native-reanimated';
import {logEvent} from '@/Utils/GAEvents';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';

const CCButton: React.FC<CCButtonProps> = ({
  children,
  contentContainerStyle,
  disabled,
  loading,
  stretched,
  style,
  type = 'fill',
  titleStyle,
  titleFontWeight = 'regular',
  GAEvent = '',
  onPress = () => {},
  icon,
  iconStyle = {width: 20, height: 20},
  isRounded = true,
  ...props
}) => {
  const {isMobileOrTab} = useMediaQuery();
  const isFilled = type === 'fill';
  const isUnderline = type === 'underline';
  const isPageNo = type === 'pageno';
  const isNone = type === 'none';
  const isBox = type === 'box';
  // containerStyle = Styles.boxStyle;

  // Define container styles based on type and other props
  const getContainerStyle = (pressed: boolean) => {
    const baseStyle = isBox
      ? Styles.boxStyle
      : isFilled
      ? Styles.containerStyle
      : isUnderline
      ? Styles.containerUnderlineStyle
      : isNone
      ? {}
      : [
          Styles.containerOutlineStyle,
          isMobileOrTab && Styles.containerOutlineMobileStyle,
        ];

    const pressedStyles =
      pressed && isUnderline
        ? Styles.underlinePressed
        : pressed
        ? Styles.pressed
        : {};

    return [
      baseStyle,
      stretched && Styles.stretched,
      isPageNo && Styles.pageNoStyle,
      disabled && Styles.disabled,
      titleStyle?.color && {borderBottomColor: titleStyle.color},
      pressedStyles,
      {borderRadius: isRounded && !isBox && !isUnderline ? 32 : isBox ? 5 : 0},
      style,
    ];
  };

  // Define text style based on props
  const getTextStyle = () => [
    isFilled ? Styles.title : Styles.titleOutlineStyle,
    titleStyle,
    loading && Styles.loadingStyle,
    // isBox && {color: CCColors.White},
  ];

  const [bgAnimStyle, setBgAnimValue] = useAnimation({
    prop: 'backgroundColor',
    start: 'rgba(0, 0, 0, 0)',
    end: updateColorWithOpacity('#000000', 0.1),
    isColor: true,
  });

  const onPressWrapper = (e: GestureResponderEvent) => {
    if (GAEvent) {
      logEvent('event', 'button-click', GAEvent);
    }
    onPress?.(e);
  };
  return (
    <Pressable
      onHoverIn={() => {
        setBgAnimValue(1);
      }}
      onHoverOut={() => {
        setBgAnimValue(0);
      }}
      disabled={disabled || loading}
      style={{
        outlineStyle: 'none',
      }}
      onPress={onPressWrapper}
      {...props}>
      {({pressed}) => {
        return (
          <>
            {loading && (
              <ActivityIndicator
                animating
                color={CCColors.Primary.Brown}
                style={Styles.loading}
                size={30}
              />
            )}
            <View style={getContainerStyle(pressed)}>
              <Animated.View style={type != 'none' && bgAnimStyle}>
                <View
                  style={[
                    isNone ? {} : Styles.contentContainerStyle,
                    contentContainerStyle,
                    loading && Styles.loadingStyle,
                    type == 'none' &&
                      pressed && {
                        transform: [{translateX: 2}, {translateY: -2}],
                      },
                  ]}>
                  {icon && (
                    <img
                      src={icon}
                      style={{...iconStyle, marginRight: 10}}
                      alt="icon"
                    />
                  )}
                  {typeof children === 'string' ? (
                    <CCText style={getTextStyle()} fontWeight={titleFontWeight}>
                      {children}
                    </CCText>
                  ) : (
                    <>{children}</>
                  )}
                </View>
              </Animated.View>
            </View>
          </>
        );
      }}
    </Pressable>
  );
};

export default CCButton;
