import {Colors} from '@/styles';
import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  menuItem: {
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  mobileMenuItem: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 16,
    paddingVertical: 8,
  },
  mobileMenuItemFocused: {
    backgroundColor: Colors.darkLight,
  },

  menuItemText: {
    fontSize: 12,
    color: '#FFF',
    textAlign: 'center',
  },
  mobileMenuItemText: {
    fontSize: 20,
  },

  menuIcon: {
    textAlign: 'center',
    width: 48,
    height: 48,
    padding: 8,
    marginBottom: 8,
    borderRadius: 8,
  },

  mobileMenuIcon: {
    marginBottom: 0,
  },
  fadeBottom: {
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    height: 60, // Height of the fade effect
  },
  fadeBottomMobile: {
    position: 'absolute',
  },
});
