import React from 'react';
import SlideModal from '@/Atoms/SlideModal';
import CCText from '@/Atoms/CCText';
import Box from '@/Atoms/Box';
import Spacer from '@/Atoms/Spacer';
import CCButton from '@/Atoms/CCButton';
import useAlertModalStore from '@/Store/AlertModalStore';
import {FontSizes} from '@/Utils/Dimensions';

type AlertModalProp = {
  header?: string;
  bodyTxt?: string;
  handleConfirmation?: () => void;
  handleClose?: () => void;
  open?: boolean;
  buttonText?: string;
};

function AlertModal({
  header,
  bodyTxt,
  handleConfirmation,
  handleClose,
  open,
  buttonText = 'OK', // Default to "OK" if not provided
}: AlertModalProp) {
  const zustandState = useAlertModalStore(state => ({
    open: state.open,
    header: state.header,
    bodyTxt: state.bodyTxt,
    buttonText: state.buttonText,
    handleConfirmation: state.handleConfirmation,
    handleClose: state.handleClose,
  }));

  const isControlledManually = open !== undefined;

  const modalOpen = isControlledManually ? open : zustandState.open;
  const modalHeader = isControlledManually ? header : zustandState.header;
  const modalBodyTxt = isControlledManually ? bodyTxt : zustandState.bodyTxt;
  const modalButtonText = isControlledManually
    ? buttonText
    : zustandState.buttonText;
  const modalHandleConfirmation = isControlledManually
    ? handleConfirmation
    : zustandState.handleConfirmation;
  const modalHandleClose = isControlledManually
    ? handleClose
    : zustandState.handleClose;

  return (
    <SlideModal
      type="center"
      modalHeaderTitle={modalHeader || 'Info'}
      visible={modalOpen}
      onClose={modalHandleClose}>
      <Box margin={2}>
        <CCText fontSize={FontSizes[14]}>{modalBodyTxt}</CCText>
        <Spacer spacing={20} />
        <Box type="row-flex-end">
          <CCButton
            onPress={() => {
              modalHandleConfirmation?.();
              modalHandleClose?.();
            }}>
            {modalButtonText}
          </CCButton>
        </Box>
      </Box>
    </SlideModal>
  );
}

export default AlertModal;
