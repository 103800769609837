import {
  Animated,
  InteractionManager,
  TouchableOpacity,
  View,
} from 'react-native';
import {NavigationState, SceneRendererProps} from 'react-native-tab-view';
// import {SCREEN_WIDTH, WINDOW_WIDTH} from '../../Utils/Dimensions';

import Box from '../../Atoms/Box/Box';
import CCColors from '../../Utils/CCColors';
import React from 'react';
import Styles from './styles';

type TabBarProps = SceneRendererProps & {
  navigationState: NavigationState<{
    key: string;
    title: string;
  }>;
};

export default function TabBar(props: TabBarProps) {
  const {width} = props?.layout;
  const translation = props.position.interpolate({
    inputRange: [0, 1],
    outputRange: [0, width / props.navigationState.routes.length],
  });

  const {index: selectedIndex} = props.navigationState;

  return (
    <View>
      <View style={Styles.tabBar}>
        {props.navigationState.routes.map((route, i) => {
          return (
            <TouchableOpacity
              key={route.title}
              style={[Styles.tabItem]}
              onPress={() => {
                if (route.title) {
                  InteractionManager.runAfterInteractions(() => {
                    props.jumpTo(route.key);
                  });
                }
              }}>
              <Animated.Text
                style={[
                  Styles.labelStyle,
                  {
                    color:
                      selectedIndex === i
                        ? CCColors.Primary.Default
                        : CCColors.Grey.Grey,
                  },
                ]}>
                {route.title}
              </Animated.Text>
            </TouchableOpacity>
          );
        })}
      </View>

      <Box style={Styles.sliderParent}>
        <Animated.View
          style={[
            Styles.sliderContainerStyle,
            {
              transform: [{translateX: translation}],
              width: width / props.navigationState.routes.length,
            },
          ]}
        />
      </Box>
    </View>
  );
}

export function renderTabBar(props: TabBarProps): React.ReactNode {
  return (
    <TabBar
      {...props}
      // onTabPress={({route, preventDefault}) => {
      //   if (route.key === 'GAME REVIEW') {
      //     // preventDefault();
      //     // Do something else
      //   }
      // }}
    />
  );
}
