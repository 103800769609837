import {useMutation} from '@tanstack/react-query';
import {getLocalStorage} from '@/Utils/CommonUtils';
import ChessRestClient from '@/Api/ChessRestClient';

export default function usePullGamesStatusApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<any, Error, void>(
    async () => {
      const token = await getLocalStorage('access_token');
      const response = await ChessRestClient.get(
        'game/v1/learning/get_pull_games_status/',
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      );

      return response;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) handleOnSuccess(data);
        else if (handleOnError) handleOnError(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
