import Box from '@/Atoms/Box';
import React from 'react';
import CCText from '@/Atoms/CCText';
import CCButton from '@/Atoms/CCButton';
import CCColors from '@/Utils/CCColors';
import { Image, ImageBackground, ScrollView } from 'react-native';
import ConditionalRender from '@/Atoms/ConditionalRender';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import square from '@/Assets/Images/Svgs/home_page/square.svg';
import Union from '@/Assets/Images/Svgs/home_page/Union.svg';
import Frame from '@/Assets/Images/Svgs/home_page/Frame.svg';
import Frame1 from '@/Assets/Images/Svgs/home_page/Frame1.svg';
import Frame2 from '@/Assets/Images/Svgs/home_page/Frame2.svg';
import Frame3 from '@/Assets/Images/Svgs/home_page/Frame3.svg';

const MobileView = ({signUpHandler}) => {
    const {isMobileOrTab} = useMediaQuery();

  return (
    <>
        <ConditionalRender
          condition={!isMobileOrTab}
          childrenA={
            <Box
              style={{
                alignItems: 'center',
                paddingTop: 100,
                marginBottom: !isMobileOrTab ? 60 : 20,
              }}>
              <Box flexDirection="row" style={{padding: 40}}>
                <Box flexDirection="row" style={{marginRight: 60}}>
                  <Box
                    style={{
                      height: 600,
                      width: 556,
                      backgroundColor: '#4D3F37',
                      padding: 20,
                      borderRadius: 12,
                    }}>
                    <Box
                      flexDirection="column"
                      style={{
                        paddingTop: 50,
                        paddingLeft: 20,
                        paddingBottom: 150,
                      }}>
                      <Box flexDirection="row">
                        <CCText
                          fontWeight="thunder"
                          style={{
                            color: '#FF7043',
                            fontSize: 56,
                            lineHeight: 56,
                          }}>
                          Track
                        </CCText>
                        <CCText
                          fontWeight="thunder"
                          style={{
                            color: '#FFFFFF',
                            fontSize: 56,
                            lineHeight: 56,
                          }}>
                          {' '}
                          your
                        </CCText>
                      </Box>
                      <CCText
                        fontWeight="thunder"
                        style={{
                          color: '#FFFFFF',
                          fontSize: 56,
                          lineHeight: 56,
                        }}>
                        learning
                      </CCText>
                    </Box>
                    <Box style={{width: 300, marginTop: 20, paddingLeft: 20}}>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Play games
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Review your games
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Solve positions{' '}
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Compete with masters
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Get Detailed Assessment
                        </CCText>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    marginLeft={30}
                    style={{position: 'absolute', bottom: 0, right: 0}}>
                    <ImageBackground
                      source={Union}
                      style={{height: 622.5, width: 498}}>
                      <Image
                        source={Frame}
                        resizeMethod="resize"
                        style={{
                          width: 233,
                          height: 447,
                          position: 'absolute',
                          bottom: 0,
                          right: 20,
                        }}
                      />
                    </ImageBackground>
                  </Box>
                </Box>
                <Box flexDirection="row">
                  <Box
                    style={{
                      height: 600,
                      width: 556,
                      backgroundColor: '#4D3F37',
                      padding: 20,
                      borderRadius: 12,
                    }}>
                    <Box
                      flexDirection="column"
                      style={{
                        paddingTop: 50,
                        paddingLeft: 20,
                        paddingBottom: 100,
                      }}>
                      <CCText
                        fontWeight="thunder"
                        style={{
                          color: '#FFFFFF',
                          fontSize: 56,
                          lineHeight: 56,
                        }}>
                        Assess and
                      </CCText>
                      <CCText
                        fontWeight="thunder"
                        style={{
                          color: '#FFFFFF',
                          fontSize: 56,
                          lineHeight: 56,
                        }}>
                        Calibrate Your
                      </CCText>
                      <CCText
                        fontWeight="thunder"
                        style={{
                          color: '#3DAB9E',
                          fontSize: 56,
                          lineHeight: 56,
                        }}>
                        Strengths
                      </CCText>
                    </Box>
                    <Box style={{width: 253, marginTop: 20, paddingLeft: 20}}>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Solidfy your strengths
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Eliminate weaknesses
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Know your openings
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Train for each specific assessment
                        </CCText>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    marginLeft={30}
                    style={{position: 'absolute', bottom: 0, right: 0}}>
                    <ImageBackground
                      source={Union}
                      style={{height: 622.5, width: 498}}>
                      <Image
                        source={Frame1}
                        resizeMethod="resize"
                        style={{
                          width: 233,
                          height: 447,
                          position: 'absolute',
                          bottom: 0,
                          right: 20,
                        }}
                      />
                    </ImageBackground>
                  </Box>
                </Box>
              </Box>
              <Box flexDirection="row" style={{padding: 40}}>
                <Box flexDirection="row" style={{marginRight: 60}}>
                  <Box
                    style={{
                      height: 600,
                      width: 556,
                      backgroundColor: '#4D3F37',
                      padding: 20,
                      borderRadius: 12,
                    }}>
                    <Box
                      flexDirection="column"
                      style={{
                        paddingTop: 50,
                        paddingLeft: 20,
                        paddingBottom: 200,
                      }}>
                      <CCText
                        fontWeight="thunder"
                        style={{
                          color: '#67B3E1',
                          fontSize: 56,
                          lineHeight: 56,
                        }}>
                        Calculate
                      </CCText>
                      <CCText
                        fontWeight="thunder"
                        style={{
                          color: '#FFFFFF',
                          fontSize: 56,
                          lineHeight: 56,
                        }}>
                        Ahead
                      </CCText>
                    </Box>
                    <Box style={{width: 285, marginTop: 20, paddingLeft: 20}}>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Get your calculation depth
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Improve visualisation
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Dedicated calculation traine
                        </CCText>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    marginLeft={30}
                    style={{position: 'absolute', bottom: 0, right: 0}}>
                    <ImageBackground
                      source={Union}
                      style={{height: 622.5, width: 498}}>
                      <Image
                        source={Frame2}
                        resizeMethod="resize"
                        style={{
                          width: 233,
                          height: 447,
                          position: 'absolute',
                          bottom: 0,
                          right: 20,
                        }}
                      />
                    </ImageBackground>
                  </Box>
                </Box>
                <Box flexDirection="row">
                  <Box
                    style={{
                      height: 600,
                      width: 556,
                      backgroundColor: '#4D3F37',
                      padding: 20,
                      borderRadius: 12,
                    }}>
                    <Box
                      flexDirection="column"
                      style={{
                        paddingTop: 50,
                        paddingLeft: 20,
                        paddingBottom: 200,
                      }}>
                      <Box flexDirection="row">
                        <CCText
                          fontWeight="thunder"
                          style={{
                            color: '#DB7ACC',
                            fontSize: 56,
                            lineHeight: 56,
                          }}>
                          Play
                        </CCText>
                        <CCText
                          fontWeight="thunder"
                          style={{
                            color: '#FFFFFF',
                            fontSize: 56,
                            lineHeight: 56,
                          }}>
                          {' '}
                          like
                        </CCText>
                      </Box>
                      <CCText
                        fontWeight="thunder"
                        style={{
                          color: '#FFFFFF',
                          fontSize: 56,
                          lineHeight: 56,
                        }}>
                        never before
                      </CCText>
                    </Box>
                    <Box style={{width: 250, marginTop: 20, paddingLeft: 20}}>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Play from any position
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Play against human-like bots
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Parental Control for Discipline
                        </CCText>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    marginLeft={30}
                    style={{position: 'absolute', bottom: 0, right: 0}}>
                    <ImageBackground
                      source={Union}
                      style={{height: 622.5, width: 498}}>
                      <Image
                        source={Frame3}
                        resizeMethod="resize"
                        style={{
                          width: 233,
                          height: 447,
                          position: 'absolute',
                          bottom: 0,
                          right: 20,
                        }}
                      />
                    </ImageBackground>
                  </Box>
                </Box>
              </Box>
            </Box>
          }
          childrenB={
            <ScrollView horizontal>
              <Box
                style={{
                  alignItems: 'center',
                  margin: 20,
                  flexDirection: 'row',
                }}>
                {/* <Box flexDirection="row" style={{padding: 40}}> */}
                <Box flexDirection="column" style={{marginRight: '2%'}}>
                  <Box
                    style={{
                      height: 850,
                      width: '100vw',
                      backgroundColor: '#4D3F37',
                      padding: 20,
                      borderRadius: 12,
                    }}>
                    <Box
                      flexDirection="column"
                      style={{paddingTop: 50, paddingLeft: 20}}>
                      <Box flexDirection="row">
                        <CCText
                          fontWeight="thunder"
                          style={{
                            color: '#FF7043',
                            fontSize: 56,
                            lineHeight: 56,
                          }}>
                          Track
                        </CCText>
                        <CCText
                          fontWeight="thunder"
                          style={{
                            color: '#FFFFFF',
                            fontSize: 56,
                            lineHeight: 56,
                          }}>
                          {' '}
                          your
                        </CCText>
                      </Box>
                      <CCText
                        fontWeight="thunder"
                        style={{
                          color: '#FFFFFF',
                          fontSize: 56,
                          lineHeight: 56,
                        }}>
                        learning
                      </CCText>
                    </Box>
                    <Box style={{width: 300, marginTop: 20, paddingLeft: 20}}>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Play games
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Review your games
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Solve positions{' '}
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Compete with masters
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Get Detailed Assessment
                        </CCText>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    // marginLeft={30}
                    style={{position: 'absolute', bottom: 0, right: 0}}>
                    <ImageBackground
                      source={Union}
                      style={{height: 622.5, width: 498}}>
                      <Image
                        source={Frame}
                        resizeMethod="resize"
                        style={{
                          width: 233,
                          height: 447,
                          position: 'absolute',
                          bottom: 0,
                          right: '20vw',
                        }}
                      />
                    </ImageBackground>
                  </Box>
                </Box>
                <Box flexDirection="column" style={{ marginRight: '2%' }}>
                  <Box
                    style={{
                      height: 850,
                      width: '100vw',
                      backgroundColor: '#4D3F37',
                      padding: 20,
                      borderRadius: 12,
                    }}>
                    <Box
                      flexDirection="column"
                      style={{paddingTop: 50, paddingLeft: 20}}>
                      <CCText
                        fontWeight="thunder"
                        style={{
                          color: '#FFFFFF',
                          fontSize: 56,
                          lineHeight: 56,
                        }}>
                        Assess and
                      </CCText>
                      <CCText
                        fontWeight="thunder"
                        style={{
                          color: '#FFFFFF',
                          fontSize: 56,
                          lineHeight: 56,
                        }}>
                        Calibrate Your
                      </CCText>
                      <CCText
                        fontWeight="thunder"
                        style={{
                          color: '#3DAB9E',
                          fontSize: 56,
                          lineHeight: 56,
                        }}>
                        Strengths
                      </CCText>
                    </Box>
                    <Box style={{width: 253, marginTop: 20, paddingLeft: 20}}>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Solidfy your strengths
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Eliminate weaknesses
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Know your openings
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Train for each specific assessment
                        </CCText>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    marginLeft={30}
                    style={{position: 'absolute', bottom: 0, right: 0}}>
                    <ImageBackground
                      source={Union}
                      style={{height: 622.5, width: 498}}>
                      <Image
                        source={Frame1}
                        resizeMethod="resize"
                        style={{
                          width: 233,
                          height: 447,
                          position: 'absolute',
                          bottom: 0,
                          right: '20vw',
                        }}
                      />
                    </ImageBackground>
                  </Box>
                  {/* </Box> */}
                </Box>
                {/* <Box flexDirection="row" style={{padding: 40}}> */}
                <Box flexDirection="column" style={{ marginRight: '2%' }}>
                  <Box
                    style={{
                      height: 850,
                      width: '100vw',
                      backgroundColor: '#4D3F37',
                      padding: 20,
                      borderRadius: 12,
                    }}>
                    <Box
                      flexDirection="column"
                      style={{paddingTop: 50, paddingLeft: 20}}>
                      <CCText
                        fontWeight="thunder"
                        style={{
                          color: '#67B3E1',
                          fontSize: 56,
                          lineHeight: 56,
                        }}>
                        Calculate
                      </CCText>
                      <CCText
                        fontWeight="thunder"
                        style={{
                          color: '#FFFFFF',
                          fontSize: 56,
                          lineHeight: 56,
                        }}>
                        Ahead
                      </CCText>
                    </Box>
                    <Box style={{width: 285, marginTop: 20, paddingLeft: 20}}>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Get your calculation depth
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Improve visualisation
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Dedicated calculation traine
                        </CCText>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    marginLeft={30}
                    style={{position: 'absolute', bottom: 0, right: 0}}>
                    <ImageBackground
                      source={Union}
                      style={{height: 622.5, width: 498}}>
                      <Image
                        source={Frame2}
                        resizeMethod="resize"
                        style={{
                          width: 233,
                          height: 447,
                          position: 'absolute',
                          bottom: 0,
                          right: '20vw',
                        }}
                      />
                    </ImageBackground>
                  </Box>
                </Box>
                <Box flexDirection="column">
                  <Box
                    style={{
                      height: 850,
                      width: '100vw',
                      backgroundColor: '#4D3F37',
                      padding: 20,
                      borderRadius: 12,
                    }}>
                    <Box
                      flexDirection="column"
                      style={{paddingTop: 50, paddingLeft: 20}}>
                      <Box flexDirection="row">
                        <CCText
                          fontWeight="thunder"
                          style={{
                            color: '#DB7ACC',
                            fontSize: 56,
                            lineHeight: 56,
                          }}>
                          Play
                        </CCText>
                        <CCText
                          fontWeight="thunder"
                          style={{
                            color: '#FFFFFF',
                            fontSize: 56,
                            lineHeight: 56,
                          }}>
                          {' '}
                          like
                        </CCText>
                      </Box>
                      <CCText
                        fontWeight="thunder"
                        style={{
                          color: '#FFFFFF',
                          fontSize: 56,
                          lineHeight: 56,
                        }}>
                        never before
                      </CCText>
                    </Box>
                    <Box style={{width: 250, marginTop: 20, paddingLeft: 20}}>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Play from any position
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Play against human-like bots
                        </CCText>
                      </Box>
                      <Box
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}>
                        <Image
                          resizeMethod="resize"
                          source={square}
                          alt="square"
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <CCText
                          fontSize={18}
                          fontWeight="regular"
                          style={{lineHeight: 24, paddingLeft: 10}}
                          color="#FFFFFF">
                          Parental Control for Discipline
                        </CCText>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    marginLeft={30}
                    style={{position: 'absolute', bottom: 0, right: 0}}>
                    <ImageBackground
                      source={Union}
                      style={{height: 622.5, width: 498}}>
                      <Image
                        source={Frame3}
                        resizeMethod="resize"
                        style={{
                          width: 233,
                          height: 447,
                          position: 'absolute',
                          bottom: 0,
                          right: '20vw',
                        }}
                      />
                    </ImageBackground>
                  </Box>
                </Box>
                {/* </Box> */}
              </Box>
            </ScrollView>
          }
        />

        <ConditionalRender
          condition={isMobileOrTab}
          childrenA={
            <CCButton
              style={{
                width: '90%',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 20,
                marginBottom: 20,
                alignSelf: 'center',
              }}
              onPress={signUpHandler}>
              <CCText
                fontSize={16}
                style={{paddingVertical: 5}}
                color={CCColors.TextColor.Dark}>
                Signup to CircleChess
              </CCText>
            </CCButton>
          }
        />
    </>
  )
}

export default MobileView;