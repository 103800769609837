import ChessRestClient from '@/Api/ChessRestClient';
import {useMutation} from '@tanstack/react-query';

export default function saveIntuitionTrainerMove(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<Error>(
    async (data: {
      move: any;
      attempted_move: string;
      move_index: number;
      color: any;
      engine_move_match: boolean;
      master_move_match: boolean;
      game_complete: boolean;
      game_id: any;
      fen: any;
    }) => {
      const {
        move,
        attempted_move,
        move_index,
        color,
        engine_move_match,
        master_move_match,
        game_complete,
        game_id,
        fen,
      } = data;
      console.info("Save Intuition trainer triggered data: ", JSON.stringify(data));
      const response = await ChessRestClient.post(
        'learning/save_intuition_trainer_move/',
        {
          move,
          attempted_move,
          move_index,
          color,
          engine_move_match,
          master_move_match,
          game_complete,
          game_id,
          fen,
        },
      );
      return response.data;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) handleOnSuccess(data);
        else if (handleOnError) handleOnError(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
