import CCColors from '@/Utils/CCColors';
import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  container: {},
  active: {
    borderBottomWidth: 2,
    paddingBottom: 16,
    borderBottomColor: CCColors.Buttons.Background,
  },
});
