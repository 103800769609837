import {PuzzlesResponse} from './types';
import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function usePuzzleFiltersApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<
    PuzzlesResponse,
    Error,
    {
      stage?: string;
      color?: string;
      type?: string;
      pieces?: string;
    }
  >(
    async filters => {
      const queryParams = new URLSearchParams(filters);
      const queryString = queryParams.toString();
      const response = await ChessRestClient.get(
        `learning/puzzle_filters/?${queryString}`,
      );
      console.log(response.data);
      return response.data;
    },
    {
      onSuccess: data => {
        console.log('Puzzle Filters Data = ', data);
        if (handleOnSuccess && data.success) handleOnSuccess(data);
        else if (handleOnError) handleOnError(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
