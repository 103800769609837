import React from 'react';
import {Image, ImageBackground, StyleSheet, Dimensions} from 'react-native';
import Carousel from 'react-native-reanimated-carousel';
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import squareBackgroundTopRight from '@/Assets/Images/png/square_background.png';
import player from '@/Assets/Images/png/player.png';
import elevate from '@/Assets/Images/png/elevate.png';
import badge from '@/Assets/Images/png/badge.png';

const {width: screenWidth} = Dimensions.get('window');

const data = [
  {
    icon: player,
    number: '25,000+',
    text: 'Registered Players',
  },
  {
    icon: elevate,
    number: '200+',
    text: 'Tournaments Hosted',
  },
  {
    icon: badge,
    number: '25,000+',
    text: 'Tournaments Registrations',
  },
];

const renderItem = ({item}) => (
  <ImageBackground source={squareBackgroundTopRight} style={styles.bottomBox}>
    <Box
      style={{marginTop: 'auto', marginBottom: 'auto'}}
      alignItems="center"
      justifyContent="center"
      gap={10}>
      <Box flexDirection="row" justifyContent="center">
        <Image
          source={item.icon}
          resizeMethod="resize"
          alt="icon"
          style={{width: 40, height: 40}}
        />
      </Box>
      <CCText
        fontWeight="thunder"
        fontSize={36}
        textAlign="center"
        color={CCColors.TextColor.Primary}>
        {item.number}
      </CCText>
      <CCText
        fontWeight="regular"
        fontSize={16}
        textAlign="center"
        style={{
          width: '80%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}>
        {item.text}
      </CCText>
    </Box>
  </ImageBackground>
);

const StatisticsCarousel = () => {
  return (
    <Box flexDirection="row" justifyContent="center" marginTop={50}>
      <Carousel
        loop
        width={screenWidth}
        height={200}
        autoPlay={true}
        autoPlayInterval={3000}
        data={data}
        renderItem={renderItem}
        pagingEnabled={true}
        snapEnabled={true}
      />
    </Box>
  );
};

export default StatisticsCarousel;

const styles = StyleSheet.create({
  bottomBox: {
    width: screenWidth - 80,
    height: 200,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    marginHorizontal: 10,
    borderRadius: 12,
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
    gap: 10,
    backgroundColor: CCColors.Primary.lightBrown,
  },
});
