import {StyleSheet} from 'react-native';
import CCColors from '@/Utils/CCColors';
import {isPhone} from '@/Utils/Dimensions';

const styles = StyleSheet.create({
  container: {
    flex: 1, // Make the container take up the whole screen
    backgroundColor: CCColors.White,
  },
  contentView: {
    marginTop: isPhone ? '20%' : undefined,
    marginBottom: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: isPhone ? 300 : 500,
    height: isPhone ? 300 : 500,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  icon: {
    marginBottom: 20,
  },
  statusCCText: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  successText: {
    fontSize: 18,
    marginBottom: 10,
  },
  buttonCCText: {
    fontSize: 18,
    color: CCColors.White,
  },
});

export default styles;
