import CCColors, { updateColorWithOpacity } from "../../Utils/CCColors";

import Box from "../../Atoms/Box/Box";
import React from "react";
import Styles from "./styles";
import { ViewStyle } from "react-native";

export interface IDividerProps {
  marginTop?: number;
  marginBottom?: number;
  width?: number;
  isVertical?: boolean;
  color?: string;
  style?: ViewStyle;
}

function Divider(props: IDividerProps) {
  const {
    width = 2,
    marginTop,
    marginBottom,
    isVertical = false,
    style = {},
    color = updateColorWithOpacity(CCColors.Grey.Grey, 0.2),
  } = props;

  return (
    <Box
      style={[
        isVertical ? Styles.verticalContainerStyle : Styles.containerStyle,
        {
          marginTop,
          marginBottom,
          borderTopWidth: width,
          borderColor: color,
        },
        style,
      ]}
    />
  );
}

export default React.memo(Divider);
