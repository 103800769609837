import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
import CCColors from '../../../Utils/CCColors';
import {memo} from 'react';

const SvgComponent = (props: SvgProps) => {
  const {color = CCColors.White, width = 20, height = 20} = props;

  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 20 20`}
      fill={color}
      {...props}>
      <Path
        d="M15.4177 16.1019C14.2306 16.9149 12.7986 17.3906 11.2534 17.3906C7.17919 17.3906 3.86252 14.074 3.86252 9.9997C3.86252 5.92543 7.17919 2.60874 11.2557 2.60874C12.7986 2.60874 14.2329 3.08454 15.4177 3.89754L16.9906 2.17915C15.3831 0.996593 13.4014 0.299072 11.2557 0.299072C5.89733 0.299072 1.55518 4.64126 1.55518 9.9997C1.55518 15.3581 5.89733 19.7003 11.2557 19.7003C13.4014 19.7003 15.3854 19.0028 16.9906 17.8226L15.4177 16.1042V16.1019Z"
        fill={color}
      />
      <Path
        d="M13.1498 8.84497L11.0388 11.1546H0.111816V8.84497H13.1498Z"
        fill={color}
      />
      <Path
        d="M12.7084 13.1412C12.2649 13.3467 11.7753 13.4645 11.2556 13.4645C9.3455 13.4645 7.7911 11.9101 7.7911 10C7.7911 8.08991 9.3455 6.5355 11.2556 6.5355C11.7753 6.5355 12.2649 6.6533 12.7084 6.85886L14.3136 5.10351C13.4267 4.54918 12.3781 4.22583 11.2556 4.22583C8.06595 4.22583 5.48145 6.81035 5.48145 10C5.48145 13.1897 8.06595 15.7742 11.2556 15.7742C12.3804 15.7742 13.4267 15.4508 14.3159 14.8965L12.7107 13.1412H12.7084Z"
        fill={color}
      />
    </Svg>
  );
};

const CCCurrencyIcon = memo(SvgComponent);
export default CCCurrencyIcon;
