import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import React, {useMemo} from 'react';
import styles from './styles';
import {ScrollView} from 'react-native';

function GameScoreTable({gameScoreDetails}: {gameScoreDetails: any}) {
  const tableDetails = useMemo(
    () => gameScoreDetails?.count,
    [gameScoreDetails],
  );

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <Box style={styles.wrapper}>
        <CCText style={styles.cell1} fontWeight="600">
          {'Insights'}
        </CCText>
        <Box style={styles.row}>
          <CCText style={styles.cell1}>{'Accuracy'}</CCText>
          <CCText style={styles.cell2} fontWeight="600">
            {gameScoreDetails?.average_accuracy?.toFixed(2) || 0}
          </CCText>
        </Box>

        <Box style={styles.row}>
          <CCText style={styles.cell1}>{'Blunders in < 10s'}</CCText>
          <CCText style={styles.cell2} fontWeight="600">
            {tableDetails?.blunder_in_less_than_ten_secs || 0}
          </CCText>
        </Box>

        <Box style={styles.row}>
          <CCText style={styles.cell1}>{'Remaining blunders'}</CCText>
          <CCText style={styles.cell2} fontWeight="600">
            {gameScoreDetails?.no_of_blunders -
              tableDetails?.blunder_in_less_than_ten_secs || 0}
          </CCText>
        </Box>

        <Box style={styles.row}>
          <CCText style={styles.cell1}>{'First Try Wins(Positions)'}</CCText>
          <CCText style={styles.cell2} fontWeight="600">
            {tableDetails?.first_attempt_puzzles || 0}
          </CCText>
        </Box>
      </Box>
    </ScrollView>
  );
}

export default GameScoreTable;
