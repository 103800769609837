import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {
  StyleSheet,
  Image,
  FlatList,
  Pressable,
  TouchableWithoutFeedback,
} from 'react-native';
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import CCButton from '@/Atoms/CCButton';
import {WINDOW_HEIGHT, isPhone, vs} from '@/Utils/Dimensions';
import CCColors from '@/Utils/CCColors';

import useGetAutoCompletion from '@/Hooks/GrandMastersGamesApi/useGetAutoCompletion';
import useGetGrandMastersGames from '@/Hooks/GrandMastersGamesApi/useGetGrandMastersGames';
import useGetIntuitionTrainerLevel from '@/Hooks/IntuitionTrainerApi/getIntuitionTrainerLevel';
import openingsData from '../../openings.json';
import ChessboardComponent from 'chessboard-package';
import IntuitionTrainerAnalysis from './IntuitionTrainerAnalysis';
import CCFlatlist from '@/Atoms/CCFlatlist';
import ConditionalRender from '@/Atoms/ConditionalRender';
import CCLoader from '@/Atoms/CCLoader';
import TimeControl from '../TimeControl';
import Divider from '@/Atoms/Divider/Divider';
import Pagination from '@/Screens/Games/Pagination';
import {debounce} from 'lodash';
import useLoggedInUserStore from '@/Store/loggedInUserstore';
import useIntuitionTrainerState from '@/Store/IntuitionTrainerStore';
import styles from './styles';

import CCAutoComplete from '@/Atoms/CCAutoComplete';
import SubscriptionModal from '@/Screens/Subscription/components/Modals';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import backArrow from '@/Assets/Images/png/back_arrow.png';

import useChessboardColorTheme from '@/Hooks/ChessboardColorTheme/useChessboardColorTheme';

import LevelModal, {getLevel, LevelInfoBox, UpgradeLevel} from '../LevelModal';
import SlideModal from '@/Atoms/SlideModal';
import useGetUserActivePlanApi from '@/Hooks/UserActivePlanApi/useGetUserActivePlan';
import {useNonPersistedUserPlanStore} from '@/Store/userPlanStore';

const clearLocalStorage = trainerStore => {
  trainerStore.setIsGamesFetched(false);
  trainerStore.setGames([]);
  trainerStore.setTotalItems(0);
  trainerStore.setCurrentPage(1);
  trainerStore.setTotalPages(1);
  trainerStore.setText1('');
  trainerStore.setIsGameStarted(false);
};

const IntuitionTrainer = ({setShowDropDown, dashboardData}) => {
  const trainerStore = useIntuitionTrainerState();
  const [player, setPlayer] = useState<string>('');
  const [debouncedPlayerName, setDebouncedPlayerName] = useState<string>('');
  const [opponent, setOpponent] = useState<string>('');
  const [color, setColor] = useState<string>('white');
  const [opening, setOpening] = useState<string>('');
  const [showGames, setShowGames] = useState<boolean>(false);
  const [games, setGames] = useState<any[]>(trainerStore.games || []);
  const [whitePlayerData, setWhitePlayerData] = useState<string[]>([]);
  const [blackPlayerData, setBlackPlayerData] = useState<string[]>([]);
  const [filteredOpeningsData, setFilteredOpeningsData] = useState<any[]>([]);
  const [activatedInputNumber, setActivatedInputNumber] = useState<number>(0);
  const [level, setLevel] = useState<number>(0);
  const [showLevelModal, setShowLevelModal] = useState<boolean>(true);
  const [isFocusedW, setIsFocusedW] = useState(false);
  const [isFocusedB, setIsFocusedB] = useState(false);
  const [isFocusedO, setIsFocusedO] = useState(false);
  const [availableOpenings, setAvailaibleOpenings] = useState([]);
  const [accuracy, setAccuracy] = useState(0);
  const {subscriptionModalOpen, setSubscriptionModalOpen} =
    useNonPersistedUserPlanStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [isUpgradeVisible, setIsUpgradeVisible] = useState(false);
  const {dropDownClose, setDropDownClose} = useLoggedInUserStore();
  const [isFocused, setIsFocused] = useState(false);

  const [pgn, setPgn] = useState<string[]>([]);
  const [eco, setEco] = useState<string>('');
  const [orientation, setOrientation] = useState<string>('white');
  const [isGamesFetched, setIsGamesFetched] = useState<boolean>(
    trainerStore.isGamesFetched || false,
  );
  const [currentPage, setCurrentPage] = useState<number>(
    trainerStore.currentPage || 1,
  );
  const [totalPages, setTotalPages] = useState<number>(
    trainerStore.totalPages || 1,
  );
  const [totalItems, setTotalItems] = useState(trainerStore.totalItems || 0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [isGameStarted, setIsGameStarted] = useState<boolean>(
    trainerStore.isGameStarted || false,
  );
  const [text1, setText1] = useState<string>('');
  const [item, setItem] = useState<any>(trainerStore.item || '');
  const [objectData, setObjectData] = useState([]);
  const [itemSelected, setItemSelected] = useState<boolean>(true);

  const [threshold, setThreshold] = useState('');
  const [attempts, setAttempts] = useState(0);
  const {isMobileOrTab, isMobile, isDesktop, isTablet} = useMediaQuery();

  const colorTimeControls = [
    {control: 'As White', icon: 'white', selected: true},
    {control: 'As Black', icon: 'black', selected: false},
  ];

  const [selectedColorControl, setSelectedColorControl] = useState(
    colorTimeControls[0],
  );
  const chessboardColorTheme = useChessboardColorTheme();
  const [limitType, setLimitType] = useState('');
  const [usageLimit, setUsageLimit] = useState(0);
  const [planName, setPlanName] = useState('');

  const handleOnSuccessAuto = useCallback(
    data => {
      if (activatedInputNumber === 1) {
        setWhitePlayerData(data.player_names);
        setBlackPlayerData([]);
        setFilteredOpeningsData([]);
        setDropDownClose(false);
      } else if (activatedInputNumber === 2) {
        setBlackPlayerData(data.player_names);
        setWhitePlayerData([]);
        setFilteredOpeningsData([]);
        setDropDownClose(false);
      }
      console.log(data);
      setAvailaibleOpenings(data.available_openings);
    },
    [activatedInputNumber],
  );

  const handleOnFailedAuto = error => {
    console.log('error', error);
  };

  const handleOnSuccessGames = data => {
    setLimitType(data?.limit_type);
    setUsageLimit(data?.usage_limit);
    setPlanName(data?.plan_name);
    if (data?.is_permitted === false) {
      setSubscriptionModalOpen(true);
      return;
    }
    setGames(data.data);
    trainerStore.setGames(data.data);
    setShowGames(true);
    setTotalPages(data.total_pages);
    trainerStore.setTotalPages(data.total_pages);
    setItemsPerPage(data.items_per_page);
    trainerStore.setTotalItems(data.total_items);
    setTotalItems(data.total_items);
  };

  const handleOnFailedGames = error => {
    console.log('games error', error);
  };

  const percentageConfig = (nextLevel: number) => {
    return `Achieve at least ${threshold}% accuracy consistently for the last ${attempts} games to move to level ${nextLevel}.`;
  };

  const handleOnSuccessLevel = data => {
    const previousLevel = level;
    const levelApi = data?.level;
    const nextThreshold = parseInt(data?.next_threshold)?.toFixed?.(0);
    const attempts = data?.positions_count;
    const score = data?.last_entries.map(plan =>
      (parseFloat(plan.accuracy) * 100).toFixed(0),
    );
    const accuracyApi = parseInt((data?.average_accuracy * 100)?.toFixed?.(0));
    setLevel(levelApi);
    setAccuracy(accuracyApi);
    if (data?.level - previousLevel === 1) {
      setIsUpgradeVisible(true);
    }
    setAttempts(attempts);
    setThreshold(nextThreshold);
    setObjectData(score);
  };

  const handleOnFailedLevel = error => {
    console.log('error while fetching level', error);
  };

  const {mutate: getAutoComplete, isLoading: loadingAutoComplete} =
    useGetAutoCompletion(handleOnSuccessAuto, handleOnFailedAuto);

  const {
    mutate: getGames,
    data,
    isLoading,
  } = useGetGrandMastersGames(handleOnSuccessGames, handleOnFailedGames);

  const {
    mutate: getIntuitionTrainerLevel,
    // data,
    // isLoading,
  } = useGetIntuitionTrainerLevel(handleOnSuccessLevel, handleOnFailedLevel);

  const debouncePlayerSave = useCallback(
    debounce((text, isAlreadySelected, player, opponent) => {
      handlePlayerSearch(text, isAlreadySelected, player, opponent);
      setDebouncedPlayerName(text);
    }, 500),
    [],
  );

  const debounceOpponentSave = useCallback(
    debounce((text, isAlreadySelected, player, opponent) => {
      handleOpponentSearch(text, isAlreadySelected, player, opponent);
      setDebouncedPlayerName(text);
    }, 500),
    [],
  );

  useEffect(() => {
    const isAlreadySelected = itemSelected;
    debouncePlayerSave(player, isAlreadySelected, player, opponent);
    setWhitePlayerData([]);
    return debouncePlayerSave.cancel;
  }, [player, isFocusedW]);

  useEffect(() => {
    const isAlreadySelected = itemSelected;
    debounceOpponentSave(opponent, isAlreadySelected, player, opponent);
    setBlackPlayerData([]);
    return debounceOpponentSave.cancel;
  }, [opponent, isFocusedB]);

  const handlePlayerChange = useCallback(text => {
    setItemSelected(false);
    setPlayer(text);
  }, []);

  const handleOpponentChange = useCallback(text => {
    setItemSelected(false);
    setOpponent(text);
  }, []);

  const handlePlayerSearch = useCallback(
    (text, isAlreadySelected = false, player, opponent) => {
      setActivatedInputNumber(1);
      if (!isAlreadySelected) {
        getAutoComplete({
          text: text,
          color: 'white',
          white_player_name: player,
          black_player_name: opponent,
        });
      }
    },
    [],
  );

  const handleOpponentSearch = useCallback(
    (text, isAlreadySelected = false, player, opponent) => {
      setActivatedInputNumber(2);
      setOpponent(text);
      if (!isAlreadySelected) {
        getAutoComplete({
          text: text,
          color: 'black',
          white_player_name: player,
          black_player_name: opponent,
        });
      }
    },
    [],
  );
  useEffect(() => {
    setIsUpgradeVisible(true);
    const timeoutId = setTimeout(() => {
      setIsUpgradeVisible(false);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [level]);
  const handleOpeningSearch = useCallback(
    text => {
      setActivatedInputNumber(3);
      setWhitePlayerData([]);
      setBlackPlayerData([]);
      setOpening(text);
      console.log(availableOpenings);
      // let filteredArray = openingsData.filter(item => {
      //   return item.name.toLowerCase().includes(text.toLowerCase());
      // });

      let filteredArray = openingsData
        .filter(item => item.name.toLowerCase().includes(text.toLowerCase()))
        .filter(item => availableOpenings.includes(item.eco));

      setFilteredOpeningsData(filteredArray);
    },
    [availableOpenings],
  );

  const selectColorControl = useCallback(control => {
    for (var i = 0; i < colorTimeControls.length; i++) {
      colorTimeControls[i].selected = false;
      if (colorTimeControls[i].control === control) {
        colorTimeControls[i].selected = true;
        setSelectedColorControl(colorTimeControls[i]);
      }
    }
  }, []);

  const paginate = useCallback(
    (pageNumber: number) => {
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
        trainerStore.setCurrentPage(pageNumber);

        // Pass the correct pageNumber to fetch the updated page of games
        getGames({
          white_player_name: player,
          black_player_name: opponent,
          opening_name: eco,
          page: pageNumber, // Updated to use pageNumber
        });
      }
    },
    [player, opponent, eco, totalPages],
  );
  useEffect(() => {
    setDropDownClose(false);
  }, [player, opponent, opening]);

  useEffect(() => {
    trainerStore.setPlayerColor(trainerStore?.playerColor || 'white');
    getIntuitionTrainerLevel();
    setShowLevelModal(false);
  }, []);

  if (isLoading) {
    return <CCLoader loading />;
  }

  const FocusW = () => {
    setItemSelected(false);
    handlePlayerChange('');
    setIsFocusedW(true);
  };

  const FocusB = () => {
    handleOpponentChange('');
    setItemSelected(false);
    setIsFocusedB(true);
  };

  const FocusO = () => {
    console.log('handleFocusO...');
    handleOpeningSearch('');
    setItemSelected(false);
    setIsFocusedO(true);
  };

  const Blur = () => {
    setTimeout(() => {
      setIsFocusedW(false);
      setIsFocusedB(false);
      setIsFocusedO(false);
    }, 150);
  };
  const planMessage = `${limitType} limit of ${usageLimit} ${
    usageLimit === 1 ? 'game' : 'games'
  } is exhausted. Upgrade now to continue with the trainer.`;

  function handlePressOnGetLevel() {
    setShowLevelModal(true);
  }
  return (
    <Box style={{overflow: isMobileOrTab && 'hidden'}}>
      {!isMobileOrTab && showLevelModal && (
        <SlideModal
          visible={showLevelModal}
          type="center"
          customStyle={styles.levelModalCustom}>
          <Box
            style={{
              width: '100%',
              height: '100%',
              padding: 0,
              margin: 0,
              overflow: 'hidden',
            }}>
            <LevelModal
              level={level}
              accuracy={accuracy}
              config={percentageConfig}
              onClose={() => setShowLevelModal(false)}
              customStyl={styles.dimension}
              custombrownbg={styles.dimensions}
              objectData={objectData}
              isIntuitionTrainer={showLevelModal}
              isSquareTrainer={false}
              isCalculationTrainer={false}
            />
          </Box>
        </SlideModal>
      )}
      {isMobileOrTab && showLevelModal && (
        <SlideModal
          visible={showLevelModal}
          type="center"
          customStyle={styles.levelMobileModalCustom}>
          <Box
            style={{
              width: '100%',
              height: '100%',
              padding: 0,
              alignItems: 'center',
              margin: 0,
            }}>
            <LevelModal
              level={level}
              accuracy={accuracy}
              config={percentageConfig}
              onClose={() => setShowLevelModal(false)}
              objectData={objectData}
              isIntuitionTrainer={showLevelModal}
              isSquareTrainer={false}
              isCalculationTrainer={false}
            />
          </Box>
        </SlideModal>
      )}

      <ConditionalRender
        condition={!isGameStarted}
        childrenA={
          <>
            <Box
              overflow="visible"
              flexDirection={!isMobileOrTab ? 'row' : 'column'}
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
              style={[
                !isMobileOrTab
                  ? styles.intuitionContain
                  : styles.mobileContainer,
              ]}>
              <Box>
                <ConditionalRender
                  condition={!isGamesFetched}
                  childrenA={<></>}
                />
              </Box>
            </Box>

            <Box
              flex={1}
              overflow="visible"
              flexDirection={isPhone ? 'column' : 'row'}
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap">
              <Box
                flex={0.9}
                overflow="hidden"
                marginTop={25}
                style={{
                  alignSelf: 'flex-start',
                }}>
                <Box
                  style={{
                    maxWidth: 'calc(100vh - 180px)',
                  }}>
                  <ChessboardComponent
                    disabled={true}
                    colorTheme={chessboardColorTheme}
                  />
                </Box>
              </Box>
              <Box
                overflow="hidden"
                gap={10}
                style={[
                  !isMobileOrTab
                    ? styles.intuitionContainer
                    : styles.mobileContainer,
                ]}>
                {!isMobileOrTab && (
                  <Box>
                    <LevelInfoBox
                      isMobileOrTab={isMobileOrTab}
                      isUpgradeVisible={isUpgradeVisible}
                      level={level}
                      handlePressOnGetLevel={handlePressOnGetLevel}
                      getLevel={getLevel}
                      marginVertical={10}
                    />
                  </Box>
                )}
                <Box
                  alignItems={!isMobileOrTab && 'center'}
                  justifyContent="flex-start"
                  style={{
                    flexDirection: 'column',

                    marginTop: isMobileOrTab ? 0 : 18,
                    gap: 5,
                    marginHorizontal: isMobileOrTab && '4%',
                  }}>
                  <CCText
                    textAlign={!isMobileOrTab ? 'center' : 'justify'}
                    fontSize={20}
                    fontWeight={500}
                    color={'#262322'}>
                    Intuition Trainer
                  </CCText>
                  <CCText
                    style={{
                      marginBottom: !isMobileOrTab && 10,
                      width: isMobileOrTab ? '100%' : undefined,
                    }}
                    fontSize={14}
                    fontWeight="regular"
                    color="#4D3F37CC"
                    textAlign={!isMobileOrTab ? 'center' : 'justify'}>
                    Choose a game and try to recall a move grandmasters played
                    in it
                  </CCText>
                </Box>
                {isMobileOrTab && (
                  <Box>
                    <LevelInfoBox
                      isMobileOrTab={isMobileOrTab}
                      isUpgradeVisible={isUpgradeVisible}
                      level={level}
                      handlePressOnGetLevel={handlePressOnGetLevel}
                      getLevel={getLevel}
                      marginVertical={8}
                    />
                  </Box>
                )}
                <ConditionalRender
                  condition={!isGamesFetched}
                  childrenA={
                    <Box
                      style={
                        isMobileOrTab ? styles.input : styles.inputfrdesktop
                      }>
                      <CCAutoComplete
                        id={1}
                        listHeight={200}
                        iconPath="Images/ChessPieces/White-Rook.svg"
                        placeholder="Search White Player"
                        value={player}
                        loading={loadingAutoComplete}
                        onChangeText={handlePlayerChange}
                        handleFocus={FocusW}
                        handleBlur={Blur}
                        onKeyPress={() => setItemSelected(false)}
                        dropDownClose={dropDownClose}
                        noOfItemsToShow={isMobileOrTab ? 4 : undefined}
                        data={whitePlayerData}
                        key="white"
                        onPressOnItem={item => {
                          setItemSelected(true);
                          setPlayer(item.item);
                          setWhitePlayerData([]);
                          setActivatedInputNumber(3);
                          getAutoComplete({
                            text: '',
                            color: 'white',
                            white_player_name: item.item,
                            black_player_name: opponent,
                          });
                        }}
                      />
                      <CCAutoComplete
                        id={2}
                        listHeight={200}
                        iconPath="Images/ChessPieces/Black-Rook.svg"
                        placeholder="Search Black Player"
                        loading={loadingAutoComplete}
                        value={opponent}
                        onChangeText={handleOpponentChange}
                        handleFocus={FocusB}
                        handleBlur={Blur}
                        onKeyPress={() => setItemSelected(false)}
                        dropDownClose={dropDownClose}
                        noOfItemsToShow={isMobileOrTab ? 4 : undefined}
                        data={blackPlayerData}
                        key="black"
                        onPressOnItem={item => {
                          setItemSelected(true);
                          setOpponent(item.item);
                          setBlackPlayerData([]);
                          setActivatedInputNumber(3);
                          getAutoComplete({
                            text: '',
                            color: 'black',
                            white_player_name: player,
                            black_player_name: item.item,
                          });
                        }}
                      />

                      <CCAutoComplete
                        id={3}
                        listHeight={200}
                        iconPath="Images/ChessPieces/whiterookscale.svg"
                        placeholder="Search Opening"
                        value={opening}
                        loading={loadingAutoComplete}
                        onChangeText={handleOpeningSearch}
                        onKeyPress={() => setItemSelected(false)}
                        dropDownClose={!isFocusedO}
                        handleFocus={FocusO}
                        handleBlur={Blur}
                        data={filteredOpeningsData}
                        key="opening"
                        noOfItemsToShow={!isMobileOrTab ? 6 : 4}
                        onPressOnItem={item => {
                          console.log('opening selected: ', item);
                          setEco(item.item.eco);
                          setOpening(item.item.name);
                          setFilteredOpeningsData([]);
                        }}
                      />

                      <CCText
                        textAlign={isMobileOrTab ? 'left' : 'center'}
                        fontSize={14}
                        fontWeight="medium"
                        style={{
                          marginTop: 5,
                          zIndex: -10,
                          paddingLeft: isMobileOrTab ? '1.2%' : undefined,
                        }}>
                        Board Orientation
                      </CCText>
                      <Box
                        flexDirection="row"
                        flexWrap="wrap"
                        justifyContent="center"
                        gap={isMobileOrTab ? '8' : undefined}
                        style={[
                          {
                            color: CCColors.TextBoxes.Borders,
                            zIndex: -10,
                          },
                        ]}>
                        {colorTimeControls.map(e => (
                          <TimeControl
                            prefixImageSource={
                              'ChessPieces/' +
                              e.control.split(' ')[1] +
                              '-Rook.svg'
                            }
                            prefixImageWidth={15}
                            prefixImageHeight={21}
                            key={e.control}
                            enableAnimation={false}
                            fontSize={16}
                            fontWeight="DM Sans"
                            height={48}
                            flex={0.3}
                            control={e.control}
                            icon={e.icon}
                            selected={
                              selectedColorControl.control === e.control
                            }
                            onClick={() => {
                              let colArray = e.control.split(' ');
                              setOrientation(
                                e.control.split(' ')[1].toLowerCase(),
                              );
                              selectColorControl(e.control);
                              setColor(colArray[1].toLowerCase());
                              trainerStore.setPlayerColor(
                                colArray[1].toLowerCase(),
                              );
                            }}
                          />
                        ))}
                      </Box>

                      <Box style={{zIndex: -20}}>
                        <CCButton
                          style={{marginTop: 20}}
                          onPress={() => {
                            getGames({
                              white_player_name: player,
                              black_player_name: opponent,
                              opening_name: eco,
                              page: 1,
                            });
                            setIsGamesFetched(true);
                            trainerStore.setIsGamesFetched(true);
                          }}>
                          <CCText
                            fontSize={14}
                            fontWeight="medium"
                            style={{padding: 5}}>
                            Find Games
                          </CCText>
                        </CCButton>
                      </Box>
                    </Box>
                  }
                />

                <ConditionalRender
                  condition={isGamesFetched}
                  childrenA={
                    <>
                      <Box flexDirection="row" alignItems="center" gap={5}>
                        <Pressable
                          onPress={() => {
                            setIsGamesFetched(false);
                            clearLocalStorage(trainerStore);
                          }}>
                          <Image
                            resizeMethod="resize"
                            source={{uri: backArrow}}
                            alt="back arrow"
                            style={{width: 20, height: 20}}
                          />
                        </Pressable>
                        <CCText fontSize={16} fontWeight="medium">
                          {totalItems} games found
                        </CCText>
                      </Box>

                      <Box>
                        <ConditionalRender
                          condition={games.length > 0}
                          childrenA={
                            <CCFlatlist
                              style={styles.gamesList}
                              data={games}
                              ItemSeparatorComponent={Divider}
                              keyExtractor={(item, index) => index.toString()}
                              renderItem={({item, index}) => {
                                let text1 = `${item?.white_player_name} vs ${item?.black_player_name} - ${item?.result}`;
                                let text2 = `${item?.event_name} | ${
                                  item?.event_date?.split('.')[0]
                                }`;
                                let pgn = item?.pgn;

                                return (
                                  <Box
                                    flexDirection={'row'}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    margin={5}
                                    style={{
                                      paddingHorizontal: 20,
                                      paddingVertical: 10,
                                      backgroundColor:
                                        index % 2 === 1
                                          ? CCColors.Primary.lightBrown
                                          : CCColors.White,
                                      width: '100%',
                                    }}>
                                    <Box style={{width: '70%'}}>
                                      <CCText
                                        fontSize={16}
                                        fontWeight="regular">
                                        {text1}
                                      </CCText>
                                      <CCText
                                        fontSize={14}
                                        fontWeight="regular"
                                        color={CCColors.TextColor.Primary}>
                                        {text2}
                                      </CCText>
                                    </Box>
                                    <CCButton
                                      onPress={() => {
                                        setIsGameStarted(true);
                                        trainerStore.setIsGameStarted(true);
                                        setPgn(pgn);
                                        trainerStore.setPgn(pgn);
                                        setItem(item);
                                        trainerStore.setItem(item);
                                        setText1(text1);
                                        trainerStore.setText1(text1);
                                        if (isMobileOrTab) {
                                          setShowDropDown(false);
                                        }
                                      }}>
                                      <CCText fontSize={14} fontWeight="medium">
                                        Play
                                      </CCText>
                                    </CCButton>
                                  </Box>
                                );
                              }}
                            />
                          }
                          childrenB={
                            <CCText
                              textAlign="center"
                              style={{margin: isMobileOrTab ? 20 : undefined}}>
                              No games Found
                            </CCText>
                          }
                        />
                      </Box>
                      <ConditionalRender
                        condition={games.length > 0}
                        childrenA={
                          <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            paginate={paginate}
                          />
                        }
                      />
                    </>
                  }
                />
              </Box>
            </Box>
          </>
        }
      />
      <SubscriptionModal
        visible={subscriptionModalOpen}
        onClose={() => setSubscriptionModalOpen(false)}
        user={dashboardData?.user}
        planMessage={planMessage}
        currentPlan={planName}
      />
    </Box>
  );
};

export default React.memo(IntuitionTrainer);
