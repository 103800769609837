'use strict';

var io      = require('socket.io-client');
var sp      = require('schemapack');

sp.setValidateByDefault(false);

module.exports = exports = cc_ws_client;

var Socket = function(uri, opts) {

    this.io = io(uri, opts);
    this.io.opts = opts;

    this.io.schemaTypeDict = {};
    this.io.eventHandler = {};
    this.io.reconnect_callbacks=[];
    this.io.connected = false;
    this.io.authenticated = false;
    this.io.timeout = 3;

    var socket = this.io;

    this.io.on('auth_complete', function(data){
        if(data.userId)
        {
            this.connected = true;
            this.authenticated = true;
            socket.username = data.userId.toString();
        }
        else
        {
            this.connected = true;
            socket.username = '';
        }

    });

    this.io.on('disconnect', function() {
        this.connected = false;
    });

    this.io.on('connect', function() {
        this.connected = true;
    });

    // Loop through all keys (message types) in schemaTypeDict
    

    this.io.send_msg = function (event, data) {
        data.username = socket.username;
        console.log("events are : ",socket.schemaTypeDict);
        if(event in socket.schemaTypeDict)
            data = socket.schemaTypeDict[event].encode(data);

        //console.log("sending data : " + event + " : " + JSON.stringify(data));
        socket.emit(event, data);
    }



    this.io.registerMessageType = function (msgType, schema) {
        //console.log("registering schema and handler for event : " + msgType);
        if(schema)
            this.schemaTypeDict[msgType] = sp.build(schema);
    }

    this.io.registerMessageHandler = function (msgType, handler) {
        if(handler)
            this.eventHandler[msgType] = handler;
        
        socket.on(msgType, function(data) {
            // Decode data if schema exists for this message type
            if (socket.schemaTypeDict[msgType]) {
                data = socket.schemaTypeDict[msgType].decode(data);
            }
    
            // Call the appropriate event handler if it exists for this message type
            if (socket.eventHandler[msgType]) {
                socket.eventHandler[msgType](socket, data);
            }
        });
    }

    this.io.registerReconnectCallback = function (callback) {
        if(callback in this.reconnect_callbacks)
            ;//do nothing, its already registered
        else
            this.reconnect_callbacks.push(callback);
    }

}

function cc_ws_client(uri, opts)
{
    var obj = new Socket(uri, opts);
    return obj.io;
};
