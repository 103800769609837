import React from 'react';
import SlideModal from '@/Atoms/SlideModal';
import CCText from '@/Atoms/CCText';
import Box from '@/Atoms/Box';
import Spacer from '@/Atoms/Spacer';
import CCButton from '@/Atoms/CCButton';

type ConfirmationModalProp = {
  header?: string;
  bodyTxt: string;
  handleConfirmation: () => void;
  handleClose: () => void;
  open: boolean;
};
function ConfirmationModal({
  header = 'Confirm',
  bodyTxt,
  handleConfirmation,
  handleClose,
  open,
}: ConfirmationModalProp) {
  return (
    <SlideModal
      type="center"
      // width={0.3}
      modalHeaderTitle={header}
      visible={open}
      onClose={handleClose}>
      <Box margin={20}>
        <CCText>{bodyTxt}</CCText>
        <Spacer spacing={20} />
        <Box type="row-flex-end">
          <CCButton onPress={handleClose}>Cancel</CCButton>
          <Spacer spacing={10} horizontal />
          <CCButton
            onPress={() => {
              handleConfirmation?.();
              handleClose();
            }}>
            Confirm
          </CCButton>
        </Box>
      </Box>
    </SlideModal>
  );
}

export default ConfirmationModal;
