import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {getLocalStorage} from '@/Utils/CommonUtils';
import useChatRestClient from '@/Api/ChatRestClient';
interface WebinarPayload {
  user_key: string;
}
interface WebinarResponse {
  webinar_id: number;
  webinar_time: string;
  webinar_date: string;
  topic: string;
  join_url: string;
  page?: number;
}
export default function useWebinarApi(
  handleOnSuccess?: (data: any) => void,
): UseMutationResult<WebinarResponse[], Error, WebinarPayload> {
  const ChatRestClient = useChatRestClient();
  return useMutation<WebinarResponse[], Error, WebinarPayload>(
    async (payload: WebinarPayload) => {
      const token = await getLocalStorage('access_token');
      const options: any = {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json', // Add Content-Type header
        },
      };
      // Use POST instead of GET, sending the payload as JSON body
      return ChatRestClient.post(
        'tournament/v1/user_webinar_detail',
        JSON.stringify(payload), // Stringify the payload for JSON format
        options,
      );
    },
    {
      onSuccess: (data: WebinarResponse[]) => {
        // Call the provided handleOnSuccess function if it's available
        if (handleOnSuccess) {
          handleOnSuccess(data);
        }
      },
      onError: (error: Error) => {
        console.error('Failed to fetch webinar data:', error);
      },
    },
  );
}
