import { FontSizes, WINDOW_WIDTH, vs } from "../../Utils/Dimensions";

import CCColors from "../../Utils/CCColors";
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  orSeparator: {
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default styles;
