import React from 'react';
import {leaderBoardStyles} from '../../Puzzles/styles';
import CCText from '@/Atoms/CCText';
import {View, Image, ScrollView} from 'react-native';
import useGetLeaderboard from '@/Hooks/BooksApi/useGetLeaderboard';

const Leaderboard = React.memo(({bookId}: {bookId}) => {
  const {data, isLoading, error} = useGetLeaderboard(bookId);

  if (isLoading) return <CCText>Loading...</CCText>;
  if (error || !data)
    return <CCText>Error loading leaderboard or no data available.</CCText>;

  const rankIcons = {
    0: require('../../../Assets/Badges/1stPrize.png'),
    1: require('../../../Assets/Badges/2ndPrize.png'),
    2: require('../../../Assets/Badges/3rdPrize.png'),
  };

  const leaderboardData = data.slice(0, 10).map(item => ({
    ...item,
    rankIcon: rankIcons[item.rank] || null
  }));

  return (
    <View style={leaderBoardStyles.container}>
      <CCText
        fontWeight="600"
        textAlign="center"
        fontSize={16}
        color="#4D3F37"
        style={{
          lineHeight: 16,
          letterSpacing: 0.1,
          marginBottom: 20,
          marginTop: 10,
        }}>
        LEADERBOARD
      </CCText>
      <ScrollView>
        {leaderboardData.length ? (
          leaderboardData.map((item, index) => (
            <View
              key={index}
              style={[
                leaderBoardStyles.item,
                item.isYou && leaderBoardStyles.highlightedItem,
              ]}>
              <View style={leaderBoardStyles.rankContainer}>
                {item.rank <= 2 && item.rankIcon ? (
                  <Image
                    source={item.rankIcon}
                    style={leaderBoardStyles.rankIcon}
                  />
                ) : (
                  <CCText style={leaderBoardStyles.rankText}>
                    {item.rank >= 0 ? item.rank : 'N/A'}
                  </CCText>
                )}
              </View>
              <CCText numberOfLines={1} style={leaderBoardStyles.playerName}>
                {item.name ? item.name : 'N/A'}
              </CCText>
              <CCText style={leaderBoardStyles.points}>
                {item.solved_count}
              </CCText>
            </View>
          ))
        ) : (
          <CCText style={{textAlign: 'center'}}>No data available.</CCText>
        )}
      </ScrollView>
    </View>
  );
});

export default Leaderboard;
