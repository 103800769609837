import {StyleSheet} from 'react-native';
import CCColors from '@/Utils/CCColors';

const reportStyles = StyleSheet.create({
  reportForm: {
    padding: 20,
    // width: '60%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10%',
  },
  input: {
    height: 40,
    borderColor: CCColors.Grey.Grey,
    borderWidth: 1,
    marginBottom: 10,
    padding: 10,
    width: '100%',
  },
  displaySuccess: {
    height: 300,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  buttonContainer: {
    marginVertical: 2,
  },
  innerview: {
    width: '50%',
    backgroundColor: '#ECECEC',
    boxSizing: 'border-box',
    padding: 40,
    borderRadius: 10,
  },
});

export default reportStyles;
