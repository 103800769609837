import {ScrollView, StyleProp, ViewStyle} from 'react-native';

import CCColors from '../../Utils/CCColors';
import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';

const SafeAreaWrapper = ({
  children,
  style,
}: {
  children?: JSX.Element | JSX.Element[];
  style?: StyleProp<ViewStyle>;
}) => {
  //   const netInfo = useNetInfo();

  return (
    <SafeAreaView
      style={[
        // eslint-disable-next-line react-native/no-inline-styles
        {
          flex: 1,
          backgroundColor: CCColors?.Background,
        },
        style,
      ]}
      edges={['top', 'bottom', 'right', 'left']}>
      {/* {isIOS() && !netInfo.isInternetReachable ? (
        <View style={styles.noIntVw}>
          <Text style={styles.noIntTxt}>No Internet Connection.</Text>
        </View>
      ) : null} */}
      {children}
    </SafeAreaView>
  );
};

export default SafeAreaWrapper;
