import {useMutation} from '@tanstack/react-query';
import {getLocalStorage} from '@/Utils/CommonUtils';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useAssessmentApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<any, Error, void>(
    async (timeControl: string) => {
      if (!timeControl) {
        timeControl = 'Rapid';
      }

      const token = await getLocalStorage('access_token');
      const response = await ChessRestClient.post(
        'game/v1/learning/run_assessment/',
        {timeControl},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        },
      );

      return response;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) handleOnSuccess(data);
        else if (handleOnError) handleOnError(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
