import CCColors from '@/Utils/CCColors';
import {hs, isPhone} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  background: {
    backgroundColor: CCColors.Primary.lightBrown,
    height: '100vh',
    width: '100vw',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  backgroundImg: {
    height: '100%',
    width: '100%',
  },
  eachScoreBox: {
    position: 'relative',
    height: '13rem',
    borderTopColor: CCColors.Grey.Border,
    borderTopWidth: 1,
    borderTopColor: CCColors.Grey.Border,
    borderTopWidth: 1,
    borderBottomColor: CCColors.Grey.Border,
    borderBottomWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 35,
  },
  eachScoreBoxMobile: {
    position: 'relative',
    flexBasis: '45%',
    height: '9rem',
    // width: '7rem',
    borderColor: CCColors.Grey.Border,
    borderRadius: 8,
    backgroundColor: CCColors.Primary.lightBrown,
    margin: 5,
    // borderTopWidth: 1,
    // borderTopColor: CCColors.Grey.Border,
    // borderTopWidth: 1,
    // borderBottomColor: CCColors.Grey.Border,
    // borderBottomWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 35,
  },
  bottomTxt: {
    position: 'absolute',
    bottom: 10,
  },
  borderRight: {
    borderRightColor: CCColors.Grey.Border,
    borderRightWidth: 1,
  },
  container: {
    flexDirection: isPhone ? 'column' : 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start', // or 'space-between' if you want to spread the items
    alignItems: 'flex-start',
    marginHorizontal: isPhone ? '5%' : '15%',
  },

  item: {
    flexDirection: 'row', // Stack the icon and text vertically
    width: isPhone ? '98%' : '48%', // 48% of the container's width for each item
    margin: '1%', // 1% margin for spacing, adjust as necessary
    alignItems: 'center', // Center the items horizontally within each 'item' box
    justifyContent: 'flex-start', // Center the items vertically within each 'item' box
    marginBottom: isPhone ? 0 : 20, // Add some bottom margin if you want space between the rows
  },

  stage2IconStyle: {
    height: isPhone ? '15vw' : '5vw', // Fixed height for the icon
    width: isPhone ? '15vw' : '5vw', // Fixed width for the icon
    marginVertical: 10,
  },

  container2: {
    flexDirection: isPhone ? 'column' : 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between', // or 'space-between' if you want to spread the items
    alignItems: 'center',
    marginHorizontal: isPhone ? '5%' : '15%',
  },

  item2: {
    // flexDirection: 'row', // Stack the icon and text vertically
    width: isPhone ? '98%' : '45%', // 48% of the container's width for each item
    margin: '1%', // 1% margin for spacing, adjust as necessary
    alignItems: 'center', // Center the items horizontally within each 'item' box
    justifyContent: 'flex-start', // Center the items vertically within each 'item' box
    marginBottom: isPhone ? 0 : 20, // Add some bottom margin if you want space between the rows
  },

  stage3IconStyle: {
    height: '15vw', // Fixed height for the icon
    width: '15vw', // Fixed width for the icon
    marginVertical: 10,
  },
  backgroundImg2: {
    height: '90vh',
    width: '100vw',
    flex: 1,
  },
});
