import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import {Platform, StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  chatWrapper: {},
  hoverShadow: {
    // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add shadow on hover
  },
  PreQuestionsWrp: {
    // padding: 20,
    backgroundColor: CCColors.Primary.lightBrown,
    borderRadius: 8,
    width: '80%',
    margin: 'auto',
    // transition: 'box-shadow 0.3s ease', // Smooth transition for hover effect

    marginTop: '5%',
  },
  normalShadow: {
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 0.2,
        shadowRadius: 4,
      },
      android: {
        elevation: 4,
      },
      web: {
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Normal shadow for web
      },
    }),
  },
  buttonGirlIcon: {
    // position: 'absolute',
    // right: 5,
    // width: '35%',
    // bottom: '-3%',
    // zIndex: 20,
    // height: '140%',
    width: 35,
    height: 35,
    backgroundColor: CCColors.Primary.Default,
    borderRadius: 35,
  },
  buttonUserIcon: {
    // position: 'absolute',
    // right: 5,
    // width: '35%',
    // bottom: '-3%',
    // zIndex: 20,
    // height: '140%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    margin: 'auto',
    backgroundColor: CCColors.Primary.Brown,
    borderRadius: 30,
  },
  closeiIconWrp: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
    opacity: 0.8,
  },
  closeiIcon: {
    width: 36,
    height: 36,
    borderRadius: '50%',
    backgroundColor: CCColors.Primary.lightBrown,
  },
  sendBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    bottom: 5,
    marginLeft: '1%',
    flex: 0.1,
    justifyContent: 'center',
    width: '98%',
  },
  sendBtnMobile: {
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    bottom: 5,
    marginLeft: 0,
    flex: 0.1,
    justifyContent: 'center',
    width: '96%',
  },
  chatImage: {
    backgroundColor: CCColors.Red,
    height: 70,
    width: 70,
    borderRadius: 50,
    margin: 'auto',
  },
  chatIconBanner: {
    margin: 'auto',
  },
  sendBtnBg: {
    backgroundColor: CCColors.Primary.Default,
    padding: 5,
    height: 35,
    width: 35,
    borderRadius: 30,
    position: 'absolute',
    right: 10,
    top: '15%',
    // backgroundColor: '#fff',
  },
  userIcon: {
    height: 35,
    width: 35,
    borderRadius: 30,
    backgroundColor: CCColors.Red,
  },
  msgBoxWrapper: {
    maxWidth: '85%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: updateColorWithOpacity(CCColors.TextColor.Dark, 0.2),
    borderRadius: 8,
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
});

export default styles;
