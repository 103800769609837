interface ChessMove {
  player: string;
  score_change: number;
  inaccuracy: number;
  mistake: number;
  blunder: number;
  best_move: string;
  best_current_move: number;
  move: string;
  // TODO: will add others
}
export function evaluateMove(move: ChessMove): string {
  if (move?.blunder > 0) {
    return 'Blunder';
  } else if (move?.mistake > 0) {
    return 'Mistake';
  } else if (move?.inaccuracy > 0) {
    return 'Inaccuracy';
  }
  // return 'Normal Move';
  // else if (move?.score_change > 10) {
  //   return 'Brilliant';
  // } else if (move?.score_change > 5) {
  //   return 'Great Move';
  // }
  // // else if (move.score_change > 0) {
  // //   return 'Excellent';
  // // }
  // else if (move?.score_change === 0) {
  //   return 'Book';
  // } else if (move?.move === move?.best_move) {
  //   return 'Best Move';
  // } else {
  //   return 'Normal Move';
  // }
}

export function generateSummary(
  moves: ChessMove[],
): Record<string, Record<string, number>> {
  const summary: Record<string, Record<string, number>> = {};
  if (moves?.length === 0) return;
  for (const move of moves) {
    if (!summary[move.player]) {
      summary[move.player] = {
        Blunder: 0,
        Mistake: 0,
        Inaccuracy: 0,
        // Brilliant: 0,
        // 'Great Move': 0,
        // Excellent: 0,
        // Good: 0,
        // Book: 0,
        // 'Best Move': 0,
        // Miss: 0,
        // 'Normal Move': 0,
      };
    }

    const moveType = evaluateMove(move) || '';
    if (moveType) {
      summary[move.player][moveType]++;
    }
  }

  return summary;
}

export function convertTimeControlToMins(timeControl: string) {
  if (!timeControl)
    return timeControl;

  if (timeControl.indexOf('+') > 0) {
    const timeControlParts = timeControl.split('+');
    let mainTime = timeControlParts[0].trim();
    const incrementTime = timeControlParts[1].trim();

    if(parseInt(mainTime) % 60 === 0) {
      mainTime = parseInt(mainTime)/60;
    }

    return mainTime + 'm + ' + incrementTime;
  } else {
    let mainTime = timeControl;
    if(parseInt(timeControl) % 60 === 0) {
      mainTime = parseInt(timeControl)/60;
    } else {
      mainTime = timeControl;
    }

    return (mainTime + 'm')
  }
}

export function calculateWinningChances(score: number) {
  if (!score) {
    return {
      white: 50,
      black: 50,
    };
  }
  const baseChance = 50; // Both players start with a 50% chance
  const percentageChangePerPawn = 10; // 10% increase per pawn advantage

  // Calculate the change in percentage based on the score
  const change = (score / 100) * percentageChangePerPawn;

  // Calculate chances for white and black
  const whiteChance = baseChance - change;
  const blackChance = baseChance + change;

  return {
    white: whiteChance,
    black: blackChance,
  };
}

interface Errors {
  inaccuracies: number;
  mistakes: number;
  blunders: number;
}

interface PlayerErrors {
  opponent: Errors;
  me: Errors;
}

// export function calculateErrors(gameMoves): PlayerErrors {
//   const errors = {
//     opponent: {inaccuracies: 0, mistakes: 0, blunders: 0},
//     me: {inaccuracies: 0, mistakes: 0, blunders: 0},
//   };

//   gameMoves.forEach(move => {
//     if (move.player === 'opponent') {
//       errors.opponent.inaccuracies += move.inaccuracy;
//       errors.opponent.mistakes += move.mistake;
//       errors.opponent.blunders += move.blunder;
//     } else if (move.player === 'me') {
//       errors.me.inaccuracies += move.inaccuracy;
//       errors.me.mistakes += move.mistake;
//       errors.me.blunders += move.blunder;
//     }
//   });

//   return errors;
// }

interface Errors {
  inaccuracies: number;
  mistakes: number;
  blunders: number;
}

interface ColorErrors {
  white: Errors;
  black: Errors;
}

export const calculateErrorsByColor = (gameMoves): ColorErrors => {
  const errors: ColorErrors = {
    white: {inaccuracies: 0, mistakes: 0, blunders: 0},
    black: {inaccuracies: 0, mistakes: 0, blunders: 0},
  };

  gameMoves?.forEach(move => {
    if (move.color === 'white') {
      errors.white.inaccuracies += move.inaccuracy;
      errors.white.mistakes += move.mistake;
      errors.white.blunders += move.blunder;
    } else if (move.color === 'black') {
      errors.black.inaccuracies += move.inaccuracy;
      errors.black.mistakes += move.mistake;
      errors.black.blunders += move.blunder;
    }
  });

  return errors;
};
