import {useEffect, useRef} from 'react';

const StockfishEngine = ({fen, depth, variations, engineOn, onResult}) => {
  const stockfish: any = useRef(null);

  useEffect(() => {
    stockfish.current = new Worker('/stockfish.js');

    stockfish.current.onmessage = function (event) {
      // Check for lines that provide principal variation information
      if (event.data.startsWith('info') && event.data.includes('pv')) {
        const match = event.data.match(/multipv (\d+) .+ pv (.+)/);
        const regex = /baseTurn (\w)/;
        const scoreRegex = /score cp ([\-+]?[0-9]+)/;
        const matchBaseTurn = event.data.match(regex);
        const matchscoreRegex = event.data.match(scoreRegex);
        let baseTurn = 'w';
        let extractedScore = 0;
        // Check if we got a match and output the result
        if (matchBaseTurn) {
          baseTurn = matchBaseTurn[1];
        } else {
          console.log('No match found.');
        }
        if (matchscoreRegex) {
          console.log('matchscoreRegex', matchscoreRegex);
          extractedScore = matchscoreRegex[1];
        } else {
          console.log('No match found.');
        }
        if (match) {
          console.log(event.data, 'event.data');
          const variationNumber = parseInt(match[1], 10);
          const movesSAN = match[2].split(' '); // Splits the PV line into individual SAN moves
          onResult?.(variationNumber, movesSAN, baseTurn, extractedScore);
        }
      }
    };

    return () => {
      stockfish.current.terminate();
    };
  }, []);

  useEffect(() => {
    console.log("depth: ",depth);
    if (engineOn) {
      stockfish.current.postMessage('ucinewgame');
      stockfish.current.postMessage('isready');
      stockfish.current.postMessage(`position fen ${fen}`);
      stockfish.current.postMessage(
        `setoption name MultiPV value ${variations}`,
      );
      stockfish.current.postMessage(`go depth ${depth} movetime 10000`);
    } else {
      stockfish.current.postMessage('stop');
    }
  }, [fen, depth, variations, engineOn]);
};

export default StockfishEngine;
