import React, {useState, useEffect} from 'react';
import Box from '@/Atoms/Box';
import CCSelect from '@/Atoms/CCSelect';
import CCTextInput from '@/Atoms/CCTextInput';
import CCText from '@/Atoms/CCText';
import CCButton from '@/Atoms/CCButton';
import Divider from '@/Atoms/Divider/Divider';
import ChallengeUserComponent from '../ChallengeUserComponent';
import ConditionalRender from '@/Atoms/ConditionalRender';
import useGetTagStartingWith from '@/Hooks/GameLibraryApi/useGetTagStartingWithApi';
import useLoggedInUserStore from '@/Store/loggedInUserstore';
import {GestureResponderEvent, FlatList, View} from 'react-native';
import styles from './styles'; // Import styles
import CCColors from '@/Utils/CCColors';
import {FontSizes, isPhone} from '@/Utils/Dimensions';

interface GameLibraryComponentProps {
  handleSelectedGameStage: (stage: string) => void;
  handleSelectedTag: (tag: string) => void;
  handleOpponentName: (name: string) => void;
  searchPlayers: (term: string) => void;
  searchPlayersResult: any[]; // Update type based on actual data structure
  user: any; // Update type based on actual data structure
  gameMode: string;
}

const GameLibraryComponent: React.FC<GameLibraryComponentProps> = ({
  handleSelectedGameStage,
  handleSelectedTag,
  handleOpponentName,
  searchPlayers,
  searchPlayersResult,
  user,
  gameMode,
}) => {
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [selectedGameStage, setSelectedGameStage] = useState<{
    value: string;
    label: string;
  }>({value: '0', label: 'Opening'});
  const [selectedOpponent, setSelectedOpponent] = useState<{
    value: string;
    label: string;
  }>({value: '0', label: 'Against Caissa'});

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleGameStageChange = (value: string) => {
    setSelectedGameStage({value, label: getLabelForValue(value)});
    handleSelectedTag('');
    setSearchTerm('');
  };

  const [gameStageOptions] = useState<{value: string; label: string}[]>([
    {value: '0', label: 'Opening'},
    {value: '1', label: 'Middle Game'},
    {value: '2', label: 'End Game'},
  ]);

  const [opponentOptions] = useState<{value: string; label: string}[]>([
    {value: '0', label: 'Challenge Caissa'},
    {value: '1', label: 'Challenge a friend'},
  ]);

  const {
    mutate: GetTagStartingWith,
    data: GetTagStartingWithData,
    isLoading: GettingTags,
  } = useGetTagStartingWith();

  const handleTagnameChange = (tagname: string) => {
    setSearchTerm(tagname);
    const gameStage = selectedGameStage.label;
    GetTagStartingWith({pattern: tagname, game_stage: gameStage});
  };

  const handleFocus = () => {
    handleTagnameChange('');
    handleSelectedTag('');
    setIsFocused(true);
    console.log("handleFocus");
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 300);
    console.log("handleBlur");
  };

  const getLabelForValue = (value: string) => {
    const option = gameStageOptions.find(option => option.value === value);
    return option ? option.label : 'Label not found';
  };

  useEffect(() => {
    if (GetTagStartingWithData && GetTagStartingWithData.tags) {
      setSearchResults(GetTagStartingWithData.tags);
    } 
  }, [GetTagStartingWithData]);

  useEffect(() => {
    if (gameMode === 'Play and Learn') {
      handleOpponentName('0:Caissa');
    }
  }, [gameMode]);

  const handleTagSelect = (selectedTag: string) => {
    setSearchTerm(selectedTag);
    handleSelectedGameStage(selectedGameStage.label);
    handleSelectedTag(selectedTag);
    setSearchResults([]);
    if (selectedOpponent.value === '0') {
      handleOpponentName('0:Caissa');
    }
  };

  useEffect(() => {
    if (selectedOpponent.value === '1') {
      handleOpponentName('');
    }
  }, [selectedOpponent]);

  return (
    <Box overflow="visible" flexDirection="column">
      <Box style={styles.container} overflow="visible" flexDirection="row">
        <Box>
          <View
            style={{width: isPhone ? '90vw' : '15vw', alignSelf: 'flex-start'}}>
            <CCSelect
              items={gameStageOptions}
              selectedValue={selectedGameStage.value}
              onValueChange={value => handleGameStageChange(value)}
            />
          </View>
        </Box>

        <Box overflow="visible" style={{zIndex: -1}}>
          <ConditionalRender
            condition={isFocused}
            childrenA={
              <FlatList
                style={[
                  styles.autoCompleteFlatList,
                  {
                    borderWidth: searchResults.length > 0 ? 1 : 0,
                    zIndex: 10,
                    maxHeight: 250,
                  },
                ]}
                data={searchResults.map(tag => ({tag}))}
                renderItem={({item}) => (
                  <>
                    <CCButton
                      style={{backgroundColor: 'inherit'}}
                      onPress={() => handleTagSelect(item.tag[0])}>
                      <CCText>{item.tag[0]}</CCText>
                    </CCButton>
                    <Divider style={styles.divider} />
                  </>
                )}
                keyExtractor={(item, index) => index.toString()}
              />
            }
          />
          <CCTextInput
            label=""
            placeholder="Tag for the game you want to practice"
            value={searchTerm}
            onChangeText={handleTagnameChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={styles.input}
            fontSize={14}
            placeholderTextColor={CCColors.TextColor.Grey}
          />
        </Box>
      </Box>
      {gameMode === 'Play' && (
        <Box
          style={[styles.container, {zIndex: -10}]}
          overflow="visible"
          flexDirection="row">
          <Box style={{marginBottom: 4}}>
            <View
              style={{
                width: isPhone ? '90vw' : '15vw',
                alignSelf: 'flex-start',
              }}>
              <CCSelect
                items={opponentOptions}
                selectedValue={selectedOpponent.value}
                onValueChange={value =>
                  setSelectedOpponent({value, label: getLabelForValue(value)})
                }
              />
            </View>
          </Box>
          {selectedOpponent.value === '1' && (
            <Box style={{marginBottom: 4, zIndex: -10}} overflow="visible">
              <ChallengeUserComponent
                handleOpponentName={handleOpponentName}
                searchPlayers={searchPlayers}
                searchPlayersResult={searchPlayersResult}
                user={user}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default GameLibraryComponent;
