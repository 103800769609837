import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useCheckCircleChessGamesAnalyzedRule() {
    return useMutation(
      async ({
        userId,
    }: {
        userId: string;
    }) => {
        const response = await ChessRestClient.post('learning/check_circlechess_games_analyzed_rule/', {
          user_id: userId,
        });
        console.log("useCheckCircleChessGamesAnalyzedRule api respoinse is :", response);

        return response.data;
      }
    );
  }
  