import React from 'react';
import { View } from 'react-native';

export interface ISpacerProps {
  spacing?: number;
  horizontal?: boolean;
}

export default function Spacer(props: ISpacerProps) {
  const { spacing = 8, horizontal = false } = props;

  return (
    <View
      // eslint-disable-next-line react-native/no-inline-styles
      style={{
        marginTop: horizontal ? 0 : spacing,
        marginLeft: horizontal ? spacing : 0,
      }}
    />
  );
}
