import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import {FontSizes, hs, vs} from '@/Utils/Dimensions';
import {Image, View} from 'react-native';
import LottieView from 'react-native-web-lottie';
import tick from '@/Assets/Images/icons/tick.png';
import {useEffect, useState} from 'react';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';

function TimeControl(props) {
  // Do nothing, default function!
  const dumb = () => {};
  const {isMobileOrTab} = useMediaQuery();

  const {
    control,
    icon,
    selected = false,
    flex,
    animate = false,
    onClick = {dumb},
    onHover = {dumb},
    handleCustomConfigChange = {dumb},
    width = hs(134),
    height = vs(134),
    fontSize = FontSizes[32],
    fontWeight = 'thunder',
    enableAnimation = true,
    prefixImageSource,
    prefixImageWidth = 16,
    prefixImageHeight = 16,
  } = props;

  const color = selected ? CCColors.LightYellow : CCColors.White;
  const circleColor = selected ? CCColors.White : CCColors.Primary.lightBrown;

  const borderWidth = selected ? 1 : 0.5;

  const getAnimation = () => {
    switch (control) {
      case '10s':
        return '10s.json';
      case '20s':
        return '20s.json';
      case '30s':
        return '30s.json';
      default:
        return '10s.json';
    }
  };

  useEffect(() => {
    if (control === 'settings') {
      handleCustomConfigChange(selected);
    }
  }, [selected]);

  return (
    <Box
      flexDirection="column"
      onPress={onClick}
      style={{
        margin: 2,
        justifyContent: 'center',
        height: height,
        width:
          isMobileOrTab && prefixImageSource
            ? 160
            : isMobileOrTab
            ? 104
            : width,
        backgroundColor: color,
        borderColor: selected
          ? CCColors.TextColor.Primary
          : CCColors.TimeControl.BorderColor,
        borderWidth: borderWidth,
        borderRadius: 8,
        gap: isMobileOrTab ? '0.7%' : undefined,
      }}>
      <View
        style={{
          width: 22,
          height: 22,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 11,
          backgroundColor: circleColor,
          position: 'absolute',
          right: 12,
          top: 12,
        }}>
        {selected && <img src={tick} style={{width: '70%'}} />}
      </View>
      <View
        style={{
          flexDirection: 'column',
          alignItems: prefixImageSource ? 'flex-start' : 'center',
        }}>
        {control !== 'settings' && (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}>
            {prefixImageSource && (
              <Box
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                gap={4}
                marginLeft="10%">
                <Image
                  resizeMethod="resize"
                  source={require('@/Assets/Images/' + prefixImageSource)}
                  alt="back arrow"
                  style={{
                    width: prefixImageWidth,
                    height: prefixImageHeight,
                  }}
                />
                <CCText
                  textAlign="center"
                  fontWeight={fontWeight}
                  fontSize={isMobileOrTab ? 14 : fontSize}>
                  {control}
                </CCText>
              </Box>
            )}

            {!prefixImageSource && (
              <CCText
                textAlign="center"
                fontWeight={fontWeight}
                fontSize={fontSize}>
                {control}
              </CCText>
            )}
          </View>
        )}
        {control !== 'settings' && enableAnimation && selected && (
          <View style={{height: 32, width: 32, marginTop: 4}}>
            <LottieView
              source={require(`@/Assets/Animation/` + getAnimation())}
              autoPlay
              loop={selected}
            />
          </View>
        )}
        {control !== 'settings' && enableAnimation && !selected && (
          <View style={{height: 32, width: 32, marginTop: 4}}>
            <LottieView
              source={require(`@/Assets/Animation/` + getAnimation())}
            />
          </View>
        )}
        {control === 'settings' && (
          <CCText
            textAlign="center"
            fontWeight="thunder"
            style={{color: CCColors.Green, paddingHorizontal: vs(32)}}
            fontSize={FontSizes[18]}>
            Additional Settings
          </CCText>
        )}
      </View>
    </Box>
  );
}

export default TimeControl;
