import Box from "@/Atoms/Box";
import CCText from "@/Atoms/CCText";
import {PieceImages} from 'chessboard-package';
import { Image } from "react-native";
import { ScrollView, View } from 'react-native';
import React, { useRef, useEffect, useState } from 'react';
import { vs, isPhone, FontSizes, hs, isPortrait, SCREEN_WIDTH } from '@/Utils/Dimensions';
import CCColors from '@/Utils/CCColors';


const getPieceValue = (piece) => {
    switch (piece) {
      case 'P':
        return 1; // Pawn
      case 'N':
        return 2.99;
      case 'B':
        return 3; // Knight or Bishop
      case 'R':
        return 5; // Rook
      case 'Q':
        return 7; // Queen
      default:
        return 0; // Default value for unknown pieces
    }
  };

function CapturedPieces(props) {
    const { color, whitePieces, blackPieces } = props;

    const colorPrefix = color === 'black' ? 'w' : 'b';
    const pieces = color === 'black' ? whitePieces : blackPieces;

    // console.log('Captured Pieces = ', pieces, color);

    const calculateTotalValue = (pieces) => {
      return pieces.reduce((total, piece) => total + getPieceValue(piece), 0);
    };
  
    const piecesTotalValue = calculateTotalValue(pieces);
    const otherPiecesTotalValue = calculateTotalValue(color === 'white' ? whitePieces : blackPieces);
  
    const pieceValueDifference = Math.round(piecesTotalValue - otherPiecesTotalValue);

    const sortedPieces = pieces.sort((a, b) => getPieceValue(a) - getPieceValue(b));

  // Group the sorted pieces
    const groupedPieces = sortedPieces.reduce((acc, piece) => {
        const pieceValue = getPieceValue(piece);
        if (!acc[pieceValue]) {
        acc[pieceValue] = []; // Initialize array for each piece value
        }
        acc[pieceValue].push(piece); // Add piece to its corresponding value
        return acc;
    }, {});

    const scrollViewRef = useRef();
    

    // Function to scroll to the bottom of the ScrollView
    const scrollToBottom = () => {
        
        if (scrollViewRef.current) {
            scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });
        }
        console.log("Scroll to bottom");

    };

    const renderPieces = () => {
      let output = [];
      let currentType = null;
      let currentGroup = [];
  
      // Iterate over grouped pieces
      Object.entries(groupedPieces)
          .sort(([valueA], [valueB]) => valueA - valueB) // Sort the groups by piece value
          .forEach(([value, piecesOfType]) => {
              if (currentType && currentType !== value) {
                  output.push(renderGroup(currentGroup)); // Render the current group
                  currentGroup = []; // Reset the currentGroup array
              }
              currentGroup = [...currentGroup, ...piecesOfType]; // Add pieces to the currentGroup array
              currentType = value; // Update the currentType
          });
  
      if (currentGroup.length > 0) {
          output.push(renderGroup(currentGroup)); // Render the last group
      }
  
      return output;
    };
  
    const renderGroup = (pieces) => {
      const imageSize  = ((isPhone||isPortrait) ? SCREEN_WIDTH * 0.05 : SCREEN_WIDTH * 0.02);
      const groupWidth = pieces.length * imageSize/10;
      
      return (

          <View style={{ flexDirection: 'row', position: 'relative' }}>
            <View style = {{ marginLeft: 0, marginRight: imageSize + 1.5*groupWidth, position: 'relative', marginBottom: 20}}>
              {pieces.map((piece, index) => (
                  <React.Fragment key={index}>
                      <View style={{ position: 'absolute' }}>
                          <Image
                              source={colorPrefix == 'b' ? PieceImages[colorPrefix + piece + 'C'] : PieceImages[colorPrefix + piece]}
                              style={{
                                  height: imageSize,
                                  width: imageSize,
                                  position: 'relative',
                                  left: index* imageSize/4,
                                   // Adjust positioning for overlapping effect
                                  // 
                              }}
                          />
                      </View>
                  </React.Fragment>
               ))}
            </View>
            
          </View>
      );
    };
  
  
    

    // Automatically scroll to bottom when component mounts or when new content is added
    useEffect(() => {
        scrollToBottom();
    }, [pieces]);

    return (
        // <ScrollView style={{flex: 8, flexDirection: 'row-reverse'}} ref={scrollViewRef} contentContainerStyle={{flexGrow: 1, justifyContent: 'space-between'}} horizontal={true} >
            <Box overflow="hidden" flexDirection="row" style={{ marginLeft: '2%', alignItems: 'center', borderWidth: 1, borderColor: '#2623221A', borderStyle: 'dotted', width: (isPhone||isPortrait) ? '45%' : '45%' }}>
                {renderPieces()} 
                {<CCText>
                  {pieceValueDifference > 0 ? '+' + pieceValueDifference : '' }
                </CCText>}
            </Box>
        // </ScrollView>
    );
}

export default CapturedPieces;