import React from 'react';
import {TouchableOpacity, TouchableOpacityProps, ViewStyle} from 'react-native';

type FlexProps = Pick<
  ViewStyle,
  | 'flex'
  | 'flexDirection'
  | 'justifyContent'
  | 'alignItems'
  | 'alignContent'
  | 'flexGrow'
  | 'flexShrink'
  | 'flexBasis'
  | 'flexWrap'
  | 'overflow'
  | 'margin'
  | 'marginBottom'
  | 'marginEnd'
  | 'marginHorizontal'
  | 'marginLeft'
  | 'marginRight'
  | 'marginTop'
  | 'marginVertical'
  | 'gap'
>;

export interface IBoxProps extends TouchableOpacityProps, FlexProps {
  type?:
    | 'center'
    | 'row-center'
    | 'row-center-between'
    | 'row-center-space-around'
    | 'row-flex-end'
    | 'row-top-between'
    | 'column-flex-between'
    | 'row-top-start'
    | 'row-flex-start';
}

export default function Box(props: IBoxProps) {
  const {
    flex,
    overflow = 'auto',
    flexDirection = 'column',
    justifyContent = 'flex-start',
    alignItems = 'stretch',
    flexGrow,
    flexShrink,
    flexBasis,
    flexWrap,
    alignContent,
    margin,
    marginBottom,
    marginEnd,
    marginHorizontal,
    marginLeft,
    marginRight,
    marginTop,
    marginVertical,
    onPress,
    type,
    gap,
    ...rest
  } = props;

  let centerStyle = {};
  if (type === 'center') {
    centerStyle = {
      justifyContent: 'center',
      alignItems: 'center',
    };
  } else if (type === 'row-center') {
    centerStyle = {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    };
  } else if (type === 'row-center-between') {
    centerStyle = {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    };
  } else if (type === 'row-center-space-around') {
    centerStyle = {
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
    };
  } else if (type === 'row-flex-start') {
    centerStyle = {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    };
  } else if (type === 'row-flex-end') {
    centerStyle = {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    };
  } else if (type === 'row-top-between') {
    centerStyle = {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    };
  } else if (type === 'row-top-start') {
    centerStyle = {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    };
  } else if (type === 'column-flex-between') {
    centerStyle = {
      justifyContent: 'flex-between',
      alignItems: 'flex-between',
    };
  }

  // Custom style to hide the scrollbar
  const scrollStyle = {
    overflow: overflow === 'auto' ? 'visible' : overflow,
    WebkitOverflowScrolling: 'touch', // For smooth scrolling on iOS
    scrollbarWidth: 'none', // Hide scrollbar in Firefox
    msOverflowStyle: 'none', // Hide scrollbar in IE and Edge
    '::-webkit-scrollbar': {
      display: 'none', // Hide scrollbar in WebKit browsers
    },
  };

  const mergedStyle = {
    ...scrollStyle,
    flex,
    gap,
    flexDirection,
    justifyContent,
    alignItems,
    flexGrow,
    flexShrink,
    flexBasis,
    flexWrap,
    alignContent,
    margin,
    marginBottom,
    marginEnd,
    marginHorizontal,
    marginLeft,
    marginRight,
    marginTop,
    marginVertical,
    ...centerStyle,
  };

  return (
    <TouchableOpacity
      activeOpacity={0.5}
      disabled={!onPress}
      {...rest}
      onPress={onPress}
      style={[mergedStyle, props.style]}
    />
  );
}
