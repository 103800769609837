import ChessRestClient from '@/Api/ChessRestClient';
// import {SetAvatarResponse} from './types';
import {useMutation} from '@tanstack/react-query';

export default function useAddOTBGameApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<
    any,
    Error,
    {
      sms_sent_timestamp: string;
      tournament_id: number,
      tournament_name: string;
      player_name: string;
      player_rating: string;
      round_id: string;
      opponent_name: string;
      opponent_fide_rating: string;
      timeClass: string;
      round_color: string;
      round_result: number;
      game_uuid: string;
    }
  >(
    async details => {
      console.log('details', details);
      const response = await ChessRestClient.post(
        `learning/create-otb-game/`,
        {
          game_date: details.sms_sent_timestamp,
          tournament_id: details.tournament_id || null,
          tournament_name: details.tournament_name,
          player_name: details.player_name,
          round_no: parseInt(details.round_id),
          opponent_name: details.opponent_name,
          opponent_rating: parseInt(details.opponent_fide_rating),
          time_class: details.timeClass,
          player_rating: details.player_rating,
          color: details.round_color,
          result: details.round_result,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );
      return response.data;
    },
    {
      onSuccess: data => {
        handleOnSuccess?.(data);
      },
      onError: data => {
        handleOnError?.(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
