import ChessRestClient from '@/Api/ChessRestClient';
// import {SetAvatarResponse} from './types';
import {useMutation} from '@tanstack/react-query';

export default function useGetOTBGameDetails(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<
    any,
    Error,
    {
      game_uuid: string;
    }
  >(
    async details => {
      const {game_uuid} = details;
      const response = await ChessRestClient.get(
        `learning/otb-game-details/${game_uuid}/`,
        // `learning/otb-games/?game_uuid=${game_uuid}`,
      );
      return response.data;
    },
    {
      onSuccess: data => {
        handleOnSuccess?.(data);
      },
      onError: data => {
        handleOnError?.(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
