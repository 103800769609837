const fontFamily = 'DMSans';
const thunderFamily = 'Thunder';

export type FontWeight = 'regular' | 'medium' | 'semibold' | 'bold' | 'thunder';

export default function fontWeightToFamily(weight: FontWeight = 'regular') {
  switch (weight) {
    case 'regular':
      return fontFamily + '-' + 'Regular';

    case 'medium':
      return fontFamily + '-' + 'Medium';

    case 'semibold':
      return fontFamily + '-' + 'SemiBold';

    case 'bold':
      return fontFamily + '-' + 'Bold';

    case 'thunder':
      return thunderFamily;
    default:
      return fontFamily + '-' + 'Regular';
  }
}

// const processFontFamily = (value) => {
//   return value ?? fontWeightToFamily("regular");
// };

// const styles = StyleSheet.create({
//   text: {
//     fontFamily: processFontFamily("fontFamily"),
//   },
// });
