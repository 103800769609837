import * as React from 'react';
import {Text, View, StyleSheet} from 'react-native';
import {PanGestureHandler} from 'react-native-gesture-handler';
import Animated, {
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';

// interface componentNameProps {}

const Draggable = ({children}) => {
  const translateX = useSharedValue(0);
  const translateY = useSharedValue(0);

  const panGesture = useAnimatedGestureHandler({
    onStart(event, context) {
      console.log('onStart');
    },
    // onStart: (event, context) => {
    //   console.log('onStart', event, context);
    //   context.startX = translateX.value;
    //   context.startY = translateY.value;
    // },
    onActive: (event, context) => {
      console.log('onActive', event, context);
      translateX.value = context.startX + event.translationX;
      translateY.value = context.startY + event.translationY;
    },
    onFinish: (event, context) => {
      console.log('onFinish', event, context);
    },
  });

  const animatedStyle = useAnimatedStyle(() => {
    return {
      zIndex: 1,
      transform: [
        {
          translateX: translateX.value,
          translateY: translateY.value,
        },
      ],
    };
  });

  return (
    <Animated.View style={animatedStyle}>
      <PanGestureHandler onGestureEvent={panGesture}>
        <Animated.View>{children}</Animated.View>
      </PanGestureHandler>
    </Animated.View>
  );
};

export default Draggable;

const styles = StyleSheet.create({
  container: {},
});
