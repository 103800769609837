import ChessRestClient from '@/Api/ChessRestClient';
// import {SetAvatarResponse} from './types';
import {useMutation} from '@tanstack/react-query';

export default function useSaveAlternateMoves(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<
    any,
    Error,
    {
      gameMoveId: number;
      alternateMoves: string;
    }
  >(
    async details => {
      const {gameMoveId, alternateMoves} = details;
      const response = await ChessRestClient.post('save-alternate-moves/', {
        game_move_id: gameMoveId,
        moves: alternateMoves,
      });
      return response.data;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess && data.success) handleOnSuccess(data);
        else if (handleOnError) handleOnError(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
