import ChessRestClient from '@/Api/ChessRestClient';
import {getLocalStorage} from '@/Utils/CommonUtils';
import {useMutation} from '@tanstack/react-query';

export default function useUploadScoreSheetApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<
    any,
    Error,
    {
      game_uuid?: string;
      file?: any;
    }
  >(
    async ({game_uuid, file}) => {
      console.log('file', file);
      const token = await getLocalStorage('access_token');

      const formData = new FormData();
      formData.append('file', file);
      // formData.append('name', file.name);
      formData.append('game_uuid', game_uuid);
      const options = {
        // url: 'learning/save-otb-game-upload',
        // method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      };

      const response = await ChessRestClient.post(
        'learning/save-otb-game-upload/',
        // 'import_book',
        formData,
        options,
      );
      return response.data;
    },
    {
      onSuccess: data => {
        handleOnSuccess?.(data);
      },
      onError: data => {
        handleOnError?.(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
