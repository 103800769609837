import CCColors, {updateColorWithOpacity} from '../../Utils/CCColors';

import {FontSizes} from '../../Utils/Dimensions';
import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  containerStyle: {},

  tabBar: {
    flexDirection: 'row',
  },
  tabItem: {
    flex: 1,
    alignItems: 'center',
    padding: 16,
    paddingTop: 5,
  },

  labelStyle: {
    fontSize: FontSizes[14],
    fontWeight: '400',
  },

  sliderParent: {
    backgroundColor: updateColorWithOpacity(CCColors.Grey.Grey, 0.2),
  },

  sliderContainerStyle: {
    height: 3,
    borderRadius: 4,
    backgroundColor: CCColors.Primary.Default,
    justifyContent: 'center',
    alignContent: 'center',
  },
});

export default styles;
