import CCColors from '@/Utils/CCColors';
import React, {useEffect} from 'react';
import {StyleSheet, ViewStyle} from 'react-native';
import {
  TabView,
  SceneMap,
  NavigationState,
  Route,
  TabBar,
} from 'react-native-tab-view';
import CCText from '../CCText';
import Box from '../Box';

export interface TabItem {
  key: string;
  label: string;
  component: React.FC;
}

interface CCTabsProps {
  tabItems: TabItem[];
  handleTabChange: ({route}) => void;
  tabIndex: number;
  tabStyle: ViewStyle;
}

export const renderUnderlineTabBar = props => (
  <TabBar
    {...props}
    indicatorStyle={{backgroundColor: CCColors.Primary.Default}}
    tabStyle={{width: 'auto'}}
    style={{
      backgroundColor: 'transparent',
      ...(props?.tabStyle ? props?.tabStyle : {}),
    }}
    labelStyle={{
      color: CCColors.TextColor.Primary,
      textTransform: 'capitalize',
      width: '100%',
      // fontSize: 20,
    }}
    activeColor={CCColors.Primary.Default}
    onTabPress={props?.handleTabChange}
  />
);
const CCTabs: React.FC<CCTabsProps> = ({
  tabItems,
  handleTabChange,
  tabIndex = 0,
  tabStyle,
}) => {
  const [index, setIndex] = React.useState<number>(tabIndex);

  useEffect(() => {
    setIndex(tabIndex);
  }, [tabIndex]);

  const routes: Route[] = tabItems.map(item => ({
    key: item.key,
    title: item.label,
  }));

  const renderScene = SceneMap<Record<string, unknown>>(
    tabItems.reduce((acc, item) => {
      acc[item.key] = item.component;
      return acc;
    }, {}),
  );

  const navigationState: NavigationState<Route> = {
    index,
    routes,
  };

  return (
    <TabView
      navigationState={navigationState}
      renderScene={renderScene}
      renderTabBar={tempProps =>
        renderUnderlineTabBar({
          ...tempProps,
          tabStyle,
          handleTabChange,
        })
      }
      lazy
      onIndexChange={setIndex}
      // animationEnabled={false}
    />
  );
};
export default CCTabs;

export const renderBoxTabBar = props => {
  // const is
  return (
    <TabBar
      {...props}
      indicatorStyle={{height: 0}}
      // renderIndicator={({navigationState, style, focused, width}) => {
      //   console.log(navigationState, focused, 'navigationState');
      //   return <Box style={[{width}, style, styles.boxIndicator]} />;
      // }}
      style={styles.boxTabBar}
      renderLabel={({route, focused, ...rest}) => {
        const totalItems = props?.navigationState?.routes?.length;
        const itemId = props?.navigationState?.routes?.findIndex(
          i => i?.key === route?.key,
        );

        return (
          <CCText
            fontWeight="semibold"
            style={[
              styles.boxTabBarLabel,
              focused ? styles.boxIndicator : {},
              {
                color: focused ? CCColors?.White : CCColors?.TextColor?.Primary,
              },
              itemId === 0
                ? styles.boxLeftBorder
                : itemId === totalItems - 1
                ? styles.boxRightBorder
                : null,
              (itemId !== 0 || itemId === totalItems - 1) && {
                borderLeftWidth: StyleSheet.hairlineWidth,
                borderRightWidth: StyleSheet.hairlineWidth,
                borderColor: CCColors.Grey.Border,
              },
            ]}>
            {route.title}
          </CCText>
        );
      }}
      tabStyle={{
        width: 'auto',
        padding: 0,
        // borderWidth: StyleSheet.hairlineWidth,
      }}
    />
  );
};

export const styles = StyleSheet.create({
  boxLeftBorder: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  boxRightBorder: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  boxTabBar: {
    backgroundColor: CCColors?.White,
    width: 'max-content',
    // margin: 10,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: CCColors.Grey.Border,
    padding: 0,
    borderRadius: 8,
  },
  boxIndicator: {
    backgroundColor: CCColors?.Primary.Brown,
    height: '100%',
    // borderRadius: 8,
  },
  boxTabBarLabel: {
    color: CCColors?.White,
    padding: 15,
    // borderWidth: StyleSheet.hairlineWidth,
  },
});
