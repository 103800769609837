import React, {useEffect, useMemo, useState} from 'react';
import {View} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import SlideModal from '@/Atoms/SlideModal';
import CCText from '@/Atoms/CCText';
import CCTextInput from '@/Atoms/CCTextInput';
import CCButton from '@/Atoms/CCButton';
import Dropzone from 'react-dropzone';
import styles from './styles';
import Spacer from '@/Atoms/Spacer';
import {vs} from '@/Utils/Dimensions';
import CCColors from '@/Utils/CCColors';
import useUploadSupportApi from '@/Hooks/GetSupportModalApi/useUploadSupportApi';

const SupportModal = ({openModal, handleClose}) => {
  const [message, setMessage] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {mutate: submitSupportTicket} = useUploadSupportApi(
    data => {
      setSuccess(true);
    },
    error => {
      setErrorMessage('Failed to submit support ticket');
    },
  );

  const isImage = useMemo(() => file && file.type.startsWith('image'), [file]);

  const handleDrop = (acceptedFiles: File[]) => {
    const acceptedFile = acceptedFiles[0];
    const fileSizeMB = acceptedFile.size / (1024 * 1024);

    const isValidFileType =
      acceptedFile.type.startsWith('image') ||
      acceptedFile.type.startsWith('video');
    const isValidSize = fileSizeMB <= 15;

    if (!isValidFileType || !isValidSize) {
      setFile(null);
      setErrorMessage(
        'File contains an unknown or unsupported file type, or exceeds the size limit of 15MB',
      );
    } else {
      setFile(acceptedFile);
      setErrorMessage(null);
    }
  };

  const handleSubmit = () => {
    if (!message || !file) {
      setErrorMessage('Both description and file are required');
      return;
    }

    setErrorMessage(null);
    submitSupportTicket({description: message, file});

    setMessage('');
    setFile(null);
  };

  const renderSuccess = () => (
    <View style={styles.successContainer}>
      <Ionicons
        name="checkmark-circle"
        size={100}
        color={CCColors?.Green}
        style={styles.successIcon}
      />
      <Spacer spacing={vs(10)} />
      <CCText style={styles.modalTitle}>We have received your feedback</CCText>
      <Spacer spacing={vs(10)} />
      <CCText style={styles.successText}>
        Thank you for bringing this to our attention. Our team will look into it
        promptly. Your feedback is invaluable in helping us elevate the chess
        experience.
      </CCText>
      <Spacer spacing={vs(20)} />
    </View>
  );

  useEffect(() => {
    if (!openModal) {
      setMessage('');
      setFile(null);
      setSuccess(false);
      setErrorMessage(null);
    }
  }, [openModal]);

  return (
    <SlideModal
      type="center"
      visible={openModal}
      onClose={handleClose}
      customStyle={{width: '45%'}}
      modalHeaderTitle={!success ? 'Raise an Issue' : ''}>
      <View style={styles.modalBackground}>
        {success ? (
          renderSuccess()
        ) : (
          <>
            <CCTextInput
              style={styles.input}
              placeholder="Add details about issue"
              value={message}
              onChangeText={setMessage}
              multiline={true}
              numberOfLines={4}
              textAlignVertical="top"
            />

            {!file ? (
              <Dropzone
                accept={{
                  'image/*': ['.jpg', '.jpeg', '.png'],
                  'video/*': ['.mp4', '.avi', '.mpeg'],
                }}
                maxFiles={1}
                onDrop={handleDrop}>
                {({getRootProps, getInputProps}) => (
                  <>
                    <div {...getRootProps()} style={styles.uploadFiles}>
                      <input {...getInputProps()} style={{display: 'none'}} />
                      <Ionicons name="cloud-upload-outline" size={50} />
                      <Spacer spacing={vs(12)} />
                      <CCText style={styles.uploadText}>
                        Drag and drop file {'\n'}or{' '}
                        <CCText style={{color: CCColors?.Green}}>
                          click here
                        </CCText>{' '}
                        to select file
                      </CCText>
                    </div>
                    {!errorMessage && (
                      <CCText color={CCColors.TextColor?.Primary}>
                        PNG/JPEG, Video max 15MB
                      </CCText>
                    )}
                    {errorMessage && (
                      <CCText style={{color: CCColors?.Red}}>
                        {errorMessage}
                      </CCText>
                    )}
                    <Spacer spacing={vs(20)} />
                  </>
                )}
              </Dropzone>
            ) : (
              <>
                <View style={[styles.uploadFiles]}>
                  <Ionicons
                    name={isImage ? 'image-outline' : 'play-circle-outline'}
                    size={100}
                  />
                  <Spacer spacing={vs(5)} />
                  <CCText textAlign="center" style={{width: '80%'}}>
                    {file.name}
                  </CCText>
                  <Ionicons
                    name="trash-outline"
                    size={20}
                    style={styles.deleteIcon}
                    onPress={() => setFile(null)}
                  />
                </View>
                <CCText color={CCColors.Green}>
                  {isImage ? 'Image' : 'Video'} uploaded successfully
                </CCText>
                <Spacer spacing={vs(20)} />
              </>
            )}

            <View style={styles.button}>
              <CCButton
                stretched
                disabled={!message || !file}
                titleStyle={{color: CCColors.TextColor.Primary, padding: 15}}
                onPress={handleSubmit}>
                Submit
              </CCButton>
            </View>
          </>
        )}
      </View>
    </SlideModal>
  );
};

export default SupportModal;
