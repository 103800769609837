import CCColors, {updateColorWithOpacity} from '../../Utils/CCColors';

import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  containerStyle: {
    borderTopWidth: 1,
    borderColor: updateColorWithOpacity(CCColors.Grey.Grey, 0.2),
  },

  verticalContainerStyle: {
    borderLeftWidth: 1,
    width: 1,
    height: '100%',
    borderColor: updateColorWithOpacity(CCColors.Grey.Grey, 0.2),
  },
});
export default styles;
