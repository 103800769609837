import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

interface ManageParticipantsParams {
  phone: string;
  gameId: string;
  action: 'add' | 'remove';
}

export default function useManageParticipants(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  return useMutation(
    async ({phone, action, gameId}: ManageParticipantsParams) => {
      const response = await ChessRestClient.post(
        `learning/study_games/${gameId}/manage-participants/`,
        {
          phone_number: phone,
          action: action,
        },
      );
      return response.data;
    },
    {
      onSuccess: data => {
        handleOnSuccess?.(data);
        console.log('Mutation successful:', data);
      },
      onError: error => {
        handleOnError?.(error);
        console.error('Mutation error:', error);
      },
      // onSettled: () => {
      //   console.log('Mutation settled (either success or error)');
      // },
    },
  );
}
