import {WINDOW_WIDTH} from '@/Utils/Dimensions';
import {useState, useEffect} from 'react';
import {Dimensions} from 'react-native';

export const useMediaQuery = () => {
  const [screenWidth, setScreenWidth] = useState(WINDOW_WIDTH);

  useEffect(() => {
    const onChange = ({window}) => {
      setScreenWidth(window.width);
    };

    // Subscribe to dimension changes
    const subscription = Dimensions.addEventListener('change', onChange);
    return () => {
      // Remove subscription to avoid memory leaks
      subscription.remove();
    };
  }, []);

  const isMobile = screenWidth <= 480;
  const isTablet = screenWidth > 480 && screenWidth <= 768;
  const isDesktop = screenWidth > 768;
  const isMobileOrTab = isMobile || isTablet;

  return {
    isMobile,
    isTablet,
    isDesktop,
    isMobileOrTab,
  };
};
