// src/ReviewGames.js
import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { ScreenName } from '@/navigators/StackConstants';
import { useNavigation, useRoute } from '@react-navigation/native';
import ReviewGame from '@/Assets/Images/ReviewGame.png';
import CCColors from '@/Utils/CCColors';


const ReviewGames = () => {
    const navigation = useNavigation();
    
    return (
        <View style={styles.container}>
            <View style={styles.content}>
              <Box flexDirection = 'row' justifyContent='flex-start' style={{width: '100%', overflow: 'visible', height: '100%'}} >
                <View style={{flex: 0.7}}>
                <CCText style={styles.title}>Play more games</CCText>
                <CCText style={styles.subtitle}>Review your previous games to unlock </CCText>
                <CCText style={styles.subtitle}>today's games</CCText>
                {/* <TouchableOpacity style={styles.button}>
                    <Text style={styles.buttonText}>Review Games</Text>
                </TouchableOpacity> */}
                <CCButton
                        onPress={() => {
                            // console.log("game_uuid is", game_uuid);
                            navigation.navigate(ScreenName.Games, {
                                query: 'platform=Circlechess'
                            });
                        }}
                        // disabled={!game_uuid}
                        style={{
                            flexWrap: 'nowrap',
                            width: 240,
                            margin: 'auto',
                            marginTop: 16,
                            // top : 40,
                            // left  : -130,
                        }}
                        contentContainerStyle={{
                            paddingRight: 8,
                            paddingVertical: 8,
                            paddingLeft: 24,
                        }}>
                        <View
                            style={{
                                flex: 1,
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}>
                            <CCText style={styles.reviewGameActionText}>Review Games</CCText>
                            <View style={[styles.reviewGameActionCoinsContainer, { marginLeft: 8 }]}>
                                <FontAwesome5 name="coins" size={16} color="#D1AB41" />
                                <CCText
                                    style={{ marginLeft: 8 }}>
                                    50+ pts
                                </CCText>
                            </View>
                        </View>
                </CCButton>
                </View>
                <View style={styles.decorations}>
                    <Image style={styles.decorativeImage} source={ReviewGame} />
                    {/* Add other decorative images similarly */}
                </View>
              </Box>
            </View>
        </View>
    );
};

// const styles = StyleSheet.create({
//     container: {
//         flex: 1,
//         justifyContent: 'center',
//         alignItems: 'center',
//         backgroundColor: '#fff',
//         padding: 20,
//     },
//     title: {
//         fontSize: 24,
//         fontWeight: 'bold',
//         marginBottom: 10,
//     },
//     subtitle: {
//         fontSize: 16,
//         color: '#666',
//         textAlign: 'center',
//         marginBottom: 20,
//     },
//     button: {
//         backgroundColor: '#FFCE00',
//         paddingVertical: 10,
//         paddingHorizontal: 20,
//         borderRadius: 5,
//     },
//     buttonText: {
//         fontSize: 16,
//         color: '#fff',
//         fontWeight: 'bold',
//     },
//     decorations: {
//         position: 'absolute',
//         top: 20,
//         right: 20,
//         // Add styling to position your decorative elements
//     },
//     icon: {
//         width: 200,
//         height: 200,
//         // Add more styling as necessary
//     },
// });
const styles = StyleSheet.create({
    container: {
      // flex: 0.4,
      justifyContent: 'left',
      alignItems: 'left',
      backgroundColor: '#fff',
      padding: 20,
      marginTop: 30,
      borderRadius: 11,
      height: 'auto'
      
    //   position: 'relative',
    },
    content: {
      alignItems: 'left',
      zIndex: 1,
      height: 'auto'
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 10,
      marginTop: 15
    },
    subtitle: {
      fontSize: 16,
      color: '#666',
      textAlign: 'left',
    //   marginBottom: 20, 
    },
    button: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#FFCE00',
      paddingVertical: 10,
      paddingHorizontal: 20,
      borderRadius: 5,
      width: 200,
    },
    buttonText: {
      fontSize: 16,
      color: '#fff',
      fontWeight: 'bold',
      marginRight: 10,
    },
    points: {
      fontSize: 14,
      color: '#fff',
    },
    // reviewGameActionText: {
    //     fontSize: 16,
    //     lineHeight: 24,
    //     fontWeight: '500',
    //     paddingRight: 8,
    //     textAlign: 'center'
    //     // color: ''
    // },
    // reviewGameActionCoinsContainer: {
    //     backgroundColor: CCColors.Primary.Light,
    //     borderRadius: 100,
    //     padding: 4,
    //     paddingHorizontal: 16,
    //     // flex: 1,
    //     flexDirection: 'row',
    //     // height: 32
    // },
    decorations: {
        position: 'relative',
        justifyContent: "flex-end",
        // top: 20,
        // right: 20,
              // Add styling to position your decorative elements
          },
    decorativeImage: {
        position: 'absolute',
        // top: -200,
        // right: -20,
        width: 200,
        height: 200,
        resizeMode: 'contain',
        overflow: 'visible',
        alignSelf: 'flex-start'

    },
  });

export default ReviewGames;
