import CCColors from '@/Utils/CCColors';
import {WINDOW_HEIGHT, WINDOW_WIDTH, vs} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';

const white = '#ffffff';
const black = '#806234';

const styles = StyleSheet.create({
  winningBarContainer: {
    // flexDirection: 'row',
    height: '100%',
    // margin: 5,
    width: 20,
    borderRadius: 1,
    borderWidth: 1,
    overflow: 'hidden',
  },
  winningBarContainerMobile: {
    // flexDirection: 'row',
    height: 20,
    margin: 5,
    width: '97%',
    borderRadius: 1,
    borderWidth: 1,
    overflow: 'hidden',
    flexDirection: 'row',
  },
  whiteBar: {
    backgroundColor: white,
  },
  blackBar: {
    backgroundColor: black,
  },
});

export default styles;
