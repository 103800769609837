import CCColors from '@/Utils/CCColors';
import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  streakContainer: {
    position: 'relative',
    // width: 'min-content',
  },
  streakContainerMobile: {
    width: 'min-content',
    // width: 'min-content',
  },
  streakWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: CCColors?.Primary?.lightBrown,
    borderRadius: 25,
    padding: 5,
  },
  streakWrapperMobile: {
    paddingRight: 10,
  },
  textWrapper: {
    // margin: 5,
  },
  streakDetailsContainer: {
    backgroundColor: CCColors?.Background,
    borderWidth: 1,
    borderColor: CCColors?.Box?.MiddleLine,
    shadowColor: '#4D3F37',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.3, // Approximate the shadow color opacity (#4D3F370D)
    shadowRadius: 8,
    borderRadius: 8,
  },
  streakDetailsTop: {
    backgroundColor: CCColors?.Primary?.Brown,
    padding: 10,
  },
  modal: {
    position: 'absolute',
    width: 280,
    margin: 0,
    justifyContent: 'flex-start',
  },
  streakDetailsContainerTitle: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  weekDaysContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginVertical: 17,
  },
  weekDay: {
    height: 30,
    width: 30,
    borderRadius: 25,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: CCColors?.Primary?.Aqua,
    marginLeft: 4,
  },
  eachStreakDetailsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  streakDetailsBottom: {
    backgroundColor: CCColors?.Background,
    padding: 16,
  },
  goButton: {
    backgroundColor: CCColors?.Primary?.Default,
    borderRadius: 25,
    gap: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  strikethroughText: {
    textDecorationLine: 'line-through',
    color: CCColors?.TextColor?.Grey,
  },
  bgImage: {
    position: 'absolute',
    right: 0,
    width: '40%',
    height: '100%',
  },
});
