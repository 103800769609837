import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  StyleProp,
  ViewStyle,
} from 'react-native';
import styles from './styles';
import Box from '../Box';
import CCText from '../CCText';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import CCColors from '@/Utils/CCColors';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
interface CheckboxProps {
  label: string | JSX.Element;
  value: boolean;
  onValueChange: (newValue: boolean) => void;
  tickSize: number;
  customStyle?: StyleProp<ViewStyle>;
}

const CCCheckbox = ({
  label,
  value,
  onValueChange,
  tickSize = 10,
  customStyle,
  onLabelPress,
}) => {
  const {isMobileOrTab} = useMediaQuery();
  return (
    <TouchableOpacity
      style={[styles.checkboxContainer, customStyle]}
      onPress={() => onValueChange(label, !value)}>
      <View
        style={[
          styles.checkbox,
          value && styles.checkboxActive,
          isMobileOrTab && styles.checkboxMobile,
        ]}>
        {value && (
          <Text style={styles.checkboxCheck}>
            <MaterialCommunityIcons
              name="check"
              size={!isMobileOrTab ? 15 : tickSize}
              color={CCColors.Primary.Brown}
            />
          </Text>
        )}
      </View>
      <TouchableOpacity onPress={onLabelPress}>
        <Text
          style={[
            styles.checkboxLabel,
            isMobileOrTab && styles.checkboxLabelMobile,
          ]}>
          {label === 1
            ? 'Show favourite games'
            : label === 0
            ? 'Hide favourite games'
            : label}
        </Text>
      </TouchableOpacity>
    </TouchableOpacity>
  );
};
// const CCCheckbox: React.FC<CheckboxProps> = ({
//   label,
//   value,
//   onValueChange,
// }) => {
//   return (
//     <TouchableOpacity
//       style={styles.checkboxContainer}
//       onPress={() => onValueChange(!value)}
//     >
//       <Box style={styles.checkbox}>
//         {value && (
//           <CCText style={styles.checkmark}>
//             <MaterialCommunityIcons name="check" />
//           </CCText>
//         )}
//       </Box>
//       <CCText style={styles.label}>{label}</CCText>
//     </TouchableOpacity>
//   );
// };

export default CCCheckbox;
