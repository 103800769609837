import {useCallback, useMemo} from 'react';
import {
  useSharedValue,
  useAnimatedStyle,
  interpolateColor,
  interpolate,
  withTiming,
  withRepeat,
  withSpring,
  cancelAnimation,
} from 'react-native-reanimated';

export default function useAnimation({
  prop,
  start,
  end,
  inputRange = [0, 1],
  isColor = false,
  duration = 200,
  repeat = false,
  numberOfReps = -1,
}) {
  const sv = useSharedValue(0);

  const style = useAnimatedStyle(() => {
    if (isColor) {
      return {
        [prop]: interpolateColor(sv.value, inputRange, [start, end]),
      };
    } else {
      return {
        [prop]: interpolate(sv.value, inputRange, [start, end]),
      };
    }
  });

  function setAnimationValue(value) {
    sv.value = withTiming(value, {duration: duration});
  }

  function startAnimation() {
    if (repeat) {
      sv.value = 0;
      sv.value = withRepeat(
        withTiming(1, {duration: duration}),
        numberOfReps,
        true,
      );
    }
  }

  function _cancelAnimation() {
    cancelAnimation(sv);
  }

  return [style, repeat ? startAnimation : setAnimationValue, _cancelAnimation];
}
