export const notations = {
  king: 'K',
  k: 'K',
  queen: 'Q',
  q: 'Q',
  rook: 'R',
  ruk: 'R',
  rocket: 'R',
  r: 'R',
  bishop: 'B',
  b: 'B',
  pawn: '',
  p: '',
  knight: 'N',
  night: 'N',
  n: 'N',
  takes: 'x',
  take: 'x',
  cross: 'x',
  capture: 'x',
  captures: 'x',
  promotesTo: '=',
  check: '+',
  checkmate: '#',
  checkmates: '#',
  enPassant: 'e.p.',
  a1: 'a1',
  a2: 'a2',
  a3: 'a3',
  a4: 'a4',
  a5: 'a5',
  a6: 'a6',
  a7: 'a7',
  a8: 'a8',
  b1: 'b1',
  b2: 'b2',
  b3: 'b3',
  b4: 'b4',
  b5: 'b5',
  b6: 'b6',
  b7: 'b7',
  b8: 'b8',
  c1: 'c1',
  c2: 'c2',
  c3: 'c3',
  c4: 'c4',
  c5: 'c5',
  c6: 'c6',
  c7: 'c7',
  c8: 'c8',
  d1: 'd1',
  d2: 'd2',
  d3: 'd3',
  d4: 'd4',
  d5: 'd5',
  d6: 'd6',
  d7: 'd7',
  d8: 'd8',
  e1: 'e1',
  e2: 'e2',
  e3: 'e3',
  e4: 'e4',
  e5: 'e5',
  e6: 'e6',
  e7: 'e7',
  e8: 'e8',
  f1: 'f1',
  f2: 'f2',
  f3: 'f3',
  f4: 'f4',
  f5: 'f5',
  f6: 'f6',
  f7: 'f7',
  f8: 'f8',
  g1: 'g1',
  g2: 'g2',
  g3: 'g3',
  g4: 'g4',
  g5: 'g5',
  g6: 'g6',
  g7: 'g7',
  g8: 'g8',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  h7: 'h7',
  h8: 'h8',
  a: 'a',
  c: 'c',
  d: 'd',
  e: 'e',
  f: 'f',
  g: 'g',
  h: 'h',
  kingsidecastle: 'O-O',
  shortcastle: 'O-O',
  queensidecastle: 'O-O-O',
  longcastle: 'O-O-O',
  castles: 'O-O',
  castle: 'O-O',
  o: 'O',
  dash: '-',
  oh: 'O',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  one: '1',
  two: '2',
  three: '3',
  four: '4',
  five: '5',
  six: '6',
  seven: '7',
  eight: '8',
  destroys: 'x',
  destroy: 'x',
  destroyce: 'x',
  kills: 'x',
  kill: 'x',
  r1: 'R1',
  r2: 'R2',
  r3: 'R3',
  r4: 'R4',
  r5: 'R5',
  r6: 'R6',
  r7: 'R7',
  r8: 'R8',
  n1: 'N1',
  n2: 'N2',
  n3: 'N3',
  n4: 'N4',
  n5: 'N5',
  n6: 'N6',
  n7: 'N7',
  n8: 'N8',
  factory: 'F3',
};
