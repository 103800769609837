import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

type createNewChapterType = {
  name: string;
  fen: string;
  pgn: string;
  orientation: 'B' | 'W';
  slug?: string;
};
export default function useCreateNewChapter() {
  return useMutation(async (payload: createNewChapterType) => {
    const formatedPayload = {
      name: payload?.name,
      fen: payload?.fen,
      pgn: payload?.pgn,
      orientation: payload?.orientation,
    };
    const response = await ChessRestClient.post(
      `learning/study_games/${payload?.slug}/chapters/`,
      formatedPayload,
    );
    return response.data;
  });
}

// {
//   name: newStudyDetails?.name,
//   chapter: {
//     name: newChapterDetails?.name,
//     ...startingPos,
//     orientation: orientation,
//   },
// }
