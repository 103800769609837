import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import {FontSizes, WINDOW_HEIGHT, vs} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';

const progressBarHeight = 32;

const customStyles = StyleSheet.create({
  mobileLine: {
    paddingVertical: 5,
  },
  yourMoves: {
    backgroundColor: CCColors.Primary.lightYellow,
    marginHorizontal: -15,
    paddingHorizontal: 15,
  },
  mobileRowBox: {
    backgroundColor: CCColors?.White,
    margin: 10,
    padding: 15,
    borderRadius: 8,
  },
  mobileResult: {
    // backgroundColor: CCColors.Primary.Default,
    padding: 5,
    margin: 'auto',
    color: CCColors?.TextColor.Dark,
    borderRadius: '50%',
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cellWrapperBox: {
    padding: vs(8),
    width: '100%',
    height: '100%',
  },
  cellText: {
    fontSize: FontSizes[13],
  },
  eachMoveWrapperSolve: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    backgroundColor: CCColors.White,
    paddingHorizontal: 5,
    paddingVertical: 15,
    paddingBottom: 0,
    paddingTop: 0,

    justifyContent: 'center',
    // flexDirection: 'row',
    // flex: 1,
  },
  eachMoveWrapper1: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,

    justifyContent: 'center',
    // flexDirection: 'row',
    // flex: 1,
  },
  eachMoveWrapper2: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 0,
    backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,

    justifyContent: 'center',
    // flexDirection: 'row',
    // flex: 1,
  },
  eachMoveInsideWrapper: {
    // margin: 15,
    marginTop: 15,
    flexDirection: 'row',
    // marginTop:15,
    // marginTop:15,
    // marginTop:15,
  },
  AnalysisRightComponentWrapper: {
    // borderColor: CCColors?.Primary?.Default,
    // borderWidth: 1,
    // borderRadius: 4,
    flex: 1,

    // maxHeight: '50%',
    // maxHeight: WINDOW_HEIGHT / 1.6,
    height: '92vh',
    width: '100%',
    // justifyContent: 'center',
    // alignItems: 'center',
    backgroundColor: CCColors.Dashboard.DashboardBackground,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  label: {
    fontSize: 18,
  },
  count: {
    fontSize: 18,
  },
  playerScore: {
    // flexDirection: 'column',
    // width: '100%',
    // height: '100%',
    flex: 1,
    // overflow: 'auto',
  },
  reviewSummary: {
    marginTop: vs(18),
    marginHorizontal: vs(18),
    width: '90%',
    backgroundColor: 'white',
    padding: vs(8),
    borderRadius: vs(8),
    shadowRadius: vs(2),
    shadowOpacity: 0.5,
    shadowColor: '#4D3F37',
  },
  reviewSummaryHeader: {
    letterSpacing: 2,
  },
  reviewSummaryDescription: {
    marginTop: vs(8),
    letterSpacing: 1,
    lineHeight: vs(18),
  },
  playerScoreEach: {
    // flex: 0.2,
    // borderWidth: StyleSheet.hairlineWidth,
  },
  rightReviewTopHeader: {
    backgroundColor: CCColors?.White,
    padding: 10,
    paddingTop: 30,
    paddingBottom: 15,
    fontSize: FontSizes[12],
    color: CCColors?.Tabs.InactiveTabFont,
    minWidth: 100,
    textAlign: 'center',
    width: '34%',
    borderBottomColor: CCColors?.Primary.Default,
    borderBottomWidth: 3,
  },
  rightReviewLeftHeader: {
    justifyContent: 'flex-start',
    // backgroundColor: CCColors?.Primary?.Default,
    padding: 10,
    width: '30%',
    color: CCColors?.White,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  selectedMove: {
    backgroundColor: CCColors.Primary.lightYellow,
    borderColor: CCColors.Primary.Default,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    // backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  rightReviewTopHeaderSub: {
    backgroundColor: CCColors?.White,
    padding: 10,
    paddingTop: 30,
    paddingBottom: 15,
    fontSize: FontSizes[12],
    color: CCColors?.Tabs.InactiveTabFont,
    minWidth: 100,
    textAlign: 'center',
    width: '33%',
  },
  rightReviewTopHeaderSubSub: {
    fontSize: FontSizes[3],
    height: 'fit-content',
  },
  progressBarWrapper: {
    borderRightWidth: 1,
    borderColor: updateColorWithOpacity(CCColors.TextColor.Dark, 0.2),
  },

  progressBar: {
    margin: 12,
    backgroundColor: CCColors.Primary.Light,
    height: progressBarHeight,
    borderRadius: 16,
    paddingHorizontal: 16,
    paddingVertical: 3,
    borderColor: CCColors.Primary.Light,
    borderWidth: 4,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    width: '80%',
  },

  progressBarIcon: {
    height: progressBarHeight / 2,
    width: progressBarHeight / 2,
    marginRight: 4,
  },

  progressBarText: {
    lineHeight: 18,
    fontSize: 12,
    fontWeight: '500',
  },

  progressBarFilling: {
    backgroundColor: CCColors.Primary.Action,
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
  },
  paginationContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: vs(10),
  },
  paginationButton: {
    paddingVertical: vs(8),
    paddingHorizontal: vs(8),
    marginHorizontal: vs(4),
    backgroundColor: CCColors.White,
    borderWidth: 1,
    borderColor: CCColors.Grey.Border,
    borderRadius: vs(10),
    minWidth: vs(40),
    justifyContent: 'center',
    alignItems: 'center',
  },
  paginationText: {
    fontSize: FontSizes[11],
    color: CCColors.Primary.Dark,
    fontWeight: 'bold',
  },
  paginationActiveButton: {
    backgroundColor: CCColors.Primary.Brown,
    borderColor: CCColors.Primary.Dark,
  },
  paginationActiveText: {
    color: CCColors.White,
  },
  paginationDisabledButton: {
    backgroundColor: CCColors.Grey.Border,
    borderColor: CCColors.Grey.Border,
  },
  paginationDisabledText: {
    color: CCColors.Grey.Grey,
  },
});
export default customStyles;
