import {Dimensions} from 'react-native';
import {RFValue} from 'react-native-responsive-fontsize';

const {width: SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get('screen');
const {width: WINDOW_WIDTH, height: WINDOW_HEIGHT} = Dimensions.get('window');
const PHONE_BREAKPOINT = 768;
const guidelineBaseWidth = 412;
const guidelineBaseHeight = 800;
const isPhone = WINDOW_WIDTH <= PHONE_BREAKPOINT;
const wrapFail = WINDOW_WIDTH <= 1424;
const isPortrait = WINDOW_HEIGHT / WINDOW_WIDTH > 1.3;
const hs = (size: number): number =>
  (Math.min(WINDOW_WIDTH, 600) / guidelineBaseWidth) * size;

const vs = (size: number): number =>
  (Math.min(WINDOW_HEIGHT, 1000) / guidelineBaseHeight) * size;

const ms = (size: number, factor: number = 0.5): number =>
  size + (hs(size) - size) * factor;

const Spacing = {
  xxxSmall: ms(4),
  xxSmall: ms(8),
  xSmall: ms(12),
  small: ms(16),
  regular: ms(20),
  large: ms(24),
  xLarge: ms(28),
  xxLarge: ms(32),
  xxxLarge: ms(36),
  extraLarge: ms(48),
};

const Sizes = {
  '2': hs(2),
  '4': hs(4),
  '8': hs(8),
  '12': hs(12),
  '13': hs(13),
  '16': hs(16),
  '20': hs(20),
  '24': hs(24),
  '28': hs(28),
  '32': hs(32),
  '36': hs(36),
  '40': hs(40),
  '44': hs(44),
  '48': hs(48),
};

const scaleFactor = isPhone ? 800 : 1200;

const FontSizes = {
  '8': RFValue(8, scaleFactor),
  '9': RFValue(9, scaleFactor),
  '10': RFValue(10, scaleFactor),
  '11': RFValue(11, scaleFactor),
  '12': RFValue(12, scaleFactor),
  '13': RFValue(13, scaleFactor),
  '14': RFValue(14, scaleFactor),
  '15': RFValue(15, scaleFactor),
  '16': RFValue(16, scaleFactor),
  '18': RFValue(18, scaleFactor),
  '20': RFValue(20, scaleFactor),
  '21': RFValue(21, scaleFactor),
  '24': RFValue(24, scaleFactor),
  '28': RFValue(28, scaleFactor),
  '32': RFValue(32, scaleFactor),
  '36': RFValue(36, scaleFactor),
  '40': RFValue(40, scaleFactor),
  '48': RFValue(48, scaleFactor),
  '51': RFValue(51, scaleFactor),
  '81': RFValue(81, scaleFactor),
};

const BorderRadius = {
  small: 4,
  medium: 8,
  large: 12,
};

const BottomBarHeight = vs(56);

export {
  SCREEN_WIDTH,
  SCREEN_HEIGHT,
  WINDOW_WIDTH,
  WINDOW_HEIGHT,
  Spacing,
  Sizes,
  FontSizes,
  BorderRadius,
  BottomBarHeight,
  PHONE_BREAKPOINT,
  isPhone,
  hs,
  vs,
  ms,
  wrapFail,
  isPortrait,
};
