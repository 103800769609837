import {ChipListProps, ChipType} from './types';
import {FlatList, ListRenderItemInfo} from 'react-native';
import React, {useMemo, useState} from 'react';

import Box from '../Box';
import CCColors from '../../Utils/CCColors';
import CCText from '../CCText';
import Chip from './Chip';
import ExclamationIcon from '../../Assets/Images/Svgs/ExclamationIcon';
import Spacer from '../Spacer';
import {areObjectsEqual} from '../../Utils/CommonUtils';
import styles from './styles';

const ChipList: React.FC<ChipListProps> = ({
  chipBackgroundColor = 'transparent',
  removeFeather = '',
  style,
  options,
  value,
  onChange,
  multiSelect = false,
  horizontal = true,
  autoWrap = false,
  error,
  type,
}) => {
  const [selectedValues, setSelectedValues] = useState<Array<any>>([]);

  const handlePress = (item: ChipType) => {
    if (multiSelect) {
      if (selectedValues.includes(item.value)) {
        const newSelectedValues = selectedValues.filter(
          val => val !== item.value,
        );
        setSelectedValues(newSelectedValues);
        onChange?.(newSelectedValues);
      } else {
        const newSelectedValues = [...selectedValues, item.value];
        setSelectedValues(newSelectedValues);
        onChange?.(newSelectedValues);
      }
    } else {
      setSelectedValues([item.value]);
      onChange?.(item);
    }
  };

  const isSelected = (item: ChipType) => {
    return multiSelect
      ? selectedValues?.includes(item.value)
      : // (value?.pk && item?.value && value?.pk === item?.value) ||
        areObjectsEqual(value, item) ||
          value === item ||
          // (value?.pk && item?.value && value?.pk === item?.value) ||
          value === item?.value;
    // selectedValues?.includes?.(item?.value);
  };

  const renderItem = (data: ListRenderItemInfo<ChipType>) => {
    // @ts-ignore
    const {item} = data;
    return (
      <>
        <Chip
          removeFeather={item.key === removeFeather}
          chipBackgroundColor={chipBackgroundColor}
          isSelected={isSelected(item)}
          item={item}
          onPress={() => handlePress(item)}
          disabled={item?.disabled || item.key === removeFeather}
          type={type}
        />
      </>
    );
  };

  const renderChips = () => {
    return options?.map((item, index) => (
      <React.Fragment key={index}>
        <Chip
          isSelected={isSelected(item)}
          item={item}
          onPress={() => handlePress(item)}
          disabled={item?.disabled}
          type={type}
        />
        {!horizontal && <Spacer spacing={10} />}
      </React.Fragment>
    ));
  };

  return (
    <>
      {horizontal ? (
        <FlatList
          horizontal={horizontal}
          data={options}
          renderItem={renderItem}
          style={style}
          showsHorizontalScrollIndicator={false}
        />
      ) : (
        <Box
          style={[
            style,
            autoWrap ? {flexWrap: 'wrap', flexDirection: 'row'} : {},
          ]}>
          {renderChips()}
        </Box>
      )}
      {error?.flag && (
        <Box style={styles?.errorTxt}>
          <ExclamationIcon color={'#D64E59'} width={13} />
          <CCText color={CCColors?.Error} fontSize={11} style={{marginLeft: 6}}>
            {error?.msg}
          </CCText>
        </Box>
      )}
    </>
  );
};

export default ChipList;
