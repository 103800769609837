import ChessRestClient from '../../Api/ChessRestClient';
import {useMutation} from '@tanstack/react-query';
import {TournamentDetailsResponse} from './types';

export function useGetGoals(
  handleOnSuccess?: (data: TournamentDetailsResponse) => void,
) {
  const mutation = useMutation<TournamentDetailsResponse, Error, void>(
    async () => {
      const response = await ChessRestClient.get('/learning/goals/');
      return response.data;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) {
          handleOnSuccess(data);
        }
      },
    },
  );

  return {
    ...mutation,
  };
}

export function useGetRules(
  handleOnSuccess?: (data: TournamentDetailsResponse) => void,
) {
  const mutation = useMutation<TournamentDetailsResponse, Error, void>(
    async () => {
      const response = await ChessRestClient.get('/learning/rules/');
      return response.data;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) {
          handleOnSuccess(data);
        }
      },
    },
  );

  return {
    ...mutation,
  };
}
