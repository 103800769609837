import {StyleSheet} from 'react-native';

import {FontSizes, SCREEN_WIDTH, vs} from '../../../Utils/Dimensions';
import CCColors, {updateColorWithOpacity} from '../../../Utils/CCColors';

export const TabWidth = SCREEN_WIDTH * 0.7;

export const styles = StyleSheet.create({
  containerStyle: {
    height: vs(40),
    borderWidth: 1,
    width: TabWidth,
    borderRadius: 25,
    overflow: 'hidden',
    position: 'relative',
    borderColor: updateColorWithOpacity(CCColors.Primary.Default, 0.5),
  },
  tabBarPill: {
    zIndex: 100,
    borderRadius: 25,
    overflow: 'hidden',
    flexDirection: 'row',
  },
  tabItem: {
    flex: 1,
    height: vs(40),
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelStyle: {
    fontSize: FontSizes[13],
  },
  sliderParent: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 50,
    borderRadius: 25,
    overflow: 'hidden',
    position: 'absolute',
  },
  sliderContainerStyle: {
    height: '100%',
    borderRadius: 25,
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: updateColorWithOpacity(CCColors.Primary.Default, 0.1),
  },
});

export default styles;
