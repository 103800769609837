import React from 'react';
import { View, Text, Button, StyleSheet } from 'react-native';
import connection_change from "@/Assets/Images/Svgs/connection_change.svg"
import CCButton from '@/Atoms/CCButton';
import CCColors from '@/Utils/CCColors';
import CCText from '@/Atoms/CCText';

const ConnectionModal = ({ visible, onContinue, onClose }) => {
  if (!visible) return null; // Render nothing if not visible

  return (
    <View style={styles.overlayContainer}>
      <View style={styles.modalView}>
        <img src={connection_change} style= {{marginTop:'5%', marginBottom: '3%'}}></img>
        <CCText style={styles.modalHeadingText}>
          Circlechess was opened in another tab.
        </CCText>
        <CCText style={styles.modalText}>
        We only support gameplay mode in a single tab. However you can continue using other features of CircleChess
        </CCText>
        <View style={styles.buttonContainer}>
          <CCButton title="Activate Gameplay Here" onPress={onContinue} style={{alignSelf: 'center', width: '60%', backgroundColor: CCColors.Primary.Light, height: '100%',padding: '0%'}} >
            <CCText style={{ color: 'black', alignContent: 'center' }} fontSize={14}>Activate Gameplay Here</CCText>
          </CCButton>
          {/* <Button title="Navigate to Dashboard" onPress={onClose} color="red" /> */}
        </View>
        <CCText style={styles.disclaimerText}>
        This will lock gameplay mode in other tab if open
        </CCText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  overlayContainer: {
    position: 'absolute',
    top: '0%', // Adjust this value to control where the overlay appears
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    width: 450,
    height: 360,
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    alignItems: 'center',
  },
  modalHeadingText: {
    fontSize: 20,
    marginBottom: 20,
    textAlign: 'center',
    fontWeight: 180,
  },
  modalText: {
    fontSize: 16,
    marginBottom: 20,
    marginLeft: '10%',
    marginRight: '10%',
    textAlign: 'center',
    fontWeight: 20,
  },
  disclaimerText: {
    fontSize: 12,
    marginTop: 20,
    marginLeft: '5%',
    marginRight: '5%',
    textAlign: 'center',
    fontWeight: 20,
  },
  buttonContainer: {
    width: '100%',
    height: '10%'
  },
});

export default ConnectionModal;

