import React, {useEffect, useMemo, useState} from 'react';
import {TouchableOpacity, View} from 'react-native';
import styles from './styles';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import Spacer from '@/Atoms/Spacer';
import GraphModal from './Component/GraphModal';
import Svg, {
  Circle,
  G,
  Line,
  Path,
  Rect,
  Text as SvgText,
} from 'react-native-svg';
import Empty from '@/Atoms/Empty';
import * as d3 from 'd3';
import {FontSizes, WINDOW_WIDTH} from '@/Utils/Dimensions';
import CCColors from '@/Utils/CCColors';
import useGraphDimensionsStore from '@/Screens/Assessments/components/AssessmentDetails/graphDimensionStore';
import emptyResult from '../../Assets/Images/png/empty_result.png';
const GRAPH_ASPECT_RATIO = 9 / 16;

const Graph = ({
  data,
  data2,
  color2,
  yaxislabel,
  xaxislabel,
  color,
  label,
  stat,
  modalHeaderTitle,
  position,
  customHeaderStyle,
  yLabels,
  xLabels,
  noOfTicks,
  graphData,
  noDataText,
  tooltipText,
  level1,
  level2,
  date1,
  date2,
}) => {
  const {isMobileOrTab} = useMediaQuery();
  const [width, setWidth] = useState(0);
  const [tooltipData, setTooltipData] = useState({
    visible: false,
    x: 0,
    y: 0,
    value: 0,
    xLabel: '',
    dataSetIndex: 0,
    level1: 0,
    level2: 0,
    date1: '',
    date2: '',
  });

  const height = width * GRAPH_ASPECT_RATIO;

  const formatToMonthDay = dateString => {
    try {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.toLocaleString('en-US', {month: 'short'});
      return `${day} ${month}`;
    } catch (error) {
      console.error('Date formatting error:', error);
      return dateString;
    }
  };

  const formattedDate1 = Array.isArray(date1)
    ? date1.map(formatToMonthDay)
    : [];
  const formattedDate2 = Array.isArray(date2)
    ? date2.map(formatToMonthDay)
    : [];

  const validData = Array.isArray(data) ? data : [];
  const validData2 = Array.isArray(data2) ? data2 : [];

  const allDates = useMemo(
    () => [...formattedDate1, ...formattedDate2],
    [formattedDate1, formattedDate2],
  );

  const yScale = d3.scaleLinear().domain([0, 100]).range([height, 10]);
  const xScale = d3
    .scaleBand()
    .domain(allDates)
    .range([60, width])
    .paddingInner(0.1);

  const yTicks = [0, 25, 50, 75, 100];
  const xTicks = allDates;

  const lineFn = dataPoints => {
    return d3
      .line()
      .x(d => {
        const xVal = xScale(d.date);
        const xPos = xVal !== undefined ? xVal + xScale.bandwidth() / 2 : 0;
        return xPos;
      })
      .y(d => yScale(d.value))
      .curve(d3.curveMonotoneX)(dataPoints);
  };

  const dimensions = useGraphDimensionsStore(state => state.dimensions);
  if (dimensions.width === 0 || dimensions.height === 0) {
    return <CCText>Loading...</CCText>;
  }
  const svgLine = lineFn(
    validData.map((value, index) => ({date: formattedDate1[index], value})),
  );
  const svgLine2 = data2
    ? lineFn(
        validData2.map((value, index) => ({
          date: formattedDate2[index],
          value,
        })),
      )
    : null;

  const handleMouseMove = event => {
    const svgElement = event.currentTarget;
    const rect = svgElement.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;

    let closestIndex = -1;
    let closestDistance = Infinity;
    allDates.forEach((date, index) => {
      const xPosition = xScale(date)! + xScale.bandwidth() / 2;
      const distance = Math.abs(mouseX - xPosition);
      if (distance < closestDistance && distance < 20) {
        closestDistance = distance;
        closestIndex = index;
      }
    });

    if (closestIndex >= 0) {
      const closestDate = allDates[closestIndex];
      const value1 = validData[closestIndex];
      const value2 = validData2 ? validData2[closestIndex] : null;

      const y1 = yScale(value1);
      const y2 = value2 !== null ? yScale(value2) : null;

      const dataSetIndex =
        y2 !== null && Math.abs(y2 - mouseY) < Math.abs(y1 - mouseY) ? 1 : 0;

      const x = xScale(closestDate)! + xScale.bandwidth() / 2;
      const y = dataSetIndex === 0 ? y1 : y2!;
      const value = dataSetIndex === 0 ? value1 : value2!;

      setTooltipData({
        visible: true,
        x,
        y,
        value,
        xLabel: xLabels?.[closestIndex] || closestIndex,
        dataSetIndex,
        level1: dataSetIndex === 0 ? level1?.[closestIndex] : 0,
        level2: dataSetIndex === 1 ? level2?.[closestIndex] : 0,
        date1: closestDate,
        date2: closestDate,
      });
    } else {
      setTooltipData(prev => ({...prev, visible: false}));
    }
  };

  const handleMouseLeave = () => {
    setTooltipData(prev => ({...prev, visible: false}));
  };

  const handleDataPointHover = (
    value: number,
    index: number,
    dataSetIndex: number,
  ) => {
    // Prevent tooltip for the last data point
    if (validData.length > 1 && index >= validData.length - 1) {
      setTooltipData(prev => ({...prev, visible: false}));
      return;
    }
    const x = xScale(index) - 20;
    const y = yScale(value);
    const level = dataSetIndex === 0 ? level1[index] : level2[index];
    const date =
      dataSetIndex === 0 ? formattedDate1[index] : formattedDate2[index];
    setTooltipData({
      visible: true,
      x,
      y,
      value,
      xLabel: xLabels[index] || index,
      dataSetIndex,
      level1: dataSetIndex === 0 ? level : 0,
      level2: dataSetIndex === 1 ? level : 0,
      date1: dataSetIndex === 0 ? date : '',
      date2: dataSetIndex === 1 ? date : '',
    });
  };

  return (
    <>
      <View
        style={{
          position: 'relative',
          zIndex: -1,
          flex: 1,
          width: !isMobileOrTab ? 0.7 * dimensions.width : '95%',
          height: !isMobileOrTab ? 0.2 * dimensions.height : '80%',
          marginHorizontal: 'auto',
        }}
        onLayout={x => {
          setWidth(x.nativeEvent.layout.width);
        }}>
        {graphData ? (
          <Svg
            width={width}
            height={height + 50}
            preserveAspectRatio="xMidYMid meet"
            viewBox={`0 0 ${width} ${height + 50}`}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}>
            {/* Y-axis ticks and labels */}
            {yTicks.map((tick, index) => (
              <G key={index}>
                <SvgText
                  x={30}
                  y={yScale(tick) + 5}
                  fontSize={isMobileOrTab ? 10 : 13}
                  textAnchor="end"
                  fill={CCColors.Black}>
                  {tick}
                </SvgText>

                <Line
                  x1={45}
                  y1={yScale(tick)}
                  x2={width}
                  y2={yScale(tick)}
                  stroke={CCColors.OffWhite}
                  strokeWidth={1}
                />
              </G>
            ))}

            {/* X-axis ticks and labels */}
            {xTicks.map((date, index) => (
              <G key={index}>
                <SvgText
                  x={xScale(date)! + xScale.bandwidth() / 2} // Center text within the band
                  y={height + 15}
                  fontSize={isMobileOrTab ? 8 : 12}
                  textAnchor="middle"
                  fill={CCColors.Black}>
                  {date}
                </SvgText>
              </G>
            ))}

            <Path d={svgLine} stroke={color} strokeWidth={2} fill={'none'} />
            {/* Data points for main line */}
            {validData.map((value, index) => {
              const date = allDates[index];
              if (!date) return null; // Avoid rendering if no valid date
              return (
                <Circle
                  key={index}
                  cx={xScale(date) + xScale.bandwidth() / 2} // Center within the band
                  cy={yScale(value)}
                  r={4}
                  fill={color}
                  opacity={0}
                  onMouseEnter={() => handleDataPointHover(value, index, 0)}
                />
              );
            })}

            {/* Secondary data line if data2 exists */}
            {data2 && (
              <>
                <Path
                  d={svgLine2}
                  stroke={color2}
                  strokeWidth={2}
                  fill="none"
                />
                {validData2.map((value, index) => (
                  <Circle
                    key={`data2-${index}`}
                    cx={xScale(allDates[index])! + xScale.bandwidth() / 2}
                    cy={yScale(value)}
                    r={4}
                    fill={color2}
                    opacity={0}
                    onMouseEnter={() => handleDataPointHover(value, index, 1)}
                  />
                ))}
              </>
            )}
            {/* Tooltip */}
            {tooltipData.visible && (
              <G>
                <Rect
                  x={Math.min(
                    Math.max(tooltipData.x - 40, 10),

                    width - 90,
                  )}
                  y={Math.min(Math.max(tooltipData.y - 60, 10), height - 65)}
                  width={80}
                  height={55}
                  fill={CCColors.Black}
                  stroke={CCColors.DarkGrey}
                  strokeWidth={1}
                  rx={4}
                />
                <SvgText
                  x={Math.min(
                    Math.max(tooltipData.x, 40),

                    width - 45,
                  )}
                  y={Math.min(Math.max(tooltipData.y - 40, 20), height - 20)}
                  fontSize={isMobileOrTab ? 12 : 13}
                  textAnchor="middle"
                  fill={CCColors.White}>
                  {`Level: ${
                    tooltipData.dataSetIndex === 0
                      ? tooltipData.level1
                      : tooltipData.level2
                  }`}
                </SvgText>
                <SvgText
                  x={Math.min(Math.max(tooltipData.x, 50), width - 45)}
                  y={Math.min(Math.max(tooltipData.y - 28, 30), height - 10)}
                  fontSize={isMobileOrTab ? 12 : 13}
                  textAnchor="middle"
                  fill={CCColors.White}>
                  {`Date: ${
                    tooltipData.dataSetIndex === 0
                      ? tooltipData.date1
                      : tooltipData.date2
                  }`}
                </SvgText>
                <SvgText
                  x={Math.min(Math.max(tooltipData.x, 50), width - 45)}
                  y={Math.min(Math.max(tooltipData.y - 15, 40), height - 5)}
                  fontSize={isMobileOrTab ? 12 : 13}
                  textAnchor="middle"
                  fill={CCColors.White}>
                  {`${tooltipText}: ${tooltipData.value.toFixed(1)}%`}
                </SvgText>
                <Circle
                  cx={tooltipData.x}
                  cy={tooltipData.y}
                  r={4}
                  fill={tooltipData.dataSetIndex === 0 ? color : color2}
                />
              </G>
            )}

            {/* Y-axis label */}
            <SvgText
              x={20} // Place left of the Y-axis
              y={height / 2}
              fontSize={12}
              textAnchor="middle"
              fill={CCColors.Black}
              transform={`rotate(-90, 20, ${height / 2})`} // Rotate to be vertical
            >
              {yaxislabel || ''}
            </SvgText>

            {/* X-axis label */}
            <SvgText
              x={width / 2} // Center horizontally on the X-axis
              y={height + 40} // Position below X-axis ticks
              fontSize={12}
              textAnchor="middle"
              fill={CCColors.Black}>
              {xaxislabel || ''}
            </SvgText>
          </Svg>
        ) : (
          <Box
            alignItems="center"
            style={{
              height: '70%',
              justifyContent: isMobileOrTab
                ? 'flex-start'
                : WINDOW_WIDTH < 920
                ? 'center'
                : 'flex-start',
              width: '100%',
            }}>
            <Empty
              imgWidth={isMobileOrTab ? 100 : 150}
              imgSrc={emptyResult}
              title={'No Data found'}
              description=""
              noBorder
              height="20vh"
            />
          </Box>
        )}
      </View>
    </>
  );
};

export default Graph;
