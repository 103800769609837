import React from 'react';
import {Platform, Dimensions, View} from 'react-native';
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import Entypo from 'react-native-vector-icons/Entypo';
import Carousel from 'react-native-reanimated-carousel';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';

// const isMobileOrTable = Platform.OS === 'ios' || Platform.OS === 'android';
const {width: screenWidth} = Dimensions.get('window');

const ratings = [
  {
    text: "My child's chess skills have improved dramatically, and the platform's user-friendly interface makes learning fun for them. It's a win-win!",
    name: 'Dinesh Kumar',
    rating: 1800,
    location: 'Hyderabad',
    color: CCColors.Green,
  },
  {
    text: "CircleChess has transformed my game. The lessons are engaging and well-structured. I've seen a significant improvement in my strategy and tactics since I joined. Highly recommended!",
    name: 'Vijay Kumar',
    rating: 2000,
    location: 'Hyderabad',
    color: CCColors.Red,
  },
  {
    text: "My child's chess skills have improved dramatically, and the platform's user-friendly interface makes learning fun for them. It's a win-win!",
    name: 'Varun K',
    rating: 1600,
    location: 'Mumbai',
    color: CCColors.Green,
  },
  {
    text: "My child's chess skills have improved dramatically, and the platform's user-friendly interface makes learning fun for them. It's a win-win!",
    name: 'Komali',
    rating: 1953,
    location: 'Delhi',
    color: CCColors.Green,
  },
];

const renderItem = ({item}) => (
  <Box
    gap={16}
    style={{
      width: 300,
      minHeight: 300,
      padding: 30,
      backgroundColor: CCColors.Primary.lightBrown,
      marginLeft: 'auto',
      marginRight: 'auto',
    }}>
    <Box flexDirection="row" gap={10}>
      <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
      <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
      <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
      <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
      <Entypo name="star" size={20} color={CCColors.Buttons.Background} />
    </Box>
    <CCText fontSize={28} style={{ lineHeight: 36, fontWeight: '400' }} textAlign="auto">
      {item.text}
    </CCText>
    <Box flexDirection="row" marginTop={20} alignItems="center" gap={20}>
      <Box
        style={{
          width: 56,
          height: 56,
          backgroundColor: item.color,
          borderRadius: '50%',
        }}
      />
      <Box>
        <CCText fontSize={16} style={{lineHeight: 22, fontWeight: '700'}}>
          {item.name}
        </CCText>
        <CCText fontSize={14} style={{lineHeight: 18.23, fontWeight: '400'}}>
          Rating: {item.rating}, {item.location}
        </CCText>
      </Box>
    </Box>
  </Box>
);

const RatingsCarousel = () => {
  const {isMobileOrTab} = useMediaQuery();
  return isMobileOrTab ? (
    <Carousel
      width={screenWidth}
      height={400}
      autoPlay={true}
      data={ratings}
      renderItem={renderItem}
    />
  ) : (
    <Box flexDirection="row" gap={20} marginLeft="auto" marginRight="auto">
      {ratings.map((item, index) => (
        <View key={index}>{renderItem({item})}</View>
      ))}
    </Box>
  );
};

export default RatingsCarousel;
