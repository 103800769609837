// import {useMutation} from '@tanstack/react-query';
// import ChessRestClient from '@/Api/ChessRestClient';

// export default function useSaveOTBGamePGN() {
//   return useMutation(
// async ({
//   gameUuid,
//   pgnContent,
//   isPublished,
// }: {
//   gameUuid: string;
//   pgnContent: TemplateStringsArray;
//   isPublished: boolean;
// }) => {
// const response = await ChessRestClient.post('learning/save-otb-pgn/', {
//   game_uuid: gameUuid,
//   pgn_content: pgnContent,
//   is_published: isPublished,
// });
//       return response.data;
//     },
//   );
// }

import ChessRestClient from '@/Api/ChessRestClient';
// import {SetAvatarResponse} from './types';
import {useMutation} from '@tanstack/react-query';

export default function useSaveOTBGamePGN(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation(
    async ({
      gameUuid,
      pgnContent,
      isPublished,
    }: {
      gameUuid: string;
      pgnContent: TemplateStringsArray;
      isPublished: boolean;
    }) => {
      // const {gameMoveId, annotation} = details;
      const response = await ChessRestClient.post(
        `learning/save-otb-pgn/${isPublished ? '?is_published=true' : ''}`,
        {
          game_uuid: gameUuid,
          pgn_content: pgnContent,
          is_published: isPublished,
        },
      );
      return response.data;
    },
    {
      onSuccess: data => {
        handleOnSuccess?.(data);
      },
      onError: data => {
        handleOnError?.(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
