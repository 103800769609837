import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import {Image, Animated, View} from 'react-native';
import SlideModal from '@/Atoms/SlideModal';
import React, {useState, useEffect, useRef} from 'react';
import styles from './styles';
import CCColors from '@/Utils/CCColors';
import {FontSizes} from '@/Utils/Dimensions';
import Spacer from '@/Atoms/Spacer';
import ChatModal from './component/ChatModal';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';

function CaissaChatbot() {
  const [openModal, setOpenModal] = useState(false);
  const [showText, setShowText] = useState(true);
  const widthAnim = useRef(new Animated.Value(200)).current; // Initial width
  const {isMobileorTab} = useMediaQuery();
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      // Hide the text after 5 seconds
      setShowText(false);

      // Animate the width of chabotBg
      Animated.timing(widthAnim, {
        toValue: 70, // Final width
        duration: 500, // Duration of the animation (500ms)
        useNativeDriver: false, // We can't animate layout properties using native driver
      }).start();
    }, 5000);

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  // Function to show the whole component when hovered/pressed
  const handleShowFull = () => {
    setShowText(true);
    Animated.timing(widthAnim, {
      toValue: 200, // Expand width
      duration: 300,
      useNativeDriver: false,
    }).start();
  };

  // Function to hide the component again after hovering/pressing is finished
  const handleHidePartial = () => {
    setTimeout(() => {
      setShowText(false);
      Animated.timing(widthAnim, {
        toValue: 70, // Shrink width
        duration: 300,
        useNativeDriver: false,
      }).start();
    }, 200); // Hide after 5 seconds
  };

  return (
    <>
      <ChatModal openModal={openModal} handleClose={handleClose} />
      <Box
        onMouseEnter={handleShowFull}
        onMouseLeave={handleHidePartial}
        onPress={handleOpen}
        style={{
          position: 'fixed',
          bottom: 0,
          right: 0
        }}>
        <Animated.View
          style={[
            isMobileorTab ? styles.chabotBgMobile : styles.chabotBg,
            {width: widthAnim},
          ]}>
          <Image
            source={require('@/Assets/Images/Caissa/Caissa_bot_girl.svg')}
            style={[styles.buttonGirlIcon]}
            resizeMode="contain"
          />
          {showText && (
            <>
              <Spacer spacing={10} horizontal />
              <CCText
                fontSize={FontSizes[18]}
                color={CCColors.White}
                style={{whiteSpace: 'nowrap'}}>
                Ask Caissa
              </CCText>
            </>
          )}
        </Animated.View>
      </Box>
    </>
  );
}

export default CaissaChatbot;
