import ChessRestApi from '@/Api/ChessRestClient';
import {LoginResponse} from './types';
import {useMutation} from '@tanstack/react-query';
import {Readable} from 'stream';

export default function useSyncGamesApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<
    void,
    Error,
    {
      lichessUser: string;
      chessdotcomUser: string;
      ccUser: string;
      player_id: number;
      userEmail: string;
      selectedPlayer: {name: string; dob: string; fide_id: number};
    }
  >(
    async (importGames: any) => {
      console.log('Calling API to import games ..');
      const {
        lichessUser,
        chessdotcomUser,
        ccUser,
        selectedPlayer,
        player_id,
        userEmail,
      } = importGames;
      console.log(selectedPlayer, 'selectedPlayer');
      const response = await ChessRestApi.post(
        'game/v1/learning/import_games/',
        {
          ...(chessdotcomUser ? {chessdotcomUser: chessdotcomUser} : {}),
          ...(lichessUser ? {lichessUser: lichessUser} : {}),
          ...(player_id ? {player_id: player_id} : {}),
          ...(userEmail ? {userEmail: userEmail} : {}),

          ccUser: ccUser,
          ...(selectedPlayer?.name
            ? {
                name: selectedPlayer?.name,
                ...(selectedPlayer?.dob ? {dob: selectedPlayer?.dob} : {}),
                ...(selectedPlayer?.fide_id
                  ? {fide_id: selectedPlayer?.fide_id}
                  : {}),
              }
            : {}),
        },
      );
      console.log('Reponse = ', response);
      return response;
    },
    {
      onSuccess: data => {
        console.log('Executing onSuccess .. ', data);
        if (handleOnSuccess) handleOnSuccess(data);
        else if (handleOnError) handleOnError(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
