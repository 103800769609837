import Environment from '../../environment';
import axios from 'axios';
import {onFulfilled} from './interceptors/request';
import {handleLogout} from '@/Utils/CommonUtils';
import {useNavigation} from '@react-navigation/native';
import {ScreenName} from '@/navigators/StackConstants';

const useChatRestClient = () => {
  const navigation = useNavigation<any>();

  const ChatRestClient = axios.create({
    baseURL: Environment.CHAT_API_URL,
  });

  ChatRestClient.interceptors.request.use(onFulfilled);

  ChatRestClient.interceptors.response.use(
    response => {
      // If the request was made and the server responded with a status code
      // that falls out of the range of 2xx, this function will not be called.
      return response;
    },
    error => {
      // If request was sent and server responded with a status outside of the 2xx range
      if (error.response) {
        switch (error.response.status) {
          case 401:
            // Handle 401 error code.
            handleLogout(() => {
              navigation?.navigate(ScreenName?.Login);
            });
            console.error('401 Unauthorized Error');
            break;
          case 403:
            // Handle 403 error code.
            handleLogout(() => {
              navigation?.navigate(ScreenName?.Login);
            });
            console.error('403 Forbidden Error');
            break;
          default:
            break;
        }
      }
      return Promise.reject(error.response.data);
    },
  );
  return ChatRestClient;
};
export default useChatRestClient;
