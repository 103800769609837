import {useMutation} from '@tanstack/react-query';
import useChatRestClient from '@/Api/ChatRestClient';
import ChessRestClient from '@/Api/ChessRestClient';

interface PlanApiParams {
  plan_type: string;
  tenure: string;
}

export default function useGetPlanApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (error: any) => void,
) {
  const mutation = useMutation<any, Error, PlanApiParams>(
    async ({plan_type, tenure}) => {
      console.log('Getting Plan', plan_type, tenure);
      const response = await ChessRestClient.get(
        `subscription/v1/get_plan_id?plan_type=${plan_type}&tenure=${tenure}`,
      );

      return response;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) handleOnSuccess(data);
      },
      onError: error => {
        if (handleOnError) handleOnError(error);
      },
    },
  );

  return {
    ...mutation,
  };
}
