import {StyleSheet} from 'react-native';

export const Colors = {
  white: '#FFF',
  black: '#000',
  primaryDark: '#4D3F37',
  darkLight: '#6b584d',
  primaryLight: '#FAF6EB',
  primaryAction: '#FACF47',
  secondaryAction: '#3DAB9E',
  fontRegular: '#4D3F37',
  fontDark: '#262322',
};

export const AppStyles = StyleSheet.create({
  menu: {
    width: 80,
    backgroundColor: Colors.primaryDark,
    borderRightWidth: 0,
    shadowColor: '#000',
    shadowRadius: 4,
    overflow: 'auto',
    shadowOffset: {
      width: 0,
      height: 0,
    },
  },

  container: {
    // width: 1200,
    // marginHorizontal: 'auto',
    // paddingTop: 24
  },

  containerMobile: {
    padding: 16,
    paddingTop: 24,
  },

  mobileMenu: {
    width: '70%',
    backgroundColor: Colors.primaryDark,
  },
});
