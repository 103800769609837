import * as React from "react";

import Svg, { Path, SvgProps } from "react-native-svg";

import CCColors from "../../../Utils/CCColors";
import { memo } from "react";

const SvgComponent = (props: SvgProps) => {
  const { color = CCColors.White } = props;

  return (
    <Svg width={20} height={20} viewBox="0 0 74 74" fill={color} {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37 .333C16.76.333.333 16.76.333 37 .333 57.24 16.76 73.667 37 73.667c20.24 0 36.667-16.427 36.667-36.667C73.667 16.76 57.24.333 37 .333Zm-3.666 55V48h7.333v7.333h-7.334Zm0-36.666v22h7.333v-22h-7.334Z"
      />
    </Svg>
  );
};

const ExclamationIcon = memo(SvgComponent);
export default ExclamationIcon;
