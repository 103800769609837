import {StyleSheet} from 'react-native';
import {isPhone, isPortrait} from '@/Utils/Dimensions';

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    right: isPhone || isPortrait ? '5' : '5%',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '12vh',
    marginTop: 8,
    zIndex: 2000,
    alignSelf: 'flex-end',
  },
  iconButton: {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 48,
    height: 48,
  },
  modalOverlay: {
    padding: 20,
    position: isPhone || isPortrait ? 'relative' : 'absolute',
    left: isPhone || isPortrait ? 0 : '-50%',
    transform:
      isPhone || isPortrait
        ? [{translateX: 0}, {translateY: 0}]
        : [{translateX: -50}, {translateY: -50}],
    zIndex: 2000,
    alignItems: 'center',
  },
  modalContent: {
    width: isPhone || isPortrait ? '100%' : 400,
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 20,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    zIndex: 2000,
  },
  headerText: {
    alignItems: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 10,
  },
  ruleItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  ruleIcon: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
  ruleText: {
    fontSize: 14,
  },
  rulesContainer: {
    backgroundColor: '#F0F0F0',
    borderRadius: 8,
    padding: 10,
  },
  noRulesText: {
    fontSize: 14,
    fontStyle: 'italic',
  },
  overlay: {
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});

export default styles;
