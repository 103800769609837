import useChatRestClient from '@/Api/ChatRestClient';
import {getUserDetails} from '@/Utils/CommonUtils';
import {useMutation} from '@tanstack/react-query';

type subscribeUserProp = {
  source?: string;
  country?: string;
  name?: string;
  user_type?: number;
  pincode?: string;
  gender?: string;
  dateOfBirth?: string;
  fideID?: string;
};

export default function useSubscribeUser() {
  const ChatRestClient = useChatRestClient();
  const mutation = useMutation(
    async ({
      source,
      country,
      name,
      user_type,
      pincode,
      gender,
      fideID,
      dateOfBirth,
    }: subscribeUserProp) => {
      let userDetails = await getUserDetails();
      userDetails = JSON.parse(userDetails);
      const mobile = userDetails?.mobile_number;
      if (!mobile) {
        throw new Error('Missing mobile number or FIDE ID');
      }

      const response = await ChatRestClient.post('/v1/subscribe', {
        mobile_number: mobile,
        source: source,
        country: country,
        player_name: name,
        user_type: user_type,
        dob: dateOfBirth,
        gender: gender?.key?.[0]?.toUpperCase(),
        fide_id: fideID,
        pincode: pincode,
      });

      if (!response.data) {
        throw new Error('Response data is null');
      }

      return response.data;
    },
  );

  return {
    ...mutation,
    isLoading: mutation.isLoading,
  };
}
