import React, {useState} from 'react';
import {TouchableOpacity, Image} from 'react-native';
import styles from './styles';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import Spacer from '@/Atoms/Spacer';
import SupportModal from './component/SupportModal';

const UserAssistance = () => {
  const [openModal, setOpenModal] = useState(false);
  const {isMobileOrTab} = useMediaQuery();
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  return (
    <>
      <TouchableOpacity onPress={handleOpen}>
        <Box
          style={[
            styles.streakWrapper,
            isMobileOrTab && styles.streakWrapperMobile,
          ]}>
          <Ionicons name="bug-outline" size={20} />
          {!isMobileOrTab && (
            <>
              <Spacer spacing={5} horizontal />
              <CCText fontWeight="medium" style={styles.textWrapper}>
                Report a Bug
              </CCText>
            </>
          )}
        </Box>
      </TouchableOpacity>
      <SupportModal openModal={openModal} handleClose={handleClose} />
    </>
  );
};

export default UserAssistance;
