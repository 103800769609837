import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import {WINDOW_WIDTH, vs} from '@/Utils/Dimensions';
import {Colors} from '@/styles';
import {StyleSheet} from 'react-native';

const headerHeight = 72;
const mobileHeaderHeight = 48;
const userSectionWidth = 240;
const progressBarHeight = 32;

export const styles = StyleSheet.create({
  mobileHeader: {
    height: mobileHeaderHeight,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  hamburgerIcon: {
    padding: 12,
    paddingHorizontal: 20,
  },

  mobileHeaderTitle: {
    color: '#fff',
    fontSize: 20,
  },

  header: {
    height: headerHeight,
    backgroundColor: '#fff',
    // display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 1,
    // flex: 1,
    alignItems: 'center',

    shadowColor: Colors.primaryDark,
    shadowRadius: 4,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.08,
  },

  headerRightItems: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  logo: {
    width: vs(162),
    height: 32,
    marginHorizontal: 24,
  },
  logoMobile: {
    width: vs(100),
    height: 32,
  },

  progressBarWrapper: {
    borderRightWidth: 1,
    borderColor: updateColorWithOpacity(CCColors.TextColor.Dark, 0.2),
  },

  progressBar: {
    margin: 12,
    backgroundColor: CCColors.Primary.Light,
    height: progressBarHeight,
    borderRadius: 16,
    paddingHorizontal: 16,
    paddingVertical: 3,
    borderColor: CCColors.Primary.Light,
    borderWidth: 4,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
  },

  progressBarIcon: {
    height: progressBarHeight / 2,
    width: progressBarHeight / 2,
    marginRight: 4,
  },

  progressBarText: {
    lineHeight: 18,
    fontSize: 12,
    fontWeight: '500',
  },

  progressBarFilling: {
    backgroundColor: CCColors.Primary.Action,
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
  },

  user: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 24,
    width: userSectionWidth,
    justifyContent: 'flex-end',
    outlineStyle: 'none',
    // height: headerHeight - 16,
  },
  userMobile: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    outlineStyle: 'none',
    // height: headerHeight - 16,
  },
  userAvatar: {
    textAlign: 'center',
  },
  userAvatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 50,
    height: 50,
    backgroundColor: CCColors.Primary.Light,
    borderRadius: 27,
    margin: 11,
    borderWidth: 2,
    borderColor: 'rgba(255, 255, 255, 0)',
  },

  userDetails: {maxWidth: 120},

  userLevels: {
    fontSize: 11,
    textAlign: 'right',
    lineHeight: 14,
    fontWeight: '500',
  },
  userName: {
    color: CCColors.TextColor.Dark,
    fontSize: 14,
    // padding: 8,
    fontWeight: '500',
    textAlign: 'right',
    lineHeight: 18,
    marginBottom: 2,
  },

  userMenu: {
    // position: 'absolute',
    // top: headerHeight,
    // right: 0,
    // backgroundColor: CCColors.Primary.Light,
    width: userSectionWidth,
    borderRadius: 8,
    shadowColor: Colors.primaryDark,
    shadowRadius: 8,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    overflow: 'hidden',
  },

  userMenuItem: {
    borderRadius: 0,
    backgroundColor: CCColors.White,
  },
  userMenuItemContent: {
    justifyContent: 'flex-start',
    paddingHorizontal: 12,
  },

  userMenuItemText: {
    color: CCColors.TextColor.Dark,
    lineHeight: 18,
  },
  userMenuItemIcon: {
    color: CCColors.TextColor.Dark,
    marginRight: 8,
    lineHeight: 18,
  },

  levelModalCustom: {
    borderRadius: 12,
    backgroundColor: 'white',
    zIndex: 5,
    overflowY: 'hidden',
    minWidth: '20%',
    margin: 'auto',
  },
});
