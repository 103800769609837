import Box from '../../Atoms/Box';
import CCText from '../../Atoms/CCText';
import CCButton from '../../Atoms/CCButton';
import React, {useEffect, useRef, useState} from 'react';
import SafeAreaWrapper from '../../Atoms/SafeViewWrapper';
import Spacer from '@/Atoms/Spacer';
import {hs, vs, FontSizes, isPhone} from '../../Utils/Dimensions';
import {styles} from './styles';
import CCTextInput from '@/Atoms/CCTextInput';
import useLogin from '@/Hooks/LoginApi/loginApi';
import ConditionalRender from '@/Atoms/ConditionalRender';
import {setLocalStorage} from '@/Utils/CommonUtils';
import useSyncGamesApi from '@/Hooks/OnboardingApis/useSyncGames';
import {useNavigation} from '@react-navigation/native';
import {ScreenName} from '@/navigators/StackConstants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChessRook, faChess} from '@fortawesome/free-solid-svg-icons';
import ProgressBar from '@/Atoms/ProgressBar';
import useSyncGamesProgressApi from '@/Hooks/OnboardingApis/useSyncGamesProgress';
import CCColors from '@/Utils/CCColors';
import logo from '../../Assets/Images/Svgs/cc_logo.png';
import illustration from '../../Assets/Images/Svgs/person_playing_chess.png';
import brief_logo from '../../Assets/Images/Svgs/brief_logo.png';
import quote from '../../Assets/Images/Svgs/quote.png';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';

function Onboardingprogress() {
  const navigation = useNavigation<any>();
  const [status, setStatus] = useState(0); // 0 = in progress, 1 = completed
  const [count, setCount] = useState(0);
  const [imported_games, setImportedGames] = useState(0);
  const [total_games, setTotalGames] = useState(0);
  const {isMobileOrTab} = useMediaQuery();
  const handleOnError = (apiData: any) => {};

  const quotes = [
    {
      id: 1,
      author: 'Savielly Tartakower',
      quote: 'The blunders are all there on the board, waiting to be made.',
    },
    {
      id: 4,
      author: 'Jose Capablanca',
      quote:
        'A book cannot by itself teach how to play. It can only serve as a guide, and the rest must be learned by experience.',
    },
    {
      id: 3,
      author: 'Richard Reti',
      quote:
        'In chess, we get a fighting game which is purely intellectual and excludes chance.',
    },
    {
      id: 5,
      author: 'Aron Nimzowitsch',
      quote:
        'Never play to win a pawn while your development is yet unfinished.',
    },
    {
      id: 2,
      author: 'Aron Nimzowitsch',
      quote:
        'You may learn much more from a game you lose than from a game you win.',
    },
    {
      id: 6,
      author: 'Bruce Pandolfini',
      quote: 'If winning, clarify; if losing, complicate.',
    },
    {
      id: 7,
      author: 'Emanuel Lasker',
      quote: 'See a good move, look for a better one!',
    },
    {
      id: 8,
      author: 'Dominic Lawson',
      quote:
        'Nothing excites jaded Grandmasters more than a theoretical novelty.',
    },
    {
      id: 9,
      author: 'Bobby Fischer',
      quote: 'All I want to do, ever, is just play Chess.',
    },
    {
      id: 10,
      author: 'Wilhelm Steinitz',
      quote:
        'A win by an unsound combination, however showy, fills me with artistic horror.',
    },
    {
      id: 11,
      author: 'Fred Reinfeld',
      quote: 'The Pin is mightier than the sword.',
    },
  ];

  const timer: any = useRef(null);
  const quoteTimer: any = useRef(null);

  const handleOnSuccess = (apiData: any) => {
    console.log('Handling success .. ', count);
    setCount(count + 1);
    if (count >= 25 || apiData.data.status == 'COMPLETED') {
      clearInterval(timer.current);
      clearInterval(quoteTimer.current);
      navigation.navigate(ScreenName.SelectAvatar);
    } else {
      const {imported_games, total_games} = apiData.data.data;
      setImportedGames(imported_games);
      setTotalGames(total_games);
      setProgress(imported_games / total_games);
    }
  };

  const {
    mutate: getStatus,
    data: validatedData,
    error: validatedError,
    isLoading,
  } = useSyncGamesProgressApi(handleOnSuccess, handleOnError);

  const [progress, setProgress] = useState(0);
  const [id, setId] = useState(Math.floor(Math.random() * (quotes.length - 1)));

  useEffect(() => {
    quoteTimer.current = setInterval(() => {
      let randIndex = Math.floor(Math.random() * quotes.length);
      do {
        randIndex = Math.floor(Math.random() * quotes.length);
        if (randIndex >= quotes.length) {
          randIndex = quotes.length - 1;
        }
      } while (randIndex == id);
      setId(randIndex);
    }, 5000);
    timer.current = setInterval(getStatus, 2000);
  }, []);

  return (
    <SafeAreaWrapper>
      <Box
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        style={styles.container}>
        <Box
          style={[styles.labelText]}
          flexDirection={isMobileOrTab ? 'column' : 'row'}>
          <Box
            style={{flex: 0.5, backgroundColor: '#FAF6EB'}}
            flexDirection="column">
            <CCText
              fontSize={FontSizes[24]}
              textAlign="center"
              fontWeight="bold"
              style={{marginTop: '15%'}}
              color={CCColors.TextColor.Primary}>
              With CircleChess, <br />
              learn like never before.
            </CCText>
            <img
              src={illustration}
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                height: vs(455),
              }}
            />
          </Box>
          <Box style={styles.rightlabelText}>
            <Box flexDirection="row">
              <Box>
                <img src={brief_logo} style={{width: 28, height: 28}} />
              </Box>
              <Box
                style={{
                  justifyContent: 'flex-end',
                  marginHorizontal: vs(10),
                  borderTopWidth: 4,
                  borderTopColor: CCColors.ColorCodes.Done,
                  width: 112,
                }}>
                <CCText
                  fontSize={FontSizes[12]}
                  style={{color: CCColors.TextColor.Primary}}>
                  Link accounts
                </CCText>
              </Box>
              <Box
                style={{
                  justifyContent: 'flex-end',
                  marginHorizontal: vs(10),
                  borderTopWidth: 4,
                  borderTopColor: CCColors.ColorCodes.Current,
                  width: 112,
                }}>
                <CCText
                  style={{color: CCColors.TextColor.Primary}}
                  fontSize={FontSizes[12]}>
                  Fetch Games
                </CCText>
              </Box>
              <Box
                style={{
                  justifyContent: 'flex-end',
                  marginHorizontal: vs(10),
                  borderTopWidth: 4,
                  borderTopColor: CCColors.ColorCodes.Pending,
                  width: 112,
                }}>
                <CCText
                  style={{color: CCColors.ColorCodes.Pending}}
                  fontSize={FontSizes[12]}>
                  Select Avatar
                </CCText>
              </Box>
            </Box>
            <CCText
              fontSize={FontSizes[24]}
              fontWeight="bold"
              style={{marginTop: '10%'}}
              color={CCColors.menuBackground.main}>
              Fasten your seatbelts!
            </CCText>
            <Spacer spacing={24} />
            <CCText fontSize={FontSizes[16]}>
              Please wait while we personalize your experience
            </CCText>
            <Spacer spacing={48} />

            <Box
              flexDirection={'column'}
              justifyContent="center"
              alignItems="center"
              style={{paddingTop: vs(12), width: '75%', height: 45}}>
              <ProgressBar progress={progress} />
            </Box>
            <CCText
              fontSize={FontSizes[16]}
              style={{marginTop: vs(14), color: CCColors.menuBackground.main}}>
              Importing your games .. {imported_games} / {total_games}
            </CCText>
            <Spacer spacing={84} />
            <Box
              flexDirection="column"
              style={{
                borderTopColor: '#E17846',
                borderTopWidth: 0.5,
                width: 640,
                height: 200,
              }}>
              <img
                src={quote}
                style={{position: 'absolute', top: 0, left: 0, height: 50}}
              />
              <CCText
                fontSize={FontSizes[16]}
                style={{marginHorizontal: vs(70), marginVertical: 30}}>
                {quotes[id].quote}
              </CCText>
              <CCText
                fontSize={FontSizes[12]}
                style={{
                  marginHorizontal: vs(70),
                  marginVertical: 30,
                  color: 'black',
                  letterSpacing: 2,
                }}>
                - {quotes[id].author}
              </CCText>
            </Box>
            <img
              src={logo}
              alt="Circlechess"
              style={{
                width: 200,
                marginTop: vs(36),
                position: 'absolute',
                bottom: 50,
                right: 50,
              }}
            />
          </Box>
        </Box>
      </Box>
    </SafeAreaWrapper>
  );
}

export default Onboardingprogress;
