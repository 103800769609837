// import { ApplicationStackParamList } from "cc-web-client/@types/navigation";
import ImportGames from '../Screens/ImportGames';
import SelectAvatar from '../Screens/SelectAvatar';
import Dashboard from '../Screens/Dashboard';
import Games from '../Screens/Games';
import Puzzles from '../Screens/Puzzles';
import Goals from '../Screens/Goals';
import Profile from '../Screens/Settings';
import ComingSoon from '../Screens/ComingSoon';
import Onboardingprogress from '../Screens/Onboardingprogress';
import ChessAnalysis from '@/Screens/ChessAnalysis';
import Login from '@/Screens/Login';
import WaitList from '@/Screens/WaitList';
import Assessments from '@/Screens/Assessments';
import AssessmentReport from '@/Screens/AssessmentReport';
import RewardsComponent from '@/Screens/Rewards';
import OnboardingWalkthrough from '@/Screens/OnboardingWalkthrough';
import OTBGames from '@/Screens/OTBGames';
import SaveOTBGames from '@/Screens/SaveOTBGames';
import ReportAnIssue from '@/Screens/ReportAnIssue/ReportAnIssue';
import Visualisation from '@/Screens/Visualisation';
import Books from '@/Screens/Books';
import Reports from '@/Screens/Reports';
import Study from '@/Screens/Study';
import StudyGameList from '@/Screens/StudyGameList';
import PlayChess from '@/Screens/PlayChess';
import Subscription from '@/Screens/Subscription';
import PaymentStatus from '@/Screens/Payments';
import CCHome from '@/Screens/CCHome';

export interface Screen {
  id: number;
  Name: string;
  component: React.ComponentType<any>;
  props?: object;
  withAuth?: boolean;
  menuOptions?: Object;
  hideMenu?: boolean;
}

export const UnauthenticatedScreens: Screen[] = [
  {id: 11, Name: 'login', component: Login},
  {id: 12, Name: 'waitlist', component: WaitList},
  {id: 13, Name: 'home', component: CCHome},
];

export const OnboardingScreenStack: Screen[] = [
  {id: 3, Name: 'importgames', component: ImportGames},
  {id: 4, Name: 'selectavatar', component: SelectAvatar},
  {id: 6, Name: 'onboardingprogress', component: Onboardingprogress},
];

// Key is the screen name and value is the web url name
export const ScreenStack: Screen[] = [
  // {id: 1, Name: 'home', component: Home},

  {id: 1, Name: 'dashboard', component: Dashboard, menuOptions: {icon: 'home'}},
  {
    id: 3,
    Name: 'playChess',
    component: PlayChess,
    menuOptions: {label: 'Play', icon: 'gamepad-variant'},
  },
  {
    id: 2,
    Name: 'games',
    component: Games,
    menuOptions: {
      icon: 'folder-open',
      label: 'Game Review',
      preAnalysisDisabled: false,
    },
  },
  {
    id: 13,
    Name: 'assessmentReport',
    component: AssessmentReport,
    menuOptions: {
      icon: 'book-open',
      label: 'Assessment',
      preAnalysisDisabled: true,
    },
  },
  {
    id: 3,
    Name: 'positions',
    component: Puzzles,
    menuOptions: {
      label: 'Positions Trainer',
      icon: 'puzzle',
      preAnalysisDisabled: false,
    },
  },
  {
    id: 14,
    Name: 'VisualisationTrainer',
    component: Visualisation,
    // props: {feature: 'Coming soon', cover_img: 2},
    menuOptions: {icon: 'account-tie-voice', label: 'Visualization Trainer'},
  },
  // {
  //   id: 9,
  //   Name: 'reports',
  //   component: Reports,
  //   props: {
  //     feature: 'Reports 📈 to help you understand your Childs progress 🦸 !',
  //     cover_img: 2,
  //   },
  //   menuOptions: {icon: 'file-chart', preAnalysisDisabled: true, hide: true},
  // },
  {
    id: 8,
    Name: 'otb',
    component: OTBGames,
    props: {
      feature:
        'Save snaps 🤳 of your OTB game notations 🗒️ through CircleChess WhatsApp companion!',
      cover_img: 1,
    },
    menuOptions: {label: 'OTB Games', icon: 'eye', hide: false},
  },

  {
    id: 10,
    Name: 'Rules-and-Rewards',
    component: RewardsComponent,
    props: {feature: 'Coming soon', cover_img: 2},
    menuOptions: {
      label: 'Rules and Rewards',
      icon: 'trophy-variant',
      preAnalysisDisabled: false,
    },
  },
  {
    id: 14,
    Name: 'resources',
    component: Books,
    menuOptions: {
      label: 'School Of Chess',
      icon: 'book',
    },
  },

  {
    id: 6,
    Name: 'assessments',
    component: Assessments,
    menuOptions: {icon: 'folder-open', hide: true, preAnalysisDisabled: true},
  },
  // {
  //   id: 7,
  //   Name: 'goals',
  //   component: Goals,
  //   menuOptions: {label: 'Rules', icon: 'flag', hide: true},
  // },

  {id: 11, Name: 'settings', component: Profile, menuOptions: {hide: true}},
  {
    id: 12,
    Name: 'chessAnalysis',
    component: ChessAnalysis,
    menuOptions: {hide: true, preAnalysisDisabled: false},
  },
  {
    id: 14,
    Name: 'SaveOTBGames',
    component: SaveOTBGames,
    menuOptions: {hide: true},
  },
  {
    id: 15,
    Name: 'ReportAnIssue',
    component: ReportAnIssue,
    menuOptions: {hide: true},
  },

  {
    id: 15,
    Name: 'studyDetails',
    component: Study,
    menuOptions: {hide: true},
  },
  {
    id: 16,
    Name: 'studyList',
    component: StudyGameList,
    menuOptions: {label: 'Workbook', icon: 'school'},
  },
  {
    id: 17,
    Name: 'subscription',
    component: Subscription,
    menuOptions: {label: 'subscription', icon: 'diamond'},
  },
  {
    id: 18,
    Name: 'payment',
    component: PaymentStatus,
    menuOptions: {label: 'payment', hide: true},
  },
];

export const OnboardingWalkthroughStack = [
  {
    id: 16,
    Name: 'OnboardingWalkthrough',
    component: OnboardingWalkthrough,
    menuOptions: {
      // hide: true,
      headerShown: false,
      swipeEnabled: false,
      gestureEnabled: false,
    },
  },
];

export const ScreenName = {
  Home: 'Home',
  Login: 'Login',
  Waitlist: 'Waitlist',
  Assessments: 'Assessments',
  AssessmentReport: 'AssessmentReport',
  ImportGames: 'Importgames',
  Reports: 'Reports',
  SelectAvatar: 'Selectavatar',
  Dashboard: 'Dashboard',
  Onboardingprogress: 'Onboardingprogress',
  ChessAnalysis: 'ChessAnalysis',
  Puzzles: 'Positions',
  Games: 'Games',
  Books: 'Resources',
  Goals: 'Goals',
  ComingSoon: 'ComingSoon',
  Rewards: 'Rules-and-Rewards',
  Settings: 'Settings',
  Otb: 'Otb',
  OnboardingWalkthrough: 'OnboardingWalkthrough',
  SaveOTBGames: 'SaveOTBGames',
  ReportAnIssue: 'ReportAnIssue',
  Visualisation: 'VisualisationTrainer',
  StudyList: 'StudyList',
  StudyDetails: 'StudyDetails',
  PlayChess: 'PlayChess',
  Subscription: 'Subscription',
  PaymentStatus: 'PaymentStatus',
};
