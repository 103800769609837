import {StyleSheet} from 'react-native';
import {vs} from '@/Utils/Dimensions';

const styles = StyleSheet.create({
  container: {
    margin: 2,
    justifyContent: 'center',
    height: vs(150),
    borderRadius: 8,
  },
  content: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  normalShadow: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 8,

    shadowColor: '#4D3F37',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.05,
    shadowRadius: 8,

    elevation: 8,
  },
  riveContainer: {
    height: 60,
    width: 60,
    marginTop: 1,
  },
  text: {
    margin: 10,
  },
});

export default styles;
