import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useSaveCircleChessGame() {
    return useMutation(
      async ({
        userId,
        pgnContent,
        opponentUserId,
        color,
        endTime,
        Result,
        timeControl,
        opponentUsername,
        myRating,
        opponentRating,
        endType
      }: {
        userId: string;
        pgnContent: string;
        opponentUserId: string;
        color: string;
        endTime: string;
        Result: string;
        timeControl: string;
        opponentUsername: string;
        myRating: number;
        opponentRating: number;
        endType: string;
      }) => {
        const response = await ChessRestClient.post('learning/save_circlechess_game/', {
          user_id: userId,
          pgn_content: pgnContent,
          opponent_user_id: opponentUserId,
          color: color,
          end_time: endTime,
          result: Result,
          time_control: timeControl,
          opponent_username: opponentUsername,
          rating: myRating,
          opponent_rating: opponentRating,
          end_type: endType
        });
        console.log("save_circlechess api respoinse is :", response);

        return response.data;
      }
    );
  }
  