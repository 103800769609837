import Box from '@/Atoms/Box';
import React from 'react';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import { Image, ImageBackground, ScrollView } from 'react-native';
import styles from '../../styles';
import ConditionalRender from '@/Atoms/ConditionalRender';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import StatisticsCarousel from '../../components/StatisticsCarousel';
import squareBackgroundTopRight from '@/Assets/Images/Svgs/home_page/square_background.svg';
import player from '@/Assets/Images/Svgs/home_page/player.svg';
import badge from '@/Assets/Images/Svgs/home_page/badge.svg';
import Tournament from '@/Assets/Images/Svgs/home_page/tournament.svg';
import ArrowUp from '@/Assets/Images/Svgs/home_page/arrow_up.svg';
import ArrowDown from '@/Assets/Images/Svgs/home_page/arrow_down.svg';

const Countdown = () => {
    const {isMobileOrTab} = useMediaQuery();
  return (
    <>
        <Box>
          <ConditionalRender
            condition={!isMobileOrTab}
            childrenA={
              <Box>
                <Image
                  source={ArrowUp}
                  resizeMethod="resize"
                  style={{width: 70, height: 70, marginLeft: '15%'}}
                />
                <CCText
                  fontSize={28}
                  textAlign="center"
                  style={{
                    width: 710,
                    height: 120,
                    fontWeight: '400',
                    lineHeight: 40,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}>
                  We are CircleChess. We want to transform the world of chess
                  and make the game accessible, exciting, and engaging for
                  players of all levels.{' '}
                </CCText>
              </Box>
            }
            childrenB={
              <Box>
                <Image
                  source={ArrowUp}
                  resizeMethod="resize"
                  style={{
                    width: 48,
                    height: 48,
                    marginLeft: '10%',
                    marginBottom: '5%',
                    marginTop: '10%',
                  }}
                />
                <CCText
                  fontSize={20}
                  textAlign="center"
                  style={{
                    // width: 320,
                    // height: 140,
                    fontWeight: '400',
                    marginLeft: '10%',
                    marginRight: '10%',
                  }}>
                  We are CircleChess. We want to transform the world of chess
                  and make the game accessible, exciting, and engaging for
                  players of all levels.{' '}
                </CCText>
              </Box>
            }
          />
          <ConditionalRender
            condition={!isMobileOrTab}
            childrenA={
              <Box
                flexDirection="row"
                gap={20}
                overflow="hidden"
                marginLeft="auto"
                marginRight="auto"
                marginTop={50}>
                <ImageBackground
                  source={squareBackgroundTopRight}
                  style={styles.bottomBox}>
                  <Box
                    style={{marginTop: 'auto', marginBottom: 'auto'}}
                    gap={10}>
                    <Box flexDirection="row" justifyContent="center">
                      <Image
                        source={player}
                        resizeMethod="resize"
                        alt="player"
                        style={{width: 80, height: 80}}
                      />
                    </Box>
                    <CCText
                      fontWeight="thunder"
                      fontSize={64}
                      style={{lineHeight: 58.88}}
                      textAlign="center"
                      color={CCColors.TextColor.Primary}>
                      2,500+
                    </CCText>
                    <CCText
                      fontWeight="regular"
                      fontSize={20}
                      textAlign="center"
                      color="#806234"
                      style={{
                        width: '80%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        lineHeight: 30,
                      }}>
                      Registered Players
                    </CCText>
                  </Box>
                </ImageBackground>
                <ImageBackground
                  source={squareBackgroundTopRight}
                  style={styles.bottomBox}>
                  <Box
                    style={{marginTop: 'auto', marginBottom: 'auto'}}
                    gap={10}>
                    <Box flexDirection="row" justifyContent="center">
                      <Image
                        source={Tournament}
                        resizeMethod="resize"
                        alt="player"
                        style={{width: 80, height: 80}}
                      />
                    </Box>
                    <CCText
                      fontWeight="thunder"
                      fontSize={64}
                      style={{lineHeight: 58.88}}
                      textAlign="center"
                      color={CCColors.TextColor.Primary}>
                      200+
                    </CCText>
                    <CCText
                      fontWeight="regular"
                      fontSize={20}
                      textAlign="center"
                      color="#4D3F37"
                      style={{
                        width: '80%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        lineHeight: 30,
                      }}>
                      Tournaments Hosted
                    </CCText>
                  </Box>
                </ImageBackground>
                <ImageBackground
                  source={squareBackgroundTopRight}
                  style={styles.bottomBox}>
                  <Box
                    style={{marginTop: 'auto', marginBottom: 'auto'}}
                    gap={10}>
                    <Box flexDirection="row" justifyContent="center">
                      <Image
                        source={badge}
                        resizeMethod="resize"
                        alt="player"
                        style={{width: 80, height: 80}}
                      />
                    </Box>
                    <CCText
                      fontWeight="thunder"
                      fontSize={64}
                      style={{lineHeight: 58.88}}
                      textAlign="center"
                      color={CCColors.TextColor.Primary}>
                      5,000+
                    </CCText>
                    <CCText
                      fontWeight="regular"
                      fontSize={20}
                      textAlign="center"
                      color="#806234"
                      style={{
                        width: '80%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        lineHeight: 30,
                      }}>
                      Tournament Registrations
                    </CCText>
                  </Box>
                </ImageBackground>
              </Box>
            }
            childrenB={<StatisticsCarousel />}
          />
          <ConditionalRender
            condition={!isMobileOrTab}
            childrenA={
              <Box>
                <CCText
                  fontSize={28}
                  textAlign="center"
                  style={{
                    width: 710,
                    height: 120,
                    lineHeight: 40,
                    fontWeight: '400',

                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 50,
                  }}>
                  Founded by a group of dedicated chess enthusiasts and
                  technology innovators, we are on a mission to revolutionize
                  the chess experience.{' '}
                </CCText>
                <Image
                  source={ArrowDown}
                  resizeMethod="resize"
                  style={{
                    width: 70,
                    height: 70,
                    alignSelf: 'flex-end',
                    marginRight: '15%',
                  }}
                />
              </Box>
            }
            childrenB={
              <Box>
                <CCText
                  fontSize={20}
                  textAlign="center"
                  style={{
                    // width: 320,
                    // height: 140,
                    lineHeight: 28,
                    fontWeight: '400',
                    marginLeft: '10%',
                    marginRight: '10%',
                    marginTop: 50,
                  }}>
                  Founded by a group of dedicated chess enthusiasts and
                  technology innovators, we are on a mission to revolutionize
                  the chess experience.{' '}
                </CCText>
                <Image
                  source={ArrowDown}
                  resizeMethod="resize"
                  style={{
                    width: 48,
                    height: 48,
                    marginLeft: '75%',
                    marginTop: '10%',
                  }}
                />
              </Box>
            }
          />
        </Box>
    </>
  )
}

export default Countdown;