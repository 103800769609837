import {
  FontSizes,
  WINDOW_HEIGHT,
  WINDOW_WIDTH,
  hs,
  isPhone,
  vs,
} from '../../Utils/Dimensions';

import CCColors from '../../Utils/CCColors';
import {Dimensions, StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  signInTypo: {
    textAlign: 'right',
    left: '50%',
    top: 48,
    color: CCColors?.Primary?.Default,
    position: 'absolute',
  },
  labelText: {
    width: '100%',
    height: '100%',
  },
  buttonStyles: {
    fontSize: FontSizes[9],
    paddingVertical: vs(3),
    color: CCColors.TextColor.Primary,
  },
  buttonWrapper: {
    backgroundColor: '#FACF47',
    borderColor: '#262322',
    borderRadius: 32,
    // height: vs(48),
    // width: vs(240),
    ...(isPhone && {width: '70%', margin: 'auto'}),
  },
  container: {
    height: WINDOW_HEIGHT,
    width: WINDOW_WIDTH,
  },
  container_avatar: {
    marginVertical: 20,
  },
  item: {
    padding: vs(6),
    backgroundColor: '#4D3F37',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    margin: 1,
    height: Dimensions.get('window').height / 8, // approximate a square
  },
  itemInvisible: {
    backgroundColor: 'transparent',
  },
  itemText: {
    color: '#FAF6EB',
    textAlign: 'center',
    fontSize: FontSizes[6],
  },
  rightlabelText: {
    paddingLeft: vs(120),
    flex: 0.5,
    marginTop: '6%',
  },
});
