import {useQuery} from '@tanstack/react-query';
import ChessRestClient from '../../Api/ChessRestClient';

export function useGetAllStudyGames(filter = 'both', handleOnSuccess) {
  const query = useQuery(
    ['allStudyGames', filter], // Include the filter in the query key
    async () => {
      const response = await ChessRestClient.get(
        `/learning/studygames/?filter=${filter}`, // Corrected endpoint path and query parameter
      );
      return response.data;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) {
          handleOnSuccess(data);
        }
      },
    },
  );

  return query;
}
