import { DashboardResponse } from './types';
import { useMutation } from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useGetProfileApi(
    handleOnSuccess?: (data: any) => void,
    handleOnError?: (data: any) => void
) {
    const mutation = useMutation<
        DashboardResponse,
        Error,
        void>(
            async () => {
                const response = await ChessRestClient.get('learning/get_profile');
                console.log('Retrieved profile = ', response.data);
                return response.data;
            },
            {
                onSuccess: (data) => {
                    if (handleOnSuccess && data.success)
                        handleOnSuccess(data)
                    else if (handleOnError)
                        handleOnError(data)
                }
            });

    return {
        ...mutation,
    };
}
