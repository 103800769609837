import React, {useEffect, useMemo, useState} from 'react';
import {View, StyleSheet, SafeAreaView, Text} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import CCButton from '@/Atoms/CCButton';
import ConditionalRender from '@/Atoms/ConditionalRender';
import {useUserPlanStore} from '@/Store/userPlanStore';
import {CommonActions, useNavigation, useRoute} from '@react-navigation/native';
import {ScreenName} from '@/navigators/StackConstants';
import styles from './styles';
import {goToDashBoard} from '@/Utils/CommonUtils';

const PaymentStatus = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const [status, setStatus] = useState('');
  const store = useUserPlanStore();

  useEffect(() => {
    // const queryParamsString = route.params?.query || '';
    const urlParams = new URLSearchParams(route.params);
    console.log('Status Param:', route?.params?.status[0]);
    const statusParam = route?.params?.status[0];

    if (statusParam) {
      switch (statusParam) {
        case 'CHARGED':
          setStatus('success');
          store.setActivePlan(store.choosenPlan);
          break;
        case 'PENDING_VBV':
        case 'AUTHORIZING':
          setStatus('pending');
          break;
        case 'AUTHORIZATION_FAILED':
        case 'AUTHENTICATION_FAILED':
          setStatus('rejected');
          break;
        // default:
        //   console.log('Unknown status:', statusParam);
      }
    }
  }, [route.params]);

  useEffect(() => {
    console.log('status updated', status);
  }, [status]);

  const onClose = () => {
    goToDashBoard(navigation);
  };

  const getStatusIcon = useMemo(() => {
    switch (status) {
      case 'success':
        return 'checkmark-circle';
      case 'pending':
        return 'time';
      case 'rejected':
        return 'close-circle';
      // default:
      //   return 'information-circle';
    }
  }, [status]);

  const getStatusColor = useMemo(() => {
    switch (status) {
      case 'success':
        return CCColors.Payment.Success;
      case 'pending':
        return CCColors.Payment.Pending;
      case 'rejected':
        return CCColors.Payment.Rejected;
      // default:
      //   return CCColors.Payment.Default;
    }
  }, [status]);

  const getStatusMessage = useMemo(() => {
    switch (status) {
      case 'success':
        return 'Payment Successful';
      case 'pending':
        return 'Payment Pending';
      case 'rejected':
        return 'Payment Rejected';
      // default:
      //   return 'Unknown Status';
    }
  }, [status]);

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.contentView}>
        <Ionicons
          name={getStatusIcon}
          size={80}
          color={getStatusColor}
          style={styles.icon}
        />
        <CCText style={[styles.statusCCText, {color: getStatusColor}]}>
          {getStatusMessage}
        </CCText>
        <ConditionalRender
          condition={status === 'success'}
          childrenA={
            <>
              <CCText textAlign="center" style={styles.successText}>
                Congrats! You have upgraded to{' '}
                {store?.choosenPlan === 'aspiringLearners'
                  ? 'Aspiring Learners'
                  : 'Aspiring Champions'}{' '}
              </CCText>
              <CCText textAlign="center" style={styles.successText}>
                {store?.tenure} Subscription
              </CCText>
              <CCText textAlign="center" style={styles.successText}>
                Enjoy the premium features and take your experience to the next
                level!
              </CCText>
            </>
          }
        />
        <ConditionalRender
          condition={status === 'success' || !status}
          childrenA={
            <CCButton onPress={onClose}>
              <CCText style={styles.buttonCCText}>Go to Dashboard</CCText>
            </CCButton>
          }
        />
        <ConditionalRender
          condition={status === 'pending'}
          childrenA={
            <CCText textAlign="center" fontSize={16}>
              Payment is in progress.Please wait...
            </CCText>
          }
        />
        <ConditionalRender
          condition={status === 'rejected'}
          childrenA={
            <>
              <CCText
                fontSize={16}
                textAlign="center"
                style={{marginBottom: 20}}>
                Unfortunately! Payment got failed.
              </CCText>
              <CCButton
                onPress={() => navigation.navigate(ScreenName.Subscription)}>
                <CCText style={styles.buttonCCText}>Explore Other Plans</CCText>
              </CCButton>
            </>
          }
        />
      </View>
    </SafeAreaView>
  );
};

export default PaymentStatus;
